import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Properties, Property, dispatchForm, PaginationState, CommonForm } from '@lib/state';
import {
  MaintenanceTicketStore,
  maintenanceTicketStore,
  MaintenanceTicketUIState,
} from './maintenance-ticket.store';
import { applyTransaction } from '@datorama/akita';
import { lightFormat } from 'date-fns';
import {
  MaintenanceTicket,
  MaintenanceTicketPhoto,
  PriorityOptions,
  TicketStatus,
} from './maintenance-ticket.model';
import {
  CreateMaintenanceTicketRequest,
  MaintenanceTicketModel,
  UpdateMaintenanceTicketRequest,
} from '@lib/state/api/generated/properties';
import { MaintenanceTicketForms } from './maintenance-ticket.form';

export class MaintenanceTicketService {
  constructor(
    private readonly store: MaintenanceTicketStore,
    private readonly maintenanceApi: Properties.MaintenanceApi
  ) {}

  getMaintenanceTickets(
    propertyId: Property['id'],
    startDate?: Date,
    endDate?: Date,
    status?: TicketStatus,
    assignedTo?: string,
    priority?: PriorityOptions,
    active?: boolean,
    textFilter?: string,
    continuationToken?: string | null,
    limit?: number
  ) {
    from(
      this.maintenanceApi.maintenanceGet(
        propertyId,
        continuationToken ?? undefined,
        startDate ? lightFormat(startDate, 'yyyy-MM-dd') : undefined,
        endDate ? lightFormat(endDate, 'yyyy-MM-dd') : undefined,
        status,
        assignedTo ?? undefined,
        priority,
        limit,
        undefined,
        active,
        textFilter
      )
    )
      .pipe(map(({ data }) => data))
      .subscribe(({ data, ...pagination }) =>
        applyTransaction(() => {
          this.store.upsertMany(data);
          this.updateUI({
            propertyId,
            startDate,
            endDate,
            status,
            assignedTo,
            priority,
            active,
            textFilter,
          });
          this.updatePaginationState(pagination);
        })
      );
  }

  getMaintenanceTicketById(propertyId: Property['id'], id: MaintenanceTicket['id']) {
    from(this.maintenanceApi.maintenancePropertyIdTicketsIdGet(propertyId, id))
      .pipe(map(({ data }) => data.data))
      .subscribe(ticket => {
        this.updateStore(ticket);
      });
  }

  createMaintenanceTicket(request: CreateMaintenanceTicketRequest) {
    from(this.maintenanceApi.maintenancePost(request))
      .pipe(
        dispatchForm(MaintenanceTicketForms.Manage),
        map(({ data }) => data.data)
      )
      .subscribe(ticket => {
        applyTransaction(() => {
          this.store.upsert(ticket.id, ticket);
          this.store.setActive(ticket.id);
        });
      });
  }

  getMaintenanceTicketPhotos(id: MaintenanceTicket['id']) {
    from(this.maintenanceApi.maintenanceIdPhotoListGet(id))
      .pipe(map(({ data }) => data.data))
      .subscribe(photos => {
        applyTransaction(() => {
          this.updatePhotosState(photos);
        });
      });
  }

  createMaintenanceTicketPhotos(id: string, files: File) {
    from(this.maintenanceApi.maintenanceIdPhotoUploadPost(id, files))
      .pipe(
        dispatchForm(MaintenanceTicketForms.CreatePhoto),
        map(({ data }) => data.data)
      )
      .subscribe(photos => {
        applyTransaction(() => {
          this.updatePhotosStateAdd(photos);
        });
      });
  }

  updateMaintenanceTicket(id: MaintenanceTicket['id'], request: UpdateMaintenanceTicketRequest) {
    from(this.maintenanceApi.maintenanceIdPost(id, request))
      .pipe(
        dispatchForm(MaintenanceTicketForms.Manage),
        map(({ data }) => data.data)
      )
      .subscribe(ticket => {
        this.updateStore(ticket);
      });
  }

  deleteMaintenanceTicket(id: MaintenanceTicket['id']) {
    from(this.maintenanceApi.maintenanceIdDeletePost(id))
      .pipe(
        dispatchForm(MaintenanceTicketForms.Delete),
        map(({ data }) => data.data)
      )
      .subscribe(ticket => {
        this.updateStore(ticket);
      });
  }

  deleteMaintenanceTicketPhoto(id: MaintenanceTicket['id'], photoId: MaintenanceTicketPhoto['id']) {
    from(this.maintenanceApi.maintenanceIdPhotoPhotoIdDeletePost(id, photoId))
      .pipe(dispatchForm(MaintenanceTicketForms.DeletePhoto))
      .subscribe(() => {
        this.updatePhotosStateDelete(photoId);
      });
  }

  exportMaintenanceTickets(
    propertyId: Property['id'],
    startDate?: Date,
    endDate?: Date,
    assignedTo?: string,
    status?: TicketStatus,
    priority?: PriorityOptions,
    active?: boolean
  ) {
    from(
      this.maintenanceApi.maintenanceExportGet(
        propertyId,
        undefined,
        startDate ? lightFormat(startDate, 'yyyy-MM-dd') : undefined,
        endDate ? lightFormat(endDate, 'yyyy-MM-dd') : undefined,
        status,
        assignedTo,
        priority,
        undefined,
        undefined,
        active
      )
    )
      .pipe(
        map(x => new Blob([x.data], { type: 'text/csv' })),
        dispatchForm(CommonForm.Export)
      )
      .subscribe(x => saveAs(x, `Maintenance_Tickets_${new Date().valueOf()}.csv`));
  }

  setActive(id?: string) {
    this.store.setActive(id ?? null);
  }

  private updatePaginationState(
    { isDone, continuationToken }: PaginationState,
    key: 'pagination' | 'current' | 'future' = 'pagination'
  ) {
    this.store.update(() => ({
      [key]: { isDone, continuationToken },
    }));
  }

  private updatePhotosState(photos?: MaintenanceTicketPhoto[]) {
    this.store.update(() => ({ photos: photos }));
  }
  private updatePhotosStateAdd(photos?: MaintenanceTicketPhoto[]) {
    let statePhotos = this.store.getValue().photos;
    statePhotos = [...statePhotos, ...photos];

    this.store.update(() => ({ photos: statePhotos }));
  }

  private updatePhotosStateDelete(photoId: string) {
    let statePhotos = this.store.getValue().photos;
    statePhotos = statePhotos?.filter(x => x.id !== photoId);

    this.store.update(() => ({ photos: statePhotos }));
  }

  private updateUI(state: Partial<MaintenanceTicketUIState>) {
    this.store.update(({ ui }) => ({
      ui: { ...ui, ...state },
    }));
  }

  private updateStore(ticket: MaintenanceTicketModel) {
    applyTransaction(() => {
      this.store.upsert(ticket.id, ticket);
      this.store.setActive(ticket.id);
    });
  }
}

export const maintenanceTicketService = new MaintenanceTicketService(
  maintenanceTicketStore,
  new Properties.MaintenanceApi()
);
