import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import { UserManagementPage, EmployeeDetailPage } from './pages';

export const UserManagementRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={match.path} exact component={UserManagementPage} />
    <Route path={`${match.path}/:employeeId`} component={EmployeeDetailPage} />
    <Redirect to={match.path} />
  </Switch>
);
