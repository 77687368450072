import { applyTransaction, setLoading } from '@datorama/akita';
import { EMPTY, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { DeepPartial } from 'utility-types';
import { Reservations, dispatchForm, RequestCancellation, Debounce, CommonForm } from '@lib/state';
import { formatDate } from 'app/shared';
import { JournalEntriesForms } from './journal-entries.forms';
import { JournalEntryStore, journalEntryStore, JournalEntryUIState } from './journal-entries.store';
import { exportFileName, JournalFilters } from './journal-entries.model';

type FilterState = ReturnType<JournalEntryService['getFilterState']>;

export class JournalEntryService {
  constructor(
    private store: JournalEntryStore,
    private readonly ledgerApi: Reservations.LedgerApi
  ) {}

  private queryLimit = 1000;

  updateFilters(filters: Partial<JournalFilters>) {
    this.store.setLoading(true);

    this.store.update(({ ui }) => ({
      ui: { ...ui, filters: { ...ui.filters, ...filters } },
    }));

    this.getLedgerData(this.getFilterState()).subscribe();
  }

  reset() {
    this.store.reset();
  }

  @Debounce(500)
  @RequestCancellation()
  private getLedgerData(filters: FilterState, options?: any) {
    if (!filters) {
      this.store.set([]);
      this.store.setLoading(false);
      return EMPTY;
    }

    return from(
      this.ledgerApi.ledgerGet(
        filters.propertyId,
        filters.startDate,
        filters.endDate,
        filters.reference,
        filters.invoice,
        filters.recordNumber,
        filters.recordNumberRange,
        filters.email,
        options
      )
    ).pipe(
      setLoading(this.store),
      tap(data => {
        applyTransaction(() => {
          this.store.set(data.data.data);
          this.updateUI({ queryLimitWarning: data.data.data.length >= this.queryLimit });
          if (data.data.accountTotals) {
            this.updateUI({ accountTotals: data.data.accountTotals });
          }
        });
      })
    );
  }

  uploadLedgerData(file: File): void {
    const { propertyId } = this.store.getValue().ui.filters;
    if (!propertyId) throw new Error('PropertyId must be set as a filter.');

    from(this.ledgerApi.ledgerUploadPost(file, propertyId))
      .pipe(
        tap(() => this.getLedgerData(this.getFilterState())),
        dispatchForm(JournalEntriesForms.UploadLedgerData)
      )
      .subscribe();
  }

  getAccounts(propertyId: string): void {
    from(this.ledgerApi.ledgerPropertyIdAccountsGet(propertyId)).subscribe(data => {
      const accounts = data.data.data;
      if (accounts) {
        this.updateUI({ accounts });
      }
    });
  }

  exportLedger() {
    const filters = this.getFilterState();
    if (!filters) {
      return;
    }

    from(
      this.ledgerApi.ledgerExportGet(
        filters.propertyId,
        filters.startDate,
        filters.endDate,
        filters.reference,
        filters.invoice,
        filters.recordNumber,
        filters.recordNumberRange,
        filters.email,
        {
          responseType: 'blob',
        }
      )
    )
      .pipe(
        map(x => new Blob([x.data], { type: 'text/csv' })),
        dispatchForm(CommonForm.Export)
      )
      .subscribe(x =>
        saveAs(x, exportFileName(filters.propertyId, filters.startDate, filters.endDate))
      );
  }

  private updateUI(values: DeepPartial<JournalEntryUIState>) {
    this.store.update(({ ui }) => ({ ui: { ...ui, ...values } }));
  }

  private getFilterState() {
    const {
      propertyId,
      startDate: startDateStr,
      endDate: endDateStr,
      reference,
      invoice,
      recordNumber,
      recordNumberRange,
      email,
    } = this.store.getValue().ui.filters;

    if (propertyId && (startDateStr || endDateStr || invoice || recordNumber || email)) {
      const startDate = !!startDateStr ? formatDate(startDateStr) : undefined;
      const endDate = !!endDateStr ? formatDate(endDateStr) : undefined;

      return {
        propertyId,
        startDate,
        endDate,
        reference,
        invoice,
        recordNumber,
        recordNumberRange,
        email,
      };
    }

    return undefined;
  }
}

export const journalEntryService = new JournalEntryService(
  journalEntryStore,
  new Reservations.LedgerApi()
);
