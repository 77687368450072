import { useState } from 'react';

// Object to store incrementing ids
const ids: Record<string, number> = {};

export const uniqueId = (prefix: string = '$id') => {
  if (!ids[prefix]) ids[prefix] = 100;

  return `${prefix}-${ids[prefix]++}`;
};

export const useUniqueId = (prefix?: string) => {
  const [id] = useState(() => uniqueId(prefix));
  return id;
};
