import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppColors,
  CorporateAffiliationIcon,
  DateDisplay,
  GuestNameListDisplay,
  SleepScheduleIconDisplay,
  VipDisplay,
} from '@lib/common';
import {
  HousekeepingStatusEnum,
  Room,
  RoomsFilters,
  RoomZone,
  ShowHousekeepingStatus,
} from '@lib/state';
import { createStyles, makeStyles, styled, Typography, useTheme } from '@material-ui/core';
import { GuestStay, GuestStaysFilters, RoomCount } from 'app/state';
import clsx from 'clsx';
import { isEqual } from 'date-fns';
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars';
import AutoSizer from 'react-virtualized-auto-sizer';
import { areEqual, FixedSizeList } from 'react-window';
import { Header } from './header';
import { RoomCounts } from './room-counts';
import { RoomSchedule } from './room-schedule';
import { RoomCountType, RoomTypeModel } from '@lib/state/api/generated/properties';
import { TimelineProps, TimelineView } from './useTimeline';
import { TapeChartRow } from './tape-chart-utils';
import { GuestStaysFiltersModal } from 'app/tape-chart/components/tape-chart-filters.modal';

const ColumnHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',

  textTransform: 'uppercase',
  fontWeight: 700,

  padding: '0 0.5rem',
});

const useStyles = makeStyles(theme =>
  createStyles({
    root: { display: 'flex', flexDirection: 'column', flex: 1 },
    header: {
      position: 'sticky',
      top: theme.tapeChart.headerSpacingTop,
      backgroundColor: theme.palette.background.default,
      zIndex: 5,
    },
    icon: {
      fontSize: '1.5em',
    },
    roomContainer: { flex: 1 },
    roomHeader: {
      flex: 3,
      borderRight: `1px solid ${AppColors.HeaderDivider}`,
    },
    nameHeader: {
      flex: 5,
      borderRight: `1px solid ${AppColors.HeaderDivider}`,
    },
    dateHeader: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: `1px solid ${AppColors.HeaderDivider}`,
    },
    room: {
      flex: 3,
    },
    name: {
      flex: 5,
    },
    date: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    row: {
      display: 'flex',
      height: '3rem',

      '& > :not(:last-child)': {
        display: 'flex',
        flexDirection: 'column',
        borderRight: `1px solid ${AppColors.RowDivider}`,

        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',

        padding: '0.5rem',
      },
    },
    oddRow: {
      backgroundColor: AppColors.LightGray,
    },
    primaryRoomRow: {},
    secondaryRoomRow: {},
    unavailable: {
      backgroundColor: AppColors.TransparentRed,
    },
    selected: {
      backgroundColor: AppColors.TransparentDarkGreen,
    },
    dateValue: { marginBottom: 4 },
    truncate: { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
    bold: { fontWeight: 700 },
    houseKeepingStatus: { color: AppColors.Red },
  })
);

interface TapeChartProps extends TimelineProps {
  rooms: Array<Room>;
  tapeChartRows: Array<TapeChartRow>;
  guestStays: Array<GuestStay>;
  roomCounts: Array<RoomCount>;
  guestStaysFilters: GuestStaysFilters;
  roomsFilters: RoomsFilters;
  countBy: RoomCountType;
  onChangeDate: (date: Date) => void;
  onChangeView: (view: TimelineView) => void;
  reloadRoom: (roomId: string) => void;
  setCountBy: (countBy: RoomCountType) => void;
  selectRow: (row: TapeChartRow) => void;
  enableRoomSelection?: boolean;
  roomSelectionHeader?: ReactNode;
  selectedRows?: Array<Room>;
  zones: Array<RoomZone>;
  roomTypes: Array<RoomTypeModel>;
}

export const TapeChart: React.FC<TapeChartProps> = ({
  startDate,
  view,
  rooms,
  tapeChartRows,
  roomCounts,
  countBy,
  onChangeDate,
  onChangeView,
  setCountBy,
  roomSelectionHeader,
  selectedRows,
  selectRow,
  guestStaysFilters,
  roomTypes,
  roomsFilters,
  zones,
}) => {
  const [openFiltersModal, setOpenFiltersModal] = useState(false);

  const theme = useTheme();
  const styles = useStyles();

  const itemData = useMemo(
    () => createItemData(startDate, view, tapeChartRows, selectRow, selectedRows),
    [startDate, view, tapeChartRows, selectRow, selectedRows]
  );

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <RoomCounts
          startDate={startDate}
          view={view}
          roomCounts={roomCounts}
          rooms={rooms}
          countBy={countBy}
          onChangeDate={onChangeDate}
          onChangeView={onChangeView}
          openTapeChartFiltersModal={() => setOpenFiltersModal(true)}
          setCountBy={setCountBy}
        />
        <Header startDate={startDate} view={view}>
          <ColumnHeader className={styles.roomHeader}>Room</ColumnHeader>
          <ColumnHeader className={styles.nameHeader}>Name</ColumnHeader>
          <ColumnHeader className={styles.dateHeader}>
            <FontAwesomeIcon icon="calendar-check" className={styles.icon} fixedWidth />
          </ColumnHeader>
          <ColumnHeader className={styles.dateHeader}>
            <FontAwesomeIcon icon="calendar-times" className={styles.icon} fixedWidth />
          </ColumnHeader>
        </Header>
      </div>
      {roomSelectionHeader}
      <div className={styles.roomContainer}>
        <AutoSizer>
          {size => (
            <FixedSizeList
              {...size}
              itemCount={tapeChartRows.length}
              itemSize={theme.tapeChart.rowHeight}
              itemData={itemData}
              outerElementType={CustomScrollbarsVirtualList}
            >
              {RoomRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
      <GuestStaysFiltersModal
        guestStaysFilters={guestStaysFilters}
        roomsFilters={roomsFilters}
        open={openFiltersModal}
        zones={zones}
        roomTypes={roomTypes}
        onClose={() => setOpenFiltersModal(false)}
      />
    </div>
  );
};
const createItemData = (
  startDate: Date,
  view: TimelineView,
  rows: TapeChartRow[],
  selectRow: (row: TapeChartRow) => void,
  selectedRows?: Room[]
) => {
  return {
    startDate,
    view,
    rows,
    selectRow,
    selectedRows,
  };
};

const indicatorMessage = (
  startDate: Date,
  housekeepingStatus: HousekeepingStatusEnum,
  guestStay?: GuestStay,
  readyForRent?: boolean
) => {
  if (
    !readyForRent &&
    housekeepingStatus === HousekeepingStatusEnum.NoCleaningRequired &&
    !!!guestStay
  ) {
    return 'Not Ready';
  } else {
    const isUpcomingCheckout =
      !!guestStay?.checkOutDate && isEqual(startDate, guestStay.checkOutDate);
    return ShowHousekeepingStatus(housekeepingStatus, isUpcomingCheckout);
  }
};

function roomSlotBlocked(row: TapeChartRow) {
  return !row.guestStay && row.occupants.some(o => o.isRoomBlocked);
}

const RoomRow = memo(
  ({
    data,
    index: i,
    style,
  }: {
    data: ReturnType<typeof createItemData>;
    index: number;
    style: React.CSSProperties;
  }) => {
    const styles = useStyles();
    const { startDate, view, rows, selectedRows, selectRow } = data;
    const row = rows[i];
    const { room, sequence, guestStay, slot } = row;
    const { roomNumber, housekeepingStatus, roomType } = room;
    const oddRow = sequence % 2 === 1;
    const primaryRow = i === 0 || rows[i - 1].room !== room;
    const selected = selectedRows?.some(x => x.id === row.room.id);
    const vipStatuses =
      guestStay?.guests.map(({ userId, vipStatus }) => {
        return { userId, vipStatus };
      }) ?? [];

    return (
      <div
        className={clsx(
          {
            [styles.oddRow]: oddRow,
            [styles.primaryRoomRow]: primaryRow,
            [styles.secondaryRoomRow]: !primaryRow,
            [styles.selected]: selected,
          },
          styles.row
        )}
        style={style}
        onClick={() => selectRow(row)}
        data-testid={`tapeChartRow:${row.room.roomNumber}`}
      >
        <div className={styles.room} data-testid="tapeChartRoomCell">
          <span className={styles.truncate} data-testid="roomCellRoomNumber">
            <strong>{roomNumber}</strong>
            &nbsp;{!!slot && slot}&nbsp;
            <small>{roomType.abbreviation ?? roomType.name}</small>
          </span>
          <strong className={styles.houseKeepingStatus} data-testid="roomCellHouseKeepingStatus">
            {indicatorMessage(startDate, housekeepingStatus, guestStay, room.readyForRent)}
          </strong>
        </div>
        <div className={styles.name}>
          {roomSlotBlocked(row) ? (
            'BLOCKED'
          ) : (
            <>
              <GuestNameListDisplay className={styles.bold} guests={guestStay?.guests} truncate>
                <CorporateAffiliationIcon {...guestStay?.affiliation} />
                <SleepScheduleIconDisplay sleepSchedule={guestStay?.affiliation?.sleepSchedule} />
                {vipStatuses.map(({ userId, vipStatus }) => (
                  <VipDisplay
                    vipStatus={vipStatus}
                    style={{ paddingLeft: '.5em' }}
                    size="lg"
                    key={userId}
                  />
                ))}
              </GuestNameListDisplay>
              <Typography component="small" variant="body2" className={styles.truncate}>
                {guestStay?.recordNumber}
                {!!guestStay?.affiliation &&
                  ` - ${guestStay.affiliation.corporateAccountName}${
                    guestStay?.affiliation?.crew ? ' - ' + guestStay?.affiliation?.crew : ''
                  }`}
              </Typography>
            </>
          )}
        </div>
        <div className={styles.date}>
          <DateDisplay className={styles.dateValue} variant="body2" date={guestStay?.checkInDate} />
        </div>
        <div className={styles.date}>
          <DateDisplay
            className={styles.dateValue}
            variant="body2"
            date={guestStay?.checkOutDate}
          />
        </div>
        <RoomSchedule
          startDate={startDate}
          view={view}
          oddRow={oddRow}
          room={room}
          checkInDate={guestStay?.checkInDate}
          checkOutDate={guestStay?.checkOutDate}
        />
      </div>
    );
  },
  areEqual
);

type CustomScrollbarProps = ScrollbarProps & {
  forwardedRef: React.Ref<Scrollbars>;
};

function CustomScrollbar({ onScroll, forwardedRef, style, children }: CustomScrollbarProps) {
  const refSetter = useCallback(
    scrollbarsRef => {
      if (forwardedRef && typeof forwardedRef === 'function') {
        if (scrollbarsRef) {
          forwardedRef(scrollbarsRef.view);
        } else {
          forwardedRef(null);
        }
      }
    },
    [forwardedRef]
  );

  return (
    <Scrollbars ref={refSetter} style={{ ...style, overflow: 'hidden' }} onScroll={onScroll}>
      {children}
    </Scrollbars>
  );
}

const CustomScrollbarsVirtualList = React.forwardRef<Scrollbars, CustomScrollbarProps>(
  (props, ref) => <CustomScrollbar {...props} forwardedRef={ref} />
);
