import React from 'react';
import { CurrencyDisplay } from '@lib/common';
import { PropertyAmenity } from '@lib/state';
import { RoomRatePlan } from 'app/state';

export const RoomRateAmenitiesColumns = (propertyAmenities: Array<PropertyAmenity>) => {
  return propertyAmenities.map(m => ({
    title: m.name,
    valueFactory: (plan?: RoomRatePlan) => {
      if (!plan?.amenities) return '--';

      const planAmenities = plan.amenities.filter(f => f.code === m.code);
      if (planAmenities.length > 0) {
        return <CurrencyDisplay value={planAmenities[0].fee} key={m.code} />;
      }
      return '--';
    },
  }));
};
