import React from 'react';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  onExport: () => void;
}

export const ExportButton: React.FC<Props> = ({ onExport }) => {
  return (
    <Button
      onClick={() => onExport()}
      variant="text"
      color="primary"
      size="small"
      startIcon={<FontAwesomeIcon icon="download" />}
    >
      Export
    </Button>
  );
};
