import { persistState, akitaDevtools } from '@datorama/akita';

export function configureStores(dev: boolean = true) {
  if (dev) {
    akitaDevtools();
  }

  persistState({
    include: ['session'],
  });
}
