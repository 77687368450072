import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  name: string;
  accept: string;
  disabled?: boolean;
  required?: boolean;
}

export const FormFileField: React.FC<Props> = ({ name, accept, disabled, required }) => {
  const { register } = useFormContext();
  const [filename, setFilename] = useState<string | undefined>();

  return (
    <Box display="flex" alignItems="center">
      <Box mr={2} clone>
        <Button
          variant="contained"
          color="primary"
          component="label"
          disabled={disabled}
          startIcon={<FontAwesomeIcon icon="file" />}
        >
          Select File
          <input
            ref={register({ required })}
            type="file"
            name={name}
            accept={accept}
            style={{ display: 'none' }}
            onChange={e => setFilename(e.target.files?.[0].name)}
          />
        </Button>
      </Box>
      {filename && <Typography>{filename}</Typography>}
    </Box>
  );
};
