import { Reservations } from '@lib/state/api';

export type CorporateInvoice = Reservations.InvoiceModel;
export type InvoiceType = Reservations.InvoiceType;
export function getHumanizeInvoiceType(type: InvoiceType) {
  switch (type) {
    case Reservations.InvoiceType.TaxCreditMemo:
      return 'Tax Credit Memo';
    default:
      return 'Room Invoice';
  }
}
