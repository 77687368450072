import { GuestProfile, guestPictureSize } from '@lib/state';
import { Box, Link, BoxProps, createStyles, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Webcam from 'react-webcam';
import { GuestPictureDisplay } from '..';

const useStyles = makeStyles(theme =>
  createStyles({
    link: {
      cursor: 'pointer',
      textAlign: 'center',
    },
    displayBox: {
      textAlign: 'center',
      padding: theme.spacing(1),
    },
  })
);

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';

const videoConstraints = {
  facingMode: FACING_MODE_USER,
};

interface Props extends BoxProps {
  name: string;
  guest?: GuestProfile | null;
  defaultToRearCamera: boolean;
}

export const GuestPicture: React.FC<Props> = ({ guest, name, defaultToRearCamera, ...props }) => {
  const styles = useStyles();
  const [data, setData] = useState<string | null>(null);
  const [allowCapture, setAllowCapture] = useState(false);
  const [facingMode, setFacingMode] = React.useState(
    defaultToRearCamera ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
  );

  const [deviceCount, setDeviceCount] = React.useState(0);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((x: any) => {
      setDeviceCount(x.filter((y: any) => y.kind === 'videoinput').length);
    });
  }, []);

  const { setValue, register, unregister } = useFormContext();

  useEffect(() => {
    const validate = (): boolean => {
      return !allowCapture;
    };
    register(
      { name: name },
      {
        validate,
      }
    );
    return () => {
      unregister(name);
    };
  }, []);

  useEffect(() => {
    if (!!guest?.userId) {
      setAllowCapture(false);
    }
    // clear new captured picture if guest is changed
    setData(null);
  }, [guest]);

  const webcamRef = React.useRef<Webcam>(null);
  const capture = useCallback(() => {
    if (webcamRef.current) {
      const val = webcamRef.current.getScreenshot();
      setValue(name, val);
      setData(val);
      setAllowCapture(false);
    }
  }, [webcamRef]);

  const toggleCamera = useCallback(() => {
    setFacingMode(prevState =>
      prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    );
  }, []);

  return (
    <>
      {!allowCapture && (
        <>
          <GuestPictureDisplay {...props} data={data} guest={guest} />
          <Box className={styles.displayBox}>
            <Link
              onClick={() => {
                setAllowCapture(true);
              }}
            >
              Change
            </Link>
          </Box>
        </>
      )}
      {allowCapture && (
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box {...props}>
              <Webcam
                ref={webcamRef}
                audio={false}
                screenshotFormat="image/jpeg"
                forceScreenshotSourceSize
                height={guestPictureSize}
                width={guestPictureSize}
                videoConstraints={{ ...videoConstraints, facingMode, width: 500, height: 500 }}
              />
            </Box>
          </Box>
          <Box>
            <Box className={styles.link}>
              <Link
                onClick={() => {
                  capture();
                }}
              >
                Capture
              </Link>
            </Box>
            <Box
              className={styles.link}
              onClick={() => {
                setAllowCapture(false);
              }}
            >
              <Link>Cancel</Link>
            </Box>
          </Box>
          {deviceCount > 1 && (
            <Box className={styles.link} onClick={toggleCamera}>
              <Link>Switch Camera</Link>
            </Box>
          )}
        </>
      )}
    </>
  );
};
