import React from 'react';
import { responsiveFontSizes, createTheme, createStyles, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppColors } from './theme';

export enum KioskColors {
  ViewBackground = '#FAFAFA',
  MediumBlue = '#0050A8',
  KioskText = '#2B282D',
  KioskRed = '#EC2558',
  KioskSecondary = 'rgba(164,170,177,0.6)',
  KioskDisabled = '#A4AAB1',
  Divider = 'rgba(164,170,177,0.36)',
  KioskHover = 'rgba(0,193,255,0.6)',
}

export const guestKioskTheme = responsiveFontSizes(
  createTheme({
    palette: {
      background: {
        default: KioskColors.ViewBackground,
      },
      primary: {
        main: AppColors.LightBlue,
        dark: KioskColors.Divider,
        contrastText: KioskColors.KioskText,
      },
      secondary: {
        main: AppColors.LightBlue,
        contrastText: KioskColors.KioskText,
      },
      text: {
        primary: KioskColors.KioskText,
        secondary: KioskColors.KioskSecondary,
        hint: KioskColors.KioskSecondary,
      },
      action: {
        active: AppColors.LightBlue,
        disabledBackground: KioskColors.KioskDisabled,
        disabled: KioskColors.ViewBackground,
      },
    },
    shape: {
      borderRadius: 12,
    },
    tapeChart: {
      headerSpacingTop: '2rem',
      rowHeight: 48,
    },
    loadingIndicator: {
      dotSize: 20,
    },
    mixins: {
      toolbar: {
        minHeight: 0,
      },
    },
    typography: {
      h1: {
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 'bold',
        fontSize: '3rem',
      },
      h2: {
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 400,
        fontSize: '3rem',
      },
      h3: {
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 600,
        fontSize: '2.5rem',
      },
      h4: {
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 400,
        fontSize: '2rem',
      },
      h5: {
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 'bold',
        fontSize: '2rem',
      },
      body1: {
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 400,
        fontSize: '1rem',
      },
      subtitle2: {
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: '1rem',
      },
      button: {
        color: KioskColors.ViewBackground,
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 600,
        textTransform: 'none',
        fontSize: '2rem !important',
      },
    },
    props: {
      MuiAlert: {
        iconMapping: {
          success: <FontAwesomeIcon icon="check-circle" />,
          info: <FontAwesomeIcon icon="info-circle" />,
          warning: <FontAwesomeIcon icon="exclamation-circle" />,
          error: <FontAwesomeIcon icon="times-circle" />,
        },
      },
      MuiContainer: {
        disableGutters: true,
      },
    },
    overrides: {
      MuiContainer: {
        root: {
          border: 'none !important',
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: KioskColors.KioskText,
        },
      },
      MuiInput: {
        root: {
          color: KioskColors.KioskText,
          caretColor: AppColors.LightBlue,
          fontFamily: '"Roboto","Helvetica",sans-serif',
          fontWeight: 400,
          fontSize: '2rem',
          paddingBottom: '0.3em',
        },
        underline: {
          '&$focused': {
            borderBottomColor: KioskColors.KioskText,
          },
          '&:before': {
            borderBottomColor: 'rgba(164, 170, 177, 0.72) !important',
          },
          '&:after': {
            borderBottomColor: 'rgba(164, 170, 177, 0.72) !important',
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottomColor: 'rgba(164, 170, 177, 0.72)',
          },
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: '2rem',
          color: KioskColors.KioskSecondary,
          '&$focused': {
            color: KioskColors.KioskSecondary,
          },
          '&$error': {
            color: AppColors.Red,
          },
        },
        shrink: {
          fontSize: '1.5em',
          fontWeight: 600,
          color: KioskColors.KioskSecondary,
        },
        filled: {
          color: KioskColors.KioskText,
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: KioskColors.ViewBackground,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: KioskColors.Divider,
        },
      },
      MuiButton: {
        root: {
          color: KioskColors.ViewBackground,
          backgroundColor: AppColors.LightBlue,
          '&$disabled': {
            color: KioskColors.ViewBackground,
            backgroundColor: KioskColors.KioskDisabled,
          },
        },
        textSecondary: {
          color: AppColors.LightBlue,
        },
        containedPrimary: {
          color: KioskColors.ViewBackground,
        },
        containedSecondary: {
          color: KioskColors.ViewBackground,
          backgroundColor: AppColors.LightBlue,
          '&:hover': {
            color: KioskColors.ViewBackground,
            backgroundColor: KioskColors.KioskHover + ' !important',
          },
        },
        outlinedSecondary: {
          color: KioskColors.KioskRed,
          backgroundColor: 'transparent',
          borderColor: KioskColors.KioskRed,
        },
      },
      MuiCheckbox: {
        root: {
          color: KioskColors.KioskText,
          '&$checked': {
            color: AppColors.LightBlue,
          },
        },
        colorSecondary: {
          '&$checked': {
            color: AppColors.LightBlue,
          },
        },
      },
      MuiDialogContentText: {
        root: {
          color: KioskColors.KioskText,
        },
      },
      MuiSelect: {
        selectMenu: {
          height: 0,
        },
      },
    },
  })
);

export const kioskStyles = makeStyles(theme =>
  createStyles({
    heroHighlight: {
      textTransform: 'uppercase',
      color: KioskColors.ViewBackground,
      backgroundColor: 'rgba(18, 38, 58, 0.87)',
      marginTop: '54px',
      marginLeft: '52px',
      marginRight: '52px',
      padding: '0.5em',
      borderRadius: '0.25em',
    },
    title: {
      color: KioskColors.KioskText,
    },
    bodyText: {
      color: KioskColors.KioskText,
    },
    blueText: {
      color: AppColors.Blue,
    },
    primaryKioskButton: {
      color: AppColors.LightBlue,
      paddingTop: '1.5em !important',
      paddingBottom: '1.5em !important',
      '& span': {
        marginBottom: '1em',
      },
    },
    secondaryKioskButton: {
      color: AppColors.LightBlue,
      paddingTop: '1.5em !important',
      paddingBottom: '1.5em !important',
      '& span': {
        marginBottom: '1em',
      },
    },
    disabledKioskButton: {
      color: AppColors.Gray,
      paddingTop: '1.5em !important',
      paddingBottom: '1.5em !important',
      '& span': {
        marginBottom: '1em',
      },
    },
    tile: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    hintText: {
      color: KioskColors.KioskText,
      paddingTop: '7em !important',
      marginBottom: '54px',
    },
    kioskForm: {
      color: KioskColors.KioskText,
      padding: '0 7.5em !important',
    },
    formInstructions: {
      paddingTop: '2em !important',
      marginBottom: '54px',
    },
    listItem: {
      backgroundColor: AppColors.White,
      color: KioskColors.KioskText,
      margin: '2em 7.5em 1em !important',
      borderRadius: '0.5em',
      padding: '1.5em',
      boxShadow: '3px 3px 9px rgba(0,0,0,0.18)',
    },
    containedListItem: {
      backgroundColor: AppColors.White,
      color: KioskColors.KioskText,
      borderRadius: '0.5em',
      padding: '1.5em',
      boxShadow: '3px 3px 9px rgba(0,0,0,0.18)',
    },
    listItemButton: {
      backgroundColor: AppColors.LightBlue,
      color: AppColors.White,
    },
    kioskTextButton: {
      backgroundColor: AppColors.LightBlue,
      color: KioskColors.ViewBackground,
    },
    kioskSecondaryTextButton: {
      background: 'none',
      color: AppColors.LightBlue,
    },
    kioskOutlinesButton: {
      background: 'none',
      color: AppColors.LightBlue,
      borderWidth: '1px',
      borderRadius: '0.25em',
      borderColor: AppColors.LightBlue,
    },
    listContentBlock: {
      margin: '2em 8.5em 1em !important',
    },
    subtitleValue: {
      color: AppColors.Blue,
    },
    internalRow: {
      display: 'flex',
    },
    internalColumn: {
      flex: '1',
      padding: '20px 0',
    },
    internalColumnDouble: {
      flex: '2',
      padding: '20px 0',
    },
    block: {
      display: 'block',
      width: '100%',
    },
    xlText: {
      fontSize: '4em',
      fontWeight: 500,
    },
  })
);
