import React from 'react';
import {
  Button,
  Checkbox,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfDay, startOfToday } from 'date-fns';

import { FilterSection, DownloadButton } from 'app/shared';
import { TicketStatus, PriorityOptions } from 'app/state';
import { Properties } from '@lib/state';
import { NameDisplay } from '@lib/common';

const useStyles = makeStyles(theme => ({
  downloadButtonContainer: {
    marginLeft: 'auto',
  },
  button: {
    marginLeft: theme.spacing(2),
  },
}));

interface MaintenanceTicketFilterProps {
  startDate?: Date;
  endDate?: Date;
  status?: TicketStatus;
  assignedTo?: string;
  priority?: PriorityOptions;
  active?: boolean;
  textFilter?: string;
  setStartDate: (startDate?: Date) => void;
  setEndDate: (endDate?: Date) => void;
  setStatus: (status?: TicketStatus) => void;
  setPriority: (priority?: PriorityOptions) => void;
  onExport: (
    startDate?: Date,
    endDate?: Date,
    status?: TicketStatus,
    priority?: PriorityOptions
  ) => void;
  setAssignedTo: (assignedTo?: string) => void;
  setTextFilter: (textFilter?: string) => void;
  onCreateTicket: () => void;
  setActive: () => void;
  employees: Properties.EmployeeModel[];
}

export const MaintenanceTicketFilters: React.FC<MaintenanceTicketFilterProps> = ({
  startDate,
  endDate,
  status,
  priority,
  active,
  textFilter,
  setStartDate,
  setEndDate,
  setStatus,
  setPriority,
  onExport,
  setAssignedTo,
  setTextFilter,
  onCreateTicket,
  setActive,
  employees,
  assignedTo,
}) => {
  const { downloadButtonContainer, button } = useStyles();

  const onTicketStatusChange = (value: any) => {
    switch (value) {
      case 'Open':
        setStatus(TicketStatus.Open);
        break;
      case 'Closed':
        setStatus(TicketStatus.Closed);
        break;
      case 'Incomplete':
        setStatus(TicketStatus.Incomplete);
        break;
      case 'Completed':
        setStatus(TicketStatus.Completed);
        break;
      default:
        setStatus(undefined);
    }
  };

  const onPriorityChange = (value: any) => {
    switch (value) {
      case 'Normal':
        setPriority(PriorityOptions.Normal);
        break;
      case 'Urgent':
        setPriority(PriorityOptions.Urgent);
        break;
      case 'Emergency':
        setPriority(PriorityOptions.Emergency);
        break;
      default:
        setPriority(undefined);
    }
  };

  return (
    <FilterSection>
      <Grid container spacing={1}>
        <Grid>
          <Tooltip title={<h2>Search for ticket number, room number, or notes.</h2>}>
            <TextField
              label="Search"
              name="textFilter"
              defaultValue={textFilter ?? null}
              onChange={e => setTextFilter(e.target.value.length > 0 ? e.target.value : undefined)}
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <InputLabel>Status</InputLabel>
          <Select
            name="status"
            value={status ?? 'All'}
            onChange={e => onTicketStatusChange(e.target.value)}
            color="primary"
            fullWidth
          >
            <MenuItem value={'All'}>All</MenuItem>
            <MenuItem value={TicketStatus.Open}>Open</MenuItem>
            <MenuItem value={TicketStatus.Incomplete}>Incomplete</MenuItem>
            <MenuItem value={TicketStatus.Completed}>Completed</MenuItem>
            <MenuItem value={TicketStatus.Closed}>Closed</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2}>
          <InputLabel>Priority</InputLabel>
          <Select
            name="priority"
            value={priority ?? 'All'}
            onChange={e => onPriorityChange(e.target.value)}
            color="primary"
            fullWidth
          >
            <MenuItem value={'All'}>All</MenuItem>
            <MenuItem value={PriorityOptions.Normal}>Normal</MenuItem>
            <MenuItem value={PriorityOptions.Urgent}>Urgent</MenuItem>
            <MenuItem value={PriorityOptions.Emergency}>Emergency</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            name="start"
            label="Start Date"
            format="MMMM d"
            value={startDate ?? null}
            onChange={e => setStartDate(e ? startOfDay(e.valueOf()) : undefined)}
            color="primary"
            InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
            size="small"
            clearable
            fullWidth
            autoOk
            maxDate={startOfToday()}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            name="end"
            label="End Date"
            format="MMMM d"
            value={endDate ?? null}
            onChange={e => setEndDate(e ? startOfDay(e.valueOf()) : undefined)}
            color="primary"
            InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
            size="small"
            clearable
            fullWidth
            autoOk
            maxDate={startOfToday()}
          />
        </Grid>
        <Grid item xs={2}>
          <InputLabel>Assigned To</InputLabel>
          <Select
            name="assignedTo"
            value={assignedTo ?? 'All'}
            onChange={e =>
              setAssignedTo(e.target.value === 'All' ? undefined : (e.target.value as string))
            }
            color="primary"
            fullWidth
          >
            <MenuItem value={'All'}>All</MenuItem>
            {employees.map(employee => (
              <MenuItem key={employee.userId} value={employee.userId} dense>
                {<NameDisplay {...employee.name} directory disableTypography />}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={2}>
          <InputLabel>Active</InputLabel>
          <Checkbox defaultChecked={active} aria-label="Active" onClick={setActive} />
        </Grid>
        <Grid className={downloadButtonContainer} item>
          <DownloadButton onDownload={() => onExport(startDate, endDate, status, priority)} />
          <Button
            className={button}
            variant="contained"
            color="primary"
            onClick={() => {
              onCreateTicket();
            }}
          >
            Create Ticket
          </Button>
        </Grid>
      </Grid>
    </FilterSection>
  );
};
