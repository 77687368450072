import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MaintenanceTicketJournal } from './maintenance-journal.model';

export interface MaintenanceTicketJournalState
  extends EntityState<MaintenanceTicketJournal, string> {
  filter: string;
}

@StoreConfig({ name: 'maintenance-tickets-journal' })
export class MaintenanceTicketJournalStore extends EntityStore<MaintenanceTicketJournalState> {
  constructor() {
    super({ filter: 'ALL' });
  }
}

const maintenanceTicketJournalStore: MaintenanceTicketJournalStore = new MaintenanceTicketJournalStore();
export default maintenanceTicketJournalStore;
