import React, { ReactNode, useEffect, useMemo } from 'react';
import { useFormContext, ValidationOptions } from 'react-hook-form';
import { TimePicker, TimePickerProps as MuiTimePickerProps } from '@material-ui/pickers';
import { lightFormat, parse, startOfMinute } from 'date-fns';

import { useFieldError } from './form-methods';

type OmittedProps = 'helperText';

export type TimePickerProps<TOmit extends keyof MuiTimePickerProps = never> = Omit<
  MuiTimePickerProps,
  OmittedProps | TOmit
>;

interface FormTimePickerProps {
  name: string;
  validationOptions?: ValidationOptions;
  helperText?: ReactNode | ((errors: any) => string);
}

function now() {
  return startOfMinute(new Date());
}

export const FormTimePicker: React.FC<
  FormTimePickerProps & TimePickerProps<'value' | 'onChange' | 'onFocus' | 'onBlur'>
> = ({
  name,
  validationOptions,
  defaultValue,
  helperText,
  label = 'Time',
  error,
  InputLabelProps,
  clearable,
  ...props
}) => {
  const { errors, register, unregister, setValue, watch } = useFormContext();
  const fieldError = useFieldError(name, errors);

  useEffect(() => {
    register(name, validationOptions);
    return () => unregister(name);
  }, [validationOptions]);

  const time = watch(name, defaultValue);

  const value = useMemo(() => {
    if (time) return parse(time, 'HH:mm:ss', now());
    if (!clearable) now();
    return null;
  }, [time]);

  // needed to set the default value in the form context
  useEffect(() => {
    if (defaultValue) {
      const dateTime = parse(defaultValue as string, 'HH:mm:ss', now());
      setValue(name, lightFormat(startOfMinute(dateTime), 'HH:mm:ss'), true);
    }
  }, [defaultValue]);

  return (
    <TimePicker
      name={name}
      value={value}
      label={InputLabelProps?.prefix ? `${InputLabelProps.prefix} ${label}` : label}
      onChange={e => {
        setValue(name, e ? lightFormat(startOfMinute(e), 'HH:mm:ss') : '', true);
      }}
      error={!!fieldError}
      {...props}
      clearable={clearable}
    />
  );
};
