import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';

import { Loading } from '../components';

type Props = React.PropsWithChildren<ButtonProps & { pending?: boolean }>;

export const SubmitButton: React.FC<Props> = ({ pending, children, className, ...rest }) => {
  return (
    <Button type="submit" {...rest}>
      {(pending && <Loading />) || children}
    </Button>
  );
};
