import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { accountService, AccountForms } from '@lib/state';
import { useFormEvents, VerifiedEmailConfirmation } from '@lib/common';
import { Container } from '@material-ui/core';

export const VerifyEmailPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email') || '';
  const token = queryParams.get('token') || '';
  const history = useHistory();
  if (!email || !token) {
    history.push('/');
  }

  const [result] = useFormEvents(AccountForms.VerifyEmail);
  useEffect(() => {
    accountService.verifyEmail(email, token);
  }, [email, token]);

  return (
    <Container maxWidth="md">
      <VerifiedEmailConfirmation result={result} />
    </Container>
  );
};
