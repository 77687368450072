import React from 'react';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';
import { ManageInventoryAdjustmentsPage } from './pages';

export const InventoryAdjustmentRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={match.path} exact component={ManageInventoryAdjustmentsPage} />
    <Redirect to={match.path} />
  </Switch>
);
