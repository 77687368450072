import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, FormControlProps } from '@material-ui/core';

interface FormCheckboxGroupProps extends Omit<FormControlProps, 'error'> {
  name: string;
  label: React.ReactNode;
  options: Array<string>;
  defaultValues?: Array<string>;
  defaultChecked?: boolean;
  helperText?: string | ((errors: any) => string);
}

export const CheckboxesGroup: React.FC<FormCheckboxGroupProps> = ({
  name,
  options,
  defaultValues = [],
}) => {
  const { control, getValues } = useFormContext();
  const [checkedValues, setCheckedValues] = useState(defaultValues);

  return (
    <>
      {options.map(option => (
        <FormControlLabel
          control={
            <Controller
              name={name}
              value={option}
              checked={checkedValues.includes(option)}
              as={Checkbox}
              onChange={() => {
                const currentVals = (getValues(name) as Array<string>) ?? [];
                const data =
                  currentVals.indexOf(option) === -1
                    ? [...currentVals, option]
                    : currentVals.filter(x => x !== option);
                setCheckedValues(data);
                return data;
              }}
              control={control}
            />
          }
          key={option}
          label={option}
        />
      ))}
    </>
  );
};
