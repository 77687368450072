import { Query } from '@datorama/akita';

import { GuestProfileState, guestProfileStore } from './guest-profile.store';

export class GuestProfileQuery extends Query<GuestProfileState> {
  guest = this.select(x => x.guest);

  hasAcount = this.select(x => x.hasAccount);

  isDoNotDisturbOn = this.select(x => x.isDoNotDisturbOn);
}

export const guestProfileQuery = new GuestProfileQuery(guestProfileStore);
