import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { PaginationState } from '@lib/state';
import { Area, AreaFilters } from './area.model';

export interface AreaState extends EntityState<Area>, ActiveState {
  ui: AreaFilters;
  pagination: PaginationState;
}

@StoreConfig({ name: 'areas', resettable: true })
export class AreaStore extends EntityStore<AreaState> {
  constructor() {
    super({
      ui: { name: '' },
      pagination: {
        isDone: true,
        continuationToken: undefined,
      },
    });
  }
}

export const areaStore = new AreaStore();
