import { KeySelectorFn } from './group-by';

// default sorting behavior of javascript's Array.sort()
function defaultCompareFn<T>(a: T, b: T): number {
  const aStr = String(a);
  const bStr = String(b);

  if (aStr < bStr) return -1;
  if (aStr > bStr) return 1;
  return 0;
}

export function* orderBy<T, TKey>(
  collection: Iterable<T>,
  keySelector: KeySelectorFn<T, TKey>,
  compareFn: (a: TKey, b: TKey) => number = defaultCompareFn
): Iterable<T> {
  const items = Array.from(collection);

  const order = items
    .map((item, index) => ({ index, key: keySelector(item) }))
    .sort((a, b) => compareFn(a.key, b.key));

  for (const { index } of order) {
    yield items[index];
  }
}

export function* orderByDesc<T, TKey>(
  collection: Iterable<T>,
  keySelector: KeySelectorFn<T, TKey>,
  compareFn: (a: TKey, b: TKey) => number = defaultCompareFn
): Iterable<T> {
  const items = Array.from(collection);

  const order = items
    .map((item, index) => ({ index, key: keySelector(item) }))
    .sort((a, b) => compareFn(a.key, b.key))
    .reverse();

  for (const { index } of order) {
    yield items[index];
  }
}
