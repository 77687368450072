import { EntityStore, StoreConfig, EntityState, ActiveState } from '@datorama/akita';

import { PaginationState } from '../models.common';
import { CorporateEmployee } from './corporate-employee.model';

export interface CorporateEmployeeUIState {
  accountId: string;
  employeeNumber?: string;
}

export interface CorporateEmployeeState
  extends EntityState<CorporateEmployee, CorporateEmployee['employeeNumber']>,
    ActiveState<CorporateEmployee['employeeNumber']> {
  ui: CorporateEmployeeUIState;
  pagination: PaginationState;
}

@StoreConfig({ name: 'corporate-employee', resettable: true, idKey: 'employeeNumber' })
export class CorporateEmployeeStore extends EntityStore<CorporateEmployeeState> {
  constructor() {
    super({
      ui: {
        accountId: '',
        employeeNumber: '',
      },
      pagination: {
        isDone: true,
        continuationToken: undefined,
      },
    });
  }
}

export const corporateEmployeeStore = new CorporateEmployeeStore();
