import React from 'react';

import { FormTextField, FormTextFieldProps, TextFieldProps } from '../form-text-field';

type Props = Omit<FormTextFieldProps, 'validationOptions' | 'type'> & TextFieldProps;

export const FormZipField: React.FC<Props> = ({
  autoComplete = 'postal-code',
  label = 'Zip',
  required,
  ...props
}) => {
  return (
    <FormTextField
      {...props}
      type="text"
      autoComplete={autoComplete}
      label={label}
      validationOptions={{ required, maxLength: 20 }}
      data-testid="formZipInput"
    />
  );
};
