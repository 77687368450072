import { EntityStore, StoreConfig, EntityState } from '@datorama/akita';
import { Housekeeper } from './house-keeper.model';
import { Property } from '../property';

export interface HousekeeperState extends EntityState<Housekeeper, Housekeeper['userId']> {}

export interface HousekeeperUIState {
  propertyId?: Property['id'];
}
export interface HousekeeperState extends EntityState<Housekeeper, Housekeeper['userId']> {
  ui: HousekeeperUIState;
}

@StoreConfig({ name: 'housekeepers', resettable: true, idKey: 'userId' })
export class HousekeeperStore extends EntityStore<HousekeeperState> {
  constructor() {
    super({ ui: {} });
  }
}

export const housekeeperStore = new HousekeeperStore();
