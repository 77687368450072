import React, { useEffect } from 'react';
import { AppConfig } from '@lib/state';
import TagManager from 'react-gtm-module';

const gtmId = AppConfig.Google.TagManagerId;

// package https://www.npmjs.com/package/react-gtm-module
export const useGoogleTagManager = (
  tagManagerArgs: any = {
    gtmId: gtmId,
  }
) => {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, [tagManagerArgs]);
};

export const TagManagerContext = React.createContext({ initialized: true });
