import React, { useCallback } from 'react';
import {
  PropertyConfigurationForms,
  propertyConfigurationService,
  PropertyConfiguration,
} from 'app/state';
import {
  PropertyConfigurationForm,
  PropertyConfigurationFormFields,
} from './property-configuration.form';
import { FormHub, useFormEvents } from '@lib/common';

interface Props {
  property: PropertyConfiguration;
}

export const ManagePropertyConfigurationComponent: React.FC<Props> = ({ property }) => {
  const [{ status, error }, reset] = useFormEvents(PropertyConfigurationForms.Update);

  const submit = useCallback(
    ({
      name,
      propertyCode,
      location,
      contact,
      groupBooking,
      taxRates,
      salesTaxRates,
      backOfficeEmail,
      reportEmail,
      maintenanceEmail,
      maintenancePhone,
      checkIn,
      checkOut,
      earlyCheckIn,
      lateCheckOut,
      changeableThreshold,
      cancelableThreshold,
      taxCreditPeriod,
      features,
    }: PropertyConfigurationFormFields) => {
      propertyConfigurationService.updateProperty(property.id, {
        name,
        propertyCode,
        location: location,
        contact,
        groupBooking,
        taxRates,
        salesTaxRates,
        backOfficeEmail,
        reportEmail,
        maintenanceEmail,
        maintenancePhone,
        checkIn,
        checkOut,
        earlyCheckIn,
        lateCheckOut,
        changeableThreshold,
        cancelableThreshold,
        taxCreditPeriod,
        features: Object.entries(features)
          .filter(([_, value]) => value)
          .map(([key]) => key),
      });
    },
    [property]
  );

  return (
    <FormHub<PropertyConfigurationFormFields>
      formOptions={{
        defaultValues: {
          name: property.name,
          propertyCode: property.propertyCode,
          contact: property.contact,
          location: property.location,
          groupBooking: property.groupBooking,
          backOfficeEmail: property.backOfficeEmail ?? '',
          reportEmail: property.reportEmail ?? '',
          maintenanceEmail: property.maintenanceEmail ?? '',
          maintenancePhone: property.maintenancePhone ?? '',
          checkIn: property.checkIn,
          checkOut: property.checkOut,
          earlyCheckIn: property.earlyCheckIn,
          lateCheckOut: property.lateCheckOut,
          changeableThreshold: property.changeableThreshold,
          cancelableThreshold: property.cancelableThreshold,
          taxRates: property.taxRates,
          taxCreditPeriod: property.taxCreditPeriod ?? 0,
          features:
            property.features?.reduce((r, flag) => {
              r[flag] = true;
              return r;
            }, {} as PropertyConfigurationFormFields['features']) ?? {},
        },
      }}
      onSubmit={submit}
    >
      <PropertyConfigurationForm
        property={property}
        status={status}
        error={error}
        resetFormUI={reset}
      />
    </FormHub>
  );
};
