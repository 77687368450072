import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Button, { ButtonProps } from '@material-ui/core/Button';
import MatLink, { LinkProps as MatLinkProps } from '@material-ui/core/Link';

export const Link: React.FC<MatLinkProps & RouterLinkProps> = ({ ...props }) => (
  <MatLink component={RouterLink} {...props} />
);

export const ButtonLink: React.FC<ButtonProps & RouterLinkProps> = ({ ...props }) => (
  <Button component={RouterLink} {...props} />
);
