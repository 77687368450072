import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop: React.FC = () => {
  const { pathname, key } = useLocation();
  const priorPath = useRef<string>();

  useEffect(() => {
    // if pathname has changed (i.e. new page), scroll without animation
    // .. otherwise, scroll smoothly up to the top of the current page
    const behavior = !!priorPath.current && priorPath.current !== pathname ? undefined : 'smooth';

    window.scrollTo({
      top: 0,
      left: 0,
      behavior,
    });

    priorPath.current = pathname;

    // key updates even on navigation to the same path
  }, [pathname, key]);

  return null;
};
