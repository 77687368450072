import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  content: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
}));

export const CenteredLayout: React.FC = ({ children }) => {
  const styles = useStyles();

  return <Box className={styles.content}>{children}</Box>;
};
