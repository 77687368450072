import { applyTransaction } from '@datorama/akita';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { lightFormat } from 'date-fns';
import { Overwrite } from 'utility-types';

import { Reservations, RequireOnly, dispatchForm, CommonForm } from '@lib/state';
import {
  RoomRatePlanStore,
  RoomRatePlanUIState,
  roomRatePlanStore,
  RoomRatePlanExportState,
} from './room-rate-plan.store';
import { RatePlanSort, RoomRatePlan, mapRoomRatePlan } from './room-rate-plan.model';
import { RoomRatePlanForms } from './room-rate-plan.forms';
import { AdditionalProperty } from '@lib/state/api/generated/reservations';

type CreateRatePlanRequest = Overwrite<
  Reservations.UpsertRatePlanRequest,
  { startDate: Date; endDate: Date }
>;

export class RoomRatePlanService {
  constructor(
    private readonly store: RoomRatePlanStore,
    private readonly ratePlanApi: Reservations.RatePlanApi
  ) {}

  getRatePlans({
    propertyId,
    roomTypeId = null,
    showDisabled = false,
    corporateAccountId = null,
    ratePlanSort = RatePlanSort.Priority,
  }: RequireOnly<RoomRatePlanUIState, 'propertyId'>) {
    from(
      this.ratePlanApi.rateplanGet(
        propertyId,
        roomTypeId ?? undefined,
        showDisabled,
        corporateAccountId ?? undefined
      )
    )
      .pipe(map(({ data }) => data.map(mapRoomRatePlan)))
      .subscribe(ratePlans =>
        applyTransaction(() => {
          this.store.upsertMany(ratePlans);
          this.store.update({
            ui: { propertyId, roomTypeId, showDisabled, corporateAccountId, ratePlanSort },
          });
        })
      );
  }

  getRatePlansByCorporateId(corporateAccountId: string) {
    from(this.ratePlanApi.rateplanCorporateGet(corporateAccountId))
      .pipe(map(({ data }) => data.map(mapRoomRatePlan)))
      .subscribe(ratePlans =>
        applyTransaction(() => {
          this.store.upsertMany(ratePlans);
          this.updateUI({ corporateAccountId });
        })
      );
  }

  exportRatePlans({
    propertyId,
    roomTypeId = null,
    showDisabled = false,
    corporateAccountId = null,
    roomTypeName,
    ratePlanSort = RatePlanSort.Priority,
  }: RequireOnly<RoomRatePlanExportState, 'propertyId'>) {
    from(
      this.ratePlanApi.rateplanExportGet(
        propertyId,
        roomTypeName,
        roomTypeId ?? undefined,
        showDisabled,
        corporateAccountId ?? undefined,
        ratePlanSort
      )
    )
      .pipe(
        map(x => new Blob([x.data], { type: 'text/csv' })),
        dispatchForm(CommonForm.Export)
      )
      .subscribe(x => saveAs(x, `roomRatePlans${new Date().valueOf()}.csv`));
  }

  getRatePlanById(ratePlanId: RoomRatePlan['id']) {
    from(this.ratePlanApi.rateplanIdGet(ratePlanId))
      .pipe(map(({ data }) => mapRoomRatePlan(data)))
      .subscribe(plan =>
        applyTransaction(() => {
          this.store.upsert(ratePlanId, plan);
          this.store.setActive(ratePlanId);
        })
      );
  }

  getRatePlanHistory(ratePlanId: RoomRatePlan['id']) {
    from(this.ratePlanApi.rateplanIdHistoryGet(ratePlanId))
      .pipe(map(({ data }) => data.map(mapRoomRatePlan)))
      .subscribe(plans => this.store.upsertMany(plans));
  }

  createPlan(request: CreateRatePlanRequest, additionalProperties?: AdditionalProperty[]) {
    from(
      this.ratePlanApi.rateplanPost({
        ...request,
        additionalProperties: additionalProperties,
        startDate: lightFormat(request.startDate, 'yyyy-MM-dd'),
        endDate: lightFormat(request.endDate, 'yyyy-MM-dd'),
      })
    )
      .pipe(
        dispatchForm(RoomRatePlanForms.Create),
        map(({ data }) => mapRoomRatePlan(data))
      )
      .subscribe(plan =>
        applyTransaction(() => {
          this.store.upsert(plan.id, plan);
          this.store.setActive(plan.id);
        })
      );
  }

  updateRatePlan(ratePlan: RoomRatePlan, additionalProperties?: AdditionalProperty[]) {
    from(
      this.ratePlanApi.rateplanIdPost(ratePlan.id, {
        ...ratePlan,
        additionalProperties: additionalProperties,
        startDate: lightFormat(ratePlan.startDate, 'yyyy-MM-dd'),
        endDate: lightFormat(ratePlan.endDate, 'yyyy-MM-dd'),
        corporateAccountId: ratePlan.affiliation?.corporateAccountId,
      })
    )
      .pipe(
        dispatchForm(RoomRatePlanForms.Edit),
        map(({ data }) => mapRoomRatePlan(data))
      )
      .subscribe(plan =>
        applyTransaction(() => {
          this.store.upsert(plan.id, plan);
          this.store.setActive(plan.id);
        })
      );
  }

  disableRatePlan(ratePlan: RoomRatePlan) {
    from(this.ratePlanApi.rateplanIdDelete(ratePlan.id))
      .pipe(map(({ data }) => mapRoomRatePlan(data)))
      .subscribe(plan => this.store.upsert(plan.id, plan));
  }

  enableRatePlan(ratePlan: RoomRatePlan) {
    from(this.ratePlanApi.rateplanIdEnablePost(ratePlan.id))
      .pipe(map(({ data }) => mapRoomRatePlan(data)))
      .subscribe(plan => this.store.upsert(plan.id, plan));
  }

  selectRatePlan(ratePlanId?: RoomRatePlan['id']) {
    this.store.setActive(ratePlanId ?? null);
  }

  private updateUI(values: Partial<RoomRatePlanUIState>) {
    this.store.update(({ ui }) => ({ ui: { ...ui, ...values } }));
  }
}

export const roomRatePlanService = new RoomRatePlanService(
  roomRatePlanStore,
  new Reservations.RatePlanApi()
);
