import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from '@material-ui/core';

import { reservationService, sessionQuery, reservationQuery } from '@lib/state';
import { useObservable, LoadingIndicator } from '@lib/common';
import { ReservationSearchResults } from '../components';
import { startOfYesterday, startOfTomorrow } from 'date-fns';

export const ReservationSearchResultsPage: React.FC<RouteComponentProps> = ({ location }) => {
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const results = useObservable(reservationQuery.searchResultsAvailableForCheckin);
  const isLoading = useObservable(reservationQuery.isLoading);

  useEffect(() => {
    if (!propertyId) return;

    const query = new URLSearchParams(location.search);
    reservationService.getCheckInReservations({
      propertyId,
      firstName: query.get('firstName') ?? undefined,
      lastName: query.get('lastName') ?? undefined,
      start: startOfYesterday(),
      end: startOfTomorrow(),
    });
  }, [propertyId, location.search]);

  if (isLoading) {
    return <LoadingIndicator loadingText="Finding reservations..." fillContainer />;
  }

  return (
    <Container maxWidth="xl">
      <ReservationSearchResults reservations={results} />
    </Container>
  );
};
