import React from 'react';
import { HousekeepingAssignment, Housekeeper, HousekeepingStatusMap } from '@lib/state';
import { makeStyles, createStyles } from '@material-ui/core';
import { lightFormat } from 'date-fns';
import { InlineBadge } from 'app/shared';
import { HousekeepingStatusEnum } from '@lib/state/api/generated/properties';
import { DataTable } from '@lib/common';

interface Props {
  rooms: HousekeepingAssignment[];
  housekeepers: Housekeeper[];
  date: Date;
}

const useStyles = makeStyles(theme =>
  createStyles({
    gridContainer: {
      marginTop: theme.spacing(1),
    },
    footer: {
      backgroundColor: theme.palette.success.main,
    },
    footerCell: {
      color: theme.palette.common.white,
      fontSize: '130%',
    },
  })
);

interface HousekeeperReportRow {
  userId: string;
  service: string;
  name: string;
  assigned: number;
  completed: number;
  totalDuration: number;
}

export const HousekeepingReport: React.FC<Props> = ({ rooms, housekeepers, date }) => {
  let checkoutAssigned = 0;
  let checkoutCompleted = 0;
  let totalAssigned = 0;
  let totalCompleted = 0;
  let totalDuration = 0;
  const styles = useStyles();

  rooms
    .filter(f => !!f.housekeeperUserId)
    .forEach(room => {
      if (
        !!room.assignedTime &&
        room.serviceRequested !== HousekeepingStatusEnum.NoCleaningRequired
      )
        totalAssigned++;

      if (room.serviceRequested === HousekeepingStatusEnum.Checkout) checkoutAssigned++;
      if (room.servicePerformed === HousekeepingStatusEnum.Checkout) checkoutCompleted++;
      if (!!room.endTime) {
        totalCompleted++;
        totalDuration += room.durationInMinutes ?? 0;
      }
    });

  const services = Array.from(HousekeepingStatusMap.keys()).filter(
    status => status !== HousekeepingStatusEnum.NoCleaningRequired
  );

  let newRows: HousekeeperReportRow[] = [];

  newRows = housekeepers.flatMap(housekeeper => {
    const assignments = rooms.filter(
      ({ housekeeperUserId }) => housekeeperUserId === housekeeper.userId
    );
    return services.map(service => {
      const filteredAssignments = assignments.filter(
        ({ serviceRequested }) => serviceRequested === service
      );
      let duration = 0;
      filteredAssignments.forEach(a => {
        duration += a.durationInMinutes ?? 0;
      });

      return {
        userId: housekeeper.userId,
        name: getHousekeeperName(housekeeper),
        service: service,
        assigned: filteredAssignments.length,
        completed: filteredAssignments.filter(x => !!x.endTime).length,
        totalDuration: duration,
      };
    });
  });

  newRows.push(
    ...services.map(service => {
      const filteredAssignments = rooms.filter(
        ({ servicePerformed }) => servicePerformed === service
      );
      return {
        userId: '',
        name: 'Total',
        service: service ?? '',
        assigned: filteredAssignments.length,
        completed: filteredAssignments.filter(x => !!x.endTime).length,
        totalDuration: filteredAssignments.reduce(
          (sum, assignment) => sum + (assignment.durationInMinutes ?? 0),
          0
        ),
      };
    })
  );

  let footerRow: HousekeeperReportRow = {
    userId: '',
    service: '',
    name: 'Total',
    assigned: totalAssigned,
    completed: totalCompleted,
    totalDuration: totalDuration,
  };
  newRows.push(footerRow);

  return (
    <DataTable
      items={newRows.filter(x => x.assigned > 0)}
      getItemKey={i => i.userId + i.service}
      columns={[
        {
          title: `Housekeeper Assignment (${lightFormat(date, 'yyyy-MM-dd')})`,
          valueFactory: item => item.name,
        },
        {
          title: 'Service',
          valueFactory: item => item.service,
        },
        {
          title: 'Assigned',
          valueFactory: item => item.assigned,
        },
        {
          name: 'Completed',
          title: (
            <InlineBadge
              color={checkoutAssigned === checkoutCompleted ? 'primary' : 'error'}
              badgeContent={`C/O: ${checkoutCompleted}/${checkoutAssigned}`}
            >
              Completed
            </InlineBadge>
          ),
          valueFactory: item => item.completed,
        },
        {
          title: 'Avg Clean Time',
          valueFactory: item => getAverage(item.completed, item.totalDuration),
        },
        {
          title: 'Total Clean Time',
          valueFactory: item => item.totalDuration,
        },
      ]}
      tableClass={styles.gridContainer}
      footerClass={styles.footer}
    />
  );
};
function getHousekeeperName(housekeeper: Housekeeper): string {
  return `${housekeeper.name?.last}, ${housekeeper.name?.first}`;
}
function getAverage(completed: number, total: number): number {
  if (completed <= 0) return 0;
  return Math.round(total / completed);
}
