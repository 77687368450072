import { Store, StoreConfig } from '@datorama/akita';
import { Properties } from '../api';
import { Room } from '../rooms';

import { CheckInRequirement } from './check-in.model';

export interface CheckInState {
  requirements: Array<CheckInRequirement> | null;
  rooms: Array<Properties.RoomModel> | null;
  roomOptions?: Array<string>;
}

@StoreConfig({ name: 'check-in', resettable: true })
export class CheckInStore extends Store<CheckInState> {
  constructor() {
    super({
      requirements: null,
      rooms: null,
    });
  }
}

export const checkInStore = new CheckInStore();
