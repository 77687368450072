import { parseISO, isAfter, isBefore, subDays, isWithinInterval } from 'date-fns';
import { isNumber } from 'lodash';
import { ReservationPolicy } from '..';

import { Reservations } from '../api';
import { Fee } from '../models.common';
import { dateFromTimeString } from '../utils';

export type ReservationChangeCostCenterFields = Reservations.ReservationChangeCostCenterFieldsRequest;
export type ReservationRoom = Reservations.ReservationRoomModel;
export type ReservationCharge = Reservations.ReservationChargeModel;
export type ReservationHoldModel = Reservations.ReservationHold & {
  stayDate: Date;
  consumedHolds?: number;
  remainingHolds?: number;
};
export interface ConsumedHolds {
  [date: string]: {
    consumed: number;
    available: number;
  };
}

export type FeeCharge = Reservations.FeeChargeModel;

export type ReservationContact = Reservations.ReservationContactModel;

export type ReservationStatus = Reservations.ReservationStatus;
export const ReservationStatus = Reservations.ReservationStatus;

export type ReservationActivity = Reservations.ReservationActivityType;
export const ReservationActivity = Reservations.ReservationActivityType;

export type RefundReservationChargeModel = Reservations.RefundReservationChargeRequest;

export type ReservationRoomRateModel = Reservations.ReservationRoomRateModel;
export type ClaimGuestContactRequest = Reservations.ClaimGuestContactRequestModel;

export type VipStatus = Reservations.VipStatus;
export const VipStatus = Reservations.VipStatus;

interface Guest {
  hasAccount: true;
  contact: ReservationContact;
  userKey: string;
}

interface AnonymousGuest {
  hasAccount: false;
  contact: ReservationContact;
}

export type VerifiedGuest = Guest | AnonymousGuest;

export interface ClaimRequest {
  guest: VerifiedGuest;
  otherGuests: ClaimGuestContactRequest[];
  skipVerification: boolean;
}

export type ContactType = Reservations.ReservationContactType;
export const ContactType = Reservations.ReservationContactType;

export type Reservation = Reservations.ReservationModel &
  Partial<RebookPreviewModel> &
  Partial<ReservationModifications> &
  Partial<ReservationCancelOptions> &
  Partial<ReservationChangeBillingOptions> &
  Partial<ReservationPolicyDetails> & {
    property?: Reservations.PropertyRefModel;
    createdByUser?: Reservations.UserRefModel;
    lateCheckOutFee?: Fee[];
    hasAutomaticLateCheckout?: boolean;
  };

export interface ReservationModifications {
  changeableBefore: Date;
  earliestValidCheckOutDate: Date;
}

export interface ReservationCancelOptions {
  remainingStayDays: number;
  refundableStayDays: number;
  hasPendingCharges: boolean;
  canRefundWithOverride: boolean;
}

export interface RebookPreviewModel {
  cancelRefundsWithoutOverrideCode: number;
  cancelRefundsWithOverrideCode: number;
  createdReservationTotalCharges: number;
}

export interface ReservationPolicyDetails {
  policy?: ReservationPolicy;
}

export interface ReservationChangeBillingOptions {
  reservationBillingCharges?: Array<ReservationCharge>;
  hasAutomaticLateCheckout?: boolean;
}
export interface UpdateCost {
  refunds: Array<Fee>;
  charges: Array<Fee>;
}

export function reservationMapper({
  properties = [],
  users = [],
}: { properties?: Reservations.PropertyRefModel[]; users?: Reservations.UserRefModel[] } = {}) {
  const propertyMap = new Map(properties.map(p => [p.id, p]));
  const userMap = new Map(users.map(u => [u.id, u]));

  type Input = Reservations.ReservationModel;
  type Output = Reservation;

  function map(model: Input): Output {
    return {
      ...model,
      property: propertyMap.get(model.propertyId),
      createdByUser: model.createdByUserId ? userMap.get(model.createdByUserId) : undefined,
    };
  }

  function mapper(model: Input): Output;
  function mapper(model: Input[]): Output[];
  function mapper(model: Input | Input[]) {
    if (Array.isArray(model)) {
      return model.map(map);
    }
    return map(model);
  }

  return mapper;
}

export function mapReservationModifications(
  model: Reservations.ReservationModificationModel
): ReservationModifications {
  return {
    changeableBefore: parseISO(model.changeableBefore),
    earliestValidCheckOutDate: parseISO(model.earliestValidCheckOutDate),
  };
}

export function mapReservationChangeOptions(
  model: Reservations.ReservationChangeModel
): ReservationCancelOptions {
  return {
    remainingStayDays: model.remainingStayDays,
    refundableStayDays: model.refundableStayDays,
    hasPendingCharges: model.hasPendingCharges,
    canRefundWithOverride: model.canRefundWithOverride,
  };
}

export function mapRebookPreviewModel(
  model: Reservations.RebookPreviewResponse
): RebookPreviewModel {
  return {
    cancelRefundsWithOverrideCode: model.cancelRefundsWithOverrideCode,
    cancelRefundsWithoutOverrideCode: model.cancelRefundsWithoutOverrideCode,
    createdReservationTotalCharges: model.createdReservationTotalCharges,
  };
}

export function mapReservationChangeBillingOptions(
  model: Reservations.ReservationBillingChargesModel
): ReservationChangeBillingOptions {
  return {
    reservationBillingCharges: model.reservationBillingCharges ?? undefined,
    hasAutomaticLateCheckout: model.hasAutomaticLateCheckOutFee ?? false,
  };
}

export function mapReservationPolicy(
  model: Reservations.ReservationPolicy
): ReservationPolicyDetails {
  return {
    policy: model,
  };
}

export function mapBookingError(error: Error, guest: Reservations.CreateGuestModel) {
  if (!error) return `Error booking reservation for ${guest.name.first} ${guest.name.last}`;

  return `Error (${error.message}) booking reservation for ${guest.name.first} ${guest.name.last}`;
}

export function reservationDateRangeFilter(reservation: Reservation, start?: Date, end?: Date) {
  if (start && end) return isWithinInterval(parseISO(reservation.checkInDate), { start, end });
  else if (start) return isAfter(parseISO(reservation.checkInDate), start);
  else if (end) return isBefore(parseISO(reservation.checkInDate), end);

  return true;
}

export function reservationNumberRangeFilter(
  reservation: Reservation,
  recordNumber?: number,
  recordNumberRange?: number
) {
  if (!recordNumber) {
    return true;
  }
  const rn = Number(recordNumber);

  if (!isNumber(rn)) {
    return true;
  }

  if (!recordNumberRange) {
    return reservation.recordNumber === rn;
  }

  const rnr = Number(recordNumberRange);

  if (!isNumber(rnr)) {
    return reservation.recordNumber === rn;
  }

  return reservation.recordNumber >= rn && reservation.recordNumber <= rnr;
}

export function isReservationAvailableForCheckin(reservation: Reservation): boolean {
  return (
    isWithinInterval(new Date(), {
      start: parseISO(reservation.checkInPeriod.start),
      end: parseISO(reservation.checkInPeriod.end),
    }) && reservation.status === ReservationStatus.Open
  );
}

export function CheckReservationChangeableState(reservation: Reservation): boolean {
  return (
    [ReservationStatus.Open, ReservationStatus.CheckedIn].includes(reservation.status) &&
    !reservation.isOTA
  );
}

export function CheckReservationCancelableState(reservation: Reservation): boolean {
  return (
    [ReservationStatus.Open, ReservationStatus.NoShow].includes(reservation.status) &&
    !reservation.isOTA
  );
}

export function CheckReservationChangeBillingState(reservation: Reservation): boolean {
  return ![ReservationStatus.Canceled].includes(reservation.status) && !reservation.isOTA;
}
/**
 * set to yesterday if current time is before property check in time
 */
export function getDefaultCheckInDate(today: Date, nextDayTime: string): Date {
  const nextDayDate = dateFromTimeString(nextDayTime);

  if (isBefore(today, nextDayDate)) {
    return subDays(today, 1);
  }
  return today;
}

export function getDelegate(
  reservation: Reservation
): Reservations.ReservationContactModel | undefined {
  return getContactByType(reservation, Reservations.ReservationContactType.Delegate);
}

export function getGuests(
  reservation: Reservation
): Reservations.ReservationContactModel[] | undefined {
  if (!reservation?.contacts?.length) return undefined;
  return reservation.contacts
    .filter(x =>
      [
        Reservations.ReservationContactType.Guest,
        Reservations.ReservationContactType.AdditionalGuest,
      ].includes(x.contactType)
    )
    .sort(contact => parseInt(contact.contactType));
}

export function getSortedContacts(
  reservation: Reservation
): Reservations.ReservationContactModel[] | undefined {
  return reservation?.contacts;
}

export function getContactByType(
  reservation: Reservation,
  type: ContactType
): Reservations.ReservationContactModel | undefined {
  if (!reservation?.contacts?.length) return undefined;

  return reservation.contacts.find(x => x.contactType === type);
}

export function getPromoCode(reservation: Reservation) {
  for (let { rates } of reservation.rooms)
    for (let { promoCode } of rates) if (promoCode) return promoCode;
  return undefined;
}

export function mapUpdateCost(fees: Array<Fee>): UpdateCost {
  return {
    refunds: fees.filter(x => x.total < 0),
    charges: fees.filter(x => x.total >= 0),
  };
}

export function getPaymentUserId(reservation: Reservation) {
  const delegate = getDelegate(reservation);
  return delegate?.userId ?? reservation.userId;
}

export function getGuestType(isPrimary: boolean): Reservations.ReservationContactType {
  return isPrimary
    ? Reservations.ReservationContactType.Guest
    : Reservations.ReservationContactType.AdditionalGuest;
}

export function getIsOpenAndFlex(reservation: Reservation): boolean {
  return reservation.status === ReservationStatus.Open && reservation.cancelable;
}

export function getIsCancelledNoShowOrCheckedOut(reservation: Reservation): boolean {
  return (
    reservation.status === ReservationStatus.Canceled ||
    reservation.status === ReservationStatus.NoShow ||
    reservation.status === ReservationStatus.CheckedOut
  );
}

export type Overrideable = 'unavailable' | 'optional' | 'required';
