// tslint:disable
/**
 * Property Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CreateQRCodeRequest } from '../models';
import { GenerateRoomQRCodeUrlListResponse } from '../models';
import { QRCodeItemResponse } from '../models';
import { QRCodePaginatedListResponse } from '../models';
import { QRCodeStatus } from '../models';
import { QrCodeType } from '../models';
import { SubmitGuestFeedbackRequest } from '../models';
import { SubmitGuestMaintenanceTicketRequest } from '../models';
import { SubmitGuestMaintenanceTicketResponse } from '../models';
import { UpdateQRCodeRequest } from '../models';
/**
 * QRCodeApi - axios parameter creator
 * @export
 */
export const QRCodeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {QrCodeType} [type]
     * @param {string} [name]
     * @param {QRCodeStatus} [status]
     * @param {string} [roomNumber]
     * @param {string} [location]
     * @param {string} [qrCodeNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeGet(
      propertyId: string,
      continuationToken?: string,
      limit?: number,
      type?: QrCodeType,
      name?: string,
      status?: QRCodeStatus,
      roomNumber?: string,
      location?: string,
      qrCodeNumber?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdQrcodeGet.'
        );
      }
      const localVarPath = `/{propertyId}/qrcode`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (continuationToken !== undefined) {
        localVarQueryParameter['ContinuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['Limit'] = limit;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (roomNumber !== undefined) {
        localVarQueryParameter['RoomNumber'] = roomNumber;
      }

      if (location !== undefined) {
        localVarQueryParameter['Location'] = location;
      }

      if (qrCodeNumber !== undefined) {
        localVarQueryParameter['QrCodeNumber'] = qrCodeNumber;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeIdDelete(id: string, propertyId: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling propertyIdQrcodeIdDelete.'
        );
      }
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdQrcodeIdDelete.'
        );
      }
      const localVarPath = `/{propertyId}/qrcode/{id}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {SubmitGuestFeedbackRequest} [submitGuestFeedbackRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeIdFeedbackPost(
      id: string,
      propertyId: string,
      submitGuestFeedbackRequest?: SubmitGuestFeedbackRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling propertyIdQrcodeIdFeedbackPost.'
        );
      }
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdQrcodeIdFeedbackPost.'
        );
      }
      const localVarPath = `/{propertyId}/qrcode/{id}/feedback`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof submitGuestFeedbackRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(submitGuestFeedbackRequest !== undefined ? submitGuestFeedbackRequest : {})
        : submitGuestFeedbackRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeIdGenerateGet(id: string, propertyId: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling propertyIdQrcodeIdGenerateGet.'
        );
      }
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdQrcodeIdGenerateGet.'
        );
      }
      const localVarPath = `/{propertyId}/qrcode/{id}/generate`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeIdGet(id: string, propertyId: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling propertyIdQrcodeIdGet.'
        );
      }
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdQrcodeIdGet.'
        );
      }
      const localVarPath = `/{propertyId}/qrcode/{id}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {SubmitGuestMaintenanceTicketRequest} [submitGuestMaintenanceTicketRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeIdTicketPost(
      id: string,
      propertyId: string,
      submitGuestMaintenanceTicketRequest?: SubmitGuestMaintenanceTicketRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling propertyIdQrcodeIdTicketPost.'
        );
      }
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdQrcodeIdTicketPost.'
        );
      }
      const localVarPath = `/{propertyId}/qrcode/{id}/ticket`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof submitGuestMaintenanceTicketRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            submitGuestMaintenanceTicketRequest !== undefined
              ? submitGuestMaintenanceTicketRequest
              : {}
          )
        : submitGuestMaintenanceTicketRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {UpdateQRCodeRequest} [updateQRCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodePost(
      propertyId: string,
      updateQRCodeRequest?: UpdateQRCodeRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdQrcodePost.'
        );
      }
      const localVarPath = `/{propertyId}/qrcode`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateQRCodeRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateQRCodeRequest !== undefined ? updateQRCodeRequest : {})
        : updateQRCodeRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {CreateQRCodeRequest} [createQRCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodePut(
      propertyId: string,
      createQRCodeRequest?: CreateQRCodeRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdQrcodePut.'
        );
      }
      const localVarPath = `/{propertyId}/qrcode`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createQRCodeRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createQRCodeRequest !== undefined ? createQRCodeRequest : {})
        : createQRCodeRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomNumber
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeRoomNumberGenerateRoomGet(
      roomNumber: string,
      propertyId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'roomNumber' is not null or undefined
      if (roomNumber === null || roomNumber === undefined) {
        throw new RequiredError(
          'roomNumber',
          'Required parameter roomNumber was null or undefined when calling propertyIdQrcodeRoomNumberGenerateRoomGet.'
        );
      }
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdQrcodeRoomNumberGenerateRoomGet.'
        );
      }
      const localVarPath = `/{propertyId}/qrcode/{roomNumber}/generate/room`
        .replace(`{${'roomNumber'}}`, encodeURIComponent(String(roomNumber)))
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * QRCodeApi - functional programming interface
 * @export
 */
export const QRCodeApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {QrCodeType} [type]
     * @param {string} [name]
     * @param {QRCodeStatus} [status]
     * @param {string} [roomNumber]
     * @param {string} [location]
     * @param {string} [qrCodeNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeGet(
      propertyId: string,
      continuationToken?: string,
      limit?: number,
      type?: QrCodeType,
      name?: string,
      status?: QRCodeStatus,
      roomNumber?: string,
      location?: string,
      qrCodeNumber?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QRCodePaginatedListResponse> {
      const localVarAxiosArgs = QRCodeApiAxiosParamCreator(configuration).propertyIdQrcodeGet(
        propertyId,
        continuationToken,
        limit,
        type,
        name,
        status,
        roomNumber,
        location,
        qrCodeNumber,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeIdDelete(
      id: string,
      propertyId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QRCodeItemResponse> {
      const localVarAxiosArgs = QRCodeApiAxiosParamCreator(configuration).propertyIdQrcodeIdDelete(
        id,
        propertyId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {SubmitGuestFeedbackRequest} [submitGuestFeedbackRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeIdFeedbackPost(
      id: string,
      propertyId: string,
      submitGuestFeedbackRequest?: SubmitGuestFeedbackRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = QRCodeApiAxiosParamCreator(
        configuration
      ).propertyIdQrcodeIdFeedbackPost(id, propertyId, submitGuestFeedbackRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeIdGenerateGet(
      id: string,
      propertyId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = QRCodeApiAxiosParamCreator(
        configuration
      ).propertyIdQrcodeIdGenerateGet(id, propertyId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeIdGet(
      id: string,
      propertyId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QRCodeItemResponse> {
      const localVarAxiosArgs = QRCodeApiAxiosParamCreator(configuration).propertyIdQrcodeIdGet(
        id,
        propertyId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {SubmitGuestMaintenanceTicketRequest} [submitGuestMaintenanceTicketRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeIdTicketPost(
      id: string,
      propertyId: string,
      submitGuestMaintenanceTicketRequest?: SubmitGuestMaintenanceTicketRequest,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<SubmitGuestMaintenanceTicketResponse> {
      const localVarAxiosArgs = QRCodeApiAxiosParamCreator(
        configuration
      ).propertyIdQrcodeIdTicketPost(id, propertyId, submitGuestMaintenanceTicketRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {UpdateQRCodeRequest} [updateQRCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodePost(
      propertyId: string,
      updateQRCodeRequest?: UpdateQRCodeRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QRCodeItemResponse> {
      const localVarAxiosArgs = QRCodeApiAxiosParamCreator(configuration).propertyIdQrcodePost(
        propertyId,
        updateQRCodeRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {CreateQRCodeRequest} [createQRCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodePut(
      propertyId: string,
      createQRCodeRequest?: CreateQRCodeRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QRCodeItemResponse> {
      const localVarAxiosArgs = QRCodeApiAxiosParamCreator(configuration).propertyIdQrcodePut(
        propertyId,
        createQRCodeRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} roomNumber
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeRoomNumberGenerateRoomGet(
      roomNumber: string,
      propertyId: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GenerateRoomQRCodeUrlListResponse> {
      const localVarAxiosArgs = QRCodeApiAxiosParamCreator(
        configuration
      ).propertyIdQrcodeRoomNumberGenerateRoomGet(roomNumber, propertyId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * QRCodeApi - factory interface
 * @export
 */
export const QRCodeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {QrCodeType} [type]
     * @param {string} [name]
     * @param {QRCodeStatus} [status]
     * @param {string} [roomNumber]
     * @param {string} [location]
     * @param {string} [qrCodeNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeGet(
      propertyId: string,
      continuationToken?: string,
      limit?: number,
      type?: QrCodeType,
      name?: string,
      status?: QRCodeStatus,
      roomNumber?: string,
      location?: string,
      qrCodeNumber?: string,
      options?: any
    ): AxiosPromise<QRCodePaginatedListResponse> {
      return QRCodeApiFp(configuration).propertyIdQrcodeGet(
        propertyId,
        continuationToken,
        limit,
        type,
        name,
        status,
        roomNumber,
        location,
        qrCodeNumber,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeIdDelete(
      id: string,
      propertyId: string,
      options?: any
    ): AxiosPromise<QRCodeItemResponse> {
      return QRCodeApiFp(configuration).propertyIdQrcodeIdDelete(
        id,
        propertyId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {SubmitGuestFeedbackRequest} [submitGuestFeedbackRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeIdFeedbackPost(
      id: string,
      propertyId: string,
      submitGuestFeedbackRequest?: SubmitGuestFeedbackRequest,
      options?: any
    ): AxiosPromise<void> {
      return QRCodeApiFp(configuration).propertyIdQrcodeIdFeedbackPost(
        id,
        propertyId,
        submitGuestFeedbackRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeIdGenerateGet(
      id: string,
      propertyId: string,
      options?: any
    ): AxiosPromise<string> {
      return QRCodeApiFp(configuration).propertyIdQrcodeIdGenerateGet(
        id,
        propertyId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeIdGet(
      id: string,
      propertyId: string,
      options?: any
    ): AxiosPromise<QRCodeItemResponse> {
      return QRCodeApiFp(configuration).propertyIdQrcodeIdGet(
        id,
        propertyId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {SubmitGuestMaintenanceTicketRequest} [submitGuestMaintenanceTicketRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeIdTicketPost(
      id: string,
      propertyId: string,
      submitGuestMaintenanceTicketRequest?: SubmitGuestMaintenanceTicketRequest,
      options?: any
    ): AxiosPromise<SubmitGuestMaintenanceTicketResponse> {
      return QRCodeApiFp(configuration).propertyIdQrcodeIdTicketPost(
        id,
        propertyId,
        submitGuestMaintenanceTicketRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {UpdateQRCodeRequest} [updateQRCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodePost(
      propertyId: string,
      updateQRCodeRequest?: UpdateQRCodeRequest,
      options?: any
    ): AxiosPromise<QRCodeItemResponse> {
      return QRCodeApiFp(configuration).propertyIdQrcodePost(
        propertyId,
        updateQRCodeRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {CreateQRCodeRequest} [createQRCodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodePut(
      propertyId: string,
      createQRCodeRequest?: CreateQRCodeRequest,
      options?: any
    ): AxiosPromise<QRCodeItemResponse> {
      return QRCodeApiFp(configuration).propertyIdQrcodePut(
        propertyId,
        createQRCodeRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} roomNumber
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdQrcodeRoomNumberGenerateRoomGet(
      roomNumber: string,
      propertyId: string,
      options?: any
    ): AxiosPromise<GenerateRoomQRCodeUrlListResponse> {
      return QRCodeApiFp(configuration).propertyIdQrcodeRoomNumberGenerateRoomGet(
        roomNumber,
        propertyId,
        options
      )(axios, basePath);
    },
  };
};

/**
 * QRCodeApi - object-oriented interface
 * @export
 * @class QRCodeApi
 * @extends {BaseAPI}
 */
export class QRCodeApi extends BaseAPI {
  /**
   *
   * @param {string} propertyId
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {QrCodeType} [type]
   * @param {string} [name]
   * @param {QRCodeStatus} [status]
   * @param {string} [roomNumber]
   * @param {string} [location]
   * @param {string} [qrCodeNumber]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QRCodeApi
   */
  public propertyIdQrcodeGet(
    propertyId: string,
    continuationToken?: string,
    limit?: number,
    type?: QrCodeType,
    name?: string,
    status?: QRCodeStatus,
    roomNumber?: string,
    location?: string,
    qrCodeNumber?: string,
    options?: any
  ) {
    return QRCodeApiFp(this.configuration).propertyIdQrcodeGet(
      propertyId,
      continuationToken,
      limit,
      type,
      name,
      status,
      roomNumber,
      location,
      qrCodeNumber,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {string} propertyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QRCodeApi
   */
  public propertyIdQrcodeIdDelete(id: string, propertyId: string, options?: any) {
    return QRCodeApiFp(this.configuration).propertyIdQrcodeIdDelete(
      id,
      propertyId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {string} propertyId
   * @param {SubmitGuestFeedbackRequest} [submitGuestFeedbackRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QRCodeApi
   */
  public propertyIdQrcodeIdFeedbackPost(
    id: string,
    propertyId: string,
    submitGuestFeedbackRequest?: SubmitGuestFeedbackRequest,
    options?: any
  ) {
    return QRCodeApiFp(this.configuration).propertyIdQrcodeIdFeedbackPost(
      id,
      propertyId,
      submitGuestFeedbackRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {string} propertyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QRCodeApi
   */
  public propertyIdQrcodeIdGenerateGet(id: string, propertyId: string, options?: any) {
    return QRCodeApiFp(this.configuration).propertyIdQrcodeIdGenerateGet(
      id,
      propertyId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {string} propertyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QRCodeApi
   */
  public propertyIdQrcodeIdGet(id: string, propertyId: string, options?: any) {
    return QRCodeApiFp(this.configuration).propertyIdQrcodeIdGet(
      id,
      propertyId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {string} propertyId
   * @param {SubmitGuestMaintenanceTicketRequest} [submitGuestMaintenanceTicketRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QRCodeApi
   */
  public propertyIdQrcodeIdTicketPost(
    id: string,
    propertyId: string,
    submitGuestMaintenanceTicketRequest?: SubmitGuestMaintenanceTicketRequest,
    options?: any
  ) {
    return QRCodeApiFp(this.configuration).propertyIdQrcodeIdTicketPost(
      id,
      propertyId,
      submitGuestMaintenanceTicketRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {UpdateQRCodeRequest} [updateQRCodeRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QRCodeApi
   */
  public propertyIdQrcodePost(
    propertyId: string,
    updateQRCodeRequest?: UpdateQRCodeRequest,
    options?: any
  ) {
    return QRCodeApiFp(this.configuration).propertyIdQrcodePost(
      propertyId,
      updateQRCodeRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {CreateQRCodeRequest} [createQRCodeRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QRCodeApi
   */
  public propertyIdQrcodePut(
    propertyId: string,
    createQRCodeRequest?: CreateQRCodeRequest,
    options?: any
  ) {
    return QRCodeApiFp(this.configuration).propertyIdQrcodePut(
      propertyId,
      createQRCodeRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} roomNumber
   * @param {string} propertyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QRCodeApi
   */
  public propertyIdQrcodeRoomNumberGenerateRoomGet(
    roomNumber: string,
    propertyId: string,
    options?: any
  ) {
    return QRCodeApiFp(this.configuration).propertyIdQrcodeRoomNumberGenerateRoomGet(
      roomNumber,
      propertyId,
      options
    )(this.axios, this.basePath);
  }
}
