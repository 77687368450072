import React, { ReactNode } from 'react';
import { ValidationOptions, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TextFieldProps, FormTextField } from './form-text-field';

interface Props {
  name: string;
  validationOptions?: ValidationOptions;
  helperText?: ReactNode | ((errors: any) => string);

  renderValue?: (values: unknown) => ReactNode;
  multiple?: boolean;
}

export type FormSelectProps = Props &
  TextFieldProps<'select' | 'type' | 'onChange' | 'onBlur' | 'onFocus'>;

export const FormSelect: React.FC<FormSelectProps> = ({
  multiple,
  defaultValue,
  name,
  renderValue,
  validationOptions,
  ...props
}) => {
  return (
    <Controller
      as={FormTextField}
      {...props}
      name={name}
      select
      defaultValue={defaultValue}
      SelectProps={{
        multiple,
        IconComponent: (props: any) => (
          <FontAwesomeIcon icon="chevron-down" transform="down-6 left-4" fixedWidth {...props} />
        ),
        renderValue,
      }}
      controlled
      rules={validationOptions}
    />
  );
};
