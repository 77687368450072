import React, { useCallback } from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

import {
  corporateAccountQuery,
  isReservationAvailableForCheckin,
  PaginationState,
  propertyQuery,
  Reservation,
  ReservationStatus,
} from '@lib/state';
import { useCommonStyle } from '../../commonStyle';
import { useLookup, useObservable } from '../../utils';
import {
  CancelReservationButton,
  CheckOutReservationButton,
  EditReservationButton,
  LateCheckOutReservationButton,
  ReservationInfo,
} from '../../reservations';
import { ExportButton } from '../export.button';
import { ButtonLink } from '../link';
import { Section } from '../section';
import { ShowMoreButton } from '../show-more-button';
import { GuestRebookButton } from '../profile/guest-rebook-button';

const useStyles = makeStyles(theme =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(1),
      '&:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
  })
);

interface Props {
  userId?: string;

  reservations: Array<Reservation>;
  page: PaginationState;

  onLoadMore: () => void;
  onExport: (status?: ReservationStatus) => void;

  onConfirmationClick?: (reservation: Reservation) => void;
  showActions?: boolean;
  createForGuest: boolean;
  onBulkSelectionChanged?: (reservation: Reservation) => void;
  selectedReservations?: Array<Reservation>;
  bulkCorporateAccountSelected?: string;
  bulkPropertySelected?: string;
}

export const CurrentGuestStays: React.FC<Props> = ({
  reservations,
  page,
  onLoadMore,
  onExport,
  onConfirmationClick,
  showActions = false,
  onBulkSelectionChanged: onSelectionChanged,
  selectedReservations,
  bulkCorporateAccountSelected,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyle();

  const properties = useObservable(propertyQuery.properties);
  const propertyLookup = useLookup(properties, x => x.id);

  const accounts = useObservable(corporateAccountQuery.accounts);
  const accountLookup = useLookup(accounts, x => x.id);

  const shouldShowSelectionCheckbox = useCallback(
    (res: Reservation): boolean => {
      if (!res.affiliation || !res.isFullyInvoiced) {
        return false;
      }

      if (
        !!bulkCorporateAccountSelected &&
        res.affiliation.corporateAccountId === bulkCorporateAccountSelected
      ) {
        return true;
      }

      return !!accountLookup.get(res.affiliation.corporateAccountId!)?.isInvoiceEnabled;
    },
    [accountLookup, bulkCorporateAccountSelected]
  );

  if (reservations.length === 0) return null;

  return (
    <Section
      title="Current Stay"
      variant="contained"
      right={<ExportButton onExport={() => onExport(ReservationStatus.CheckedIn)} />}
    >
      {reservations.map((currentReservation, i) => (
        <div
          key={i}
          className={clsx({
            [commonStyles.hidden]: currentReservation.status === ReservationStatus.CheckedOut,
          })}
          data-testid={`currentStaysItem:${currentReservation.recordNumber}`}
        >
          <ReservationInfo
            reservation={currentReservation}
            property={propertyLookup.get(currentReservation.propertyId)}
            divider={i !== reservations.length - 1}
            onConfirmationClick={onConfirmationClick}
            onSelectionChanged={onSelectionChanged}
            isSelected={!!selectedReservations?.find(r => r.id === currentReservation.id)}
            showSelectionCheckbox={shouldShowSelectionCheckbox(currentReservation)}
          >
            {showActions && !selectedReservations?.length && (
              <div className={styles.buttonContainer}>
                <LateCheckOutReservationButton
                  reservation={currentReservation}
                  className={styles.button}
                  variant="contained"
                  color="secondary"
                  size="small"
                  data-testid="currentStayLateCheckOutButton"
                />
                <CheckOutReservationButton
                  reservation={currentReservation}
                  className={styles.button}
                  variant="contained"
                  color="secondary"
                  size="small"
                  data-testid="currentStayCheckOutButton"
                />
                <EditReservationButton
                  reservation={currentReservation}
                  className={styles.button}
                  variant="contained"
                  color="secondary"
                  size="small"
                  data-testid="currentStayEditButton"
                />
              </div>
            )}
          </ReservationInfo>
        </div>
      ))}
      <ShowMoreButton isDone={page.isDone} loadMore={onLoadMore} />
    </Section>
  );
};

export const FutureGuestStays: React.FC<Props> = ({
  userId,
  reservations,
  page,
  onLoadMore,
  onExport,
  onConfirmationClick,
  showActions = false,
  createForGuest,
  onBulkSelectionChanged: onSelectionChanged,
  selectedReservations,
  bulkCorporateAccountSelected,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyle();

  const properties = useObservable(propertyQuery.properties);
  const propertyLookup = useLookup(properties, x => x.id);

  const accounts = useObservable(corporateAccountQuery.accounts);
  const accountLookup = useLookup(accounts, x => x.id);

  const shouldShowSelectionCheckbox = useCallback(
    (res: Reservation): boolean => {
      if (!res.affiliation || !res.isFullyInvoiced) {
        return false;
      }

      if (
        !!bulkCorporateAccountSelected &&
        res.affiliation.corporateAccountId === bulkCorporateAccountSelected
      ) {
        return true;
      }

      return !!accountLookup.get(res.affiliation.corporateAccountId!)?.isInvoiceEnabled;
    },
    [accountLookup, bulkCorporateAccountSelected]
  );

  return (
    <Section
      title="Upcoming Stays"
      variant="contained"
      right={<ExportButton onExport={() => onExport(ReservationStatus.Open)} />}
    >
      {reservations.length === 0 ? (
        <Typography>No upcoming stays</Typography>
      ) : (
        reservations.map((x, i) => (
          <div
            key={x.id}
            className={clsx({ [commonStyles.hidden]: x.status === ReservationStatus.Canceled })}
            data-testid={`futureStaysItem:${x.recordNumber}`}
          >
            <ReservationInfo
              reservation={x}
              property={propertyLookup.get(x.propertyId)}
              divider={i !== reservations.length - 1}
              onConfirmationClick={onConfirmationClick}
              onSelectionChanged={onSelectionChanged}
              isSelected={!!selectedReservations?.find(r => r.id === x.id)}
              showSelectionCheckbox={shouldShowSelectionCheckbox(x)}
            >
              {showActions && !selectedReservations?.length && (
                <div className={styles.buttonContainer}>
                  <CancelReservationButton
                    reservation={x}
                    className={styles.button}
                    variant="contained"
                    color="secondary"
                    size="small"
                    data-testid="stayCancelButton"
                  />
                  <LateCheckOutReservationButton
                    reservation={x}
                    className={styles.button}
                    variant="contained"
                    color="secondary"
                    size="small"
                    data-testid="stayLateCheckoutButton"
                  />
                  {isReservationAvailableForCheckin(x) && x.userId === userId && (
                    <ButtonLink
                      to={`/profile/stays/${x.id}/check-in`}
                      className={styles.button}
                      variant="contained"
                      color="secondary"
                      size="small"
                      name="checkInReservation"
                      data-testid="stayCheckInButton"
                    >
                      Check In
                    </ButtonLink>
                  )}
                  <EditReservationButton
                    reservation={x}
                    className={styles.button}
                    variant="contained"
                    color="secondary"
                    size="small"
                    data-testid="stayEditButton"
                  />
                  <GuestRebookButton
                    className={styles.button}
                    reservation={x}
                    color="secondary"
                    variant="contained"
                    createForGuest={createForGuest}
                    data-testid="stayRebookButton"
                  />
                </div>
              )}
            </ReservationInfo>
          </div>
        ))
      )}
      <ShowMoreButton isDone={page.isDone} loadMore={onLoadMore} />
    </Section>
  );
};
