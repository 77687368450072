import { Reservation, reservationService } from '@lib/state';
import { ReservationAvailableRatesModel } from '@lib/state/api/generated/reservations';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import { transformRatesToBulkModify } from './bulk-change-utils';
import { FormHub, FormSubmitButton } from '../forms';
import { ButtonLink } from './link';

interface Props {
  propertyId: string;
  corporateAccountId: string;
  bulkAvailability: ReservationAvailableRatesModel[];
  reservations: Reservation[];
  checkInDate?: Date;
  checkOutDate?: Date;
  disabled?: boolean;
}

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      marginLeft: theme.spacing(1),
    },
  })
);

export const BulkChangeDatesFormButtons: React.FC<Props> = ({
  propertyId,
  corporateAccountId,
  bulkAvailability,
  reservations,
  checkInDate,
  checkOutDate,
  disabled,
}) => {
  return (
    <Box display="flex" justifyContent="flex-end" my={2}>
      <BulkChangesButtonsContainer
        propertyId={propertyId}
        corporateAccountId={corporateAccountId}
        bulkAvailability={bulkAvailability}
        reservations={reservations}
        checkInDate={checkInDate}
        checkOutDate={checkOutDate}
        disabled={disabled}
      />
    </Box>
  );
};

const BulkChangesButtonsContainer: React.FC<Props> = ({
  propertyId,
  corporateAccountId,
  bulkAvailability,
  reservations,
  checkInDate,
  checkOutDate,
  disabled,
}) => {
  const onSubmit = useCallback(() => {
    const rooms = transformRatesToBulkModify(bulkAvailability, reservations);

    reservationService.bulkReservationsModify(
      propertyId,
      corporateAccountId,
      rooms,
      checkInDate,
      checkOutDate
    );
  }, [reservations, bulkAvailability, propertyId, corporateAccountId, checkInDate, checkOutDate]);

  return (
    <FormHub onSubmit={onSubmit}>
      <BulkChangesButtonsForm disabled={disabled} />
    </FormHub>
  );
};

const BulkChangesButtonsFormComponent: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const styles = useStyles();

  return (
    <div>
      <FormSubmitButton
        color="secondary"
        variant="contained"
        className={styles.button}
        disabled={disabled}
      >
        Confirm Changes
      </FormSubmitButton>
      <ButtonLink
        to="/profile/stays"
        variant="contained"
        color="secondary"
        className={styles.button}
      >
        Cancel
      </ButtonLink>
    </div>
  );
};

export const BulkChangesButtonsForm = BulkChangesButtonsFormComponent;
