import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexBasis: '68%',
      flexShrink: 1,
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexBasis: '60px',
        width: '60px',
      },
    },
  })
);

interface Props {
  header?:boolean
}

export const TimelineColumn: React.FC<Props> = ({ children }) => {
  const { root } = useStyles();

  return <div className={root}>{children}</div>;
};
