import { StoreConfig, EntityStore, EntityState, ActiveState } from '@datorama/akita';

import { GuestProfile, PaginationState, VipStatus } from '@lib/state';

export interface GuestManagementUIState {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  propertyId?: string;
  showArchived?: boolean;
  vipStatus?: VipStatus;
}

export interface GuestManagementState
  extends EntityState<GuestProfile, GuestProfile['userId']>,
    ActiveState<GuestProfile['userId']> {
  ui: GuestManagementUIState;
  pagination: PaginationState;
}

@StoreConfig({ name: 'guest-management', resettable: true, idKey: 'userId' })
export class GuestManagementStore extends EntityStore<GuestManagementState> {
  constructor() {
    super({
      ui: {
        propertyId: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        showArchived: false,
      },
      pagination: {
        isDone: false,
        continuationToken: undefined,
      },
      active: null,
    });
  }
}

export const guestManagementStore = new GuestManagementStore();
