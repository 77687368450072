import React from 'react';
import {
  DateDisplay,
  EnumValueDisplay,
  NameDisplay,
  Section,
  ShowMoreButton,
  ShowMoreButtonProps,
  DataTable,
} from '@lib/common';
import { Link, Typography } from '@material-ui/core';
import { MaintenanceTicketModel } from '@lib/state/api/generated/properties';
import { parseISO } from 'date-fns';

interface Props extends ShowMoreButtonProps {
  maintenanceTickets: MaintenanceTicketModel[];
  onSelect: (id: string) => void;
}

export const MaintenanceTicketList: React.FC<Props> = ({
  maintenanceTickets,
  onSelect,
  ...loadMoreProps
}) => {
  return (
    <>
      {maintenanceTickets.length > 0 && (
        <Section title="Results" textAlign="left" disableGutters>
          <DataTable
            items={maintenanceTickets}
            getItemKey={ticket => ticket.id}
            columns={[
              {
                title: 'Ticket',
                valueFactory: ticket => (
                  <Link onClick={() => onSelect(ticket.id)}>{ticket.ticketNumber}</Link>
                ),
                colSpan: 0.4,
              },
              {
                title: 'Created',
                valueFactory: ticket => (
                  <DateDisplay date={parseISO(ticket.timestamp)} dateFormat="M/d/yy h:mm a" />
                ),
                colSpan: 0.6,
              },
              {
                title: 'Priority',
                valueFactory: ticket => <EnumValueDisplay value={ticket.priority} />,
                colSpan: 0.4,
              },
              {
                title: 'Room Number / Area',
                valueFactory: ticket => (
                  <Typography>
                    {ticket.roomNumber} {ticket.roomNumber && ticket.area && ' / '} {ticket.area}
                  </Typography>
                ),
                colSpan: 0.6,
              },
              {
                title: 'Status',
                valueFactory: ticket => <Typography>{ticket.status}</Typography>,
                colSpan: 0.5,
              },
              {
                title: 'Assigned To',
                valueFactory: ticket => <NameDisplay {...ticket.assignedTo?.name} />,
                colSpan: 0.8,
              },
              {
                title: 'Notes',
                valueFactory: ticket => (
                  <TicketNotesList description={ticket.description ?? undefined} />
                ),
                colSpan: 1.5,
              },
              {
                title: 'Active',
                valueFactory: ticket => (
                  <Typography>{ticket.isActive ? 'Active' : 'Inactive'}</Typography>
                ),
                colSpan: 0.3,
              },
            ]}
          >
            <ShowMoreButton {...loadMoreProps} />
          </DataTable>
        </Section>
      )}
    </>
  );
};

interface TicketNotesListProps {
  description?: Array<string>;
}

function TicketNotesList({ description }: TicketNotesListProps) {
  return (
    <ul>
      {description?.map(d => (
        <li key={d}>
          <Typography>{d}</Typography>
        </li>
      ))}
    </ul>
  );
}
