import { useEffect, EffectCallback, DependencyList } from 'react';

// Extracts return type from a function type
type ReturnType<T> = T extends () => infer TReturn ? TReturn : never;

export function useDebouncedEffect(effect: EffectCallback, timeout: number, deps?: DependencyList) {
  useEffect(() => {
    let result: ReturnType<EffectCallback>;

    const timeoutId = setTimeout(() => {
      result = effect();
    }, timeout);

    return () => {
      clearTimeout(timeoutId);

      if (result) {
        result();
        result = undefined;
      }
    };
  }, deps);
}
