import { QueryEntity } from '@datorama/akita';
import bulkCancelPreviewStore, {
  BulkCancelPreviewStore,
  BulkCancellationPreviewState,
} from './bulk-cancel-preview.store';

export class BulkCancelPreviewQuery extends QueryEntity<BulkCancellationPreviewState> {
  constructor(protected store: BulkCancelPreviewStore) {
    super(store);
  }

  bulkCancelPreviews = this.selectAll();
}

export const bulkCancelPreviewQuery = new BulkCancelPreviewQuery(bulkCancelPreviewStore);
