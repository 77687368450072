import { applyTransaction } from '@datorama/akita';
import { from, Observable, concat } from 'rxjs';
import { switchMap, map, tap, scan } from 'rxjs/operators';

import { Reservations, Properties } from '../api';
import { dispatchForm } from '../forms';
import { Reservation, ReservationStore } from '../reservation';

// separately import reservationStore, otherwise it will have an undefined value in dev builds
// reason for this is very much unclear, but suspect the webpack dev server...
import { reservationStore } from '../reservation/reservation.store';

import { roomsService, RoomsStore, roomsStore } from '../rooms';
import { AcceptCardData, getPaymentNonce } from '../utils';
import { formatPaymentMethod } from '../payment-method';
import { CheckInForm } from './check-in.forms';
import { CheckInStore, checkInStore } from './check-in.store';
import { mapCheckInRequirement } from './check-in.model';

export class CheckInService {
  constructor(
    private readonly checkInStore: CheckInStore,
    private readonly reservationStore: ReservationStore,
    private readonly roomStore: RoomsStore,
    private readonly reservationApi: Reservations.ReservationApi,
    private readonly roomApi: Properties.RoomsApi
  ) {}

  getCheckInRoomOptions(reservation: Reservation) {
    this.checkInStore.update({
      roomOptions: undefined,
    });
    roomsService.getRooms(reservation.propertyId);
    from(this.reservationApi.reservationIdCheckinOptionsGet(reservation.id))
      .pipe(map(res => res.data.data))
      .subscribe(roomOptions => {
        this.checkInStore.update({ roomOptions });
      });
  }

  getCheckInRequirements(reservationId: Reservation['id']) {
    from(this.reservationApi.reservationIdCheckinGet(reservationId))
      .pipe(map(res => res.data.data.map(mapCheckInRequirement(reservationId))))
      .subscribe(requirements => this.checkInStore.update({ requirements }));
  }

  checkInReservation(
    reservation: Reservation,
    billing?: Reservations.CreateBillingModel,
    savePaymentMethod?: boolean,
    paymentProfileId?: string,
    card?: AcceptCardData,
    overrideCode?: number,
    selectedRoomIds?: Array<string>
  ) {
    this.mapCheckInResponse(
      getPaymentNonce(card).pipe(
        switchMap(nonce =>
          this.reservationApi.reservationCheckinPost({
            reservationId: reservation.id,
            billing,
            savePaymentMethod: true, //Hardcoding to true
            paymentProfileId,
            paymentNonce: nonce?.opaqueData,
            overrideCode,
            selectedRoomIds,
            ...formatPaymentMethod(paymentProfileId),
          })
        ),
        map(response => response.data)
      ),
      reservation
    )
      .pipe(dispatchForm(CheckInForm.CheckIn))
      .subscribe();
  }

  private mapCheckInResponse(
    obs: Observable<Reservations.CheckInReservationResponse>,
    reservation: Reservation,
    requestOpts?: any
  ) {
    return obs.pipe(
      map(response => ({
        reservation,
        rooms: response.rooms,
      })),
      switchMap(({ reservation, rooms }) =>
        concat(
          ...rooms.map(({ roomId }) =>
            this.roomApi.propertyIdRoomsRoomIdGet(reservation.propertyId, roomId, requestOpts)
          )
        ).pipe(
          scan((arr, response) => [...arr, response.data.data], [] as Array<Properties.RoomModel>),
          map(rooms => ({
            reservation,
            rooms,
          }))
        )
      ),
      tap(({ reservation, rooms }) =>
        applyTransaction(() => {
          this.reservationStore.upsert(reservation.id, reservation);
          this.reservationStore.setActive(reservation.id);

          this.roomStore.upsertMany(rooms);
          this.checkInStore.update({ rooms });
        })
      )
    );
  }

  reset() {
    this.checkInStore.reset();
    this.reservationStore.reset();
    this.roomStore.reset();
  }
}

export const checkInService = new CheckInService(
  checkInStore,
  reservationStore,
  roomsStore,
  new Reservations.ReservationApi(),
  new Properties.RoomsApi()
);
