import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { dispatchForm, GuestRelationForms } from '..';
import { Reservations } from '../api/generated';
import { guestRelationStore, GuestRelationStore } from './guest-relation.store';

export class GuestRelationService {
  constructor(
    private readonly store: GuestRelationStore,
    private readonly guestRelationApi: Reservations.GuestRelationApi
  ) {}

  getGuestRelations(userId: string): void {
    from(this.guestRelationApi.guestrelationUserIdGet(userId))
      .pipe(map(({ data }) => data.data))
      .subscribe(relations => this.store.update({ userId, relations }));
  }

  updateGuestRelations(
    userId: string,
    { relations }: Reservations.UpdateGuestRelationsRequest
  ): void {
    from(this.guestRelationApi.guestrelationUserIdPost(userId, { relations }))
      .pipe(
        dispatchForm(GuestRelationForms.UpdateRelations),
        map(({ data }) => data.data)
      )
      .subscribe(relations => this.store.update({ userId, relations }));
  }

  reset(): void {
    this.store.reset();
  }
}

export const guestRelationService = new GuestRelationService(
  guestRelationStore,
  new Reservations.GuestRelationApi()
);
