import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CorporateDelegate } from './corporate-delegate.model';
import { Reservations } from '../api';

export interface CorporateDelegateUIState {
  corporateAccounts: Reservations.CorporateAccountModel[];
}

export interface CorporateDelegateState extends EntityState<CorporateDelegate, string> {
  ui: CorporateDelegateUIState;
  corporateAccountId?: string;
}

@StoreConfig({ name: 'corporate-delegates', idKey: 'userId' })
export class CorporateDelegateStore extends EntityStore<CorporateDelegateState> {
  constructor() {
    super({
      corporateAccountId: undefined,
      ui: {
        corporateAccounts: [],
      },
    });
  }
}

const corporateDelegateStore: CorporateDelegateStore = new CorporateDelegateStore();
export default corporateDelegateStore;
