import { EntityState, ActiveState, EntityStore, StoreConfig } from '@datorama/akita';
import { GuestStay } from './guest-stays.model';
import { VipStatus } from '@lib/state';

export interface GuestStaysFilters {
  confirmationNumber: string;
  guestName: string;
  corporateAccountName: string;
  crew: string;
  vipStatus?: VipStatus;
}

export interface GuestStaysStateUIState {
  filters: GuestStaysFilters;
}

export interface GuestStaysState
  extends EntityState<GuestStay, GuestStay['id']>,
    ActiveState<GuestStay['id']> {
  ui: GuestStaysStateUIState;
}

export const initialFilters = {
  confirmationNumber: '',
  guestName: '',
  corporateAccountName: '',
  crew: '',
};

@StoreConfig({ name: 'guestStays', resettable: true })
export class GuestStaysStore extends EntityStore<GuestStaysState> {
  constructor() {
    super({
      ui: {
        filters: initialFilters,
      },
      active: null,
    });
  }
}

export const guestStaysStore = new GuestStaysStore();
