import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CorporateAccount } from '../models.common';

export interface CorporateAccountState extends EntityState<CorporateAccount, string> {}

@StoreConfig({ name: 'corporate-accounts', idKey: 'id' })
export class CorporateAccountStore extends EntityStore<CorporateAccountState> {
  constructor() {
    super({});
  }
}

const corporateDelegateStore: CorporateAccountStore = new CorporateAccountStore();
export default corporateDelegateStore;
