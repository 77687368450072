import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { RoomType } from '@lib/state';
import { RoomRatePlan } from '../room-rate-plans';

import { RateRestriction } from './room-rate-restriction.model';

export interface RateRestrictionUIState {
  roomTypeId: RoomType['id'] | null;
  ratePlanId: RoomRatePlan['id'] | null;
}

export interface RateRestrictionState
  extends EntityState<RateRestriction, RateRestriction['id']>,
    ActiveState<RateRestriction['id']> {
  ui: RateRestrictionUIState;
}

@StoreConfig({ name: 'rate-restrictions', resettable: true })
export class RateRestrictionStore extends EntityStore<RateRestrictionState> {
  constructor() {
    super({
      ui: {
        roomTypeId: null,
        ratePlanId: null,
      },
    });
  }
}

export const rateRestrictionStore = new RateRestrictionStore();
