import React, { useState, useMemo, useEffect } from 'react';
import { Box, BoxProps, makeStyles, Typography, createStyles } from '@material-ui/core';
import { GuestProfile, AppConfig, guestPictureSize } from '@lib/state';

const useStyles = makeStyles(theme =>
  createStyles({
    text: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

interface Props extends BoxProps {
  guest?: GuestProfile | null;
  data: string | null;
}

export const GuestPictureDisplay: React.FC<Props> = ({ guest, data, ...props }) => {
  const { text } = useStyles();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false);
  }, [guest]);

  const picture = useMemo(() => {
    if (hasError)
      return (
        <Typography variant="h2" className={text}>{`${guest?.name.first?.charAt(0) ?? ''}${
          guest?.name.middle?.charAt(0) ?? ''
        }${guest?.name.last?.charAt(0) ?? ''}`}</Typography>
      );
    if (!!data) return <img height={guestPictureSize} width={guestPictureSize} src={data} />;
    if (!guest?.userId) {
      return (
        <Typography variant="body2" component="span" className={text}>
          Take picture
        </Typography>
      );
    }
    return (
      <img
        height={guestPictureSize}
        width={guestPictureSize}
        src={`${AppConfig.Api.BaseUrl}/reservations/guests/guest/${guest.userId}/picture`}
        onError={e => setHasError(true)}
      />
    );
  }, [hasError, data, guest]);

  return <Box {...props}>{picture}</Box>;
};
