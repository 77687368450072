import React from 'react';
import { DialogContent, makeStyles, createStyles, Typography } from '@material-ui/core';
import { StyledDialog, useObservable } from '@lib/common';
import { auditLogQuery, SecurityImageStatus } from 'app/state';

const useStyles = makeStyles(theme =>
  createStyles({
    img: {
      width: '100%',
    },
  })
);

interface Props {
  show: boolean;
  onClose: () => void;
}

export const SecurityImageModal: React.FC<Props> = ({ show, onClose }) => {
  const styles = useStyles();
  const securityImage = useObservable(auditLogQuery.securityImage);

  if (securityImage.status === SecurityImageStatus.Loaded)
    return (
      <StyledDialog maxWidth="lg" title={`Security Image`} onClose={onClose} open={show}>
        <DialogContent>
          <img src={securityImage.url} className={styles.img} alt="" />
        </DialogContent>
      </StyledDialog>
    );
  if (securityImage.status === SecurityImageStatus.NotFound)
    return (
      <StyledDialog title="Error" onClose={onClose} open={show}>
        <DialogContent>
          <Typography>No Image Found</Typography>
        </DialogContent>
      </StyledDialog>
    );
  return <></>;
};
