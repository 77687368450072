import { useContext, memo } from 'react';
import TagManager from 'react-gtm-module';
import { TagManagerContext } from '../utils';

export const Analytics = memo(function AnalyticsComponent(props: any) {
  const context = useContext(TagManagerContext);

  if (context.initialized) {
    const tagManagerArgs = {
      dataLayer: props,
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  return null;
});
