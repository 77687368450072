import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/operators';

import { todayAndBefore, activeStay } from '../utils';
import { UserStaysState, UserStaysStore, userStaysStore } from './user-stays.store';

export class UserStaysQuery extends QueryEntity<UserStaysState> {
  constructor(protected store: UserStaysStore) {
    super(store);
  }

  loading = this.selectLoading();

  stays = this.selectAll();
  activeStay = this.selectActive();
  pastStays = this.selectAll().pipe(map(stays => todayAndBefore(stays)));
  guestRooms = this.selectAll().pipe(map(stays => activeStay(stays).map(code => code.roomNumber)));
}

export const userStaysQuery = new UserStaysQuery(userStaysStore);
