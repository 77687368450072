import { Reservations } from '../api';

export type GuestRelation = Reservations.GuestRelationModel;

export type GuestRelationTypeEnum = Reservations.GuestRelationType;
export const GuestRelationTypeEnum = Reservations.GuestRelationType;

export const GuestRelationTypeMap = new Map<GuestRelationTypeEnum, string>([
  [GuestRelationTypeEnum.HeadOfHousehold, 'Head of Household'],
  [GuestRelationTypeEnum.Spouse, 'Spouse'],
  [GuestRelationTypeEnum.ChildInfant, 'Child - Infant'],
  [GuestRelationTypeEnum.ChildNonInfant, 'Child - Non Infant'],
  [GuestRelationTypeEnum.OtherRelation, 'Other Relation'],
  [GuestRelationTypeEnum.Unrelated, 'Unrelated'],
]);
