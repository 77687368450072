import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { AppColors } from '../theme';
import { AppConfig } from '@lib/state';

interface StyleProps {
  dismissed: boolean;
  color: string;
}

const useStyles = makeStyles({
  banner: ({ color, dismissed }: StyleProps) => ({
    zIndex: 100,
    position: 'absolute',
    display: dismissed ? 'none' : 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    fontSize: '9px',
    borderTopWidth: 3,
    borderTopStyle: 'solid',
    borderTopColor: color,
    cursor: 'pointer',
  }),
  label: ({ color }: StyleProps) => ({
    backgroundColor: color,
    color: AppColors.White,
    padding: '0 8px 3px',
    borderBottomLeftRadius: 3,
  }),
});

interface Props {
  environment?: string;
  version?: string;
}

export const EnvironmentBanner: React.FC<Props> = ({
  environment = AppConfig.Api.EnvironmentName,
  version = AppConfig.Version,
}) => {
  // hidden when environment is production, otherwise conditionally once clicked
  const [dismissed, setDismissed] = useState(environment === 'production');

  // vary color by environment
  const { banner, label } = useStyles({ dismissed, color: getEnvironmentColor(environment) });

  return (
    <div className={banner} onClick={() => setDismissed(true)}>
      <span className={label}>
        {environment} {version}
      </span>
    </div>
  );
};

function getEnvironmentColor(environment: string) {
  switch (environment) {
    case 'production':
      return AppColors.DarkGreen;
    case 'staging':
      return AppColors.Red;
    default:
      return AppColors.DarkBlue;
  }
}
