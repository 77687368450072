import { useEffect } from 'react';

import { Property } from '@lib/state';
import { useObservable } from '@lib/common';
import { GuestStay } from '../guest-stays/guest-stays.model';
import { guestNotesService } from './guest-notes.service';
import { guestNoteQuery } from './guest-notes.query';

type UseGuestNoteParams = {
  propertyId?: Property['id'] | null;
  guestStayId?: GuestStay['id'] | null;
  guests:
    | string // UserId
    | Array<{ userId?: string }>
    | undefined
    | null; // or an object with a guests array
};

export function useGuestNotes({ propertyId, guests, guestStayId }: UseGuestNoteParams) {
  let userId: string;
  if (typeof guests === 'string') {
    userId = guests;
  } else if (guests && guests[0]) {
    userId = guests[0]?.userId ? guests[0].userId : '';
  } else {
    userId = '';
  }

  useEffect(() => {
    if (propertyId && userId !== '') {
      guestNotesService.getGuestNotes(propertyId, userId, guestStayId ? guestStayId : undefined);
    }
  }, [propertyId, userId, guestStayId]);

  return useObservable(guestNoteQuery.guestNotes, 'async');
}
