import React, { useCallback, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import {
  Contact,
  FormStatus,
  Name,
  Reservation,
  reservationQuery,
  reservationService,
  ClaimGuestContactRequest,
  ClaimRequest,
  ReservationForms,
} from '@lib/state';
import { kioskStyles } from '../../guest-kiosk.theme';
import { ContactInformationForm, LoadingIndicator, NameForm, Section } from '../../components';
import { FormHub, SubmitButton, useFormEvents, FormTextField, ErrorDisplay } from '../../forms';
import { useObservable } from '../../utils';

interface ClaimRequestFormFields {
  contact: Contact;
  name: Name;
  others: ClaimGuestContactRequest[];
}

interface Props {
  reservationId: Reservation['id'];
  code: number;
  onVerificationFailed(): void;
  onVerificationSucceeded(): void;
}

export const ClaimAccountComponent: React.FC<Props> = ({
  reservationId,
  code,
  onVerificationFailed,
  onVerificationSucceeded,
}) => {
  const { formInstructions } = kioskStyles();

  const [{ status: verificationCodeStatus }] = useFormEvents(ReservationForms.VerifyCode);
  const [{ status: verificationGuestStatus, error: guestError }] = useFormEvents(
    ReservationForms.VerifyGuest
  );

  const claimGuests = useObservable(reservationQuery.claimGuests, 'async');

  const confirmGuestDetails = useCallback(
    (request: ClaimRequestFormFields) => {
      let userKey = !!claimGuests?.guest.hasAccount ? claimGuests.guest.userKey : undefined;

      reservationService.updateVerifiedGuest(
        request.contact,
        request.name,
        request.others ?? [],
        userKey
      );
    },
    [claimGuests]
  );

    useEffect(() => reservationService.checkVerificationCode(reservationId, code), [
        reservationId,
        code,
    ]);

  useEffect(() => {
    if (verificationCodeStatus === FormStatus.Error) {
      onVerificationFailed();
    }
  }, [verificationCodeStatus]);

  useEffect(() => {
    if (verificationGuestStatus === FormStatus.Success) {
      onVerificationSucceeded();
    }
  }, [verificationGuestStatus]);

  useEffect(() => {
    // automatically confirm details if we verified an existing user account
    if (claimGuests?.skipVerification)
    {
      onVerificationSucceeded();
    }
  }, [claimGuests]);

  if (!claimGuests) return <LoadingIndicator loadingText="Verifying Code..." fillContainer />;

  return (
    <FormHub<ClaimRequestFormFields> onSubmit={confirmGuestDetails}>
      <Typography
        variant="h4"
        color="textPrimary"
        align="center"
        paragraph
        className={formInstructions}
      >
        Please confirm the guest information below and try to provide any missing information.
      </Typography>
      <ContactInformationForm
        email={claimGuests.guest.contact.contact.email}
        phone={claimGuests.guest.contact.contact.phone}
      />
      <NameForm name={claimGuests.guest.contact.name} />
      {claimGuests?.otherGuests.length > 0 && (
        <Section title="Additional Guests">
          {claimGuests.otherGuests.map((m, i) => (
            <Box mt={1} mb={2} key={i}>
              <FormTextField type="hidden" name={`others[${i}].userId`} defaultValue={m.userId} />
              <ContactInformationForm
                email={m.contact?.email}
                phone={m.contact?.phone}
                prefix={`others[${i}]`}
              />
              <NameForm prefix={`others[${i}]`} name={m.name} />
            </Box>
          ))}
        </Section>
      )}
      <Box py={2}>
        <ErrorDisplay error={guestError} />
        <SubmitButton name="claimAccount" variant="contained" color="secondary" fullWidth>
          Continue
        </SubmitButton>
      </Box>
    </FormHub>
  );
};

function getFormValues(claimGuests: ClaimRequest) {
  const { email, phone } = claimGuests.guest.contact.contact;
  const { first, last } = claimGuests.guest.contact.name;
  return {
    contact: {
      email: email ?? null,
      phone: phone ?? null,
    },
    name: {
      first: first ?? null,
      last: last ?? null,
    },
    others: claimGuests.otherGuests,
  } as ClaimRequestFormFields;
}
