import React from 'react';
import { responsiveFontSizes, createTheme } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppColors } from './theme';
import { KioskColors } from './guest-kiosk.theme';

export const frontDeskTheme = responsiveFontSizes(
  createTheme({
    palette: {
      background: {
        default: common.white,
        paper: common.white,
      },
      primary: {
        main: AppColors.Blue,
        dark: AppColors.DarkBlue,
        contrastText: common.white,
      },
      secondary: {
        main: AppColors.LightBlue,
        contrastText: common.white,
      },
      divider: KioskColors.Divider,
      grey: {
        '300': AppColors.LightGray,
        '500': AppColors.Gray,
        '800': AppColors.DarkGray,
      },
      text: {
        primary: AppColors.DarkGray,
        secondary: AppColors.MediumGray,
      },
      action: {
        disabled: AppColors.MediumGray,
        disabledBackground: AppColors.LightGray,
      },
    },
    shape: {
      borderRadius: 12,
    },
    tapeChart: {
      headerSpacingTop: '0',
      rowHeight: 62,
    },
    loadingIndicator: {
      dotSize: 10,
    },
    mixins: {
      toolbar: {
        minHeight: 0,
      },
    },
    typography: {
      h1: {
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 'bold',
        fontSize: '2rem',
      },
      h2: {
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 400,
        fontSize: '1.75rem',
      },
      h3: {
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 600,
        fontSize: '1.5rem',
      },
      h4: {
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 400,
        fontSize: '1.25rem',
      },
      h5: {
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 'bold',
        fontSize: '1.25rem',
      },
      body1: {
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 400,
        fontSize: '1rem',
      },
      subtitle2: {
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: '1rem',
      },
      button: {
        color: KioskColors.ViewBackground,
        fontFamily: '"Roboto","Helvetica",sans-serif',
        fontWeight: 600,
        textTransform: 'none',
        fontSize: '1.25rem !important',
      },
    },
    props: {
      MuiAlert: {
        iconMapping: {
          success: <FontAwesomeIcon icon="check-circle" />,
          info: <FontAwesomeIcon icon="info-circle" />,
          warning: <FontAwesomeIcon icon="exclamation-circle" />,
          error: <FontAwesomeIcon icon="times-circle" />,
        },
      },
    },
    overrides: {
      MuiToolbar: {
        dense: {
          minHeight: 56,
        },
      },
      MuiButton: {
        root: {
          borderRadius: '6px',
        },
      },
      MuiOutlinedInput: {
        root: {
          color: common.white,
          border: `1px solid ${common.white}`,
          borderRadius: '6px',
          '&::placeholder': {
            color: common.white,
          },
        },
      },
      MuiInputLabel: {
        outlined: {
          color: common.white,
        },
      },
      MuiSelect: {
        outlined: {
          color: common.white,
          textAlign: 'left',
        },
      },
    },
  })
);
