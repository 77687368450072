// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { BulkCancelReservationRequest } from '../models';
import { BulkCancelReservationsPreviewResponse } from '../models';
import { BulkCancelReservationsResponse } from '../models';
import { BulkUpdateReservationRequest } from '../models';
import { CancelReservationRequest } from '../models';
import { ChangeReservationBillingRequest } from '../models';
import { CheckInReservationRequest } from '../models';
import { CheckInReservationResponse } from '../models';
import { CheckOutReservationRequest } from '../models';
import { ClaimReservationRequest } from '../models';
import { CreateReservationRequest } from '../models';
import { CreateReservationResponse } from '../models';
import { FeeListResponse } from '../models';
import { GetCheckInRoomOptionsListResponse } from '../models';
import { GetReservationItemResponse } from '../models';
import { GetReservationPolicyItemResponse } from '../models';
import { GetReservationsByIdsRequest } from '../models';
import { GetReservationsByIdsResponse } from '../models';
import { NoContentResult } from '../models';
import { OverrideablePaymentRequest } from '../models';
import { ProblemDetails } from '../models';
import { RebookPreviewReservationRequest } from '../models';
import { RebookPreviewResponse } from '../models';
import { RebookReservationRequest } from '../models';
import { RebookReservationResponse } from '../models';
import { RefundReservationChargeRequest } from '../models';
import { RefundReservationChargeResponse } from '../models';
import { RefundStayDateRequest } from '../models';
import { ReservationActivityType } from '../models';
import { ReservationBillingChargesModel } from '../models';
import { ReservationChangeCostCenterFieldsRequest } from '../models';
import { ReservationChangeModel } from '../models';
import { ReservationChangeRoomRequest } from '../models';
import { ReservationModel } from '../models';
import { ReservationModelListResponse } from '../models';
import { ReservationModificationModel } from '../models';
import { UpdateReservationRequest } from '../models';
import { UpdateReservationSleepScheduleRequest } from '../models';
import { ValidateBookingRequest } from '../models';
import { ValidateBookingResponse } from '../models';
import { ValidateBulkReservationChangesRequest } from '../models';
import { ValidateBulkReservationChangesResponse } from '../models';
import { VerifyCodeResponse } from '../models';
import { VerifyGuestRequest } from '../models';
import { VerifyGuestResponse } from '../models';
/**
 * ReservationApi - axios parameter creator
 * @export
 */
export const ReservationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {BulkCancelReservationRequest} [bulkCancelReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationBulkCancelPost(
      bulkCancelReservationRequest?: BulkCancelReservationRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/reservation/bulk/cancel`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof bulkCancelReservationRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            bulkCancelReservationRequest !== undefined ? bulkCancelReservationRequest : {}
          )
        : bulkCancelReservationRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {BulkCancelReservationRequest} [bulkCancelReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationBulkCancelPreviewPost(
      bulkCancelReservationRequest?: BulkCancelReservationRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/reservation/bulk/cancel/preview`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof bulkCancelReservationRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            bulkCancelReservationRequest !== undefined ? bulkCancelReservationRequest : {}
          )
        : bulkCancelReservationRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {BulkUpdateReservationRequest} [bulkUpdateReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationBulkModifyPost(
      bulkUpdateReservationRequest?: BulkUpdateReservationRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/reservation/bulk/modify`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof bulkUpdateReservationRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            bulkUpdateReservationRequest !== undefined ? bulkUpdateReservationRequest : {}
          )
        : bulkUpdateReservationRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ValidateBulkReservationChangesRequest} [validateBulkReservationChangesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationBulkValidatePost(
      validateBulkReservationChangesRequest?: ValidateBulkReservationChangesRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/reservation/bulk/validate`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof validateBulkReservationChangesRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            validateBulkReservationChangesRequest !== undefined
              ? validateBulkReservationChangesRequest
              : {}
          )
        : validateBulkReservationChangesRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetReservationsByIdsRequest} [getReservationsByIdsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationByIdsPost(
      getReservationsByIdsRequest?: GetReservationsByIdsRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/reservation/by-ids`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof getReservationsByIdsRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            getReservationsByIdsRequest !== undefined ? getReservationsByIdsRequest : {}
          )
        : getReservationsByIdsRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CancelReservationRequest} [cancelReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationCancelPost(
      cancelReservationRequest?: CancelReservationRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/reservation/cancel`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof cancelReservationRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cancelReservationRequest !== undefined ? cancelReservationRequest : {})
        : cancelReservationRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CheckInReservationRequest} [checkInReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationCheckinPost(
      checkInReservationRequest?: CheckInReservationRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/reservation/checkin`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof checkInReservationRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(checkInReservationRequest !== undefined ? checkInReservationRequest : {})
        : checkInReservationRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CheckOutReservationRequest} [checkOutReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationCheckoutPost(
      checkOutReservationRequest?: CheckOutReservationRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/reservation/checkout`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof checkOutReservationRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(checkOutReservationRequest !== undefined ? checkOutReservationRequest : {})
        : checkOutReservationRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [reservationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationClaimCodeGet(reservationId?: string, options: any = {}): RequestArgs {
      const localVarPath = `/reservation/claim/code`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (reservationId !== undefined) {
        localVarQueryParameter['reservationId'] = reservationId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ClaimReservationRequest} [claimReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationClaimPost(
      claimReservationRequest?: ClaimReservationRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/reservation/claim`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof claimReservationRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(claimReservationRequest !== undefined ? claimReservationRequest : {})
        : claimReservationRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [reservationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationClaimSendPost(reservationId?: string, options: any = {}): RequestArgs {
      const localVarPath = `/reservation/claim/send`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (reservationId !== undefined) {
        localVarQueryParameter['reservationId'] = reservationId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {VerifyGuestRequest} [verifyGuestRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationClaimVerifyPost(
      verifyGuestRequest?: VerifyGuestRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/reservation/claim/verify`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof verifyGuestRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(verifyGuestRequest !== undefined ? verifyGuestRequest : {})
        : verifyGuestRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {ReservationActivityType} activityType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdChangeActivityTypeGet(
      id: string,
      activityType: ReservationActivityType,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdChangeActivityTypeGet.'
        );
      }
      // verify required parameter 'activityType' is not null or undefined
      if (activityType === null || activityType === undefined) {
        throw new RequiredError(
          'activityType',
          'Required parameter activityType was null or undefined when calling reservationIdChangeActivityTypeGet.'
        );
      }
      const localVarPath = `/reservation/{id}/change/{activityType}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'activityType'}}`, encodeURIComponent(String(activityType)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {ReservationChangeCostCenterFieldsRequest} [reservationChangeCostCenterFieldsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdChangecostcenterfieldsPost(
      id: string,
      reservationChangeCostCenterFieldsRequest?: ReservationChangeCostCenterFieldsRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdChangecostcenterfieldsPost.'
        );
      }
      const localVarPath = `/reservation/{id}/changecostcenterfields`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof reservationChangeCostCenterFieldsRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            reservationChangeCostCenterFieldsRequest !== undefined
              ? reservationChangeCostCenterFieldsRequest
              : {}
          )
        : reservationChangeCostCenterFieldsRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {ReservationChangeRoomRequest} [reservationChangeRoomRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdChangeroomPost(
      id: string,
      reservationChangeRoomRequest?: ReservationChangeRoomRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdChangeroomPost.'
        );
      }
      const localVarPath = `/reservation/{id}/changeroom`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof reservationChangeRoomRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            reservationChangeRoomRequest !== undefined ? reservationChangeRoomRequest : {}
          )
        : reservationChangeRoomRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdCheckinGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdCheckinGet.'
        );
      }
      const localVarPath = `/reservation/{id}/checkin`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdCheckinOptionsGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdCheckinOptionsGet.'
        );
      }
      const localVarPath = `/reservation/{id}/checkin/options`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdEditGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdEditGet.'
        );
      }
      const localVarPath = `/reservation/{id}/edit`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdGet.'
        );
      }
      const localVarPath = `/reservation/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdLateCheckoutGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdLateCheckoutGet.'
        );
      }
      const localVarPath = `/reservation/{id}/late-checkout`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {OverrideablePaymentRequest} [overrideablePaymentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdLateCheckoutPost(
      id: string,
      overrideablePaymentRequest?: OverrideablePaymentRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdLateCheckoutPost.'
        );
      }
      const localVarPath = `/reservation/{id}/late-checkout`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof overrideablePaymentRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(overrideablePaymentRequest !== undefined ? overrideablePaymentRequest : {})
        : overrideablePaymentRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdPolicyGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdPolicyGet.'
        );
      }
      const localVarPath = `/reservation/{id}/policy`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateReservationRequest} [updateReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdPost(
      id: string,
      updateReservationRequest?: UpdateReservationRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdPost.'
        );
      }
      const localVarPath = `/reservation/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateReservationRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateReservationRequest !== undefined ? updateReservationRequest : {})
        : updateReservationRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {RefundReservationChargeRequest} [refundReservationChargeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdRefundPost(
      id: string,
      refundReservationChargeRequest?: RefundReservationChargeRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdRefundPost.'
        );
      }
      const localVarPath = `/reservation/{id}/refund`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof refundReservationChargeRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            refundReservationChargeRequest !== undefined ? refundReservationChargeRequest : {}
          )
        : refundReservationChargeRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {RefundStayDateRequest} [refundStayDateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdRefundStayPost(
      id: string,
      refundStayDateRequest?: RefundStayDateRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdRefundStayPost.'
        );
      }
      const localVarPath = `/reservation/{id}/refund/stay`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof refundStayDateRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(refundStayDateRequest !== undefined ? refundStayDateRequest : {})
        : refundStayDateRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdReservationBillingGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdReservationBillingGet.'
        );
      }
      const localVarPath = `/reservation/{id}/reservation-billing`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {ChangeReservationBillingRequest} [changeReservationBillingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdReservationBillingPost(
      id: string,
      changeReservationBillingRequest?: ChangeReservationBillingRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdReservationBillingPost.'
        );
      }
      const localVarPath = `/reservation/{id}/reservation-billing`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof changeReservationBillingRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            changeReservationBillingRequest !== undefined ? changeReservationBillingRequest : {}
          )
        : changeReservationBillingRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateReservationSleepScheduleRequest} [updateReservationSleepScheduleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdSleepSchedulePut(
      id: string,
      updateReservationSleepScheduleRequest?: UpdateReservationSleepScheduleRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationIdSleepSchedulePut.'
        );
      }
      const localVarPath = `/reservation/{id}/sleep-schedule`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateReservationSleepScheduleRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            updateReservationSleepScheduleRequest !== undefined
              ? updateReservationSleepScheduleRequest
              : {}
          )
        : updateReservationSleepScheduleRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateReservationRequest} [createReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationManagerPost(
      createReservationRequest?: CreateReservationRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/reservation/manager`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createReservationRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createReservationRequest !== undefined ? createReservationRequest : {})
        : createReservationRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateReservationRequest} [createReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationPost(
      createReservationRequest?: CreateReservationRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/reservation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createReservationRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createReservationRequest !== undefined ? createReservationRequest : {})
        : createReservationRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {RebookReservationRequest} [rebookReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationRebookIdPost(
      id: string,
      rebookReservationRequest?: RebookReservationRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationRebookIdPost.'
        );
      }
      const localVarPath = `/reservation/rebook/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof rebookReservationRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(rebookReservationRequest !== undefined ? rebookReservationRequest : {})
        : rebookReservationRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {RebookPreviewReservationRequest} [rebookPreviewReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationRebookPreviewIdPost(
      id: string,
      rebookPreviewReservationRequest?: RebookPreviewReservationRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling reservationRebookPreviewIdPost.'
        );
      }
      const localVarPath = `/reservation/rebook-preview/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof rebookPreviewReservationRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            rebookPreviewReservationRequest !== undefined ? rebookPreviewReservationRequest : {}
          )
        : rebookPreviewReservationRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationRoomRoomIdGet(roomId: string, options: any = {}): RequestArgs {
      // verify required parameter 'roomId' is not null or undefined
      if (roomId === null || roomId === undefined) {
        throw new RequiredError(
          'roomId',
          'Required parameter roomId was null or undefined when calling reservationRoomRoomIdGet.'
        );
      }
      const localVarPath = `/reservation/room/{roomId}`.replace(
        `{${'roomId'}}`,
        encodeURIComponent(String(roomId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ValidateBookingRequest} [validateBookingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationValidatePost(
      validateBookingRequest?: ValidateBookingRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/reservation/validate`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof validateBookingRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(validateBookingRequest !== undefined ? validateBookingRequest : {})
        : validateBookingRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReservationApi - functional programming interface
 * @export
 */
export const ReservationApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {BulkCancelReservationRequest} [bulkCancelReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationBulkCancelPost(
      bulkCancelReservationRequest?: BulkCancelReservationRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkCancelReservationsResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationBulkCancelPost(bulkCancelReservationRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {BulkCancelReservationRequest} [bulkCancelReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationBulkCancelPreviewPost(
      bulkCancelReservationRequest?: BulkCancelReservationRequest,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<BulkCancelReservationsPreviewResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationBulkCancelPreviewPost(bulkCancelReservationRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {BulkUpdateReservationRequest} [bulkUpdateReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationBulkModifyPost(
      bulkUpdateReservationRequest?: BulkUpdateReservationRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationModelListResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationBulkModifyPost(bulkUpdateReservationRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {ValidateBulkReservationChangesRequest} [validateBulkReservationChangesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationBulkValidatePost(
      validateBulkReservationChangesRequest?: ValidateBulkReservationChangesRequest,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<ValidateBulkReservationChangesResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationBulkValidatePost(validateBulkReservationChangesRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {GetReservationsByIdsRequest} [getReservationsByIdsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationByIdsPost(
      getReservationsByIdsRequest?: GetReservationsByIdsRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReservationsByIdsResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(configuration).reservationByIdsPost(
        getReservationsByIdsRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {CancelReservationRequest} [cancelReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationCancelPost(
      cancelReservationRequest?: CancelReservationRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeeListResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationCancelPost(cancelReservationRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {CheckInReservationRequest} [checkInReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationCheckinPost(
      checkInReservationRequest?: CheckInReservationRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckInReservationResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationCheckinPost(checkInReservationRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {CheckOutReservationRequest} [checkOutReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationCheckoutPost(
      checkOutReservationRequest?: CheckOutReservationRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeeListResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationCheckoutPost(checkOutReservationRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [reservationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationClaimCodeGet(
      reservationId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyCodeResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationClaimCodeGet(reservationId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {ClaimReservationRequest} [claimReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationClaimPost(
      claimReservationRequest?: ClaimReservationRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(configuration).reservationClaimPost(
        claimReservationRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [reservationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationClaimSendPost(
      reservationId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationClaimSendPost(reservationId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {VerifyGuestRequest} [verifyGuestRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationClaimVerifyPost(
      verifyGuestRequest?: VerifyGuestRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyGuestResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationClaimVerifyPost(verifyGuestRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {ReservationActivityType} activityType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdChangeActivityTypeGet(
      id: string,
      activityType: ReservationActivityType,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationChangeModel> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationIdChangeActivityTypeGet(id, activityType, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {ReservationChangeCostCenterFieldsRequest} [reservationChangeCostCenterFieldsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdChangecostcenterfieldsPost(
      id: string,
      reservationChangeCostCenterFieldsRequest?: ReservationChangeCostCenterFieldsRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReservationItemResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationIdChangecostcenterfieldsPost(
        id,
        reservationChangeCostCenterFieldsRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {ReservationChangeRoomRequest} [reservationChangeRoomRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdChangeroomPost(
      id: string,
      reservationChangeRoomRequest?: ReservationChangeRoomRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoContentResult> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationIdChangeroomPost(id, reservationChangeRoomRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdCheckinGet(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeeListResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationIdCheckinGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdCheckinOptionsGet(
      id: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetCheckInRoomOptionsListResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationIdCheckinOptionsGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdEditGet(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationModificationModel> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(configuration).reservationIdEditGet(
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdGet(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReservationItemResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(configuration).reservationIdGet(
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdLateCheckoutGet(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeeListResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationIdLateCheckoutGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {OverrideablePaymentRequest} [overrideablePaymentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdLateCheckoutPost(
      id: string,
      overrideablePaymentRequest?: OverrideablePaymentRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationModel> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationIdLateCheckoutPost(id, overrideablePaymentRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdPolicyGet(
      id: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetReservationPolicyItemResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationIdPolicyGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateReservationRequest} [updateReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdPost(
      id: string,
      updateReservationRequest?: UpdateReservationRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReservationItemResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(configuration).reservationIdPost(
        id,
        updateReservationRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {RefundReservationChargeRequest} [refundReservationChargeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdRefundPost(
      id: string,
      refundReservationChargeRequest?: RefundReservationChargeRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefundReservationChargeResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationIdRefundPost(id, refundReservationChargeRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {RefundStayDateRequest} [refundStayDateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdRefundStayPost(
      id: string,
      refundStayDateRequest?: RefundStayDateRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefundReservationChargeResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationIdRefundStayPost(id, refundStayDateRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdReservationBillingGet(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationBillingChargesModel> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationIdReservationBillingGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {ChangeReservationBillingRequest} [changeReservationBillingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdReservationBillingPost(
      id: string,
      changeReservationBillingRequest?: ChangeReservationBillingRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReservationItemResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationIdReservationBillingPost(id, changeReservationBillingRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateReservationSleepScheduleRequest} [updateReservationSleepScheduleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdSleepSchedulePut(
      id: string,
      updateReservationSleepScheduleRequest?: UpdateReservationSleepScheduleRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReservationItemResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationIdSleepSchedulePut(id, updateReservationSleepScheduleRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {CreateReservationRequest} [createReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationManagerPost(
      createReservationRequest?: CreateReservationRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateReservationResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationManagerPost(createReservationRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {CreateReservationRequest} [createReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationPost(
      createReservationRequest?: CreateReservationRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateReservationResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(configuration).reservationPost(
        createReservationRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {RebookReservationRequest} [rebookReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationRebookIdPost(
      id: string,
      rebookReservationRequest?: RebookReservationRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RebookReservationResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationRebookIdPost(id, rebookReservationRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {RebookPreviewReservationRequest} [rebookPreviewReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationRebookPreviewIdPost(
      id: string,
      rebookPreviewReservationRequest?: RebookPreviewReservationRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RebookPreviewResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationRebookPreviewIdPost(id, rebookPreviewReservationRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationRoomRoomIdGet(
      roomId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReservationItemResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationRoomRoomIdGet(roomId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {ValidateBookingRequest} [validateBookingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationValidatePost(
      validateBookingRequest?: ValidateBookingRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateBookingResponse> {
      const localVarAxiosArgs = ReservationApiAxiosParamCreator(
        configuration
      ).reservationValidatePost(validateBookingRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ReservationApi - factory interface
 * @export
 */
export const ReservationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {BulkCancelReservationRequest} [bulkCancelReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationBulkCancelPost(
      bulkCancelReservationRequest?: BulkCancelReservationRequest,
      options?: any
    ): AxiosPromise<BulkCancelReservationsResponse> {
      return ReservationApiFp(configuration).reservationBulkCancelPost(
        bulkCancelReservationRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {BulkCancelReservationRequest} [bulkCancelReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationBulkCancelPreviewPost(
      bulkCancelReservationRequest?: BulkCancelReservationRequest,
      options?: any
    ): AxiosPromise<BulkCancelReservationsPreviewResponse> {
      return ReservationApiFp(configuration).reservationBulkCancelPreviewPost(
        bulkCancelReservationRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {BulkUpdateReservationRequest} [bulkUpdateReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationBulkModifyPost(
      bulkUpdateReservationRequest?: BulkUpdateReservationRequest,
      options?: any
    ): AxiosPromise<ReservationModelListResponse> {
      return ReservationApiFp(configuration).reservationBulkModifyPost(
        bulkUpdateReservationRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {ValidateBulkReservationChangesRequest} [validateBulkReservationChangesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationBulkValidatePost(
      validateBulkReservationChangesRequest?: ValidateBulkReservationChangesRequest,
      options?: any
    ): AxiosPromise<ValidateBulkReservationChangesResponse> {
      return ReservationApiFp(configuration).reservationBulkValidatePost(
        validateBulkReservationChangesRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {GetReservationsByIdsRequest} [getReservationsByIdsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationByIdsPost(
      getReservationsByIdsRequest?: GetReservationsByIdsRequest,
      options?: any
    ): AxiosPromise<GetReservationsByIdsResponse> {
      return ReservationApiFp(configuration).reservationByIdsPost(
        getReservationsByIdsRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {CancelReservationRequest} [cancelReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationCancelPost(
      cancelReservationRequest?: CancelReservationRequest,
      options?: any
    ): AxiosPromise<FeeListResponse> {
      return ReservationApiFp(configuration).reservationCancelPost(
        cancelReservationRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {CheckInReservationRequest} [checkInReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationCheckinPost(
      checkInReservationRequest?: CheckInReservationRequest,
      options?: any
    ): AxiosPromise<CheckInReservationResponse> {
      return ReservationApiFp(configuration).reservationCheckinPost(
        checkInReservationRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {CheckOutReservationRequest} [checkOutReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationCheckoutPost(
      checkOutReservationRequest?: CheckOutReservationRequest,
      options?: any
    ): AxiosPromise<FeeListResponse> {
      return ReservationApiFp(configuration).reservationCheckoutPost(
        checkOutReservationRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} [reservationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationClaimCodeGet(
      reservationId?: string,
      options?: any
    ): AxiosPromise<VerifyCodeResponse> {
      return ReservationApiFp(configuration).reservationClaimCodeGet(reservationId, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {ClaimReservationRequest} [claimReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationClaimPost(
      claimReservationRequest?: ClaimReservationRequest,
      options?: any
    ): AxiosPromise<void> {
      return ReservationApiFp(configuration).reservationClaimPost(claimReservationRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} [reservationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationClaimSendPost(reservationId?: string, options?: any): AxiosPromise<void> {
      return ReservationApiFp(configuration).reservationClaimSendPost(reservationId, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {VerifyGuestRequest} [verifyGuestRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationClaimVerifyPost(
      verifyGuestRequest?: VerifyGuestRequest,
      options?: any
    ): AxiosPromise<VerifyGuestResponse> {
      return ReservationApiFp(configuration).reservationClaimVerifyPost(
        verifyGuestRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {ReservationActivityType} activityType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdChangeActivityTypeGet(
      id: string,
      activityType: ReservationActivityType,
      options?: any
    ): AxiosPromise<ReservationChangeModel> {
      return ReservationApiFp(configuration).reservationIdChangeActivityTypeGet(
        id,
        activityType,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {ReservationChangeCostCenterFieldsRequest} [reservationChangeCostCenterFieldsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdChangecostcenterfieldsPost(
      id: string,
      reservationChangeCostCenterFieldsRequest?: ReservationChangeCostCenterFieldsRequest,
      options?: any
    ): AxiosPromise<GetReservationItemResponse> {
      return ReservationApiFp(configuration).reservationIdChangecostcenterfieldsPost(
        id,
        reservationChangeCostCenterFieldsRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {ReservationChangeRoomRequest} [reservationChangeRoomRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdChangeroomPost(
      id: string,
      reservationChangeRoomRequest?: ReservationChangeRoomRequest,
      options?: any
    ): AxiosPromise<NoContentResult> {
      return ReservationApiFp(configuration).reservationIdChangeroomPost(
        id,
        reservationChangeRoomRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdCheckinGet(id: string, options?: any): AxiosPromise<FeeListResponse> {
      return ReservationApiFp(configuration).reservationIdCheckinGet(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdCheckinOptionsGet(
      id: string,
      options?: any
    ): AxiosPromise<GetCheckInRoomOptionsListResponse> {
      return ReservationApiFp(configuration).reservationIdCheckinOptionsGet(id, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdEditGet(id: string, options?: any): AxiosPromise<ReservationModificationModel> {
      return ReservationApiFp(configuration).reservationIdEditGet(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdGet(id: string, options?: any): AxiosPromise<GetReservationItemResponse> {
      return ReservationApiFp(configuration).reservationIdGet(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdLateCheckoutGet(id: string, options?: any): AxiosPromise<FeeListResponse> {
      return ReservationApiFp(configuration).reservationIdLateCheckoutGet(id, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} id
     * @param {OverrideablePaymentRequest} [overrideablePaymentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdLateCheckoutPost(
      id: string,
      overrideablePaymentRequest?: OverrideablePaymentRequest,
      options?: any
    ): AxiosPromise<ReservationModel> {
      return ReservationApiFp(configuration).reservationIdLateCheckoutPost(
        id,
        overrideablePaymentRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdPolicyGet(
      id: string,
      options?: any
    ): AxiosPromise<GetReservationPolicyItemResponse> {
      return ReservationApiFp(configuration).reservationIdPolicyGet(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {UpdateReservationRequest} [updateReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdPost(
      id: string,
      updateReservationRequest?: UpdateReservationRequest,
      options?: any
    ): AxiosPromise<GetReservationItemResponse> {
      return ReservationApiFp(configuration).reservationIdPost(
        id,
        updateReservationRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {RefundReservationChargeRequest} [refundReservationChargeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdRefundPost(
      id: string,
      refundReservationChargeRequest?: RefundReservationChargeRequest,
      options?: any
    ): AxiosPromise<RefundReservationChargeResponse> {
      return ReservationApiFp(configuration).reservationIdRefundPost(
        id,
        refundReservationChargeRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {RefundStayDateRequest} [refundStayDateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdRefundStayPost(
      id: string,
      refundStayDateRequest?: RefundStayDateRequest,
      options?: any
    ): AxiosPromise<RefundReservationChargeResponse> {
      return ReservationApiFp(configuration).reservationIdRefundStayPost(
        id,
        refundStayDateRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdReservationBillingGet(
      id: string,
      options?: any
    ): AxiosPromise<ReservationBillingChargesModel> {
      return ReservationApiFp(configuration).reservationIdReservationBillingGet(id, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} id
     * @param {ChangeReservationBillingRequest} [changeReservationBillingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdReservationBillingPost(
      id: string,
      changeReservationBillingRequest?: ChangeReservationBillingRequest,
      options?: any
    ): AxiosPromise<GetReservationItemResponse> {
      return ReservationApiFp(configuration).reservationIdReservationBillingPost(
        id,
        changeReservationBillingRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {UpdateReservationSleepScheduleRequest} [updateReservationSleepScheduleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationIdSleepSchedulePut(
      id: string,
      updateReservationSleepScheduleRequest?: UpdateReservationSleepScheduleRequest,
      options?: any
    ): AxiosPromise<GetReservationItemResponse> {
      return ReservationApiFp(configuration).reservationIdSleepSchedulePut(
        id,
        updateReservationSleepScheduleRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {CreateReservationRequest} [createReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationManagerPost(
      createReservationRequest?: CreateReservationRequest,
      options?: any
    ): AxiosPromise<CreateReservationResponse> {
      return ReservationApiFp(configuration).reservationManagerPost(
        createReservationRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {CreateReservationRequest} [createReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationPost(
      createReservationRequest?: CreateReservationRequest,
      options?: any
    ): AxiosPromise<CreateReservationResponse> {
      return ReservationApiFp(configuration).reservationPost(createReservationRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} id
     * @param {RebookReservationRequest} [rebookReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationRebookIdPost(
      id: string,
      rebookReservationRequest?: RebookReservationRequest,
      options?: any
    ): AxiosPromise<RebookReservationResponse> {
      return ReservationApiFp(configuration).reservationRebookIdPost(
        id,
        rebookReservationRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {RebookPreviewReservationRequest} [rebookPreviewReservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationRebookPreviewIdPost(
      id: string,
      rebookPreviewReservationRequest?: RebookPreviewReservationRequest,
      options?: any
    ): AxiosPromise<RebookPreviewResponse> {
      return ReservationApiFp(configuration).reservationRebookPreviewIdPost(
        id,
        rebookPreviewReservationRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationRoomRoomIdGet(
      roomId: string,
      options?: any
    ): AxiosPromise<GetReservationItemResponse> {
      return ReservationApiFp(configuration).reservationRoomRoomIdGet(roomId, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {ValidateBookingRequest} [validateBookingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reservationValidatePost(
      validateBookingRequest?: ValidateBookingRequest,
      options?: any
    ): AxiosPromise<ValidateBookingResponse> {
      return ReservationApiFp(configuration).reservationValidatePost(
        validateBookingRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ReservationApi - object-oriented interface
 * @export
 * @class ReservationApi
 * @extends {BaseAPI}
 */
export class ReservationApi extends BaseAPI {
  /**
   *
   * @param {BulkCancelReservationRequest} [bulkCancelReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationBulkCancelPost(
    bulkCancelReservationRequest?: BulkCancelReservationRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationBulkCancelPost(
      bulkCancelReservationRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {BulkCancelReservationRequest} [bulkCancelReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationBulkCancelPreviewPost(
    bulkCancelReservationRequest?: BulkCancelReservationRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationBulkCancelPreviewPost(
      bulkCancelReservationRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {BulkUpdateReservationRequest} [bulkUpdateReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationBulkModifyPost(
    bulkUpdateReservationRequest?: BulkUpdateReservationRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationBulkModifyPost(
      bulkUpdateReservationRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {ValidateBulkReservationChangesRequest} [validateBulkReservationChangesRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationBulkValidatePost(
    validateBulkReservationChangesRequest?: ValidateBulkReservationChangesRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationBulkValidatePost(
      validateBulkReservationChangesRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {GetReservationsByIdsRequest} [getReservationsByIdsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationByIdsPost(
    getReservationsByIdsRequest?: GetReservationsByIdsRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationByIdsPost(
      getReservationsByIdsRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {CancelReservationRequest} [cancelReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationCancelPost(cancelReservationRequest?: CancelReservationRequest, options?: any) {
    return ReservationApiFp(this.configuration).reservationCancelPost(
      cancelReservationRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {CheckInReservationRequest} [checkInReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationCheckinPost(
    checkInReservationRequest?: CheckInReservationRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationCheckinPost(
      checkInReservationRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {CheckOutReservationRequest} [checkOutReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationCheckoutPost(
    checkOutReservationRequest?: CheckOutReservationRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationCheckoutPost(
      checkOutReservationRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [reservationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationClaimCodeGet(reservationId?: string, options?: any) {
    return ReservationApiFp(this.configuration).reservationClaimCodeGet(reservationId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {ClaimReservationRequest} [claimReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationClaimPost(claimReservationRequest?: ClaimReservationRequest, options?: any) {
    return ReservationApiFp(this.configuration).reservationClaimPost(
      claimReservationRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [reservationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationClaimSendPost(reservationId?: string, options?: any) {
    return ReservationApiFp(this.configuration).reservationClaimSendPost(reservationId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {VerifyGuestRequest} [verifyGuestRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationClaimVerifyPost(verifyGuestRequest?: VerifyGuestRequest, options?: any) {
    return ReservationApiFp(this.configuration).reservationClaimVerifyPost(
      verifyGuestRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {ReservationActivityType} activityType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdChangeActivityTypeGet(
    id: string,
    activityType: ReservationActivityType,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationIdChangeActivityTypeGet(
      id,
      activityType,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {ReservationChangeCostCenterFieldsRequest} [reservationChangeCostCenterFieldsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdChangecostcenterfieldsPost(
    id: string,
    reservationChangeCostCenterFieldsRequest?: ReservationChangeCostCenterFieldsRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationIdChangecostcenterfieldsPost(
      id,
      reservationChangeCostCenterFieldsRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {ReservationChangeRoomRequest} [reservationChangeRoomRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdChangeroomPost(
    id: string,
    reservationChangeRoomRequest?: ReservationChangeRoomRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationIdChangeroomPost(
      id,
      reservationChangeRoomRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdCheckinGet(id: string, options?: any) {
    return ReservationApiFp(this.configuration).reservationIdCheckinGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdCheckinOptionsGet(id: string, options?: any) {
    return ReservationApiFp(this.configuration).reservationIdCheckinOptionsGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdEditGet(id: string, options?: any) {
    return ReservationApiFp(this.configuration).reservationIdEditGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdGet(id: string, options?: any) {
    return ReservationApiFp(this.configuration).reservationIdGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdLateCheckoutGet(id: string, options?: any) {
    return ReservationApiFp(this.configuration).reservationIdLateCheckoutGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {OverrideablePaymentRequest} [overrideablePaymentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdLateCheckoutPost(
    id: string,
    overrideablePaymentRequest?: OverrideablePaymentRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationIdLateCheckoutPost(
      id,
      overrideablePaymentRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdPolicyGet(id: string, options?: any) {
    return ReservationApiFp(this.configuration).reservationIdPolicyGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {UpdateReservationRequest} [updateReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdPost(
    id: string,
    updateReservationRequest?: UpdateReservationRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationIdPost(
      id,
      updateReservationRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {RefundReservationChargeRequest} [refundReservationChargeRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdRefundPost(
    id: string,
    refundReservationChargeRequest?: RefundReservationChargeRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationIdRefundPost(
      id,
      refundReservationChargeRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {RefundStayDateRequest} [refundStayDateRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdRefundStayPost(
    id: string,
    refundStayDateRequest?: RefundStayDateRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationIdRefundStayPost(
      id,
      refundStayDateRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdReservationBillingGet(id: string, options?: any) {
    return ReservationApiFp(this.configuration).reservationIdReservationBillingGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {ChangeReservationBillingRequest} [changeReservationBillingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdReservationBillingPost(
    id: string,
    changeReservationBillingRequest?: ChangeReservationBillingRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationIdReservationBillingPost(
      id,
      changeReservationBillingRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {UpdateReservationSleepScheduleRequest} [updateReservationSleepScheduleRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationIdSleepSchedulePut(
    id: string,
    updateReservationSleepScheduleRequest?: UpdateReservationSleepScheduleRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationIdSleepSchedulePut(
      id,
      updateReservationSleepScheduleRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {CreateReservationRequest} [createReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationManagerPost(
    createReservationRequest?: CreateReservationRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationManagerPost(
      createReservationRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {CreateReservationRequest} [createReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationPost(createReservationRequest?: CreateReservationRequest, options?: any) {
    return ReservationApiFp(this.configuration).reservationPost(createReservationRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {RebookReservationRequest} [rebookReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationRebookIdPost(
    id: string,
    rebookReservationRequest?: RebookReservationRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationRebookIdPost(
      id,
      rebookReservationRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {RebookPreviewReservationRequest} [rebookPreviewReservationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationRebookPreviewIdPost(
    id: string,
    rebookPreviewReservationRequest?: RebookPreviewReservationRequest,
    options?: any
  ) {
    return ReservationApiFp(this.configuration).reservationRebookPreviewIdPost(
      id,
      rebookPreviewReservationRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} roomId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationRoomRoomIdGet(roomId: string, options?: any) {
    return ReservationApiFp(this.configuration).reservationRoomRoomIdGet(roomId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {ValidateBookingRequest} [validateBookingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public reservationValidatePost(validateBookingRequest?: ValidateBookingRequest, options?: any) {
    return ReservationApiFp(this.configuration).reservationValidatePost(
      validateBookingRequest,
      options
    )(this.axios, this.basePath);
  }
}
