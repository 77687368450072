import { CorporateEmployee } from '../corporate-employees';
import { GuestProfile } from '../guest-profile';
import { Contact, Name } from '../models.common';

export interface GuestLookupFields {
  hasEmployeeRole: boolean;
  contact?: Partial<Contact>;
  name?: Partial<Name>;

  corporateAccountId?: string;
  employeeNumber?: string;
}

export type GuestLookupProfile = { key: string } & GuestProfile & Partial<CorporateEmployee>;

export function mapToGuestProfile(match: GuestLookupProfile): GuestProfile {
  const { userId } = match;
  return {
    ...match,
    userId: userId ?? '',
  };
}
