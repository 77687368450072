import React from 'react';
import { ButtonProps } from '@material-ui/core';
import { Reservation, RefundReservationChargeModel } from '@lib/state';
import { PrintButton } from 'app/shared';
import { PrintableReservationReceipt } from './printable-reservation-receipt';
import { JournalAccount } from 'app/state';

interface Props extends Omit<ButtonProps, 'action' | 'onClick'> {
  reservation: Reservation;
  charges: Reservation['charges'];
  accounts: JournalAccount[];
  refundCharge: (model: RefundReservationChargeModel) => void;
}

export const PrintableReservationReceiptButton: React.FC<Props> = ({
  reservation,
  charges,
  accounts,
  refundCharge,
  className,
}) => {
  const componentToPrint = () => {
    return (
      <PrintableReservationReceipt
        reservation={reservation}
        charges={charges}
        accounts={accounts}
        refundCharge={refundCharge}
      />
    );
  };
  return (
    <PrintButton componentToPrint={componentToPrint} className={className}>
      Print
    </PrintButton>
  );
};
