import React, { useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { Grid, MenuItem, Typography, createStyles, makeStyles } from '@material-ui/core';

import { RoomType, CorporateAccount } from '@lib/state';
import { FormSelect, FormCheckbox, ButtonLink, EnumValueDisplay } from '@lib/common';
import { DownloadButton, FilterSection } from 'app/shared';
import { RatePlanSort } from 'app/state';

const useStyles = makeStyles(theme =>
  createStyles({
    newPlanButtonContainer: {
      marginLeft: 'auto',
    },
    downloadButton: {
      marginRight: theme.spacing(2),
    },
  })
);

export interface RatePlanFilterFields {
  roomTypeId: RoomType['id'];
  corporateAccountId: CorporateAccount['id'];
  showDisabled: boolean;
  ratePlanSort?: RatePlanSort;
}

export interface RatePlanExportFilterFields extends RatePlanFilterFields {
  roomTypeName: string;
}

interface Props {
  roomTypes: Array<RoomType>;
  accounts: Array<CorporateAccount>;
  onChange: (filters: RatePlanFilterFields) => void;
  onExport: (filters: RatePlanExportFilterFields) => void;
}

export const RatePlanFilters: React.FC<Props> = ({ roomTypes, accounts, onChange, onExport }) => {
  const styles = useStyles();

  const form = useForm<RatePlanFilterFields>({
    defaultValues: {
      roomTypeId: roomTypes[0].id,
      corporateAccountId: '',
      showDisabled: false,
      ratePlanSort: undefined,
    },
  });

  const { roomTypeId, corporateAccountId, showDisabled, ratePlanSort } = form.watch({ nest: true });

  const emptyAccount: CorporateAccount = {
    id: '',
    name: '--',
    customerId: '',
    invoiceSubAccount: '',
    ratePlans: [],
    fields: [],
    sendDailyHousingReport: false,
    isInvoiceEnabled: false,
  };

  const accountList: CorporateAccount[] = [emptyAccount, ...accounts];

  useEffect(() => {
    if (onChange !== null) onChange({ roomTypeId, corporateAccountId, showDisabled, ratePlanSort });
  }, [roomTypeId, corporateAccountId, showDisabled, ratePlanSort, onChange]);

  return (
    <FilterSection>
      <FormContext {...form}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormSelect
              name="ratePlanSort"
              label="Sort By"
              fullWidth
              defaultValue={RatePlanSort.Priority}
            >
              {Object.keys(RatePlanSort).map(r => (
                <MenuItem key={r} value={r}>
                  <EnumValueDisplay value={r} />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={3}>
            <FormSelect
              name="roomTypeId"
              label="Room Type"
              fullWidth
              InputLabelProps={{ shrink: !!roomTypeId }}
            >
              {roomTypes.map(x => (
                <MenuItem key={x.id} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={3}>
            <FormSelect
              name="corporateAccountId"
              label="Name"
              defaultValue=""
              fullWidth
              InputLabelProps={{ shrink: !!roomTypeId }}
            >
              {accountList.map(x => (
                <MenuItem key={x.id} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </FormSelect>{' '}
          </Grid>
          <Grid item xs={3}>
            <FormCheckbox
              name="showDisabled"
              label={<Typography variant="body2">Show Inactive</Typography>}
              color="secondary"
              fullWidth
            />
          </Grid>

          <Grid className={styles.newPlanButtonContainer} item>
            <DownloadButton
              onDownload={() => {
                const roomType = roomTypes.find(r => r.id === roomTypeId);
                const roomTypeName = roomType?.name ?? '';
                onExport({
                  roomTypeId,
                  corporateAccountId,
                  showDisabled,
                  roomTypeName,
                  ratePlanSort,
                });
              }}
              className={styles.downloadButton}
            />
            <ButtonLink to="/rates/add" variant="contained" color="secondary">
              New Rate Plan
            </ButtonLink>
          </Grid>
        </Grid>
      </FormContext>
    </FilterSection>
  );
};
