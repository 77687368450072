import { useEffect, useMemo } from 'react';

import {
  paymentMethodService,
  paymentMethodQuery,
  sessionQuery,
  Reservation,
  getPaymentUserId,
  guestProfileQuery,
  GuestProfile,
  guestProfileService,
} from '@lib/state';
import { useObservable } from './useObservable';
import { useCurrentUserId } from '.';

export function usePaymentMethods(propertyId?: string, corporateAccountId?: string) {
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');

  useEffect(() => {
    if (isLoggedIn) {
      paymentMethodService.getPaymentMethods(propertyId, corporateAccountId);
    }
  }, [isLoggedIn, propertyId, corporateAccountId]);

  return useObservable(paymentMethodQuery.availablePaymentMethods);
}

// Returns payment methods based on the guest profile passed in
// This profile may be the delegate of the reservation or the primary contact
export function useChangeBillingPaymentMethods(
  reservation: Reservation,
  bookingGuest?: GuestProfile,
  corporateAccountId?: string
) {
  return {
    paymentMethods: useGuestPaymentMethod(
      bookingGuest?.userId ?? undefined,
      reservation.propertyId,
      corporateAccountId
    ),
    guest: bookingGuest,
  };
}

export function useReservationPaymentMethods(reservation: Reservation) {
  // the guest profile is typically passed into the payment form which is used to pre-fill billing details
  const guest = usePayingGuest(reservation);

  return {
    paymentMethods: useGuestPaymentMethod(
      guest?.userId ?? undefined,
      reservation.propertyId,
      reservation.affiliation?.corporateAccountId ?? undefined
    ),
    guest,
  };
}

export function useGuestPaymentMethod(
  userId?: string,
  propertyId?: string,
  corporateAccountId?: string
) {
  const paymentMethods = useObservable(paymentMethodQuery.paymentMethods);
  const invoiceAccounts = useObservable(paymentMethodQuery.invoiceAccounts);

  useEffect(() => {
    if (userId && propertyId) {
      paymentMethodService.getGuestPaymentMethods(userId, propertyId, corporateAccountId);
    }
    return () => paymentMethodService.reset();
  }, [userId, propertyId, corporateAccountId]);

  return {
    creditCards: paymentMethods,
    invoiceAccounts,
  };
}

function usePayingGuest(reservation: Reservation): GuestProfile {
  const loggedInUserId = useCurrentUserId();
  const isEmployee = useObservable(sessionQuery.isEmployee);
  const guest = useObservable(guestProfileQuery.guest);
  const contact = reservation.contacts.find(x => x.userId === getPaymentUserId(reservation));

  useEffect(() => {
    if (!!loggedInUserId) guestProfileService.getGuestProfile();
  }, [loggedInUserId]);

  // If I am an employee, then I impersonate getPaymentUserId by using their guest info and payment methods.
  // Otherwise, I am the one paying and I use my own payment methods.
  const guestProfile = useMemo(() => {
    const loggedInGuest = guest ?? ({ ...{ userId: loggedInUserId }, sId: '' } as GuestProfile);

    return !!isEmployee ? contact : loggedInGuest;
  }, [contact, loggedInUserId, isEmployee, guest]);

  return {
    userId: guestProfile?.userId ?? '',
    name: guestProfile?.name ?? {},
    contact: guestProfile?.contact ?? {},
    address: guestProfile?.address ?? {},
    sId: guestProfile?.sId ?? '',
  };
}
