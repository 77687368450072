import React, { useMemo } from 'react';

import { CorporateAccount, sessionQuery } from '@lib/state';
import {
  Section,
  useObservable,
  usePropertyRoomTypes,
  ButtonLink,
  usePropertyAmenities,
  LoadingIndicator,
} from '@lib/common';
import { RatePlanList } from 'app/room-rate-plans';
import { mapRoomRatePlan, RoomRatePlan } from 'app/state';
import { Box } from '@material-ui/core';

interface Props {
  account?: CorporateAccount;
}

export const CorporateAccountRatePlans: React.FC<Props> = ({ account }) => {
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const propertyAmenities = usePropertyAmenities(propertyId);
  const roomTypes = usePropertyRoomTypes(propertyId);

  //filter rate plans based on property
  const ratePlans = useMemo(() => {
    if (!account?.ratePlans || !propertyId) return new Array<RoomRatePlan>();

    return account.ratePlans
      .filter(plan => plan.propertyId === propertyId)
      .map(plan => mapRoomRatePlan(plan) as RoomRatePlan);
    // eslint-disable-next-line
  }, [account?.ratePlans, propertyId]);

  const link = useMemo(() => {
    if (!account || !propertyId) return '';

    return `/rates/add?corporateAccountId=${account.id}`;
  }, [account, propertyId]);

  if (!account || !propertyId) return null;

  if (!roomTypes.length || !propertyAmenities) return <LoadingIndicator />;

  return (
    <Section title="Account Rate Plans" textAlign="left">
      <Box my="1rem" display="flex" justifyContent="flex-end" flexDirection="row">
        <ButtonLink
          to={link}
          variant="contained"
          color="secondary"
          data-testid="corporateNewRatePlanButton"
        >
          New Rate Plan
        </ButtonLink>
      </Box>
      <RatePlanList
        plans={ratePlans}
        roomTypes={roomTypes}
        propertyAmenities={propertyAmenities}
        account={account}
      />
    </Section>
  );
};
