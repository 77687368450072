import { Name } from '../api/generated/auth/models/name';

export function formatName(first?: string | null, middle?: string | null, last?: string | null) {
  return [first, middle, last].filter(x => !!x).join(' ');
}

export function formatNameDirectory(
  first?: string | null,
  middle?: string | null,
  last?: string | null
) {
  const primaryName = [last, first].filter(x => !!x).join(', ');
  return [primaryName, middle].filter(x => !!x).join(' ');
}

export function formatNameFromObject(name: Name): string {
  return formatName(name.first, name.middle, name.last);
}
