import { Query } from '@datorama/akita';
import { switchMap } from 'rxjs/operators';

import { GuestRelationState, guestRelationStore } from './guest-relation.store';

export class GuestRelationQuery extends Query<GuestRelationState> {
  userId = this.select(({ userId }) => userId);

  relations = this.select(({ relations }) => relations);

  myHousehold = this.userId.pipe(
    switchMap(userId => this.select(x => x.relations?.filter(x => x.userId === userId)))
  );

  otherHouseholds = this.userId.pipe(
    switchMap(userId => this.select(x => x.relations?.filter(x => x.userId !== userId)))
  );
}

export const guestRelationQuery = new GuestRelationQuery(guestRelationStore);
