import React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  createStyles,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Close } from '@material-ui/icons';
import { hasHardwareKeyboard } from '../native-interface/nativebridge';
import { AppColors } from '@lib/common';

interface AutocompleteStyleProps {
  iconColor?: string;
}

const useStyles = makeStyles(theme =>
  createStyles({
    selectContainer: {
      marginRight: '12px',
    },
    inputField: {
      minWidth: '300px',
    },
    icon: {
      fontSize: '1rem',
      color: ({ iconColor }: AutocompleteStyleProps) =>
        iconColor ? `${iconColor} !important` : `${theme.palette.common.white} !important`,
    },
  })
);

export interface AutocompleteOption {
  label: string;
  value: string;
}

interface AutocompleteFieldProps {
  options: AutocompleteOption[];
  placeholder?: string;
  iconColor?: AppColors;
  variant?: 'outlined' | 'filled';
  resetKey?: string;
  onChangeHandler: (newValue: AutocompleteOption | null) => void;
}

export const AutocompleteField: React.FC<AutocompleteFieldProps> = ({
  options,
  placeholder,
  iconColor,
  variant,
  resetKey,
  onChangeHandler,
}) => {
  const keyboardIsAvailable = hasHardwareKeyboard();
  const styles = useStyles({ iconColor });

  return (
    <>
      {(keyboardIsAvailable && (
        <Autocomplete
          key={resetKey} //when changed will re-render the component, can be used to clear/reset
          options={options}
          getOptionLabel={option => option.label}
          renderInput={params => (
            <TextField
              variant={variant}
              placeholder={placeholder}
              className={styles.inputField}
              {...params}
            />
          )}
          onChange={(event: any, newValue: AutocompleteOption | null) => {
            onChangeHandler(newValue);
          }}
          closeIcon={<Close className={styles.icon} fontSize="small" />}
          popupIcon={<FontAwesomeIcon icon="chevron-down" className={styles.icon} />}
          className={styles.selectContainer}
        />
      )) || (
        <FormControl className={clsx(styles.selectContainer, styles.inputField)}>
          <Select
            defaultValue=""
            variant={variant}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            IconComponent={(props: any) => (
              <FontAwesomeIcon
                icon="chevron-down"
                className={styles.icon}
                transform="down-2 left-12"
              />
            )}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              const index = event.target.value as number;
              onChangeHandler(options[index]);
            }}
          >
            <MenuItem value="" disabled>
              {placeholder}
            </MenuItem>
            {options.map((option, i) => (
              <MenuItem key={i} value={i}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};
