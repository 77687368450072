import { Store, StoreConfig } from '@datorama/akita';

import { GuestSurvey } from './guest-survey.model';

export interface GuestSurveyState {
  guestSurvey: GuestSurvey | null;
  guestSurveyRequestValid: boolean | null;
}

@StoreConfig({ name: 'guest-suvey', resettable: true })
export class GuestSurveyStore extends Store<GuestSurveyState> {
  constructor() {
    super({ guestSurvey: null, guestSurveyRequestValid: null });
  }
}

export const guestSurveyStore = new GuestSurveyStore();
