import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm, FormContext } from 'react-hook-form';
import { Button, Grid } from '@material-ui/core';

import { FormTextField, useFormEvents } from '@lib/common';
import { FormStatus } from '@lib/state';
import { employeeService, EmployeeForms } from 'app/state';

interface EnrollEmployeeFormFields {
  first?: string;
  last?: string;
}

interface Props {
  existingEmployee: boolean;
  onSubmitComplete: () => void;
}

export const EnrollEmployeeForm: React.FC<Props> = ({ existingEmployee, onSubmitComplete }) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const employeeInviteId = queryParams.get('employeeInviteId');

  const form = useForm<EnrollEmployeeFormFields>({});
  const { first, last } = form.watch({ nest: true });

  const [{ status: enrollStatus }] = useFormEvents(EmployeeForms.EnrollEmployee);

  const onSubmit = useCallback(() => {
    if (employeeInviteId) {
      const name = { first, last };
      employeeService.submitEmployeeEnrollment(name, employeeInviteId);
    }
  }, [first, last, employeeInviteId]);

  useEffect(() => {
    if (onSubmitComplete && enrollStatus === FormStatus.Success) onSubmitComplete();
  }, [enrollStatus, onSubmitComplete]);

  return (
    <FormContext {...form}>
      <Grid container spacing={1}>
        {!existingEmployee && (
          <>
            <Grid item xs={12}>
              <FormTextField defaultValue={first} name="first" label="First Name" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <FormTextField defaultValue={last} name="last" label="Last Name" fullWidth />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Button variant="contained" color="secondary" fullWidth onClick={onSubmit}>
            Enroll
          </Button>
        </Grid>
      </Grid>
    </FormContext>
  );
};
