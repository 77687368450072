import { AppColors, useFormEvents, useObservable } from '@lib/common';
import {
  FormStatus,
  HousekeepingStatusEnum,
  Room,
  roomsQuery,
  roomsService,
  RoomUpdate,
  sessionQuery,
} from '@lib/state';
import { Box, Button, createStyles, makeStyles } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { GuestStay, guestStaysService } from 'app/state';
import React, { useCallback, useState } from 'react';
import { GuestStayForms } from '../../state/guest-stays';
import { ChangeRoomModal } from './change-room.modal';
import { RoomInfoModal } from './room-info.modal';
import { RoomCountType } from '@lib/state/api/generated/properties';
import {
  getTapeChartFilteredRows,
  TapeChart,
  TapeChartRow,
  TimelineProps,
  TimelineView,
  RoomSelectionFilter,
  useTapeChart,
  useGuestStayLookup,
} from 'app/shared';

const useStyles = makeStyles(_ =>
  createStyles({
    root: { display: 'flex', flexDirection: 'column', flex: 1 },
    changeRoomHeader: {
      fontSize: '125%',
      padding: '12px',
      textAlign: 'center',
      backgroundColor: AppColors.DarkGreen,
      color: common.white,
    },
    changeRoomCloseButton: {
      fontSize: '75%',
      padding: '0 18px',
      marginLeft: '12px',
      borderRadius: '6px',
      backgroundColor: common.white,
      color: AppColors.Red,
      '&:hover': {
        backgroundColor: common.white,
      },
    },
  })
);

interface TapeChartProps extends TimelineProps {
  onChangeView: (view: TimelineView) => void;
  onChangeDate: (date: Date) => void;
}

export const GuestStayTapeChartController: React.FC<TapeChartProps> = ({
  startDate,
  view,
  onChangeView,
  onChangeDate,
}) => {
  const propertyId = useObservable(sessionQuery.propertyId, 'async');

  // When change room is performed, we need to refresh the room in the store,
  // without changing the active flag
  const reloadRoom = useCallback(
    (roomId: string) => {
      if (propertyId) {
        roomsService.getRoomById(propertyId, roomId);
      }
    },
    [propertyId]
  );

  const [countBy, setCountBy] = useState(RoomCountType.Bed);

  const handleToggle = (event: RoomCountType) => {
    setCountBy(event);
  };

  const {
    guestStays,
    guestStaysFilters,
    roomCounts,
    roomTypes,
    zones,
    roomsFilters,
    allPropertyRooms,
  } = useTapeChart(startDate, view, countBy, propertyId);

  const [selectedGuest, setSelectedGuest] = useState<GuestStay | undefined>(undefined);
  const [selectedNewRoom, setSelectedNewRoom] = useState<Room | undefined>(undefined);

  const styles = useStyles();
  const [changeRoomMode, setChangeRoomMode] = useState(false);
  const [openRoomInfoModal, setOpenRoomInfoModal] = useState(false);
  const [openRoomChangeModal, setOpenRoomChangeModal] = useState(false);

  const selectedRoom = useObservable(roomsQuery.activeRoom, 'async');
  const [changeRoomResults, resetChangeRoomFormUI] = useFormEvents(GuestStayForms.ChangeRooms);

  const onOpenRoomInfoModal = (guest: GuestStay | undefined, roomModel: Room) => {
    setSelectedGuest(guest);
    roomsService.setActive(roomModel);
    setOpenRoomInfoModal(true);
  };

  const onUpdateRoomInfo = useCallback((room: Room, update: RoomUpdate) => {
    roomsService.updateRoom(room, update);
    setOpenRoomInfoModal(false);
  }, []);

  const markReadyForRent = useCallback((room: Room, update: RoomUpdate) => {
    roomsService.markReadyForRent(room, update);
    setOpenRoomInfoModal(false);
  }, []);

  const onCloseRoomInfoModal = useCallback(() => {
    setOpenRoomInfoModal(false);
    roomsService.setActive();
    setSelectedGuest(undefined);
  }, []);

  const onCloseChangeRoomModal = useCallback(() => {
    setOpenRoomChangeModal(false);
    setSelectedNewRoom(undefined);
  }, []);

  const startChangeRoom = useCallback(() => {
    setOpenRoomInfoModal(false);
    setChangeRoomMode(true);
  }, []);

  const changeRoom = useCallback(
    (oldRoomCleaningStatus?: HousekeepingStatusEnum) => {
      if (selectedGuest && selectedNewRoom && selectedRoom) {
        guestStaysService.changeRooms(
          selectedGuest.id,
          selectedNewRoom.roomNumber,
          selectedGuest.reservationId,
          {
            oldRoomId: selectedRoom.id,
            newRoomId: selectedNewRoom.id,
            oldRoomCleaningStatus,
          }
        );
      }
    },
    [selectedGuest, selectedNewRoom, selectedRoom]
  );

  const cancelChangeRoom = useCallback(() => {
    setOpenRoomInfoModal(true);
    setChangeRoomMode(false);
    setSelectedNewRoom(undefined);
  }, []);

  const onChangeRoomSuccess = useCallback(() => {
    if (changeRoomResults.status === FormStatus.Success) {
      roomsService.setActive(selectedNewRoom);
      cancelChangeRoom();

      // Reload rooms
      if (selectedRoom) reloadRoom(selectedRoom.id);
      if (selectedNewRoom) reloadRoom(selectedNewRoom.id);
    }
  }, [changeRoomResults, selectedNewRoom, selectedRoom, cancelChangeRoom, reloadRoom]);

  const guestStaysLookup = useGuestStayLookup(guestStays);

  const filteredRows = getTapeChartFilteredRows(
    allPropertyRooms,
    guestStaysLookup,
    roomsFilters,
    guestStaysFilters,
    changeRoomMode,
    getRoomSelectionProps(selectedGuest)
  );

  const selectRow = useCallback(
    ({ room, guestStay }: TapeChartRow) => {
      resetChangeRoomFormUI();
      if (changeRoomMode) {
        setSelectedNewRoom(room);
        setOpenRoomChangeModal(true);
      } else {
        onOpenRoomInfoModal(guestStay, room);
      }
    },
    [resetChangeRoomFormUI, changeRoomMode]
  );

  const getOccupants = () => {
    return filteredRows.find(row => row.room.id === selectedRoom?.id)?.occupants;
  };

  return (
    <div className={styles.root}>
      <TapeChart
        rooms={allPropertyRooms}
        tapeChartRows={filteredRows}
        selectRow={selectRow}
        startDate={startDate}
        view={view}
        enableRoomSelection={changeRoomMode}
        countBy={countBy}
        guestStays={guestStays}
        guestStaysFilters={guestStaysFilters}
        roomCounts={roomCounts}
        onChangeDate={onChangeDate}
        onChangeView={onChangeView}
        reloadRoom={reloadRoom}
        roomsFilters={roomsFilters}
        setCountBy={handleToggle}
        roomTypes={roomTypes}
        zones={zones}
        roomSelectionHeader={
          changeRoomMode ? (
            <Box className={styles.changeRoomHeader}>
              Please choose an available room below{' '}
              <Button className={styles.changeRoomCloseButton} onClick={cancelChangeRoom}>
                Cancel
              </Button>
            </Box>
          ) : null
        }
      />
      {selectedRoom && (
        <RoomInfoModal
          open={openRoomInfoModal}
          onClose={onCloseRoomInfoModal}
          stay={selectedGuest}
          room={selectedRoom}
          occupants={getOccupants()}
          onSave={onUpdateRoomInfo}
          startChangeRoom={startChangeRoom}
          markReadyForRent={markReadyForRent}
          createForGuest={true}
        />
      )}
      {selectedNewRoom && (
        <ChangeRoomModal
          open={openRoomChangeModal}
          stay={selectedGuest}
          currentRoom={selectedRoom}
          newRoom={selectedNewRoom}
          changeRoomResults={changeRoomResults}
          onSave={changeRoom}
          onClose={onCloseChangeRoomModal}
          onSuccess={onChangeRoomSuccess}
        />
      )}
    </div>
  );
};

function getRoomSelectionProps(guestStay?: GuestStay): RoomSelectionFilter | undefined {
  if (guestStay)
    return {
      checkInDate: guestStay.checkInDate,
      checkOutDate: guestStay.checkOutDate,
      reservationId: guestStay.reservationId,
      corporateAccountId: guestStay.affiliation?.corporateAccountId,
    };
}
