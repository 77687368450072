import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: ({ minHeight }: Props) => minHeight ?? theme.mixins.toolbar.minHeight,
  },
}));

interface Props {
  minHeight?: string | number;
}

export const NavBarSpacer: React.FC<Props> = props => {
  const { root } = useStyles(props);
  return <div className={root} />;
};
