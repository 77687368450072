import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';

import { Properties, Reservations, AccessControl } from '../api';
import { SessionService, sessionService } from '../session';
import { propertyStore, PropertyStore } from './property.store';
import { Property, FeatureFlag, AccessControlType } from './property.model';
import { ArmDeviceProvisioningPolicyType } from '../api/generated/access-control';
import { PropertyForms } from './property.forms';
import { dispatchForm } from '../forms';

export class PropertyService {
  constructor(
    private readonly store: PropertyStore,
    private readonly sessionService: SessionService,
    private readonly propertyApi: Properties.PropertyApi,
    private readonly propertyConfigurationApi: Reservations.PropertyApi,
    private readonly accessControlApi: AccessControl.PropertyApi,
    private readonly accessControlProvisioningApi: AccessControl.ProvisioningApi,
    private readonly propertyAmenitiesApi: Properties.AmenitiesApi
  ) {}

  loadProperties(customerCode?: string | undefined): void {
    from(this.propertyApi.propertyGet(customerCode))
      .pipe(map(response => response.data.data))
      .subscribe(data => {
        applyTransaction(() => {
          this.sessionService.resetProperty(data);

          // remove any properties that are not in the resulting list
          const propertyIds = new Set(data.map(({ id }) => id));
          this.store.remove(({ id }) => !propertyIds.has(id));

          this.store.upsertMany(data);
        });
      });
  }

  loadPropertyConfiguration(id: Property['id']) {
    this.sessionService.switchProperty(id);

    from(this.propertyConfigurationApi.propertyIdGet(id))
      .pipe(map(response => response.data.data))
      .subscribe(configuration =>
        this.store.upsert(id, {
          configuration: {
            ...configuration,
            features: configuration.features as Array<FeatureFlag>,
          },
        })
      );
  }

  loadPropertyAmenities(id: Property['id']) {
    this.sessionService.switchProperty(id);

    from(this.propertyAmenitiesApi.propertyIdAmenitiesGet(id))
      .pipe(map(response => response.data))
      .subscribe(amenities => this.store.upsert(id, { amenities }));
  }

  selectProperty(id?: Property['id']) {
    if (id) {
      this.loadPropertyConfiguration(id);
    } else {
      this.sessionService.switchProperty(id);
    }
  }

  loadKiosk(property: Property) {
    const properties = [property];
    this.store.upsertMany(properties);

    this.selectProperty(property.id);
  }

  getPropertyProvisioningCode(id: Property['id']) {
    from(this.accessControlApi.propertyIdGet(id))
      .pipe(map(response => response.data.code))
      .subscribe(provisioningCode => this.store.upsert(id, { provisioningCode }));
  }

  getDeviceProvisioningQRCodeData(
    propertyId: Property['id'],
    policyType: ArmDeviceProvisioningPolicyType
  ) {
    from(
      this.accessControlProvisioningApi.provisioningPropertyIdDeviceProvisioningQrCodePolicyTypeGet(
        propertyId,
        policyType
      )
    )
      .pipe(dispatchForm(PropertyForms.GetDeviceQRCode))
      .pipe(map(response => response.data))
      .subscribe(enrollmentToken =>
        this.store.update(state => ({
          ...state,
          ui: { ...state.ui, enrollmentToken },
        }))
      );
  }

  getQRCodeScannerQRCodeData(
    propertyId: string,
    ssid: string,
    wiFiPassword: string,
    deviceName: string,
    accessControlType: AccessControlType
  ) {
    from(
      this.accessControlProvisioningApi.provisioningPropertyIdDeviceQrCodePost(propertyId, {
        ssid,
        wiFiPassword,
        deviceName,
        accessControlType,
      })
    )
      .pipe(dispatchForm(PropertyForms.GetDeviceQRCode))
      .pipe(map(response => response.data))
      .subscribe(enrollmentToken =>
        this.store.update(state => ({
          ...state,
          ui: { ...state.ui, enrollmentToken },
        }))
      );
  }
}

export const propertyService = new PropertyService(
  propertyStore,
  sessionService,
  new Properties.PropertyApi(),
  new Reservations.PropertyApi(),
  new AccessControl.PropertyApi(),
  new AccessControl.ProvisioningApi(),
  new Properties.AmenitiesApi()
);
