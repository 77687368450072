import React, { useRef } from 'react';
import QRCode from 'qrcode.react';
import { Loading, useQRCodeUrlQuery } from '@lib/common';
import { QRCode as QRCodeModel } from '@lib/state';
import { Box, Grid, Typography } from '@material-ui/core';
import { PrintButton } from 'app/shared';

interface Props {
  qrCode: QRCodeModel;
  propertyId: string;
}

export function QRCodeView({ qrCode, propertyId }: Props) {
  const { data, isLoading } = useQRCodeUrlQuery({ qrCodeId: qrCode.id, propertyId });

  const componentToPrintRef = useRef(null);

  if (isLoading || !data) return <Loading />;

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <div ref={componentToPrintRef}>
        <Grid container justifyContent="center">
          <Typography variant="h1">{qrCode.name}</Typography>
        </Grid>
        <Grid container justifyContent="center">
          <QRCode value={data} size={300} includeMargin={true} renderAs="canvas" />
        </Grid>
        <Grid container justifyContent="center">
          <Typography variant="h1">{qrCode.qrCodeNumber}</Typography>
        </Grid>
      </div>
      <Box paddingTop={3}>
        <PrintButton componentToPrint={componentToPrintRef} />
      </Box>
    </Box>
  );
}
