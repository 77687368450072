import React from 'react';
import { Button, ButtonGroup, makeStyles, createStyles } from '@material-ui/core';
import { AppColors } from '@lib/common';

const useStyles = makeStyles(theme =>
  createStyles({
    buttonGroup: {
      marginLeft: 'auto',
    },
    selectedButton: {
      color: AppColors.DarkBlue,
      backgroundColor: AppColors.LightBlue,
      '&:hover': {
        color: AppColors.DarkBlue,
        backgroundColor: AppColors.LightBlue,
      },
    },
  })
);

export interface AuditHistoryTab {
  title: string;
}

export interface AuditHistoryTabProps {
  tabs: AuditHistoryTab[];
  isVisible: (tab: number) => boolean;
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
}

export const AuditHistoryTabs: React.FC<AuditHistoryTabProps> = ({
  tabs,
  isVisible,
  selectedTab,
  setSelectedTab,
}) => {
  const styles = useStyles();

  return (
    <ButtonGroup
      color="secondary"
      aria-label="outlined primary button group"
      className={styles.buttonGroup}
    >
      {tabs.map(
        (tab, tabIndex) =>
          isVisible(tabIndex) && (
            <Button
              key={tab.title}
              onClick={() => setSelectedTab(tabIndex)}
              className={selectedTab === tabIndex ? styles.selectedButton : undefined}
            >
              {tab.title}
            </Button>
          )
      )}
    </ButtonGroup>
  );
};
