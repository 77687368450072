import { from, of } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';

import { Reservations, Auth, AccessControl } from '../api';
import { GuestProfileStore, guestProfileStore } from './guest-profile.store';
import { dispatchForm } from '../forms';
import { GuestProfilesForms } from './guest-profile.forms';
import { getE164FormattedPhoneNumber } from '../utils';
import { sessionQuery, SessionQuery, SessionService, sessionService } from '../session';

export class GuestProfileService {
  constructor(
    private readonly store: GuestProfileStore,
    private readonly guestApi: Reservations.GuestApi,
    private readonly accountApi: Auth.AccountApi,
    private readonly accessControlApi: AccessControl.UserApi,
    private readonly sessionService: SessionService,
    private readonly sessionQuery: SessionQuery
  ) {}

  getGuestProfile(): void {
    from(from(this.sessionService.updateUserInfo()))
      .pipe(
        switchMap(() => from(this.guestApi.guestProfileGet())),
        map(({ data }) => data.data),
        switchMap(profile =>
          this.sessionQuery.currentUser.pipe(
            take(1), // we're only interested in a single emission from currentUser; else this observable will never finish
            map(user => ({
              ...profile,
              emailVerfied: user?.email_verified,
              phoneNumberVerified: user?.phone_number_verified,
            }))
          )
        )
      )
      .subscribe(guest => this.store.update({ guest }));
  }

  updateGuestProfile({
    userId,
    name,
    contact,
    address = {},
    gender,
    language,
    archived,
  }: Reservations.GuestProfileModel): void {
    // separately store the formatted phone
    // this formatted phone cannot be stored in the store as it will break the form-phone-field input
    const e164Phone = !!contact.phone ? getE164FormattedPhoneNumber(contact.phone) : undefined;
    from(
      this.accountApi.accountUpdatePost({
        userId: userId,
        email: contact.email!,
        phoneNumber: e164Phone,
      })
    )
      .pipe(
        switchMap(() =>
          this.guestApi.guestProfilePost({
            name,
            address,
            gender,
            language,
            archived,
          })
        ),
        dispatchForm(GuestProfilesForms.UpdateProfile)
      )
      .subscribe(() => {
        this.store.update(({ guest }) => ({
          guest: {
            userId: guest ? guest.userId : '',
            contact: guest ? contact : {},
            sId: guest?.sId ?? '',
            name,
            address,
            gender,
            language,
            archived,
          },
        }));
        sessionService.updateUserInfo();
      });
  }

  getDoNotDisturbStatus() {
    from(this.accessControlApi.userDoNotDisturbGet())
      .pipe(map(response => response.data))
      .subscribe(doNotDisturb =>
        this.store.update({ isDoNotDisturbOn: doNotDisturb.doNotDisturbIsOn })
      );
  }

  setDoNotDisturb(dnd: boolean) {
    from(this.accessControlApi.userDoNotDisturbPut({ dnd }))
      .pipe(dispatchForm(GuestProfilesForms.UpdateDoNotDisturb))
      .subscribe(() => this.store.update({ isDoNotDisturbOn: dnd }));
  }

  reset() {
    this.store.reset();
  }
}

export const guestProfileService = new GuestProfileService(
  guestProfileStore,
  new Reservations.GuestApi(),
  new Auth.AccountApi(),
  new AccessControl.UserApi(),
  sessionService,
  sessionQuery
);
