import React from 'react';
import { TypographyProps } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Name, VipStatus } from '@lib/state';
import { NameDisplay } from './name-display';
import { GuestSIdDisplay } from './guest-sid-display';
import { VipDisplay } from './vip-display';

type Props = React.PropsWithChildren<{
  guests?: Array<{ name: Name; sid?: string | null; vipStatus?: VipStatus }> | null;
  truncate?: boolean;
  className?: string | ((index: number) => string);
  typographyProps?: TypographyProps;
}>;

export const GuestNameListDisplay: React.FC<Props> = ({ guests, truncate = false, ...props }) => {
  if (guests == null || guests.length === 0) return null;

  if (truncate) {
    return <TruncatedGuestList guests={guests} {...props} />;
  }

  return <FullGuestList guests={guests} {...props} />;
};

function FullGuestList({ className, guests, typographyProps = {}, children }: Props) {
  const classNameSelector = getClassNameSelector(className);
  return (
    <>
      {guests?.map(({ name, sid, vipStatus }, i) => (
        <NameDisplay
          key={i}
          {...name}
          directory={true}
          className={classNameSelector(i)}
          noWrap
          variant="body2"
          prefix={
            i == 0 ? (
              <FontAwesomeIcon icon="user" size="sm" fixedWidth />
            ) : (
              <FontAwesomeIcon icon="plus" size="sm" fixedWidth />
            )
          }
          {...typographyProps}
        >
          {i === 0 ? children : null}
          {!!sid && <GuestSIdDisplay val={sid} parenthesis={true} />}
          <VipDisplay vipStatus={vipStatus} style={{ paddingLeft: '.5em' }} />
        </NameDisplay>
      ))}
    </>
  );
}

function TruncatedGuestList({ className, guests, typographyProps = {}, children }: Props) {
  const first = guests?.[0];
  if (!first) return null;

  const guestCount = guests?.length ?? 0;
  const guestCountDisplay = guestCount > 1 ? ` + ${guestCount - 1}` : '';
  const classNameSelector = getClassNameSelector(className);

  return (
    <NameDisplay
      {...first.name}
      directory={true}
      className={classNameSelector(0)}
      noWrap
      variant="body2"
      {...typographyProps}
    >
      {guestCountDisplay}
      {children}
    </NameDisplay>
  );
}

function getClassNameSelector(className: Props['className']) {
  if (!className) return () => undefined;
  return typeof className === 'string' ? () => className : className;
}
