import React, { useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { CSVLink } from 'react-csv';
import { makeStyles, Button, Grid } from '@material-ui/core';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatISO, format, parseISO } from 'date-fns';

import { FormTextField, WebOnly } from '@lib/common';
import { InHouseGuestExportModel } from 'app/state';
import { FilterSection, formatDateMidnight } from 'app/shared';

const useStyles = makeStyles(theme => ({
  downloadButtonContainer: {
    marginLeft: 'auto',
  },
}));

export interface InHouseGuestFilterFields {
  date: string;
  corporateAccountName: string;
}

interface Props {
  data: InHouseGuestExportModel[];

  onChange: (filters: InHouseGuestFilterFields) => void;
}

export const InHouseGuestHeader: React.FC<Props> = ({ data, onChange }) => {
  const { downloadButtonContainer } = useStyles();

  const { register, unregister, watch, setValue } = useFormContext<InHouseGuestFilterFields>();

  useEffect(() => {
    register('date');
    return () => unregister('date');
  }, [register, unregister]);

  const { date, corporateAccountName } = watch({ nest: true });

  const filename = useMemo(() => `ihgr-${format(parseISO(date), 'MM-dd-yyyy')}.csv`, [date]);

  useEffect(() => {
    onChange({ date, corporateAccountName });
  }, [onChange, date, corporateAccountName]);

  return (
    <FilterSection>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <DatePicker
            label="Date"
            value={date}
            onChange={date => {
              if (date) setValue('date', formatDateMidnight(date));
            }}
            format="MM/dd/yyyy"
            autoOk
          />
        </Grid>
        <Grid item xs={2}>
          <TimePicker
            label="Time"
            value={date}
            onChange={date => {
              if (date) setValue('date', formatISO(date));
            }}
            autoOk
          />
        </Grid>
        <Grid item xs={2}>
          <FormTextField name="corporateAccountName" label="Company Name" fullWidth />
        </Grid>
        <WebOnly>
          <Grid className={downloadButtonContainer} item>
            <CSVLink filename={filename} data={data}>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                startIcon={<FontAwesomeIcon icon="download" />}
              >
                Download
              </Button>
            </CSVLink>
          </Grid>
        </WebOnly>
      </Grid>
    </FilterSection>
  );
};
