import React, { useCallback, useEffect, useState } from 'react';
import { RemoveButton, DateDisplay, useFormEvents, DataTable } from '@lib/common';
import { guestNotesService, GuestNotesForms, GuestNote } from 'app/state';
import { Link, makeStyles, Typography } from '@material-ui/core';
import {  GuestNoteModal } from 'app/shared';
import { CreateGuestNote } from './create-guest-note';

interface Props {
  propertyId: string;
  guestUserId: string;
  guestStayId?: string;
  notes?: GuestNote[];
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  details: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto',
    flex: 1,
  },
});

export const GuestNotesList: React.FC<Props> = ({
  propertyId,
  guestUserId,
  guestStayId,
  notes,
}) => {
  const [{ status, error }, resetStatus] = useFormEvents(GuestNotesForms.Remove);

  const removeGuestNote = useCallback(
    (guestNote: GuestNote) => guestNotesService.removeGuestNote(propertyId, guestNote.id),
    [propertyId]
  );

  const styles = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  const [pickedNote, setPickedNote] = useState<GuestNote>();

  const onSubmit = (arg: any): any => {
    setOpen(false);
    guestNotesService.updateGuestNote(propertyId, arg.guestNoteId, arg.note, arg.noteType);
  };

  useEffect(() => resetStatus(), [notes, resetStatus]);

  return (
    <>
      <CreateGuestNote propertyId={propertyId} userId={guestUserId} guestStayId={guestStayId} />
      <DataTable
        stickyHeader
        items={notes ? notes : []}
        getItemKey={note => note.id}
        columns={[
          {
            title: 'Id',
            valueFactory: guestNote => (
              <div className={styles.container}>
                <Link
                  href="#"
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    setPickedNote(guestNote);
                    setOpen(true);
                  }}
                >
                  {guestNote.recordNumber}
                </Link>
                {open && (
                  <GuestNoteModal
                    onSubmit={onSubmit}
                    guestNote={pickedNote!}
                    open={open}
                    onClose={() => setOpen(false)}
                  ></GuestNoteModal>
                )}
              </div>
            ),
          },
          {
            title: 'Note',
            valueFactory: x => x.note,
            colSpan: 5,
          },
          {
            title: 'Type',
            valueFactory: x => x.noteType.label,
          },
          {
            title: 'State',
            valueFactory: x => x.status,
          },
          {
            title: 'Created',
            valueFactory: guestNote =>
              guestNote.createdTime ? (
                <DateDisplay date={new Date(guestNote.createdTime)} dateFormat="M/d/yy - h:mm a" />
              ) : null,
            colSpan: 2,
          },
          {
            title: 'Actions',
            align: 'right',
            valueFactory: x => (
              <RemoveButton
                onConfirm={() => removeGuestNote(x)}
                status={status}
                error={error}
                confirmView={
                  <Typography>Are you sure you want to remove note {x.recordNumber}?</Typography>
                }
              />
            ),
          },
        ]}
      />
    </>
  );
};
