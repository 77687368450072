import React, { useState, useMemo, useCallback } from 'react';
import {
  Typography,
  makeStyles,
  createStyles,
  Grid,
  Paper,
  Box,
  Container,
} from '@material-ui/core';
import { GridSelectionModel } from '@mui/x-data-grid-pro';
import { format, parseISO, differenceInDays, startOfDay } from 'date-fns';
import { format as tzFormat, utcToZonedTime } from 'date-fns-tz';

import {
  HousekeepingAssignment,
  Housekeeper,
  ShowHousekeepingStatus,
  HousekeepingStatusEnum,
  ServicePerformedMap,
  totalAssignedHousekeepingByRequested,
  totalHousekeepingByPerformed,
  totalDueHousekeepingByRequested,
  totalHousekeepingRefusedByRequested,
} from '@lib/state';
import {
  AppColors,
  DateDisplay,
  usePropertyConfiguration,
  SleepScheduleIconDisplay,
  DataTable,
  IDataTableColumn,
} from '@lib/common';
import { getDate } from 'app/shared';
import { FooterContainer } from './footer-container';
import { HousekeeperSelection } from './housekeeper-selection';
import { Alert } from '@material-ui/lab';

interface RoomAssignmentsListProps {
  propertyId: string;
  allRooms: HousekeepingAssignment[];
  housekeepers: Housekeeper[];
  date: Date;
  disable?: boolean;
}

const useStyles = makeStyles(theme =>
  createStyles({
    assignmentHeader: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      padding: theme.spacing(1),
    },
    completedHeader: {
      backgroundColor: AppColors.DarkGreen,
      color: theme.palette.common.white,
      padding: theme.spacing(1),
    },
    nowrap: {
      whiteSpace: 'nowrap',
    },
    noborder: {
      border: 'none !important',
    },
    container: { marginBottom: theme.spacing(8) },
    stats: {
      backgroundColor: theme.palette.grey[300],
      padding: theme.spacing(2),
      borderRadius: '8px',
    },
    statItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    title: {
      fontSize: '16px',
      textAlign: 'center',
    },
    label: {
      fontSize: '16px',
      textAlign: 'left',
    },
    value: {
      fontSize: '16px',
      textAlign: 'right',
    },
    totalValue: {
      fontSize: '16px',
      textAlign: 'right',
      fontWeight: 'bold',
    },
    statDescription: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'left',
      flexDirection: 'column',
    },
    statTotal: {
      display: 'flex',
      justifyContent: 'left',
    },
    dataTable: {
      '& .Mui-disabled': {
        opacity: '.5',
      },
    },
    strikethrough: {
      textDecoration: 'line-through',
    },
  })
);

export const RoomAssignmentsList: React.FC<RoomAssignmentsListProps> = ({
  propertyId,
  allRooms,
  housekeepers,
  date,
  disable,
}) => {
  const styles = useStyles();
  const propertyConfiguration = usePropertyConfiguration(propertyId);

  const { isFutureDate, notificationMessage } = useMemo(() => {
    const difference = differenceInDays(startOfDay(new Date()), date);

    if (difference > 0) {
      return {
        isFutureDate: false,
        notificationMessage: 'Past Date: Past housekeeping statuses and assignments.',
      };
    }

    if (difference < 0) {
      return {
        isFutureDate: true,
        notificationMessage: 'Future Date: Projected housekeeping statuses may change.',
      };
    }

    return { isFutureDate: false, notificationMessage: '' };
  }, [date]);

  const housekeeperMap = useMemo(() => {
    return new Map(
      housekeepers.map(m => {
        return [m.userId, m];
      })
    );
  }, [housekeepers]);

  const [selectedRooms, setSelectedRooms] = useState<HousekeepingAssignment[]>([]);

  const onAssignmentComplete = () => {
    setSelectedRooms([]);
  };

  const getHousekeeperName = useCallback(
    (userId: string | null | undefined) => {
      if (!userId) return '--';
      const housekeeper = housekeeperMap.get(userId);
      if (!housekeeper) return '--';
      return `${housekeeper.name?.first} ${housekeeper.name?.last}`;
    },
    [housekeeperMap]
  );
  const columns = useMemo<IDataTableColumn<HousekeepingAssignment>[]>(
    () => [
      {
        title: 'Room #',
        itemClass: styles.nowrap,
        headerClass: styles.nowrap,
        valueFactory: room => room.roomNumber,
      },
      {
        title: 'House-keepers',
        valueFactory: room => getHousekeeperName(room.housekeeperUserId),
      },
      {
        title: 'Super-visor',
        valueFactory: room => getHousekeeperName(room.supervisorUserId),
      },
      {
        title: 'Corporate Account',
        valueFactory: room => room.affiliation?.corporateAccountName,
      },
      {
        title: 'Time of Assignment',
        valueFactory: room => (
          <TimeDisplay date={room.assignedTime} timeZone={propertyConfiguration?.timezone} />
        ),
      },
      {
        title: 'Clean Requested',
        valueFactory: room => {
          if (
            !!room.servicePerformed &&
            room.servicePerformed !== room.serviceRequested &&
            !room.isComeBackLater
          )
            return (
              <Typography className={styles.strikethrough}>
                {ShowHousekeepingStatus(room.serviceRequested, room.isUpcomingCheckOut)}
              </Typography>
            );

          return ShowHousekeepingStatus(room.serviceRequested, room.isUpcomingCheckOut);
        },
      },
      {
        title: 'Clean Performed',
        valueFactory: room => {
          return !!room.servicePerformed || room.isComeBackLater
            ? ServicePerformedMap.get(room.servicePerformed ?? undefined)
            : '--';
        },
      },
      {
        title: 'Disinfect',
        valueFactory: room => (room.roomDisinfected ? 'Yes' : 'No'),
      },
      {
        title: 'Sleep Schedule',
        valueFactory: room => (
          <>
            {room.occupants === undefined ||
              (room.occupants?.length === 0 && '--') ||
              room.occupants?.map(occupant => (
                <div key={`${occupant.name?.last}-${occupant.name?.first}`}>
                  <SleepScheduleIconDisplay sleepSchedule={occupant.affiliation?.sleepSchedule} />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              ))}
          </>
        ),
      },
      {
        title: 'Start Time',
        valueFactory: room => {
          if (!room.startTime) return '--';
          if (room.startTime)
            return (
              <DateDisplay
                date={getDate(room.startTime)}
                dateFormat="M/d/yy - h:mm a"
                timeZone={propertyConfiguration?.timezone}
              />
            );
        },
      },
      {
        title: 'End Time',
        valueFactory: room => {
          if (!room.endTime) return '--';
          if (room.endTime)
            return (
              <DateDisplay
                date={getDate(room.endTime)}
                dateFormat="M/d/yy - h:mm a"
                timeZone={propertyConfiguration?.timezone}
              />
            );
        },
      },
      {
        title: 'Duration',
        valueFactory: room => (!!room.durationInMinutes ? `${room.durationInMinutes} mins` : '--'),
      },
      {
        title: 'Notes',
        valueFactory: room => room.notes ?? '--',
      },
    ],
    [getHousekeeperName, propertyConfiguration, styles.nowrap, styles.strikethrough]
  );

  const isRowSelectable = useCallback((room: HousekeepingAssignment) => {
    return !(!!room.id && !!room.servicePerformed);
  }, []);

  const housekeepingTotals = useMemo(() => {
    const performed = allRooms?.filter(x => x.isCompleted).length ?? 0;
    const assigned = allRooms?.filter(x => x.isAssigned).length ?? 0;
    const due = allRooms?.filter(x => x.isDue).length ?? 0;
    const refused = allRooms?.filter(x => x.isRefused).length ?? 0;

    const performedPerc = Math.round((due !== 0 ? performed / due : 0) * 100);
    const assignedPerc = Math.round((due !== 0 ? assigned / due : 0) * 100);
    const refusedPerc = Math.round((due !== 0 ? refused / due : 0) * 100);

    return { performed, assigned, due, performedPerc, assignedPerc, refused, refusedPerc };
  }, [allRooms]);

  if (!propertyConfiguration) return null;

  return (
    <div className={styles.container}>
      <Box marginY={2}>
        <Container maxWidth="md">
          <Paper className={styles.stats}>
            <Grid container spacing={2}>
              {notificationMessage !== '' && (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    <Typography>{notificationMessage}</Typography>
                  </Alert>
                </Grid>
              )}
              <Grid container item xs={12}>
                <Grid item xs={1} className={styles.statDescription}>
                  <Typography className={styles.title}>&nbsp;</Typography>
                </Grid>
                <Grid container item xs={11}>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.title}>CHECK OUT</Typography>
                  </Grid>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.title}>FULL</Typography>
                  </Grid>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.title}>TOUCH UP</Typography>
                  </Grid>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.title}>TOTAL</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={1} className={styles.statDescription}>
                  <Typography className={styles.label}>DUE</Typography>
                </Grid>
                <Grid container item xs={11}>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.value}>
                      {totalDueHousekeepingByRequested(
                        allRooms.filter(f => f.isDue),
                        HousekeepingStatusEnum.Checkout
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.value}>
                      {totalDueHousekeepingByRequested(
                        allRooms.filter(f => f.isDue),
                        HousekeepingStatusEnum.FullClean
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.value}>
                      {totalDueHousekeepingByRequested(
                        allRooms.filter(f => f.isDue),
                        HousekeepingStatusEnum.TouchUpClean
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className={styles.statTotal}>
                    <Grid item xs={7}>
                      <Typography className={styles.totalValue}>
                        {housekeepingTotals.due}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body1" align="right"></Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={1} className={styles.statDescription}>
                  <Typography className={styles.label}>ASSIGNED</Typography>
                </Grid>
                <Grid container item xs={11}>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.value}>
                      {totalAssignedHousekeepingByRequested(
                        allRooms.filter(f => f.isAssigned),
                        HousekeepingStatusEnum.Checkout
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.value}>
                      {totalAssignedHousekeepingByRequested(
                        allRooms.filter(f => f.isAssigned),
                        HousekeepingStatusEnum.FullClean
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.value}>
                      {totalAssignedHousekeepingByRequested(
                        allRooms.filter(f => f.isAssigned),
                        HousekeepingStatusEnum.TouchUpClean
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className={styles.statTotal}>
                    <Grid item xs={7}>
                      <Typography className={styles.totalValue}>
                        {housekeepingTotals.assigned}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={styles.totalValue}>
                        {`(${housekeepingTotals.assignedPerc}%)`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={1} className={styles.statDescription}>
                  <Typography className={styles.label}>COMPLETED</Typography>
                </Grid>
                <Grid container item xs={11}>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.value}>
                      {totalHousekeepingByPerformed(
                        allRooms.filter(f => f.isCompleted),
                        HousekeepingStatusEnum.Checkout
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.value}>
                      {totalHousekeepingByPerformed(
                        allRooms.filter(f => f.isCompleted),
                        HousekeepingStatusEnum.FullClean
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.value}>
                      {totalHousekeepingByPerformed(
                        allRooms.filter(f => f.isCompleted),
                        HousekeepingStatusEnum.TouchUpClean
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className={styles.statTotal}>
                    <Grid item xs={7}>
                      <Typography className={styles.totalValue}>
                        {housekeepingTotals.performed}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={styles.totalValue}>
                        {`(${housekeepingTotals.performedPerc}%)`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={1} className={styles.statDescription}>
                  <Typography className={styles.label}>REFUSED</Typography>
                </Grid>
                <Grid container item xs={11}>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.value}>
                      {totalHousekeepingRefusedByRequested(
                        allRooms.filter(f => f.isRefused),
                        HousekeepingStatusEnum.Checkout
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.value}>
                      {totalHousekeepingRefusedByRequested(
                        allRooms.filter(f => f.isRefused),
                        HousekeepingStatusEnum.FullClean
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className={styles.statItem}>
                    <Typography className={styles.value}>
                      {totalHousekeepingRefusedByRequested(
                        allRooms.filter(f => f.isRefused),
                        HousekeepingStatusEnum.TouchUpClean
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className={styles.statTotal}>
                    <Grid item xs={7}>
                      <Typography className={styles.totalValue}>
                        {housekeepingTotals.refused}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={styles.totalValue}>
                        {`(${housekeepingTotals.refusedPerc}%)`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Box>
      <DataTable
        items={allRooms}
        getItemKey={i => `${i.roomId}-${i.id}`}
        checkboxSelection={!isFutureDate}
        isRowSelectable={i => isRowSelectable(i)}
        onSelectionModelChange={(model: GridSelectionModel) => {
          const selected = new Set(model);
          setSelectedRooms(allRooms.filter(room => selected.has(`${room.roomId}-${room.id}`)));
        }}
        columns={columns}
        tableClass={styles.dataTable}
        pagination
      />
      {selectedRooms.length > 0 && (
        <FooterContainer>
          <HousekeeperSelection
            housekeepers={housekeepers}
            selectedRooms={selectedRooms}
            propertyId={propertyId}
            date={date}
            onAssignmentComplete={onAssignmentComplete}
          />
        </FooterContainer>
      )}
    </div>
  );
};

const TimeDisplay: React.FC<{ date?: string | null; timeZone?: string }> = ({ date, timeZone }) => {
  let timeFormat = 'hh:mm a';
  let displayDate;
  if (!!date && !!timeZone) {
    displayDate = tzFormat(utcToZonedTime(date, timeZone), timeFormat, {
      timeZone: timeZone,
    });
  } else if (!!date) {
    displayDate = format(parseISO(date), 'hh:mm a');
  } else {
    displayDate = '--';
  }

  return <Typography noWrap>{displayDate}</Typography>;
};
