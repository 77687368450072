import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import DateFnsUtils from '@date-io/date-fns';

import {
  managementPortalTheme,
  guestKioskTheme,
  frontDeskTheme,
  ScrollToTop,
  DisplayContext,
  DeviceType,
} from '@lib/common';
import { AppRoutes } from './app.routes';
import { isKiosk, isFrontDesk, getKioskType } from './shared/native-interface/nativebridge';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '@lib/state';

LicenseInfo.setLicenseKey(
  'e7882171da3d93d4f8dfa9e3685724cdT1JERVI6MzQ0NzUsRVhQSVJZPTE2NzEyOTU1NDYwMDAsS0VZVkVSU0lPTj0x'
);

const _isClientKiosk = isKiosk();
const _isFrontDesk = isFrontDesk();
const theme = _isClientKiosk
  ? _isFrontDesk
    ? frontDeskTheme
    : guestKioskTheme
  : managementPortalTheme;

const displayContext = {
  deviceType: _isClientKiosk ? DeviceType.Kiosk : DeviceType.Web,
  kioskType: getKioskType(),
};

export const App: React.FC = () => (
  <AppProviders>
    <AppRoutes />
  </AppProviders>
);

export const AppProviders: React.FC = ({ children }) => (
  <StylesProvider injectFirst>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <Router>
            <ScrollToTop />
            <DisplayContext.Provider value={displayContext}>{children}</DisplayContext.Provider>
          </Router>
        </QueryClientProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </StylesProvider>
);
