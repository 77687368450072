import React from 'react';
import { BoxProps, Typography, Box } from '@material-ui/core';
import { Overrideable } from '@lib/state';

interface Props extends BoxProps {
  override: Overrideable;
  remainingStayDays: number;
  refundableStayDays: number;
  hasPendingCharges: boolean;
  canRefundWithOverride: boolean;
}

export const RefundableDaysTextDisplay: React.FC<Props> = ({
  override = 'unavailable',
  remainingStayDays,
  refundableStayDays,
  hasPendingCharges,
  canRefundWithOverride,
  ...props
}) => {
  // the api provides the number of refundable stay days without a manager override
  let policyMessage = `Based on the policy for this reservation, ${refundableStayDays} of the remaining ${remainingStayDays} days are eligible to be refunded.`;

  let overrideMessage = '';

  if (override !== 'unavailable' && remainingStayDays > 0) {
    // refunding past stay dates now requires manual accounting intervention
    // .. so the only thing a manager override can do is refund the remaining stay days
    overrideMessage = ` If you need to refund the remaining ${remainingStayDays} days, then you will need a manager override.`;
  }

  return (
    <Box {...props}>
      <Typography>
        {policyMessage}
        {overrideMessage}
      </Typography>
      &nbsp;
      {hasPendingCharges && (
        <Typography>
          This reservation has pending charges that
          {override !== 'unavailable' && canRefundWithOverride
            ? ' will need a manager override to be refunded.'
            : ' cannot be refunded at this time. Please contact the front desk for assistance.'}
        </Typography>
      )}
    </Box>
  );
};
