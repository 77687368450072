import React from 'react';
import { GuestRelation } from '@lib/state';
import { GuestRelationField, NameDisplay } from '../..';
import { useFormContext } from 'react-hook-form';

interface Props {
  prefix?: string;
  guestRelation: GuestRelation;
  readOnly?: boolean;
}

export const GuestRelationComponent: React.FC<Props> = ({
  prefix,
  guestRelation,
  readOnly = false,
}) => {
  const { register } = useFormContext();

  return (
    <>
      <NameDisplay {...guestRelation.dependentGuestProfile?.name} />
      {!readOnly && (
        <input
          type="hidden"
          name={`${prefix}.dependentUserId`}
          ref={register}
          defaultValue={guestRelation.dependentUserId}
        />
      )}
      <GuestRelationField
        prefix={prefix}
        relation={guestRelation.relationType}
        required={true}
        readOnly={readOnly}
      />
    </>
  );
};
