import { applyTransaction } from '@datorama/akita';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import { dispatchForm, PaginationState, Properties, Property } from '@lib/state';
import { areaStore, AreaStore } from './area.store';
import { Area, AreaFilters, mapArea } from './area.model';
import { AreaForms } from './area.forms';

export class AreaService {
  constructor(private readonly store: AreaStore, private readonly areaApi: Properties.AreasApi) {}

  getAreas(
    propertyId: Property['id'],
    { name }: AreaFilters,
    continuationToken?: string | null,
    limit?: number
  ) {
    from(
      this.areaApi.propertyIdAreasGet(
        propertyId,
        name || undefined,
        continuationToken ?? undefined,
        limit
      )
    )
      .pipe(map(({ data }) => data))
      .subscribe(({ data, ...pagination }) =>
        applyTransaction(() => {
          this.store.upsertMany(data);
          this.updateUI({ name });
          this.updatePagination(pagination);
        })
      );
  }

  createArea(propertyId: Property['id'], name: string) {
    from(this.areaApi.propertyIdAreasPost(propertyId, { name }))
      .pipe(
        dispatchForm(AreaForms.AddArea),
        map(({ data }) => mapArea(data.data, data.rooms))
      )
      .subscribe(area =>
        applyTransaction(() => {
          this.store.upsert(area.id, area);
          this.select(area.id);
        })
      );
  }

  getAreaById(propertyId: Property['id'], areaId: Area['id']) {
    from(this.areaApi.propertyIdAreasAreaIdGet(propertyId, areaId))
      .pipe(map(({ data }) => mapArea(data.data, data.rooms)))
      .subscribe(area =>
        applyTransaction(() => {
          this.store.upsert(areaId, area);
          this.select(areaId);
        })
      );
  }

  updateArea(propertyId: Property['id'], areaId: Area['id'], name: string, roomIds: Array<string>) {
    from(this.areaApi.propertyIdAreasAreaIdPost(propertyId, areaId, { name, roomIds }))
      .pipe(
        dispatchForm(AreaForms.EditArea),
        map(({ data }) => mapArea(data.data, data.rooms))
      )
      .subscribe(area => this.store.upsert(areaId, area));
  }

  deleteArea(propertyId: Property['id'], areaId: Area['id']) {
    from(this.areaApi.propertyIdAreasAreaIdDelete(propertyId, areaId))
      .pipe(dispatchForm(AreaForms.RemoveArea))
      .subscribe(() => this.store.remove(areaId));
  }

  select(areaId?: Area['id']) {
    this.store.setActive(areaId ?? null);
  }

  private updateUI(values: Partial<AreaFilters>) {
    this.store.update(({ ui }) => ({
      ui: {
        ...ui,
        ...values,
      },
    }));
  }

  private updatePagination(pagination: PaginationState) {
    this.store.update(() => ({ pagination }));
  }
}

export const areaService = new AreaService(areaStore, new Properties.AreasApi());
