import { combineLatest, defer } from 'rxjs';
import { lightFormat } from 'date-fns';

import { poll, Properties, Reservations, Unsubscribe, unsubscribeCallback } from '@lib/state';
import { RoomCountStore, roomCountStore } from './room-count.store';
import { mapRoomCount } from './room-count.model';
import { RoomCountType } from '@lib/state/api/generated/properties';

export class RoomCountService {
  constructor(
    private readonly store: RoomCountStore,
    private readonly propertiesRoomCountApi: Properties.RoomCountsApi,
    private readonly reservationsRoomCountApi: Reservations.RoomCountsApi
  ) {}

  pollRoomCounts(
    propertyId: string,
    startDate: Date,
    endDate: Date,
    countBy: RoomCountType,
    roomTypeId?: string,
    interval = 30000
  ): Unsubscribe {
    const start = lightFormat(startDate, 'yyyy-MM-dd');
    const end = lightFormat(endDate, 'yyyy-MM-dd');
    const sub = defer(() =>
      combineLatest([
        this.getRoomCountsRequest(propertyId, start, end, countBy, roomTypeId),
        this.getReservationCountsRequest(propertyId, start, end, countBy, roomTypeId),
      ])
    )
      .pipe(poll(interval))
      .subscribe(([propertyCounts, reservationCounts]) => {
        const lookup = new Map<string, Reservations.RoomCountModel>(
          reservationCounts.map(x => [x.date, x])
        );
        this.store.upsertMany(
          propertyCounts.map(propertyCount => {
            const reservationCount = lookup.get(propertyCount.date) ?? {
              date: propertyCount.date,
              reservedRooms: 0,
              checkInCount: 0,
              checkOutCount: 0,
              totalRooms: 0,
              readyRooms: 0,
              inventoryAdjustments: 0,
            };
            return mapRoomCount(propertyId, propertyCount, reservationCount);
          })
        );
      });

    return unsubscribeCallback(sub);
  }

  updateIntervalFilter(start: Date, end: Date) {
    this.store.update(() => ({
      ui: {
        start,
        end,
      },
    }));
  }

  private async getRoomCountsRequest(
    propertyId: string,
    start: string,
    end: string,
    countBy: RoomCountType,
    roomTypeId?: string
  ) {
    const response = await this.propertiesRoomCountApi.propertyIdRoomcountsGet(
      propertyId,
      start,
      end,
      roomTypeId,
      countBy
    );
    return response.data.data;
  }

  private async getReservationCountsRequest(
    propertyId: string,
    start: string,
    end: string,
    countBy: RoomCountType,
    roomTypeId?: string
  ) {
    const response = await this.reservationsRoomCountApi.roomcountsGet(
      propertyId,
      start,
      end,
      roomTypeId,
      countBy
    );
    return response.data.data;
  }
}

export const roomCountService = new RoomCountService(
  roomCountStore,
  new Properties.RoomCountsApi(),
  new Reservations.RoomCountsApi()
);
