import { QueryEntity } from '@datorama/akita';
import bulkAvailableRateStore, {
  BulkAvailableRateStore,
  BulkAvailableRateState,
} from './bulk-available-rate.store';

export class BulkAvailableRateQuery extends QueryEntity<BulkAvailableRateState> {
  constructor(protected store: BulkAvailableRateStore) {
    super(store);
  }

  filters = this.select(({ ui }) => ui);
  bulkAvailableRates = this.selectAll();
}

export const bulkAvailableRateQuery = new BulkAvailableRateQuery(bulkAvailableRateStore);
