import { isKiosk } from 'app/shared/native-interface/nativebridge';
import { NativeActions } from '../native-interface/native-actions';
import { WebActions } from '../web-interface/web-actions';

/**
 * Application Actions that may differ between the Web / Native use cases
 */
export interface AppActions {
  onPrint: () => void;
}

export function createAppActionsInterface(): AppActions {
  if (isKiosk()) {
    return new NativeActions();
  } else {
    return new WebActions();
  }
}
