import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

interface Props extends TypographyProps {
  value?: number | null;
  fixedPoint?: number;
}

export const PercentDisplay: React.FC<Props> = ({ value, fixedPoint, ...props }) => (
  <>
    {value != null && (
      <Typography noWrap {...props}>
        {(value * 100).toFixed(fixedPoint ?? 2)}%
      </Typography>
    )}
  </>
);
