import { faCrown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { VipStatus } from '@lib/state';
import React from 'react';

type Props = Omit<FontAwesomeIconProps, 'icon'> & {
  vipStatus?: VipStatus;
};

export function VipDisplay({ vipStatus, style, ...props }: Props) {
  switch (vipStatus) {
    case VipStatus.Platinum:
      return (
        <FontAwesomeIcon
          {...props}
          icon={faCrown.iconName}
          style={{ ...style, color: '#A0B2C6' }}
          title="Platinum VIP"
        />
      );
    case VipStatus.Gold:
      return (
        <FontAwesomeIcon
          {...props}
          icon={faCrown.iconName}
          style={{ ...style, color: '#FFD43B' }}
          title="Gold VIP"
        />
      );
    default:
      return <></>;
  }
}
