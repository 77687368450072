import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
  FormControlProps,
  FormControlLabelProps,
} from '@material-ui/core';
import { Controller, useFormContext, ValidationOptions } from 'react-hook-form';

import { getHelperText } from './form-methods';

interface FormCheckboxProps extends Omit<FormControlProps, 'error'> {
  name: string;
  label: React.ReactNode;
  defaultChecked?: boolean;
  validationOptions?: Partial<ValidationOptions>;
  helperText?: string | ((errors: any) => string);
  labelPlacement?: FormControlLabelProps['labelPlacement'];
}

export const FormCheckbox: React.FC<FormCheckboxProps> = ({
  name,
  label,
  defaultChecked,
  validationOptions,
  helperText,
  labelPlacement,
  ...props
}) => {
  const { errors } = useFormContext();

  const controlHelperText = getHelperText(errors, helperText);

  return (
    <FormControl error={name in errors} {...props}>
      <FormControlLabel
        control={
          <Controller
            name={name}
            as={Checkbox}
            rules={validationOptions}
            defaultValue={!!defaultChecked}
            valueName="checked"
          />
        }
        labelPlacement={labelPlacement}
        label={label}
      />
      {controlHelperText && <FormHelperText>{controlHelperText}</FormHelperText>}
    </FormControl>
  );
};
