import { AppColors, DataTable, EnumValueDisplay, IDataTableColumn } from '@lib/common';
import { VipHousekeepingOverride, VipStatus } from '@lib/state';
import { Box, Button, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { UpdateVipHousekeepingOverrideModal } from './update-vip-housekeeping-override-modal';
import { DeleteVipHousekeepingOverrideModal } from './delete-vip-housekeeping-override-modal';
import { CreateVipHousekeepingOverrideModal } from './create-vip-housekeeping-override-modal';

interface Props {
  overrides: Array<VipHousekeepingOverride>;
  propertyId: string;
  loading: boolean;
}

export function VipHousekeepingOverrideList({ overrides, propertyId, loading }: Props) {
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [selectedOverride, setSelectedOverride] = useState<VipHousekeepingOverride | undefined>();
  const [selectedVipStatus, setSelectedVipStatus] = useState<VipStatus | undefined>();

  const onCreateClick = useCallback(
    (status: VipStatus) => {
      setSelectedVipStatus(status);
      setCreateOpen(true);
    },
    [setCreateOpen, setSelectedVipStatus]
  );

  const onEditClick = useCallback(
    (override: VipHousekeepingOverride) => {
      setSelectedOverride(override);
      setEditOpen(true);
    },
    [setSelectedOverride, setEditOpen]
  );

  const onDeleteClick = useCallback(
    (override: VipHousekeepingOverride) => {
      setSelectedOverride(override);
      setDeleteOpen(true);
    },
    [setSelectedOverride, setDeleteOpen]
  );

  const onClose = useCallback(() => {
    setCreateOpen(false);
    setEditOpen(false);
    setDeleteOpen(false);
    setSelectedOverride(undefined);
    setSelectedVipStatus(undefined);
  }, [setCreateOpen, setEditOpen, setDeleteOpen, setSelectedOverride, setSelectedVipStatus]);

  const getOverride = useCallback(
    (status: VipStatus) => {
      return overrides.find(o => o.status === status);
    },
    [overrides]
  );

  const renderValue = (value?: number | null) => <Typography>{value ?? '--'}</Typography>;

  const renderButtons = useCallback(
    (status: VipStatus, override?: VipHousekeepingOverride) => {
      if (override) {
        return (
          <Box>
            <Box display="flex" flexDirection="row">
              <Box mr={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onEditClick(override)}
                  style={{ marginRight: 1 }}
                >
                  EDIT POLICY OVERRIDE
                </Button>
              </Box>
              <Button
                type="button"
                variant="outlined"
                style={{ color: AppColors.Red }}
                onClick={() => onDeleteClick(override)}
              >
                REMOVE POLICY OVERRIDE
              </Button>
            </Box>
          </Box>
        );
      }

      return (
        <Button variant="contained" color="secondary" onClick={() => onCreateClick(status)}>
          ENABLE POLICY OVERRIDE
        </Button>
      );
    },
    [onEditClick, onDeleteClick, onCreateClick]
  );

  const columns: Array<IDataTableColumn<VipStatus>> = [
    {
      title: 'Override VIP status',
      valueFactory: status => <EnumValueDisplay value={status} />,
    },
    {
      title: 'Touch Up Clean Frequency (days)',
      valueFactory: status =>
        renderValue(getOverride(status)?.housekeepingPolicy?.touchUpCleanFrequency),
    },
    {
      title: 'Full Clean Frequency (days)',
      valueFactory: status =>
        renderValue(getOverride(status)?.housekeepingPolicy?.fullCleanFrequency),
    },
    {
      title: '',
      valueFactory: status => renderButtons(status, getOverride(status)),
      colSpan: 2,
    },
  ];

  return (
    <>
      {selectedOverride && (
        <>
          <UpdateVipHousekeepingOverrideModal
            override={selectedOverride}
            onClose={onClose}
            open={editOpen}
            propertyId={propertyId}
          />
          <DeleteVipHousekeepingOverrideModal
            override={selectedOverride}
            onClose={onClose}
            open={deleteOpen}
            propertyId={propertyId}
          />
        </>
      )}
      {selectedVipStatus && (
        <CreateVipHousekeepingOverrideModal
          vipStatus={selectedVipStatus}
          onClose={onClose}
          open={createOpen}
          propertyId={propertyId}
        />
      )}
      <DataTable
        items={Object.values(VipStatus)}
        columns={columns}
        getItemKey={status => status}
        loading={loading}
      />
    </>
  );
}
