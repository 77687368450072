import { applyTransaction, setLoading } from '@datorama/akita';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import { Reservations } from '../api';
import { PaginationState } from '../models.common';
import {
  corporateEmployeeStore,
  CorporateEmployeeStore,
  CorporateEmployeeUIState,
} from './corporate-employee.store';

export class CorporateEmployeeService {
  constructor(
    private readonly store: CorporateEmployeeStore,
    private readonly corporateAccountApi: Reservations.CorporateAccountApi
  ) {}

  getEmployees(
    { accountId, employeeNumber }: CorporateEmployeeUIState,
    continuationToken?: string | null,
    limit?: number
  ) {
    from(
      this.corporateAccountApi.corporateaccountIdEmployeesGet(
        accountId,
        employeeNumber,
        continuationToken ?? undefined,
        limit
      )
    )
      .pipe(
        setLoading(this.store),
        map(response => response.data)
      )
      .subscribe(data =>
        applyTransaction(() => {
          this.store.upsertMany(data.data);
          this.updateUI({ accountId, employeeNumber });
          this.updatePaginationState({
            isDone: data.isDone,
            continuationToken: data.continuationToken,
          });
        })
      );
  }

  lookupEmployees({ accountId, employeeNumber }: CorporateEmployeeUIState) {
    from(this.corporateAccountApi.corporateaccountIdEmployeesLookupGet(accountId, employeeNumber))
      .pipe(
        setLoading(this.store),
        map(response => response.data)
      )
      .subscribe(data =>
        applyTransaction(() => {
          this.store.upsertMany(data.data);
          this.updateUI({ accountId, employeeNumber });
        })
      );
  }

  private updateUI(state: Partial<CorporateEmployeeUIState>) {
    this.store.update(({ ui }) => ({
      ui: { ...ui, ...state },
    }));
  }

  private updatePaginationState(state: Partial<PaginationState>) {
    this.store.update(({ pagination }) => ({
      pagination: { ...pagination, ...state },
    }));
  }
}

export const corporateEmployeeService = new CorporateEmployeeService(
  corporateEmployeeStore,
  new Reservations.CorporateAccountApi()
);
