import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Grid, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  isBefore,
  startOfDay,
  parseISO,
  isPast,
  subDays,
  isSameDay,
  addDays,
  max as maxDate,
  min as minDate,
} from 'date-fns';

import {
  Reservation,
  FormStatus,
  reservationService,
  AvailablePaymentMethods,
  UpdateCost,
  GuestProfile,
} from '@lib/state';
import { withAuthorizeNet, PaymentInfoForm, ManagerOverrideInput } from '../../components';
import { SubmitButton, ErrorDisplay } from '../../forms';
import { ReservationFeeList } from '../reservation-fee-list';

interface Props {
  reservation: Reservation;
  paymentMethods: AvailablePaymentMethods;
  paymentGuest: GuestProfile;
  updateCost?: UpdateCost;
  onDateChange: (checkInDate: Date, checkOutDate: Date) => void;

  status: FormStatus;
  error?: Error;

  availabilityStatus: FormStatus;
  availabilityError?: Error;

  isManagerOverrideRequired?: boolean;
}

const EditReservationFormComponent: React.FC<Props> = ({
  reservation,
  paymentMethods,
  paymentGuest,
  updateCost,
  onDateChange,
  status,
  error,
  availabilityStatus,
  availabilityError,
  isManagerOverrideRequired = false,
}) => {
  const { register, getValues, watch, setValue, errors } = useFormContext();

  useEffect(() => {
    reservationService.getReservationModificationOptions(reservation);
  }, [reservation.id]);

  const currentStay = useMemo(
    () => ({
      checkIn: parseISO(reservation.checkInDate),
      checkOut: parseISO(reservation.checkOutDate),
    }),
    [reservation]
  );

  useEffect(() => {
    const validate = (): boolean => {
      const { checkInDate, checkOutDate } = getValues();
      return isBefore(checkInDate, checkOutDate);
    };

    register({ name: 'checkInDate' }, { required: true, validate });
    register({ name: 'checkOutDate' }, { required: true, validate });

    setValue('checkInDate', parseISO(reservation.checkInDate));
    setValue('checkOutDate', parseISO(reservation.checkOutDate));
  }, []);

  const checkInDate = watch('checkInDate', parseISO(reservation.checkInDate));
  const checkOutDate = watch('checkOutDate', parseISO(reservation.checkOutDate));

  useEffect(() => onDateChange(checkInDate, checkOutDate), [
    checkInDate.valueOf(),
    checkOutDate.valueOf(),
    onDateChange,
  ]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            autoOk
            disableToolbar
            disablePast={!isPast(currentStay.checkIn)}
            fullWidth
            variant="inline"
            format="MMMM d"
            label="Check-In Date"
            name="checkInDate"
            value={checkInDate}
            maxDate={
              reservation.changeableBefore && isPast(reservation.changeableBefore)
                ? currentStay.checkIn
                : subDays(checkOutDate, 1)
            }
            error={!!errors.checkInDate}
            onChange={value => setValue('checkInDate', value && startOfDay(value), true)}
            color="primary"
            size="small"
            InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
            disabled={isPast(currentStay.checkIn)}
            data-testid="editReservationCheckInDatePicker"
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            autoOk
            disableToolbar
            disablePast
            fullWidth
            variant="inline"
            format="MMMM d"
            label="Check-Out Date"
            name="checkOutDate"
            minDate={maxDate([
              minDate([reservation.earliestValidCheckOutDate ?? new Date(), currentStay.checkOut]),
              addDays(checkInDate, 1),
            ])}
            value={checkOutDate}
            error={!!errors.checkOutDate}
            onChange={value => setValue('checkOutDate', value && startOfDay(value), true)}
            color="primary"
            size="small"
            InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
            data-testid="editReservationCheckOutDatePicker"
          />
        </Grid>
        {isManagerOverrideRequired && (
          <Grid item xs={6}>
            <ManagerOverrideInput required={isManagerOverrideRequired} />
          </Grid>
        )}

        {(!!errors.checkInDate || !!errors.checkOutDate) && (
          <Grid item xs={12}>
            <Typography>Check-out must be after check-in</Typography>
          </Grid>
        )}
      </Grid>

      {updateCost && (
        <ReservationFeeList
          {...updateCost}
          paymentView={<PaymentInfoForm paymentMethods={paymentMethods} guest={paymentGuest} />}
        />
      )}

      <Box my={3}>
        <ErrorDisplay error={availabilityError ?? error} />
        <SubmitButton
          variant="contained"
          color="secondary"
          fullWidth
          pending={status === FormStatus.Pending || availabilityStatus === FormStatus.Pending}
          disabled={
            availabilityStatus === FormStatus.Pending ||
            availabilityStatus === FormStatus.Error ||
            status === FormStatus.Pending ||
            (isSameDay(checkInDate, currentStay.checkIn) &&
              isSameDay(checkOutDate, currentStay.checkOut))
          }
          data-testid="editReservationUpdateStayButton"
        >
          Update Stay
        </SubmitButton>
      </Box>
    </>
  );
};

export const EditReservationForm = withAuthorizeNet(EditReservationFormComponent);
