// tslint:disable
/**
 * Property Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { ProblemDetails } from '../models';
import { StartGuestSurveyRequest } from '../models';
import { StartGuestSurveyResponse } from '../models';
import { SubmitGuestSurveyRequest } from '../models';
import { SubmitGuestSurveyResponse } from '../models';
/**
 * GuestSurveyApi - axios parameter creator
 * @export
 */
export const GuestSurveyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [start]
     * @param {string} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestsurveyExportGet(
      propertyId: string,
      start?: string,
      end?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdGuestsurveyExportGet.'
        );
      }
      const localVarPath = `/{propertyId}/guestsurvey/export`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (start !== undefined) {
        localVarQueryParameter['start'] =
          (start as any) instanceof Date ? (start as any).toISOString() : start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] =
          (end as any) instanceof Date ? (end as any).toISOString() : end;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {StartGuestSurveyRequest} [startGuestSurveyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestsurveyStartPost(
      propertyId: string,
      startGuestSurveyRequest?: StartGuestSurveyRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdGuestsurveyStartPost.'
        );
      }
      const localVarPath = `/{propertyId}/guestsurvey/start`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof startGuestSurveyRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(startGuestSurveyRequest !== undefined ? startGuestSurveyRequest : {})
        : startGuestSurveyRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {SubmitGuestSurveyRequest} [submitGuestSurveyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestsurveySubmitPost(
      propertyId: string,
      submitGuestSurveyRequest?: SubmitGuestSurveyRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdGuestsurveySubmitPost.'
        );
      }
      const localVarPath = `/{propertyId}/guestsurvey/submit`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof submitGuestSurveyRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(submitGuestSurveyRequest !== undefined ? submitGuestSurveyRequest : {})
        : submitGuestSurveyRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GuestSurveyApi - functional programming interface
 * @export
 */
export const GuestSurveyApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [start]
     * @param {string} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestsurveyExportGet(
      propertyId: string,
      start?: string,
      end?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = GuestSurveyApiAxiosParamCreator(
        configuration
      ).propertyIdGuestsurveyExportGet(propertyId, start, end, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {StartGuestSurveyRequest} [startGuestSurveyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestsurveyStartPost(
      propertyId: string,
      startGuestSurveyRequest?: StartGuestSurveyRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartGuestSurveyResponse> {
      const localVarAxiosArgs = GuestSurveyApiAxiosParamCreator(
        configuration
      ).propertyIdGuestsurveyStartPost(propertyId, startGuestSurveyRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {SubmitGuestSurveyRequest} [submitGuestSurveyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestsurveySubmitPost(
      propertyId: string,
      submitGuestSurveyRequest?: SubmitGuestSurveyRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmitGuestSurveyResponse> {
      const localVarAxiosArgs = GuestSurveyApiAxiosParamCreator(
        configuration
      ).propertyIdGuestsurveySubmitPost(propertyId, submitGuestSurveyRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GuestSurveyApi - factory interface
 * @export
 */
export const GuestSurveyApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [start]
     * @param {string} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestsurveyExportGet(
      propertyId: string,
      start?: string,
      end?: string,
      options?: any
    ): AxiosPromise<any> {
      return GuestSurveyApiFp(configuration).propertyIdGuestsurveyExportGet(
        propertyId,
        start,
        end,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {StartGuestSurveyRequest} [startGuestSurveyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestsurveyStartPost(
      propertyId: string,
      startGuestSurveyRequest?: StartGuestSurveyRequest,
      options?: any
    ): AxiosPromise<StartGuestSurveyResponse> {
      return GuestSurveyApiFp(configuration).propertyIdGuestsurveyStartPost(
        propertyId,
        startGuestSurveyRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {SubmitGuestSurveyRequest} [submitGuestSurveyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestsurveySubmitPost(
      propertyId: string,
      submitGuestSurveyRequest?: SubmitGuestSurveyRequest,
      options?: any
    ): AxiosPromise<SubmitGuestSurveyResponse> {
      return GuestSurveyApiFp(configuration).propertyIdGuestsurveySubmitPost(
        propertyId,
        submitGuestSurveyRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * GuestSurveyApi - object-oriented interface
 * @export
 * @class GuestSurveyApi
 * @extends {BaseAPI}
 */
export class GuestSurveyApi extends BaseAPI {
  /**
   *
   * @param {string} propertyId
   * @param {string} [start]
   * @param {string} [end]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestSurveyApi
   */
  public propertyIdGuestsurveyExportGet(
    propertyId: string,
    start?: string,
    end?: string,
    options?: any
  ) {
    return GuestSurveyApiFp(this.configuration).propertyIdGuestsurveyExportGet(
      propertyId,
      start,
      end,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {StartGuestSurveyRequest} [startGuestSurveyRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestSurveyApi
   */
  public propertyIdGuestsurveyStartPost(
    propertyId: string,
    startGuestSurveyRequest?: StartGuestSurveyRequest,
    options?: any
  ) {
    return GuestSurveyApiFp(this.configuration).propertyIdGuestsurveyStartPost(
      propertyId,
      startGuestSurveyRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {SubmitGuestSurveyRequest} [submitGuestSurveyRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestSurveyApi
   */
  public propertyIdGuestsurveySubmitPost(
    propertyId: string,
    submitGuestSurveyRequest?: SubmitGuestSurveyRequest,
    options?: any
  ) {
    return GuestSurveyApiFp(this.configuration).propertyIdGuestsurveySubmitPost(
      propertyId,
      submitGuestSurveyRequest,
      options
    )(this.axios, this.basePath);
  }
}
