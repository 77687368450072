// tslint:disable
/**
 * Property Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { GetInHouseGuestListResponse } from '../models';
import { ProblemDetails } from '../models';
/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} date
     * @param {string} [corporateAccountName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportPropertyIdInHouseGet(
      propertyId: string,
      date: string,
      corporateAccountName?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling reportPropertyIdInHouseGet.'
        );
      }
      // verify required parameter 'date' is not null or undefined
      if (date === null || date === undefined) {
        throw new RequiredError(
          'date',
          'Required parameter date was null or undefined when calling reportPropertyIdInHouseGet.'
        );
      }
      const localVarPath = `/report/{propertyId}/in-house`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['Date'] =
          (date as any) instanceof Date ? (date as any).toISOString() : date;
      }

      if (corporateAccountName !== undefined) {
        localVarQueryParameter['CorporateAccountName'] = corporateAccountName;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} date
     * @param {string} [corporateAccountName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportPropertyIdInHouseGet(
      propertyId: string,
      date: string,
      corporateAccountName?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInHouseGuestListResponse> {
      const localVarAxiosArgs = ReportApiAxiosParamCreator(
        configuration
      ).reportPropertyIdInHouseGet(propertyId, date, corporateAccountName, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} date
     * @param {string} [corporateAccountName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportPropertyIdInHouseGet(
      propertyId: string,
      date: string,
      corporateAccountName?: string,
      options?: any
    ): AxiosPromise<GetInHouseGuestListResponse> {
      return ReportApiFp(configuration).reportPropertyIdInHouseGet(
        propertyId,
        date,
        corporateAccountName,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
  /**
   *
   * @param {string} propertyId
   * @param {string} date
   * @param {string} [corporateAccountName]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public reportPropertyIdInHouseGet(
    propertyId: string,
    date: string,
    corporateAccountName?: string,
    options?: any
  ) {
    return ReportApiFp(this.configuration).reportPropertyIdInHouseGet(
      propertyId,
      date,
      corporateAccountName,
      options
    )(this.axios, this.basePath);
  }
}
