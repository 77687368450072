import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';

import { useDebouncedEffect } from '@lib/common';
import { FilterSection } from './filter-section';
import isEqual from 'lodash.isequal';

interface Props<T> {
  filters: T;
  onChange: (filters: T) => void;
  children: NonNullable<React.ReactNode>;
}

export function ListFilters<T>({ filters, onChange, children }: Props<T>) {
  const form = useForm<T>({ defaultValues: filters });

  const values = form.watch({ nest: true });

  const [filterValues, setFilterValues] = useState(filters);

  useEffect(() => {
    // React compares objects by reference. In order to allow filters
    // to be passed as an object, we need to perform a deep comparison.
    if (isEqual(values, filterValues)) return;

    setFilterValues(values);

    // Disabling cra warning about filterValues as
    // this only needs to be run when values changes.
    // eslint-disable-next-line
  }, [values]);

  useDebouncedEffect(() => onChange(filterValues), 500, [filterValues, onChange]);

  return (
    <FilterSection>
      <FormContext {...form}>{children}</FormContext>
    </FilterSection>
  );
}
