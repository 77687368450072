import { ReactNode, useMemo } from 'react';
import { ValidationOptions, FormContextValues, NestDataObject, FieldError } from 'react-hook-form';

export function getInputRef(
  register: FormContextValues['register'],
  values: Record<string, any>,
  validationOptions?:
    | Partial<ValidationOptions>
    | ((values: Record<string, any>) => Partial<ValidationOptions>)
) {
  if (!validationOptions) return register;
  const opt = typeof validationOptions === 'object' ? validationOptions : validationOptions(values);
  return register(opt);
}

export function getHelperText(errors: any, helperText?: ReactNode | ((errors: any) => string)) {
  return typeof helperText === 'function' ? helperText(errors) : helperText;
}

export function useFieldError(
  name: string,
  errors: NestDataObject<Record<string, any>, FieldError>
): FieldError | undefined {
  return useMemo(
    () =>
      name
        // Controls in an array can use [i] syntax for naming.
        .split(/[\.\[\]]/)
        // Remove any empty strings
        .filter(x => !!x)
        .reduce((x, prop) => x?.[prop], errors) as FieldError | undefined,
    [name, errors]
  );
}
