import React, { useCallback, useMemo } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Container } from '@material-ui/core';
import { formatISO } from 'date-fns';

import { sessionQuery } from '@lib/state';
import { useObservable, Section } from '@lib/common';
import { reportQuery, reportService, exportInHouseGuests } from 'app/state';
import { getDate } from 'app/shared';
import { InHouseGuestFilterFields, InHouseGuestHeader } from './in-house-guest-header';
import { InHouseGuestTable } from './in-house-guest-table';

const defaultValues: InHouseGuestFilterFields = {
  date: formatISO(new Date()),
  corporateAccountName: '',
};

export const InHouseGuestReport: React.FC = () => {
  const form = useForm<InHouseGuestFilterFields>({ defaultValues });

  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const inHouseGuests = useObservable(reportQuery.inHouseGuests, 'async');

  const onChange = useCallback(
    ({ date, corporateAccountName }: InHouseGuestFilterFields) => {
      if (propertyId) {
        reportService.loadInHouseGuests(propertyId, getDate(date), corporateAccountName);
      }
    },
    [propertyId]
  );

  const exportData = useMemo(() => inHouseGuests?.map(exportInHouseGuests) ?? [], [inHouseGuests]);

  if (!propertyId) return null;

  return (
    <Container maxWidth="xl">
      <FormContext {...form}>
        <InHouseGuestHeader data={exportData} onChange={onChange} />
      </FormContext>
      <Section title="In-House Guest Report" textAlign="left" disableGutters>
        <InHouseGuestTable guests={inHouseGuests} />
      </Section>
    </Container>
  );
};
