import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container, Grid, Typography } from '@material-ui/core';

import { reservationService, reservationQuery, sessionQuery } from '@lib/state';
import { useObservable, kioskStyles, usePageTitle, LoadingIndicator } from '@lib/common';
import { forceLogoutOnBackAction } from 'app/shared/native-interface/nativebridge';
import { KioskUserReservationsList } from '../components';

export const KioskUserReservationsPage: React.FC<RouteComponentProps> = props => {
  const { hintText } = kioskStyles();
  const propertyId = useObservable(sessionQuery.propertyId, 'async');

  usePageTitle('Your Reservations');

  useEffect(() => {
    forceLogoutOnBackAction();
    if (propertyId) {
      reservationService.getKioskReservations(propertyId);
    }
  }, [propertyId]);

  const availableReservations = useObservable(reservationQuery.reservationsAvailableForCheckIn);
  const currentReservations = useObservable(reservationQuery.currentReservations);
  const isLoading = useObservable(reservationQuery.isLoading);

  // Show be a loading animation if isLoading, else continue
  if (isLoading) {
    return <LoadingIndicator loadingText="Finding reservations..." fillContainer />;
  }

  if (availableReservations.length === 0 && currentReservations.length === 0 && !isLoading) {
    return (
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              color="textPrimary"
              align="center"
              paragraph
              className={hintText}
            >
              You have no reservations available for check-in.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      {availableReservations.length > 0 ? (
        <KioskUserReservationsList reservations={availableReservations} />
      ) : null}
      {currentReservations.length > 0 ? (
        <KioskUserReservationsList reservations={currentReservations} />
      ) : null}
    </Container>
  );
};
