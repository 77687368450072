import React from 'react';
import { Typography } from '@material-ui/core';
import { PropertyAmenity, RoomType, CorporateAccount } from '@lib/state';
import {
  useLookup,
  CurrencyDisplay,
  DateDisplay,
  Link,
  CorporateAffiliationIcon,
  DataTable,
} from '@lib/common';
import { RoomRatePlan } from 'app/state';
import { RoomRateAmenitiesColumns } from './room-rate-amenities-columns';

interface Props {
  plans: Array<RoomRatePlan>;
  roomTypes: Array<RoomType>;
  propertyAmenities: Array<PropertyAmenity>;
  account?: CorporateAccount;
}

export const RatePlanList: React.FC<Props> = ({ plans, roomTypes, propertyAmenities, account }) => {
  const roomTypeLookup = useLookup(roomTypes, x => x.id);

  return (
    <DataTable
      items={plans}
      getItemKey={plan => plan.rateKey}
      columns={[
        {
          title: 'Rank',
          valueFactory: plan => <Typography>{plan.priority}</Typography>,
        },
        {
          title: 'Name',
          valueFactory: plan => {
            let url = `/rates/${plan.id}`;
            if (account) url += `?corporateAccountId=${account.id}`;
            return (
              <Link to={url}>
                {plan.name}
                <CorporateAffiliationIcon
                  corporateAccountId={plan.affiliation?.corporateAccountId}
                />
              </Link>
            );
          },
          colSpan: 1.5,
        },
        {
          title: 'Corporate Plan',
          valueFactory: plan => (
            <Typography>{plan.affiliation?.corporateAccountName ?? '--'}</Typography>
          ),
          colSpan: 1.5,
          hidden: !!account,
        },
        {
          title: 'Room',
          valueFactory: plan => roomTypeLookup.get(plan.roomTypeId)?.name,
        },
        {
          title: 'Promo ',
          valueFactory: plan => plan.promoCode,
          colSpan: 0.9,
        },

        {
          title: 'Std Rate',
          align: 'right',
          valueFactory: plan => <CurrencyDisplay value={plan.standardRate} />,
          colSpan: 0.9,
        },
        {
          title: 'Flex Rate',
          align: 'right',
          valueFactory: plan => <CurrencyDisplay value={plan.flexRate} />,
          colSpan: 0.9,
        },
        {
          title: 'HK Rate',
          align: 'right',
          valueFactory: plan => <CurrencyDisplay value={plan.housekeepingRate} />,
          colSpan: 0.9,
        },
        {
          title: 'HK Fee',
          align: 'right',
          valueFactory: item => <CurrencyDisplay value={item.housekeepingFee} />,
          colSpan: 0.9,
        },
        {
          title: 'Early CI',
          align: 'right',
          valueFactory: plan => <CurrencyDisplay value={plan.earlyCheckInFee} />,
          colSpan: 0.9,
        },
        {
          title: 'Late CO',
          align: 'right',
          valueFactory: plan => <CurrencyDisplay value={plan.lateCheckOutFee} />,
          colSpan: 0.9,
        },
        {
          title: 'Start',
          valueFactory: plan =>
            plan.isDefault ? (
              <Typography>N/A</Typography>
            ) : (
              <DateDisplay date={plan.startDate} dateFormat="M/d/yy" />
            ),
          colSpan: 1.2,
        },
        {
          title: 'End',
          valueFactory: plan =>
            plan.isDefault ? (
              <Typography>N/A</Typography>
            ) : (
              <DateDisplay date={plan.endDate} dateFormat="M/d/yy" />
            ),
          colSpan: 1.2,
        },
        {
          title: 'Weekday Weekend',
          valueFactory: plan =>
            '' + (plan.weekdays ? 'Yes' : 'No') + ' - ' + (plan.weekdays ? 'Yes' : 'No') + '',
          colSpan: 1.25,
        },

        ...RoomRateAmenitiesColumns(propertyAmenities),
      ]}
    />
  );
};
