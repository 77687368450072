import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      position: 'absolute',
      bottom: '1rem',
      left: 0,
      right: 0,

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      zIndex: 2,
    },
    controlDot: {
      margin: '0.15rem',
      minWidth: '3rem',
      height: '8px',
      backgroundColor: theme.palette.common.white,
      opacity: '50%',
      cursor: 'pointer',

      '&$active': {
        opacity: '100%',
      },
    },
    active: {},
  })
);

interface Props {
  index: number;
  itemCount: number;
  setIndex: (index: number) => void;
}

export const CarouselControl: React.FC<Props> = ({ index, itemCount, setIndex }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {[...Array(itemCount)].map((_, i) => (
        <span
          key={i}
          className={clsx(classes.controlDot, { [classes.active]: i === index })}
          onClick={() => setIndex(i)}
        />
      ))}
    </div>
  );
};
