import React, { useMemo } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { parseISO } from 'date-fns';

import {
  DateDisplay,
  kioskStyles,
  ButtonLink,
  RoomInfo,
  useObservable,
  RoomDisplay,
  useCurrentUserId,
} from '@lib/common';
import {
  Reservation,
  entryCodesQuery,
  isReservationAvailableForCheckin,
  ReservationStatus,
} from '@lib/state';
import { PrintQRCodeButton } from 'app/shared';

interface Props {
  reservation: Reservation;
}

export const KioskUserRevervation: React.FC<Props> = ({ reservation }) => {
  const {
    listItem,
    subtitleValue,
    internalRow,
    internalColumn,
    listItemButton,
    internalColumnDouble,
  } = kioskStyles();

  const userId = useCurrentUserId();
  const isCheckedIn = reservation.status === ReservationStatus.CheckedIn;

  const entryCodes = useObservable(entryCodesQuery.entryCodes);

  const roomNumber = useMemo(
    () => entryCodes.find(x => x.reservationId === reservation.id)?.roomNumber,
    [reservation, entryCodes]
  );

  const stayDates = useMemo(
    () => ({
      checkInDate: parseISO(reservation.checkInDate),
      checkOutDate: parseISO(reservation.checkOutDate),
    }),
    [reservation]
  );

  const contact = reservation.contacts[0];
  const room = reservation.rooms[0];

  return (
    <Grid
      item
      xs={12}
      className={listItem}
      data-testid={`kioskReservationItem:${reservation.recordNumber}`}
    >
      <div className={internalRow}>
        <Typography variant="h4">
          {contact.name.first} {contact.name.last}
        </Typography>
      </div>
      <div className={internalRow}>
        {!isCheckedIn ? (
          <Typography variant="subtitle2">
            CONF CODE <span className={subtitleValue}>{reservation.recordNumber}</span>
          </Typography>
        ) : (
          <RoomInfo roomNumber={roomNumber ?? ''} centered={false} />
        )}
      </div>
      <div className={internalRow}>
        <div className={internalColumn}>
          <Typography variant="subtitle2">Check-In</Typography>
          <DateDisplay date={stayDates.checkInDate} dateFormat="MMM d" variant="h4" />
        </div>
        <div className={internalColumn}>
          <Typography variant="subtitle2">Check-Out</Typography>
          <DateDisplay date={stayDates.checkOutDate} dateFormat="MMM d" variant="h4" />
        </div>
        <div className={internalColumnDouble}>
          <Typography variant="subtitle2">Room Type</Typography>
          <Typography variant="h4">
            <RoomDisplay propertyId={reservation.propertyId} roomTypeId={room.roomTypeId} />
          </Typography>
        </div>
      </div>
      {!isCheckedIn && isReservationAvailableForCheckin(reservation) ? (
        <ButtonLink
          to={`/kiosk/${reservation.id}/check-in`}
          size="large"
          fullWidth
          className={listItemButton}
          data-testid="kioskReservationCheckInButton"
        >
          Check-In
        </ButtonLink>
      ) : (
        <PrintQRCodeButton
          variant="outlined"
          color="secondary"
          fullWidth
          className={listItemButton}
          userId={userId}
          reservation={reservation}
          printAllGuests={true}
        >
          Print Room Key
        </PrintQRCodeButton>
      )}
    </Grid>
  );
};
