import { QueryEntity } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaymentMethodState, PaymentMethodStore, paymentMethodStore } from './payment-method.store';

export class PaymentMethodQuery extends QueryEntity<PaymentMethodState> {
  constructor(protected store: PaymentMethodStore) {
    super(store);
  }

  paymentMethods = this.selectAll({ sortBy: 'defaultMethod' });

  invoiceAccounts = this.select(({ invoiceAccounts }) => invoiceAccounts);

  availablePaymentMethods = combineLatest(this.paymentMethods, this.invoiceAccounts).pipe(
    map(([creditCards, invoiceAccounts]) => ({ creditCards, invoiceAccounts }))
  );
}

export const paymentMethodQuery = new PaymentMethodQuery(paymentMethodStore);
