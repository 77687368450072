import {
  ClaimRequest,
  FormStatus,
  Reservation,
  ReservationForms,
  reservationService,
} from '@lib/state';
import { useEffect } from 'react';
import { useFormEvents } from '../../forms';

export function useClaimReservation(
  reservationId: Reservation['id'],
  code: number,
  claimGuests: ClaimRequest | null
) {
  const [{ status, error }] = useFormEvents(ReservationForms.ClaimReservation);

  useEffect(() => {
    if (status === FormStatus.Initial && claimGuests) {
      const { contact, name } = claimGuests.guest.contact;
      reservationService.claimReservation(
        reservationId,
        code,
        contact,
        name,
        claimGuests.otherGuests
      );
    }
  }, [reservationId, status, claimGuests]);

  return [status, error] as const;
}
