import { useEffect } from 'react';

import { Reservation, reservationService, reservationQuery } from '@lib/state';
import { useObservable } from './useObservable';

export function useReservation(id?: Reservation['id']) {
  useEffect(() => {
    if (id) {
      reservationService.getReservationById(id);
      return reservationService.selectReservation(id);
    }
  }, [id]);

  return useObservable(reservationQuery.activeReservation, 'async');
}
