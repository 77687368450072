import React from 'react';
import { Grid } from '@material-ui/core';

import { Reservations } from '@lib/state';
import {
  FormCityField,
  FormLine1Field,
  FormLine2Field,
  FormStateField,
  FormZipField,
  TextFieldProps,
  useFormPrefix,
  useFieldLabelProps,
} from '../../forms';

interface Props {
  prefix?: string;

  address?: Reservations.Address;

  optional?: boolean;
  billing?: boolean;
  prefixName?: string;
  inputProps?: TextFieldProps;
}

export const AddressForm: React.FC<Props> = ({
  prefix,
  address,
  optional,
  billing,
  prefixName = 'address',
  inputProps = {},
}) => {
  const fieldName = useFormPrefix(prefix);

  const line1LabelProps = useFieldLabelProps(
    fieldName(`${prefixName}.line1`),
    address?.line1,
    inputProps
  );
  const line2LabelProps = useFieldLabelProps(
    fieldName(`${prefixName}.line2`),
    address?.line2,
    inputProps
  );
  const cityLabelProps = useFieldLabelProps(
    fieldName(`${prefixName}.city`),
    address?.city,
    inputProps
  );
  const stateLabelProps = useFieldLabelProps(
    fieldName(`${prefixName}.state`),
    address?.state,
    inputProps
  );
  const zipLabelProps = useFieldLabelProps(
    fieldName(`${prefixName}.zip`),
    address?.zip,
    inputProps
  );

  return (
    <Grid container spacing={1} data-testid="addressForm">
      <Grid item xs={12}>
        <FormLine1Field
          {...inputProps}
          name={fieldName(`${prefixName}.line1`)}
          defaultValue={address?.line1}
          autoComplete={billing ? 'billing address-line1' : undefined}
          required={!optional}
          fullWidth
          InputLabelProps={line1LabelProps}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLine2Field
          {...inputProps}
          name={fieldName(`${prefixName}.line2`)}
          defaultValue={address?.line2}
          autoComplete={billing ? 'billing address-line2' : undefined}
          fullWidth
          InputLabelProps={line2LabelProps}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <FormCityField
          {...inputProps}
          name={fieldName(`${prefixName}.city`)}
          defaultValue={address?.city}
          autoComplete={billing ? 'billing address-level2' : undefined}
          required={!optional}
          fullWidth
          InputLabelProps={cityLabelProps}
        />
      </Grid>
      <Grid item md={4} xs={6}>
        <FormStateField
          {...inputProps}
          name={fieldName(`${prefixName}.state`)}
          defaultValue={address?.state}
          autoComplete={billing ? 'billing address-level1' : undefined}
          required={!optional}
          fullWidth
          InputLabelProps={stateLabelProps}
        />
      </Grid>
      <Grid item md={4} xs={6}>
        <FormZipField
          {...inputProps}
          name={fieldName(`${prefixName}.zip`)}
          defaultValue={address?.zip}
          autoComplete={billing ? 'billing postal-code' : undefined}
          required={!optional}
          fullWidth
          InputLabelProps={zipLabelProps}
        />
      </Grid>
    </Grid>
  );
};
