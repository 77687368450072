import React, { memo } from 'react';
import { Link, Typography, TypographyProps } from '@material-ui/core';
import { formatPhoneNumber } from '@lib/state/utils';
import { KioskOnly, WebOnly } from '../device-display';

interface Props extends TypographyProps {
  phone?: string | null;
}

export const PhoneDisplay: React.FC<Props> = memo(({ phone, ...props }) =>
  !phone ? (
    <Typography {...props}>N/A</Typography>
  ) : (
    <>
      <WebOnly>
        <Link href={`tel:${phone}`} color="inherit" {...props} data-testid="phoneDisplay">
          {formatPhoneNumber(phone) || 'N/A'}
        </Link>
      </WebOnly>
      <KioskOnly>
        <Typography {...props} data-testid="phoneDisplay">
          {formatPhoneNumber(phone) || 'N/A'}
        </Typography>
      </KioskOnly>
    </>
  )
);
