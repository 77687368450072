import { useEffect } from 'react';
import { Reservation, checkInService, checkInQuery } from '@lib/state';
import { useObservable } from '@lib/common';
import { isFrontDeskOrPortal } from 'app/shared';

export function useGetCheckInRoomOptions(reservation?: Reservation) {
  useEffect(() => {
    if (reservation && isFrontDeskOrPortal()) checkInService.getCheckInRoomOptions(reservation);
    // eslint-disable-next-line
  }, [reservation?.id]);
  return useObservable(checkInQuery.roomOptions);
}
