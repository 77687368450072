import React, { useState } from 'react';
import isEmail from 'sane-email-validation';

import { FormTextField, FormTextFieldProps, TextFieldProps } from '../form-text-field';
import { VerifiedIndicator } from './verified-indicator';
import { invalidCharacter } from '../../forms/util';

type Props = Omit<FormTextFieldProps, 'validationOptions' | 'type'> &
  TextFieldProps & {
    verified?: boolean;
  };

export const FormEmailField: React.FC<Props> = ({
  label = 'Email Address',
  name,
  helperText,
  required,
  verified,
  defaultValue,
  InputProps,
  ...props
}) => {
  const [email, setEmail] = useState<string>('');

  return (
    <FormTextField
      {...props}
      type="email"
      name={name}
      defaultValue={defaultValue}
      autoComplete={'email'}
      label={props.InputLabelProps?.prefix ? `${props.InputLabelProps.prefix} ${label}` : label}
      helperText={
        helperText ?? (errors => (errors[name]?.type === 'validate' ? 'Invalid email address' : ''))
      }
      validationOptions={{
        validate: {
          isValidEmail: email => isEmail(email) || (!required && !email),
          noInvalidCharacter: email => !invalidCharacter(email),
        },
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
      }}
      InputProps={{
        ...InputProps,
        startAdornment: verified ? (
          <VerifiedIndicator name={name} value={defaultValue} verified={verified} />
        ) : null,
      }}
      onChange={e => setEmail(e.target.value)}
      error={invalidCharacter(email)}
      data-testid="formEmailInput"
    />
  );
};
