import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    badge: {
      minWidth: '40px',
      marginLeft: '10px',
      textAlign: 'center',
      padding: theme.spacing(0.5),
      borderRadius: '16px',
    },
    /* Styles applied to the badge element if `color="primary"`. */
    primary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    /* Styles applied to the badge element if `color="secondary"`. */
    secondary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    /* Styles applied to the badge element if `color="error"`. */
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  })
);

interface InlineBadgeProps {
  badgeContent: string;
  color?: 'primary' | 'secondary' | 'default' | 'error';
}

export const InlineBadge: React.FC<InlineBadgeProps> = ({ children, badgeContent, color }) => {
  const styles = useStyles();
  const classLookup = {
    primary: styles.primary,
    secondary: styles.secondary,
    error: styles.error,
    default: styles.primary,
  };
  const colorClass: string = color ? classLookup[color] : styles.primary;

  return (
    <div className={styles.container}>
      <div>{children}</div>
      <div className={clsx(styles.badge, colorClass)}>{badgeContent}</div>
    </div>
  );
};
