import React from 'react';
import { Box } from '@material-ui/core';

import { Section } from '@lib/common';
import { AuditLogs } from 'app/shared';

export interface AuditHistoryTabProps {
  userId: string;
}

export const EmployeeAuditLogComponent: React.FC<AuditHistoryTabProps> = ({ userId }) => {
  return (
    <Section title="Employee History">
      <Box>
        <AuditLogs scope="user" id={userId} />
      </Box>
    </Section>
  );
};
