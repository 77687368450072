import { from, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Properties, Property, dispatchForm, isAxiosError, PaginationState } from '@lib/state';
import { Room } from './room-list.model';
import { RoomListStore, roomListStore, RoomListFilters, RoomListUIState } from './room-list.store';
import { applyTransaction } from '@datorama/akita';
import { RoomListForms } from './room-list.form';

export class RoomListService {
  constructor(
    private readonly store: RoomListStore,
    private readonly roomsApi: Properties.RoomsApi
  ) {}

  getRoomList(
    propertyId: Property['id'],
    filters: RoomListFilters,
    continuationToken?: string | null,
    limit?: number
  ) {
    from(
      this.roomsApi.propertyIdRoomsGet(
        propertyId,
        continuationToken ?? undefined,
        limit ?? undefined,
        filters.roomNumber,
        filters.roomTypeId,
        filters.roomZoneId
      )
    )
      .pipe(map(data => data.data))
      .subscribe(data => {
        applyTransaction(() => {
          this.store.upsertMany(data.data);
          this.updateUI({
            propertyId: propertyId,
          });
          this.updateFilters(filters);
          this.updatePaginationState({
            isDone: data.isDone,
            continuationToken: data.continuationToken,
          });
        });
      });
  }

  selectRoom(roomId: Room['id']) {
    this.store.setActive(roomId);
  }

  updateFilters(filters: RoomListFilters) {
    this.updateUI({ filters });
  }

  private updateUI(values: Partial<RoomListUIState>) {
    this.store.update(({ ui }) => ({
      ui: {
        ...ui,
        ...values,
      },
    }));
  }

  deleteRoom(propertyId: Property['id'], id: string) {
    from(this.roomsApi.propertyIdRoomsRoomIdDeleteValidatePost(propertyId, id))
      .pipe(
        switchMap(_ => this.roomsApi.propertyIdRoomsRoomIdDelete(propertyId, id)),
        catchError((e: Error) => {
          if (isAxiosError(e) && e.response?.status === 500) {
            return throwError(new Error('Cannot remove an occupied room.'));
          }
          return throwError(e);
        }),
        dispatchForm(RoomListForms.DeleteRoom)
      )
      .subscribe(rooms => {
        this.store.remove(id);
      });
  }

  private updatePaginationState({ isDone, continuationToken }: PaginationState) {
    this.store.update(() => ({
      pagination: { isDone, continuationToken },
    }));
  }
}

export const roomListService = new RoomListService(roomListStore, new Properties.RoomsApi());
