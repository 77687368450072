import React, { useCallback } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Box, Button, Grid } from '@material-ui/core';

import { Address, AvailablePaymentMethods, FormStatus, GuestProfile, Name } from '@lib/state';
import { SubmitButton, ErrorDisplay } from '../../forms';
import { Section } from '../section';
import { PaymentInfoForm } from './payment-info.form';
import { CardFields } from './credit-card.form';
import { withAuthorizeNet } from '../with-authorize-net';
import { ManagerOverrideInput } from './manager-override.input';

export interface BillingFields {
  paymentProfileId?: string;
  billing?: {
    name: Name;
    address: Address;
  };
  card?: CardFields;
  savePaymentMethod?: boolean;
  overrideCode?: number;
}

interface Props {
  title?: string | null;

  submitLabel: string;
  cancelLabel?: string;

  status: FormStatus;
  error?: Error;

  guest: GuestProfile;

  noSave?: boolean;
  overridable?: boolean;
  paymentMethodOptional?: boolean;

  paymentMethods: AvailablePaymentMethods;

  onSubmit: (fields: BillingFields) => void;
  onCancel: () => void;
}

const PaymentRequiredFormComponent: React.FC<Props> = ({
  title = 'Payment Required',
  submitLabel,
  cancelLabel = 'Cancel',
  status,
  error,
  guest,
  noSave = false,
  overridable = false,
  paymentMethodOptional = false,
  paymentMethods,
  onSubmit,
  onCancel,
  children,
}) => {
  const form = useForm<BillingFields>({ mode: 'onBlur', reValidateMode: 'onChange' });

  const submit = useCallback(
    (values: BillingFields) => {
      if (paymentMethodOptional && values.overrideCode) {
        onSubmit({ overrideCode: values.overrideCode });
      } else {
        onSubmit(Object.assign(values, { overrideCode: values.overrideCode || undefined }));
      }
    },
    [onSubmit, paymentMethodOptional]
  );

  const overrideCode = form.watch('overrideCode');

  return (
    <Section title={title ?? undefined} disableGutters data-testid="paymentRequiredForm">
      <FormContext {...form}>
        <form onSubmit={form.handleSubmit(submit)}>
          {children}
          {overridable && (
            <Box display="flex" justifyContent="flex-end" py={2}>
              <ManagerOverrideInput />
            </Box>
          )}
          <PaymentInfoForm
            guest={guest}
            optional={overridable && paymentMethodOptional && !!overrideCode}
            paymentMethods={paymentMethods}
            noSave={noSave}
          />
          <ErrorDisplay error={error} />
          <Box py={3}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={onCancel}
                  data-testid="paymentRequiredCancelButton"
                >
                  {cancelLabel}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <SubmitButton
                  variant="contained"
                  color="secondary"
                  fullWidth
                  pending={status === FormStatus.Pending}
                  disabled={status === FormStatus.Pending}
                  data-testid="paymentRequiredSubmitButton"
                >
                  {submitLabel}
                </SubmitButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </FormContext>
    </Section>
  );
};

export const PaymentRequiredForm = withAuthorizeNet<Props>(PaymentRequiredFormComponent);
