import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import { CorporateContactModel } from './corporate-contact.model';

export interface CorporateContactState
  extends EntityState<CorporateContactModel, CorporateContactModel['id']> {}

@StoreConfig({ name: 'corporate-contact', resettable: true })
export class CorporateContactStore extends EntityStore<CorporateContactState> {}

export const corporateContactStore = new CorporateContactStore();
