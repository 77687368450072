import React, { useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { Button, Grid, MenuItem, Checkbox, ListItemText } from '@material-ui/core';

import {
  HousekeepingStatusMap,
  HousekeepingStatusEnum,
  RoomStatusMap,
  RoomStatusEnum,
  RoomZone,
  RoomsFilters,
  RoomType,
  VipStatus,
} from '@lib/state';
import { GuestStaysFilters } from 'app/state';
import { FormTextField, FormSelect, EnumValueDisplay } from '@lib/common';
import { RoomTypeModel } from '@lib/state/api/generated/properties';

const housekeepingStatusTypes = Array.from(HousekeepingStatusMap, ([name, value]) => ({
  name,
  value,
}));
const roomStatusTypes = Array.from(RoomStatusMap, ([name, value]) => ({ name, value }));

export interface TapeChartFilterFields {
  confirmationNumber: string;
  guestName: string;
  corporateAccountName: string;
  roomNumber: string;
  zone: string;
  roomTypeId: RoomType['id'];
  roomStatuses: Array<string>;
  houseKeepingStatuses: Array<string>;
  crew: string;
  vipStatus: string;
}

const defaultFormValues: TapeChartFilterFields = {
  confirmationNumber: '',
  guestName: '',
  corporateAccountName: '',
  roomNumber: '',
  zone: 'all',
  roomTypeId: '',
  roomStatuses: [],
  houseKeepingStatuses: [],
  crew: '',
  vipStatus: '',
};

interface Props {
  guestStaysFilters: GuestStaysFilters;
  roomsFilters: RoomsFilters;
  zones: Array<RoomZone>;
  roomTypes: Array<RoomTypeModel>;
  onGuestStaysFiltersChange: (filters: GuestStaysFilters) => void;
  onRoomsFiltersChange: (filters: RoomsFilters) => void;
}

export const GuestStaysFiltersForm: React.FC<Props> = ({
  guestStaysFilters,
  roomsFilters,
  zones,
  roomTypes,
  onGuestStaysFiltersChange,
  onRoomsFiltersChange,
}) => {
  const form = useForm<TapeChartFilterFields>({
    defaultValues: { ...guestStaysFilters, ...roomsFilters },
  });
  const reset = form.reset;

  const {
    confirmationNumber,
    guestName,
    corporateAccountName,
    roomNumber,
    zone,
    roomTypeId,
    roomStatuses,
    houseKeepingStatuses,
    crew,
    vipStatus,
  } = form.watch({ nest: true });

  useEffect(
    () =>
      onGuestStaysFiltersChange({
        confirmationNumber,
        guestName,
        corporateAccountName,
        crew,
        vipStatus: VipStatus[vipStatus as keyof typeof VipStatus],
      }),
    [
      confirmationNumber,
      guestName,
      corporateAccountName,
      crew,
      vipStatus,
      onGuestStaysFiltersChange,
    ]
  );

  useEffect(() => {
    onRoomsFiltersChange({
      roomNumber,
      zone,
      roomTypeId,
      roomStatuses,
      houseKeepingStatuses,
    });
  }, [roomNumber, zone, roomTypeId, roomStatuses, houseKeepingStatuses, onRoomsFiltersChange]);

  return (
    <FormContext {...form}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormTextField
            defaultValue={confirmationNumber ?? defaultFormValues.confirmationNumber}
            name="confirmationNumber"
            label="Confirmation #"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            defaultValue={guestName ?? defaultFormValues.guestName}
            name="guestName"
            label="Guest Name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            defaultValue={corporateAccountName ?? defaultFormValues.corporateAccountName}
            name="corporateAccountName"
            label="Corporate Account Name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            defaultValue={crew ?? defaultFormValues.crew}
            name="crew"
            label="Crew"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            defaultValue={roomNumber ?? defaultFormValues.roomNumber}
            name="roomNumber"
            label="Room #"
            fullWidth
            data-testid="filtersFormRoomNumberInput"
          />
        </Grid>
        <Grid item xs={12}>
          <FormSelect
            defaultValue={zone ?? defaultFormValues.zone}
            name="zone"
            label="Zone"
            fullWidth
          >
            <MenuItem key="all" value="all">
              All
            </MenuItem>
            {zones.map(x => (
              <MenuItem key={x.id} value={x.id}>
                {x.name}
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <Grid item xs={12}>
          <FormSelect defaultValue={''} name="roomTypeId" label="Room Type" fullWidth>
            <MenuItem key="all" value="">
              All
            </MenuItem>
            {roomTypes.map(x => (
              <MenuItem key={x.id} value={x.id}>
                {x.name}
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <Grid item xs={12}>
          <FormSelect
            defaultValue={roomStatuses ?? defaultFormValues.roomStatuses}
            name="roomStatuses"
            label="Room Status"
            fullWidth
            multiple
            renderValue={(selected: any) =>
              (selected as string[])
                .map(x => RoomStatusMap.get(RoomStatusEnum[x as keyof typeof RoomStatusEnum]))
                .join(', ')
            }
          >
            {roomStatusTypes.map(x => (
              <MenuItem key={x.name} value={x.name}>
                <Checkbox checked={roomStatuses?.indexOf(x.name) > -1} />
                <ListItemText primary={x.value} />
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <Grid item xs={12}>
          <FormSelect
            defaultValue={houseKeepingStatuses ?? defaultFormValues.houseKeepingStatuses}
            name="houseKeepingStatuses"
            label="Housekeeping Status"
            fullWidth
            multiple
            renderValue={(selected: any) =>
              (selected as string[])
                .map(x =>
                  HousekeepingStatusMap.get(
                    HousekeepingStatusEnum[x as keyof typeof HousekeepingStatusEnum]
                  )
                )
                .join(', ')
            }
            data-testid="filtersFormStatusInput"
          >
            {housekeepingStatusTypes.map(x => (
              <MenuItem key={x.name} value={x.name}>
                <Checkbox
                  checked={houseKeepingStatuses?.indexOf(x.name) > -1}
                  data-testid={`status${x.name}Checkbox`}
                />
                <ListItemText primary={x.value} />
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <Grid item xs={12}>
          <FormSelect
            defaultValue={vipStatus ?? defaultFormValues.vipStatus}
            name="vipStatus"
            label="VIP Status"
            fullWidth
            data-testid="filtersFormVipStatusSelect"
          >
            <MenuItem value="all" key="all">
              All
            </MenuItem>
            {Object.values(VipStatus).map(x => (
              <MenuItem value={x} key={x}>
                <EnumValueDisplay value={x} />
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => reset(defaultFormValues)}
          >
            Reset Filters
          </Button>
        </Grid>
      </Grid>
    </FormContext>
  );
};
