import React from 'react';
import { FormStatus, Properties, RoomType } from '@lib/state';
import {
  EnumValueDisplay,
  ErrorDisplay,
  Feedback,
  FormNumberField,
  FormSelect,
  FormTextField,
  SubmitButton,
} from '@lib/common';
import { useFormContext } from 'react-hook-form';
import { Grid, makeStyles, createStyles, MenuItem } from '@material-ui/core';
import {
  BedSize,
  BedType,
  OccupancyType,
  RoomTypeOption,
  SmokingPreference,
} from '@lib/state/api/generated/properties';

const useStyles = makeStyles(theme =>
  createStyles({
    disableButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      minWidth: 200,
    },
    submitButton: {
      minWidth: 200,
    },
  })
);

export interface RoomTypeFields {
  name: string;
  abbreviation: string;
  description?: string;
  type?: RoomTypeOption;
  bedType?: BedType;
  bedSize?: BedSize;
  smokingPreference?: SmokingPreference;
  adultMinAge?: number | null;
  adultMaxOccupancy?: number | null;
  childMinAge?: number | null;
  childMaxOccupancy?: number | null;
  occupancyType?: OccupancyType;
}

export const mapRoomTypeFields = (values: RoomTypeFields) => {
  return {
    name: values.name,
    abbreviation: values.abbreviation,
    description: values.description,
    type: values.type ? values.type : undefined,
    bedType: values.bedType ? values.bedType : undefined,
    bedSize: values.bedSize ? values.bedSize : undefined,
    smokingPreference: values.smokingPreference ? values.smokingPreference : undefined,
    adultMinAge: parseNumber(values.adultMinAge),
    adultMaxOccupancy: parseNumber(values.adultMaxOccupancy),
    childMinAge: parseNumber(values.childMinAge),
    childMaxOccupancy: parseNumber(values.childMaxOccupancy),
    occupancyType: values.occupancyType ? values.occupancyType : undefined,
  };
};

const parseNumber = (num?: number | null): number | null => {
  return !!num || num === 0 ? num : null;
};

interface Props {
  roomType?: RoomType;
  status: FormStatus;
  error?: Error;
  reset: () => void;
}

export const RoomTypeForm: React.FC<Props> = ({ roomType, status, error, reset }) => {
  const styles = useStyles();
  const { formState } = useFormContext<RoomTypeFields>();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <FormTextField
          name="name"
          label="Name"
          defaultValue={''}
          validationOptions={{ required: 'This field is required.' }}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormTextField
          name="description"
          label="Description"
          defaultValue={''}
          validationOptions={{ required: 'This field is required.' }}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormTextField
          name="abbreviation"
          label="Abbreviation"
          defaultValue={''}
          validationOptions={{
            maxLength: {
              value: 3,
              message: 'Value cannot be longer than 3 characters.',
            },
          }}
          helperText={errors =>
            errors.abbreviation?.message ?? 'Used for display on the tape chart.'
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormSelect name="type" label="Type" defaultValue={''} fullWidth>
          <MenuItem value={''} dense>
            None
          </MenuItem>
          {Object.values(RoomTypeOption).map(x => (
            <MenuItem key={x} value={x} dense>
              <EnumValueDisplay value={x} disableTypography />
            </MenuItem>
          ))}
        </FormSelect>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormSelect name="bedType" label="Bed Type" defaultValue={''} fullWidth>
          <MenuItem value={''} dense>
            None
          </MenuItem>
          {Object.values(BedType).map(x => (
            <MenuItem key={x} value={x} dense>
              <EnumValueDisplay value={x} disableTypography />
            </MenuItem>
          ))}
        </FormSelect>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormSelect name="bedSize" label="Bed Size" defaultValue={''} fullWidth>
          <MenuItem value={''} dense>
            None
          </MenuItem>
          {Object.values(BedSize).map(x => (
            <MenuItem key={x} value={x} dense>
              <EnumValueDisplay value={x} disableTypography />
            </MenuItem>
          ))}
        </FormSelect>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormSelect name="smokingPreference" label="Smoking Preference" defaultValue={''} fullWidth>
          <MenuItem value={''} dense>
            None
          </MenuItem>
          {Object.values(Properties.SmokingPreference).map(x => (
            <MenuItem key={x} value={x} dense>
              <EnumValueDisplay value={x} disableTypography />
            </MenuItem>
          ))}
        </FormSelect>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormNumberField
          name="adultMinAge"
          label="Adult Min Age"
          type="number"
          defaultValue={''}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormNumberField
          name="adultMaxOccupancy"
          label="Adult Max Occupancy"
          type="number"
          defaultValue={''}
          fullWidth
          required
          validationOptions={{
            min: {
              value: 1,
              message: 'Value cannot be less than one',
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormNumberField
          name="childMinAge"
          label="Child Min Age"
          type="number"
          defaultValue={''}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormNumberField
          name="childMaxOccupancy"
          label="Child Max Occupancy"
          type="number"
          defaultValue={''}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormSelect name="occupancyType" label="Occupancy Type" defaultValue={''} fullWidth>
          {Object.values(OccupancyType).map(x => (
            <MenuItem key={x} value={x} dense>
              <EnumValueDisplay value={x} disableTypography />
            </MenuItem>
          ))}
        </FormSelect>
      </Grid>
      <Grid item xs={12}>
        <Feedback show={status === FormStatus.Success} severity="success" onHide={reset}>
          Room Type {roomType ? 'saved' : 'created'} successfully!
        </Feedback>
        <ErrorDisplay error={error} />
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <SubmitButton
              classes={{ root: styles.submitButton }}
              variant="contained"
              color="secondary"
              pending={status === FormStatus.Pending}
              disabled={status === FormStatus.Pending || !formState.dirty}
            >
              {roomType ? 'Save' : 'Create'} Room Type
            </SubmitButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
