import { format, parseISO } from 'date-fns';

import { Reservations } from '@lib/state/api';
import { JournalEntryUIState } from './journal-entries.store';

export type JournalEntry = Reservations.JournalEntryModel;

export type AccountTotals = Reservations.AccountTotalsModel;

export type JournalAccount = Reservations.LedgerAccount;

export type JournalFilters = JournalEntryUIState['filters'];

export function exportFileName(propertyId: string, startDate?: string, endDate?: string) {
  return `ledger_csv${!!startDate ? `_from_${format(parseISO(startDate), 'MM-dd-yyyy')}` : ''}${
    !!endDate ? `_to_${format(parseISO(endDate), 'MM-dd-yyyy')}` : ''
  }_property:${propertyId}_downloaded_${format(new Date(), 'MM-dd-yyyy')}.csv`;
}
