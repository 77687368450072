// tslint:disable
/**
 * Access Control
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { DoNotDisturbRequest } from '../models';
import { DoNotDisturbResponse } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userDoNotDisturbGet(options: any = {}): RequestArgs {
      const localVarPath = `/user/do-not-disturb`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DoNotDisturbRequest} [doNotDisturbRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userDoNotDisturbPut(doNotDisturbRequest?: DoNotDisturbRequest, options: any = {}): RequestArgs {
      const localVarPath = `/user/do-not-disturb`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof doNotDisturbRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(doNotDisturbRequest !== undefined ? doNotDisturbRequest : {})
        : doNotDisturbRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userQrCodeUserKeyGet(userKey: string, options: any = {}): RequestArgs {
      // verify required parameter 'userKey' is not null or undefined
      if (userKey === null || userKey === undefined) {
        throw new RequiredError(
          'userKey',
          'Required parameter userKey was null or undefined when calling userQrCodeUserKeyGet.'
        );
      }
      const localVarPath = `/user/qr-code/{userKey}`.replace(
        `{${'userKey'}}`,
        encodeURIComponent(String(userKey))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userDoNotDisturbGet(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoNotDisturbResponse> {
      const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).userDoNotDisturbGet(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {DoNotDisturbRequest} [doNotDisturbRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userDoNotDisturbPut(
      doNotDisturbRequest?: DoNotDisturbRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).userDoNotDisturbPut(
        doNotDisturbRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} userKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userQrCodeUserKeyGet(
      userKey: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).userQrCodeUserKeyGet(
        userKey,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userDoNotDisturbGet(options?: any): AxiosPromise<DoNotDisturbResponse> {
      return UserApiFp(configuration).userDoNotDisturbGet(options)(axios, basePath);
    },
    /**
     *
     * @param {DoNotDisturbRequest} [doNotDisturbRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userDoNotDisturbPut(
      doNotDisturbRequest?: DoNotDisturbRequest,
      options?: any
    ): AxiosPromise<void> {
      return UserApiFp(configuration).userDoNotDisturbPut(doNotDisturbRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} userKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userQrCodeUserKeyGet(userKey: string, options?: any): AxiosPromise<string> {
      return UserApiFp(configuration).userQrCodeUserKeyGet(userKey, options)(axios, basePath);
    },
  };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userDoNotDisturbGet(options?: any) {
    return UserApiFp(this.configuration).userDoNotDisturbGet(options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {DoNotDisturbRequest} [doNotDisturbRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userDoNotDisturbPut(doNotDisturbRequest?: DoNotDisturbRequest, options?: any) {
    return UserApiFp(this.configuration).userDoNotDisturbPut(doNotDisturbRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} userKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userQrCodeUserKeyGet(userKey: string, options?: any) {
    return UserApiFp(this.configuration).userQrCodeUserKeyGet(userKey, options)(
      this.axios,
      this.basePath
    );
  }
}
