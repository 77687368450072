import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Paper, Typography, Box, Link, Grid } from '@material-ui/core';

import { getSortedContacts, Reservation, ReservationContact } from '@lib/state';
import {
  useCommonStyle,
  KioskOnly,
  NameDisplay,
  EnumValueDisplay,
  GuestRelationDisplay,
  AddressDisplay,
  ContactDisplay,
  RoomDisplay,
  useFeatureEnabled,
  GuestSIdDisplay,
  DataTable,
  IDataTableColumn,
  VipDisplay,
} from '@lib/common';
import { PrintQRCodeButton } from 'app/shared';

interface Props {
  reservation: Reservation;
}

export const ReservationInfoGuestInfo: React.FC<Props> = ({ reservation }) => {
  const styles = useCommonStyle();

  const contacts = getSortedContacts(reservation);
  const showGuestRelation = useFeatureEnabled('GuestRelationships');

  const guestColumns: IDataTableColumn<ReservationContact>[] = useMemo(
    () => [
      {
        title: 'Type',
        valueFactory: ({ contactType }) => (
          <EnumValueDisplay value={contactType} disableTypography={true} />
        ),
      },
      {
        title: 'Name',
        valueFactory: ({ userId, name, vipStatus }) =>
          !!userId ? (
            <Grid container>
              <Grid item xs={11}>
                <Link component={RouterLink} to={`/guests/${userId}`}>
                  <NameDisplay {...name} disableTypography />
                </Link>
              </Grid>
              <Grid item xs={1}>
                <VipDisplay vipStatus={vipStatus} key={userId} />
              </Grid>
            </Grid>
          ) : (
            <NameDisplay {...name} disableTypography />
          ),
      },
      { title: 'Employee Number', valueFactory: ({ employeeNumber }) => employeeNumber },
      ...(showGuestRelation
        ? [
            {
              title: 'Relation',
              valueFactory: ({ relationType }) => (
                <GuestRelationDisplay relation={relationType} disableTypography />
              ),
            } as IDataTableColumn<ReservationContact>,
          ]
        : []),
      {
        title: 'Contact',
        valueFactory: ({ contact }) => <ContactDisplay {...contact} variant="body2" />,
      },
      {
        title: 'Address',
        printable: false,
        valueFactory: ({ address }) => <AddressDisplay {...address} variant="body2" />,
      },
      {
        title: 'Guest SID',
        printable: false,
        valueFactory: ({ sId }) => <GuestSIdDisplay val={sId} />,
      },
      {
        title: 'Room',
        printable: false,
        valueFactory: ({ roomGroup }) => {
          if (roomGroup == null) return roomGroup;

          const room = reservation.rooms[roomGroup] ?? reservation.rooms[0];
          return (
            <>
              Room {roomGroup + 1} /{' '}
              <RoomDisplay
                propertyId={reservation.propertyId}
                roomTypeId={room.roomTypeId}
                roomId={room.roomId || undefined}
              />
            </>
          );
        },
      },
      {
        title: 'Actions',
        align: 'right',
        printable: false,
        valueFactory: ({ userId }) => (
          <KioskOnly frontDesk>
            <PrintQRCodeButton
              color="secondary"
              size="small"
              userId={userId}
              reservation={reservation}
            >
              Print Key
            </PrintQRCodeButton>
          </KioskOnly>
        ),
      },
    ],
    [reservation, showGuestRelation]
  );

  return (
    <Box height="100%" clone>
      <Paper className={styles.paper} elevation={3} square>
        <Typography variant="h5" paragraph>
          Guest Info ({(reservation.guestCountAdult ?? 0) + (reservation.guestCountChild ?? 0)})
        </Typography>
        <Box marginX="-8px" marginBottom="-8px">
          <DataTable items={contacts ?? []} getItemKey={i => i.id} columns={guestColumns} />
        </Box>
      </Paper>
    </Box>
  );
};
