import React from 'react';
import { parse, format } from 'date-fns';
import { Typography } from '@material-ui/core';
import pluralize from 'pluralize';

interface Props {
  label: string;
  timeOfDay?: string;
  gracePeriodInMinutes?: number;
  asDays?: boolean;
  isPrior?: boolean;
  gracePeriodDetailText?: string;
}

export const TimeThresholdDisplay: React.FC<Props> = ({
  timeOfDay,
  gracePeriodInMinutes,
  label,
  asDays,
  isPrior,
  gracePeriodDetailText = 'with a grace period of',
}) => {
  if (!timeOfDay) return null;

  return (
    <>
      <Typography>
        <strong>{label}</strong>
      </Typography>
      <Typography>
        {isPrior ? 'Prior to' : 'After'}{' '}
        {format(parse(timeOfDay, 'HH:mm:ss', new Date()), 'h:mm a')}
      </Typography>
      {!!gracePeriodInMinutes && (
        <Typography variant="body2">
          {gracePeriodDetailText} {gracePeriodInMinutes}{' '}
          {pluralize(asDays ? 'day' : 'minute', gracePeriodInMinutes)}
        </Typography>
      )}
    </>
  );
};
