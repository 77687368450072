import React, { useEffect } from 'react';
import { Box, Container, Grid, Typography, Divider } from '@material-ui/core';

import { forceLogoutOnBackActionOn, isFrontDeskOrPortal, TileLink } from 'app/shared';
import { usePageTitle, kioskStyles } from '@lib/common';

export const KioskLoginMethodPage: React.FC = () => {
  const { hintText, secondaryKioskButton } = kioskStyles();
  const isFrontDesk = isFrontDeskOrPortal();
  usePageTitle('Find Reservation');

  useEffect(() => {
    if (isFrontDesk) {
      forceLogoutOnBackActionOn('/kiosk/login-method');
    }
  }, [isFrontDesk]);

  return (
    <Box py={6} clone>
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              color="textPrimary"
              align="center"
              paragraph
              className={hintText}
            >
              Choose one of the following ways to find your reservation
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <TileLink
              kioskOnly
              to="/kiosk/qr-code?next=/kiosk/reservations"
              variant="h3"
              icon={['far', 'qrcode']}
              iconProps={{ size: '4x' }}
              className={secondaryKioskButton}
              data-testid="kioskQrCodeTile"
            >
              Scan <br />
              QR Code
            </TileLink>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs>
            <TileLink
              to="/kiosk/account"
              variant="h3"
              icon={['far', 'user']}
              iconProps={{ size: '4x' }}
              className={secondaryKioskButton}
              data-testid="kioskAccountLoginTile"
            >
              Account <br />
              Login
            </TileLink>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs>
            <TileLink
              kioskOnly
              to="/kiosk/search"
              variant="h3"
              icon={['far', 'search']}
              iconProps={{ size: '4x' }}
              className={secondaryKioskButton}
              data-testid="kioskSearchNameTile"
            >
              Search <br />
              By Name
            </TileLink>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
