import { Properties, Room, RoomsFilters, RoomStatusEnum, VipStatus } from '@lib/state';
import { HousekeepingStatusEnum } from '@lib/state/api/generated/properties';
import { parseISO } from 'date-fns';
import { GuestStaysFilters } from './guest-stays.store';

export type Guest = Properties.GuestModel;
export type GuestStay = Omit<
  Properties.GuestStayModel,
  'checkInTime' | 'checkInDate' | 'checkOutDate'
> & {
  checkInTime: Date;
  checkInDate: Date;
  checkOutDate: Date;
};

export function getGuestStay({
  checkInTime,
  checkInDate,
  checkOutDate,
  ...props
}: Properties.GuestStayModel) {
  return {
    checkInTime: parseISO(checkInTime),
    checkInDate: parseISO(checkInDate),
    checkOutDate: parseISO(checkOutDate),
    ...props,
  } as GuestStay;
}

function filterByRoomNumber(roomNumber: string, roomNumberFilter: string) {
  return roomNumberFilter === '' || roomNumber.indexOf(roomNumberFilter) > -1;
}

function filterByRoomZone(zone: string, zoneFilter: string) {
  return zoneFilter === 'all' || zoneFilter === '' || zone === zoneFilter;
}

function filterByRoomTypeId(roomTypeId: string, roomTypeFilter: string) {
  return roomTypeFilter === 'all' || roomTypeFilter === '' || roomTypeId === roomTypeFilter;
}

function filterByHousekeepingStatus(
  houseKeepingStatus: HousekeepingStatusEnum,
  houseKeepingStatusesFilter: string[]
) {
  return (
    houseKeepingStatusesFilter.length === 0 ||
    houseKeepingStatusesFilter.indexOf(houseKeepingStatus) > -1
  );
}

function filterRoomsByGuestName(guestNameFilter: string, guestStayForRoom?: GuestStay) {
  return (
    guestNameFilter === '' ||
    // There is a filter and GuestStay for this room
    (!!guestStayForRoom &&
      // - the Guest Name filter matches one of the GuestStay's for this room
      guestStayForRoom.guests
        .map(x => `${x.name.first} ${x.name.middle} ${x.name.last}`.toLowerCase())
        .some(x => x.indexOf(guestNameFilter.toLowerCase()) > -1))
  );
}

function filterRoomsByCrew(crewFilter: string, guestStayForRoom?: GuestStay) {
  if (
    crewFilter === '' ||
    (guestStayForRoom?.affiliation?.crew &&
      guestStayForRoom?.affiliation?.crew.toLowerCase().includes(crewFilter.toLowerCase()))
  ) {
    return true;
  }
  return false;
}

function filterByConfirmationNumber(
  confirmationNumberFilter: string,
  guestStayForRoom?: GuestStay
) {
  return (
    confirmationNumberFilter === '' ||
    // There is a filter and GuestStay for this room
    (!!guestStayForRoom &&
      // There is a GuestStay for the room and the Guest Name filter
      // matches one of the GuestStay's Guest's names for this room
      guestStayForRoom.recordNumber.indexOf(confirmationNumberFilter.toLowerCase()) > -1)
  );
}

// determine room status based on availability of room
function filterByRoomStatus(
  roomStatusesFilter: string[],
  occupants: GuestStay[],
  hasEmptySlot?: boolean
) {
  // No Room Status filters selected
  return (
    roomStatusesFilter.length === 0 ||
    // Both Occupied and Unoccupied -> show all for now
    // NOTE: Until we figure out what "Out of Service" should be doing
    (roomStatusesFilter.includes(RoomStatusEnum.Occupied.toString()) &&
      roomStatusesFilter.includes(RoomStatusEnum.Unoccupied.toString())) ||
    // Only show Occupied rooms, when there are GuestStay's for this room
    (roomStatusesFilter.includes(RoomStatusEnum.Occupied.toString()) &&
      occupants.length > 0 &&
      !hasEmptySlot) ||
    // Only show Partially Occupied rooms, when there are GuestStay's for this room
    (roomStatusesFilter.includes(RoomStatusEnum.PartiallyOccupied.toString()) &&
      occupants.length > 0 &&
      hasEmptySlot) ||
    (roomStatusesFilter.includes(RoomStatusEnum.Unoccupied.toString()) && occupants.length === 0)
  );
}

function filterByCorporateAccount(accountName: string, guestStay?: GuestStay) {
  return (
    !accountName ||
    (guestStay?.affiliation?.corporateAccountName || '')
      .toLowerCase()
      .indexOf(accountName.toLowerCase()) > -1
  );
}

function filterByVipStatus(status?: VipStatus, guestStay?: GuestStay) {
  return !status || !!guestStay?.guests.some(g => g.vipStatus === status);
}

/*
 * Filter behavior logic
    
 ConfirmationNumber, GuestName -> only show rooms with a Guest Stay that matches filter value
 RoomNumber, Zone, HousekeepingStatus -> only show rooms matching filter value
 RoomStatus -> Occupied = when guestStaysForRoom is not empty  

*/
export function filterRoom(
  filters: RoomsFilters & GuestStaysFilters,
  room: Room,
  occupants: GuestStay[],
  guestStayForRoom?: GuestStay,
  hasEmptySlot?: boolean
): boolean {
  return (
    filterByConfirmationNumber(filters.confirmationNumber, guestStayForRoom) &&
    filterRoomsByGuestName(filters.guestName, guestStayForRoom) &&
    filterRoomsByCrew(filters.crew, guestStayForRoom) &&
    filterByRoomNumber(room.roomNumber, filters.roomNumber) &&
    filterByRoomZone(room.roomZoneId, filters.zone) &&
    filterByRoomTypeId(room.roomTypeId, filters.roomTypeId) &&
    filterByHousekeepingStatus(room.housekeepingStatus, filters.houseKeepingStatuses) &&
    filterByRoomStatus(filters.roomStatuses, occupants, hasEmptySlot) &&
    filterByCorporateAccount(filters.corporateAccountName, guestStayForRoom) &&
    filterByVipStatus(filters.vipStatus, guestStayForRoom)
  );
}
