import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { HousekeepingAssignment } from './house-keeping-assignment.model';
import { startOfToday, lightFormat } from 'date-fns';
import { Property } from '../property';
import { Room } from '../rooms';

export interface HousekeepingAssignmentUIState {
  date: string;
  propertyId?: Property['id'];
  roomId?: Room['id'];
}

export interface HousekeepingAssignmentState
  extends EntityState<HousekeepingAssignment, HousekeepingAssignment['id']> {
  ui: HousekeepingAssignmentUIState;
}

@StoreConfig({ name: 'house-keeping-assignments', resettable: true, idKey: 'id' })
export class HousekeepingAssignmentStore extends EntityStore<HousekeepingAssignmentState> {
  constructor() {
    super({ ui: { date: lightFormat(startOfToday(), 'yyyy-MM-dd') } });
  }
}

export const housekeepingAssignmentStore = new HousekeepingAssignmentStore();
