import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from '@material-ui/core';

import {
  checkInQuery,
  CheckInForm,
  Reservation,
  CheckInRequirement,
  checkInService,
  mapReservationContact,
} from '@lib/state';
import {
  CheckInConfirmation,
  CheckInError,
  useObservable,
  useFormEvents,
  usePageTitle,
  kioskStyles,
  LoadingIndicator,
  BillingFields,
  CheckInRequirements,
} from '@lib/common';
import { isFrontDeskOrPortal, PrintQRCodeButton } from 'app/shared';

interface KioskCheckInProps {
  reservation?: Reservation;
  requirements: Array<CheckInRequirement> | null;

  onCheckIn: (payment?: BillingFields) => void;
}

export const KioskCheckIn: React.FC<KioskCheckInProps> = ({
  reservation,
  requirements,
  onCheckIn,
}) => {
  const { listItem, listContentBlock, kioskOutlinesButton } = kioskStyles();

  const history = useHistory();

  const [{ error, status }] = useFormEvents(CheckInForm.CheckIn);

  const stay = useObservable(checkInQuery.guestStay, 'async');

  const guest = useMemo(() => {
    const contact = reservation?.contacts.find(x => x.userId === reservation.userId);

    return mapReservationContact(contact);
  }, [reservation]);

  let pageTitle = 'Checking-In';
  if (stay && stay.reservation.contacts.length > 0) {
    pageTitle = 'Welcome ' + stay.reservation.contacts[0].name.first;
  }
  usePageTitle(pageTitle);

  useEffect(() => {
    if (reservation && requirements?.length === 0) {
      onCheckIn();
    }
    // if dependency is reservation instead
    // of reservation.id, the call to check-in
    // happens 4 times
    // eslint-disable-next-line
  }, [reservation?.id, requirements, onCheckIn]);

  const finishRoute = isFrontDeskOrPortal() ? '/reservations' : '/kiosk';

  const onCancel = useCallback(() => {
    checkInService.reset();
    history.push(finishRoute);
  }, [history, finishRoute]);

  if (!reservation) return <LoadingIndicator fillContainer />;

  if (error) return <CheckInError error={error} />;

  if (stay && stay.rooms.length > 0) {
    return (
      <Container maxWidth="xl" data-testid="kioskCheckIn">
        <CheckInConfirmation
          {...stay}
          variant="h4"
          containerClass={listItem}
          finishRoute={finishRoute}
          printView={
            <PrintQRCodeButton
              variant="outlined"
              color="secondary"
              fullWidth
              className={kioskOutlinesButton}
              userId={reservation.userId}
              reservation={reservation}
              printAllGuests={true}
            >
              Print Code
            </PrintQRCodeButton>
          }
        />
      </Container>
    );
  }

  if (requirements?.length) {
    return (
      <Container maxWidth="xl" data-testid="kioskCheckIn">
        <div className={listContentBlock}>
          <CheckInRequirements
            requirements={requirements}
            status={status}
            guest={guest}
            reservation={reservation}
            onSubmit={onCheckIn}
            onCancel={onCancel}
            noSave={true}
            overridable={requirements.some(x => x.isOverridable)}
            paymentMethodOptional={requirements.every(x => x.isOverridable)}
          />
        </div>
      </Container>
    );
  }

  return <LoadingIndicator loadingText="Checking In..." fillContainer />;
};
