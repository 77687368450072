import { QueryEntity } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { sessionQuery, SessionQuery } from '@lib/state';
import { AreaState, AreaStore, areaStore } from './area.store';

export class AreaQuery extends QueryEntity<AreaState> {
  constructor(store: AreaStore, private readonly sessionQuery: SessionQuery) {
    super(store);
  }

  filters = this.select(x => x.ui);
  pagination = this.select(x => x.pagination);

  areas = combineLatest([this.sessionQuery.propertyId, this.filters]).pipe(
    switchMap(([propertyId, { name }]) =>
      this.selectAll({
        filterBy: [
          x => x.property.id === propertyId,
          x => x.name.toLowerCase().startsWith(name.toLowerCase()),
        ],
        sortBy: 'name',
      })
    )
  );

  activeArea = this.selectActive();
}

export const areaQuery = new AreaQuery(areaStore, sessionQuery);
