import { useEffect } from 'react';
import { Property, propertyService, propertyQuery } from '@lib/state';
import { useObservable } from './useObservable';

export function usePropertyConfiguration(propertyId?: Property['id'] | null) {
  useEffect(() => {
    if (propertyId) {
      propertyService.loadPropertyConfiguration(propertyId);
    }
  }, [propertyId]);

  return useObservable(propertyQuery.configuration);
}
