import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { Feedback } from '../forms';
import { useAcceptJs } from '../utils';
import { LoadingIndicator } from './loading-indicator';

export function withAuthorizeNet<T>(WrappedComponent: React.ComponentType<T>): React.FC<T> {
  return (props: T) => {
    const [authNetLoading, authNetLoadingError] = useAcceptJs();
    if (authNetLoading) return <LoadingIndicator loadingText="Loading..." />;

    if (!authNetLoading && !authNetLoadingError) return <WrappedComponent {...props} />;

    return (
      <Box>
        <Feedback show severity="error">
          <Typography>
            {authNetLoadingError?.message ?? 'Payment service is currently unavailable'}
          </Typography>
        </Feedback>
      </Box>
    );
  };
}
