import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import { useHasRole, useObservable, withPropertyFeatures } from '@lib/common';
import { Admin, FrontDesk, Manager, sessionQuery } from '@lib/state';
import { ResourceLoader } from './resource-loader';
import { NavLayout } from './shared';
import { AccountRoutes } from './account';
import { AreaRoutes } from './areas';
import { TapeChartRoutes } from './tape-chart';
import { ReservationsRoutes } from './reservations';
import { KioskRoutes } from './kiosk';
import { RoomRatePlansRoutes } from './room-rate-plans';
import { HousekeepingRoutes } from './housekeeping';
import { LedgerRoutes } from './ledger';
import { isFrontDesk, isKiosk } from './shared/native-interface/nativebridge';
import { NativeBridgeProvider } from './shared/native-interface/nativebridge-provider';
import { ReportsRoutes } from './reports';
import { UserManagementRoutes } from './user-management';
import { PropertyRoutes } from './property';
import { RoomTypeRoutes } from './room-types/room-types.route';
import { GuestManagementRoutes } from './guest-management';
import { UnauthorizedPage } from './account/pages';
import { CustomerSignupPage } from './customer-signup';
import { CorporateAccountRoutes } from './corporate-account';
import { DeviceEnrollmentRoutes } from './device-enrollment';
import { InventoryAdjustmentRoutes } from 'app/inventory-adjustments';
import { MaintenanceTicketRoutes } from './maintenance/maintenance.routes';
import { QRCodeRoutes } from './qr-codes';

export const AppRoutes: React.FC = withPropertyFeatures(() => {
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');
  const isEmployee = useObservable(sessionQuery.isEmployee);

  if (isLoggedIn === undefined) return null;

  return (
    <NavLayout>
      <Switch>
        <Route path="/account" component={AccountRoutes} />
        <Route path="/kiosk" component={KioskRoutes} />
        <Route path="/signup" component={CustomerSignupPage} />

        {/* Show unauthorized page if current user does not have employee role */}
        {isLoggedIn && !isEmployee && <Route path="/" exact component={UnauthorizedPage} />}
        {isLoggedIn && !isEmployee && <Redirect to="/" />}

        {isLoggedIn && <Route path="/" component={PrivateRoutes} />}
        {/* Redirect to root if no route matches */}
        {isLoggedIn && <Redirect to="/" />}

        {/* Redirect to sign-in based on platform. */}
        {isFrontDesk() && <Redirect to="/kiosk/front-desk" />}
        {isKiosk() && <Redirect to="/kiosk" />}
        <Redirect to="/account/sign-in" />
      </Switch>
      <NativeBridgeProvider />
    </NavLayout>
  );
}, sessionQuery.propertyId);

const PrivateRoutes: React.FC = () => {
  const isAdmin = useHasRole(Admin);
  const isLoading = useObservable(sessionQuery.isLoadingRoles);
  const isAdminManagerFrontDesk = useHasRole(Manager, Admin, FrontDesk);

  return (
    <ResourceLoader>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/tape-chart" />
        </Route>
        <Route path="/tape-chart" component={TapeChartRoutes} />
        <Route path="/reservations" component={ReservationsRoutes} />
        <Route path="/housekeeping" component={HousekeepingRoutes} />
        <Route path="/reports" component={ReportsRoutes} />
        <Route path="/ledger" component={LedgerRoutes} />
        <Route path="/property" component={PropertyRoutes} />
        <Route path="/guests" component={GuestManagementRoutes} />
        <Route path="/maintenance" component={MaintenanceTicketRoutes} />
        <Route path="/qrcodes" component={QRCodeRoutes} />
        {isAdmin && <Route path="/areas" component={AreaRoutes} />}
        {isAdmin && <Route path="/enrollment" component={DeviceEnrollmentRoutes} />}
        {isAdmin && <Route path="/room-types" component={RoomTypeRoutes} />}
        {isAdmin && <Route path="/rates" component={RoomRatePlansRoutes} />}
        {isAdmin && <Route path="/user-management" component={UserManagementRoutes} />}
        {isAdmin && <Route path="/corporate-accounts" component={CorporateAccountRoutes} />}
        {isAdminManagerFrontDesk && (
          <Route path="/inventory-adjustments" component={InventoryAdjustmentRoutes} />
        )}
        {isLoading ? (
          <Route>
            <LinearProgress />
          </Route>
        ) : (
          <Redirect to="/" />
        )}
      </Switch>
    </ResourceLoader>
  );
};
