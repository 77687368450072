import React from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import { GuestRelationComponent } from './guest-relation.component';
import { GuestRelation, GuestRelationTypeEnum } from '@lib/state';

interface Props {
  relations?: GuestRelation[];
  label: string;
  readOnly?: boolean;
}

export const GuestRelationList: React.FC<Props> = ({ label, relations, readOnly = false }) => {
  if (!relations?.length) return null;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">{label}</Typography>
      </Grid>
      {relations.map((guestRelation, i) => (
        <>
          {guestRelation.relationType === GuestRelationTypeEnum.HeadOfHousehold && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
          <Grid item key={i} xs={12} sm={6} md={4}>
            <GuestRelationComponent
              prefix={`relations[${i}]`}
              guestRelation={guestRelation}
              readOnly={readOnly}
            />
          </Grid>
        </>
      ))}
    </Grid>
  );
};
