import { RoomOccupancy } from './report.model';

export type OccupancyExportModel = {
  Date: string;
  TotalRooms: number;
  OutOfService: number;
  AvailableRooms: number;
  Occupied: number;
  NoShow: number;
  occupancyPercentage: number;
  TotalRevenue: number;
  AverageDailyRate: number;
  RevPar: number;
};

export function exportRoomOccupancy(occupancy: RoomOccupancy): OccupancyExportModel {
  return {
    Date: occupancy.date,
    TotalRooms: occupancy.totalRooms,
    OutOfService: occupancy.outOfService,
    AvailableRooms: occupancy.availableRooms,
    Occupied: occupancy.occupied,
    NoShow: occupancy.noShow,
    occupancyPercentage: occupancy.occupancyPercentage,
    TotalRevenue: occupancy.totalRevenue,
    AverageDailyRate: occupancy.averageDailyRate,
    RevPar: occupancy.revPar,
  };
}
