import React, { useCallback } from 'react';
import { Grid, Button } from '@material-ui/core';
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';

import { FormTextField, TextFieldProps } from '../../forms';
import { PaymentMethod, formatBillingAddress, paymentMethodService } from '@lib/state';
import { useCurrentUserId } from '../../utils';

interface Props {
  inputProps?: TextFieldProps;
  paymentMethod: PaymentMethod;
  paymentUserId: string;
}

export const ExistingCardForm: React.FC<Props> = ({ inputProps, paymentMethod, paymentUserId }) => {
  const { getCardImageProps } = usePaymentInputs();
  const loggedInUserId = useCurrentUserId();

  // if the logged in user is not the owner of these payment methods,
  // then the owner's user id needs to be passed into the API request
  const deleteSavedCard = useCallback(() => {
    paymentMethodService.deletePaymentMethod(
      paymentMethod.authNetPaymentId,
      paymentUserId !== loggedInUserId ? paymentUserId : undefined
    );
  }, [paymentMethod.authNetPaymentId, paymentUserId, loggedInUserId]);

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <FormTextField
          {...inputProps}
          name="cardNumber"
          value={`•••• •••• •••• ${paymentMethod.cardLastFour}`}
          InputProps={{
            startAdornment: <svg {...getCardImageProps({ images })} />,
          }}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          {...inputProps}
          name="expiryDate"
          value={`${paymentMethod.expirationMonth}/${paymentMethod.expirationYear}`}
          fullWidth
          disabled
          data-testid="existingCardExpiryDateInput"
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          {...inputProps}
          name="billingAddress"
          value={formatBillingAddress(paymentMethod.billingAddress)}
          fullWidth
          disabled
          multiline
        />
      </Grid>
      <Button size="small" onClick={deleteSavedCard}>
        delete
      </Button>
    </Grid>
  );
};
