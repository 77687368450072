import { QueryEntity } from '@datorama/akita';
import { MaintenanceTicketState, maintenanceTicketStore } from './maintenance-ticket.store';
import { switchMap } from 'rxjs/operators';
import { dateRangeFilter } from './maintenance-ticket.model';
export class MaintenanceTicketQuery extends QueryEntity<MaintenanceTicketState> {
  filters = this.select(({ ui }) => ui);

  maintenanceTickets = this.filters.pipe(
    switchMap(ui =>
      this.selectAll({
        filterBy: [
          x => x.propertyId === ui.propertyId,
          x => dateRangeFilter(x, ui.startDate, ui.endDate),
          x => ui.status == null || x.status === ui.status,
          x => ui.assignedTo == null || x.assignedTo?.id === ui.assignedTo,
          x => x.deletedOn === null,
          x => ui.priority == null || x.priority === ui.priority,
          x => ui.active == null || ui.active === false || x.isActive === ui.active,
          x =>
            ui.textFilter == null ||
            x.ticketNumber.toString().toLowerCase().includes(ui.textFilter.toLowerCase()) ||
            (!!x.roomNumber && x.roomNumber.toLowerCase().includes(ui.textFilter.toLowerCase())) ||
            (!!x.description &&
              x.description.some(y => y.toLowerCase().includes(ui.textFilter.toLowerCase()))),
        ],
      })
    )
  );
  isLoading = this.selectLoading();

  selectedTicket = this.selectActive();

  pagination = this.select(x => x.pagination);

  photos = this.select(ui => ui.photos);
}

export const maintenanceTicketQuery = new MaintenanceTicketQuery(maintenanceTicketStore);
