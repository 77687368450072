import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { kioskStyles } from '../guest-kiosk.theme';

interface Props {
  roomNumber: string;
  centered?: boolean;
}

export const RoomInfo: React.FC<Props> = ({ roomNumber, centered = false }) => {
  const { internalRow, block, blueText, xlText } = kioskStyles();

  const alignment = centered ? 'center' : 'left';

  return (
    <div>
      <div className={internalRow}>
        <Typography variant="subtitle2" align={alignment} className={block}>
          Your Room
        </Typography>
      </div>
      <div className={internalRow}>
        <Typography
          variant="h1"
          align={alignment}
          className={clsx(blueText, xlText, block)}
          data-testid="roomNumberDisplay"
        >
          {roomNumber}
        </Typography>
      </div>
    </div>
  );
};
