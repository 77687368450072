import React from 'react';
import { Typography, makeStyles, createStyles } from '@material-ui/core';
import { PurchaseTransaction } from 'app/state';
import { DateDisplay, NameDisplay, ShowMoreButton, DataTable } from '@lib/common';
import parseISO from 'date-fns/parseISO';

const useStyles = makeStyles(theme =>
  createStyles({
    gridContainer: {
      marginTop: theme.spacing(1),
    },
  })
);

interface Props {
  transactions?: PurchaseTransaction[];
  loadMore: () => void;
  isDone: boolean;
}

export const PurchaseTransactionTable: React.FC<Props> = ({
  transactions = [],
  loadMore,
  isDone,
}) => {
  const styles = useStyles();
  if (!transactions || transactions.length === 0) {
    return <Typography>No transactions found.</Typography>;
  }
  return (
    <DataTable
      items={transactions}
      getItemKey={i => i.id}
      children={<ShowMoreButton isDone={isDone} loadMore={loadMore} />}
      columns={[
        {
          title: `Date`,
          valueFactory: item => (
            <DateDisplay date={parseISO(item.purchasedAt)} dateFormat="M/d/yyyy h:mm a" />
          ),
        },
        {
          title: 'Terminal',
          valueFactory: item => item.terminalName,
        },
        {
          title: 'Transaction #',
          valueFactory: item => item.transactionId,
        },
        {
          title: 'Status',
          valueFactory: item => item.status,
        },
        {
          title: 'Item Code',
          valueFactory: item => item.product.code,
        },
        {
          title: 'Item Name',
          valueFactory: item => item.product.name,
        },
        {
          title: 'User Name',
          valueFactory: item => <NameDisplay {...item.userName} />,
        },
        {
          title: 'Type',
          valueFactory: item => item.purchaseType,
        },
        {
          title: 'Reservation #',
          valueFactory: item => item.reservationNumber,
        },
        {
          title: 'Company Name',
          valueFactory: item => item.affiliation?.corporateAccountName,
        },
        {
          title: 'Crew Name',
          valueFactory: item => item.affiliation?.crew,
        },
        {
          title: 'Employee #',
          valueFactory: item => item.affiliation?.employeeNumber,
        },
      ]}
      tableClass={styles.gridContainer}
    />
  );
};
