// tslint:disable
/**
 * Property Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CompleteHousekeepingSurveyRequest } from '../models';
import { GetAssignedHousekeepingRoomsItemResponse } from '../models';
import { GetHousekeeperListResponse } from '../models';
import { GetHousekeepingRoomAssignmentListResponse } from '../models';
import { ProblemDetails } from '../models';
import { RemoveHousekeepingRoomAssignmentsRequest } from '../models';
import { StartHousekeepingRoomAssignmentRequest } from '../models';
import { UpdateHousekeepingRoomAssignmentsRequest } from '../models';
/**
 * HousekeepingApi - axios parameter creator
 * @export
 */
export const HousekeepingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingAssignmentsAssignedGet(
      propertyId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdHousekeepingAssignmentsAssignedGet.'
        );
      }
      const localVarPath = `/{propertyId}/housekeeping/assignments/assigned`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {RemoveHousekeepingRoomAssignmentsRequest} [removeHousekeepingRoomAssignmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingAssignmentsDelete(
      propertyId: string,
      removeHousekeepingRoomAssignmentsRequest?: RemoveHousekeepingRoomAssignmentsRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdHousekeepingAssignmentsDelete.'
        );
      }
      const localVarPath = `/{propertyId}/housekeeping/assignments`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof removeHousekeepingRoomAssignmentsRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            removeHousekeepingRoomAssignmentsRequest !== undefined
              ? removeHousekeepingRoomAssignmentsRequest
              : {}
          )
        : removeHousekeepingRoomAssignmentsRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [date]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingAssignmentsGet(
      propertyId: string,
      date?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdHousekeepingAssignmentsGet.'
        );
      }
      const localVarPath = `/{propertyId}/housekeeping/assignments`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['date'] = date;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {UpdateHousekeepingRoomAssignmentsRequest} [updateHousekeepingRoomAssignmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingAssignmentsPost(
      propertyId: string,
      updateHousekeepingRoomAssignmentsRequest?: UpdateHousekeepingRoomAssignmentsRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdHousekeepingAssignmentsPost.'
        );
      }
      const localVarPath = `/{propertyId}/housekeeping/assignments`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateHousekeepingRoomAssignmentsRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            updateHousekeepingRoomAssignmentsRequest !== undefined
              ? updateHousekeepingRoomAssignmentsRequest
              : {}
          )
        : updateHousekeepingRoomAssignmentsRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomId
     * @param {string} [date]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingAssignmentsRoomIdGet(
      propertyId: string,
      roomId: string,
      date?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdHousekeepingAssignmentsRoomIdGet.'
        );
      }
      // verify required parameter 'roomId' is not null or undefined
      if (roomId === null || roomId === undefined) {
        throw new RequiredError(
          'roomId',
          'Required parameter roomId was null or undefined when calling propertyIdHousekeepingAssignmentsRoomIdGet.'
        );
      }
      const localVarPath = `/{propertyId}/housekeeping/assignments/{roomId}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'roomId'}}`, encodeURIComponent(String(roomId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['date'] = date;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {CompleteHousekeepingSurveyRequest} [completeHousekeepingSurveyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingCompletePost(
      propertyId: string,
      completeHousekeepingSurveyRequest?: CompleteHousekeepingSurveyRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdHousekeepingCompletePost.'
        );
      }
      const localVarPath = `/{propertyId}/housekeeping/complete`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof completeHousekeepingSurveyRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            completeHousekeepingSurveyRequest !== undefined ? completeHousekeepingSurveyRequest : {}
          )
        : completeHousekeepingSurveyRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingGet(propertyId: string, options: any = {}): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdHousekeepingGet.'
        );
      }
      const localVarPath = `/{propertyId}/housekeeping`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {StartHousekeepingRoomAssignmentRequest} [startHousekeepingRoomAssignmentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingStartPost(
      propertyId: string,
      startHousekeepingRoomAssignmentRequest?: StartHousekeepingRoomAssignmentRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdHousekeepingStartPost.'
        );
      }
      const localVarPath = `/{propertyId}/housekeeping/start`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof startHousekeepingRoomAssignmentRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            startHousekeepingRoomAssignmentRequest !== undefined
              ? startHousekeepingRoomAssignmentRequest
              : {}
          )
        : startHousekeepingRoomAssignmentRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HousekeepingApi - functional programming interface
 * @export
 */
export const HousekeepingApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingAssignmentsAssignedGet(
      propertyId: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetAssignedHousekeepingRoomsItemResponse> {
      const localVarAxiosArgs = HousekeepingApiAxiosParamCreator(
        configuration
      ).propertyIdHousekeepingAssignmentsAssignedGet(propertyId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {RemoveHousekeepingRoomAssignmentsRequest} [removeHousekeepingRoomAssignmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingAssignmentsDelete(
      propertyId: string,
      removeHousekeepingRoomAssignmentsRequest?: RemoveHousekeepingRoomAssignmentsRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = HousekeepingApiAxiosParamCreator(
        configuration
      ).propertyIdHousekeepingAssignmentsDelete(
        propertyId,
        removeHousekeepingRoomAssignmentsRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [date]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingAssignmentsGet(
      propertyId: string,
      date?: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetHousekeepingRoomAssignmentListResponse> {
      const localVarAxiosArgs = HousekeepingApiAxiosParamCreator(
        configuration
      ).propertyIdHousekeepingAssignmentsGet(propertyId, date, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {UpdateHousekeepingRoomAssignmentsRequest} [updateHousekeepingRoomAssignmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingAssignmentsPost(
      propertyId: string,
      updateHousekeepingRoomAssignmentsRequest?: UpdateHousekeepingRoomAssignmentsRequest,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetHousekeepingRoomAssignmentListResponse> {
      const localVarAxiosArgs = HousekeepingApiAxiosParamCreator(
        configuration
      ).propertyIdHousekeepingAssignmentsPost(
        propertyId,
        updateHousekeepingRoomAssignmentsRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomId
     * @param {string} [date]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingAssignmentsRoomIdGet(
      propertyId: string,
      roomId: string,
      date?: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetHousekeepingRoomAssignmentListResponse> {
      const localVarAxiosArgs = HousekeepingApiAxiosParamCreator(
        configuration
      ).propertyIdHousekeepingAssignmentsRoomIdGet(propertyId, roomId, date, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {CompleteHousekeepingSurveyRequest} [completeHousekeepingSurveyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingCompletePost(
      propertyId: string,
      completeHousekeepingSurveyRequest?: CompleteHousekeepingSurveyRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = HousekeepingApiAxiosParamCreator(
        configuration
      ).propertyIdHousekeepingCompletePost(propertyId, completeHousekeepingSurveyRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingGet(
      propertyId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHousekeeperListResponse> {
      const localVarAxiosArgs = HousekeepingApiAxiosParamCreator(
        configuration
      ).propertyIdHousekeepingGet(propertyId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {StartHousekeepingRoomAssignmentRequest} [startHousekeepingRoomAssignmentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingStartPost(
      propertyId: string,
      startHousekeepingRoomAssignmentRequest?: StartHousekeepingRoomAssignmentRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = HousekeepingApiAxiosParamCreator(
        configuration
      ).propertyIdHousekeepingStartPost(
        propertyId,
        startHousekeepingRoomAssignmentRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * HousekeepingApi - factory interface
 * @export
 */
export const HousekeepingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingAssignmentsAssignedGet(
      propertyId: string,
      options?: any
    ): AxiosPromise<GetAssignedHousekeepingRoomsItemResponse> {
      return HousekeepingApiFp(configuration).propertyIdHousekeepingAssignmentsAssignedGet(
        propertyId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {RemoveHousekeepingRoomAssignmentsRequest} [removeHousekeepingRoomAssignmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingAssignmentsDelete(
      propertyId: string,
      removeHousekeepingRoomAssignmentsRequest?: RemoveHousekeepingRoomAssignmentsRequest,
      options?: any
    ): AxiosPromise<void> {
      return HousekeepingApiFp(configuration).propertyIdHousekeepingAssignmentsDelete(
        propertyId,
        removeHousekeepingRoomAssignmentsRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [date]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingAssignmentsGet(
      propertyId: string,
      date?: string,
      options?: any
    ): AxiosPromise<GetHousekeepingRoomAssignmentListResponse> {
      return HousekeepingApiFp(configuration).propertyIdHousekeepingAssignmentsGet(
        propertyId,
        date,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {UpdateHousekeepingRoomAssignmentsRequest} [updateHousekeepingRoomAssignmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingAssignmentsPost(
      propertyId: string,
      updateHousekeepingRoomAssignmentsRequest?: UpdateHousekeepingRoomAssignmentsRequest,
      options?: any
    ): AxiosPromise<GetHousekeepingRoomAssignmentListResponse> {
      return HousekeepingApiFp(configuration).propertyIdHousekeepingAssignmentsPost(
        propertyId,
        updateHousekeepingRoomAssignmentsRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomId
     * @param {string} [date]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingAssignmentsRoomIdGet(
      propertyId: string,
      roomId: string,
      date?: string,
      options?: any
    ): AxiosPromise<GetHousekeepingRoomAssignmentListResponse> {
      return HousekeepingApiFp(configuration).propertyIdHousekeepingAssignmentsRoomIdGet(
        propertyId,
        roomId,
        date,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {CompleteHousekeepingSurveyRequest} [completeHousekeepingSurveyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingCompletePost(
      propertyId: string,
      completeHousekeepingSurveyRequest?: CompleteHousekeepingSurveyRequest,
      options?: any
    ): AxiosPromise<void> {
      return HousekeepingApiFp(configuration).propertyIdHousekeepingCompletePost(
        propertyId,
        completeHousekeepingSurveyRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingGet(
      propertyId: string,
      options?: any
    ): AxiosPromise<GetHousekeeperListResponse> {
      return HousekeepingApiFp(configuration).propertyIdHousekeepingGet(propertyId, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} propertyId
     * @param {StartHousekeepingRoomAssignmentRequest} [startHousekeepingRoomAssignmentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdHousekeepingStartPost(
      propertyId: string,
      startHousekeepingRoomAssignmentRequest?: StartHousekeepingRoomAssignmentRequest,
      options?: any
    ): AxiosPromise<void> {
      return HousekeepingApiFp(configuration).propertyIdHousekeepingStartPost(
        propertyId,
        startHousekeepingRoomAssignmentRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * HousekeepingApi - object-oriented interface
 * @export
 * @class HousekeepingApi
 * @extends {BaseAPI}
 */
export class HousekeepingApi extends BaseAPI {
  /**
   *
   * @param {string} propertyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HousekeepingApi
   */
  public propertyIdHousekeepingAssignmentsAssignedGet(propertyId: string, options?: any) {
    return HousekeepingApiFp(this.configuration).propertyIdHousekeepingAssignmentsAssignedGet(
      propertyId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {RemoveHousekeepingRoomAssignmentsRequest} [removeHousekeepingRoomAssignmentsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HousekeepingApi
   */
  public propertyIdHousekeepingAssignmentsDelete(
    propertyId: string,
    removeHousekeepingRoomAssignmentsRequest?: RemoveHousekeepingRoomAssignmentsRequest,
    options?: any
  ) {
    return HousekeepingApiFp(this.configuration).propertyIdHousekeepingAssignmentsDelete(
      propertyId,
      removeHousekeepingRoomAssignmentsRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} [date]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HousekeepingApi
   */
  public propertyIdHousekeepingAssignmentsGet(propertyId: string, date?: string, options?: any) {
    return HousekeepingApiFp(this.configuration).propertyIdHousekeepingAssignmentsGet(
      propertyId,
      date,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {UpdateHousekeepingRoomAssignmentsRequest} [updateHousekeepingRoomAssignmentsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HousekeepingApi
   */
  public propertyIdHousekeepingAssignmentsPost(
    propertyId: string,
    updateHousekeepingRoomAssignmentsRequest?: UpdateHousekeepingRoomAssignmentsRequest,
    options?: any
  ) {
    return HousekeepingApiFp(this.configuration).propertyIdHousekeepingAssignmentsPost(
      propertyId,
      updateHousekeepingRoomAssignmentsRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} roomId
   * @param {string} [date]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HousekeepingApi
   */
  public propertyIdHousekeepingAssignmentsRoomIdGet(
    propertyId: string,
    roomId: string,
    date?: string,
    options?: any
  ) {
    return HousekeepingApiFp(this.configuration).propertyIdHousekeepingAssignmentsRoomIdGet(
      propertyId,
      roomId,
      date,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {CompleteHousekeepingSurveyRequest} [completeHousekeepingSurveyRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HousekeepingApi
   */
  public propertyIdHousekeepingCompletePost(
    propertyId: string,
    completeHousekeepingSurveyRequest?: CompleteHousekeepingSurveyRequest,
    options?: any
  ) {
    return HousekeepingApiFp(this.configuration).propertyIdHousekeepingCompletePost(
      propertyId,
      completeHousekeepingSurveyRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HousekeepingApi
   */
  public propertyIdHousekeepingGet(propertyId: string, options?: any) {
    return HousekeepingApiFp(this.configuration).propertyIdHousekeepingGet(propertyId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} propertyId
   * @param {StartHousekeepingRoomAssignmentRequest} [startHousekeepingRoomAssignmentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HousekeepingApi
   */
  public propertyIdHousekeepingStartPost(
    propertyId: string,
    startHousekeepingRoomAssignmentRequest?: StartHousekeepingRoomAssignmentRequest,
    options?: any
  ) {
    return HousekeepingApiFp(this.configuration).propertyIdHousekeepingStartPost(
      propertyId,
      startHousekeepingRoomAssignmentRequest,
      options
    )(this.axios, this.basePath);
  }
}
