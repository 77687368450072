import {
  MonoTypeOperatorFunction,
  interval as timeInterval,
  Subscription,
  BehaviorSubject,
  EMPTY,
} from 'rxjs';
import {
  repeatWhen,
  switchMapTo,
  tap,
  startWith,
  switchMap,
  skip,
  catchError,
} from 'rxjs/operators';
import { intervalBackoff, IntervalBackoffConfig } from 'backoff-rxjs';
import { memoize } from './functions';

export function poll<T>(interval: number): MonoTypeOperatorFunction<T> {
  return obs =>
    obs.pipe(
      catchError(() => EMPTY), // if failed, retry at next interval
      repeatWhen(() => timeInterval(interval))
    );
}

export interface PollWhenOptions<T> extends IntervalBackoffConfig {
  resetDelay?: (previous: T, current: T) => boolean;
}

/**
/**
 * Continually resubscribe to the source observable with
 * an increasing delay between each resubscription.
 *
 * @param initialInterval Initial delay before resubscribing in milliseconds.
 * @param maxInterval Maximum delay before resubscribing in milliseconds.
 * @param backoffDelay Function to calculate the delay before the next resubscription.
 * @param resetDelay Predicate to reset the delay back to initialInterval
 */
export function pollWhen<T>({
  resetDelay = () => false,
  backoffDelay = (i, interval) => fibonacci(i + 1) * interval,
  ...backoffOpts
}: PollWhenOptions<T>): MonoTypeOperatorFunction<T> {
  const reset = new BehaviorSubject<void>(undefined);
  let previous: T;

  return source =>
    reset.pipe(
      switchMap(() =>
        intervalBackoff({
          ...backoffOpts,
          backoffDelay,
        }).pipe(skip(1))
      ),
      startWith(0),
      switchMapTo(source),
      tap(value => {
        if (previous && resetDelay && resetDelay(previous, value)) {
          reset.next();
        }

        previous = value;
      })
    );
}

const fibonacci: (index: number) => number = memoize(index => {
  if (index === 0) return 0;
  if (index === 1) return 1;

  return fibonacci(index - 2) + fibonacci(index - 1);
});

export type Unsubscribe = () => void;

export function unsubscribeCallback(sub: Subscription): Unsubscribe {
  return () => sub.unsubscribe();
}
