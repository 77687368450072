import React from 'react';

import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';

import { GuestSearchPage, GuestDetailsPage } from './pages';

export const GuestManagementRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={match.path} exact component={GuestSearchPage} />
    <Route path={`${match.path}/:userId`} component={GuestDetailsPage} />
    <Redirect to={match.path} />
  </Switch>
);
