import React, { memo } from 'react';
import { TypographyProps } from '@material-ui/core';

import { PhoneDisplay } from './phone-display';
import { EmailDisplay } from './email-display';

interface Props extends TypographyProps {
  alternateEmail?: string | null;
  email?: string | null;
  phone?: string | null;
}

export const ContactDisplay: React.FC<Props> = memo(
  ({ alternateEmail, email, phone, variant = 'body1', ...props }) => (
    <>
      {(email || alternateEmail) && (
        <EmailDisplay
          email={email}
          alternateEmail={alternateEmail}
          variant={variant}
          display="block"
          {...props}
        />
      )}
      {phone && <PhoneDisplay phone={phone} variant={variant} display="block" {...props} />}
    </>
  )
);
