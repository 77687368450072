import React, { useState, useEffect } from 'react';
import {
  Box,
  DialogContent,
  Typography,
  makeStyles,
  createStyles,
  Tab,
  Tabs,
} from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';

import {
  StyledDialog,
  AppColors,
  useFormEvents,
  usePropertyConfiguration,
  useReservation,
  useObservable,
  useFormWatch,
} from '@lib/common';
import {
  Room,
  RoomUpdate,
  FormStatus,
  roomsService,
  housekeepingAssignmentService,
  propertyQuery,
  housekeepingAssignmentQuery,
  HousekeepingAssignmentForms,
} from '@lib/state';
import { GuestStay, RoomAccessForms, roomAccessService, useGuestNotes } from 'app/state';
import { AuditLogs, GuestNotesList } from 'app/shared';

import { AuditHistoryTabs, AuditHistoryTab } from './audit-history-tabs';
import { GuestStayDetails } from './guest-stay-info';
import { HousekeepingStatus } from './housekeeping-status';
import { StaffAssignmentSection } from './staff-assignment-section';
import { RoomAvailability } from './room-availability';
import { ReservationDetails } from 'app/reservations';
import { ReadyForRent } from './ready-for-rent';

const useStyles = makeStyles(theme =>
  createStyles({
    dialog: {
      padding: '0 16px',
    },
    sectionHeader: {
      display: 'flex',
      position: 'sticky',
      top: 0,
      zIndex: 100,

      padding: theme.spacing(1),
      alignItems: 'center',

      color: theme.palette.common.white,
      backgroundColor: AppColors.DarkBlue,
      fontWeight: 'bold',
      fontSize: '200%',
    },
    sectionContainer: {
      marginBottom: theme.spacing(1),
    },
    sectionBody: {
      marginTop: theme.spacing(1),
      padding: `${theme.spacing(1)}px 0`,
    },
    title: {
      marginLeft: 'auto',
    },
  })
);

interface RoomInfoProps {
  open: boolean;
  stay: GuestStay | undefined;
  room: Room;
  occupants?: GuestStay[];
  onClose: () => void;
  onSave: (room: Room, update: RoomUpdate) => void;
  markReadyForRent: (room: Room, update: RoomUpdate) => void;
  startChangeRoom: () => void;
  createForGuest: boolean;
}

export const RoomInfoModal: React.FC<RoomInfoProps> = ({
  stay,
  room,
  occupants,
  open,
  onClose,
  onSave,
  markReadyForRent,
  createForGuest,
  ...props
}) => {
  const { dialog } = useStyles();
  const { id: roomId, propertyId } = room;

  useEffect(() => {
    if (room) {
      roomsService.getRoomDoorControllerStatus(room);
    }
    // eslint-disable-next-line
  }, [room?.id]);

  const reservation = useReservation(stay?.reservationId);
  const propertyConfiguration = usePropertyConfiguration(room.propertyId);
  const [assignStaffResult, resetAssignStaffFormUI] = useFormEvents(RoomAccessForms.AssignRoom);

  const stayDate = useObservable(propertyQuery.currentStayDate);
  const housekeepingAssignments = useObservable(housekeepingAssignmentQuery.currentRoom);
  const housekeepingRefreshEvent = useFormWatch(
    HousekeepingAssignmentForms.UpdateHousekeepingRoomAssignment
  );

  useEffect(() => {
    roomsService.getRoomById(propertyId, roomId, true);
    housekeepingAssignmentService.getHousekeepingAssignmentForRoom(propertyId, roomId, stayDate);
  }, [stayDate, roomId, propertyId, housekeepingRefreshEvent]);

  const notes = useGuestNotes({
    propertyId: room.propertyId,
    guestStayId: stay?.id,
    guests: reservation?.userId,
  });

  const [tab, setTab] = useState('room');

  if (room.doorControllerStatus == null) return null;

  return (
    <StyledDialog
      title={`Room: ${room.roomNumber}`}
      onClose={onClose}
      aria-labelledby="room-info-title"
      open={open}
      maxWidth="lg"
      scroll="paper"
    >
      <DialogContent className={dialog}>
        <TabContext value={tab}>
          <Tabs
            value={tab}
            onChange={(_, x) => setTab(x)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Room Info" value="room" />
            {reservation && <Tab label="Reservation Info" value="reservation" />}
            {stay && stay.guests && stay.guests.length > 0 && (
              <Tab label="Guest Notes" value="guestnotes" />
            )}
          </Tabs>
          <TabPanel value="room">
            <Box paddingY={3}>
              <GuestStayDetails
                room={room}
                stay={stay}
                reservation={reservation}
                closeModal={onClose}
                {...props}
              />
            </Box>
            <Section
              title="Housekeeping Status"
              banner={<ReadyForRent room={room} stay={stay} onReadyForRent={markReadyForRent} />}
            >
              <HousekeepingStatus
                room={room}
                stay={stay}
                onSave={onSave}
                assignments={housekeepingAssignments}
              />
            </Section>
            <Section title="Room Availability">
              <RoomAvailability room={room} onSave={onSave} stay={stay} />
            </Section>
            {!!propertyConfiguration && !!room.doorControllerStatus.isProvisioned && (
              <Section title="Room Access">
                <StaffAssignmentSection
                  pending={assignStaffResult.status === FormStatus.Pending}
                  success={assignStaffResult.status === FormStatus.Success}
                  error={assignStaffResult.error}
                  onSubmit={e => {
                    roomAccessService.assignRoom(
                      room.propertyId,
                      room.id,
                      e.userId,
                      room.roomNumber,
                      propertyConfiguration
                    );
                  }}
                  onResetFeedback={resetAssignStaffFormUI}
                  room={room}
                  stay={stay}
                  occupants={occupants}
                />
              </Section>
            )}
            <HistorySection
              title="History"
              roomId={room.id}
              reservationId={stay?.reservationId}
              userId={stay?.checkInGuest.userId}
            />
          </TabPanel>
          {reservation && (
            <TabPanel value="reservation">
              <ReservationDetails reservation={reservation} createForGuest={createForGuest} />
            </TabPanel>
          )}
          {stay && stay.guests && stay.guests.length > 0 && (
            <TabPanel value="guestnotes">
              <GuestNotesList
                propertyId={stay.propertyId}
                guestUserId={stay.guests[0].userId}
                guestStayId={stay.id}
                notes={notes}
              />
            </TabPanel>
          )}
        </TabContext>
      </DialogContent>
    </StyledDialog>
  );
};

const Section: React.FC<{ title: string; banner?: React.ReactNode }> = ({
  title,
  banner,
  children,
}) => {
  const styles = useStyles();

  return (
    <Box className={styles.sectionContainer}>
      <Box className={styles.sectionHeader}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      {banner}
      <Box className={styles.sectionBody}>{children}</Box>
    </Box>
  );
};

interface HistorySectionProps {
  roomId: string | undefined;
  reservationId: string | undefined;
  userId: string | undefined;
  title: string;
}

type TabProps = Omit<HistorySectionProps, 'title'>;

interface HistorySectionTab extends AuditHistoryTab {
  scope: string;
  getKey: (props: TabProps) => string | undefined;
}

const tabs: Array<HistorySectionTab> = [
  {
    title: 'Room',
    scope: 'room',
    getKey({ roomId }) {
      return roomId;
    },
  },

  {
    title: 'Reservation',
    scope: 'reservation',
    getKey({ reservationId }) {
      return reservationId;
    },
  },
  {
    title: 'User',
    scope: 'user',
    getKey({ userId }) {
      return userId;
    },
  },
];

const HistorySection: React.FC<HistorySectionProps> = ({ title, ...keys }) => {
  const styles = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Box className={styles.sectionContainer}>
      <Box className={styles.sectionHeader}>
        <Typography variant="h5">{title}</Typography>
        <AuditHistoryTabs
          tabs={tabs}
          isVisible={i => !!tabs[i].getKey(keys)}
          selectedTab={tabIndex}
          setSelectedTab={i => setTabIndex(i)}
        />
      </Box>
      <Box className={styles.sectionBody}>
        <AuditLogs scope={tabs[tabIndex].scope} id={tabs[tabIndex].getKey(keys)} />
      </Box>
    </Box>
  );
};
