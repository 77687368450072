import { EntityStore, EntityState, StoreConfig } from '@datorama/akita';
import { MdmEnrollmentTokenData, Property } from './property.model';

export interface PropertyState extends EntityState<Property, Property['id']> {
  ui: {
    enrollmentToken: MdmEnrollmentTokenData | undefined;
  };
}

@StoreConfig({ name: 'properties', resettable: true })
export class PropertyStore extends EntityStore<PropertyState> {
  constructor() {
    super({ ui: { enrollmentToken: undefined } });
  }
}

export const propertyStore = new PropertyStore();
