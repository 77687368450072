import { QueryEntity } from '@datorama/akita';
import { GuestNoteTypeState, guestNoteTypeStore } from './guest-note-types.store';

export class GuestNoteTypeQuery extends QueryEntity<GuestNoteTypeState> {
  guestNoteTypes = this.selectAll();

  selectedGuestNote = this.selectActive();
}

export const guestNoteTypeQuery = new GuestNoteTypeQuery(guestNoteTypeStore);
