import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/operators';

import { EntryCodesState, EntryCodesStore, entryCodesStore } from './entry-codes.store';

export class EntryCodesQuery extends QueryEntity<EntryCodesState> {
  constructor(protected store: EntryCodesStore) {
    super(store);
  }

  entryCodes = this.selectAll();
  keys = this.selectAll().pipe(map(entryCodes => entryCodes.map(code => code.key)));
  guestRooms = this.selectAll().pipe(
    map(entryCodes => entryCodes.filter(f => !f.isStaffAccess).map(code => code.roomNumber))
  );
}

export const entryCodesQuery = new EntryCodesQuery(entryCodesStore);
