import { StyledDialog, useFormEvents } from '@lib/common';
import {
  FormStatus,
  HousekeepingPolicy,
  VipHousekeepingOverride,
  VipHousekeepingOverrideForms,
  vipHousekeepingOverrideService,
} from '@lib/state';
import React, { useCallback, useEffect } from 'react';
import { VipHousekeepingOverrideForm } from './vip-housekeeping-override-form';
import { DialogContent } from '@material-ui/core';

interface Props {
  override: VipHousekeepingOverride;
  onClose: () => void;
  open: boolean;
  propertyId: string;
}

export function UpdateVipHousekeepingOverrideModal({
  override,
  onClose: close,
  open,
  propertyId,
}: Props) {
  const [{ status, error }, reset] = useFormEvents(VipHousekeepingOverrideForms.UpdateOverride);

  const onSubmit = useCallback(
    (housekeepingPolicy: HousekeepingPolicy) => {
      vipHousekeepingOverrideService.updateVipOverride(
        { ...override, housekeepingPolicy },
        propertyId
      );
    },
    [override, propertyId]
  );

  const onClose = useCallback(() => {
    reset();
    close();
  }, [reset, close]);

  useEffect(() => {
    if (status === FormStatus.Success) onClose();
  }, [status, onClose]);

  return (
    <StyledDialog
      title={`Override Housekeeping Policy for ${override.status} VIP`}
      onClose={onClose}
      open={open}
    >
      <DialogContent>
        <VipHousekeepingOverrideForm
          override={override}
          formStatus={status}
          onSubmit={onSubmit}
          error={error}
        />
      </DialogContent>
    </StyledDialog>
  );
}
