import { useFormEvents } from '@lib/common';
import { FormStatus, HousekeepingAssignmentForms, housekeepingAssignmentService } from '@lib/state';
import { Button, Grid, Typography } from '@material-ui/core';
import { formatISO } from 'date-fns';
import React, { useEffect, useCallback } from 'react';

interface Props {
  roomNumber: string;
  assignmentDate: string;
  propertyId: string;
}

export function StartAssignmentButton({ roomNumber, assignmentDate, propertyId }: Props) {
  const [{ status }] = useFormEvents(HousekeepingAssignmentForms.StartHousekeepingRoomAssignment);

  const onStartClick = useCallback(() => {
    const now = new Date(Date.now());

    housekeepingAssignmentService.startHousekeepingAssignemt(
      {
        roomNumber,
        housekeepingAssignmentDate: assignmentDate,
        startTime: formatISO(now, { representation: 'complete' }),
      },
      propertyId
    );
  }, [roomNumber, assignmentDate, propertyId]);

  useEffect(() => {
    if (status === FormStatus.Success)
      housekeepingAssignmentService.getHousekeepingAssignmentsForUser(propertyId);
  }, [status, propertyId]);

  return (
    <Grid container spacing={2} style={{ marginTop: 32, marginBottom: 32 }}>
      <Grid item xs={12}>
        <Typography style={{ textAlign: 'center' }}>
          You are assigned to this room and may begin cleaning.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={onStartClick} variant="contained" color="secondary" fullWidth>
          Start Assignment
        </Button>
      </Grid>
    </Grid>
  );
}
