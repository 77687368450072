import { useFormEvents, StyledDialog, ErrorDisplay } from '@lib/common';
import {
  FormStatus,
  VipHousekeepingOverride,
  VipHousekeepingOverrideForms,
  vipHousekeepingOverrideService,
} from '@lib/state';
import { DialogContent, Typography, DialogActions, Button } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';

interface Props {
  override: VipHousekeepingOverride;
  onClose: () => void;
  open: boolean;
  propertyId: string;
}

export function DeleteVipHousekeepingOverrideModal({
  override,
  onClose: close,
  open,
  propertyId,
}: Props) {
  const [{ status, error }, reset] = useFormEvents(VipHousekeepingOverrideForms.DeleteOverride);

  const onClose = useCallback(() => {
    reset();
    close();
  }, [reset, close]);

  const onConfirm = useCallback(() => {
    vipHousekeepingOverrideService.removeVipOverride(override.id!, propertyId);
  }, [override, propertyId]);

  useEffect(() => {
    if (status === FormStatus.Success) onClose();
  }, [status, onClose]);

  return (
    <StyledDialog open={open} title="Are you sure?" onClose={onClose}>
      <ErrorDisplay error={error} />
      <DialogContent data-testid="confirmOverrideRemoveDisplay">
        <Typography>
          Are you sure you want to delete the houskeeping override for {override.status}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="contained"
          color="default"
          disabled={!!status && status === FormStatus.Pending}
          onClick={onClose}
          data-testid="confirmCancelButton"
        >
          Cancel
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          disabled={!!status && status === FormStatus.Pending}
          onClick={() => {
            onConfirm();
          }}
          data-testid="confirmButton"
        >
          Confirm
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
