export type KeySelectorFn<T, TKey> = (item: T) => TKey;

export interface IGrouping<TKey, T> extends ReadonlyArray<T> {
  key: TKey;
}

export function* groupBy<T, TKey>(
  collection: Iterable<T>,
  keySelector: KeySelectorFn<T, TKey>
): Iterable<IGrouping<TKey, T>> {
  const groups = new Map<TKey, Grouping<TKey, T>>();

  for (const item of collection) {
    const key = keySelector(item);

    if (!groups.has(key)) {
      groups.set(key, new Grouping(key, item));
    } else {
      groups.get(key)!.push(item);
    }
  }

  yield* groups.values();
}

export class Grouping<TKey, T> extends Array<T> implements IGrouping<TKey, T> {
  constructor(public readonly key: TKey, ...items: Array<T>) {
    super(...items);
  }

  toJSON = () => {
    return {
      key: this.key,
      items: [...this],
    };
  };
}
