import { QueryEntity } from '@datorama/akita';
import { EMPTY } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isWithinInterval, Interval } from 'date-fns';

import { groupJoin } from '../utils';
import { RoomTypeQuery, roomTypeQuery } from '../room-type';
import { RoomRateState, RoomRateStore, roomRateStore } from './room-rate.store';
import { RoomRate } from './room-rate.model';

export class RoomRateQuery extends QueryEntity<RoomRateState> {
  constructor(store: RoomRateStore, private readonly roomTypeQuery: RoomTypeQuery) {
    super(store);
  }

  roomRates = this.select(({ ui }) => ui).pipe(
    filter(x => !!x.checkInDate && !!x.checkOutDate),
    map(({ propertyId, checkInDate, checkOutDate, roomTypeId }) => ({
      propertyId,
      roomTypeId,
      dateInterval: { start: checkInDate, end: checkOutDate } as Interval,
    })),
    switchMap(({ propertyId, roomTypeId, dateInterval }) =>
      this.selectAll({
        filterBy: [
          x => x.propertyId === propertyId,
          x => (roomTypeId ? x.roomTypeId === roomTypeId : true),
          x => x.dailyRates.every(r => isWithinInterval(r.date, dateInterval)),
        ],
        sortBy: sortRoomTypes,
      }).pipe(
        // Because we update the UI state for every change
        // to the propertyId, checkInDate, and checkOutDate
        // an invalid interval can be created that causes the
        // observable chain to error. Without the catching the
        // error, the consumer would need to resubscribe before
        // it gets any new emissions.
        catchError(() => EMPTY)
      )
    )
  );

  updateCost = this.select(({ updateCost }) => updateCost);

  roomTypeRates = this.roomTypeQuery.propertyRoomTypes.pipe(
    switchMap(roomTypes =>
      this.roomRates.pipe(
        map(roomRates => [
          ...groupJoin(
            roomTypes,
            roomRates,
            x => x.id,
            x => x.roomTypeId
          ),
        ])
      )
    )
  );

  propertyId = this.select(({ ui }) => ui.propertyId);
  uiState = this.select(({ ui }) => ui);

  corporateBooking = this.select(({ corporateBooking }) => corporateBooking);

  changeBillingRefunds = this.select(({ changeBillingRefunds }) => changeBillingRefunds);

  policy = this.select(({ policy }) => policy);
}

export const roomRateQuery = new RoomRateQuery(roomRateStore, roomTypeQuery);

function sortRoomTypes(a: RoomRate, b: RoomRate): number {
  return a.dailyRates[0].standardRate - b.dailyRates[0].standardRate;
}
