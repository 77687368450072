// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CreateRateRestrictionRequest } from '../models';
import { CreateRateRestrictionResponse } from '../models';
import { ProblemDetails } from '../models';
import { RateRestrictionListResponse } from '../models';
import { RateRestrictionModel } from '../models';
/**
 * RateRestrictionsApi - axios parameter creator
 * @export
 */
export const RateRestrictionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [propertyId]
     * @param {string} [roomTypeId]
     * @param {string} [roomRatePlanId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    raterestrictionsGet(
      propertyId?: string,
      roomTypeId?: string,
      roomRatePlanId?: string,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/raterestrictions`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (roomTypeId !== undefined) {
        localVarQueryParameter['roomTypeId'] = roomTypeId;
      }

      if (roomRatePlanId !== undefined) {
        localVarQueryParameter['roomRatePlanId'] = roomRatePlanId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    raterestrictionsIdDelete(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling raterestrictionsIdDelete.'
        );
      }
      const localVarPath = `/raterestrictions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    raterestrictionsIdGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling raterestrictionsIdGet.'
        );
      }
      const localVarPath = `/raterestrictions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateRateRestrictionRequest} [createRateRestrictionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    raterestrictionsPost(
      createRateRestrictionRequest?: CreateRateRestrictionRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/raterestrictions`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createRateRestrictionRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            createRateRestrictionRequest !== undefined ? createRateRestrictionRequest : {}
          )
        : createRateRestrictionRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RateRestrictionsApi - functional programming interface
 * @export
 */
export const RateRestrictionsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [propertyId]
     * @param {string} [roomTypeId]
     * @param {string} [roomRatePlanId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    raterestrictionsGet(
      propertyId?: string,
      roomTypeId?: string,
      roomRatePlanId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RateRestrictionListResponse> {
      const localVarAxiosArgs = RateRestrictionsApiAxiosParamCreator(
        configuration
      ).raterestrictionsGet(propertyId, roomTypeId, roomRatePlanId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    raterestrictionsIdDelete(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RateRestrictionModel> {
      const localVarAxiosArgs = RateRestrictionsApiAxiosParamCreator(
        configuration
      ).raterestrictionsIdDelete(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    raterestrictionsIdGet(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RateRestrictionModel> {
      const localVarAxiosArgs = RateRestrictionsApiAxiosParamCreator(
        configuration
      ).raterestrictionsIdGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {CreateRateRestrictionRequest} [createRateRestrictionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    raterestrictionsPost(
      createRateRestrictionRequest?: CreateRateRestrictionRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRateRestrictionResponse> {
      const localVarAxiosArgs = RateRestrictionsApiAxiosParamCreator(
        configuration
      ).raterestrictionsPost(createRateRestrictionRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * RateRestrictionsApi - factory interface
 * @export
 */
export const RateRestrictionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} [propertyId]
     * @param {string} [roomTypeId]
     * @param {string} [roomRatePlanId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    raterestrictionsGet(
      propertyId?: string,
      roomTypeId?: string,
      roomRatePlanId?: string,
      options?: any
    ): AxiosPromise<RateRestrictionListResponse> {
      return RateRestrictionsApiFp(configuration).raterestrictionsGet(
        propertyId,
        roomTypeId,
        roomRatePlanId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    raterestrictionsIdDelete(id: string, options?: any): AxiosPromise<RateRestrictionModel> {
      return RateRestrictionsApiFp(configuration).raterestrictionsIdDelete(id, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    raterestrictionsIdGet(id: string, options?: any): AxiosPromise<RateRestrictionModel> {
      return RateRestrictionsApiFp(configuration).raterestrictionsIdGet(id, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {CreateRateRestrictionRequest} [createRateRestrictionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    raterestrictionsPost(
      createRateRestrictionRequest?: CreateRateRestrictionRequest,
      options?: any
    ): AxiosPromise<CreateRateRestrictionResponse> {
      return RateRestrictionsApiFp(configuration).raterestrictionsPost(
        createRateRestrictionRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * RateRestrictionsApi - object-oriented interface
 * @export
 * @class RateRestrictionsApi
 * @extends {BaseAPI}
 */
export class RateRestrictionsApi extends BaseAPI {
  /**
   *
   * @param {string} [propertyId]
   * @param {string} [roomTypeId]
   * @param {string} [roomRatePlanId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RateRestrictionsApi
   */
  public raterestrictionsGet(
    propertyId?: string,
    roomTypeId?: string,
    roomRatePlanId?: string,
    options?: any
  ) {
    return RateRestrictionsApiFp(this.configuration).raterestrictionsGet(
      propertyId,
      roomTypeId,
      roomRatePlanId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RateRestrictionsApi
   */
  public raterestrictionsIdDelete(id: string, options?: any) {
    return RateRestrictionsApiFp(this.configuration).raterestrictionsIdDelete(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RateRestrictionsApi
   */
  public raterestrictionsIdGet(id: string, options?: any) {
    return RateRestrictionsApiFp(this.configuration).raterestrictionsIdGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {CreateRateRestrictionRequest} [createRateRestrictionRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RateRestrictionsApi
   */
  public raterestrictionsPost(
    createRateRestrictionRequest?: CreateRateRestrictionRequest,
    options?: any
  ) {
    return RateRestrictionsApiFp(this.configuration).raterestrictionsPost(
      createRateRestrictionRequest,
      options
    )(this.axios, this.basePath);
  }
}
