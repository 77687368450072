import { QueryEntity } from '@datorama/akita';
import { switchMap } from 'rxjs/operators';

import { JournalEntryStore, JournalEntryState, journalEntryStore } from './journal-entries.store';
import { JournalEntry } from './journal-entries.model';

export class JournalEntryQuery extends QueryEntity<JournalEntryState> {
  constructor(protected store: JournalEntryStore) {
    super(store);
  }

  loading = this.selectLoading();

  accountTotals = this.select(({ ui }) => ui.accountTotals);

  accounts = this.select(({ ui }) => ui.accounts);

  queryLimitWarning = this.select(({ ui }) => ui.queryLimitWarning);

  filters = this.select(({ ui }) => ui.filters);

  journalEntries = this.filters.pipe(
    switchMap(({ accounts }) =>
      this.selectAll({
        sortBy: compareEntries,
        filterBy: [entry => accounts.length === 0 || accounts.includes(entry.account)],
      })
    )
  );
}

function compareEntries(a: JournalEntry, b: JournalEntry): number {
  return (
    a.timestamp.localeCompare(b.timestamp) ||
    a.reference.localeCompare(b.reference) ||
    Math.sign(b.amount) - Math.sign(a.amount) || // positive amounts first
    Math.abs(b.amount) - Math.abs(a.amount) // then largest amounts
  );
}

export const journalEntryQuery = new JournalEntryQuery(journalEntryStore);
