import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Reservations } from '../api';
import { Reservation, ReservationForms, ReservationStore, reservationStore } from '../reservation';
import { dispatchForm } from '../forms';
import { bulkAvailableService } from '../bulk-available-rates';
import bulkCancelPreviewStore, { BulkCancelPreviewStore } from './bulk-cancel-preview.store';

export class BulkCancelService {
  constructor(
    private readonly store: BulkCancelPreviewStore,
    private readonly reservationStore: ReservationStore,
    private readonly reservationApi: Reservations.ReservationApi
  ) {}

  bulkCancelPreview(
    propertyId: string,
    corporateAccountId: string,
    reservationIds: Array<Reservation['id']>
  ) {
    const request: Reservations.BulkCancelReservationRequest = {
      propertyId: propertyId,
      corporateAccountId: corporateAccountId,
      reservationIds: reservationIds,
    };
    from(this.reservationApi.reservationBulkCancelPreviewPost(request))
      .pipe(
        map(response => response.data.data),
        dispatchForm(ReservationForms.BulkCancelPreview)
      )
      .subscribe(res => {
        this.store.set(res);
      });
  }

  bulkCancelReservations(
    propertyId: string,
    corporateAccountId: string,
    reservationIds: Array<Reservation['id']>
  ) {
    const request: Reservations.BulkCancelReservationRequest = {
      propertyId,
      corporateAccountId,
      reservationIds,
    };
    from(this.reservationApi.reservationBulkCancelPost(request))
      .pipe(
        map(response => response.data.data),
        dispatchForm(ReservationForms.BulkCancel),
        dispatchForm(ReservationForms.CancelReservation)
      )
      .subscribe(data => {
        data.forEach(r => {
          if (r.reservationId) {
            this.reservationStore.update(r.reservationId, {
              status: Reservations.ReservationStatus.Canceled,
            });
          }
        });
        bulkAvailableService.resetUI();
      });
  }
}
export const bulkCancelService = new BulkCancelService(
  bulkCancelPreviewStore,
  reservationStore,
  new Reservations.ReservationApi()
);
