import { Properties } from '../api';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { GuestSurveyStore, guestSurveyStore } from './guest-survey.store';
import { StartGuestSurveyRequest } from '../api/generated/properties/models/start-guest-survey-request';
import { SubmitGuestSurveyRequest } from '../api/generated/properties/models/submit-guest-survey-request';
import { lightFormat } from 'date-fns';
import { dispatchForm } from '../forms';
import { GuestSurveyForms } from './guest-survey.forms';
import { GuestStaysStore, guestStaysStore } from '../guest-stays';
import { PropertyStore, propertyStore } from '../property';

export class GuestSurveyService {
  constructor(
    private readonly guestSurveyApi: Properties.GuestSurveyApi,
    private readonly store: GuestSurveyStore,
    private readonly guestStaysStore: GuestStaysStore,
    private readonly propertyStore: PropertyStore
  ) {}

  startGuestSurvey(propertyId: string, startGuestSurveyRequest: StartGuestSurveyRequest) {
    from(this.guestSurveyApi.propertyIdGuestsurveyStartPost(propertyId, startGuestSurveyRequest))
      .pipe(
        map(({ data }) => {
          return data.data;
        }),
        dispatchForm(GuestSurveyForms.StartGuestSurvey)
      )
      .subscribe(guestSurvey => {
        this.store.update({ guestSurvey });
      });
  }

  submitGuestSurvey(propertyId: string, submitGuestSurveyRequest: SubmitGuestSurveyRequest) {
    from(this.guestSurveyApi.propertyIdGuestsurveySubmitPost(propertyId, submitGuestSurveyRequest))
      .pipe(
        map(({ data }) => {
          return data.data;
        }),
        dispatchForm(GuestSurveyForms.SubmitGuestSurvey)
      )
      .subscribe(guestSurvey => {
        this.store.update({ guestSurvey });
      });
  }
  exportGuestSurveys(propertyId: string, start?: Date, end?: Date) {
    from(
      this.guestSurveyApi.propertyIdGuestsurveyExportGet(
        propertyId,
        !!start ? lightFormat(start, 'yyyy-MM-dd') : undefined,
        !!end ? lightFormat(end, 'yyyy-MM-dd') : undefined
      )
    )
      .pipe(map(x => new Blob([x.data], { type: 'text/csv' })))
      .subscribe(x =>
        saveAs(
          x,
          `GuestSurveys_${!!start ? lightFormat(start, 'yyyy-MM-dd') : undefined}-${
            !!end ? lightFormat(end, 'yyyy-MM-dd') : undefined
          }.csv`
        )
      );
  }
}
export const guestSurveyService = new GuestSurveyService(
  new Properties.GuestSurveyApi(),
  guestSurveyStore,
  guestStaysStore,
  propertyStore
);
