import { applyTransaction } from '@datorama/akita';
import {
  Properties,
  Reservations,
  dispatchForm,
  getE164FormattedPhoneNumber,
  PointOfSale,
  AccessControl,
  Audit,
} from '@lib/state';
import { combineLatest, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { PropertyConfigurationForms } from './property-configuration.forms';
import {
  UpdatePropertyConfigurationRequest,
  formatOutgoingPolicy,
  formatIncomingPolicy,
  UpadatePropertyNotificationRequests,
} from './property-configuration.model';
import {
  PropertyConfigurationStore,
  propertyConfigurationStore,
} from './property-configuration.store';

export class PropertyConfigurationService {
  constructor(
    private readonly propertyApi: Properties.PropertyApi,
    private readonly reservationPropertyApi: Reservations.PropertyApi,
    private readonly posApi: PointOfSale.PropertyConfigurationApi,
    private readonly accessControlApi: AccessControl.PropertyApi,
    private readonly auditApi: Audit.PropertyApi,
    private readonly store: PropertyConfigurationStore
  ) {}

  getProperty(propertyId: Properties.PropertyModel['id']) {
    combineLatest([
      this.propertyApi.propertyIdGet(propertyId),
      this.reservationPropertyApi.propertyIdConfigurationGet(propertyId),
      this.posApi.propertyconfigurationPropertyIdGet(propertyId),
      this.accessControlApi.propertyIdGet(propertyId),
      this.auditApi.propertyIdGet(propertyId),
    ])
      .pipe(
        map(
          ([
            propertyApiResponse,
            propertyReservationResponse,
            posResponse,
            accessControlApiResponse,
            auditApiResponse,
          ]) => [
            propertyApiResponse.data.data,
            formatIncomingPolicy(propertyReservationResponse.data.data),
            posResponse.data,
            accessControlApiResponse.data,
            auditApiResponse.data.data,
          ]
        )
      )
      .subscribe(([property, propertyConfiguration, pos, accessControl, audit]) => {
        applyTransaction(() => {
          this.store.upsert(propertyId, {
            ...property,
            ...propertyConfiguration,
            ...pos,
            ...accessControl,
            ...audit,
          });
          this.store.setActive(propertyId);
        });
      });
  }

  getPropertyConfiguration(propertyId: Properties.PropertyModel['id']) {
    from(this.reservationPropertyApi.propertyIdConfigurationGet(propertyId))
      .pipe(
        map(propertyReservationResponse =>
          formatIncomingPolicy(propertyReservationResponse.data.data)
        )
      )
      .subscribe(propertyConfiguration => {
        applyTransaction(() => {
          this.store.upsert(propertyId, {
            ...propertyConfiguration,
          });
          this.store.setActive(propertyId);
        });
      });
  }
  updateProperty(propertyId: string, request: UpdatePropertyConfigurationRequest) {
    request = formatOutgoingPolicy(request);

    combineLatest([
      this.propertyApi.propertyIdPatch(propertyId, {
        name: request.name,
        location: normalizeAddress(request.location),
        contact: normalizeContact(request.contact),
        groupBooking: normalizeContact(request.groupBooking),
      }),
      this.reservationPropertyApi.propertyIdConfigurationPatch(propertyId, {
        propertyCode: request.propertyCode,
        taxRates: request.taxRates,
        checkIn: request.checkIn,
        checkOut: request.checkOut,
        earlyCheckIn: request.earlyCheckIn,
        lateCheckOut: request.lateCheckOut,
        changeableThreshold: request.changeableThreshold,
        cancelableThreshold: request.cancelableThreshold,
        features: request.features,
        taxCreditPeriod: request.taxCreditPeriod,
      }),
      this.posApi.propertyconfigurationPropertyIdPut(propertyId, {
        salesTaxRates: request.salesTaxRates!,
      }),
    ])
      .pipe(dispatchForm(PropertyConfigurationForms.Update))
      .subscribe(() => {
        this.getProperty(propertyId);
      });
  }

  updatePropertyNotifications(propertyId: string, request: UpadatePropertyNotificationRequests) {
    request = formatOutgoingPolicy(request);

    combineLatest([
      this.propertyApi.propertyIdPatch(propertyId, {
        maintenanceEmail: request.maintenanceEmail,
        maintenancePhone: request.maintenancePhone,
        guestSurveyNotificationEmail: request.guestSurveyEmail,
        guestSurveyNotificationThreshold: request.guestSurveyThreshold
          ? request.guestSurveyThreshold
          : null,
        //Prevent JSON from converting to number.
        vipReservationNotificationPhone: `${request.vipReservationNotificationPhone}`,
      }),
      this.reservationPropertyApi.propertyIdConfigurationPatch(propertyId, {
        backOfficeEmail: etn(request.backOfficeEmail),
        taxCreditPeriod: request.taxCreditPeriod,
      }),
      this.posApi.propertyconfigurationPropertyIdPut(propertyId, {
        reportEmail: request.reportEmail!,
      }),
      this.accessControlApi.propertyIdPatch(propertyId, {
        deviceMonitoringEmail: request.monitoringEmail,
      }),
      this.auditApi.propertyIdPatch(propertyId, {
        refundAlertEmail: request.refundAlertEmail,
      }),
    ])
      .pipe(dispatchForm(PropertyConfigurationForms.Update))
      .subscribe(() => {
        this.getProperty(propertyId);
      });
  }
}

export const propertyConfigurationService = new PropertyConfigurationService(
  new Properties.PropertyApi(),
  new Reservations.PropertyApi(),
  new PointOfSale.PropertyConfigurationApi(),
  new AccessControl.PropertyApi(),
  new Audit.PropertyApi(),
  propertyConfigurationStore
);

// Empty-string to Null
function etn(str?: string | null) {
  if (str === '') return null;
  return str;
}

function normalizeContact(contact?: Properties.Contact): Properties.Contact | undefined {
  if (!contact) return undefined;

  const { email, phone } = contact;

  return {
    email: etn(email),
    phone: phone ? getE164FormattedPhoneNumber(phone) : etn(phone),
  };
}

function normalizeAddress(location?: Properties.Address): Properties.Address | undefined {
  if (!location) return undefined;

  const { line1, line2, city, state, zip } = location;

  return {
    line1: etn(line1),
    line2: etn(line2),
    city: etn(city),
    state: etn(state),
    zip: etn(zip),
  };
}
