// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { GetLedgerAccountsResponse } from '../models';
import { JournalEntryListResponse } from '../models';
/**
 * LedgerApi - axios parameter creator
 * @export
 */
export const LedgerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [reference]
     * @param {string} [invoice]
     * @param {string} [recordNumber]
     * @param {string} [recordNumberRange]
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ledgerExportGet(
      propertyId: string,
      startDate?: string,
      endDate?: string,
      reference?: string,
      invoice?: string,
      recordNumber?: string,
      recordNumberRange?: string,
      email?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling ledgerExportGet.'
        );
      }
      const localVarPath = `/ledger/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (reference !== undefined) {
        localVarQueryParameter['reference'] = reference;
      }

      if (invoice !== undefined) {
        localVarQueryParameter['invoice'] = invoice;
      }

      if (recordNumber !== undefined) {
        localVarQueryParameter['recordNumber'] = recordNumber;
      }

      if (recordNumberRange !== undefined) {
        localVarQueryParameter['recordNumberRange'] = recordNumberRange;
      }

      if (email !== undefined) {
        localVarQueryParameter['email'] = email;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [reference]
     * @param {string} [invoice]
     * @param {string} [recordNumber]
     * @param {string} [recordNumberRange]
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ledgerGet(
      propertyId: string,
      startDate?: string,
      endDate?: string,
      reference?: string,
      invoice?: string,
      recordNumber?: string,
      recordNumberRange?: string,
      email?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling ledgerGet.'
        );
      }
      const localVarPath = `/ledger`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (reference !== undefined) {
        localVarQueryParameter['reference'] = reference;
      }

      if (invoice !== undefined) {
        localVarQueryParameter['invoice'] = invoice;
      }

      if (recordNumber !== undefined) {
        localVarQueryParameter['recordNumber'] = recordNumber;
      }

      if (recordNumberRange !== undefined) {
        localVarQueryParameter['recordNumberRange'] = recordNumberRange;
      }

      if (email !== undefined) {
        localVarQueryParameter['email'] = email;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ledgerPropertyIdAccountsGet(propertyId: string, options: any = {}): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling ledgerPropertyIdAccountsGet.'
        );
      }
      const localVarPath = `/ledger/{propertyId}/accounts`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} file
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ledgerUploadPost(file: any, propertyId: string, options: any = {}): RequestArgs {
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          'file',
          'Required parameter file was null or undefined when calling ledgerUploadPost.'
        );
      }
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling ledgerUploadPost.'
        );
      }
      const localVarPath = `/ledger/upload`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (file !== undefined) {
        localVarFormParams.append('File', file as any);
      }

      if (propertyId !== undefined) {
        localVarFormParams.append('PropertyId', propertyId as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LedgerApi - functional programming interface
 * @export
 */
export const LedgerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [reference]
     * @param {string} [invoice]
     * @param {string} [recordNumber]
     * @param {string} [recordNumberRange]
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ledgerExportGet(
      propertyId: string,
      startDate?: string,
      endDate?: string,
      reference?: string,
      invoice?: string,
      recordNumber?: string,
      recordNumberRange?: string,
      email?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = LedgerApiAxiosParamCreator(configuration).ledgerExportGet(
        propertyId,
        startDate,
        endDate,
        reference,
        invoice,
        recordNumber,
        recordNumberRange,
        email,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [reference]
     * @param {string} [invoice]
     * @param {string} [recordNumber]
     * @param {string} [recordNumberRange]
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ledgerGet(
      propertyId: string,
      startDate?: string,
      endDate?: string,
      reference?: string,
      invoice?: string,
      recordNumber?: string,
      recordNumberRange?: string,
      email?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalEntryListResponse> {
      const localVarAxiosArgs = LedgerApiAxiosParamCreator(configuration).ledgerGet(
        propertyId,
        startDate,
        endDate,
        reference,
        invoice,
        recordNumber,
        recordNumberRange,
        email,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ledgerPropertyIdAccountsGet(
      propertyId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLedgerAccountsResponse> {
      const localVarAxiosArgs = LedgerApiAxiosParamCreator(
        configuration
      ).ledgerPropertyIdAccountsGet(propertyId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {any} file
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ledgerUploadPost(
      file: any,
      propertyId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LedgerApiAxiosParamCreator(configuration).ledgerUploadPost(
        file,
        propertyId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * LedgerApi - factory interface
 * @export
 */
export const LedgerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [reference]
     * @param {string} [invoice]
     * @param {string} [recordNumber]
     * @param {string} [recordNumberRange]
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ledgerExportGet(
      propertyId: string,
      startDate?: string,
      endDate?: string,
      reference?: string,
      invoice?: string,
      recordNumber?: string,
      recordNumberRange?: string,
      email?: string,
      options?: any
    ): AxiosPromise<any> {
      return LedgerApiFp(configuration).ledgerExportGet(
        propertyId,
        startDate,
        endDate,
        reference,
        invoice,
        recordNumber,
        recordNumberRange,
        email,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [reference]
     * @param {string} [invoice]
     * @param {string} [recordNumber]
     * @param {string} [recordNumberRange]
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ledgerGet(
      propertyId: string,
      startDate?: string,
      endDate?: string,
      reference?: string,
      invoice?: string,
      recordNumber?: string,
      recordNumberRange?: string,
      email?: string,
      options?: any
    ): AxiosPromise<JournalEntryListResponse> {
      return LedgerApiFp(configuration).ledgerGet(
        propertyId,
        startDate,
        endDate,
        reference,
        invoice,
        recordNumber,
        recordNumberRange,
        email,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ledgerPropertyIdAccountsGet(
      propertyId: string,
      options?: any
    ): AxiosPromise<GetLedgerAccountsResponse> {
      return LedgerApiFp(configuration).ledgerPropertyIdAccountsGet(propertyId, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {any} file
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ledgerUploadPost(file: any, propertyId: string, options?: any): AxiosPromise<void> {
      return LedgerApiFp(configuration).ledgerUploadPost(
        file,
        propertyId,
        options
      )(axios, basePath);
    },
  };
};

/**
 * LedgerApi - object-oriented interface
 * @export
 * @class LedgerApi
 * @extends {BaseAPI}
 */
export class LedgerApi extends BaseAPI {
  /**
   *
   * @param {string} propertyId
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {string} [reference]
   * @param {string} [invoice]
   * @param {string} [recordNumber]
   * @param {string} [recordNumberRange]
   * @param {string} [email]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LedgerApi
   */
  public ledgerExportGet(
    propertyId: string,
    startDate?: string,
    endDate?: string,
    reference?: string,
    invoice?: string,
    recordNumber?: string,
    recordNumberRange?: string,
    email?: string,
    options?: any
  ) {
    return LedgerApiFp(this.configuration).ledgerExportGet(
      propertyId,
      startDate,
      endDate,
      reference,
      invoice,
      recordNumber,
      recordNumberRange,
      email,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {string} [reference]
   * @param {string} [invoice]
   * @param {string} [recordNumber]
   * @param {string} [recordNumberRange]
   * @param {string} [email]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LedgerApi
   */
  public ledgerGet(
    propertyId: string,
    startDate?: string,
    endDate?: string,
    reference?: string,
    invoice?: string,
    recordNumber?: string,
    recordNumberRange?: string,
    email?: string,
    options?: any
  ) {
    return LedgerApiFp(this.configuration).ledgerGet(
      propertyId,
      startDate,
      endDate,
      reference,
      invoice,
      recordNumber,
      recordNumberRange,
      email,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LedgerApi
   */
  public ledgerPropertyIdAccountsGet(propertyId: string, options?: any) {
    return LedgerApiFp(this.configuration).ledgerPropertyIdAccountsGet(propertyId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {any} file
   * @param {string} propertyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LedgerApi
   */
  public ledgerUploadPost(file: any, propertyId: string, options?: any) {
    return LedgerApiFp(this.configuration).ledgerUploadPost(
      file,
      propertyId,
      options
    )(this.axios, this.basePath);
  }
}
