import React, { useState } from 'react';
import { Button, ButtonProps } from '@material-ui/core';

import { Reservation, ReservationStatus } from '@lib/state';

import { PurchaseLateCheckOutModal } from './purchase-late-check-out.modal';

interface Props extends Omit<ButtonProps, 'action' | 'onClick'> {
  reservation: Reservation;
}

export const LateCheckOutReservationButton: React.FC<Props> = ({ reservation, ...props }) => {
  const validState =
    reservation.status === ReservationStatus.CheckedIn &&
    !reservation.rooms.every(r => r.hasLateCheckOut);

  const allowed = validState;

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      {allowed && (
        <Button variant="contained" color="secondary" onClick={() => setOpen(true)} {...props}>
          Add Late Check Out
        </Button>
      )}
      {open && (
        <PurchaseLateCheckOutModal reservation={reservation} onClose={() => setOpen(false)} />
      )}
    </>
  );
};
