import React, { useMemo } from 'react';
import { MenuItem, Grid, Box } from '@material-ui/core';
import { FormStatus, RoomZone } from '@lib/state';
import { SubmitButton, ErrorDisplay, FormSelect, LoadingIndicator, Feedback } from '@lib/common';
import { CorporateZonePreference } from 'app/state';
import { useFormContext } from 'react-hook-form';

interface PreferenceSelection {
  index: number;
  zoneId?: string;
}

export interface ZonePreferencesFormFields {
  preferences: string[];
}

interface Props {
  zonePreferences: CorporateZonePreference[];
  zones: RoomZone[];
  status?: FormStatus;
  error?: Error;
}

export const CorporateZonePreferenceForm: React.FC<Props> = ({
  zonePreferences,
  zones,
  status,
  error,
}) => {
  const maxPreferences = useMemo(() => {
    return Array.from(new Array(zones.length > 10 ? 10 : zones.length), (x, i) => i).map(
      (m, i) =>
        ({
          index: i,
          zoneId: zonePreferences.find(f => f.sequence === i)?.roomZoneId,
        } as PreferenceSelection)
    );
  }, [zonePreferences, zones]);

  const { watch } = useFormContext<ZonePreferencesFormFields>();
  const formValues = watch({ nest: true });

  const validate = () => {
    const nonPreferences = formValues.preferences?.filter(f => !!f) ?? [];
    if (new Set(nonPreferences).size !== nonPreferences.length)
      return 'Cannot have duplicate Room Zones selected.';
  };
  const validationResult = validate();

  if (status === FormStatus.Pending) return <LoadingIndicator loadingText="Processing..." />;

  return (
    <Grid container spacing={2}>
      {maxPreferences.map(x => (
        <Grid key={x.index} item xs={12}>
          <FormSelect
            name={`preferences[${x.index}]`}
            label="Room Zones"
            fullWidth
            defaultValue={x.zoneId}
            data-testid="zonePreferenceSelect"
          >
            <MenuItem value="">Select Room Zone</MenuItem>
            {zones.map(({ name, id }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Feedback show={!!validationResult} severity="error">
          {validationResult}
        </Feedback>
        <Feedback
          show={status === FormStatus.Success}
          severity="success"
          data-testid="zonePreferenceSuccessDisplay"
        >
          Zone Preferences have been updated successfully.
        </Feedback>
        <ErrorDisplay error={error} />
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
          <SubmitButton
            style={{ display: 'block' }}
            variant="contained"
            color="secondary"
            disabled={!!validationResult}
            data-testid="zonePreferencesSubmitButton"
          >
            Save Zone Preferences
          </SubmitButton>
        </Box>
      </Grid>
    </Grid>
  );
};
