import React, { useCallback } from 'react';
import { ButtonProps } from '@material-ui/core';
import { ConfirmedButton } from '../components';
import { useForm } from 'react-hook-form';
import { useFormEvents } from '../forms';
import {
  Reservation,
  ReservationActivity,
  ReservationForms,
  reservationQuery,
  reservationService,
  ReservationStatus,
} from '@lib/state';
import { RefundableConfirmationForm, useObservable } from '..';
import { ReservationFeeList } from './reservation-fee-list';

interface Props extends Omit<ButtonProps, 'action' | 'onClick'> {
  reservation: Reservation;
  overrideable?: boolean;
  onSuccess?: () => void;
}

export const CheckOutReservationButton: React.FC<Props> = ({
  reservation,
  overrideable = false,
  onSuccess,
  ...props
}) => {
  const [{ status, error }, resetCheckOutReservationForm] = useFormEvents(
    ReservationForms.CheckOutReservation
  );

  const form = useForm();
  const activity = useObservable(reservationQuery.lastActivity);
  const overrideCode = form.watch('overrideCode');

  const resultView = <ReservationFeeList {...activity?.cost} />;

  const allowed = reservation.status === ReservationStatus.CheckedIn;

  const onOpen = useCallback(() => {
    resetCheckOutReservationForm();
  }, [resetCheckOutReservationForm]);

  return (
    <ConfirmedButton
      name="checkOutReservation"
      action={`Check Out Reservation ${reservation.recordNumber}`}
      onConfirm={() => reservationService.checkOutReservation(reservation, overrideCode)}
      onOpen={onOpen}
      buttonText="Check Out"
      status={status}
      error={error}
      hidden={!allowed}
      confirmView={
        <RefundableConfirmationForm
          reservation={reservation}
          actionType={ReservationActivity.CheckOut}
          overrideable={overrideable}
          form={form}
        />
      }
      resultView={resultView}
      onSuccess={onSuccess}
      {...props}
    />
  );
};
