import axios, { AxiosInstance, AxiosError } from 'axios';

import { AppConfig } from '../../app.config';
import { withAuthentication } from './withAuthentication';

export interface JwtToken {
  access_token: string;
  refresh_token?: string;
  expires_in: number;
  token_type: string;
  scope: string;
}

export interface UserInfo {
  sub: string;
  role: string[];
  preferred_username: string;
  name: string;
  email: string;
  phone_number: string;
  email_verified: boolean;
  phone_number_verified: boolean;
  [key: string]: string | string[] | number | boolean;
}

export class AuthApi {
  constructor(private readonly client: AxiosInstance = withAuthentication(axios.create())) {
    this.client.defaults.baseURL = AppConfig.Api.BaseUrl;
  }

  async login(username: string, password: string) {
    const params = new URLSearchParams({
      client_id: AppConfig.Api.Auth.ClientId,
      grant_type: AppConfig.Api.Auth.GrantType,
      client_secret: AppConfig.Api.Auth.ClientSecret,
      scope: AppConfig.Api.Auth.Scope,
      username,
      password,
    });

    return await this.attemptLogin(params, 'Invalid username and / or password.');
  }

  async loginWithUserKey(userKey: string) {
    const params = new URLSearchParams({
      client_id: AppConfig.Api.Auth.ClientId,
      grant_type: AppConfig.Api.Auth.UserKeyGrantType,
      client_secret: AppConfig.Api.Auth.ClientSecret,
      userKey,
    });

    return await this.attemptLogin(params, 'Invalid user key.');
  }

  private async attemptLogin(params: URLSearchParams, errorMessage: string) {
    return await this.client.post<JwtToken>('/connect/token', params).catch((e: AxiosError) => {
      const error = e.response?.data;

      if (error && isIdentityServerError(error) && error.error == 'invalid_grant') {
        return Promise.reject(new Error(errorMessage));
      }

      return Promise.reject(e);
    });
  }

  async refreshToken(refresh_token: string) {
    const params = new URLSearchParams({
      client_id: AppConfig.Api.Auth.ClientId,
      client_secret: AppConfig.Api.Auth.ClientSecret,
      grant_type: AppConfig.Api.Auth.RefreshGrantType,
      scope: AppConfig.Api.Auth.Scope,
      refresh_token,
    });

    return await this.client.post<JwtToken>('/connect/token', params);
  }

  async userInfo(accessToken: string) {
    return await this.client.get<UserInfo>('/connect/userinfo', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      // never attempt a token refresh for userinfo
      // .. it is always performed immediately after a refresh
      tokenRefreshed: true,
    });
  }

  async revokeToken(refresh_token: string) {
    const params = new URLSearchParams({
      client_id: AppConfig.Api.Auth.ClientId,
      token: refresh_token,
      client_secret: AppConfig.Api.Auth.ClientSecret,
    });

    return await this.client.post('/connect/revocation', params);
  }
}

interface IdentityServerError {
  error: string;
  error_description: string;
}

function isIdentityServerError(obj: any): obj is IdentityServerError {
  const err = obj as IdentityServerError;
  return err.error !== undefined && err.error_description !== undefined;
}
