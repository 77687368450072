import { AriaAttributes } from 'react';

export function aria(
  valid: boolean,
  touched: boolean,
  ...errors: string[]
): Partial<AriaAttributes> | null {
  if (valid || !touched) return null;

  return {
    'aria-invalid': true,
    'aria-errormessage': errors[0],
  };
}

export function asArray<T>(value?: T | T[]): T[] {
  if (value === undefined) return [];
  if (Array.isArray(value)) return value;
  return [value];
}

const validEmailCharacters = `abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._@+/ `;
export function invalidCharacter(email: string): boolean {
  for (let i = 0; i < email.length; i++) {
    if (!validEmailCharacters.includes(email[i])) {
      return true;
    }
  }
  return false;
}
