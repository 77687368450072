import { Store, EntityStore, EntityState, getEntityType, getIDType } from '@datorama/akita';
import { Subject } from 'rxjs';

export interface IResettable {
  readonly storeCancel: Subject<void>;
  cancel(): void;
}

export abstract class ResettableStore<State = any> extends Store<State> implements IResettable {
  readonly storeCancel = new Subject<void>();

  cancel(): void {
    this.storeCancel.next();
  }

  reset(): void {
    this.storeCancel.next();
    super.reset();
  }
}

export abstract class ResettableEntityStore<
  TState extends EntityState<any, any> = any,
  TEntity = getEntityType<TState>,
  TId = getIDType<TState>
> extends EntityStore<TState, TEntity, TId> implements IResettable {
  readonly storeCancel = new Subject<void>();

  cancel(): void {
    this.storeCancel.next();
  }

  reset(): void {
    this.storeCancel.next();
    super.reset();
  }
}
