import { useEffect } from 'react';

import { Property, propertyQuery, propertyService } from '@lib/state';
import { useObservable } from './useObservable';

export function usePropertyAmenities(propertyId?: Property['id'] | null) {
  useEffect(() => {
    if (propertyId) {
      propertyService.loadPropertyAmenities(propertyId);
    }
  }, [propertyId]);

  return useObservable(propertyQuery.amenities);
}
