import React from 'react';
import { Redirect } from 'react-router-dom';

interface Props {
  isLoggedIn: boolean | undefined;
  search: string;
}

export const SignedInRedirect: React.FC<Props> = ({ isLoggedIn, search }) => {
  if (!isLoggedIn) return null;

  const queryParams = new URLSearchParams(search);
  const returnUrl = queryParams.get('returnUrl') ?? '/tape-chart';

  return <Redirect to={returnUrl} />;
};
