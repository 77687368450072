import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { Overrideable, Reservation, ReservationActivity, reservationService } from '@lib/state';
import { FormContext, FormContextValues } from 'react-hook-form';
import { ManagerOverrideInput, RefundableDaysTextDisplay } from '..';

interface Props {
  reservation: Reservation;
  actionType: ReservationActivity;
  overrideable?: boolean; //determines if override input can be displayed
  overrideRequired?: boolean;
  form: FormContextValues;
}

export const RefundableConfirmationForm: React.FC<Props> = ({
  reservation,
  actionType,
  overrideable = false,
  overrideRequired = false,
  form,
}) => {
  const reservationId = reservation.id;
  const action = getAction(actionType);
  const refundableStayDays = reservation.refundableStayDays;
  const remainingStayDays = reservation.remainingStayDays;
  const hasPendingCharges = reservation.hasPendingCharges;
  const canRefundWithOverride = reservation.canRefundWithOverride;

  useEffect(() => {
    reservationService.GetReservationChangeOptions(reservation, actionType);
  }, [reservationId]);

  if (
    remainingStayDays === undefined ||
    refundableStayDays === undefined ||
    hasPendingCharges === undefined ||
    canRefundWithOverride === undefined
  )
    return null;

  const hasRemainingStayDays = remainingStayDays > 0;
  let override: Overrideable = 'unavailable';
  if (overrideRequired) {
    override = 'required';
  } else if (refundableStayDays < remainingStayDays && overrideable) {
    override = 'optional';
  }

  return (
    <FormContext {...form}>
      {hasRemainingStayDays && (
        <RefundableDaysTextDisplay
          pb={1}
          override={override}
          remainingStayDays={remainingStayDays}
          refundableStayDays={refundableStayDays}
          hasPendingCharges={hasPendingCharges}
          canRefundWithOverride={canRefundWithOverride}
        />
      )}
      <Typography data-testid={`confirmationActionDisplay:${reservation.recordNumber}`}>
        Are you sure you want to {action} reservation {reservation.recordNumber}?
      </Typography>
      {override !== 'unavailable' && <ManagerOverrideInput required={overrideRequired} />}
    </FormContext>
  );
};

function getAction(actionType: ReservationActivity) {
  switch (actionType) {
    case ReservationActivity.CancelReservation:
      return 'cancel';
    case ReservationActivity.CheckOut:
      return 'check out';
    default:
      return 'cancel';
  }
}
