import {
  getRoomAvailabilityPredicate,
  HousekeepingStatusEnum,
  Room,
  RoomsFilters,
} from '@lib/state';
import { filterRoom, GuestStay, GuestStaysFilters } from 'app/state';
import { eachDayOfInterval, isAfter, parseISO } from 'date-fns';
import { useMemo } from 'react';

export interface RoomSelectionFilter {
  reservationId: string;
  checkInDate: Date;
  checkOutDate: Date;
  corporateAccountId?: string | null;
}

export interface TapeChartRow {
  room: Room;
  sequence: number;
  slot?: string;
  hasEmptySlot: boolean;
  guestStay?: GuestStay;
  occupants: GuestStay[];
}

export function useGuestStayLookup(guestStays: GuestStay[]) {
  return useMemo(() => {
    const map = new Map<Room['roomNumber'], GuestStay[]>();

    guestStays
      .filter(x => isAfter(parseISO(x.roomAssignmentEnd), new Date()))
      .forEach(x => {
        const arr = map.get(x.roomNumber) ?? [];
        arr.push(x);
        map.set(x.roomNumber, arr);
      });

    return map;
  }, [guestStays]);
}

export function getTapeChartFilteredRows(
  rooms: Array<Room>,
  guestStaysLookup: Map<string, GuestStay[]>,
  roomsFilters: RoomsFilters,
  guestStaysFilters: GuestStaysFilters,
  enableRoomSelection: boolean,
  selectedGuest?: RoomSelectionFilter
): Array<TapeChartRow> {
  const slot = 'A'.charCodeAt(0);

  const rows: TapeChartRow[] = rooms.flatMap(room => {
    const occupancy = room.occupancy ?? 1;

    // work on a copy of the lookup array since we're going to mutate it
    const occupants = guestStaysLookup.get(room.roomNumber) ?? [];
    const slots = [...occupants];

    if (slots.length > 1) {
      // sort the guest stays so the oldest assigned room appears in the first slot
      slots.sort((a, b) => a.roomAssignmentStart.localeCompare(b.roomAssignmentStart));
    }

    let hasEmptySlot = false;
    if (slots.length < occupancy) {
      // force there to be room x occupancy rows
      hasEmptySlot = true; // we can use this room for room changes
      slots.push(
        ...Array.from<GuestStay>({ length: occupancy - slots.length })
      );
    }

    return slots.map((guestStay, i) => ({
      room,
      sequence: 0,
      guestStay,
      slot: occupancy > 1 ? String.fromCharCode(slot + i) : undefined,
      hasEmptySlot,
      occupants,
    }));
  });
    const defaultRows = rows.filter(({ room, guestStay, hasEmptySlot, occupants }) =>
        filterRoom(
            {
                ...roomsFilters,
                ...guestStaysFilters,
            },
            room,
            occupants,
            guestStay,
            hasEmptySlot
        )
    );
    // return a default room list, unless we've switched modes
    if (enableRoomSelection && selectedGuest) {
        return defaultRows
            .filter(
                ({ room, occupants, hasEmptySlot }) =>
                    hasEmptySlot && isAvailableForRoomChange(room, occupants, selectedGuest)
            )
            .map(roomZebraStripes());
    }
    return defaultRows.map(roomZebraStripes());
}

function isAvailableForRoomChange(
  room: Room,
  occupants: GuestStay[],
  selectedGuest: RoomSelectionFilter
): boolean {
  return (
    room.housekeepingStatus === HousekeepingStatusEnum.NoCleaningRequired &&
    room.readyForRent === true &&
    checkRoomAvailability(room, selectedGuest.checkInDate, selectedGuest.checkOutDate) &&
    // hide the current room, as well as any rooms for the same reservation (see Bug 40704: Doubles: Multiple room reservation cannot occupy the same room)
    occupants.every(o => o.reservationId !== selectedGuest.reservationId) &&
    // hide the room if it is occupied by a different corporate account id
    occupants.every(o => o.affiliation?.corporateAccountId === selectedGuest.corporateAccountId) &&
    occupants.every(o => !o.isRoomBlocked)
  );
}

function checkRoomAvailability(room: Room, checkInDate: Date, checkOutDate: Date): boolean {
  const isRoomAvailable = getRoomAvailabilityPredicate(room);
  return eachDayOfInterval({
    start: checkInDate,
    end: checkOutDate,
  }).every(s => isRoomAvailable(s));
}

function roomZebraStripes() {
  const roomNumbers = new Set<string>();
  return (row: TapeChartRow) => {
    const {
      room: { roomNumber },
    } = row;
    roomNumbers.add(roomNumber);
    return { ...row, sequence: roomNumbers.size };
  };
}
