import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from '@material-ui/core';

import { sessionQuery } from '@lib/state';
import { FormHub, Section, useFormEvents, useObservable, usePageTitle } from '@lib/common';
import { AreaForms, areaQuery, areaService } from 'app/state';
import { AuditLogs, BreadcrumbBackButton } from 'app/shared';
import { AreaForm, AreaFormFields } from '../components';

export const EditAreaPage: React.FC<RouteComponentProps<{ areaId: string }>> = ({ match }) => {
  usePageTitle('Edit Common Area');

  const areaId = match.params.areaId;

  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const area = useObservable(areaQuery.activeArea, 'async');

  useEffect(() => {
    if (propertyId) {
      areaService.getAreaById(propertyId, areaId);
    }

    return () => areaService.select();
  }, [propertyId, areaId]);

  const [{ status, error }, reset] = useFormEvents(AreaForms.EditArea);

  const submit = useCallback(
    ({ name, roomIds }: AreaFormFields) => {
      if (propertyId) areaService.updateArea(propertyId, areaId, name, roomIds!);
    },
    [propertyId, areaId]
  );

  const remove = useCallback(() => {
    if (propertyId) areaService.deleteArea(propertyId, areaId);
  }, [propertyId, areaId]);

  const defaultValues = useMemo(
    () => ({ name: area?.name ?? '', roomIds: area?.rooms?.map(x => x.id) ?? [] }),
    [area]
  );

  return (
    <Container maxWidth="lg">
      <BreadcrumbBackButton destination="/areas" />
      <FormHub onSubmit={submit} formOptions={{ defaultValues }}>
        <AreaForm area={area} onRemove={remove} status={status} error={error} resetFormUI={reset} />
      </FormHub>
      <Section title="Audit Logs" disableGutters>
        <AuditLogs scope="area" id={areaId} />
      </Section>
    </Container>
  );
};
