import React from 'react';
import { Typography, Grid } from '@material-ui/core';

import { RoomModel } from '@lib/state/api/generated/properties';
import { CorporateAffiliationDisplay, DateDisplay } from '@lib/common';
import { GuestStay } from 'app/state';

interface Props {
  stay: GuestStay;
  room: RoomModel;
}

export const GuestRoomDates: React.FC<Props> = ({ stay, room }) => {
  return (
    <Grid container spacing={3}>
      <Grid item>
        <Typography variant="subtitle2" color="textPrimary">
          Conf Code
        </Typography>
        <Typography>{stay.recordNumber}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" color="textPrimary">
          Check-In
        </Typography>
        <DateDisplay date={stay.checkInDate} dateFormat="MMMM d" />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" color="textPrimary">
          Check-Out
        </Typography>
        <DateDisplay date={stay.checkOutDate} dateFormat="MMMM d" />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" color="textPrimary">
          Room Type
        </Typography>
        <Typography>{room.roomType.name}</Typography>
      </Grid>
      {!!stay.affiliation && (
        <Grid item>
          <Typography color="textPrimary" variant="subtitle2">
            Company
          </Typography>
          <CorporateAffiliationDisplay {...stay.affiliation} color="textPrimary" />
        </Grid>
      )}
    </Grid>
  );
};
