import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Optional } from 'utility-types';

import { ConfirmedButton, ConfirmedButtonProps } from '../confirmed-button';
import { AppColors } from '../../theme';

const useStyles = makeStyles(
  createStyles({
    button: {
      color: AppColors.Red,
    },
  })
);

type Props = Optional<ConfirmedButtonProps, 'action'>;

export const RemoveButton: React.FC<Props> = ({ className, ...props }) => {
  const styles = useStyles();

  return (
    <ConfirmedButton
      action="Remove"
      className={clsx(styles.button, className)}
      variant="outlined"
      {...props}
    />
  );
};
