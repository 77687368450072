import React, { useCallback, useState } from 'react';

import { ButtonProps, Typography, TextareaAutosize } from '@material-ui/core';
import { FormContext, useForm } from 'react-hook-form';

import { Reservation, ReservationForms, reservationService } from '@lib/state';
import { ReservationRoomRateModel } from '@lib/state/api/generated/reservations';
import { ConfirmedButton, useFormEvents, CurrencyDisplay } from '@lib/common';

interface Props extends Omit<ButtonProps, 'action' | 'onClick'> {
  roomRate: ReservationRoomRateModel;
  reservationId: Reservation['id'];
}

export const RefundStayDateButton: React.FC<Props> = ({ roomRate, reservationId }) => {
  const [{ status, error }, resetRefundStayDateForm] = useFormEvents(
    ReservationForms.RefundStayDate
  );

  const form = useForm();

  const [notes, setNotes] = useState(' ');

  const confirmView = (
    <FormContext {...form}>
      <Typography>
        Are you sure you would like to refund the reservation for {roomRate.date}?
      </Typography>
      <TextareaAutosize
        style={{ width: '100%' }}
        minRows={2}
        maxRows={5}
        placeholder="Notes"
        onChange={e => {
          setNotes(e.target.value);
        }}
      />
    </FormContext>
  );

  const onOpen = useCallback(() => {
    resetRefundStayDateForm();
  }, [resetRefundStayDateForm]);

  const resultView = (
    <div>
      <Typography>
        You have successfully refunded <CurrencyDisplay value={roomRate.total} display="inline" />{' '}
        from your total balance.
      </Typography>
    </div>
  );

  if (!roomRate.refundAvailable && !roomRate.refunded) return null;

  return (
    <ConfirmedButton
      name="refundStayDate"
      size="small"
      color="secondary"
      action={`Refund Stay Date ${roomRate.date}`}
      onConfirm={() => reservationService.refundStayDate(reservationId, roomRate, notes)}
      onOpen={onOpen}
      buttonText={roomRate.refunded ? 'Refunded' : 'Refund'}
      status={status}
      error={error}
      hidden={false}
      disabled={roomRate.refunded}
      confirmView={confirmView}
      resultView={resultView}
    ></ConfirmedButton>
  );
};
