import React, { useState, useMemo } from 'react';
import {
  Button,
  makeStyles,
  createStyles,
  styled,
  Menu,
  MenuItem,
  Divider,
  Switch,
  FormGroup,
  Typography,
  Grid,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { subDays, addDays } from 'date-fns';
import { AppColors } from '@lib/common';
import { RoomCount } from 'app/state';
import { Room } from '@lib/state';
import { RoomCountType } from '@lib/state/api/generated/properties';
import { TimelineProps, TimelineView, useStayDates, useTimeline } from './useTimeline';
import { useTapeChartFilterCount } from './useTapeChartFilterCount';
import { Header } from './header';
import { TimelineColumn } from './timeline-column';

const HeaderButton = styled(Button)({
  height: '100%',
  minWidth: 'unset',
  padding: '0 0.75rem',

  backgroundColor: AppColors.DarkBlue,
  borderRight: `1px solid ${AppColors.HeaderDivider}`,
});

const DateNavButton = styled(Button)({
  width: '1.5rem',
  fontSize: '1rem',
  minWidth: 'unset',
  padding: 0,
});

const FlexContainer = styled('div')({
  display: 'flex',
});

const Row = styled(FlexContainer)({
  '&:nth-child(even)': {
    backgroundColor: AppColors.LightGray,
  },
});

const useStyles = makeStyles(theme =>
  createStyles({
    label: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      fontSize: '1em',
      fontWeight: 700,
      padding: '0.5rem 0.5rem 0.5rem 0',
    },
    headerButton: {
      fontSize: '1em !important',
    },
    icon: {
      fontSize: '1.25em',
      marginRight: '8px',
    },
    date: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0.5rem 0',
      borderLeft: `1px solid ${AppColors.RowDivider}`,
    },
    grow: { flex: 1 },
    dateRow: {
      [theme.breakpoints.up('xs')]: {
        flex: 1,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        width: '60px',
      },
    },
    menuList: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    rightHeaderItems: {
      display: 'flex',
      borderLeft: `1px solid ${AppColors.HeaderDivider}`,
      paddingLeft: '0.5rem',
    },
    disabledHeaderItem: {
      color: `${AppColors.DisabledHeaderItem} !important`,
    },
    viewButton: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  })
);

interface RoomCountsProps extends TimelineProps {
  roomCounts: Array<RoomCount>;
  rooms: Array<Room>;
  countBy: RoomCountType;
  onChangeDate: (date: Date) => void;
  onChangeView: (view: TimelineView) => void;
  openTapeChartFiltersModal: () => void;
  setCountBy: (countBy: RoomCountType) => void;
}

export const RoomCounts: React.FC<RoomCountsProps> = ({
  roomCounts,
  startDate,
  view,
  countBy,
  onChangeDate,
  onChangeView,
  openTapeChartFiltersModal,
  setCountBy,
}) => {
  const {
    label,
    headerButton,
    icon,
    date,
    grow,
    dateRow,
    viewButton,
    menuList,
    rightHeaderItems,
    disabledHeaderItem,
  } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const checked = countBy === RoomCountType.Rooms;

  const dates = useTimeline(startDate, view);
  const { isToday, isFuture } = useStayDates();

  const activeFilterCount = useTapeChartFilterCount();
  const activeFilterCountLabel = useMemo(
    () => (activeFilterCount > 0 ? ` (${activeFilterCount})` : ''),
    [activeFilterCount]
  );

  const roomCountLookup = useMemo(
    () => new Map<number, RoomCount>(roomCounts.map(x => [x.date.valueOf(), x])),
    [roomCounts]
  );

  const todayRoomCount = useMemo(() => dates.find(x => x.getDate() === startDate.getDate()), [
    dates,
    startDate,
  ]);

  const todayCleanRooms = roomCountLookup.get(todayRoomCount!.valueOf());

  const roomCountRows = [
    { title: 'In Service', count: (count: RoomCount) => count.totalRooms },
    {
      title: 'Occupied',
      count: (count: RoomCount) =>
        isToday(count.date) ? count.today.occupiedRooms : count.occupiedRooms,
    },
    {
      title: `(Clean:  ${todayCleanRooms?.readyRooms ?? 0})  Checking In`,
      count: (count: RoomCount) =>
        isToday(count.date) ? count.today.checkInCount : count.checkInCount,
    },
    { title: 'Checking Out', count: (count: RoomCount) => count.checkOutCount },
    { title: 'Inventory Adjustments', count: (count: RoomCount) => count.adjustments },
    { title: 'Available', count: (count: RoomCount) => count.availableRooms },
  ];
  return (
    <>
      <Header startDate={startDate} view={view}>
        <div className={grow}>
          <HeaderButton
            className={viewButton}
            color="secondary"
            onClick={e => setAnchorEl(e.currentTarget)}
            disableElevation
          >
            <FontAwesomeIcon icon={['far', 'calendar-alt']} className={icon} fixedWidth /> View
          </HeaderButton>
          <Menu
            id="view-options"
            className={viewButton}
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorPosition={{ top: 64, left: 0 }}
            anchorReference="anchorPosition"
            variant="selectedMenu"
            marginThreshold={0}
            MenuListProps={{
              dense: true,
              disablePadding: true,
              classes: { root: menuList },
            }}
          >
            <MenuItem
              disabled={view === TimelineView.TwoWeek}
              selected={view === TimelineView.TwoWeek}
              onClick={() => {
                onChangeView(TimelineView.TwoWeek);
                setAnchorEl(null);
              }}
            >
              2-Week View
            </MenuItem>
            <Divider />
            <MenuItem
              disabled={view === TimelineView.ThirtyDay}
              selected={view === TimelineView.ThirtyDay}
              onClick={() => {
                onChangeView(TimelineView.ThirtyDay);
                setAnchorEl(null);
              }}
            >
              30-Day View
            </MenuItem>
          </Menu>
          <HeaderButton
            className={headerButton}
            color="secondary"
            onClick={openTapeChartFiltersModal}
            disableElevation
            data-testid="tapeChartFilterButton"
          >
            <FontAwesomeIcon icon={['far', 'filter']} className={icon} fixedWidth /> Filters{' '}
            {activeFilterCountLabel}
          </HeaderButton>

          <HeaderButton
            className={headerButton}
            color="secondary"
            onClick={() => {
              setCountBy(countBy === RoomCountType.Bed ? RoomCountType.Rooms : RoomCountType.Bed);
            }}
            disableElevation
          >
            <FormGroup>
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>Beds</Grid>
                  <Grid item>
                    <Switch checked={checked} /*onChange={handleChange}*/ />
                  </Grid>
                  <Grid item>Rooms</Grid>
                </Grid>
              </Typography>
            </FormGroup>
          </HeaderButton>
        </div>
        <div className={rightHeaderItems}>
          <DateNavButton
            color="secondary"
            className={!isFuture(startDate) ? disabledHeaderItem : undefined}
            onClick={() => onChangeDate(subDays(startDate, 1))}
            disableElevation
            disabled={!isFuture(startDate)}
          >
            <FontAwesomeIcon icon="chevron-left" className={icon} fixedWidth />
          </DateNavButton>
          <DateNavButton
            color="secondary"
            onClick={() => onChangeDate(addDays(startDate, 1))}
            disableElevation
          >
            <FontAwesomeIcon icon="chevron-right" className={icon} fixedWidth />
          </DateNavButton>
        </div>
      </Header>
      <FlexContainer>
        <FlexContainer className={grow}>
          <div className={grow}>
            {roomCountRows.map(({ title }, i) => (
              <Row className={label} key={i}>
                {title}
              </Row>
            ))}
          </div>
        </FlexContainer>
        <TimelineColumn>
          <div className={dateRow}>
            {roomCountRows.map(({ count }, i) => (
              <Row key={i}>
                {dates.map(d => {
                  const dval = d.valueOf();
                  const roomCount = roomCountLookup.get(dval);
                  return (
                    <div key={dval} className={date}>
                      {!!roomCount ? count(roomCount) : null}
                    </div>
                  );
                })}
              </Row>
            ))}
          </div>
        </TimelineColumn>
      </FlexContainer>
    </>
  );
};
