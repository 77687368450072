import React from 'react';
import { kioskStyles, ReservationRouteProps, VerifyReservationCodeComponent } from '@lib/common';

export const VerifyReservationCodePage: React.FC<ReservationRouteProps> = ({
  match,
  history,
  location,
}) => {
  const { kioskForm } = kioskStyles();
  const reservationId = match.params['reservationId'];
  const failed = new URLSearchParams(location.search).has('failed');

  return (
    <VerifyReservationCodeComponent
      kioskForm={kioskForm}
      reservationId={reservationId}
      failed={failed}
      onVerifyCode={code => history.push(`/kiosk/${reservationId}/account?code=${code}`)}
    />
  );
};
