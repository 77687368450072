import React, { Fragment, memo } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

interface Props extends TypographyProps {
  addressVariant?: 'city' | 'state' | 'cityState' | 'full';

  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
}

export const AddressDisplay: React.FC<Props> = memo(
  ({ addressVariant = 'full', line1, line2, city, state, zip, ...props }) => (
    <Typography {...props}>
      {addressVariant === 'full' && line1 && (
        <>
          {line1}
          <br />
        </>
      )}
      {addressVariant === 'full' && line2 && (
        <>
          {line2}
          <br />
        </>
      )}
      {(city || state || zip) && (
        <Fragment>
          {addressVariant !== 'state' && city}
          {(addressVariant === 'cityState' || addressVariant === 'full') && city && state && ', '}
          {addressVariant !== 'city' && state}
          {addressVariant === 'full' && ` ${zip}`}
        </Fragment>
      )}
    </Typography>
  )
);
