// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { GetPropertyConfigurationFormItemResponse } from '../models';
import { GetPropertyConfigurationItemResponse } from '../models';
import { UpdatePropertyRequest } from '../models';
/**
 * PropertyApi - axios parameter creator
 * @export
 */
export const PropertyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdConfigurationGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling propertyIdConfigurationGet.'
        );
      }
      const localVarPath = `/property/{id}/configuration`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdatePropertyRequest} [updatePropertyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdConfigurationPatch(
      id: string,
      updatePropertyRequest?: UpdatePropertyRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling propertyIdConfigurationPatch.'
        );
      }
      const localVarPath = `/property/{id}/configuration`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updatePropertyRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updatePropertyRequest !== undefined ? updatePropertyRequest : {})
        : updatePropertyRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling propertyIdGet.'
        );
      }
      const localVarPath = `/property/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PropertyApi - functional programming interface
 * @export
 */
export const PropertyApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdConfigurationGet(
      id: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetPropertyConfigurationFormItemResponse> {
      const localVarAxiosArgs = PropertyApiAxiosParamCreator(
        configuration
      ).propertyIdConfigurationGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdatePropertyRequest} [updatePropertyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdConfigurationPatch(
      id: string,
      updatePropertyRequest?: UpdatePropertyRequest,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetPropertyConfigurationFormItemResponse> {
      const localVarAxiosArgs = PropertyApiAxiosParamCreator(
        configuration
      ).propertyIdConfigurationPatch(id, updatePropertyRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGet(
      id: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetPropertyConfigurationItemResponse> {
      const localVarAxiosArgs = PropertyApiAxiosParamCreator(configuration).propertyIdGet(
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PropertyApi - factory interface
 * @export
 */
export const PropertyApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdConfigurationGet(
      id: string,
      options?: any
    ): AxiosPromise<GetPropertyConfigurationFormItemResponse> {
      return PropertyApiFp(configuration).propertyIdConfigurationGet(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {UpdatePropertyRequest} [updatePropertyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdConfigurationPatch(
      id: string,
      updatePropertyRequest?: UpdatePropertyRequest,
      options?: any
    ): AxiosPromise<GetPropertyConfigurationFormItemResponse> {
      return PropertyApiFp(configuration).propertyIdConfigurationPatch(
        id,
        updatePropertyRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGet(id: string, options?: any): AxiosPromise<GetPropertyConfigurationItemResponse> {
      return PropertyApiFp(configuration).propertyIdGet(id, options)(axios, basePath);
    },
  };
};

/**
 * PropertyApi - object-oriented interface
 * @export
 * @class PropertyApi
 * @extends {BaseAPI}
 */
export class PropertyApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyIdConfigurationGet(id: string, options?: any) {
    return PropertyApiFp(this.configuration).propertyIdConfigurationGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {UpdatePropertyRequest} [updatePropertyRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyIdConfigurationPatch(
    id: string,
    updatePropertyRequest?: UpdatePropertyRequest,
    options?: any
  ) {
    return PropertyApiFp(this.configuration).propertyIdConfigurationPatch(
      id,
      updatePropertyRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyIdGet(id: string, options?: any) {
    return PropertyApiFp(this.configuration).propertyIdGet(id, options)(this.axios, this.basePath);
  }
}
