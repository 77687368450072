import { EntityStore, EntityState, StoreConfig } from '@datorama/akita';
import { AuditLog, SecurityImageStatus, SecurityImage } from './audit-log.model';

export interface AuditLogUIState {
  securityImage: SecurityImage;
}

export interface AuditLogState extends EntityState<AuditLog, AuditLog['id']> {
  ui: AuditLogUIState;
}

const initialState: AuditLogState = {
  ui: {
    securityImage: { url: undefined, status: SecurityImageStatus.Pending },
  },
};

@StoreConfig({ name: 'audit-logs', resettable: true })
export class AuditLogStore extends EntityStore<AuditLogState> {
  constructor() {
    super(initialState);
  }
}

export const auditLogStore = new AuditLogStore();
