import { isToday, isTomorrow, lightFormat, parseISO, startOfDay } from 'date-fns';

import { Reservations } from '../api';
import { CorporateAccount, SleepSchedule } from '../models.common';
import { formatPaymentMethod } from '../payment-method';
import {
  ConsumedHolds,
  ContactType,
  Reservation,
  ReservationHoldModel,
  VipStatus,
} from '../reservation';
import { DailyRate, OptionalAmenity, RoomRate } from '../room-rate';
import { AcceptCardData, getE164FormattedPhoneNumber, groupBy } from '../utils';

type BookingGuest = Reservations.CreateGuestModel;
interface BookingContact extends Required<Omit<BookingGuest, 'vipStatus'>> {
  contactType: ContactType;
  userId?: string | null;
  vipStatus?: VipStatus;
}

interface RequestedRoom {
  group: number;
  rate: RoomRate;
  guests: Array<BookingGuest>;
  sleepSchedule?: SleepSchedule;
}

interface HasContact {
  contact: Reservations.Contact;
}

export interface BookingRequest
  extends Omit<
    Reservations.CreateReservationRequest,
    | 'checkInDate'
    | 'checkOutDate'
    | 'invoiceAccount'
    | 'amenities'
    | 'contact'
    | 'rooms'
    | 'guests'
    | 'sleepSchedule'
    | 'isMultipleBooking'
  > {
  checkInDate: Date;
  checkOutDate: Date;
  quantity: number;
  promoCode?: string | null;
  amenities?: Array<OptionalAmenity>;
  card?: AcceptCardData;
  contact: BookingContact;
  rooms: Array<RequestedRoom>;
  multipleBooking?: boolean;
  useGuestForBilling?: boolean;
  useRoomHolds?: boolean;
}

export type FailedReservation = {
  error: Error | null;
  guest?: Reservations.CreateGuestModel | null;
};

export type BookingResult = {
  reservation?: Reservation | null;
  failed?: FailedReservation | null;
  consumedHolds?: ReservationHoldModel[] | null;
};

export function mapBookingRequest({
  propertyId,
  checkInDate,
  checkOutDate,
  quantity,
  paymentProfileId,
  amenities,
  contact,
  rooms,
  multipleBooking = true,
  billing,
  useGuestForBilling,
  ...request
}: Omit<BookingRequest, 'card'>): Array<Reservations.CreateReservationRequest> {
  rooms = rooms.slice(0, quantity);
  const mapped = {
    ...request,
    propertyId,
    checkInDate: lightFormat(checkInDate, 'yyyy-MM-dd'),
    checkOutDate: lightFormat(checkOutDate, 'yyyy-MM-dd'),
    amenities: (amenities ?? []).filter(x => x.selected).map(x => x.code),
    billing: useGuestForBilling ? contact : billing,
    ...formatPaymentMethod(paymentProfileId),
  };

  // If this is a single booking, then all guests are under one reservation.
  //  - Used for a single room reservation with one or more guests
  //  - Used for a multiple room reservation with one or more guests per room
  if (!multipleBooking) {
    return [
      {
        ...mapped,
        isMultipleBooking: false,
        contact: formatContact(contact),
        guests: rooms.flatMap(x => formatGuestInfo(x.guests, x.group)),
        rooms: rooms.map(formatRoomRate),
        sleepSchedule: rooms[0].sleepSchedule,
      },
    ];
  }

  // Otherwise this is a multiple booking and each reservation has a single room
  return rooms.map((room, i) => ({
    ...mapped,
    isMultipleBooking: true,
    sleepSchedule: room.sleepSchedule,
    // a contact which could be a guest or delegate for the first room, but otherwise is a delegate
    contact: formatContact({
      ...contact,
      contactType: i === 0 ? contact.contactType : ContactType.Delegate,
    }),
    // the guests for the room
    guests: formatGuestInfo(room.guests),
    rooms: [formatRoomRate(room)],
  }));
}

export function mapBookingResponse(results: BookingResult[]) {
  var reservations = results
    .filter(({ reservation }) => !!reservation)
    .map(({ reservation }) => reservation!);

  var consumedHolds = results
    .flatMap(({ consumedHolds }) => consumedHolds)
    .reduce((acc: ConsumedHolds, curr) => {
      if (curr) {
        let date = new Date(curr.stayDate).toISOString().substring(0, 10);
        if (acc[date]) {
          acc[date].consumed += curr.consumedHolds!;
          acc[date].available = curr.remainingHolds!;
        } else {
          acc[date] = { consumed: curr.consumedHolds!, available: curr.remainingHolds! };
        }
      }
      return acc;
    }, {});

  var failures = results.filter(({ failed }) => !!failed).map(({ failed }) => failed!);

  return { failures, reservations, consumedHolds };
}

export function formatContact<T extends HasContact>(contact: T): T;
export function formatContact<T extends HasContact>(contact: T | undefined): T | undefined {
  if (!contact) return contact;

  const {
    contact: { phone, ...email },
    ...rest
  } = contact;

  return {
    ...rest,
    contact: {
      ...email,
      phone: !!phone ? getE164FormattedPhoneNumber(phone) : undefined,
    },
  } as T;
}

export function formatGuestInfo(
  guests: Reservations.CreateGuestModel[] | undefined,
  roomGroup: number = 0
): Reservations.CreateGuestModel[] {
  const formatter = (guest: Reservations.CreateGuestModel): Reservations.CreateGuestModel =>
    formatContact({
      ...guest,
      roomGroup,
      relationType: !!guest.relationType ? guest.relationType : undefined, // need to overwrite the default value of "" set by the input to prevent an enum mapping issue in the api
    });

  return (guests ?? []).filter(guest => !isGuestEmpty(guest)).map(formatter);
}

function isGuestEmpty(guest: Reservations.CreateGuestModel): boolean {
  return !guest.name.first && !guest.name.last;
}

function formatRoomRate({ rate }: RequestedRoom) {
  return {
    ...rate,
    dailyRates: rate.dailyRates.map((x: DailyRate) => ({
      ...x,
      date: lightFormat(x.date, 'yyyy-MM-dd'),
    })),
  };
}

// Is called to deteremine if payment should be saved prior to booking
// Should only occur for multiple bookings
export function canPreSavePayment(
  billing: Reservations.CreateBillingModel | undefined,
  savePaymentMethod: boolean | undefined,
  hasMultipleBookings: boolean
): boolean {
  return !!billing?.name && !!billing?.address && !!savePaymentMethod && hasMultipleBookings;
}

export function isEmployeeBookingAllowed(
  account: CorporateAccount | undefined,
  checkOut: string | Date | undefined
) {
  if (!account || !checkOut) return true;
  if (!account.disableEmployeeBooking) return true;

  const checkOutDate = typeof checkOut === 'string' ? parseISO(checkOut) : checkOut;
  const checkOutStartOfDay = startOfDay(checkOutDate);

  // todo: this check needs to move to the api / domain per #38454
  // .. for now, I've refactored this method to a single place,
  // fixed the check up to 7AM, updated the time to 9PM per #40294
  const now = new Date();
  const isLate = now.getHours() >= 21; // on or after 9PM
  const isEarly = now.getHours() < 7; // before 7AM
  return (isLate && isTomorrow(checkOutStartOfDay)) || (isEarly && isToday(checkOutStartOfDay));
}
