import { Reservations } from '../api';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import corporateDelegateStore, { CorporateDelegateStore } from './corporate-delegate.store';

export class CorporateDelegateService {
  constructor(
    private readonly corporateDelegateApi: Reservations.CorporateAccountApi,
    private readonly store: CorporateDelegateStore
  ) {}

  getDelegatesByAccountId(accountId: string) {
    // no need to reload the delegate list if we already have it
    if (this.store.getValue().corporateAccountId === accountId) return;

    // otherwise, clear the store and load the delegates
    this.store.reset();
    this.store.update({ corporateAccountId: accountId });

    from(this.corporateDelegateApi.corporateaccountIdDelegatesGet(accountId))
      .pipe(map(data => data.data))
      .subscribe(data => {
        this.store.set(data);
      });
  }
}

export const corporateDelegateService = new CorporateDelegateService(
  new Reservations.CorporateAccountApi(),
  corporateDelegateStore
);
