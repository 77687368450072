import { CorporateAccount, CostCenterFields, Reservations } from '@lib/state';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ContactDisplay, DateDisplay, NameDisplay } from '../../components';
import { parseISO } from 'date-fns';
import React from 'react';

interface Props {
  validation: Reservations.ValidateBookingResponse;
}

export const BookingValidationDisplay: React.FC<Props> = ({ validation }) => {
  if (!validation.message) return null;
  return (
    <Alert severity={validation.isValid ? 'warning' : 'error'}>
      <AlertTitle>{validation.message}</AlertTitle>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Reservation</TableCell>
              <TableCell>Check In</TableCell>
              <TableCell>Check Out</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Contact</TableCell>
              {validation.employeeNumberEnabled && <TableCell>Employee Number</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {validation.reservations?.map(reservation => (
              <TableRow key={reservation.recordNumber}>
                <TableCell>
                  <Typography>{reservation.recordNumber}</Typography>
                </TableCell>
                <TableCell>
                  <DateDisplay date={parseISO(reservation.checkIn)} />
                </TableCell>
                <TableCell>
                  <DateDisplay date={parseISO(reservation.checkOut)} />
                </TableCell>
                <TableCell>
                  <NameDisplay
                    {...reservation.name}
                    color={
                      reservation.validationTypes.includes(
                        Reservations.BookingValidationTypeEnum.Name
                      )
                        ? 'error'
                        : undefined
                    }
                  />
                </TableCell>
                <TableCell>
                  <ContactDisplay
                    {...reservation.contact}
                    color={
                      reservation.validationTypes.includes(
                        Reservations.BookingValidationTypeEnum.Email
                      )
                        ? 'error'
                        : undefined
                    }
                  />
                </TableCell>
                {validation.employeeNumberEnabled && (
                  <TableCell>
                    <Typography
                      color={
                        reservation.validationTypes.includes(
                          Reservations.BookingValidationTypeEnum.EmployeeNumber
                        )
                          ? 'error'
                          : undefined
                      }
                    >
                      {reservation.employeeNumber}
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Alert>
  );
};
