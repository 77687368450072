import { QueryEntity } from '@datorama/akita';
import corporateDelegateStore, {
  CorporateDelegateStore,
  CorporateDelegateState,
} from './corporate-delegate.store';

export class CorporateDelegateQuery extends QueryEntity<CorporateDelegateState> {
  constructor(protected store: CorporateDelegateStore) {
    super(store);
  }

  delegates = this.selectAll();
}

export const corporateDelegateQuery = new CorporateDelegateQuery(corporateDelegateStore);
