import { ThemeProvider } from '@material-ui/core';
import { printTheme } from '../print.theme';
import React, { useContext } from 'react';

export interface Printing {
  isPrinting: boolean;
}

const IsPrintingContext = React.createContext<Printing>({ isPrinting: false });
const isPrinting: Printing = { isPrinting: true };

export const PrintContext: React.FC = ({ children }) => {
  return (
    <IsPrintingContext.Provider value={isPrinting}>
      <ThemeProvider theme={printTheme}>{children}</ThemeProvider>
    </IsPrintingContext.Provider>
  );
};

export function useIsPrinting() {
  const { isPrinting } = useContext(IsPrintingContext);
  return isPrinting;
}
