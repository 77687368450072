import React from 'react';
import { Container } from '@material-ui/core';

import { Ledger } from '../components';
import { usePageTitle } from '@lib/common';

export const LedgerPage: React.FC = () => {
  usePageTitle('Ledger');
  return (
    <Container maxWidth="xl">
      <Ledger />
    </Container>
  );
};
