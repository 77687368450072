import React from 'react';
import { Typography } from '@material-ui/core';
import { InHouseGuest } from 'app/state';
import { formatDate } from 'app/shared';
import { EnumValueDisplay, GuestNameListDisplay, DataTable } from '@lib/common';

interface Props {
  guests?: InHouseGuest[];
}

export const InHouseGuestTable: React.FC<Props> = ({ guests = [] }) => {
  if (guests.length === 0) {
    return <Typography>No guests found</Typography>;
  }

  return (
    <DataTable
      items={guests}
      getItemKey={i => `${i.roomNumber}|${i.reservationNumber}`}
      columns={[
        {
          title: 'Reservation #',
          valueFactory: item => item.reservationNumber,
        },
        {
          title: 'Location',
          valueFactory: item => item.propertyName,
        },
        {
          title: 'Company',
          valueFactory: item => item.companyName,
        },
        {
          title: 'Guest',
          colSpan: 2,
          valueFactory: item => <GuestNameListDisplay guests={item.guestList} />,
        },
        {
          title: 'Sleep Schedule',
          valueFactory: item => <EnumValueDisplay value={item.sleepSchedule} />,
        },
        {
          title: 'Room',
          valueFactory: item => item.roomNumber,
        },
        {
          title: 'Check-In',
          valueFactory: item => formatDate(item.checkIn),
        },
        {
          title: 'Check-Out',
          valueFactory: item => formatDate(item.checkOut),
        },
        {
          title: 'Source',
          valueFactory: item => item.bookingSource ?? '',
        },
      ]}
    />
  );
};
