import React, { useMemo, useState } from 'react';
import { Button, Grid, Typography, makeStyles, createStyles, Container } from '@material-ui/core';
import { Reservation, Properties, ContactType } from '@lib/state';
import { RoomSelectionTapeChartController } from './room-selection-tape-chart-controller';
import { kioskStyles, NameDisplay } from '@lib/common';

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
  })
);

interface Props {
  reservation: Reservation;
  roomOptions: Properties.RoomModel[];
  onRoomsSelect: (roomIds?: Array<string>) => void;
}

export const CheckInRoomSelection: React.FC<Props> = ({
  roomOptions,
  reservation,
  onRoomsSelect,
}) => {
  const styles = useStyles();
  const { blueText, bodyText, kioskForm, formInstructions } = kioskStyles();
  const roomCount = reservation.rooms.length;
  const [allowCheckIn, setAllowCheckIn] = useState(
    roomOptions.some(s => reservation.rooms.some(m => m.roomTypeId === s.roomTypeId))
  );
  const [showTapeChart, setShowTapeChart] = useState(false);
  const [selectedRooms, setSelectedRooms] = useState<string[] | undefined>();

  const assignedRooms = useMemo(() => {
    if (!selectedRooms) {
      return roomOptions.slice(0, roomCount);
    } else {
      return roomOptions.filter(room => selectedRooms.includes(room.id));
    }
  }, [roomOptions, selectedRooms, roomCount]);

  return (
    <>
      {!showTapeChart && (
        <Container maxWidth="md" className={kioskForm}>
          <Typography
            variant="h4"
            color="textPrimary"
            align="center"
            paragraph
            className={formInstructions}
          >
            {allowCheckIn
              ? 'Please review the room assignments below and make any necessary changes.'
              : 'The booked room type is unavailable, please click below to select different a room.'}
          </Typography>
          <Grid container spacing={2}>
            {assignedRooms.map((room, i) => (
              <React.Fragment key={i}>
                <Grid item xs={3}>
                  <Typography variant="h3" align={'left'} className={blueText}>
                    Room {i + 1}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {reservation.contacts
                    .filter(c => c.contactType === ContactType.Guest && c.roomGroup === i)
                    .map(contact => (
                      <NameDisplay
                        key={contact.id}
                        variant="h4"
                        className={bodyText}
                        {...contact.name}
                      />
                    ))}
                </Grid>
                <Grid item xs={3}>
                  {allowCheckIn && (
                    <>
                      <Typography variant="h3" align={'right'} className={blueText}>
                        {room.roomNumber}
                      </Typography>
                      <Typography align={'right'} className={bodyText}>
                        {room.roomType.name}
                      </Typography>
                    </>
                  )}
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                className={styles.button}
                onClick={() => {
                  setShowTapeChart(true);
                }}
              >
                Change Room Assignments
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                disabled={!allowCheckIn}
                fullWidth
                className={styles.button}
                onClick={() => {
                  onRoomsSelect(selectedRooms);
                }}
                data-testid="checkInCompleteButton"
              >
                Complete Check-In
              </Button>
            </Grid>
          </Grid>
        </Container>
      )}
      {showTapeChart && (
        <RoomSelectionTapeChartController
          rooms={roomOptions}
          reservation={reservation}
          checkInRoomCount={reservation.rooms.length}
          onCanceled={() => {
            setShowTapeChart(false);
          }}
          onSelected={roomIds => {
            setAllowCheckIn(true);
            setSelectedRooms(roomIds ?? selectedRooms);
            setShowTapeChart(false);
          }}
        />
      )}
    </>
  );
};
