import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import { VipHousekeepingOverride } from './vip-housekeeping-overrides.model';

export interface VipHousekeepingOverrideState
  extends EntityState<VipHousekeepingOverride, VipHousekeepingOverride['id']> {}

@StoreConfig({ name: 'vip-housekeeping-override', resettable: true })
export class VipHousekeepingOverrideStore extends EntityStore<VipHousekeepingOverrideState> {
  constructor() {
    super({ active: null, ui: {} });
  }
}

export const vipHousekeepingOverrideStore = new VipHousekeepingOverrideStore();
