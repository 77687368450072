import React from 'react';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';

import { sessionQuery } from '@lib/state';
import { useObservable, CenteredLayout } from '@lib/common';
import {
  ChangePasswordPage,
  CreateAccountPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  SignInPage,
  SignOutPage,
  VerifyEmailPage,
  VerifyPhoneNumberPage,
  EmployeeEnrollmentPage,
} from './pages';

export const AccountRoutes: React.FC<RouteComponentProps> = ({ match }) => {
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');

  return (
    <CenteredLayout>
      <Switch>
        <Route path={`${match.path}/signin`} component={SignInPage} />
        <Route path={`${match.path}/signout`} component={SignOutPage} />
        <Route path={`${match.path}/create`} component={CreateAccountPage} />
        <Route path={`${match.path}/forgot`} component={ForgotPasswordPage} />
        <Route path={`${match.path}/reset`} component={ResetPasswordPage} />
        <Route path={`${match.path}/employee-enrollment`} component={EmployeeEnrollmentPage} />
        <Route path={`${match.path}/verify/email`} component={VerifyEmailPage} />
        {isLoggedIn && <Route path={match.path} component={PrivateRoutes} />}
        <Redirect to={`${match.path}/signin`} />
      </Switch>
    </CenteredLayout>
  );
};

const PrivateRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/verify/phone/:userId`} component={VerifyPhoneNumberPage} />
    <Route path={`${match.path}/change`} component={ChangePasswordPage} />
  </Switch>
);
