import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { GuestStayModel } from './guest-stays.models';

export interface GuestStaysState
  extends EntityState<GuestStayModel, GuestStayModel['id']>,
    ActiveState<GuestStayModel['id']> {}

@StoreConfig({ name: 'guest-stay', resettable: true })
export class GuestStaysStore extends EntityStore<GuestStaysState> {}

export const guestStaysStore = new GuestStaysStore();
