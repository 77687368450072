import React, { useCallback } from 'react';
import { Box, TypographyProps } from '@material-ui/core';

import { FormStatus, Reservation, reservationService, CostCenterFieldValue } from '@lib/state';
import { useFormEvents, EditableDisplay } from '../../forms';
import { useUniqueId } from '../../utils';
import { PriorityHigh } from '@material-ui/icons';

type ReservationLike = Pick<Reservation, 'id'>;
interface Props {
  reservation: Reservation | ReservationLike;
  costCenterField: CostCenterFieldValue;

  labelVariant?: TypographyProps['variant'];
  valueVariant?: TypographyProps['variant'];
  dense?: boolean;
}

export const CostCenterFieldEditableDisplay: React.FC<Props> = ({
  reservation,
  costCenterField,
  ...props
}) => {
  const formId = useUniqueId('cost-center-field');

  const [{ status, error }] = useFormEvents(formId);

  const onSubmit = useCallback(
    (value?: string) => {
      if (reservation && costCenterField) {
        reservationService.changeCostCenterFields(
          reservation.id,
          {
            key: costCenterField.key,
            value,
          },
          formId
        );
      }
    },
    [reservation, costCenterField, formId]
  );

  if (!reservation || !costCenterField) return null;

  return (
    <Box sx={{ display: 'flex' }} data-testid={`details${costCenterField.key}Display`}>
      <EditableDisplay
        label={costCenterField.label}
        value={costCenterField.value ?? ''}
        onSubmit={onSubmit}
        validationOptions={{
          required: {
            value: costCenterField.isRequired,
            message: 'Field is required',
          },
          maxLength: {
            value: 80,
            message: 'Maximum of 80 characters.',
          },
        }}
        pending={status == FormStatus.Pending}
        error={error}
        {...props}
      />
      {costCenterField.isRequired && !costCenterField.value && (
        <PriorityHigh
          style={{ color: 'red', fontSize: '20' }}
          titleAccess="This is a required field but the value entry was postponed during check-in."
        />
      )}
    </Box>
  );
};

export default CostCenterFieldEditableDisplay;
