import { Store } from '@datorama/akita';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { trace, traceError } from './trace';
import { IResettable } from './resettableStore';

export const resetUI = (store: Store<any>) => {
  store.setError(undefined);
  store.setLoading(false);
};

type DispatchStore<T = any> = Store<T> & IResettable;

export function dispatch<TResult, TStore extends DispatchStore, TErr = Error>(store: TStore) {
  return (source: Observable<TResult>) => {
    return new Observable<TResult>(observer => {
      return source.pipe(takeUntil(store.storeCancel)).subscribe({
        next(result: TResult) {
          trace(`Dispatch ${store.storeName}:`, result);
          observer.next(result);
        },
        error(err: TErr) {
          traceError(`Dispatch ${store.storeName} error:`, err);
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
    });
  };
}

export function dispatchCancel<TResult, TStore extends DispatchStore, TErr = Error>(store: TStore) {
  store.cancel();
  return dispatch<TResult, TStore, TErr>(store);
}
