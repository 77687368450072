import React from 'react';
import { InputAdornment, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import { AppColors } from '../../theme';

interface Props {
  name: string;
  value?: unknown;
  verified?: boolean;
}

export const VerifiedIndicator: React.FC<Props> = ({ name, value, verified }) => {
  const { watch } = useFormContext();

  const currentValue = watch(name, value);
  const isDirty = currentValue !== value;

  if (!verified || isDirty) return null;

  return (
    <Tooltip title="Verified">
      <InputAdornment position="start">
        <FontAwesomeIcon color={AppColors.Green} icon="check-circle" />
      </InputAdornment>
    </Tooltip>
  );
};
