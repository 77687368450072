import React, { HTMLAttributes } from 'react';
import { Container, ContainerProps, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme =>
  createStyles({
    responsiveContainer: {
      [theme.breakpoints.down('md')]: {
        maxWidth: theme.breakpoints.values['md'],
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 1.5rem !important',
      },
    },
  })
);

interface ResponsiveContainerProps extends HTMLAttributes<HTMLDivElement> {
  disableGutters?: boolean;
  maxWidth?: ContainerProps['maxWidth'];
  children: NonNullable<React.ReactNode>;
}

export const ResponsiveContainer: React.FC<ResponsiveContainerProps> = ({
  maxWidth = 'lg',
  ...props
}) => {
  const styles = useStyles();

  return (
    <Container
      {...props}
      className={clsx(styles.responsiveContainer, props.className)}
      maxWidth={maxWidth}
    />
  );
};
