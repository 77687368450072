import React, { FormEvent, HTMLAttributes } from 'react';
import { useForm, FormContext, OnSubmit, UseFormOptions } from 'react-hook-form';
import { autoCompleteIfEnabled } from '../utils';

interface Props<T> extends Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'> {
  onSubmit: OnSubmit<T>;
  formOptions?: UseFormOptions<T>;
}

export function FormHub<T>({ onSubmit, formOptions, ...props }: Props<T>) {
  const methods = useForm<T>({ mode: 'onBlur', reValidateMode: 'onChange', ...formOptions });

  return (
    <FormContext {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        {...props}
        autoComplete={autoCompleteIfEnabled()}
      />
    </FormContext>
  );
}
