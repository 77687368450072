import React, { useState, useCallback } from 'react';
import { Button, Menu, MenuItem, makeStyles, createStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { propertyQuery, propertyService, Property } from '@lib/state';
import { useObservable } from '@lib/common';

interface Props {
  readonly?: boolean;
  onChange: () => void;
}

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      display: 'flex',
      minWidth: '128px',
      height: '2rem',
      padding: '0.25rem 0.5rem',
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.white}`,
      fontWeight: 700,
      textTransform: 'none',
      borderRadius: '6px',

      '&:disabled': {
        color: theme.palette.common.white,
      },
    },
    buttonLabel: {
      lineHeight: '1.5rem',
    },
    buttonItemEnd: {
      marginLeft: 'auto',
    },
    menu: {
      marginTop: '12px',
      zIndex: theme.zIndex.modal + 2,
    },
    menuList: {
      minWidth: '128px',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    menuItem: {
      padding: '16px',

      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  })
);

export const PropertySelector: React.FC<Props> = ({ readonly = false, onChange }) => {
  const { button, buttonLabel, buttonItemEnd, menu, menuList, menuItem } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const activeProperty = useObservable(propertyQuery.activeProperty, 'async');
  const properties = useObservable(propertyQuery.properties);

  const selectProperty = useCallback(
    (id: Property['id']) => {
      if (readonly) return;

      propertyService.selectProperty(id);
      setAnchorEl(null);
      onChange();
    },
    [readonly, onChange]
  );

  return (
    <>
      <Button
        id="property-selector-toggle"
        classes={{ text: button, label: buttonLabel }}
        variant="text"
        onClick={e => setAnchorEl(anchorEl == null ? e.currentTarget : null)}
        disableElevation
        disabled={readonly}
        data-testid="navPropertySelectorButton"
      >
        <div>{activeProperty?.name ?? 'Select A Property'}</div>
        <div className={buttonItemEnd}>
          <FontAwesomeIcon icon="chevron-down" />
        </div>
      </Button>
      <Menu
        className={menu}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        getContentAnchorEl={null}
        MenuListProps={{
          dense: true,
          disablePadding: true,
          classes: { root: menuList },
        }}
        data-testid="propertySelectorMenu"
      >
        {properties.map(p => (
          <MenuItem
            key={p.id}
            className={menuItem}
            disabled={p === activeProperty}
            selected={p === activeProperty}
            onClick={() => selectProperty(p.id)}
          >
            {p.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
