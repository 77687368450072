import { useEffect } from 'react';

import { roomTypeService, roomTypeQuery, RoomType } from '@lib/state';
import { useObservable } from './useObservable';

export function useRoomType(roomTypeId?: RoomType['id'] | null) {
  useEffect(() => {
    if (!roomTypeId) return;

    roomTypeService.selectRoomType(roomTypeId);
  }, [roomTypeId]);

  return useObservable(roomTypeQuery.activeRoomType);
}
