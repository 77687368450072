import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Audit, AccessControl } from '@lib/state';
import { AuditLogStore, auditLogStore } from './audit-log.store';
import { SecurityImage, SecurityImageStatus } from './audit-log.model';

export class AuditLogService {
  constructor(
    private store: AuditLogStore,
    private securityApi: Audit.SecurityApi,
    private accessControlApi: AccessControl.PropertyApi
  ) {}

  loadDoorAccessSecurityImage(hashKey: string, rangeKey: string): void {
    this.updateSecurityImage({ url: undefined, status: SecurityImageStatus.Pending });

    from(this.securityApi.securitySecurityImageKeyPost({ hashKey, rangeKey }))
      .pipe(
        switchMap(x =>
          this.accessControlApi.propertyDoorAccessImagePost({ imageKey: x.data.securityImageKey })
        )
      )
      .subscribe(
        x =>
          this.updateSecurityImage({
            url: x.data.doorAccessImageUrl,
            status: SecurityImageStatus.Loaded,
          }),
        err => this.updateSecurityImage({ url: undefined, status: SecurityImageStatus.NotFound }),
        () => null
      );
  }

  private updateSecurityImage(securityImage: SecurityImage) {
    this.store.update(({ ui }) => {
      return {
        ui: {
          ...ui,
          securityImage,
        },
      };
    });
  }
}

export const auditLogService = new AuditLogService(
  auditLogStore,
  new Audit.SecurityApi(),
  new AccessControl.PropertyApi()
);
