import React from 'react';
import { Grid } from '@material-ui/core';

import {
  FormEmailField,
  FormPhoneField,
  TextFieldProps,
  useFieldLabelProps,
  useFormPrefix,
} from '../../forms';

interface Props {
  prefix?: string;

  email?: string | null;
  emailVerified?: boolean;
  phone?: string | null;
  phoneVerified?: boolean;
  prefixName?: string;
  required?: 'email' | 'phone' | boolean;
  isEmailDisabled?: boolean;
  inputProps?: TextFieldProps;
}

export const ContactInformationForm: React.FC<Props> = ({
  prefix,
  email,
  phone,
  required,
  isEmailDisabled,
  emailVerified,
  phoneVerified,
  prefixName = 'contact',
  inputProps = {},
}) => {
  const fieldName = useFormPrefix(prefix);

  const emailLabelProps = useFieldLabelProps(fieldName(`${prefixName}.email`), email, inputProps);
  const phoneLabelProps = useFieldLabelProps(fieldName(`${prefixName}.phone`), phone, inputProps);

  return (
    <Grid container spacing={1}>
      <Grid item md={6} xs={12}>
        <FormEmailField
          {...inputProps}
          name={fieldName(`${prefixName}.email`)}
          defaultValue={email}
          verified={emailVerified}
          fullWidth
          required={required === 'email' || required === true}
          disabled={isEmailDisabled}
          InputLabelProps={emailLabelProps}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormPhoneField
          {...inputProps}
          name={fieldName(`${prefixName}.phone`)}
          defaultValue={phone}
          verified={phoneVerified}
          required={required === 'phone' || required === true}
          fullWidth
          InputLabelProps={phoneLabelProps}
        />
      </Grid>
    </Grid>
  );
};
