import React, { useEffect } from 'react';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';
import {
  AccountLoginPage,
  CreateReservationPage,
  KioskCheckInPage,
  KioskDashboardPage,
  KioskLoginMethodPage,
  KioskUserReservationsPage,
  QrErrorPage,
  QrLoginPage,
  ReservationAccountPage,
  ReservationSearchPage,
  ReservationSearchResultsPage,
  SelectPropertyPage,
  VerifyReservationCodePage,
  FrontDeskLoginPage,
  ClaimReservationPage,
  BypassReservationCodePage,
  CheckInRoomOptionsPage,
} from './pages';

import { ResourceLoader } from 'app/resource-loader';
import { handleKioskLogout, isKiosk } from 'app/shared';
import { sessionService } from '@lib/state';

declare module 'react-router-dom' {
  interface RouteProps {
    title?: string;
  }
}

export const KioskRoutes: React.FC<RouteComponentProps> = ({ match, location }) => {
  return (
    <>
      {!isKiosk() && <ResourceLoader customerCode="armadillo" />}
      <Switch>
        <Route path={match.path} exact component={KioskDashboardPage} />
        <Route path={`${match.path}/select-property`} component={SelectPropertyPage} />
        <Route path={`${match.path}/login-method`} component={KioskLoginMethodPage} />
        <Route
          path={`${match.path}/front-desk/qr-code`}
          component={QrLoginPage}
          title="Scan QR Code"
        />

        <Route
          path={`${match.path}/front-desk/:reservationId/account`}
          component={BypassReservationCodePage}
        />
        <Route
          path={`${match.path}/front-desk/:reservationId/check-in`}
          component={KioskCheckInPage}
        />
        <Route
          path={`${match.path}/front-desk/:reservationId/check-in-options`}
          component={CheckInRoomOptionsPage}
        />
        <Route path={`${match.path}/front-desk`} component={FrontDeskLoginPage} />
        <Route path={match.path} component={GuestKioskRoutes} />
      </Switch>
    </>
  );
};

const GuestKioskRoutes: React.FC<RouteComponentProps> = ({ match }) => {
  useEffect(() => {
    // for any of these guest routes, we must start as an anonymous user
    // .. later, we can restore the session when we leave these routes
    sessionService.preserveSessionAndLogout();
    return () => handleKioskLogout();
  }, []);

  return (
    <Switch>
      <Route path={`${match.path}/account`} component={AccountLoginPage} title="Account Login" />
      <Route path={`${match.path}/qr-code`} component={QrLoginPage} title="Scan QR Code" />
      <Route path={`${match.path}/qr-error`} component={QrErrorPage} title="Invalid QR Code" />
      <Route
        path={`${match.path}/new-reservation`}
        component={CreateReservationPage}
        title="Book Room"
      />
      <Route
        path={`${match.path}/reservations`}
        component={KioskUserReservationsPage}
        title="Your Reservations"
      />
      <Route
        path={`${match.path}/search`}
        exact
        component={ReservationSearchPage}
        title="Search by Name"
      />
      <Route
        path={`${match.path}/search/results`}
        component={ReservationSearchResultsPage}
        title="Search Results"
      />
      <Route
        path={`${match.path}/:reservationId/check-in`}
        component={KioskCheckInPage}
        title="Checking-In"
      />
      <Route
        path={`${match.path}/:reservationId/verify`}
        component={VerifyReservationCodePage}
        title="Enter Code"
      />
      <Route
        path={`${match.path}/:reservationId/account`}
        component={ReservationAccountPage}
        title="Create Account"
      />
      <Route
        path={`${match.path}/:reservationId/claim`}
        component={ClaimReservationPage}
        title="Checking-In"
      />
      <Redirect to={match.path} />
    </Switch>
  );
};
