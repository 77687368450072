import React, { useEffect } from 'react';
import { makeStyles, createStyles, Button, Typography } from '@material-ui/core';
import {
  Housekeeper,
  HousekeepingAssignment,
  housekeepingAssignmentService,
  HousekeepingAssignmentForms,
  FormStatus,
} from '@lib/state';
import { AppColors, useFormEvents } from '@lib/common';
import { EmployeeSelect } from 'app/shared';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    error: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '16px',
    },
    errorText: {
      color: `${theme.palette.common.white} !important`,
    },
  })
);

interface Option {
  label: string;
  value: string;
}
interface Props {
  propertyId: string;
  date: Date;
  housekeepers: Housekeeper[];
  selectedRooms: HousekeepingAssignment[];
  onAssignmentComplete: () => void;
}

export const HousekeeperSelection: React.FC<Props> = ({
  propertyId,
  date,
  housekeepers,
  selectedRooms,
  onAssignmentComplete,
}) => {
  const styles = useStyles();
  const [selectedHousekeeper, setSelectedHousekeeper] = React.useState<string | undefined>(
    undefined
  );
  const [selectedSupervisor, setSelectedSupervisor] = React.useState<string | undefined>(undefined);

  const [updateAssignmentResult, resetUpdateAssignment] = useFormEvents(
    HousekeepingAssignmentForms.UpdateHousekeepingRoomAssignment
  );
  const [removeAssignmentResult, resetRemoveAssignment] = useFormEvents(
    HousekeepingAssignmentForms.RemoveHousekeepingRoomAssignment
  );

  useEffect(() => {
    if (
      updateAssignmentResult.status === FormStatus.Success ||
      removeAssignmentResult.status === FormStatus.Success
    ) {
      resetRemoveAssignment();
      resetUpdateAssignment();
      onAssignmentComplete();
    }
  }, [
    updateAssignmentResult,
    removeAssignmentResult,
    resetRemoveAssignment,
    resetUpdateAssignment,
    onAssignmentComplete,
  ]);

  const onHousekeeperSelect = (housekeeperId?: string) => {
    setSelectedHousekeeper(housekeeperId);
    if (!housekeeperId) {
      //when housekeeper is undefined, reset supervisor
      setSelectedSupervisor(undefined);
    }
  };

  const onClick = (housekeeperId?: string, supervisorId?: string) => {
    if (!housekeeperId) {
      housekeepingAssignmentService.removeHousekeepingAssignment(propertyId, selectedRooms, date);
    } else {
      housekeepingAssignmentService.updateHousekeepingAssignment(
        propertyId,
        selectedRooms,
        housekeeperId,
        date,
        supervisorId
      );
    }
  };

  return (
    <div className={styles.container}>
      <EmployeeSelect
        options={housekeepers}
        placeholder="Housekeepers"
        onEmployeeSelect={selectedHousekeeper => {
          onHousekeeperSelect(selectedHousekeeper);
        }}
        iconColor={AppColors.DarkBlue}
        variant="outlined"
      />
      <EmployeeSelect
        options={housekeepers.filter(
          ({ userId, isSupervisor }) =>
            selectedHousekeeper && userId !== selectedHousekeeper && isSupervisor
        )}
        placeholder="Supervisor"
        resetKey={selectedHousekeeper} //when this value changes, it will reset/clear selection of supervisor
        onEmployeeSelect={userId => {
          setSelectedSupervisor(userId);
        }}
        iconColor={AppColors.DarkBlue}
        variant="outlined"
      />
      <Button
        onClick={() => onClick(selectedHousekeeper, selectedSupervisor)}
        color="secondary"
        variant="contained"
      >
        {!!selectedHousekeeper ? 'Assign' : 'Unassign'}
      </Button>
      {(updateAssignmentResult.error || removeAssignmentResult.error) && (
        <div className={styles.error}>
          <Typography variant="h5" className={styles.errorText}>
            {updateAssignmentResult.error?.message}
            {removeAssignmentResult.error?.message}
          </Typography>
        </div>
      )}
    </div>
  );
};
