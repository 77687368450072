import { useCallback, useEffect, useMemo, useState } from 'react';
import { addDays, eachDayOfInterval, isAfter, isSameDay, startOfDay, startOfToday } from 'date-fns';
import { useObservable } from '@lib/common';
import { propertyQuery } from '@lib/state';

export interface TimelineProps {
  startDate: Date;
  view: TimelineView;
}

export enum TimelineView {
  OneDay = 1,
  TwoWeek = 14,
  ThirtyDay = 30,
}

export function useTimeline(start: Date, view: TimelineView) {
  return useMemo(() => getTimelineDays(start, view), [start, view]);
}

export function getTimelineDays(start: Date, view: TimelineView) {
  return eachDayOfInterval({ start, end: addDays(start, view - 1) });
}

export function useCurrentStayDate() {
  const [startDate, setStartDate] = useState(startOfToday());
  const today = useObservable(propertyQuery.currentStayDate, 'async');

  useEffect(() => {
    if (today) {
      setStartDate(today);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [today?.valueOf()]);

  return [startDate, setStartDate] as const;
}

export function useStayDates() {
  const today = useObservable(propertyQuery.currentStayDate, 'async') ?? startOfToday();

  const isToday = useCallback((date: Date) => isSameDay(date, today), [today]);
  const isFuture = useCallback((date: Date) => isAfter(startOfDay(date), today), [today]);

  return {
    today,
    isToday,
    isFuture,
  };
}
