import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';

import { FormStatus, Room } from '@lib/state';
import { ErrorDisplay, FormHub, StyledDialog, SubmitButton, useFormEvents, DataTable } from '@lib/common';
import { GuestNotesForms } from 'app/state';
import { GridSelectionModel } from '@mui/x-data-grid-pro';

interface Props {
  rooms: Room[];
  previouslySelected: string[];
  onSubmission: (rooms: string[]) => void;
}

interface FormFields {
  rooms: Room[];
}

export const AddRooms: React.FC<Props> = ({ rooms, previouslySelected, onSubmission }) => {
  const [showModal, setShowModal] = useState(false);

  const [selectedRoomIdsArray, setSelectedRoomIdsArray] = useState<string[]>([]);

  const [{ status, error }, resetUI] = useFormEvents(GuestNotesForms.Create);

  const [displayRooms, setDisplayRooms] = useState<Room[]>([]);

  useEffect(() => {
    var selected = new Set(previouslySelected);
    setDisplayRooms(
      rooms.filter(room => {
        return !selected.has(room.id);
      })
    );
  }, [rooms, previouslySelected]);

  const updateSelectedRoomIdsArray = (arr: string[]) => {
    setSelectedRoomIdsArray([...arr]);
  };

  const filterRooms = (filterText: string) => {
    setDisplayRooms(
      rooms.filter(room => {
        if (room.roomNumber.startsWith(filterText)) {
          return room;
        } else {
          return undefined;
        }
      })
    );
  };

  useEffect(() => {
    if (status === FormStatus.Success) {
      setShowModal(false);
      resetUI();
    }
  }, [status, resetUI]);

  const onSubmit = useCallback(
    // eslint-disable-next-line no-empty-pattern
    ({}: FormFields) => {
      onSubmission([...selectedRoomIdsArray]);
      resetUI();
    },
    [selectedRoomIdsArray, onSubmission, resetUI]
  );

  return (
    <>
      <Box my="1rem" display="flex" justifyContent="flex-end" flexDirection="row">
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            resetUI();
            setShowModal(true);
          }}
        >
          Add Rooms
        </Button>
      </Box>
      <StyledDialog title="Add Rooms" open={showModal} onClose={() => setShowModal(false)}>
        <DialogContent>
          <FormHub<FormFields> onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DialogContentText>
                  Please select the rooms you would like to add.
                </DialogContentText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  placeholder={'Filter'}
                  onChange={x => {
                    filterRooms(x.target.value);
                  }}
                />
                <DataTable
                  items={displayRooms}
                  getItemKey={room => room.id}
                  columns={[
                    {
                      title: 'Room',
                      valueFactory: room => <Typography>{room.roomNumber}</Typography>,
                    },
                  ]}
                  checkboxSelection
                  onSelectionModelChange={(model: GridSelectionModel) => {
                    updateSelectedRoomIdsArray(model as string[]);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <ErrorDisplay error={error} />
                <SubmitButton
                  variant="contained"
                  color="secondary"
                  fullWidth
                  pending={status === FormStatus.Pending}
                  disabled={status === FormStatus.Pending}
                >
                  Add
                </SubmitButton>
              </Grid>
            </Grid>
          </FormHub>
        </DialogContent>
      </StyledDialog>
    </>
  );
};
