import { applyTransaction } from '@datorama/akita';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { format } from 'date-fns';
import { Reservations } from '../api';
import { dispatchForm } from '../forms';
import { throwIf, Debounce, RequestCancellation } from '../utils';
import { mapUpdateCost, Reservation, ReservationActivity } from '../reservation';
import { CorporateAccount } from '../models.common';
import { mapRoomRate, RoomRate } from './room-rate.model';
import { RoomRateStore, RoomRateUIState, roomRateStore } from './room-rate.store';
import { RoomRateForms } from './room-rate.forms';
import { PropertyPolicy } from '../property';
import { ChangeBillingRefunds } from '../api/generated/reservations/models/change-billing-refunds';
export class RoomRateService {
  constructor(
    private readonly store: RoomRateStore,
    private readonly rateApi: Reservations.AvailabilityApi
  ) {}

  getRoomRates(
    propertyId: string,
    checkInDate: Date,
    checkOutDate: Date,
    quantity: number,
    promoCode?: string | null,
    userId?: string | null,
    corporateAccountId?: string | null
  ): void {
    this.availabilityRatesObs(
      propertyId,
      checkInDate,
      checkOutDate,
      quantity,
      promoCode,
      userId,
      corporateAccountId
    )
      .pipe(
        throwIf(x => x.data.data.length === 0, 'No Available Rooms Were Found.'),
        dispatchForm(RoomRateForms.Availability),
        map(response => response.data)
      )
      .subscribe(({ data, corporateAccount, policy }) =>
        applyTransaction(() => {
          this.store.set(data.map(mapRoomRate));
          this.setCorporateBooking(corporateAccount);
          this.setPropertyPolicy(policy);
          this.clearUpdateCost();
        })
      );
  }

  availabilityRatesObs(
    propertyId: string,
    checkInDate: Date,
    checkOutDate: Date,
    quantity: number,
    promoCode?: string | null,
    userId?: string | null,
    corporateAccountId?: string | null
  ) {
    return from(
      this.rateApi.availabilityRatesPost({
        propertyId: propertyId,
        checkInDate: format(checkInDate, 'yyyy-MM-dd'),
        checkOutDate: format(checkOutDate, 'yyyy-MM-dd'),
        promoCode: promoCode ?? undefined,
        userId: userId ?? undefined,
        corporateAccountId: corporateAccountId ?? undefined,
        quantity: quantity,
      })
    );
  }

  getRoomRatesByReservation(
    propertyId: string,
    checkInDate: Date,
    checkOutDate: Date,
    reservationId: string,
    roomTypeId: string,
    activtyType: ReservationActivity,
    promoCode?: string | null,
    userId?: string | null,
    corporateAccountId?: string | null
  ): void {
    this.getReservationRoomRatesWithCancellation(
      propertyId,
      checkInDate,
      checkOutDate,
      reservationId,
      activtyType,
      promoCode,
      userId,
      corporateAccountId
    )
      .pipe(
        dispatchForm(RoomRateForms.Availability),
        map(response => response.data)
      )
      .subscribe(({ charges, changeBillingRefunds, data, corporateAccount, policy }) =>
        applyTransaction(() => {
          this.updateUI({ propertyId, checkInDate, checkOutDate, roomTypeId, promoCode });
          this.store.set(data.map(mapRoomRate));
          this.store.update(() => ({
            updateCost: mapUpdateCost(charges),
          }));
          this.setCorporateBooking(corporateAccount);
          this.setPropertyPolicy(policy);
          this.setChangeBillingRefunds(changeBillingRefunds);
        })
      );
  }

  @Debounce(500)
  @RequestCancellation()
  private getReservationRoomRatesWithCancellation(
    propertyId: string,
    checkInDate: Date,
    checkOutDate: Date,
    reservationId: string,
    activtyType: ReservationActivity,
    promoCode?: string | null,
    userId?: string | null,
    corporateAccountId?: string | null,
    options?: any
  ) {
    return from(
      this.rateApi.availabilityRatesReservationPost(
        {
          propertyId: propertyId,
          checkInDate: format(checkInDate, 'yyyy-MM-dd'),
          checkOutDate: format(checkOutDate, 'yyyy-MM-dd'),
          reservationId: reservationId,
          reservationActivityType: activtyType,
          promoCode: promoCode ?? undefined,
          userId: userId ?? undefined,
          corporateAccountId: corporateAccountId ?? undefined,
        },
        options
      )
    );
  }

  selectRoomRate(rate?: RoomRate) {
    this.store.setActive(rate?.roomTypeId ?? null);
  }

  updateUI(values: Partial<RoomRateUIState>) {
    this.store.update(({ ui }) => ({ ui: { ...ui, ...values } }));
  }

  resetUI() {
    this.updateUI({
      checkInDate: undefined,
      checkOutDate: undefined,
      promoCode: undefined,
      quantity: undefined,
    });
  }

  clearUpdateCost() {
    this.store.update(() => ({ updateCost: undefined }));
    this.setChangeBillingRefunds(undefined);
  }

  setCorporateBooking(account?: CorporateAccount) {
    this.store.update(() => ({ corporateBooking: account }));
  }

  setChangeBillingRefunds(changeBillingRefunds?: ChangeBillingRefunds) {
    this.store.update(() => ({ changeBillingRefunds: changeBillingRefunds }));
  }

  setPropertyPolicy(policy?: PropertyPolicy) {
    this.store.update(() => ({ policy: policy }));
  }
}

export const roomRateService = new RoomRateService(
  roomRateStore,
  new Reservations.AvailabilityApi()
);
