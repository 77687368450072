import { AppColors } from '@lib/common';
import { Box, Button, ButtonGroup, createStyles, makeStyles } from '@material-ui/core';
import { AuditLogs } from 'app/shared';
import { AuditHistoryTab } from 'app/tape-chart/components/audit-history-tabs';
import React, { useState } from 'react';

interface Props {
  roomId: string | undefined;
}

const useStyles = makeStyles(() =>
  createStyles({
    buttonGroup: {
      marginLeft: 'auto',
    },
    selectedButton: {
      color: AppColors.White,
      backgroundColor: AppColors.MediumBlue,
      '&:hover': {
        color: AppColors.White,
        backgroundColor: AppColors.MediumBlue,
      },
    },
  })
);

interface HistorySectionTab extends AuditHistoryTab {
  scope: string;
  getKey: (props: Props) => string | undefined;
}

const tabs: Array<HistorySectionTab> = [
  {
    title: 'Room',
    scope: 'room',
    getKey({ roomId }) {
      return roomId;
    },
  },
  {
    title: 'QR Codes',
    scope: 'room-qr-codes',
    getKey({ roomId }) {
      return roomId;
    },
  },
  {
    title: 'Device Usage',
    scope: 'room-thing-actions',
    getKey({ roomId }) {
      return roomId;
    },
  },
];

export function RoomLogsTab(props: Props) {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Box>
      <Box marginBottom="1em">
        <AuditHistorySelect
          tabs={tabs}
          isVisible={i => !!tabs[i].getKey(props)}
          selectedTab={tabIndex}
          setSelectedTab={i => setTabIndex(i)}
        />
      </Box>
      <Box>
        <AuditLogs scope={tabs[tabIndex].scope} id={tabs[tabIndex].getKey(props)} />
      </Box>
    </Box>
  );
}

export interface AuditHistoryTabProps {
  tabs: HistorySectionTab[];
  isVisible: (tab: number) => boolean;
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
}

export const AuditHistorySelect: React.FC<AuditHistoryTabProps> = ({
  tabs,
  isVisible,
  selectedTab,
  setSelectedTab,
}) => {
  const styles = useStyles();

  return (
    <ButtonGroup
      color="primary"
      aria-label="outlined primary button group"
      className={styles.buttonGroup}
      fullWidth
    >
      {tabs.map(
        (tab, tabIndex) =>
          isVisible(tabIndex) && (
            <Button
              key={tab.title}
              onClick={() => setSelectedTab(tabIndex)}
              className={selectedTab === tabIndex ? styles.selectedButton : undefined}
            >
              {tab.title}
            </Button>
          )
      )}
    </ButtonGroup>
  );
};
