import { useEffect } from 'react';

import { Reservation, checkInService, checkInQuery } from '@lib/state';
import { useObservable } from './useObservable';

export function useCheckInRequirements(reservationId: Reservation['id']) {
  useEffect(() => {
    checkInService.getCheckInRequirements(reservationId);

    return () => checkInService.reset();
  }, [reservationId]);

  return useObservable(checkInQuery.requirements);
}
