import React from 'react';
import { Typography, Box, Grid } from '@material-ui/core';
import { GuestProfile } from '@lib/state';
import { Tile, DownloadButton } from 'app/shared';
import {
  NameDisplay,
  Section,
  Link,
  ShowMoreButton,
  GuestSIdDisplay,
  DataTable,
  IDataTableColumn,
  VipDisplay,
} from '@lib/common';
import { GuestSearchFilter, GuestFilters } from './guest-search-filter';

interface Props {
  guests: Array<GuestProfile>;
  filters: GuestFilters;
  isDone: boolean;
  loadMore: () => void;
  onExport: (filters: GuestFilters) => void;
  onChange: (filters: GuestFilters) => void;
}

export const GuestLookup: React.FC<Props> = ({
  guests,
  filters,
  isDone,
  loadMore,
  onExport,
  onChange,
}) => {
  const columns: IDataTableColumn<GuestProfile>[] = [
    {
      title: 'Guest',
      valueFactory: guest => (
        <Grid container>
          <Grid item xs={11}>
            <Link to={`/guests/${guest.userId}`}>{<NameDisplay {...guest.name} directory />}</Link>
          </Grid>
          <Grid item xs={1}>
            <VipDisplay vipStatus={guest.vipStatus} key={guest.userId} />
          </Grid>
        </Grid>
      ),
    },
    {
      title: 'Email',
      valueFactory: guest => <Typography>{guest.contact.email}</Typography>,
    },
    {
      title: 'Phone',
      valueFactory: res => <Typography>{res.contact.phone}</Typography>,
    },
    {
      title: 'Archived',
      valueFactory: res => <Typography>{res.archived ? 'Yes' : 'No'}</Typography>,
    },
    {
      title: 'Guest SID',
      valueFactory: res => <GuestSIdDisplay val={res.sId} />,
    },
  ];

  return (
    <Tile>
      <Box display="flex" justifyContent="flex-end" flexDirection="row">
        <DownloadButton onDownload={() => onExport(filters)} />
      </Box>
      <GuestSearchFilter filters={filters} onChange={onChange} />

      {guests.length > 0 && (
        <Section title="Results" textAlign="left" disableGutters>
          <DataTable
            items={guests}
            getItemKey={guest => guest.userId}
            children={<ShowMoreButton isDone={isDone} loadMore={loadMore} />}
            columns={columns}
          />
        </Section>
      )}
    </Tile>
  );
};

interface Props {
  guests: Array<GuestProfile>;
}
