import React, { useCallback, useEffect } from 'react';
import { Box, Typography, Container } from '@material-ui/core';
import { CheckInForm, FormStatus, reservationService } from '@lib/state';
import { VerifyReservationCodeForm } from './verification-reservation-code-form';
import { Feedback, FormHub, useFormEvents } from '../../forms';
import { LoadingIndicator } from '../../components';

interface FormFields {
  code: string;
}

interface Props {
  reservationId: string;
  kioskForm?: string;
  failed?: boolean;
  onVerifyCode(code: string): void;
}

export const VerifyReservationCodeComponent: React.FC<Props> = ({
  reservationId,
  failed = false,
  kioskForm,
  onVerifyCode,
}) => {
  const [{ status }] = useFormEvents(CheckInForm.SendCode);

  const sendCode = useCallback(() => reservationService.sendVerificationCode(reservationId), [
    reservationId,
  ]);

  useEffect(() => sendCode(), [sendCode]);

  const onSubmit = useCallback(({ code }: FormFields) => onVerifyCode(code), [onVerifyCode]);

  if ([FormStatus.Initial, FormStatus.Pending].includes(status)) {
    return <LoadingIndicator loadingText="Loading..." fillContainer />;
  }

  if ([FormStatus.Error].includes(status)) {
    return (
      <Container maxWidth="md" className={kioskForm}>
        <Box my={3}>
          <Typography variant="h4" align="center" paragraph>
            Please go to the front desk to complete check-in.
          </Typography>
          <Typography align="center" paragraph>
            An email address or phone number has not been provided for this reservation.
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className={kioskForm}>
      <Box my={3} clone>
        <Typography variant="h4" align="center" paragraph>
          We just sent you a verification code! Please check your email and phone.
        </Typography>
      </Box>
      {failed && (
        <Feedback show severity="error">
          The previously provided verification code is invalid or has expired.
        </Feedback>
      )}
      <FormHub<FormFields> onSubmit={onSubmit}>
        <VerifyReservationCodeForm onResendCode={sendCode} />
      </FormHub>
    </Container>
  );
};
