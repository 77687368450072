import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import { TapeChartPage } from './pages';

export const TapeChartRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={match.path} exact component={TapeChartPage} />
    <Redirect to={match.path} />
  </Switch>
);
