import React from 'react';
import { Box, Button, TextField, TextFieldProps } from '@material-ui/core';

import {
  useForm,
  useInput,
  required,
  FormProps,
  ErrorDisplay,
  SubmitButton,
  email,
} from '../../forms';
import { ButtonLink } from '../link';
import { autoCompleteIfEnabled } from '../../utils';

export interface SignInFormFields {
  username: string;
  password: string;
}

interface Props extends FormProps<SignInFormFields> {
  usernameLabel: string;
  presetUsername?: string;
  onForgotPasswordRedirect?: () => void;
  buttonColor?: 'inherit' | 'primary' | 'secondary' | 'default';

  formVariant?: TextFieldProps['variant'];
  formColor?: TextFieldProps['color'];
}

export const SignInForm: React.FC<Props> = ({
  pending,
  error,
  onSubmit,
  onResetFeedback,
  usernameLabel,
  presetUsername,
  buttonColor,
  formVariant,
  formColor,
  onForgotPasswordRedirect,
  children,
}) => {
  const username = useInput(presetUsername ?? '', { validators: [required(), email()] });
  const password = useInput('', { validators: required() });
  const form = useForm(
    () => {
      onResetFeedback();
      onSubmit({ username: username.value, password: password.value });
    },
    username,
    password
  );

  return (
    <form {...form.bind}>
      <ErrorDisplay error={error}></ErrorDisplay>
      <TextField
        name="username"
        label={usernameLabel}
        value={username}
        variant={formVariant}
        color={formColor}
        {...username.bind}
        autoComplete={autoCompleteIfEnabled('email')}
        type="email"
        fullWidth
        style={{ marginBottom: '15px' }}
        data-testid="formUsernameInput"
      />
      <TextField
        name="password"
        label="Password"
        value={password}
        variant={formVariant}
        color={formColor}
        {...password.bind}
        autoComplete={autoCompleteIfEnabled('password')}
        type="password"
        fullWidth
        style={{ marginBottom: '15px' }}
        data-testid="formPasswordInput"
      />
      <SubmitButton
        variant="contained"
        color={buttonColor}
        fullWidth
        pending={pending}
        disabled={!form.valid || pending}
        style={{ marginBottom: '15px' }}
        data-testid="formLoginButton"
      >
        Login
      </SubmitButton>
      {onForgotPasswordRedirect && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            component="button"
            color="primary"
            onClick={onForgotPasswordRedirect}
            data-testid="formForgotPasswordButton"
          >
            Forgot Password?
          </Button>
        </Box>
      )}
      {children}
    </form>
  );
};
