import React, { useMemo } from 'react';
import { Container } from '@material-ui/core';

import {
  kioskStyles,
  usePageTitle,
  ReservationRouteProps,
  ClaimAccountComponent,
} from '@lib/common';

export const ReservationAccountPage: React.FC<ReservationRouteProps> = ({
  match,
  location,
  history,
}) => {
  const { kioskForm } = kioskStyles();
  const code = useMemo(() => {
    const query = new URLSearchParams(location.search);
    const value = query.get('code');
    return value ? parseInt(value) : 0;
  }, [location.search]);

  const reservationId = match.params['reservationId'];

  const pageTitle = 'Create Account';
  const verifyRoute = `/kiosk/${reservationId}/verify?failed`;
  const claimRoute = `/kiosk/${reservationId}/claim?code=${code}`;

  usePageTitle(pageTitle);

  return (
    <Container maxWidth="md" className={kioskForm}>
      <ClaimAccountComponent
        reservationId={reservationId}
        code={code}
        onVerificationFailed={() => history.replace(verifyRoute)}
        onVerificationSucceeded={() => history.replace(claimRoute)}
      />
    </Container>
  );
};
