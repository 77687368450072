import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { FormStatus } from '@lib/state';
import {
  ErrorDisplay,
  Feedback,
  FormNumberField,
  FormSubmitButton,
  FormTextField,
  Section,
} from '@lib/common';

export interface PropertyNotificationFormFields {
  backOfficeEmail?: string;
  reportEmail?: string;
  maintenanceEmail?: string;
  maintenancePhone?: string;
  monitoringEmail?: string;
  refundAlertEmail?: string;
  guestSurveyEmail?: string;
  guestSurveyThreshold?: number;
  vipReservationNotificationPhone?: string;
}

interface Props {
  status: FormStatus;
  error?: Error;
  resetUI?: () => void;
}

export const PropertyNotificationsForm: React.FC<Props> = ({ status, error, resetUI }) => {
  const emailRegEx = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,10}[\W]*;{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,10})[\W]*$/;

  return (
    <>
      <Section title="Property Notifications">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="backOfficeEmail"
              label="Back Office Email"
              fullWidth
              multiline={true}
              validationOptions={{
                pattern: emailRegEx,
              }}
              helperText={errors =>
                errors.backOfficeEmail?.type === 'pattern'
                  ? 'Please enter a semicolon separated list ex. jdoe@domain.com; jdoe2@domain.com'
                  : 'Will receive the daily ledger report, corporate invoices and OTA transaction errors.'
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="reportEmail"
              label="Sales Report Email"
              fullWidth
              multiline={true}
              validationOptions={{
                pattern: emailRegEx,
              }}
              helperText={errors =>
                errors.reportEmail?.type === 'pattern'
                  ? 'Please enter a semicolon separated list ex. jdoe@domain.com; jdoe2@domain.com'
                  : 'Used for POS daily meals report.'
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="maintenanceEmail"
              label="Maintenance Email"
              fullWidth
              multiline={true}
              validationOptions={{
                pattern: emailRegEx,
              }}
              helperText={errors =>
                errors.maintenanceEmail?.type === 'pattern'
                  ? 'Please enter a semicolon separated list ex. jdoe@domain.com; jdoe2@domain.com'
                  : 'Will receive the maintenance emergency notification and daily report'
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="maintenancePhone"
              label="Maintenance Phone"
              fullWidth
              multiline={true}
              helperText={_ =>
                'Will receive the maintenance emergency SMS. Separate multiple numbers with a semi-colon.'
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="monitoringEmail"
              label="Device Monitoring Email"
              fullWidth
              multiline={true}
              validationOptions={{
                pattern: emailRegEx,
              }}
              helperText={errors =>
                errors.monitoringEmail?.type === 'pattern'
                  ? 'Please enter a semicolon separated list ex. jdoe@domain.com; jdoe2@domain.com'
                  : 'Will receive the device monitoring email that contains information on online/offline devices and devices with low battery levels.'
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="refundAlertEmail"
              label="Refund Alert Email"
              fullWidth
              multiline={true}
              validationOptions={{
                pattern: emailRegEx,
              }}
              helperText={errors =>
                errors.refundAlertEmail?.type === 'pattern'
                  ? 'Please enter a semicolon separated list ex. jdoe@domain.com; jdoe2@domain.com'
                  : 'Will receive an email when a manager code is used, when an out-of-policy refund is made, or when a refund is issued during a change billing.'
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="guestSurveyEmail"
              label="Guest Survey Notification Email"
              fullWidth
              multiline={true}
              validationOptions={{
                pattern: emailRegEx,
              }}
              helperText={errors =>
                errors.backOfficeEmail?.type === 'pattern'
                  ? 'Please enter a semicolon separated list ex. jdoe@domain.com; jdoe2@domain.com'
                  : 'Will receive an email when a guest survey is submitted.'
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="guestSurveyThreshold"
              label="Guest Survey Notification Threshold"
              fullWidth
              validationOptions={{
                min: 1,
                max: 5,
              }}
              helperText={
                'Trigger an email to be sent with a score equal to or less than the threshold.'
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormNumberField
              name="vipReservationNotificationPhone"
              label="VIP Reservation Notification Phone"
              fullWidth
              multiline={true}
              helperText={
                'Will receive notifications when a Platinum VIP guest books or checks in to a reservation'
              }
            />
          </Grid>
        </Grid>
      </Section>
      <Feedback show={status === FormStatus.Success} severity="success" onHide={resetUI}>
        Property notifications saved successfully!
      </Feedback>
      <ErrorDisplay error={error} />
      <Box display="flex" justifyContent="flex-end" my={1}>
        <FormSubmitButton
          type="submit"
          color="secondary"
          variant="contained"
          disabled={status === FormStatus.Pending}
        >
          Save Property Notifications
        </FormSubmitButton>
      </Box>
    </>
  );
};
