import React from 'react';
import { Typography, Container } from '@material-ui/core';

import { accountService, FormStatus, AccountForms } from '@lib/state';
import { useFormEvents, ChangePasswordForm, ChangePasswordFormFields } from '@lib/common';

export const ChangePasswordPage: React.FC = () => {
  const [{ status, error }, resetFormUI] = useFormEvents(AccountForms.ChangePassword);

  const submit = ({ newPassword, currentPassword }: ChangePasswordFormFields) => {
    accountService.changePassword(currentPassword, newPassword);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h5" align="center" color="textSecondary">
        CHANGE PASSWORD
      </Typography>
      <ChangePasswordForm
        pending={status === FormStatus.Pending}
        success={status === FormStatus.Success}
        error={error}
        onSubmit={submit}
        onResetFeedback={resetFormUI}
      />
    </Container>
  );
};
