import { filter } from './filter';
import { KeySelectorFn } from './group-by';
import { map } from './map';

export function* join<TOuter, TInner, TKey>(
  outer: Iterable<TOuter>,
  inner: Iterable<TInner>,
  outerKeySelector: KeySelectorFn<TOuter, TKey>,
  innerKeySelector: KeySelectorFn<TInner, TKey>
): Iterable<[TOuter, TInner]> {
  const lookup = new Map<TKey, Array<TInner>>();
  for (const x of inner) {
    const key = innerKeySelector(x);
    if (lookup.has(key)) lookup.get(key)!.push(x);
    else lookup.set(key, [x]);
  }

  for (const outerItem of filter(outer, x => lookup.has(outerKeySelector(x)))) {
    yield* map(lookup.get(outerKeySelector(outerItem))!, innerItem => [outerItem, innerItem]);
  }
}
