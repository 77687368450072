import React from 'react';
import { useReservation, ReservationRouteProps, LoadingIndicator } from '@lib/common';
import { CheckInRoomSelection } from '../components/check-in-room-selection';
import { useGetCheckInRoomOptions } from '../useGetCheckInRoomOptions';
import { CheckInUnavailable } from '../components';

export const CheckInRoomOptionsPage: React.FC<ReservationRouteProps> = ({ match, history }) => {
  const reservationId = match.params['reservationId'];
  const reservation = useReservation(reservationId);
  const roomOptions = useGetCheckInRoomOptions(reservation);

  const onSubmit = (roomIds?: string[]) => {
    const roomIdsQs = roomIds && roomIds.map(m => `roomIds=${m}`).join('&');
    history.push(`/kiosk/front-desk/${reservationId}/check-in${roomIdsQs ? `?${roomIdsQs}` : ''}`);
  };

  if (reservation && roomOptions) {
    if (roomOptions.length)
      return (
        <CheckInRoomSelection
          roomOptions={roomOptions}
          reservation={reservation}
          onRoomsSelect={roomIds => onSubmit(roomIds)}
        />
      );
    return <CheckInUnavailable />;
  }

  return <LoadingIndicator loadingText="Checking In..." fillContainer />;
};
