import React, { useEffect, ChangeEvent } from 'react';
import { Grid, makeStyles, createStyles } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';

import { FormTextField, TextFieldProps, useFormEvents } from '../../forms';
import { ReservationForms } from '@lib/state';

export interface CardFields {
  cardNumber: string;
  expiryDate: string;
  cvc: string;
}

interface Props {
  optional?: boolean;

  inputProps?: TextFieldProps;
}

const largeIconBodyFontSize = '1rem';

export const useLocalStyle = makeStyles(theme =>
  createStyles({
    svgIcon: {
      display: 'flex',
      alignContent: 'center',
      height: theme.typography.body1.fontSize === largeIconBodyFontSize ? '32px' : '16px',
      width: theme.typography.body1.fontSize === largeIconBodyFontSize ? '48px' : '24px',
      marginTop: '24px',
      marginRight: '8px',
    },
  })
);

export const CreditCardForm: React.FC<Props> = ({ optional = false, inputProps }) => {
  const styles = useLocalStyle();

  const { watch, register, unregister, setValue, triggerValidation } = useFormContext();

  const [_, resetReservationFormStatus] = useFormEvents(ReservationForms.CreateReservation);

  const { card } = watch({ nest: true });

  useEffect(() => {
    register('card.cardNumber');
    register('card.expiryDate');
    register('card.cvc');

    return () => {
      unregister('card.cardNumber');
      unregister('card.expiryDate');
      unregister('card.cvc');
    };
  }, [optional]);

  const {
    meta: { touchedInputs, erroredInputs },
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getCardImageProps,
  } = usePaymentInputs({
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.name, e.target.value);
      resetReservationFormStatus();
    },
  });

  useEffect(() => {
    if (touchedInputs.cardNumber) triggerValidation('card.cardNumber');
    if (touchedInputs.expiryDate) triggerValidation('card.expiryDate');
    if (touchedInputs.cvc) triggerValidation('card.cvc');
  }, [erroredInputs, touchedInputs]);

  return (
    <Grid container spacing={1}>
      <Grid item md={6} xs={12}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item>
            <svg className={styles.svgIcon} {...getCardImageProps({ images })} />
          </Grid>
          <Grid item xs>
            <FormTextField
              {...inputProps}
              name="card.cardNumber"
              label="Card Number"
              value={card?.cardNumber ?? ''}
              helperText={() => touchedInputs.cardNumber && erroredInputs.cardNumber}
              error={touchedInputs.cardNumber && !!erroredInputs.cardNumber}
              inputProps={getCardNumberProps({ name: 'card.cardNumber' })}
              fullWidth
              validationOptions={{
                validate: value => (!!value && !erroredInputs.cardNumber) || optional,
              }}
              data-testid="cardNumberInput"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={3} xs={6}>
        <FormTextField
          {...inputProps}
          name="card.expiryDate"
          label="Exp Date"
          value={card?.expiryDate ?? ''}
          helperText={() => touchedInputs.expiryDate && erroredInputs.expiryDate}
          error={touchedInputs.expiryDate && !!erroredInputs.expiryDate}
          inputProps={getExpiryDateProps({ name: 'card.expiryDate' })}
          fullWidth
          validationOptions={{
            validate: value => (!!value && !erroredInputs.expiryDate) || optional,
          }}
          data-testid="cardExpDateInput"
        />
      </Grid>
      <Grid item xs={3}>
        <FormTextField
          {...inputProps}
          name="card.cvc"
          label="CVC"
          value={card?.cvc ?? ''}
          helperText={() => touchedInputs.cvc && erroredInputs.cvc}
          error={touchedInputs.cvc && !!erroredInputs.cvc}
          inputProps={getCVCProps({ name: 'card.cvc' })}
          fullWidth
          validationOptions={{
            validate: value => (!!value && !erroredInputs.cvc) || optional,
          }}
          data-testid="cardCvcInput"
        />
      </Grid>
    </Grid>
  );
};
