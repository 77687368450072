import { QueryEntity } from '@datorama/akita';
import createInvoiceStatusStore, {
  CreateInvoiceStatusStore,
  CreateInvoiceStatusState,
} from './create-invoice-status.store';

export class CreateInvoiceStatusQuery extends QueryEntity<CreateInvoiceStatusState> {
  constructor(protected store: CreateInvoiceStatusStore) {
    super(store);
  }

  createInvoicesStatus = this.selectAll();
}

const createInvoiceStatusQuery: CreateInvoiceStatusQuery = new CreateInvoiceStatusQuery(
  createInvoiceStatusStore
);
export default createInvoiceStatusQuery;
