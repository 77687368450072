import { isSameDay, isAfter, isBefore, parseISO, isWithinInterval } from 'date-fns';
import { Properties, Reservations, AccessControl } from '../api';

export type DoorAccessControllerStatus = AccessControl.DoorAccessControllerStatusModel;
export type DoorAccessController = AccessControl.RoomThingDetails;
export type Room = Properties.RoomModel &
  Partial<
    Pick<
      Reservations.RoomModel,
      'readyForRent' | 'availableStartDate' | 'availableEndDate' | 'occupancy'
    >
  > & {
    doorControllerStatus?: DoorAccessControllerStatus;
    doorController?: DoorAccessController;
  };

export type HousekeepingStatusEnum = Properties.HousekeepingStatusEnum;
export const HousekeepingStatusEnum = Properties.HousekeepingStatusEnum;

export enum RoomStatusEnum {
  Occupied = 'Occupied',
  Unoccupied = 'Unoccupied',
  PartiallyOccupied = 'Partially Occupied',
}

export const RoomStatusMap = new Map<RoomStatusEnum, string>([
  [RoomStatusEnum.Occupied, 'Occupied'],
  [RoomStatusEnum.Unoccupied, 'Unoccupied'],
  [RoomStatusEnum.PartiallyOccupied, 'Partially Occupied'],
]);

export const HousekeepingStatusMap = new Map<HousekeepingStatusEnum, string>([
  [HousekeepingStatusEnum.FullClean, 'Full Clean'],
  [HousekeepingStatusEnum.NoCleaningRequired, 'No Cleaning Required'],
  [HousekeepingStatusEnum.Checkout, 'Checkout'],
  [HousekeepingStatusEnum.TouchUpClean, 'Touch Up Clean'],
]);

export const ServicePerformedMap = new Map<HousekeepingStatusEnum | undefined, string>([
  [HousekeepingStatusEnum.FullClean, 'Full Clean'],
  // special case that can be selected by housekeeper on the tablet.
  [HousekeepingStatusEnum.NoCleaningRequired, 'Come Back Tomorrow'],
  [HousekeepingStatusEnum.Checkout, 'Checkout'],
  [HousekeepingStatusEnum.TouchUpClean, 'Touch Up Clean'],
  [undefined, 'Come Back Later'],
]);

export function ShowHousekeepingStatus(
  housekeepingStatus: HousekeepingStatusEnum,
  isUpcomingCheckout: boolean
): string {
  if (isUpcomingCheckout && housekeepingStatus === HousekeepingStatusEnum.FullClean)
    return 'Upcoming Checkout - Full Clean';
  if (isUpcomingCheckout && housekeepingStatus === HousekeepingStatusEnum.TouchUpClean)
    return 'Upcoming Checkout - Touchup Clean';
  if (housekeepingStatus === HousekeepingStatusEnum.Checkout)
    return HousekeepingStatusMap.get(housekeepingStatus)!;
  if (isUpcomingCheckout) return 'Upcoming Checkout';
  if (housekeepingStatus !== HousekeepingStatusEnum.NoCleaningRequired)
    return HousekeepingStatusMap.get(housekeepingStatus)!;
  return '';
}

export type RoomUpdate = Partial<
  Pick<Room, 'housekeepingStatus' | 'availableStartDate' | 'availableEndDate'>
>;

export function getRoomAvailabilityPredicate(room: Room) {
  const { availableStartDate, availableEndDate } = room;

  if (!availableStartDate && !availableEndDate) return () => true;

  const startDate = availableStartDate ? parseISO(availableStartDate) : null;
  const endDate = availableEndDate ? parseISO(availableEndDate) : null;

  if (!startDate && endDate)
    return (date: Date) => isBefore(date, endDate) || isSameDay(date, endDate);

  if (startDate && !endDate)
    return (date: Date) => isAfter(date, startDate) || isSameDay(date, startDate);

  return (date: Date) =>
    isWithinInterval(date, {
      start: startDate!,
      end: endDate!,
    });
}
