import { QueryEntity, Order } from '@datorama/akita';
import { switchMap, distinctUntilChanged } from 'rxjs/operators';
import isEqual from 'lodash.isequal';
import { GuestManagementState, guestManagementStore } from './guest-management.store';
import { GuestProfile, sortByName } from '@lib/state';

export class GuestManagementQuery extends QueryEntity<GuestManagementState> {
  loading = this.selectLoading();

  guests = this.selectAll();
  activeGuest = this.selectActive().pipe(distinctUntilChanged<GuestProfile>(isEqual));

  filters = this.select(({ ui }) => ui);

  pagination = this.select(({ pagination }) => pagination);

  searchResults = this.filters.pipe(
    switchMap(ui =>
      this.selectAll({
        filterBy: [
          x =>
            !ui.firstName ||
            (x.name.first != null &&
              x.name.first.toLowerCase().startsWith(ui.firstName.toLowerCase())),
          x =>
            !ui.lastName ||
            (x.name.last != null &&
              x.name.last.toLowerCase().startsWith(ui.lastName.toLowerCase())),
          x =>
            !ui.email ||
            (x.contact.email != null &&
              x.contact.email.toLowerCase().startsWith(ui.email!.toLowerCase())),
          x => !ui.phone || x.contact.phone?.toLowerCase() === ui.phone.toLowerCase(),
          x => (ui.showArchived ? true : !x.archived),
          x => !ui.vipStatus || ui.vipStatus === x.vipStatus,
        ],
        sortBy: (a, b) => sortByName(a.name, b.name),
        sortByOrder: Order.DESC,
      })
    )
  );
}

export const guestManagementQuery = new GuestManagementQuery(guestManagementStore);
