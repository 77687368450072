import React from 'react';
import { Button, DialogContent, DialogActions } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Feedback, ErrorDisplay, FormProps, StyledDialog } from '@lib/common';

export interface LedgerUploadFormFields {
  file?: File;
}

interface Props extends FormProps<LedgerUploadFormFields> {}

export const LedgerUploadForm: React.FC<Props> = ({
  pending,
  success,
  error,
  onSubmit,
  onResetFeedback,
}) => {
  const submit = (files: FileList | null) => {
    if (files && files.length > 0) {
      onSubmit({ file: files[0] });
    }
  };

  return (
    <>
      <StyledDialog open={!!success || !!error} title="Ledger Upload" onClose={onResetFeedback}>
        <ErrorDisplay error={error} />
        <Feedback show={!!success} severity="success">
          Ledger data successfully uploaded!
        </Feedback>
        {!!error && (
          <DialogContent>
            Please check your .csv import file for formatting issues. None of the records were
            imported.
          </DialogContent>
        )}
        <DialogActions>
          <Button type="button" variant="contained" color="primary" onClick={onResetFeedback}>
            Continue
          </Button>
        </DialogActions>
      </StyledDialog>
      <Button
        variant="contained"
        color="primary"
        component="label"
        disabled={pending}
        startIcon={<FontAwesomeIcon icon="cloud-upload-alt" />}
      >
        Upload
        <input
          type="file"
          accept=".csv"
          style={{ display: 'none' }}
          onChange={e => submit(e.target.files)}
        />
      </Button>
    </>
  );
};
