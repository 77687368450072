import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReactToPrint } from 'react-to-print';
import { createAppActionsInterface, AppActions } from '../../shared/actions';

const appActions: AppActions = createAppActionsInterface();

type RenderCallback = () => JSX.Element;

interface PrintButtonProps {
  title?: string;
  componentToPrint: React.MutableRefObject<any> | RenderCallback;
  onBeforePrint?: () => boolean;
  onAfterPrint?: () => void;
  className?: any;
}

export const PrintButton: React.FC<PrintButtonProps> = ({
  title,
  componentToPrint,
  onBeforePrint,
  onAfterPrint,
  className,
}) => {
  const [shouldPrint, setShouldPrint] = useState(false);
  const printRef =
    'current' in componentToPrint ? componentToPrint : React.createRef<HTMLDivElement>();

  const onPrint = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => {
      appActions.onPrint();
    },
    onAfterPrint: () => {
      setShouldPrint(false);
      onAfterPrint?.();
    },
  });

  const handlePrint = () => {
    if (onBeforePrint?.() ?? true) {
      setShouldPrint(true);
    }
  };

  useEffect(() => {
    if (shouldPrint) {
      onPrint?.();
    }
  }, [shouldPrint, onPrint]);

  const componentFactory: RenderCallback | undefined =
    componentToPrint instanceof Function ? componentToPrint : undefined;

  return (
    <>
      {componentFactory ? (
        <InnerPrintButtonWithFactory
          className={className}
          handlePrint={handlePrint}
          title={title}
          factory={componentFactory}
          printRef={printRef}
          shouldPrint={shouldPrint}
        />
      ) : (
        <InnerPrintButton handlePrint={handlePrint} title={title} />
      )}
    </>
  );
};

interface InnerPrintButtonProps {
  handlePrint: () => void;
  title?: string;
  className?: any;
}

const InnerPrintButton: React.FC<InnerPrintButtonProps> = ({ handlePrint, title, className }) => {
  return (
    <Button
      className={className}
      variant="contained"
      color="secondary"
      onClick={() => handlePrint()}
      startIcon={<FontAwesomeIcon icon="print" />}
    >
      {title ?? 'Print'}
    </Button>
  );
};

interface InnerPrintButtonWithFactoryProps extends InnerPrintButtonProps {
  shouldPrint: boolean;
  factory: RenderCallback;
  printRef: React.MutableRefObject<any>;
  className?: any;
}

const InnerPrintButtonWithFactory: React.FC<InnerPrintButtonWithFactoryProps> = ({
  handlePrint,
  title,
  shouldPrint,
  factory,
  printRef,
  className,
}) => {
  const renderPrintComponent = () => {
    return (
      <div style={{ display: 'none' }}>
        <div ref={printRef}>{factory()}</div>
      </div>
    );
  };

  return (
    <>
      <InnerPrintButton handlePrint={handlePrint} title={title} className={className} />
      {shouldPrint && renderPrintComponent()}
    </>
  );
};
