import { EntityStore, EntityState, StoreConfig, ActiveState } from '@datorama/akita';

import { Property } from '../property';
import { RoomType } from './room-type.model';

export interface RoomTypeUIState {
  propertyId?: Property['id'];
}

export interface RoomTypeState
  extends EntityState<RoomType, RoomType['id']>,
    ActiveState<RoomType['id']> {
  ui: RoomTypeUIState;
}

@StoreConfig({ name: 'room-types', resettable: true })
export class RoomTypeStore extends EntityStore<RoomTypeState> {
  constructor() {
    super({ active: null, ui: {} });
  }
}

export const roomTypeStore = new RoomTypeStore();
