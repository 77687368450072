// tslint:disable
/**
 * Access Control
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { ProblemDetails } from '../models';
import { PutMetricsRequest } from '../models';
/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} thingName
     * @param {PutMetricsRequest} [putMetricsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metricsThingNamePut(
      thingName: string,
      putMetricsRequest?: PutMetricsRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'thingName' is not null or undefined
      if (thingName === null || thingName === undefined) {
        throw new RequiredError(
          'thingName',
          'Required parameter thingName was null or undefined when calling metricsThingNamePut.'
        );
      }
      const localVarPath = `/metrics/{thingName}`.replace(
        `{${'thingName'}}`,
        encodeURIComponent(String(thingName))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof putMetricsRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(putMetricsRequest !== undefined ? putMetricsRequest : {})
        : putMetricsRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} thingName
     * @param {PutMetricsRequest} [putMetricsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metricsThingNamePut(
      thingName: string,
      putMetricsRequest?: PutMetricsRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MetricsApiAxiosParamCreator(configuration).metricsThingNamePut(
        thingName,
        putMetricsRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} thingName
     * @param {PutMetricsRequest} [putMetricsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metricsThingNamePut(
      thingName: string,
      putMetricsRequest?: PutMetricsRequest,
      options?: any
    ): AxiosPromise<void> {
      return MetricsApiFp(configuration).metricsThingNamePut(
        thingName,
        putMetricsRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
  /**
   *
   * @param {string} thingName
   * @param {PutMetricsRequest} [putMetricsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetricsApi
   */
  public metricsThingNamePut(
    thingName: string,
    putMetricsRequest?: PutMetricsRequest,
    options?: any
  ) {
    return MetricsApiFp(this.configuration).metricsThingNamePut(
      thingName,
      putMetricsRequest,
      options
    )(this.axios, this.basePath);
  }
}
