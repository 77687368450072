import { Box, createStyles, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import { FormHub, FormSubmitButton } from '../forms';
import { ButtonLink } from './link';
import { BulkCancellationPreview, bulkCancelService } from '@lib/state';

interface Props {
  propertyId: string;
  corporateAccountId: string;
  bulkPreviewItems: BulkCancellationPreview[];
  disabled?: boolean;
}

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      marginLeft: theme.spacing(1),
    },
  })
);

export const BulkCancelFormButtons: React.FC<Props> = ({
  propertyId,
  corporateAccountId,
  bulkPreviewItems,
  disabled,
}) => {
  const styles = useStyles();

  const onSubmit = useCallback(() => {
    bulkCancelService.bulkCancelReservations(
      propertyId,
      corporateAccountId,
      bulkPreviewItems.map(p => p.reservationId)
    );
  }, [propertyId, corporateAccountId, bulkPreviewItems]);

  return (
    <Box display="flex" justifyContent="flex-end" my={2}>
      <FormHub onSubmit={onSubmit}>
        <div>
          <FormSubmitButton
            color="secondary"
            variant="contained"
            className={styles.button}
            disabled={disabled}
          >
            Confirm Cancellation
          </FormSubmitButton>
          <ButtonLink
            to="/profile/stays"
            variant="contained"
            color="secondary"
            className={styles.button}
          >
            Cancel
          </ButtonLink>
        </div>
      </FormHub>
    </Box>
  );
};
