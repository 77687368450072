import React, { useEffect } from 'react';
import { FormSelect, useObservable } from '@lib/common';
import { MenuItem } from '@material-ui/core';
import { GuestNoteType, guestNoteTypeQuery, guestNoteTypesService } from 'app/state';
import { Property } from '@lib/state';
import { ValidationOptions, useFormContext } from 'react-hook-form';

interface Props {
  propertyId?: Property['id'];
  selectedNoteTypeId?: string;
  onSelect?: (type?: GuestNoteType) => void;
  validationOptions?: ValidationOptions;
}

export const GuestNoteTypeSelect: React.FC<Props> = ({
  propertyId,
  selectedNoteTypeId,
  validationOptions,
  onSelect,
}) => {
  const { watch } = useFormContext();
  const noteTypes = useObservable(guestNoteTypeQuery.guestNoteTypes);

  const noteTypeId = watch('noteType');

  useEffect(() => {
    if (onSelect) onSelect(noteTypes.find(f => f.id === noteTypeId));
  }, [noteTypeId, noteTypes, onSelect]);

  useEffect(() => {
    if (propertyId) return guestNoteTypesService.getGuestNoteTypes(propertyId);
  }, [propertyId]);

  return (
    <>
      <FormSelect
        name="noteType"
        label="Type"
        fullWidth
        defaultValue={selectedNoteTypeId}
        validationOptions={validationOptions}
      >
        {noteTypes.map(noteType => (
          <MenuItem key={noteType.id} value={noteType.id}>
            {noteType.label}
          </MenuItem>
        ))}
      </FormSelect>
    </>
  );
};
