import { InventoryAdjustmentDateGroup } from '.';
import { ResettableStore } from '../utils/resettableStore';
import { EntityStore, EntityState, StoreConfig } from '@datorama/akita';

export interface InventoryAdjustmentState {
  inventoryAdjustmentDateGroups: InventoryAdjustmentDateGroup[];
}

@StoreConfig({ name: 'inventory-adjustment', resettable: true })
export class InventoryAdjustmentStore extends ResettableStore<InventoryAdjustmentState> {
  constructor() {
    super({
      inventoryAdjustmentDateGroups: [],
    });
  }
}

export const inventoryAdjustmentStore = new InventoryAdjustmentStore();
