import React, { useCallback, useEffect } from 'react';
import { CorporateAccount, roomZoneQuery, roomZoneService, sessionQuery } from '@lib/state';
import { Section, FormHub, useObservable, useFormEvents } from '@lib/common';
import { CorporateAccountForms, corporateAccountQuery, corporateAccountService } from 'app/state';
import {
  CorporateZonePreferenceForm,
  ZonePreferencesFormFields,
} from './corporate-zone-preferences-form';

interface Props {
  account: CorporateAccount;
}

export const CorporateZonePreferenceComponent: React.FC<Props> = ({ account }) => {
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const roomZones = useObservable(roomZoneQuery.roomZones, 'async');
  const zonePreferences = useObservable(corporateAccountQuery.activeAccountZones);
  const [{ status, error }] = useFormEvents(CorporateAccountForms.UpdateZonePreferences);

  useEffect(() => {
    if (propertyId) corporateAccountService.getZonePreferences(account, propertyId);
    // eslint-disable-next-line
  }, [account?.id, propertyId]);

  useEffect(() => {
    if (propertyId) roomZoneService.getPropertyRoomZones(propertyId);
  }, [propertyId]);

  const submit = useCallback(
    (fields: ZonePreferencesFormFields) => {
      corporateAccountService.setZonePreferences(
        account,
        propertyId!,
        fields.preferences
          .filter(f => !!f)
          .map((x, i) => ({
            zoneId: x,
            sequence: i,
          }))
      );
    },
    [account, propertyId]
  );

  if (!propertyId || !roomZones || !zonePreferences) return null;

  return (
    <Section title="Zone Preferences" textAlign="left">
      <FormHub onSubmit={submit}>
        <CorporateZonePreferenceForm
          zones={roomZones}
          zonePreferences={zonePreferences}
          status={status}
          error={error}
        />
      </FormHub>
    </Section>
  );
};
