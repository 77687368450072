// tslint:disable
/**
 * Access Control
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { RoomThingDetails } from '../models';
import { ThingUnlockRequest } from '../models';
/**
 * RoomThingApi - axios parameter creator
 * @export
 */
export const RoomThingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} roomNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomthingPropertyIdDeviceRoomNumberGet(
      propertyId: string,
      roomNumber: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling roomthingPropertyIdDeviceRoomNumberGet.'
        );
      }
      // verify required parameter 'roomNumber' is not null or undefined
      if (roomNumber === null || roomNumber === undefined) {
        throw new RequiredError(
          'roomNumber',
          'Required parameter roomNumber was null or undefined when calling roomthingPropertyIdDeviceRoomNumberGet.'
        );
      }
      const localVarPath = `/roomthing/{propertyId}/device/{roomNumber}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'roomNumber'}}`, encodeURIComponent(String(roomNumber)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ThingUnlockRequest} [thingUnlockRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomthingUnlockDoorPatch(
      thingUnlockRequest?: ThingUnlockRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/roomthing/unlock-door`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof thingUnlockRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(thingUnlockRequest !== undefined ? thingUnlockRequest : {})
        : thingUnlockRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RoomThingApi - functional programming interface
 * @export
 */
export const RoomThingApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} roomNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomthingPropertyIdDeviceRoomNumberGet(
      propertyId: string,
      roomNumber: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomThingDetails> {
      const localVarAxiosArgs = RoomThingApiAxiosParamCreator(
        configuration
      ).roomthingPropertyIdDeviceRoomNumberGet(propertyId, roomNumber, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {ThingUnlockRequest} [thingUnlockRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomthingUnlockDoorPatch(
      thingUnlockRequest?: ThingUnlockRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = RoomThingApiAxiosParamCreator(
        configuration
      ).roomthingUnlockDoorPatch(thingUnlockRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * RoomThingApi - factory interface
 * @export
 */
export const RoomThingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} roomNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomthingPropertyIdDeviceRoomNumberGet(
      propertyId: string,
      roomNumber: string,
      options?: any
    ): AxiosPromise<RoomThingDetails> {
      return RoomThingApiFp(configuration).roomthingPropertyIdDeviceRoomNumberGet(
        propertyId,
        roomNumber,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {ThingUnlockRequest} [thingUnlockRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomthingUnlockDoorPatch(
      thingUnlockRequest?: ThingUnlockRequest,
      options?: any
    ): AxiosPromise<void> {
      return RoomThingApiFp(configuration).roomthingUnlockDoorPatch(thingUnlockRequest, options)(
        axios,
        basePath
      );
    },
  };
};

/**
 * RoomThingApi - object-oriented interface
 * @export
 * @class RoomThingApi
 * @extends {BaseAPI}
 */
export class RoomThingApi extends BaseAPI {
  /**
   *
   * @param {string} propertyId
   * @param {string} roomNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomThingApi
   */
  public roomthingPropertyIdDeviceRoomNumberGet(
    propertyId: string,
    roomNumber: string,
    options?: any
  ) {
    return RoomThingApiFp(this.configuration).roomthingPropertyIdDeviceRoomNumberGet(
      propertyId,
      roomNumber,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {ThingUnlockRequest} [thingUnlockRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomThingApi
   */
  public roomthingUnlockDoorPatch(thingUnlockRequest?: ThingUnlockRequest, options?: any) {
    return RoomThingApiFp(this.configuration).roomthingUnlockDoorPatch(thingUnlockRequest, options)(
      this.axios,
      this.basePath
    );
  }
}
