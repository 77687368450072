import { QRCodeFormFeilds, QRCodeForms, Room, qrCodeService } from '@lib/state';
import { DialogContent } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { QRCodeForm } from './qr-code-form';
import { StyledDialog, useFormEvents } from '@lib/common';

interface Props {
  propertyId: string;
  rooms: Array<Room>;
  open: boolean;
  onClose: () => void;
}

export function CreateQRCodeModal({ propertyId, rooms, open, onClose }: Props) {
  const [{ status, error }, reset] = useFormEvents(QRCodeForms.CreateQRCode);

  useEffect(() => {
    if (status === 'success') onClose();
  }, [status, onClose]);

  const onSubmit = useCallback(
    (code: QRCodeFormFeilds) => {
      reset();
      qrCodeService.createQRCode(propertyId, code);
    },
    [reset, propertyId]
  );

  return (
    <StyledDialog title="Create QR Code" open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent>
        <QRCodeForm
          propertyId={propertyId}
          rooms={rooms}
          onSubmit={onSubmit}
          formStatus={status}
          error={error}
        />
      </DialogContent>
    </StyledDialog>
  );
}
