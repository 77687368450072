import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArmKeyGuest, ArmPrintKeysRequest } from '..';

import { AccessControl } from '../api';
import { entryCodesStore, EntryCodesStore } from './entry-codes.store';
export class EntryCodesService {
  constructor(private store: EntryCodesStore, private entryCodesApi: AccessControl.EntryCodeApi) {}

  getEntryCodes(): void {
    this.store.reset();

    from(this.entryCodesApi.entrycodeUserGet())
      .pipe(map(response => response.data.data))
      .subscribe(data => {
        this.store.set(data);
      });
  }

  getEntryCodesAndPrintQrCode(
    propertyId: string,
    company: string | undefined | null,
    reservationId: string,
    guests: ArmKeyGuest[],
    printQRCode: (keysRequest: ArmPrintKeysRequest) => void
  ): void {
    // we only have one guest
    if (guests.length === 1) {
      const guest = guests[0];
      from(this.entryCodesApi.entrycodePropertyIdUserGet(propertyId, guest.userId))
        .pipe(map(response => response.data))
        .subscribe(data => {
          const printRequest: ArmPrintKeysRequest = {
            keys: [
              {
                key: JSON.stringify({
                  user: data.userKey,
                  keys: data.data.map(code => code.key),
                }),
                guestName: guest.guestName,
              },
            ],
            company: company,
          };
          printQRCode(printRequest);
        });
    } else {
      // we want to get all guests on a reservation
      from(
        this.entryCodesApi.entrycodePropertyIdReservationsReservationIdGet(
          propertyId,
          reservationId
        )
      )
        .pipe(map(response => response.data))
        .subscribe(data => {
          const printRequest: ArmPrintKeysRequest = {
            keys: data.data.map(user => ({
              key: JSON.stringify({
                user: user.userKey,
                keys: user.entryCodes.map(code => code.key),
              }),
              guestName: guests.find(guest => guest.userId == user.userId)?.guestName,
            })),
            company: company,
          };
          printQRCode(printRequest);
        });
    }
  }
}

export const entryCodesService = new EntryCodesService(
  entryCodesStore,
  new AccessControl.EntryCodeApi()
);
