import { FormStore, formStore } from './form.store';
import { FormStatus } from './form.models';

export class FormService {
  constructor(private readonly formStore: FormStore) {}

  setPending(formId: string) {
    this.formStore.upsert(formId, {
      formId,
      status: FormStatus.Pending,
      submitted: true,
      error: undefined,
    });
  }

  setSuccess(formId: string) {
    this.formStore.upsert(formId, {
      formId,
      status: FormStatus.Success,
      submitted: true,
      error: undefined,
    });
  }

  setError(formId: string, error: Error) {
    this.formStore.upsert(formId, {
      formId,
      error,
      status: FormStatus.Error,
      submitted: true,
    });
  }

  resetForm(formId: string) {
    this.formStore.upsert(formId, {
      formId,
      status: FormStatus.Initial,
      submitted: false,
      error: undefined,
    });
  }
}

export const formService = new FormService(formStore);
