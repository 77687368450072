import React, { useCallback, memo, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { isSameDay, isAfter, isBefore } from 'date-fns';
import clsx from 'clsx';

import { AppColors } from '@lib/common';
import { getTimelineDays, TimelineProps } from './useTimeline';
import { TimelineColumn } from './timeline-column';
import { Room, getRoomAvailabilityPredicate } from '@lib/state';

const useStyles = makeStyles({
  column: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderRight: `1px solid ${AppColors.RowDivider}`,
  },
  unavailable: {
    backgroundColor: `${AppColors.TransparentRed}`,
  },
});

enum RoomStatus {
  CheckIn = 'CheckIn',
  CheckOut = 'CheckOut',
  Occupied = 'Occupied',
  Empty = 'Empty',
}

interface RoomScheduleProps extends TimelineProps {
  room: Room;
  oddRow?: boolean;
  checkInDate?: Date;
  checkOutDate?: Date;
}

export const RoomSchedule: React.FC<RoomScheduleProps> = memo(
  ({ startDate, view, oddRow, room, checkInDate, checkOutDate }) => {
    const styles = useStyles();

    const getStatus = useCallback(
      (date: Date) => {
        if (checkInDate === undefined || checkOutDate === undefined) return RoomStatus.Empty;

        if (isSameDay(date, checkInDate)) return RoomStatus.CheckIn;
        if (isSameDay(date, checkOutDate)) return RoomStatus.CheckOut;

        if (isAfter(date, checkInDate) && isBefore(date, checkOutDate)) {
          return RoomStatus.Occupied;
        }

        return RoomStatus.Empty;
      },
      [checkInDate, checkOutDate]
    );

    const dates = useMemo(() => {
      const isRoomAvailable = getRoomAvailabilityPredicate(room);

      return getTimelineDays(startDate, view).map(date => ({
        date,
        key: date.valueOf(),
        status: getStatus(date),
        available: isRoomAvailable(date),
      }));
    }, [startDate, view, room, getStatus]);

    return (
      <TimelineColumn>
        {dates.map(({ key, status, available }) => (
          <div
            className={clsx(styles.column, {
              [styles.unavailable]: !available,
            })}
            key={key}
          >
            <ScheduleIndicator oddRow={oddRow} status={status} />
          </div>
        ))}
      </TimelineColumn>
    );
  }
);

const useScheduleStyles = makeStyles({
  root: {
    height: '100%',
  },

  checkIn: {
    background: `linear-gradient(to top right, transparent 49%, ${AppColors.DarkGreen} 50%)`,

    '&$oddRow': {
      background: `linear-gradient(to top right, transparent 49%, ${AppColors.Green} 50%)`,
    },
  },
  occupied: {
    backgroundColor: AppColors.DarkGreen,

    '&$oddRow': {
      background: AppColors.Green,
    },
  },
  checkOut: {
    background: `linear-gradient(to top right, ${AppColors.DarkGreen} 50%, transparent 51%)`,

    '&$oddRow': {
      background: `linear-gradient(to top right, ${AppColors.Green} 50%, transparent 51%)`,
    },
  },

  oddRow: {},
});

interface ScheduleIndicatorProps {
  oddRow?: boolean;
  status: RoomStatus;
}

const ScheduleIndicator: React.FC<ScheduleIndicatorProps> = ({ status, oddRow }) => {
  const { root, checkIn, occupied, checkOut, oddRow: oddRowClassName } = useScheduleStyles();
  return (
    <div
      className={clsx(root, {
        [oddRowClassName]: oddRow,
        [checkIn]: status === RoomStatus.CheckIn,
        [occupied]: status === RoomStatus.Occupied,
        [checkOut]: status === RoomStatus.CheckOut,
      })}
    />
  );
};
