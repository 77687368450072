import { QueryEntity, SortBy } from '@datorama/akita';
import isEqual from 'lodash.isequal';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { GuestLookupFields, GuestLookupProfile } from './guest-lookup.model';
import { GuestLookupState, guestLookupStore } from './guest-lookup.store';

export class GuestLookupQuery extends QueryEntity<GuestLookupState> {
  loading = this.selectLoading();

  matches = this.select('ui').pipe(
    distinctUntilChanged(isEqual),
    switchMap(ui =>
      this.selectAll({
        filterBy: getFilters(ui),
        sortBy: getSort(),
        limitTo: 5,
      })
    )
  );
}

export const guestLookupQuery = new GuestLookupQuery(guestLookupStore);

function getFilters(fields: GuestLookupFields): Array<(profile: GuestLookupProfile) => boolean> {
  const email = getFilter(fields.contact?.email);
  const phone = getFilter(fields.contact?.phone);
  const firstName = getFilter(fields.name?.first);
  const lastName = getFilter(fields.name?.last);
  const employeeNumber = getFilter(fields.employeeNumber);

  return [
    p => employeeNumber(p.employeeNumber),
    p => email(p.contact?.email),
    p => phone(p.contact?.phone),
    p => lastName(p.name?.last),
    p => firstName(p.name?.first),
  ];
}

function getFilter(filter?: string | null) {
  if (!filter) return () => true;

  const normalized = normalize(filter);

  return (value?: string | null) => {
    if (!value) return false;

    return normalize(value).startsWith(normalized);
  };
}

function normalize(value: string) {
  return value.toLocaleLowerCase().replaceAll(/[^a-z0-9@\.]/gi, '');
}

function getSort(): SortBy<GuestLookupProfile> {
  return (a, b) => compare(a.name?.last, b.name?.last) || compare(a.name?.first, b.name?.first);
}

function compare(a?: string | null, b?: string | null) {
  return (a ?? '').localeCompare(b ?? '');
}
