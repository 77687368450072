import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, DialogContent, Typography, Button } from '@material-ui/core';

import { FormStatus, Reservation, ReservationForms, reservationService } from '@lib/state';
import { BillingFields, PaymentRequiredForm, StyledDialog, LoadingIndicator } from '../components';
import { useFormEvents } from '../forms';
import { getAcceptCardData, useReservationPaymentMethods } from '../utils';
import { ReservationFeeList } from './reservation-fee-list';

interface Props {
  reservation: Reservation;
  noSave?: boolean;
  overridable?: boolean;
  onClose: () => void;
}

export const PurchaseLateCheckOutModal: React.FC<Props> = ({
  reservation,
  noSave,
  overridable,
  onClose,
}) => {
  const [{ status, error }] = useFormEvents(ReservationForms.LateCheckOut);

  const reservationId = reservation.id;

  useEffect(() => {
    reservationService.getReservationById(reservationId);
    reservationService.getLateCheckOutFee(reservationId);
  }, [reservationId]);

  const { paymentMethods, guest } = useReservationPaymentMethods(reservation);

  const noFees = () => {
    return reservation.lateCheckOutFee && reservation.lateCheckOutFee.length === 0;
  };

  const confirmationText = () => {
    if (noFees()) return 'No fee required. Late check out applied.';

    return 'Late check out applied.';
  };

  const onSubmit = useCallback(
    ({
      billing,
      savePaymentMethod = false,
      paymentProfileId,
      card,
      overrideCode,
    }: BillingFields) => {
      reservationService.purchaseLateCheckOut(
        reservation,
        billing,
        !noSave && savePaymentMethod,
        paymentProfileId,
        getAcceptCardData(card),
        overrideCode
      );
    },
    [reservation, noSave]
  );

  useEffect(() => {
    if (noFees() && !reservation.rooms.every(x => x.hasLateCheckOut)) {
      onSubmit({});
    }
    // if dependency is reservation instead
    // of reservation.id, the call to purchase late check out
    // happens 4 times
    // eslint-disable-next-line
  }, [reservationId, reservation.lateCheckOutFee, reservation.rooms, onSubmit]);

  return (
    <StyledDialog title="Purchase Late Check Out" open onClose={onClose}>
      <DialogContent>
        {[FormStatus.Initial, FormStatus.Error].includes(status) &&
          reservation.lateCheckOutFee &&
          !noFees() && (
            <PaymentRequiredForm
              title={null}
              submitLabel="Purchase"
              status={status}
              error={error}
              guest={guest}
              paymentMethods={paymentMethods}
              onSubmit={onSubmit}
              onCancel={onClose}
              noSave={noSave}
              overridable={overridable}
              paymentMethodOptional={overridable}
            >
              <ReservationFeeList charges={reservation.lateCheckOutFee} />
            </PaymentRequiredForm>
          )}
        {status === FormStatus.Success && (
          <Box p={1} fontSize="125%" textAlign="center">
            <Typography>{confirmationText()}</Typography>
            <Button color="secondary" variant="contained" onClick={onClose}>
              Close
            </Button>
          </Box>
        )}
        {status === FormStatus.Pending && <LoadingIndicator />}
      </DialogContent>
    </StyledDialog>
  );
};
