import { applyTransaction } from '@datorama/akita';
import { dispatchForm, Properties, Property } from '@lib/state';
import { NoteTypeStatus } from '@lib/state/api/generated/properties';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { GuestNotesForms } from './guest-notes.forms';
import {
  GuestNoteFilters,
  GuestNoteStore,
  guestNoteStore,
  GuestNoteUIState,
} from './guest-notes.store';

export class GuestNotesService {
  constructor(private store: GuestNoteStore, private guestNotesApi: Properties.GuestNotesApi) {}

  getGuestNotes(propertyId: Property['id'], userId: string, guestStayId?: string) {
    from(this.guestNotesApi.propertyIdGuestnotesGet(propertyId, userId, guestStayId))
      .pipe(map(({ data }) => data.data))
      .subscribe(guestNotes =>
        applyTransaction(() => {
          this.store.reset();
          this.store.add(guestNotes);
          this.updateUI({
            propertyId: propertyId,
          });
        })
      );
  }

  getGuestNoteById(guestNoteId: string, propertyId: Property['id']) {
    from(this.guestNotesApi.propertyIdGuestnotesIdGet(propertyId, guestNoteId))
      .pipe(map(({ data }) => data.data))
      .subscribe(guestNote =>
        applyTransaction(() => {
          this.store.reset();
          this.store.add(guestNote);
          this.updateUI({
            propertyId: propertyId,
          });
        })
      );
  }

  createGuestNote(
    propertyId: Property['id'],
    userId: string,
    note: string,
    noteType: string,
    sendNotification: boolean,
    guestStayId?: string
  ) {
    from(
      this.guestNotesApi.propertyIdGuestnotesPost(propertyId, {
        userId: userId,
        noteTypeId: noteType,
        guestStayId: guestStayId,
        note: note,
        status: Properties.NoteTypeStatus.Created,
        sendNotification: sendNotification,
      })
    )
      .pipe(
        map(({ data }) => data.data),
        dispatchForm(GuestNotesForms.Create)
      )
      .subscribe(x => this.store.upsertMany([x]));
  }

  updateGuestNote(
    propertyId: Property['id'],
    guestNoteId: string,
    note: string,
    noteTypeId: string
  ) {
    from(
      this.guestNotesApi.propertyIdGuestnotesIdPut(propertyId, guestNoteId, {
        note: note,
        noteTypeId: noteTypeId,
        status: NoteTypeStatus.Updated,
      })
    )
      .pipe(
        map(({ data }) => data.data),
        dispatchForm(GuestNotesForms.Update)
      )
      .subscribe(x => this.store.upsertMany([x]));
  }

  removeGuestNote(propertyId: Property['id'], guestNoteId: string) {
    from(this.guestNotesApi.propertyIdGuestnotesIdDelete(propertyId, guestNoteId))
      .pipe(
        map(({ data }) => data.data),
        dispatchForm(GuestNotesForms.Remove)
      )
      .subscribe(() => this.store.remove(guestNoteId));
  }

  updateFilters(filters: GuestNoteFilters) {
    this.updateUI({ filters });
  }

  private updateUI(values: Partial<GuestNoteUIState>) {
    this.store.update(({ ui }) => ({
      ui: {
        ...ui,
        ...values,
      },
    }));
  }
}

export const guestNotesService = new GuestNotesService(
  guestNoteStore,
  new Properties.GuestNotesApi()
);
