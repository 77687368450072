import React, { useEffect } from 'react';
import { Container, createStyles, Grid, makeStyles } from '@material-ui/core';
import { useObservable, Section, usePageTitle, ButtonLink } from '@lib/common';
import { sessionQuery, roomTypeQuery, roomTypeService } from '@lib/state';
import { RoomTypeList } from '../components';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      paddingTop: theme.spacing(2),
    },
    newPlanButtonContainer: {
      marginLeft: 'auto',
    },
  })
);

export const RoomTypeListPage: React.FC = () => {
  const styles = useStyles();
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const roomTypes = useObservable(roomTypeQuery.propertyRoomTypes);

  usePageTitle('Room Types');

  useEffect(() => {
    if (propertyId) roomTypeService.getPropertyRoomTypes(propertyId);
  }, [propertyId]);

  if (!propertyId) return null;

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} className={styles.container}>
        <Grid className={styles.newPlanButtonContainer} item>
          <ButtonLink to="/room-types/add" variant="contained" color="secondary">
            New Room Type
          </ButtonLink>
        </Grid>
      </Grid>
      <Section title="Room Types" textAlign="left" disableGutters>
        <RoomTypeList roomTypes={roomTypes} />
      </Section>
    </Container>
  );
};
