import React from 'react';
import { ErrorDisplay, Link } from '@lib/common';

export const UnauthorizedPage: React.FC = () => {
  return (
    <ErrorDisplay error={{ name: 'auth', message: 'You are not authorized to access this page.' }}>
      Unauthorized Access &bull; <Link to="account/signout">Sign Out</Link>
    </ErrorDisplay>
  );
};
