import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';

import { CorporateAccount, FormStatus } from '@lib/state';
import { ErrorDisplay, Feedback, FormHub, Section, SubmitButton, WebOnly } from '@lib/common';
import { CostCenterFieldConfiguration } from 'app/state';
import { CorporateAccountInformationForm } from './corporate-account-info';
import { CorporateEmployees } from './corporate-employees';
import { BillingFrequencyType } from '@lib/state/api/generated/reservations';

export interface CorporateAccountFormFields {
  name: string;
  invoiceSubAccount?: string;
  reportRecipients?: string;
  bookingRecipients?: string;
  automaticNoShowDays?: number;
  automaticNoShowTime?: string;
  sendDailyHousingReport?: boolean;
  isInvoiceEnabled?: boolean;
  fields?: Array<CostCenterFieldConfiguration>;
  isInvoiceGroupedByLocation?: boolean;
  isInvoiceGroupedByCostCenter1?: boolean;
  isInvoiceGroupedByCostCenter2?: boolean;
  billingFrequencyType?: BillingFrequencyType;
}

interface Props {
  account?: CorporateAccount;
  status: FormStatus;
  error?: Error;
  resetFormUI: () => void;
  onSubmit: (values: CorporateAccountFormFields) => void;
}

export const CorporateAccountForm: React.FC<Props> = ({
  account,
  status,
  error,
  resetFormUI,
  onSubmit,
}) => {
  const defaultValues = useMemo(
    () => ({
      name: account?.name ?? '',
      invoiceSubAccount: account?.invoiceSubAccount ?? '',
    }),
    [account]
  );

  return (
    <Section title={`${account ? 'Edit' : 'Create'} Corporate Account`} textAlign="left">
      {account && (
        <WebOnly>
          <Box display="flex" justifyContent="flex-end">
            <CorporateEmployees account={account} />
          </Box>
        </WebOnly>
      )}
      <FormHub onSubmit={onSubmit} formOptions={{ defaultValues }}>
        <CorporateAccountInformationForm account={account} />
        <Box py={1}>
          <Feedback
            show={status === FormStatus.Success}
            severity="success"
            onHide={resetFormUI}
            data-testid="corporateAccountSavedNotification"
          >
            Corporate Account {account ? 'saved' : 'created'} successfully!
          </Feedback>
          <ErrorDisplay error={error} />
        </Box>
        <Box display="flex" justifyContent="flex-end" pb={2}>
          <SubmitButton
            name="createCorporateAccount"
            variant="contained"
            color="secondary"
            pending={status === FormStatus.Pending}
            disabled={status === FormStatus.Pending}
            data-testid="corporateAccountSubmit"
          >
            {account ? 'Save' : 'Create'} Corporate Account
          </SubmitButton>
        </Box>
      </FormHub>
    </Section>
  );
};
