import React, { useMemo } from 'react';

import { Section, ReservationRouteProps, ResponsiveContainer, useReservation } from '@lib/common';
import { BreadcrumbBackButton } from 'app/shared';
import { ReservationDetails } from '../components';

export const ReservationDetailsPage: React.FC<ReservationRouteProps> = ({ location, match }) => {
  const reservationId = match.params.reservationId;
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const guestId = params.get('guestId') ?? undefined;
  const reservation = useReservation(reservationId);

  const backUrl = useMemo(() => {
    if (guestId) return `/guests/${guestId}`;
    return `/reservations`;
  }, [guestId]);

  return (
    <ResponsiveContainer>
      <Section>
        <BreadcrumbBackButton destination={backUrl} />
        <ReservationDetails reservation={reservation} createForGuest={true} />
      </Section>
    </ResponsiveContainer>
  );
};
