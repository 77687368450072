import React from 'react';
import { createTheme } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { AppColors } from './theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const managementPortalTheme = createTheme({
  palette: {
    background: {
      default: common.white,
      paper: common.white,
    },
    primary: {
      main: AppColors.Blue,
      dark: AppColors.DarkBlue,
      contrastText: common.white,
    },
    secondary: {
      main: AppColors.LightBlue,
      contrastText: common.white,
    },
    divider: AppColors.RowDivider,
    grey: {
      '300': AppColors.LightGray,
      '500': AppColors.Gray,
      '800': AppColors.DarkGray,
    },
    text: {
      primary: AppColors.DarkGray,
      secondary: AppColors.MediumGray,
    },
    action: {
      disabled: AppColors.MediumGray,
      disabledBackground: AppColors.LightGray,
    },
  },
  tapeChart: {
    headerSpacingTop: '2rem',
    rowHeight: 48,
  },
  loadingIndicator: {
    dotSize: 10,
  },
  shape: {
    borderRadius: 0,
  },
  mixins: {
    toolbar: {
      minHeight: 56,
    },
  },
  typography: {
    body1: {
      fontSize: '0.875rem', // 14px
      fontWeight: 500,
    },
    body2: {
      fontSize: '0.75rem', // 12px
      fontWeight: 500,
    },
    button: {
      fontWeight: 700,
    },
    h1: {
      fontSize: '2.25rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    h6: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 600,
    },
    subtitle2: {
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1299,
    drawer: 1298,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  props: {
    MuiAlert: {
      iconMapping: {
        success: <FontAwesomeIcon icon="check-circle" />,
        info: <FontAwesomeIcon icon="info-circle" />,
        warning: <FontAwesomeIcon icon="exclamation-circle" />,
        error: <FontAwesomeIcon icon="times-circle" />,
      },
    },
  },
  overrides: {
    MuiToolbar: {
      dense: {
        minHeight: 56,
      },
    },
    MuiButton: {
      root: {
        borderRadius: '6px',
      },
    },
    MuiOutlinedInput: {
      root: {
        color: common.white,
        border: `1px solid ${common.white}`,
        borderRadius: '6px',
        '&::placeholder': {
          color: common.white,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        color: common.white,
      },
    },
    MuiSelect: {
      outlined: {
        color: common.white,
        textAlign: 'left',
      },
    },
  },
});
