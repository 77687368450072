import { Reservations } from '../api';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import corporateAccountStore, { CorporateAccountStore } from './corporate-account.store';

export class CorporateAccountService {
  constructor(
    private readonly corporateAccountApi: Reservations.CorporateAccountApi,
    private readonly store: CorporateAccountStore
  ) {}

  getMyAccounts() {
    from(this.corporateAccountApi.corporateaccountMyAccountsGet())
      .pipe(map(data => data.data))
      .subscribe(data => {
        this.store.upsertMany(data);
      });
  }
}
export const corporateAccountService = new CorporateAccountService(
  new Reservations.CorporateAccountApi(),
  corporateAccountStore
);
