import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    titleBackground: {
      padding: '8px 16px',
      backgroundColor: theme.palette.primary.dark,
    },
    title: {
      color: theme.palette.primary.contrastText,
    },
    closeButton: {
      position: 'absolute',
      right: '1px',
      top: '-5px',
    },
    closeIcon: {
      fontSize: '1.75rem',
      color: theme.palette.common.white,
    },
  })
);

type Props = {
  title: string;
  onClose: () => void;
} & DialogProps;

export const StyledDialog: React.FC<Props> = ({ title, onClose, children, ...props }) => {
  const styles = useStyles();

  return (
    <Dialog onClose={onClose} fullWidth maxWidth="sm" {...props} data-testid="dialog">
      <IconButton onClick={onClose} className={styles.closeButton} data-testid="dialogCloseButton">
        <FontAwesomeIcon icon="times-circle" className={styles.closeIcon} />
      </IconButton>
      <DialogTitle className={styles.titleBackground} disableTypography>
        <Typography className={styles.title} variant="h5" align="center">
          {title}
        </Typography>
      </DialogTitle>
      {children}
    </Dialog>
  );
};
