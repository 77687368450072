import React from 'react';
import { MenuItem } from '@material-ui/core';

import { FormSelect } from '../form-select';
import { FormTextFieldProps, TextFieldProps } from '../form-text-field';

const options = [
  { value: 'America/Anchorage', label: 'America/Anchorage (US/Alaska)' },
  { value: 'America/Chicago', label: 'America/Chicago (US/Central)' },
  { value: 'America/Denver', label: 'America/Denver (US/Mountain)' },
  { value: 'America/Detroit', label: 'America/Detroit (US/Michigan)' },
  { value: 'America/Los_Angeles', label: 'America/Los_Angeles (US/Pacific)' },
  { value: 'America/New_York', label: 'America/New_York (US/Eastern)' },
  { value: 'America/Phoenix', label: 'America/Phoenix (US/Arizona)' },
];

type PassThroughProps = Omit<FormTextFieldProps, 'type'> & TextFieldProps;
type Props = { required?: boolean } & PassThroughProps;

export const FormTimezoneField: React.FC<Props> = ({
  label = 'Timezone',
  defaultValue = '',
  required,
  validationOptions,
  ...props
}) => {
  return (
    <FormSelect
      {...props}
      label={label}
      defaultValue={defaultValue}
      validationOptions={{
        required,
        validate: (value: any) => options.some(x => x.value === value) || !!value?.value,
        ...validationOptions,
      }}
      data-testid="formTimezoneInput"
    >
      {options.map(x => (
        <MenuItem key={x.value} value={x.value}>
          {x.label}
        </MenuItem>
      ))}
    </FormSelect>
  );
};
