// tslint:disable
/**
 * Point of Sale
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { ProblemDetails } from '../models';
import { ProductModel } from '../models';
import { TerminalType } from '../models';
/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {TerminalType} type
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsTypeGet(type: TerminalType, propertyId?: string, options: any = {}): RequestArgs {
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError(
          'type',
          'Required parameter type was null or undefined when calling productsTypeGet.'
        );
      }
      const localVarPath = `/products/{type}`.replace(
        `{${'type'}}`,
        encodeURIComponent(String(type))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {TerminalType} type
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsTypeGet(
      type: TerminalType,
      propertyId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductModel>> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).productsTypeGet(
        type,
        propertyId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {TerminalType} type
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productsTypeGet(
      type: TerminalType,
      propertyId?: string,
      options?: any
    ): AxiosPromise<Array<ProductModel>> {
      return ProductsApiFp(configuration).productsTypeGet(
        type,
        propertyId,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
  /**
   *
   * @param {TerminalType} type
   * @param {string} [propertyId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public productsTypeGet(type: TerminalType, propertyId?: string, options?: any) {
    return ProductsApiFp(this.configuration).productsTypeGet(
      type,
      propertyId,
      options
    )(this.axios, this.basePath);
  }
}
