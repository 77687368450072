import React, { useState, useEffect } from 'react';
import {
  Button,
  makeStyles,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { WebOnly, useFormEvents } from '@lib/common';
import { CommonForm, FormStatus } from '@lib/state';

const useStyles = makeStyles(() => ({
  download: {
    textDecoration: 'none !important',
  },
}));

interface DownloadButtonProps {
  onDownload?: () => void;
  title?: string;
  className?: string;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  title = 'Download',
  onDownload,
  className,
}) => {
  const { download } = useStyles();
  const [{ status, error }] = useFormEvents(CommonForm.Export);
  const [openError, setOpenError] = useState(false);
  const [initial, setInitial] = useState(true);
  const [pending, setPending] = useState(false);

  const handleCloseError = () => {
    setOpenError(false);
  };

  useEffect(() => {
    if (status === FormStatus.Initial) {
      setInitial(true);
    } else if (status === FormStatus.Pending) {
      setInitial(false);
      setPending(true);
    } else if (status === FormStatus.Success) {
      setPending(false);
      setInitial(true);
    } else if (status === FormStatus.Error) {
      setPending(false);
      setOpenError(true);
      setInitial(true);
    }
  }, [status]);

  return (
    <WebOnly>
      {initial && (
        <Button
          className={clsx(download, className)}
          variant="contained"
          color="secondary"
          startIcon={<FontAwesomeIcon icon="download" />}
          onClick={onDownload}
        >
          {title}
        </Button>
      )}
      {pending && (
        <>
          <CircularProgress />
          <Dialog
            open={pending}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Exporting...</DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      )}
      {status === FormStatus.Error && (
        <Dialog
          open={openError}
          onClose={handleCloseError}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Error with export. {error?.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseError} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </WebOnly>
  );
};
