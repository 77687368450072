import { AppColors } from '@lib/common';
import { Reservation, Room } from '@lib/state';
import { RoomCountType } from '@lib/state/api/generated/properties';
import { Box, Button, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import {
  TapeChart,
  TapeChartRow,
  RoomSelectionFilter,
  getTapeChartFilteredRows,
  useTapeChart,
  useCurrentStayDate,
  TimelineView,
  useGuestStayLookup,
} from 'app/shared';
import { parseISO } from 'date-fns';
import React, { useCallback, useState } from 'react';

const useStyles = makeStyles(_ =>
  createStyles({
    roomSelectionHeader: {
      fontSize: '125%',
      padding: '12px',
      textAlign: 'center',
      backgroundColor: AppColors.DarkGreen,
      color: common.white,
    },
    roomSelectionButton: {
      fontSize: '75%',
      padding: '0 18px',
      marginLeft: '12px',
      borderRadius: '6px',
      backgroundColor: common.white,
      color: AppColors.Red,
      '&:hover': {
        backgroundColor: common.white,
      },
    },
  })
);

interface TapeChartProps {
  reservation: Reservation;
  rooms: Array<Room>;
  checkInRoomCount: number;
  onCanceled: () => void;
  onSelected: (roomId: Array<string>) => void;
}

export const RoomSelectionTapeChartController: React.FC<TapeChartProps> = ({
  reservation,
  rooms,
  checkInRoomCount = 1,
  onCanceled,
  onSelected,
}) => {
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [selectedCheckInRooms, setSelectedCheckInRooms] = useState<Room[]>([]);
  const [startDate, setStartDate] = useCurrentStayDate();
  const [selectedView, setView] = useState(TimelineView.TwoWeek);
  const view = onMobile ? TimelineView.OneDay : selectedView;

  const [countBy, setCountBy] = useState(RoomCountType.Bed);

  const handleToggle = (event: RoomCountType) => {
    setCountBy(event);
  };

  const {
    guestStays,
    guestStaysFilters,
    roomCounts,
    roomsFilters,
    zones,
    roomTypes,
  } = useTapeChart(startDate, view, countBy, reservation.propertyId);

  const styles = useStyles();

  const guestStaysLookup = useGuestStayLookup(guestStays);

  const filteredRows = getTapeChartFilteredRows(
    rooms,
    guestStaysLookup,
    roomsFilters,
    guestStaysFilters,
    true,
    getRoomSelectionProps(reservation)
  );
  const selectRow = useCallback(
    ({ room }: TapeChartRow) => {
      if (selectedCheckInRooms.some(s => s.id === room.id)) {
        setSelectedCheckInRooms(selectedCheckInRooms.filter(f => f.id !== room.id));
      } else if (selectedCheckInRooms.length < checkInRoomCount) {
        setSelectedCheckInRooms([...selectedCheckInRooms, room]);
      }
    },
    [selectedCheckInRooms, setSelectedCheckInRooms, checkInRoomCount]
  );

  return (
    <TapeChart
      rooms={rooms}
      countBy={countBy}
      setCountBy={handleToggle}
      guestStays={guestStays}
      guestStaysFilters={guestStaysFilters}
      selectRow={selectRow}
      startDate={startDate}
      onChangeDate={setStartDate}
      view={view}
      onChangeView={setView}
      reloadRoom={() => {}}
      roomCounts={roomCounts}
      roomsFilters={roomsFilters}
      tapeChartRows={filteredRows}
      enableRoomSelection={true}
      selectedRows={selectedCheckInRooms}
      roomTypes={roomTypes}
      zones={zones}
      roomSelectionHeader={
        <Box className={styles.roomSelectionHeader}>
          {selectedCheckInRooms.length} of {checkInRoomCount} rooms selected{' '}
          <Button className={styles.roomSelectionButton} onClick={() => onCanceled()}>
            Cancel
          </Button>
          {selectedCheckInRooms.length === checkInRoomCount && (
            <Button
              className={styles.roomSelectionButton}
              onClick={() => onSelected(selectedCheckInRooms.map(m => m.id))}
            >
              Continue
            </Button>
          )}
        </Box>
      }
    />
  );
};

function getRoomSelectionProps(reservation: Reservation): RoomSelectionFilter | undefined {
  const checkInDate = parseISO(reservation.checkInDate);
  const checkOutDate = parseISO(reservation.checkOutDate);
  return {
    checkInDate: checkInDate,
    checkOutDate: checkOutDate,
    reservationId: reservation.id,
    corporateAccountId: reservation.affiliation?.corporateAccountId,
  };
}
