import { defer, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { lightFormat } from 'date-fns';

import {
  Properties,
  Reservations,
  dispatchForm,
  poll,
  unsubscribeCallback,
  Unsubscribe,
} from '@lib/state';
import { GuestStaysStore, guestStaysStore, GuestStaysFilters } from './guest-stays.store';
import { getGuestStay } from './guest-stays.model';
import { GuestStayForms } from './guest-stay.forms';

export class GuestStaysService {
  constructor(
    private readonly store: GuestStaysStore,
    private readonly guestStaysApi: Properties.GuestStaysApi,
    private readonly reservationApi: Reservations.ReservationApi
  ) {}

  private guestStaysRequest(propertyId: string, date: Date) {
    return from(
      this.guestStaysApi.propertyIdGueststaysGet(propertyId, lightFormat(date, 'yyyy-MM-dd'))
    ).pipe(map(({ data }) => data.data.map(getGuestStay)));
  }

  getGuestStaysByReservation(propertyId: string, reservationId: string) {
    from(this.guestStaysApi.propertyIdGueststaysGet(propertyId, undefined, reservationId))
      .pipe(map(({ data }) => data.data.map(getGuestStay)))
      .subscribe(stays => this.store.upsertMany(stays));
  }

  changeRooms(
    guestStayId: string,
    newRoomNumber: string,
    reservationId: string,
    request: Reservations.ReservationChangeRoomRequest
  ) {
    from(this.reservationApi.reservationIdChangeroomPost(reservationId, request))
      .pipe(dispatchForm(GuestStayForms.ChangeRooms))
      .subscribe(() => this.store.upsert(guestStayId, { roomNumber: newRoomNumber }));
  }

  pollGuestStays(propertyId: string, date: Date, interval = 15000): Unsubscribe {
    const sub = defer(() => this.guestStaysRequest(propertyId, date))
      .pipe(poll(interval))
      .subscribe(stays => this.store.set(stays));

    return unsubscribeCallback(sub);
  }

  updateGuestStaysFilters(filters: GuestStaysFilters) {
    this.store.update(({ ui }) => {
      return {
        ui: {
          ...ui,
          filters,
        },
      };
    });
  }
}

export const guestStaysService = new GuestStaysService(
  guestStaysStore,
  new Properties.GuestStaysApi(),
  new Reservations.ReservationApi()
);
