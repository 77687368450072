import { useCallback, useEffect, useMemo } from 'react';

import { reservationQuery, reservationService, StayInfoFilters } from '@lib/state';
import { useObservable } from './useObservable';

export type LoadMoreCallback = () => void;
const limit = 20;

export function useCurrentReservations(filters: StayInfoFilters) {
  useEffect(() => {
    reservationService.getCurrentStays(mapReservationFilters(filters), { limit });
  }, [filters]);

  const reservations = useObservable(reservationQuery.currentReservations);

  const page = useObservable(reservationQuery.currentPagination);
  const loadMore = useCallback(
    () => reservationService.getCurrentStays(mapReservationFilters(filters), { limit, ...page }),
    [filters, page]
  );

  return useMemo(() => ({ reservations, page, loadMore }), [reservations, page, loadMore]);
}

export function useGuestCurrentReservations(userId: string, propertyId?: string | null) {
  useEffect(() => {
    if (!!propertyId) reservationService.getGuestCurrentStays(propertyId, userId);
  }, [userId, propertyId]);

  const reservations = useObservable(reservationQuery.currentReservations);

  const page = useObservable(reservationQuery.currentPagination);
  const loadMore = useCallback(() => {
    if (!!propertyId) reservationService.getGuestCurrentStays(propertyId, userId, page);
  }, [page]);

  return useMemo(() => ({ reservations, page, loadMore }), [reservations, page, loadMore]);
}

export function useFutureReservations(filters: StayInfoFilters) {
  useEffect(() => {
    reservationService.getUpcomingStays(mapReservationFilters(filters), { limit });
  }, [filters]);

  const reservations = useObservable(reservationQuery.upcomingReservations);

  const page = useObservable(reservationQuery.futurePagination);
  const loadMore = useCallback(
    () => reservationService.getUpcomingStays(mapReservationFilters(filters), { limit, ...page }),
    [filters, page]
  );

  return useMemo(() => ({ reservations, page, loadMore }), [reservations, page, loadMore]);
}

export function useGuestFutureReservations(userId: string, propertyId?: string | null) {
  useEffect(() => {
    if (!!propertyId) reservationService.getGuestUpcomingStays(propertyId, userId);
  }, [userId, propertyId]);

  const reservations = useObservable(reservationQuery.upcomingReservations);

  const page = useObservable(reservationQuery.futurePagination);
  const loadMore = useCallback(() => {
    if (!!propertyId) reservationService.getGuestUpcomingStays(propertyId, userId, page);
  }, [page]);

  return useMemo(() => ({ reservations, page, loadMore }), [reservations, page, loadMore]);
}

function mapReservationFilters(filters: StayInfoFilters) {
  const {
    recordNumber,
    firstName,
    lastName,
    crew,
    start,
    end,
    bookedBy,
    corporateAccountId,
  } = filters;

  return {
    recordNumber,
    firstName,
    lastName,
    crew,
    start,
    end,
    bookedBy,
    corporateAccountId,
  };
}
