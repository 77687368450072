import { Overwrite } from 'utility-types';
import { parseISO } from 'date-fns';

import { Reservations } from '@lib/state';

interface DateProps {
  startDate: Date;
  endDate: Date;
  deletedTime: Date | null;
  createdTime: Date;
}

export type RateRestriction = Overwrite<Reservations.RateRestrictionModel, DateProps>;

export type RateRestrictionTypeEnum = Reservations.RateRestrictionType;
export const RateRestrictionTypeEnum = Reservations.RateRestrictionType;

export const RateRestrictionTypeMap = new Map<RateRestrictionTypeEnum, string>([
  [RateRestrictionTypeEnum.Open, 'Open'],
  [RateRestrictionTypeEnum.Closed, 'Closed'],
  [RateRestrictionTypeEnum.ClosedToArrival, 'CTA'],
  [RateRestrictionTypeEnum.ClosedToDeparture, 'CTD'],
]);

export function mapRateRestriction(rateRestriction: Reservations.RateRestrictionModel) {
  return {
    ...rateRestriction,
    startDate: parseISO(rateRestriction.startDate),
    endDate: parseISO(rateRestriction.endDate),
    deletedTime:
      (rateRestriction.deletedTime != null && parseISO(rateRestriction.deletedTime)) || null,
    createdTime: parseISO(rateRestriction.createdTime),
  };
}
