import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import { Properties } from '../api';
import { RoomZoneStore, roomZoneStore } from './room-zone.store';

export class RoomZoneService {
  constructor(
    private readonly store: RoomZoneStore,
    private readonly roomZoneApi: Properties.RoomZoneApi
  ) {}

  getPropertyRoomZones(propertyId: string) {
    from(this.roomZoneApi.propertyIdRoomzoneGet(propertyId))
      .pipe(map(response => response.data.data))
      .subscribe(roomZones => this.store.upsertMany(roomZones));
  }
}

export const roomZoneService = new RoomZoneService(roomZoneStore, new Properties.RoomZoneApi());
