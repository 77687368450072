import { filter } from './filter';
import { KeySelectorFn, IGrouping, Grouping, groupBy } from './group-by';
import { map } from './map';

export function* groupJoin<TOuter, TInner, TKey>(
  outer: Iterable<TOuter>,
  inner: Iterable<TInner>,
  outerKeySelector: KeySelectorFn<TOuter, TKey>,
  innerKeySelector: KeySelectorFn<TInner, TKey>
): Iterable<IGrouping<TOuter, TInner>> {
  const lookup = new Map<TKey, Array<TInner>>();
  for (const x of inner) {
    const key = innerKeySelector(x);
    if (lookup.has(key)) lookup.get(key)!.push(x);
    else lookup.set(key, [x]);
  }

  yield* map(
    filter(outer, x => lookup.has(outerKeySelector(x))),
    x => new Grouping(x, ...lookup.get(outerKeySelector(x))!)
  );
}
