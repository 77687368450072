import React, { ReactNode } from 'react';
import { FormControl } from './controls';

export type ValidatorFn<T> = (value: T) => ReactNode;

export function validator<T>(check: (value: T) => boolean, message: string): ValidatorFn<T> {
  return (value: T) => (check(value) ? undefined : message);
}

export function required<T>(message: string = 'This field is required.') {
  return validator<T>(
    val =>
      val != undefined &&
      !((typeof val === 'string' && val === '') || (val instanceof Array && val.length < 1)),
    message
  );
}

export function email(message: string = 'Please enter an email address.') {
  return validator<string>(str => str.indexOf('@') > 0, message);
}

export function matches<T>(other: FormControl, message: string = 'Values must match.') {
  return validator<T>(value => value === other.value, message);
}

export function minimum(
  minValue: number,
  message: string = 'Value must be greater than or equal to {minValue}.'
) {
  return validator<number | undefined>(
    x => x === undefined || x >= minValue,
    message.replace('{minValue}', minValue.toString())
  );
}

export function maximum(
  maxValue: number,
  message: string = 'Value must be less than or equal to {maxValue}.'
) {
  return validator<number | undefined>(
    x => x === undefined || x <= maxValue,
    message.replace('{maxValue}', maxValue.toString())
  );
}
