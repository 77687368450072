import { useEffect } from 'react';
import {
  roomZoneService,
  roomTypeService,
  roomsQuery,
  roomZoneQuery,
  roomTypeQuery,
  roomsService,
} from '@lib/state';
import { addDays } from 'date-fns';
import { guestStaysQuery, guestStaysService, roomCountQuery, roomCountService } from 'app/state';
import { TimelineView } from './useTimeline';
import { RoomCountType } from '@lib/state/api/generated/properties';
import { useObservable } from '@lib/common';

export function useTapeChart(
  startDate: Date,
  view: TimelineView,
  countBy: RoomCountType,
  propertyId?: string | null
) {
  const guestStays = useObservable(guestStaysQuery.guestStays);
  const roomCounts = useObservable(roomCountQuery.roomCounts);
  const guestStaysFilters = useObservable(guestStaysQuery.guestStaysFilters);
  const roomsFilters = useObservable(roomsQuery.roomsFilters);
  const zones = useObservable(roomZoneQuery.roomZones);
  const roomTypes = useObservable(roomTypeQuery.propertyRoomTypes);
  const { roomTypeId } = useObservable(roomsQuery.roomsFilters);
  const allPropertyRooms = useObservable(roomsQuery.rooms);

  useEffect(() => {
    roomCountService.updateIntervalFilter(startDate, addDays(startDate, view));
  }, [view, startDate]);

  useEffect(() => {
    if (propertyId) roomZoneService.getPropertyRoomZones(propertyId);
  }, [propertyId]);

  useEffect(() => {
    if (propertyId) roomTypeService.getPropertyRoomTypes(propertyId);
  }, [propertyId]);

  useEffect(() => {
    if (propertyId) {
      return roomsService.pollRooms(propertyId);
    }
  }, [propertyId]);

  useEffect(() => {
    if (propertyId) {
      return roomCountService.pollRoomCounts(
        propertyId,
        startDate,
        addDays(startDate, view),
        countBy,
        roomTypeId !== '' ? roomTypeId : undefined
      );
    }
  }, [propertyId, startDate, view, roomTypeId, countBy]);

  useEffect(() => {
    if (propertyId) {
      return guestStaysService.pollGuestStays(propertyId, startDate);
    }
  }, [propertyId, startDate]);

  return {
    guestStays,
    guestStaysFilters,
    roomCounts,
    roomsFilters,
    zones,
    roomTypes,
    allPropertyRooms,
  };
}
