export enum AccountForms {
  CreateAccount = 'createAccount',
  Login = 'login',
  QRLogin = 'qrLogin',
  ChangePassword = 'changePassword',
  ForgotPassword = 'forgotPassword',
  ResetPassword = 'resetPassword',
  VerifyEmail = 'verifyEmail',
  VerifyPhoneNumber = 'verifyPhoneNumber',
  SendPhoneNumberVerificationCode = 'SendPhoneNumberVerificationCode',
  SendEmailVerificationToken = 'SendEmailVerificationToken',
  EmployeeEnrollment = 'EmployeeEnrollment',
  RegenerateUserKey = 'RegenerateUserKey',
}
