import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Typography,
  Divider,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import { checkInService, sessionQuery } from '@lib/state';
import {
  usePageTitle,
  kioskStyles,
  HotelPlaceholderJpg,
  useObservable,
  ArmadilloDarkLogoSvg,
} from '@lib/common';
import { isKiosk } from 'app/shared/native-interface/nativebridge';
import { TileLink } from 'app/shared';

const useStyles = makeStyles(
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: `url(${HotelPlaceholderJpg})`,
      backgroundPosition: 'center top',
      backgroundSize: 'cover',
    },
    cover: {
      backgroundColor: 'rgba(250,250,250,0.64)', // Tint color
      paddingBottom: '24px',
    },
    logo: {
      display: 'flex',
      flexDirection: 'column',
      height: '305.698px', //calced height with 40% width
      width: '301px', // 40% of tablet view
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '136px',
      marginBottom: '86px',
      margin: 'auto',
    },
    welcome: {
      marginBottom: '86px',
    },
  })
);

export const KioskDashboardPage: React.FC<RouteComponentProps> = props => {
  const propertyId = useObservable(sessionQuery.propertyId);
  const { root, cover, logo, welcome } = useStyles();
  const { primaryKioskButton } = kioskStyles();
  usePageTitle('Self Check-In');

  useEffect(() => {
    if (!propertyId && !isKiosk()) {
      // Redirect to selection screen
      props.history.push('kiosk/select-property');
    } else {
      // We're staying here, let's reset the check-in service
      checkInService.reset();
    }
  });

  return (
    <div>
      <Box className={root}>
        <Container maxWidth="md" className={cover}>
          <Grid container>
            <Grid item xs={12}>
              <img src={ArmadilloDarkLogoSvg} className={logo} alt="The Armadillo Hotel" />
              <Typography
                className={welcome}
                variant="h2"
                color="textPrimary"
                align="center"
                paragraph
              >
                Welcome to <br />
                the Armadillo Hotel
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box mt={6}>
        <Container maxWidth="md">
          <Grid container>
            <Grid item xs>
              <TileLink
                to="/kiosk/login-method"
                variant="h3"
                icon={['far', 'search']}
                iconProps={{ size: '4x' }}
                className={primaryKioskButton}
                data-testid="kioskFindReservationLink"
              >
                Find My
                <br />
                Reservation
              </TileLink>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs>
              <TileLink
                to="/kiosk/new-reservation"
                variant="h3"
                icon={['far', 'bed']}
                iconProps={{ size: '4x' }}
                className={primaryKioskButton}
                data-testid="kioskNewReservationLink"
              >
                I Don't Have <br />a Reservation
              </TileLink>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};
