import React from 'react';
import { makeStyles, createStyles, Grid } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { ArrowForward } from '@material-ui/icons';
import { format, parseISO } from 'date-fns';

import { AppColors } from '../../theme';

const useStyles = makeStyles(() =>
  createStyles({
    divider: {
      height: '100%',
      backgroundColor: common.white,
    },
    container: {
      position: 'relative',
      padding: 0,
      textTransform: 'none',
    },
    box: {
      color: common.white,
      fontSize: '200%',
      textAlign: 'center',
      padding: '12px',
      height: '80px',
    },
    month: {
      display: 'block',
      lineHeight: 1,
      fontSize: '70%',
    },
    day: {
      fontSize: '100%',
    },
    arrow: {
      borderRadius: '100%',
      backgroundColor: common.white,
      position: 'absolute',
      left: 'calc(50% - 10px)',
      top: 'calc(50% - 10px)',
    },
  })
);

export type DateRangeBlockProps = {
  fromDate?: string;
  toDate?: string;
  leftBorder?: boolean;
  backgroundColor?: string;
};

export const DateRangeBlock: React.FC<DateRangeBlockProps> = ({ ...props }) => {
  const styles = useStyles();
  const from = props.fromDate ? parseISO(props.fromDate) : undefined;
  const to = props.toDate ? parseISO(props.toDate) : undefined;

  return (
    <Grid
      container
      className={styles.container}
      style={props.leftBorder ? { borderLeft: '1px solid white' } : {}}
    >
      <Grid
        item
        className={styles.box}
        xs={6}
        style={{
          borderRight: '1px solid white',
          backgroundColor: props.backgroundColor ?? AppColors.LightBlue,
        }}
      >
        {from ? (
          <React.Fragment>
            <strong className={styles.month}>{format(from, 'MMM')}</strong>
            <strong className={styles.day}>{from.getDate()}</strong>
          </React.Fragment>
        ) : null}
      </Grid>
      <Grid
        item
        className={styles.box}
        xs={6}
        style={{
          backgroundColor: props.backgroundColor ?? AppColors.LightBlue,
        }}
      >
        {to ? (
          <React.Fragment>
            <strong className={styles.month}>{format(to, 'MMM')}</strong>
            <strong className={styles.day}>{to.getDate()}</strong>
          </React.Fragment>
        ) : null}
      </Grid>
      <ArrowForward
        className={styles.arrow}
        fontSize="small"
        style={{ color: props.backgroundColor ?? AppColors.LightBlue }}
      />
    </Grid>
  );
};
