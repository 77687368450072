// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { AuthorizedUserType } from '../models';
import { ProblemDetails } from '../models';
import { ReservationSearchPaginatedListResponse } from '../models';
import { ReservationSearchResponse } from '../models';
import { ReservationStatus } from '../models';
import { VipStatus } from '../models';
/**
 * ReservationSearchApi - axios parameter creator
 * @export
 */
export const ReservationSearchApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {number} [recordNumber]
     * @param {number} [recordNumberRange]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [email]
     * @param {string} [phone]
     * @param {string} [corporateAccountName]
     * @param {string} [corporateAccountId]
     * @param {string} [planName]
     * @param {boolean} [isFilterByZeroCharges]
     * @param {string} [createdByUserId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {string} [roomTypeId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCheckinGet(
      propertyId: string,
      recordNumber?: number,
      recordNumberRange?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      email?: string,
      phone?: string,
      corporateAccountName?: string,
      corporateAccountId?: string,
      planName?: string,
      isFilterByZeroCharges?: boolean,
      createdByUserId?: string,
      createdByUserType?: AuthorizedUserType,
      roomTypeId?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling searchCheckinGet.'
        );
      }
      const localVarPath = `/search/checkin`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (recordNumber !== undefined) {
        localVarQueryParameter['recordNumber'] = recordNumber;
      }

      if (recordNumberRange !== undefined) {
        localVarQueryParameter['recordNumberRange'] = recordNumberRange;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['firstName'] = firstName;
      }

      if (lastName !== undefined) {
        localVarQueryParameter['lastName'] = lastName;
      }

      if (crew !== undefined) {
        localVarQueryParameter['crew'] = crew;
      }

      if (email !== undefined) {
        localVarQueryParameter['email'] = email;
      }

      if (phone !== undefined) {
        localVarQueryParameter['phone'] = phone;
      }

      if (corporateAccountName !== undefined) {
        localVarQueryParameter['corporateAccountName'] = corporateAccountName;
      }

      if (corporateAccountId !== undefined) {
        localVarQueryParameter['corporateAccountId'] = corporateAccountId;
      }

      if (planName !== undefined) {
        localVarQueryParameter['planName'] = planName;
      }

      if (isFilterByZeroCharges !== undefined) {
        localVarQueryParameter['isFilterByZeroCharges'] = isFilterByZeroCharges;
      }

      if (createdByUserId !== undefined) {
        localVarQueryParameter['createdByUserId'] = createdByUserId;
      }

      if (createdByUserType !== undefined) {
        localVarQueryParameter['createdByUserType'] = createdByUserType;
      }

      if (roomTypeId !== undefined) {
        localVarQueryParameter['roomTypeId'] = roomTypeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (vipStatus !== undefined) {
        localVarQueryParameter['vipStatus'] = vipStatus;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} reservationNumber
     * @param {string} [reservationNumberRange]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCodeGet(
      reservationNumber: string,
      reservationNumberRange?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'reservationNumber' is not null or undefined
      if (reservationNumber === null || reservationNumber === undefined) {
        throw new RequiredError(
          'reservationNumber',
          'Required parameter reservationNumber was null or undefined when calling searchCodeGet.'
        );
      }
      const localVarPath = `/search/code`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (reservationNumber !== undefined) {
        localVarQueryParameter['reservationNumber'] = reservationNumber;
      }

      if (reservationNumberRange !== undefined) {
        localVarQueryParameter['reservationNumberRange'] = reservationNumberRange;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (vipStatus !== undefined) {
        localVarQueryParameter['vipStatus'] = vipStatus;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {number} [recordNumber]
     * @param {number} [recordNumberRange]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [email]
     * @param {string} [phone]
     * @param {string} [corporateAccountName]
     * @param {string} [corporateAccountId]
     * @param {string} [planName]
     * @param {boolean} [isFilterByZeroCharges]
     * @param {string} [createdByUserId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {string} [roomTypeId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchExportGet(
      propertyId: string,
      recordNumber?: number,
      recordNumberRange?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      email?: string,
      phone?: string,
      corporateAccountName?: string,
      corporateAccountId?: string,
      planName?: string,
      isFilterByZeroCharges?: boolean,
      createdByUserId?: string,
      createdByUserType?: AuthorizedUserType,
      roomTypeId?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling searchExportGet.'
        );
      }
      const localVarPath = `/search/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (recordNumber !== undefined) {
        localVarQueryParameter['recordNumber'] = recordNumber;
      }

      if (recordNumberRange !== undefined) {
        localVarQueryParameter['recordNumberRange'] = recordNumberRange;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['firstName'] = firstName;
      }

      if (lastName !== undefined) {
        localVarQueryParameter['lastName'] = lastName;
      }

      if (crew !== undefined) {
        localVarQueryParameter['crew'] = crew;
      }

      if (email !== undefined) {
        localVarQueryParameter['email'] = email;
      }

      if (phone !== undefined) {
        localVarQueryParameter['phone'] = phone;
      }

      if (corporateAccountName !== undefined) {
        localVarQueryParameter['corporateAccountName'] = corporateAccountName;
      }

      if (corporateAccountId !== undefined) {
        localVarQueryParameter['corporateAccountId'] = corporateAccountId;
      }

      if (planName !== undefined) {
        localVarQueryParameter['planName'] = planName;
      }

      if (isFilterByZeroCharges !== undefined) {
        localVarQueryParameter['isFilterByZeroCharges'] = isFilterByZeroCharges;
      }

      if (createdByUserId !== undefined) {
        localVarQueryParameter['createdByUserId'] = createdByUserId;
      }

      if (createdByUserType !== undefined) {
        localVarQueryParameter['createdByUserType'] = createdByUserType;
      }

      if (roomTypeId !== undefined) {
        localVarQueryParameter['roomTypeId'] = roomTypeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (vipStatus !== undefined) {
        localVarQueryParameter['vipStatus'] = vipStatus;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {number} [recordNumber]
     * @param {number} [recordNumberRange]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [email]
     * @param {string} [phone]
     * @param {string} [corporateAccountName]
     * @param {string} [corporateAccountId]
     * @param {string} [planName]
     * @param {boolean} [isFilterByZeroCharges]
     * @param {string} [createdByUserId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {string} [roomTypeId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGet(
      propertyId: string,
      recordNumber?: number,
      recordNumberRange?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      email?: string,
      phone?: string,
      corporateAccountName?: string,
      corporateAccountId?: string,
      planName?: string,
      isFilterByZeroCharges?: boolean,
      createdByUserId?: string,
      createdByUserType?: AuthorizedUserType,
      roomTypeId?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling searchGet.'
        );
      }
      const localVarPath = `/search`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (recordNumber !== undefined) {
        localVarQueryParameter['recordNumber'] = recordNumber;
      }

      if (recordNumberRange !== undefined) {
        localVarQueryParameter['recordNumberRange'] = recordNumberRange;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['firstName'] = firstName;
      }

      if (lastName !== undefined) {
        localVarQueryParameter['lastName'] = lastName;
      }

      if (crew !== undefined) {
        localVarQueryParameter['crew'] = crew;
      }

      if (email !== undefined) {
        localVarQueryParameter['email'] = email;
      }

      if (phone !== undefined) {
        localVarQueryParameter['phone'] = phone;
      }

      if (corporateAccountName !== undefined) {
        localVarQueryParameter['corporateAccountName'] = corporateAccountName;
      }

      if (corporateAccountId !== undefined) {
        localVarQueryParameter['corporateAccountId'] = corporateAccountId;
      }

      if (planName !== undefined) {
        localVarQueryParameter['planName'] = planName;
      }

      if (isFilterByZeroCharges !== undefined) {
        localVarQueryParameter['isFilterByZeroCharges'] = isFilterByZeroCharges;
      }

      if (createdByUserId !== undefined) {
        localVarQueryParameter['createdByUserId'] = createdByUserId;
      }

      if (createdByUserType !== undefined) {
        localVarQueryParameter['createdByUserType'] = createdByUserType;
      }

      if (roomTypeId !== undefined) {
        localVarQueryParameter['roomTypeId'] = roomTypeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (vipStatus !== undefined) {
        localVarQueryParameter['vipStatus'] = vipStatus;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {string} propertyId
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGuestExportGet(
      userId: string,
      propertyId: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling searchGuestExportGet.'
        );
      }
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling searchGuestExportGet.'
        );
      }
      const localVarPath = `/search/guest/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (vipStatus !== undefined) {
        localVarQueryParameter['vipStatus'] = vipStatus;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {string} propertyId
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGuestGet(
      userId: string,
      propertyId: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling searchGuestGet.'
        );
      }
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling searchGuestGet.'
        );
      }
      const localVarPath = `/search/guest`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (vipStatus !== undefined) {
        localVarQueryParameter['vipStatus'] = vipStatus;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {boolean} [guestOnly]
     * @param {number} [recordNumber]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [corporateAccountId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchUserExportGet(
      propertyId?: string,
      guestOnly?: boolean,
      recordNumber?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      corporateAccountId?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/search/user/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (guestOnly !== undefined) {
        localVarQueryParameter['guestOnly'] = guestOnly;
      }

      if (recordNumber !== undefined) {
        localVarQueryParameter['recordNumber'] = recordNumber;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['firstName'] = firstName;
      }

      if (lastName !== undefined) {
        localVarQueryParameter['lastName'] = lastName;
      }

      if (crew !== undefined) {
        localVarQueryParameter['crew'] = crew;
      }

      if (corporateAccountId !== undefined) {
        localVarQueryParameter['corporateAccountId'] = corporateAccountId;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (vipStatus !== undefined) {
        localVarQueryParameter['vipStatus'] = vipStatus;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {boolean} [guestOnly]
     * @param {number} [recordNumber]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [corporateAccountId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchUserGet(
      propertyId?: string,
      guestOnly?: boolean,
      recordNumber?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      corporateAccountId?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/search/user`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (guestOnly !== undefined) {
        localVarQueryParameter['guestOnly'] = guestOnly;
      }

      if (recordNumber !== undefined) {
        localVarQueryParameter['recordNumber'] = recordNumber;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['firstName'] = firstName;
      }

      if (lastName !== undefined) {
        localVarQueryParameter['lastName'] = lastName;
      }

      if (crew !== undefined) {
        localVarQueryParameter['crew'] = crew;
      }

      if (corporateAccountId !== undefined) {
        localVarQueryParameter['corporateAccountId'] = corporateAccountId;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (vipStatus !== undefined) {
        localVarQueryParameter['vipStatus'] = vipStatus;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReservationSearchApi - functional programming interface
 * @export
 */
export const ReservationSearchApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {number} [recordNumber]
     * @param {number} [recordNumberRange]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [email]
     * @param {string} [phone]
     * @param {string} [corporateAccountName]
     * @param {string} [corporateAccountId]
     * @param {string} [planName]
     * @param {boolean} [isFilterByZeroCharges]
     * @param {string} [createdByUserId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {string} [roomTypeId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCheckinGet(
      propertyId: string,
      recordNumber?: number,
      recordNumberRange?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      email?: string,
      phone?: string,
      corporateAccountName?: string,
      corporateAccountId?: string,
      planName?: string,
      isFilterByZeroCharges?: boolean,
      createdByUserId?: string,
      createdByUserType?: AuthorizedUserType,
      roomTypeId?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<ReservationSearchPaginatedListResponse> {
      const localVarAxiosArgs = ReservationSearchApiAxiosParamCreator(
        configuration
      ).searchCheckinGet(
        propertyId,
        recordNumber,
        recordNumberRange,
        firstName,
        lastName,
        crew,
        email,
        phone,
        corporateAccountName,
        corporateAccountId,
        planName,
        isFilterByZeroCharges,
        createdByUserId,
        createdByUserType,
        roomTypeId,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} reservationNumber
     * @param {string} [reservationNumberRange]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCodeGet(
      reservationNumber: string,
      reservationNumberRange?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationSearchResponse> {
      const localVarAxiosArgs = ReservationSearchApiAxiosParamCreator(configuration).searchCodeGet(
        reservationNumber,
        reservationNumberRange,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {number} [recordNumber]
     * @param {number} [recordNumberRange]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [email]
     * @param {string} [phone]
     * @param {string} [corporateAccountName]
     * @param {string} [corporateAccountId]
     * @param {string} [planName]
     * @param {boolean} [isFilterByZeroCharges]
     * @param {string} [createdByUserId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {string} [roomTypeId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchExportGet(
      propertyId: string,
      recordNumber?: number,
      recordNumberRange?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      email?: string,
      phone?: string,
      corporateAccountName?: string,
      corporateAccountId?: string,
      planName?: string,
      isFilterByZeroCharges?: boolean,
      createdByUserId?: string,
      createdByUserType?: AuthorizedUserType,
      roomTypeId?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = ReservationSearchApiAxiosParamCreator(
        configuration
      ).searchExportGet(
        propertyId,
        recordNumber,
        recordNumberRange,
        firstName,
        lastName,
        crew,
        email,
        phone,
        corporateAccountName,
        corporateAccountId,
        planName,
        isFilterByZeroCharges,
        createdByUserId,
        createdByUserType,
        roomTypeId,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {number} [recordNumber]
     * @param {number} [recordNumberRange]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [email]
     * @param {string} [phone]
     * @param {string} [corporateAccountName]
     * @param {string} [corporateAccountId]
     * @param {string} [planName]
     * @param {boolean} [isFilterByZeroCharges]
     * @param {string} [createdByUserId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {string} [roomTypeId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGet(
      propertyId: string,
      recordNumber?: number,
      recordNumberRange?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      email?: string,
      phone?: string,
      corporateAccountName?: string,
      corporateAccountId?: string,
      planName?: string,
      isFilterByZeroCharges?: boolean,
      createdByUserId?: string,
      createdByUserType?: AuthorizedUserType,
      roomTypeId?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<ReservationSearchPaginatedListResponse> {
      const localVarAxiosArgs = ReservationSearchApiAxiosParamCreator(configuration).searchGet(
        propertyId,
        recordNumber,
        recordNumberRange,
        firstName,
        lastName,
        crew,
        email,
        phone,
        corporateAccountName,
        corporateAccountId,
        planName,
        isFilterByZeroCharges,
        createdByUserId,
        createdByUserType,
        roomTypeId,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} userId
     * @param {string} propertyId
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGuestExportGet(
      userId: string,
      propertyId: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = ReservationSearchApiAxiosParamCreator(
        configuration
      ).searchGuestExportGet(
        userId,
        propertyId,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} userId
     * @param {string} propertyId
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGuestGet(
      userId: string,
      propertyId: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<ReservationSearchPaginatedListResponse> {
      const localVarAxiosArgs = ReservationSearchApiAxiosParamCreator(configuration).searchGuestGet(
        userId,
        propertyId,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {boolean} [guestOnly]
     * @param {number} [recordNumber]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [corporateAccountId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchUserExportGet(
      propertyId?: string,
      guestOnly?: boolean,
      recordNumber?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      corporateAccountId?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = ReservationSearchApiAxiosParamCreator(
        configuration
      ).searchUserExportGet(
        propertyId,
        guestOnly,
        recordNumber,
        firstName,
        lastName,
        crew,
        corporateAccountId,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {boolean} [guestOnly]
     * @param {number} [recordNumber]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [corporateAccountId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchUserGet(
      propertyId?: string,
      guestOnly?: boolean,
      recordNumber?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      corporateAccountId?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<ReservationSearchPaginatedListResponse> {
      const localVarAxiosArgs = ReservationSearchApiAxiosParamCreator(configuration).searchUserGet(
        propertyId,
        guestOnly,
        recordNumber,
        firstName,
        lastName,
        crew,
        corporateAccountId,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ReservationSearchApi - factory interface
 * @export
 */
export const ReservationSearchApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {number} [recordNumber]
     * @param {number} [recordNumberRange]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [email]
     * @param {string} [phone]
     * @param {string} [corporateAccountName]
     * @param {string} [corporateAccountId]
     * @param {string} [planName]
     * @param {boolean} [isFilterByZeroCharges]
     * @param {string} [createdByUserId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {string} [roomTypeId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCheckinGet(
      propertyId: string,
      recordNumber?: number,
      recordNumberRange?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      email?: string,
      phone?: string,
      corporateAccountName?: string,
      corporateAccountId?: string,
      planName?: string,
      isFilterByZeroCharges?: boolean,
      createdByUserId?: string,
      createdByUserType?: AuthorizedUserType,
      roomTypeId?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): AxiosPromise<ReservationSearchPaginatedListResponse> {
      return ReservationSearchApiFp(configuration).searchCheckinGet(
        propertyId,
        recordNumber,
        recordNumberRange,
        firstName,
        lastName,
        crew,
        email,
        phone,
        corporateAccountName,
        corporateAccountId,
        planName,
        isFilterByZeroCharges,
        createdByUserId,
        createdByUserType,
        roomTypeId,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} reservationNumber
     * @param {string} [reservationNumberRange]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCodeGet(
      reservationNumber: string,
      reservationNumberRange?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): AxiosPromise<ReservationSearchResponse> {
      return ReservationSearchApiFp(configuration).searchCodeGet(
        reservationNumber,
        reservationNumberRange,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {number} [recordNumber]
     * @param {number} [recordNumberRange]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [email]
     * @param {string} [phone]
     * @param {string} [corporateAccountName]
     * @param {string} [corporateAccountId]
     * @param {string} [planName]
     * @param {boolean} [isFilterByZeroCharges]
     * @param {string} [createdByUserId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {string} [roomTypeId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchExportGet(
      propertyId: string,
      recordNumber?: number,
      recordNumberRange?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      email?: string,
      phone?: string,
      corporateAccountName?: string,
      corporateAccountId?: string,
      planName?: string,
      isFilterByZeroCharges?: boolean,
      createdByUserId?: string,
      createdByUserType?: AuthorizedUserType,
      roomTypeId?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): AxiosPromise<any> {
      return ReservationSearchApiFp(configuration).searchExportGet(
        propertyId,
        recordNumber,
        recordNumberRange,
        firstName,
        lastName,
        crew,
        email,
        phone,
        corporateAccountName,
        corporateAccountId,
        planName,
        isFilterByZeroCharges,
        createdByUserId,
        createdByUserType,
        roomTypeId,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {number} [recordNumber]
     * @param {number} [recordNumberRange]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [email]
     * @param {string} [phone]
     * @param {string} [corporateAccountName]
     * @param {string} [corporateAccountId]
     * @param {string} [planName]
     * @param {boolean} [isFilterByZeroCharges]
     * @param {string} [createdByUserId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {string} [roomTypeId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGet(
      propertyId: string,
      recordNumber?: number,
      recordNumberRange?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      email?: string,
      phone?: string,
      corporateAccountName?: string,
      corporateAccountId?: string,
      planName?: string,
      isFilterByZeroCharges?: boolean,
      createdByUserId?: string,
      createdByUserType?: AuthorizedUserType,
      roomTypeId?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): AxiosPromise<ReservationSearchPaginatedListResponse> {
      return ReservationSearchApiFp(configuration).searchGet(
        propertyId,
        recordNumber,
        recordNumberRange,
        firstName,
        lastName,
        crew,
        email,
        phone,
        corporateAccountName,
        corporateAccountId,
        planName,
        isFilterByZeroCharges,
        createdByUserId,
        createdByUserType,
        roomTypeId,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} userId
     * @param {string} propertyId
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGuestExportGet(
      userId: string,
      propertyId: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): AxiosPromise<any> {
      return ReservationSearchApiFp(configuration).searchGuestExportGet(
        userId,
        propertyId,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} userId
     * @param {string} propertyId
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGuestGet(
      userId: string,
      propertyId: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): AxiosPromise<ReservationSearchPaginatedListResponse> {
      return ReservationSearchApiFp(configuration).searchGuestGet(
        userId,
        propertyId,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {boolean} [guestOnly]
     * @param {number} [recordNumber]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [corporateAccountId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchUserExportGet(
      propertyId?: string,
      guestOnly?: boolean,
      recordNumber?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      corporateAccountId?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): AxiosPromise<any> {
      return ReservationSearchApiFp(configuration).searchUserExportGet(
        propertyId,
        guestOnly,
        recordNumber,
        firstName,
        lastName,
        crew,
        corporateAccountId,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {boolean} [guestOnly]
     * @param {number} [recordNumber]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [corporateAccountId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {ReservationStatus} [status]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchUserGet(
      propertyId?: string,
      guestOnly?: boolean,
      recordNumber?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      corporateAccountId?: string,
      start?: string,
      end?: string,
      status?: ReservationStatus,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): AxiosPromise<ReservationSearchPaginatedListResponse> {
      return ReservationSearchApiFp(configuration).searchUserGet(
        propertyId,
        guestOnly,
        recordNumber,
        firstName,
        lastName,
        crew,
        corporateAccountId,
        start,
        end,
        status,
        continuationToken,
        limit,
        vipStatus,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ReservationSearchApi - object-oriented interface
 * @export
 * @class ReservationSearchApi
 * @extends {BaseAPI}
 */
export class ReservationSearchApi extends BaseAPI {
  /**
   *
   * @param {string} propertyId
   * @param {number} [recordNumber]
   * @param {number} [recordNumberRange]
   * @param {string} [firstName]
   * @param {string} [lastName]
   * @param {string} [crew]
   * @param {string} [email]
   * @param {string} [phone]
   * @param {string} [corporateAccountName]
   * @param {string} [corporateAccountId]
   * @param {string} [planName]
   * @param {boolean} [isFilterByZeroCharges]
   * @param {string} [createdByUserId]
   * @param {AuthorizedUserType} [createdByUserType]
   * @param {string} [roomTypeId]
   * @param {string} [start]
   * @param {string} [end]
   * @param {ReservationStatus} [status]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {VipStatus} [vipStatus]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationSearchApi
   */
  public searchCheckinGet(
    propertyId: string,
    recordNumber?: number,
    recordNumberRange?: number,
    firstName?: string,
    lastName?: string,
    crew?: string,
    email?: string,
    phone?: string,
    corporateAccountName?: string,
    corporateAccountId?: string,
    planName?: string,
    isFilterByZeroCharges?: boolean,
    createdByUserId?: string,
    createdByUserType?: AuthorizedUserType,
    roomTypeId?: string,
    start?: string,
    end?: string,
    status?: ReservationStatus,
    continuationToken?: string,
    limit?: number,
    vipStatus?: VipStatus,
    options?: any
  ) {
    return ReservationSearchApiFp(this.configuration).searchCheckinGet(
      propertyId,
      recordNumber,
      recordNumberRange,
      firstName,
      lastName,
      crew,
      email,
      phone,
      corporateAccountName,
      corporateAccountId,
      planName,
      isFilterByZeroCharges,
      createdByUserId,
      createdByUserType,
      roomTypeId,
      start,
      end,
      status,
      continuationToken,
      limit,
      vipStatus,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} reservationNumber
   * @param {string} [reservationNumberRange]
   * @param {string} [start]
   * @param {string} [end]
   * @param {ReservationStatus} [status]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {VipStatus} [vipStatus]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationSearchApi
   */
  public searchCodeGet(
    reservationNumber: string,
    reservationNumberRange?: string,
    start?: string,
    end?: string,
    status?: ReservationStatus,
    continuationToken?: string,
    limit?: number,
    vipStatus?: VipStatus,
    options?: any
  ) {
    return ReservationSearchApiFp(this.configuration).searchCodeGet(
      reservationNumber,
      reservationNumberRange,
      start,
      end,
      status,
      continuationToken,
      limit,
      vipStatus,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {number} [recordNumber]
   * @param {number} [recordNumberRange]
   * @param {string} [firstName]
   * @param {string} [lastName]
   * @param {string} [crew]
   * @param {string} [email]
   * @param {string} [phone]
   * @param {string} [corporateAccountName]
   * @param {string} [corporateAccountId]
   * @param {string} [planName]
   * @param {boolean} [isFilterByZeroCharges]
   * @param {string} [createdByUserId]
   * @param {AuthorizedUserType} [createdByUserType]
   * @param {string} [roomTypeId]
   * @param {string} [start]
   * @param {string} [end]
   * @param {ReservationStatus} [status]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {VipStatus} [vipStatus]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationSearchApi
   */
  public searchExportGet(
    propertyId: string,
    recordNumber?: number,
    recordNumberRange?: number,
    firstName?: string,
    lastName?: string,
    crew?: string,
    email?: string,
    phone?: string,
    corporateAccountName?: string,
    corporateAccountId?: string,
    planName?: string,
    isFilterByZeroCharges?: boolean,
    createdByUserId?: string,
    createdByUserType?: AuthorizedUserType,
    roomTypeId?: string,
    start?: string,
    end?: string,
    status?: ReservationStatus,
    continuationToken?: string,
    limit?: number,
    vipStatus?: VipStatus,
    options?: any
  ) {
    return ReservationSearchApiFp(this.configuration).searchExportGet(
      propertyId,
      recordNumber,
      recordNumberRange,
      firstName,
      lastName,
      crew,
      email,
      phone,
      corporateAccountName,
      corporateAccountId,
      planName,
      isFilterByZeroCharges,
      createdByUserId,
      createdByUserType,
      roomTypeId,
      start,
      end,
      status,
      continuationToken,
      limit,
      vipStatus,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {number} [recordNumber]
   * @param {number} [recordNumberRange]
   * @param {string} [firstName]
   * @param {string} [lastName]
   * @param {string} [crew]
   * @param {string} [email]
   * @param {string} [phone]
   * @param {string} [corporateAccountName]
   * @param {string} [corporateAccountId]
   * @param {string} [planName]
   * @param {boolean} [isFilterByZeroCharges]
   * @param {string} [createdByUserId]
   * @param {AuthorizedUserType} [createdByUserType]
   * @param {string} [roomTypeId]
   * @param {string} [start]
   * @param {string} [end]
   * @param {ReservationStatus} [status]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {VipStatus} [vipStatus]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationSearchApi
   */
  public searchGet(
    propertyId: string,
    recordNumber?: number,
    recordNumberRange?: number,
    firstName?: string,
    lastName?: string,
    crew?: string,
    email?: string,
    phone?: string,
    corporateAccountName?: string,
    corporateAccountId?: string,
    planName?: string,
    isFilterByZeroCharges?: boolean,
    createdByUserId?: string,
    createdByUserType?: AuthorizedUserType,
    roomTypeId?: string,
    start?: string,
    end?: string,
    status?: ReservationStatus,
    continuationToken?: string,
    limit?: number,
    vipStatus?: VipStatus,
    options?: any
  ) {
    return ReservationSearchApiFp(this.configuration).searchGet(
      propertyId,
      recordNumber,
      recordNumberRange,
      firstName,
      lastName,
      crew,
      email,
      phone,
      corporateAccountName,
      corporateAccountId,
      planName,
      isFilterByZeroCharges,
      createdByUserId,
      createdByUserType,
      roomTypeId,
      start,
      end,
      status,
      continuationToken,
      limit,
      vipStatus,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} userId
   * @param {string} propertyId
   * @param {string} [start]
   * @param {string} [end]
   * @param {ReservationStatus} [status]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {VipStatus} [vipStatus]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationSearchApi
   */
  public searchGuestExportGet(
    userId: string,
    propertyId: string,
    start?: string,
    end?: string,
    status?: ReservationStatus,
    continuationToken?: string,
    limit?: number,
    vipStatus?: VipStatus,
    options?: any
  ) {
    return ReservationSearchApiFp(this.configuration).searchGuestExportGet(
      userId,
      propertyId,
      start,
      end,
      status,
      continuationToken,
      limit,
      vipStatus,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} userId
   * @param {string} propertyId
   * @param {string} [start]
   * @param {string} [end]
   * @param {ReservationStatus} [status]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {VipStatus} [vipStatus]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationSearchApi
   */
  public searchGuestGet(
    userId: string,
    propertyId: string,
    start?: string,
    end?: string,
    status?: ReservationStatus,
    continuationToken?: string,
    limit?: number,
    vipStatus?: VipStatus,
    options?: any
  ) {
    return ReservationSearchApiFp(this.configuration).searchGuestGet(
      userId,
      propertyId,
      start,
      end,
      status,
      continuationToken,
      limit,
      vipStatus,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [propertyId]
   * @param {boolean} [guestOnly]
   * @param {number} [recordNumber]
   * @param {string} [firstName]
   * @param {string} [lastName]
   * @param {string} [crew]
   * @param {string} [corporateAccountId]
   * @param {string} [start]
   * @param {string} [end]
   * @param {ReservationStatus} [status]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {VipStatus} [vipStatus]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationSearchApi
   */
  public searchUserExportGet(
    propertyId?: string,
    guestOnly?: boolean,
    recordNumber?: number,
    firstName?: string,
    lastName?: string,
    crew?: string,
    corporateAccountId?: string,
    start?: string,
    end?: string,
    status?: ReservationStatus,
    continuationToken?: string,
    limit?: number,
    vipStatus?: VipStatus,
    options?: any
  ) {
    return ReservationSearchApiFp(this.configuration).searchUserExportGet(
      propertyId,
      guestOnly,
      recordNumber,
      firstName,
      lastName,
      crew,
      corporateAccountId,
      start,
      end,
      status,
      continuationToken,
      limit,
      vipStatus,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [propertyId]
   * @param {boolean} [guestOnly]
   * @param {number} [recordNumber]
   * @param {string} [firstName]
   * @param {string} [lastName]
   * @param {string} [crew]
   * @param {string} [corporateAccountId]
   * @param {string} [start]
   * @param {string} [end]
   * @param {ReservationStatus} [status]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {VipStatus} [vipStatus]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationSearchApi
   */
  public searchUserGet(
    propertyId?: string,
    guestOnly?: boolean,
    recordNumber?: number,
    firstName?: string,
    lastName?: string,
    crew?: string,
    corporateAccountId?: string,
    start?: string,
    end?: string,
    status?: ReservationStatus,
    continuationToken?: string,
    limit?: number,
    vipStatus?: VipStatus,
    options?: any
  ) {
    return ReservationSearchApiFp(this.configuration).searchUserGet(
      propertyId,
      guestOnly,
      recordNumber,
      firstName,
      lastName,
      crew,
      corporateAccountId,
      start,
      end,
      status,
      continuationToken,
      limit,
      vipStatus,
      options
    )(this.axios, this.basePath);
  }
}
