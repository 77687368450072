import defaultsDeep from 'lodash.defaultsdeep';

export interface IAppConfig {
  readonly Version: string;
  readonly Api: {
    readonly BaseUrl: string;
    readonly EnvironmentName: string;
    readonly CustomerCode: string;
    readonly Auth: {
      readonly ClientId: string;
      readonly ClientSecret: string;
      readonly GrantType: string;
      readonly RefreshGrantType: string;
      readonly Scope: string;
      readonly UserKeyGrantType: string;
    };
  };
  readonly AuthorizeNet: {
    readonly Env: string;
    readonly LoginId: string;
    readonly ClientKey: string;
  };
  readonly Google: {
    readonly TagManagerId: string;
  };
}

const configDefaults = {
  Version: process.env.REACT_APP_VERSION,
  Api: {
    BaseUrl: process.env.REACT_APP_API_BASE_URL,
    EnvironmentName: process.env.REACT_APP_API_ENVIRONMENT_NAME,
    CustomerCode: process.env.REACT_APP_API_CUSTOMER_CODE,
    Auth: {
      ClientId: process.env.REACT_APP_API_AUTH_CLIENT_ID,
      ClientSecret: process.env.REACT_APP_API_AUTH_CLIENT_SECRET,
      GrantType: process.env.REACT_APP_API_AUTH_GRANT_TYPE,
      RefreshGrantType: process.env.REACT_APP_API_AUTH_REFRESH_GRANT_TYPE,
      Scope: process.env.REACT_APP_API_AUTH_SCOPE,
      UserKeyGrantType: process.env.REACT_APP_API_AUTH_USERKEY_GRANT_TYPE,
    },
  },
  AuthorizeNet: {
    Env: process.env.REACT_APP_API_AUTHORIZE_NET_ENV,
    LoginId: process.env.REACT_APP_API_AUTHORIZE_NET_LOGIN_ID,
    ClientKey: process.env.REACT_APP_API_AUTHORIZE_NET_CLIENT_KEY,
  },
  Google: {
    TagManagerId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  },
} as IAppConfig;

function getRuntimeConfig(): IAppConfig {
  let bundleConfig = {} as IAppConfig;
  try {
    bundleConfig = JSON.parse(document.getElementById('runtime-config')?.innerHTML ?? '{}');
  } catch (error) {
    if (process.env.NODE_ENV === 'production') {
      console.warn('Runtime application config failed to load.', error);
    }
  }

  return defaultsDeep(bundleConfig, configDefaults);
}

export const AppConfig = getRuntimeConfig();
