import React, { useCallback, useEffect, useState } from 'react';
import { Button, DialogContent, Grid, MenuItem } from '@material-ui/core';
import { FormStatus, Reservations } from '@lib/state';
import {
  ErrorDisplay,
  FormCheckbox,
  FormDatePicker,
  FormHub,
  FormSelect,
  StyledDialog,
  SubmitButton,
  useFormEvents,
} from '@lib/common';
import {
  CorporateAccount,
  CorporateInvoiceForms,
  corporateInvoiceService,
  getHumanizeInvoiceType,
  InvoiceType,
} from 'app/state';

interface Props {
  account: CorporateAccount;
  accounts?: Array<CorporateAccount>;
  createMultipleInvoices: boolean;
  createInvoiceErrorStatus?: Error;
}

interface FormFields {
  startDate: string;
  endDate: string;
  isDraft: boolean;
  invoiceType: InvoiceType;
}

export const CreateInvoice: React.FC<Props> = ({
  account,
  accounts,
  createMultipleInvoices,
  createInvoiceErrorStatus,
}) => {
  const [showModal, setShowModal] = useState(false);

  const [{ status, error }, resetUI] = useFormEvents(CorporateInvoiceForms.Create);

  useEffect(() => {
    if (status === FormStatus.Success && createInvoiceErrorStatus === undefined) {
      setShowModal(false);
      resetUI();
    }
  }, [status, resetUI, createInvoiceErrorStatus]);

  const onSubmit = useCallback(
    ({ startDate, endDate, isDraft, invoiceType }: FormFields) => {
      if (createMultipleInvoices && accounts !== undefined) {
        corporateInvoiceService.createMultipleInvoices(
          accounts,
          startDate,
          endDate,
          invoiceType,
          isDraft
        );
      } else {
        corporateInvoiceService.createInvoice(account.id, startDate, endDate, invoiceType, isDraft);
      }
    },
    [account, accounts, createMultipleInvoices]
  );

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => {
          resetUI();
          setShowModal(true);
        }}
      >
        Create Invoice
      </Button>
      <StyledDialog title="Create Invoice" open={showModal} onClose={() => setShowModal(false)}>
        <DialogContent>
          <FormHub<FormFields> onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormDatePicker
                  name="startDate"
                  label="Start Date"
                  fullWidth
                  validationOptions={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormDatePicker
                  name="endDate"
                  label="End Date"
                  fullWidth
                  validationOptions={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormSelect
                  name="invoiceType"
                  label="Invoice Type"
                  fullWidth
                  required
                  defaultValue={Reservations.InvoiceType.RoomInvoice}
                >
                  <MenuItem value={Reservations.InvoiceType.RoomInvoice}>
                    {getHumanizeInvoiceType(Reservations.InvoiceType.RoomInvoice)}
                  </MenuItem>
                  <MenuItem value={Reservations.InvoiceType.TaxCreditMemo}>
                    {getHumanizeInvoiceType(Reservations.InvoiceType.TaxCreditMemo)}
                  </MenuItem>
                </FormSelect>
              </Grid>
              <Grid item xs={12}>
                <FormCheckbox name="isDraft" label="Is Draft" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <ErrorDisplay error={error ?? createInvoiceErrorStatus} />
                <SubmitButton
                  variant="contained"
                  color="secondary"
                  fullWidth
                  pending={status === FormStatus.Pending}
                  disabled={status === FormStatus.Pending}
                >
                  Create
                </SubmitButton>
              </Grid>
            </Grid>
          </FormHub>
        </DialogContent>
      </StyledDialog>
    </>
  );
};
