import { getCancelToken } from '@lib/state';
import { QRCodeApi } from '@lib/state/api/generated/properties';
import { useState } from 'react';
import { useQuery } from 'react-query';

const qrCodeApi = new QRCodeApi();

interface Props {
  qrCodeId: string;
  propertyId: string;
}

export function useQRCodeUrlQuery(props: Props) {
  const [request, setRequest] = useState<Props>(props);

  const query = useQuery({
    queryKey: ['QRCode', request.qrCodeId, request.propertyId],
    queryFn: async ({ signal }) => {
      const { data } = await qrCodeApi.propertyIdQrcodeIdGenerateGet(
        request.qrCodeId,
        request.propertyId,
        getCancelToken(signal)
      );

      return data;
    },
  });

  return { request, setRequest, ...query };
}

interface RoomQueryProps {
  roomNumber: string;
  propertyId: string;
}

export function useQRCodeUrlRoomQuery(props: RoomQueryProps) {
  const [request, setRequest] = useState<RoomQueryProps>(props);

  const query = useQuery({
    queryKey: ['QRCodes', request.roomNumber, request.propertyId],
    queryFn: async ({ signal }) => {
      const { data } = await qrCodeApi.propertyIdQrcodeRoomNumberGenerateRoomGet(
        request.roomNumber,
        request.propertyId,
        getCancelToken(signal)
      );

      return data.data;
    },
  });

  return { request, setRequest, ...query };
}
