import { lightFormat, parseISO } from 'date-fns';

export function getDate(date: Date | string): Date {
  if (typeof date === 'string') {
    return parseISO(date);
  }
  return date;
}

export function formatDateMidnight(date: Date | string) {
  return lightFormat(getDate(date), "yyyy-MM-dd'T'00:00:00");
}

export function formatDate(date: Date | string) {
  return lightFormat(getDate(date), 'yyyy-MM-dd');
}
