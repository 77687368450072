import React, { useState, useEffect, useCallback } from 'react';
import { sessionQuery, PointOfSale } from '@lib/state';
import { useObservable, Section } from '@lib/common';
import { endOfMonth, startOfMonth } from 'date-fns';
import { formatDateMidnight, getDate } from 'app/shared';
import { Container } from '@material-ui/core';
import { PurchaseTransactionHeader } from './purchase-transaction-header';
import { PurchaseTransactionTable } from './purchase-transaction-table';
import { reportQuery, reportService } from 'app/state';

export const PurchaseTransactionReport: React.FC = () => {
  const [startDate, setStartDate] = useState(() => formatDateMidnight(startOfMonth(new Date())));
  const [endDate, setEndDate] = useState(() => formatDateMidnight(endOfMonth(new Date())));
  const [purchaseType, setPurchaseType] = useState<PointOfSale.PurchaseType | undefined>(undefined);
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const purchaseTransactions = useObservable(reportQuery.purchaseTransactions, 'async');
  const pagination = useObservable(reportQuery.pagination);

  useEffect(() => {
    if (propertyId)
      reportService.loadPurchaseTransactions(
        propertyId,
        getDate(startDate),
        getDate(endDate),
        purchaseType
      );
  }, [propertyId, startDate, endDate, purchaseType]);

  const exportTransactions = useCallback(() => {
    reportService.exportTransactions(
      propertyId!,
      getDate(startDate),
      getDate(endDate),
      purchaseType
    );
  }, [propertyId, startDate, endDate, purchaseType]);

  if (!propertyId || !pagination) return null;

  const loadMore = () =>
    reportService.loadPurchaseTransactions(
      propertyId,
      getDate(startDate),
      getDate(endDate),
      purchaseType,
      pagination.continuationToken
    );

  return (
    <Container maxWidth="xl">
      <PurchaseTransactionHeader
        startDate={startDate}
        endDate={endDate}
        purchaseType={purchaseType}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setPurchaseType={setPurchaseType}
        exportpurchases={exportTransactions}
      />
      <Section title="Purchase Transaction Report" textAlign="left" disableGutters>
        <PurchaseTransactionTable
          transactions={purchaseTransactions}
          loadMore={loadMore}
          isDone={pagination.isDone}
        />
      </Section>
    </Container>
  );
};
