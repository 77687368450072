import { applyTransaction, setLoading } from '@datorama/akita';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  GuestManagementStore,
  guestManagementStore,
  GuestManagementUIState,
} from './guest-management.store';
import {
  Auth,
  Reservations,
  GuestProfilesForms,
  GuestProfile,
  dispatchForm,
  getE164FormattedPhoneNumber,
  PaginationState,
  RequestCancellation,
  Debounce,
  CommonForm,
} from '@lib/state';

export class GuestManagementService {
  constructor(
    private readonly store: GuestManagementStore,
    private readonly guestsApi: Reservations.GuestsApi,
    private readonly accountApi: Auth.AccountApi
  ) {}

  getGuestById(userId: GuestProfile['userId']) {
    from(this.guestsApi.guestsGuestUserIdGet(userId))
      .pipe(
        map(({ data }) => data.data),
        switchMap(profile =>
          from(this.accountApi.accountVerificationGet(profile.userId)).pipe(
            map(verification => verification.data.data),
            map(verification => {
              return Object.assign(profile, {
                emailVerfied: verification.emailVerified,
                phoneNumberVerified: verification.phoneNumberVerified,
              });
            })
          )
        )
      )
      .subscribe(guest =>
        applyTransaction(() => {
          this.store.upsert(userId, guest);
        })
      );
  }

  getGuests(filters: GuestManagementUIState, continuationToken?: string | null, limit?: number) {
    this.store.setLoading(true);
    this.searchGuests(filters, continuationToken ?? null, limit ?? undefined)
      .pipe(
        setLoading(this.store),
        map(response => response.data)
      )
      .subscribe(data =>
        applyTransaction(() => {
          this.store.upsertMany(data.data);
          this.updateUI(filters);
          this.updatePaginationState({
            isDone: data.isDone,
            continuationToken: data.continuationToken,
          });
        })
      );
  }

  @Debounce(500)
  @RequestCancellation()
  private searchGuests(
    {
      firstName,
      lastName,
      email,
      phone,
      propertyId,
      showArchived,
      vipStatus,
    }: GuestManagementUIState,
    continuationToken: string | null,
    limit: number | undefined,
    options?: any
  ) {
    return from(
      this.guestsApi.guestsSearchGet(
        firstName ?? undefined,
        lastName ?? undefined,
        email ?? undefined,
        phone ?? undefined,
        propertyId ?? undefined,
        showArchived,
        continuationToken ?? undefined,
        limit ?? undefined,
        vipStatus,
        options
      )
    );
  }
  exportMyGuests(
    {
      firstName,
      lastName,
      email,
      phone,
      propertyId,
      showArchived,
      vipStatus,
    }: GuestManagementUIState,
    options?: any
  ) {
    from(
      this.guestsApi.guestsExportGet(
        firstName ?? undefined,
        lastName ?? undefined,
        email ?? undefined,
        phone ?? undefined,
        propertyId ?? undefined,
        showArchived,
        undefined,
        0,
        vipStatus,
        options
      )
    )
      .pipe(
        map(x => new Blob([x.data], { type: 'text/csv' })),
        dispatchForm(CommonForm.Export)
      )
      .subscribe(x => saveAs(x, 'Guests.csv'));
  }

  updateGuestProfile({
    userId,
    name,
    contact,
    address = {},
    gender,
    language,
    archived,
    vipStatus,
  }: Reservations.GuestProfileModel): void {
    contact.phone = !!contact.phone ? getE164FormattedPhoneNumber(contact.phone) : undefined;

    from(
      this.accountApi.accountUpdatePost({
        userId,
        email: contact.email!,
        phoneNumber: contact.phone,
      })
    )
      .pipe(
        switchMap(() =>
          this.guestsApi.guestsGuestUserIdPost(userId, {
            name,
            address,
            gender,
            language,
            archived,
            vipStatus,
          })
        ),
        dispatchForm(GuestProfilesForms.UpdateProfile)
      )
      .subscribe(() =>
        this.store.update(({ guest }) => ({
          guest: {
            ...(guest ?? { userId: '', contact: {} }),
            name,
            address,
            gender,
            language,
            archived,
          },
        }))
      );
  }

  private updateUI(state: Partial<GuestManagementUIState>) {
    this.store.update(({ ui }) => ({
      ui: { ...ui, ...state },
    }));
  }

  private updatePaginationState(state: Partial<PaginationState>) {
    this.store.update(({ pagination }) => ({
      pagination: { ...pagination, ...state },
    }));
  }

  select(userId?: string) {
    this.store.setActive(userId ?? null);
  }
}

export const guestManagementService = new GuestManagementService(
  guestManagementStore,
  new Reservations.GuestsApi(),
  new Auth.AccountApi()
);
