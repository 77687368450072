import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Property, Room } from '@lib/state';
import { RoomAccess } from './room-access.model';

export interface RoomAccessUIState {
  propertyId?: Property['id'];
  roomNumber?: Room['roomNumber'];
}

export interface RoomAccessState extends EntityState<RoomAccess, RoomAccess['userId']> {
  ui: RoomAccessUIState;
}

@StoreConfig({ name: 'room-entry', resettable: true, idKey: 'userId' })
export class RoomAccessStore extends EntityStore<RoomAccessState> {
  constructor() {
    super({
      ui: {},
    });
  }
}

export const roomAccessStore = new RoomAccessStore();
