import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

interface Props extends TypographyProps {
  value?: number | null;
  addon?: boolean;
  accounting?: boolean;
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const accountingFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  //@ts-ignore  https://github.com/microsoft/TypeScript/issues/36533
  currencySign: 'accounting',
});

export function formatCurrency(value: number, accounting = false) {
  if (accounting) {
    return accountingFormatter.format(value).replace('$', '');
  } else {
    return currencyFormatter.format(value);
  }
}

export const CurrencyDisplay: React.FC<Props> = ({
  value,
  addon,
  accounting,
  children,
  ...props
}) => (
  <>
    {value != null && (
      <Typography component="span" display="block" {...props}>
        {!!addon ? '+ ' : ''}
        {formatCurrency(value, accounting || false)}
        {children}
      </Typography>
    )}
  </>
);
