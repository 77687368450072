import { Reservations } from '@lib/state';

export type CreateCorporateContactRequest = Reservations.CreateCorporateContactRequest;
export type UpdateCorporateContactRequest = Reservations.UpdateCorporateContactRequest;
export type CorporateContactModel = Reservations.CorporateContactModel;
export enum CorporateContactType {
  Emergency = 'Emergency',
  Sales = 'Sales',
  AccountsPayable = 'Accounts Payable',
}

export function GetContactType(contact: Reservations.CorporateContactType) {
  switch (contact) {
    case Reservations.CorporateContactType.AccountsPayable:
      return CorporateContactType.AccountsPayable;
    case Reservations.CorporateContactType.Emergency:
      return CorporateContactType.Emergency;
    case Reservations.CorporateContactType.Sales:
      return CorporateContactType.Sales;
  }
}
