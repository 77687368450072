import React, { useCallback, useState } from 'react';
import { TypographyProps } from '@material-ui/core';

import { FormStatus, Reservation, reservationService } from '@lib/state';
import { useUniqueId } from '../../utils';
import { EditableDisplay, useFormEvents } from '../../forms';

type ReservationLike = Pick<Reservation, 'id' | 'billingReference'>;

interface Props {
  reservation: Reservation | ReservationLike;

  labelVariant?: TypographyProps['variant'];
  valueVariant?: TypographyProps['variant'];
  dense?: boolean;
}

export const BillingReferenceEditableDisplay: React.FC<Props> = ({ reservation, ...props }) => {
  const formId = useUniqueId('billing-reference');

  const [{ status, error }] = useFormEvents(formId);

  const onSubmit = useCallback(
    (value?: string) => {
      reservationService.changeCostCenterFields(
        reservation.id,
        {
          key: 'BillingReference',
          value,
        },
        formId
      );
    },
    [reservation, formId]
  );

  return (
    <EditableDisplay
      label="Billing Reference"
      value={reservation.billingReference ?? ''}
      onSubmit={onSubmit}
      validationOptions={{
        required: {
          value: true,
          message: 'Field is required.',
        },
        maxLength: {
          value: 80,
          message: 'Maximum of 80 characters.',
        },
      }}
      pending={status == FormStatus.Pending}
      error={error}
      {...props}
    />
  );
};

export default BillingReferenceEditableDisplay;
