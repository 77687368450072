import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { Address, FormStatus } from '@lib/state';
import {
  AddressForm,
  ContactInformationForm,
  ErrorDisplay,
  FormNumberField,
  FormTextField,
  FormTimezoneField,
  LoadingIndicator,
  NameForm,
  PasswordForm,
  Section,
  SectionVariant,
  SubmitButton,
} from '@lib/common';

export interface CustomerSignupFields {
  admin: {
    name: {
      first: string;
      last: string;
    };
    contact: {
      email: string;
      phone: string;
    };
  };
  password: string;
  confirmPassword: string;
  customerName: string;
  propertyName: string;
  timezone: string;
  initialRoomType: string;
  initialRoomRate: number;
  numberOfRooms?: number;
  address: Address;
}

interface Props {
  status: FormStatus;
  error?: Error;
  variant?: SectionVariant;
}

export const CustomerSignupForm: React.FC<Props> = ({ status, error, variant }) => {
  return (
    <>
      <Section title="Admin User Information" variant={variant}>
        <NameForm prefix="admin" />
        <ContactInformationForm prefix="admin" required />
        <PasswordForm />
      </Section>
      <Section title="Company & Property Information" variant={variant}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormTextField
              name="customerName"
              label="Company Name"
              type="text"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              name="propertyName"
              label="Property Name"
              type="text"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTimezoneField name="timezone" label="Timezone" fullWidth required />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              name="initialRoomType"
              label="Room Type"
              type="text"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormNumberField
              name="initialRoomRate"
              label="Room Rate"
              defaultValue={''}
              fullWidth
              required
              validationOptions={{
                min: {
                  value: 0,
                  message: 'Value cannot be less than zero',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormNumberField
              name="numberOfRooms"
              label="Number Of Rooms"
              defaultValue={''}
              fullWidth
              validationOptions={{
                max: {
                  value: 1000,
                  message: 'Value cannot be more than 1000',
                },
                min: {
                  value: 1,
                  message: 'Value cannot be less than one',
                },
              }}
            />
          </Grid>
        </Grid>

        <Box mb={4}>
          <AddressForm />
        </Box>

        <ErrorDisplay error={error} />
        {status === FormStatus.Pending ? <LoadingIndicator loadingText="Processing..." /> : null}
        <SubmitButton
          variant="contained"
          color="secondary"
          fullWidth
          pending={status === FormStatus.Pending}
          disabled={status === FormStatus.Pending}
        >
          Register
        </SubmitButton>
      </Section>
    </>
  );
};
