import React, { memo } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

interface Props extends TypographyProps {
  value?: string | string[];

  disableTypography?: boolean;
}

export const EnumValueDisplay: React.FC<Props> = memo(({ value, disableTypography, ...props }) => {
  if (!value) return null;

  const list = Array.isArray(value) ? value : [value];
  const formatted = list
    .map(val => val?.split(/((?<=[a-z])(?=[A-Z])(?![A-Z][a-z])|(?=[A-Z](?=[a-z])))/)?.join(' '))
    .join(', ');

  if (disableTypography) return <>{formatted}</>;

  return <Typography {...props}>{formatted}</Typography>;
});
