import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import { CorporateAccount, GuestProfile } from '@lib/state';
import {
  AddressForm,
  CostCenterForm,
  FormPhoneField,
  NameForm,
  RoomListForm,
  Section,
  SectionVariant,
  useFieldLabelProps,
  GuestPictureContainerComponent,
  GuestEntryForm,
  Feedback,
} from '@lib/common';
import { GuestEmailLookupInput } from 'app/shared';
import { GuestProfileModel } from '@lib/state/api/generated/reservations';

interface Props {
  useRearCam: boolean;
  className?: string;
  variant?: SectionVariant;
  setUserId(userId: string): void;
  quantity: number;
  corporateBooking?: CorporateAccount;
  guestCount: number | undefined;
  delegateGuest?: GuestProfileModel;
}

export const GuestLookupForm: React.FC<Props> = ({
  useRearCam,
  setUserId,
  quantity,
  children,
  corporateBooking,
  guestCount = 1,
  delegateGuest,
  ...sectionProps
}) => {
  const { setValue } = useFormContext();
  const [guestContact, setGuestContact] = useState<GuestProfile | null>(null);

  const setContactInfo = useCallback(
    (profile: GuestProfile) => {
      const { contact, address, name, userId } = profile;
      setValue('contact.contact.phone', contact.phone);
      setValue('contact.contact.email', contact.email);
      setValue('contact.name', name);
      setValue('contact.address', address);
      setUserId(userId);
      setGuestContact(profile);
    },
    [setValue, setUserId]
  );

  const resetContactInfo = () => {
    setValue('contact.contact.phone', '');
    setValue('contact.name', {});
    setValue('contact.address', {});
    setUserId('');
    setGuestContact(null);
    setValue('rooms', []);
  };

  useEffect(() => {
    if (delegateGuest) {
      setContactInfo(delegateGuest);
      setCorporateAccountDisableFields(true);
    }
    // eslint-disable-next-line
  }, [delegateGuest]);

  const contactPhoneLabelProps = useFieldLabelProps('contact.contact.phone');

  const [corporateAccountDisableFields, setCorporateAccountDisableFields] = useState<boolean>(
    false
  );

  return (
    <>
      <Section title="Contact Info" maxWidth="md" {...sectionProps}>
        <GuestPictureContainerComponent guest={guestContact} prefix="contact">
          <Feedback show={!!corporateAccountDisableFields} severity="info">
            Delegate information can only be updated through their guest profile. Select a different
            delegate by clicking Change Dates.
          </Feedback>
          <br />
          <Grid
            container
            spacing={1}
            style={{ pointerEvents: corporateAccountDisableFields ? 'none' : 'auto' }}
          >
            <Grid item md={6} xs={12}>
              <GuestEmailLookupInput
                name="contact.contact.email"
                label="Email"
                onGuestSelected={profile => {
                  resetContactInfo();
                  if (profile) setContactInfo(profile);
                }}
                onClear={() => resetContactInfo()}
                required
                fullWidth
                defaultValue={delegateGuest?.contact.email}
                disabled={corporateAccountDisableFields}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormPhoneField
                name="contact.contact.phone"
                fullWidth
                InputLabelProps={contactPhoneLabelProps}
                defaultValue={delegateGuest?.contact.phone}
                disabled={corporateAccountDisableFields}
              />
            </Grid>
          </Grid>
          <NameForm prefix="contact" disabled={corporateAccountDisableFields} />
        </GuestPictureContainerComponent>
        <AddressForm prefix="contact" />
      </Section>
      <CostCenterForm corporateAccount={corporateBooking} {...sectionProps} />
      <RoomListForm
        variant="contained"
        corporateBooking={corporateBooking}
        roomQuantity={quantity}
        maxGuestCount={guestCount}
        guestEntryForm={props => <GuestEntryForm {...props} />}
        useRearCam={useRearCam}
      />
    </>
  );
};
