import {
  ErrorDisplay,
  Loading,
  StyledDialog,
  SubmitButton,
  useFormEvents,
  useGuestStays,
  useObservable,
} from '@lib/common';
import {
  FormStatus,
  QRCodeStatus,
  Room,
  RoomForms,
  roomsQuery,
  roomsService,
  sessionQuery,
} from '@lib/state';
import { DialogActions, DialogContent, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQRCodes } from 'app/qr-codes';
import { RoomAccessControlTab, RoomDetailTab, RoomQRCodes } from './room-detail-tabs';
import { lightFormat, startOfDay } from 'date-fns';
import { RoomLogsTab } from './room-detail-tabs/room-logs-tab';

interface Props {
  room: Room;
  propertyId: string;
  open: boolean;
  onClose: () => void;
}

export function RoomDetailsModal({ room, propertyId, open, onClose }: Props) {
  const [{ status, error }, reset] = useFormEvents(RoomForms.UnlockDoor);

  const user = useObservable(sessionQuery.currentUser);
  const isAdmin = user?.role.includes('Administrator');

  useEffect(() => {
    roomsService.getRoomById(propertyId, room.id, true);
  }, [room, propertyId]);

  const updatedRoom = useObservable(roomsQuery.activeRoom);

  useEffect(() => {
    if (updatedRoom) roomsService.getRoomDoorControllerStatus(updatedRoom);
    // eslint-disable-next-line
  }, [updatedRoom?.id]);

  const onUnlockClick = useCallback(() => {
    if (isAdmin) roomsService.unlockRoomDoor(room.propertyId, room.roomNumber);
  }, [room, isAdmin]);

  const { stays, loading } = useGuestStays(
    propertyId,
    lightFormat(startOfDay(new Date(Date.now())), 'yyyy-MM-dd')
  );

  const filters = useMemo(() => {
    return { roomNumber: room.roomNumber, propertyId: propertyId, status: QRCodeStatus.Active };
  }, [propertyId, room]);

  const { qrCodes, loading: qrCodesLoading } = useQRCodes(filters);

  const roomStay = useMemo(() => stays.find(s => s.roomNumber === room.roomNumber), [stays, room]);

  const [tab, setTab] = useState('details');

  const onModalClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  return (
    <StyledDialog title={room.roomNumber} onClose={onModalClose} open={open} scroll="paper">
      <TabContext value={tab}>
        {room && (
          <>
            <Tabs
              value={tab}
              onChange={(_, x) => setTab(x)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Room Details" value="details" />
              <Tab label="QR Codes" value="qrcodes" />
              {isAdmin && updatedRoom?.doorControllerStatus?.isProvisioned && (
                <Tab label="Device" value="device" />
              )}
              <Tab label="Logs" value="logs" />
            </Tabs>
            <DialogContent>
              <ErrorDisplay error={error} />
              {loading || qrCodesLoading ? (
                <Loading />
              ) : (
                <>
                  <TabPanel value="details">
                    <RoomDetailTab room={updatedRoom ?? room} occupied={!!roomStay} />
                  </TabPanel>
                  <TabPanel value="qrcodes">
                    <RoomQRCodes
                      roomNumber={room.roomNumber}
                      qrCodes={qrCodes}
                      propertyId={propertyId}
                    />
                  </TabPanel>
                  <TabPanel value="device">
                    <RoomAccessControlTab room={updatedRoom} />
                  </TabPanel>
                  <TabPanel value="logs">
                    <RoomLogsTab roomId={room.id} />
                  </TabPanel>
                </>
              )}
            </DialogContent>
            {isAdmin && updatedRoom?.doorControllerStatus?.isProvisioned && false && (
              <DialogActions>
                <SubmitButton onClick={onUnlockClick} pending={status === FormStatus.Pending}>
                  Unlock Door
                </SubmitButton>
              </DialogActions>
            )}
          </>
        )}
      </TabContext>
    </StyledDialog>
  );
}
