import React from 'react';
import { makeStyles, Select, MenuItem, Button, Grid, InputLabel } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PointOfSale } from '@lib/state';
import { WebOnly } from '@lib/common';
import { FilterSection, formatDateMidnight } from 'app/shared';

const useStyles = makeStyles(theme => ({
  download: {
    textDecoration: 'none !important',
  },
}));

interface Props {
  startDate: string;
  endDate: string;
  purchaseType?: PointOfSale.PurchaseType;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  setPurchaseType: (status?: PointOfSale.PurchaseType) => void;
  exportpurchases: () => void;
}

export const PurchaseTransactionHeader: React.FC<Props> = ({
  startDate,
  endDate,
  purchaseType,
  setStartDate,
  setEndDate,
  setPurchaseType,
  exportpurchases,
}) => {
  const { download } = useStyles();
  return (
    <FilterSection>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={date => {
              if (date) setStartDate(formatDateMidnight(date));
            }}
            format="MM/dd/yyyy"
            autoOk
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={date => {
              if (date) setEndDate(formatDateMidnight(date));
            }}
            format="MM/dd/yyyy"
            autoOk
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel>Purchase Type</InputLabel>
          <Select
            label="Type"
            value={purchaseType ?? 'All'}
            fullWidth
            onChange={e =>
              setPurchaseType(
                e.target.value === 'All' ? undefined : (e.target.value as PointOfSale.PurchaseType)
              )
            }
          >
            <MenuItem value={'All'}>All</MenuItem>
            <MenuItem value={PointOfSale.PurchaseType.Admin}>Admin</MenuItem>
            <MenuItem value={PointOfSale.PurchaseType.Amenity}>Amenity</MenuItem>
            <MenuItem value={PointOfSale.PurchaseType.Charge}>Charge</MenuItem>
            <MenuItem value={PointOfSale.PurchaseType.HappyHour}>Happy Hour</MenuItem>
            <MenuItem value={PointOfSale.PurchaseType.Failed}>Failed</MenuItem>
          </Select>
        </Grid>
        <WebOnly>
          <Grid container item xs={12} spacing={1} justify="flex-end">
            <Grid item>
              <Button
                className={download}
                variant="contained"
                color="secondary"
                startIcon={<FontAwesomeIcon icon="download" />}
                onClick={exportpurchases}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </WebOnly>
      </Grid>
    </FilterSection>
  );
};
