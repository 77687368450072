import React, { useEffect } from 'react';
import { TextField, Button, Grid, Typography } from '@material-ui/core';
import {
  useInput,
  useForm,
  required,
  email as isEmail,
  Feedback,
  FormProps,
  ErrorDisplay,
} from '../../forms';
import { autoCompleteIfEnabled } from '../../utils';

export interface ForgotPasswordFormFields {
  username: string;
}

interface Props extends FormProps<ForgotPasswordFormFields> {
  onCancel: () => void;
}

export const ForgotPasswordForm: React.FC<Props> = ({
  pending,
  success,
  error,
  onSubmit,
  onResetFeedback,
  onCancel,
}) => {
  const username = useInput('', { validators: [required(), isEmail()] });
  const form = useForm(() => {
    onResetFeedback();
    onSubmit({
      username: username.value,
    });
  }, username);

  useEffect(() => {
    if (success || error) form.reset();
  }, [success, error, form]);

  return (
    <form {...form.bind} data-testid="forgotPassForm">
      <ErrorDisplay error={error} />
      <Feedback show={!!success} severity="success" onHide={onResetFeedback}>
        An email with a link to reset your password has been sent to the address provided.
      </Feedback>
      {!success && (
        <>
          <TextField
            name="username"
            label="Email"
            value={username}
            {...username.bind}
            autoComplete={autoCompleteIfEnabled('email')}
            type="email"
            fullWidth
            style={{ marginBottom: 5 }}
          />
          <Grid container spacing={4}>
            <Grid item xs>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                disabled={!form.valid}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                onClick={onCancel}
                variant="outlined"
                size="large"
                fullWidth
                data-testid="forgotPassFormCancelButton"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </form>
  );
};
