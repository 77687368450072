import { Properties } from '@lib/state';

export type Area = Properties.AreaModel & { rooms?: Array<Properties.RoomModel> };

export interface AreaFilters {
  name: string;
}

export function mapArea(area: Properties.AreaModel, rooms?: Array<Properties.RoomModel>) {
  return {
    ...area,
    rooms: rooms?.sort((x, y) =>
      x.roomNumber.localeCompare(y.roomNumber, undefined, { sensitivity: 'base' })
    ),
  };
}
