import React from 'react';
import { MenuItem } from '@material-ui/core';
import { EnumValueDisplay, FormSelect, FormTextFieldProps, TextFieldProps } from '@lib/common';
import { ArmDeviceProvisioningPolicyType } from '@lib/state';

type Props = Omit<FormTextFieldProps, 'validationOptions' | 'type'> & TextFieldProps;

export const FormDevicePolicyField: React.FC<Props> = ({
  label = 'Device Type',
  defaultValue,
  required,
  ...props
}) => {
  return (
    <FormSelect {...props} label={label} defaultValue={defaultValue} fullWidth required={required}>
      {Object.keys(ArmDeviceProvisioningPolicyType).map(key => (
        <MenuItem key={key} value={key}>
          <EnumValueDisplay value={key} />
        </MenuItem>
      ))}
    </FormSelect>
  );
};
