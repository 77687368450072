import { applyTransaction } from '@datorama/akita';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Properties } from '../api';
import { CreateRoomTypeRequest, UpdateRoomTypeRequest } from '../api/generated/properties';
import { dispatchForm } from '../forms';
import { RoomTypeForms } from './room-type.form';
import { RoomType } from './room-type.model';
import { RoomTypeStore, RoomTypeUIState, roomTypeStore } from './room-type.store';

export class RoomTypeService {
  constructor(
    private readonly store: RoomTypeStore,
    private readonly roomTypeApi: Properties.RoomTypeApi
  ) {}

  getPropertyRoomTypes(propertyId: string) {
    from(this.roomTypeApi.roomtypePropertyGet(propertyId))
      .pipe(map(response => response.data.data))
      .subscribe(roomTypes =>
        applyTransaction(() => {
          this.updateUI({ propertyId });
          this.store.upsertMany(roomTypes);
        })
      );
  }

  getAllRoomTypes(): void {
    from(this.roomTypeApi.roomtypeGetallGet())
      .pipe(map(response => response.data.data))
      .subscribe(roomTypes =>
        applyTransaction(() => {
          this.store.upsertMany(roomTypes);
        })
      );
  }

  getRoomType(roomTypeId: string): void {
    from(this.roomTypeApi.roomtypeIdGet(roomTypeId))
      .pipe(map(response => response.data.data))
      .subscribe(data => this.store.upsert(data.id, data));
  }

  getRoomTypes(...roomTypeIds: Array<RoomType['id']>): void {
    from(this.roomTypeApi.roomtypeGet(roomTypeIds))
      .pipe(map(response => response.data.data))
      .subscribe(roomTypes => this.store.upsertMany(roomTypes));
  }

  updateRoomType(id: string, propertyId: string, request: UpdateRoomTypeRequest): void {
    from(this.roomTypeApi.roomtypeIdPost(id, request))
      .pipe(
        dispatchForm(RoomTypeForms.UpdateRoomType),
        map(response => response.data.data)
      )
      .subscribe(data => {
        this.store.upsert(id, data);
      });
  }

  createRoomType(request: CreateRoomTypeRequest): void {
    from(this.roomTypeApi.roomtypePost(request))
      .pipe(
        dispatchForm(RoomTypeForms.CreateRoomType),
        map(response => response.data.data)
      )
      .subscribe(data => {
        this.store.upsert(data.id, data);
      });
  }

  selectRoomType(id?: string | null) {
    if (id) this.getRoomType(id);

    this.store.setActive(id ?? null);
  }

  private updateUI(values: Partial<RoomTypeUIState>) {
    this.store.update(({ ui }) => ({ ui: { ...ui, ...values } }));
  }
}

export const roomTypeService = new RoomTypeService(roomTypeStore, new Properties.RoomTypeApi());
