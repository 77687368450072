import React, { useCallback, useEffect, useState } from 'react';
import { Grid, makeStyles, createStyles } from '@material-ui/core';

import { FormStatus, Properties } from '@lib/state';
import { ErrorDisplay, FormFileField, FormHub, SubmitButton, useFormEvents } from '@lib/common';
import { MaintenanceTicketForms, maintenanceTicketService } from 'app/state';

interface Props {
  ticket?: Properties.MaintenanceTicketModel;
}

const useStyles = makeStyles(theme =>
  createStyles({
    fullWidth: {
      width: '100%',
    },
    saveButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  })
);
export const MaintenanceTicketPhotoUpload: React.FC<Props> = ({ ticket }) => {
  const styles = useStyles();
  const [{ status, error }, resetUI] = useFormEvents(MaintenanceTicketForms.CreatePhoto);
  const [{ error: deleteError }] = useFormEvents(MaintenanceTicketForms.DeletePhoto);
  const [submitCount, setSubmitCount] = useState(0);

  const onSubmit = useCallback(
    ({ file }) => {
      maintenanceTicketService.createMaintenanceTicketPhotos(ticket?.id!, file[0]);
      setSubmitCount(count => count + 1);
    },
    // eslint-disable-next-line
    [ticket]
  );

  useEffect(() => {
    if (status === FormStatus.Success) resetUI();
  }, [status, resetUI]);

  return (
    <>
      <FormHub onSubmit={onSubmit} className={styles.fullWidth}>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <FormFileField name="file" accept="image/*" required key={submitCount} />
          </Grid>

          <Grid item xs={3} className={styles.saveButton}>
            <ErrorDisplay error={error ?? deleteError} />
            <SubmitButton
              variant="contained"
              color="primary"
              disabled={status === FormStatus.Pending}
            >
              Save
            </SubmitButton>
          </Grid>
        </Grid>
      </FormHub>
    </>
  );
};
