import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { max, parseISO, startOfYesterday } from 'date-fns';

import { formatDateMidnight, FilterSection } from 'app/shared';

type Props = React.PropsWithChildren<{
  startDate: string;
  endDate: string;
  excludeNotCharged: boolean;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  setExcludeNotCharged: (exclude: boolean) => void;
}>;

export const OccupancyHeader: React.FC<Props> = ({
  children,
  startDate,
  endDate,
  excludeNotCharged,
  setStartDate,
  setEndDate,
  setExcludeNotCharged,
}) => {
  return (
    <FilterSection>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={date => {
              if (date) setStartDate(formatDateMidnight(date));
            }}
            format="MM/dd/yyyy"
            autoOk
            maxDate={max([parseISO(startDate), startOfYesterday()])}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={date => {
              if (date) setEndDate(formatDateMidnight(date));
            }}
            format="MM/dd/yyyy"
            autoOk
            minDate={startDate}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={excludeNotCharged}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setExcludeNotCharged(e.target.checked)
                }
              />
            }
            label="Exclude Free Rooms"
          />
        </Grid>
        {children}
      </Grid>
    </FilterSection>
  );
};
