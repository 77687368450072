import React, { useCallback, useEffect } from 'react';

import { CorporateAccount, PolicyForm, ReservationPolicy } from '@lib/state';
import { useFormEvents } from '@lib/common';
import { CorporateAccountForms, corporateAccountService, PropertyConfiguration } from 'app/state';
import { CorporatePolicyForm } from './corporate-policy-form';
import { PolicyTimeConfigurationForm } from 'app/shared';

interface Props {
  account?: CorporateAccount;
  property?: PropertyConfiguration;
}

export const CorporateReservationPolicy: React.FC<Props> = ({ account, property }) => {
  const [updateEvent, reset] = useFormEvents(CorporateAccountForms.UpdateReservationPolicy);
  const [removeEvent, resetRemoveStatus] = useFormEvents(
    CorporateAccountForms.RemoveReservationPolicy
  );
  const [addEvent, resetAddStatus] = useFormEvents(CorporateAccountForms.AddReservationPolicy);

  useEffect(() => {
    resetRemoveStatus();
    resetAddStatus();
  }, [account, resetRemoveStatus, resetAddStatus]);

  const addOverride = useCallback(() => {
    if (account && property) corporateAccountService.addReservationOverride(account, property);
  }, [account, property]);

  const updateOverride = useCallback(
    (policy: PolicyForm) => {
      if (account) {
        corporateAccountService.updateReservationPolicy(account, policy as ReservationPolicy);
      }
    },
    [account]
  );

  const removeOverride = useCallback(() => {
    if (account) corporateAccountService.removeReservationPolicy(account);
  }, [account]);

  return (
    <CorporatePolicyForm
      policyType={{ policy: account?.reservation, title: 'Reservation' }}
      addOverride={addOverride}
      updateOverride={updateOverride}
      removeOverride={removeOverride}
      reset={reset}
      updateEvent={updateEvent}
      removeEvent={removeEvent}
      addEvent={addEvent}
    >
      <PolicyTimeConfigurationForm configuration={account?.reservation} />
    </CorporatePolicyForm>
  );
};
