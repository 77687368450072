import { Observable, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * Decorator that debounces the invocations of a method.
 * Method must return an observable.
 *
 * Note: The original method is invoked with the current state of the arguments.
 * Mutations of arguments will be included.
 *
 * @param time Number of milliseconds to debounce by.
 * @returns Decorator that debounces the invocations of a method.
 */
export function Debounce(time: number) {
  let $sub = new Subscription();

  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value;

    if (typeof original !== 'function') {
      throw new Error('Debounce can only be used as a method decorator.');
    }

    descriptor.value = function (...args: any[]) {
      $sub.unsubscribe();
      $sub = new Subscription();

      return new Observable(observer => {
        $sub.add(
          timer(time)
            .pipe(switchMap(() => original.apply(this, args)))
            .subscribe(observer)
        );
      });
    };

    return descriptor;
  };
}
