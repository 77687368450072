import React from 'react';
import { Grid } from '@material-ui/core';
import { FormFirstNameField, FormLastNameField, FormSubmitButton, kioskStyles } from '@lib/common';

export const ReservationSearchForm: React.FC = () => {
  const { kioskTextButton } = kioskStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormFirstNameField name="firstName" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <FormLastNameField name="lastName" required fullWidth />
      </Grid>
      <Grid item xs={12}>
        <FormSubmitButton
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          className={kioskTextButton}
        >
          Search
        </FormSubmitButton>
      </Grid>
    </Grid>
  );
};
