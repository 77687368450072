import React from 'react';
import { Container, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',

      backgroundImage: ({ image }: Props) => `url(${image})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',

      height: '100%',
    },
    overlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      width: '100%',
      height: '100%',
      backgroundImage:
        'linear-gradient(to right, rgba(18, 38, 58, 0.65), rgba(18, 38, 58, 0.45) 40%, rgba(0,0,0,0) 72%)',
    },
    textContainer: {
      maxWidth: '80%',
      position: 'relative',
      flex: 1,

      [theme.breakpoints.up('lg')]: {
        maxWidth: '50%',
      },
    },
    textContent: {
      position: 'relative',
      color: theme.palette.primary.contrastText,
    },
    itemContainer: {
      [theme.breakpoints.down('md')]: {
        maxWidth: theme.breakpoints.values['md'],
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 1.5rem !important',
      },
    },
  })
);

interface Props {
  image: string;
}

export const CarouselItem: React.FC<Props> = ({ children, ...props }) => {
  const styles = useStyles(props);

  return (
    <div className={styles.root}>
      <div className={styles.overlay}>
        <Container maxWidth="lg" className={styles.itemContainer}>
          <div className={styles.textContainer}>
            <div className={styles.textContent}>{children}</div>
          </div>
        </Container>
      </div>
    </div>
  );
};
