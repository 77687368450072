import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, DialogActions } from '@material-ui/core';

import { FormStatus } from '@lib/state';
import {
  CreditCardForm,
  GuestInformationForm,
  LoadingIndicator,
  ManagerOverrideInput,
  PaymentMethodSelect,
  Section,
  SectionVariant,
  StyledDialog,
  withAuthorizeNet,
} from '../../components';
import { ErrorDisplay, FormCheckbox, FormTextField, SubmitButton } from '../../forms';
import { useCurrentUserId, useGuestPaymentMethod, usePaymentMethods } from '../../utils';
import { useBookingValidationQueryStatus } from './useBookingValidationQuery';
import { BookingValidationDisplay } from './booking-validation-display';

interface Props extends PaymentFormProps {
  requiresPayment: boolean;
}

interface PaymentFormProps extends StatusProps {
  className?: string;
  variant?: SectionVariant;
  useGuestPaymentMethods?: boolean;
  guestUserId?: string | null;
  corporateAccountId?: string | null;
  isManagerOverrideRequired?: boolean;
}

interface StatusProps {
  status: FormStatus;
  error?: Error;
}

export const PaymentDetailsForm: React.FC<Props> = ({ requiresPayment, ...props }) => {
  return requiresPayment ? <BookingPaymentForm {...props} /> : <NoPaymentForm {...props} />;
};

const BookingPaymentFormComponent: React.FC<PaymentFormProps> = ({
  className,
  variant,
  useGuestPaymentMethods = false,
  guestUserId,
  corporateAccountId,
  isManagerOverrideRequired = false,
  status,
  error,
}) => {
  const { watch } = useFormContext();

  const propertyId = watch('propertyId');
  const useGuestForBilling = watch('useGuestForBilling', true);
  const paymentProfileId = watch('paymentProfileId');

  return (
    <Section
      className={className}
      title="Payment Details"
      maxWidth="md"
      variant={variant}
      data-testid="paymentDetailsForm"
    >
      {useGuestPaymentMethods ? (
        <>
          {guestUserId && (
            <GuestSavedPaymentMethods
              guestUserId={guestUserId}
              propertyId={propertyId}
              corporateAccountId={corporateAccountId ?? undefined}
            />
          )}
        </>
      ) : (
        <CurrentUserSavedPaymentMethods
          propertyId={propertyId}
          corporateAccountId={corporateAccountId ?? undefined}
        />
      )}
      {!paymentProfileId && (
        <>
          <CreditCardForm />
          <FormCheckbox
            defaultChecked
            name="useGuestForBilling"
            label="Billing info is the same as my contact info above"
            data-testid="paymentGuestBillingCheck"
          />
          {!useGuestForBilling && (
            <Box mb={2}>
              <GuestInformationForm prefix="billing" billing disablePersonalDetails />
            </Box>
          )}
        </>
      )}
      <Box mb={2}>
        <FormTextField
          name="billingReference"
          label="Billing Reference (Optional)"
          type="text"
          fullWidth
          data-testid="paymentGuestBillingReferenceInput"
        />
      </Box>

      {isManagerOverrideRequired && (
        <ManagerOverrideInput
          required={isManagerOverrideRequired}
          requiredMessage="The associated corporate account is configured to disallow employees booking on behalf of delegates. Reservations can only be booked after 9 PM and for one day without a manager override."
        />
      )}

      <StatusAndSubmitComponent error={error} status={status} />
    </Section>
  );
};

interface CurrentUserSavedPaymentMethodsProps {
  propertyId?: string;
  corporateAccountId?: string;
}

interface GuestSavedPaymentMethodsProps {
  propertyId: string;
  guestUserId: string;
  corporateAccountId?: string;
}

const CurrentUserSavedPaymentMethods: React.FC<CurrentUserSavedPaymentMethodsProps> = ({
  propertyId,
  corporateAccountId,
}) => {
  const { creditCards, invoiceAccounts } = usePaymentMethods(propertyId, corporateAccountId);
  const loggedInUserId = useCurrentUserId();

  if (!loggedInUserId) return null;

  return (
    <PaymentMethodSelect
      name="paymentProfileId"
      paymentMethods={creditCards}
      invoiceAccounts={invoiceAccounts}
      paymentUserId={loggedInUserId}
    />
  );
};

const GuestSavedPaymentMethods: React.FC<GuestSavedPaymentMethodsProps> = ({
  propertyId,
  guestUserId,
  corporateAccountId,
}) => {
  const { creditCards, invoiceAccounts } = useGuestPaymentMethod(
    guestUserId,
    propertyId,
    corporateAccountId
  );

  return (
    <PaymentMethodSelect
      name="paymentProfileId"
      paymentMethods={creditCards}
      invoiceAccounts={invoiceAccounts}
      paymentUserId={guestUserId}
    />
  );
};

const NoPaymentForm: React.FC<StatusProps> = ({ error, status }) => {
  return (
    <Box mt={2}>
      <StatusAndSubmitComponent error={error} status={status} />
    </Box>
  );
};

const StatusAndSubmitComponent: React.FC<StatusProps> = ({ error, status }) => {
  const {
    isValid,
    isFetching,
    message,
    reservations,
    employeeNumberEnabled,
  } = useBookingValidationQueryStatus();
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <StyledDialog
        open={isOpen}
        title="Confirm Booking Details"
        onClose={() => {}}
        disablePortal
        maxWidth="md"
      >
        <BookingValidationDisplay
          validation={{ isValid, message, reservations, employeeNumberEnabled }}
        />
        <DialogActions>
          <Button
            type="button"
            variant="contained"
            color="default"
            disabled={!!status && status === FormStatus.Pending}
            onClick={() => setOpen(false)}
            data-testid="paymentCancelCreateButton"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={!!status && status === FormStatus.Pending}
            onClick={() => setOpen(false)}
            data-testid="paymentIgnoreAndBookButton"
          >
            Ignore Warning and Book Anyway
          </Button>
        </DialogActions>
      </StyledDialog>

      <ErrorDisplay error={error} />
      {status == FormStatus.Pending ? <LoadingIndicator loadingText="Processing..." /> : null}
      <SubmitButton
        name="bookReservation"
        variant="contained"
        color="secondary"
        fullWidth
        pending={status === FormStatus.Pending}
        disabled={status === FormStatus.Pending || isFetching || !isValid}
        onClick={e => {
          if (message) {
            setOpen(true);
            e.preventDefault();
          }
        }}
        data-testid="paymentBookReservationButton"
      >
        Book Your Reservation
      </SubmitButton>
    </>
  );
};

const BookingPaymentForm = withAuthorizeNet(BookingPaymentFormComponent);
