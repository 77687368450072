import { QueryEntity } from '@datorama/akita';
import { switchMap } from 'rxjs/operators';
import { isWithinInterval, compareAsc } from 'date-fns';

import { sessionQuery, SessionQuery } from '@lib/state';
import { RoomCountState, roomCountStore, RoomCountStore } from './room-count.store';

export class RoomCountQuery extends QueryEntity<RoomCountState> {
  constructor(store: RoomCountStore, private readonly sessionQuery: SessionQuery) {
    super(store);
  }

  roomCounts = this.sessionQuery.propertyId.pipe(
    switchMap(propertyId => this.select(({ ui }) => ({ propertyId, ...ui }))),
    switchMap(({ propertyId, ...interval }) =>
      this.selectAll({
        filterBy: [x => x.propertyId === propertyId, x => isWithinInterval(x.date, interval)],
        sortBy: (a, b) => compareAsc(a.date, b.date),
      })
    )
  );
}

export const roomCountQuery = new RoomCountQuery(roomCountStore, sessionQuery);
