import {
  Button,
  createStyles,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { memo, useMemo } from 'react';
import { useFormContext, FormContextValues, useFieldArray } from 'react-hook-form';
import { parseISO } from 'date-fns';
import { AppColors, DateDisplay, FormNumberField } from '@lib/common';
import { CorporateAccount, RoomType } from '@lib/state';
import { areEqual } from 'react-window';
import {
  CorporateGroupFields,
  DateGroupFields,
  InventoryAdjustmentField,
  InventoryAdjustmentFormFields,
} from '../pages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  roomTypes: RoomType[];
  corporateAccounts: CorporateAccount[];
  canManageAdjustments: boolean;
}

const useStyles = makeStyles(theme =>
  createStyles({
    dateRow: {
      backgroundColor: theme.palette.grey[300],
    },
  })
);

const PropertyAdjustmentGroup = memo(
  ({
    date,
    dateGroupIdx,
    register,
    control,
    canManageAdjustments,
  }: {
    date: string;
    dateGroupIdx: number;
    register: FormContextValues<InventoryAdjustmentFormFields>['register'];
    control: FormContextValues<InventoryAdjustmentFormFields>['control'];
    canManageAdjustments: boolean;
  }) => {
    const { fields } = useFieldArray<InventoryAdjustmentField>({
      name: `groupAdjustments[${dateGroupIdx}].propertyAdjustments`,
      control,
    });

    return (
      <TableRow key={`PropertyAdjustmentGroup_${dateGroupIdx}_${date}`}>
        <TableCell />
        <TableCell width={200}>
          <Typography variant="body1">Inventory Adjustment</Typography>
        </TableCell>
        {fields.map((propertyAdjustment, roomTypeIdx) => {
          return (
            <React.Fragment key={propertyAdjustment.id}>
              <TableCell
                key={`groupAdjustments[${dateGroupIdx}].propertyAdjustments[${roomTypeIdx}]`}
                width={200}
              >
                <FormNumberField
                  name={`groupAdjustments[${dateGroupIdx}].propertyAdjustments[${roomTypeIdx}].quantity`}
                  {...register}
                  disabled={!canManageAdjustments}
                  defaultValue={propertyAdjustment.quantity ?? 0}
                />
                <input
                  ref={register()}
                  type="hidden"
                  name={`groupAdjustments[${dateGroupIdx}].propertyAdjustments[${roomTypeIdx}].roomTypeId`}
                />
              </TableCell>
            </React.Fragment>
          );
        })}
        <TableCell></TableCell>
      </TableRow>
    );
  },
  areEqual
);

export const InventoryAdjustmentsForm: React.FC<Props> = ({
  roomTypes,
  corporateAccounts,
  canManageAdjustments,
}) => {
  const { register, control, watch } = useFormContext<InventoryAdjustmentFormFields>();

  const form = watch({ nest: true });

  const { fields } = useFieldArray<DateGroupFields>({
    name: `groupAdjustments`,
    control,
  });

  const availableRoomTypes = useMemo(() => {
    if (!fields || fields.length === 0) return [];
    return roomTypes.filter(f =>
      fields[0].propertyAdjustments?.map(r => r.roomTypeId).includes(f.id)
    );
  }, [fields, roomTypes]);

  if (!fields) return null;

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body1">Date</Typography>
            </TableCell>
            <Header availableRoomTypes={availableRoomTypes} />
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((group, dateGroupIdx) => {
            if (!group.corporateAdjustments || !group.date || !group.propertyAdjustments)
              return null;
            return (
              <DateRow
                key={group.date}
                data={{
                  date: group.date,
                  propertyAdjustments: group.propertyAdjustments,
                  corporateGroups: group.corporateAdjustments,
                }}
                dateGroupIdx={dateGroupIdx}
                corporateAccounts={corporateAccounts}
                availableRoomTypes={availableRoomTypes}
                register={register}
                control={control}
                canManageAdjustments={canManageAdjustments}
                form={form}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const DateRow = memo(
  ({
    data,
    dateGroupIdx,
    corporateAccounts,
    availableRoomTypes,
    register,
    control,
    canManageAdjustments,
    form,
  }: {
    data: {
      date: string;
      propertyAdjustments: InventoryAdjustmentField[];
      corporateGroups: CorporateGroupFields[];
    };
    dateGroupIdx: number;
    corporateAccounts: CorporateAccount[];
    availableRoomTypes: RoomType[];
    register: FormContextValues<InventoryAdjustmentFormFields>['register'];
    control: FormContextValues<InventoryAdjustmentFormFields>['control'];
    canManageAdjustments: boolean;
    form: InventoryAdjustmentFormFields;
  }) => {
    return (
      <DateGroupTable
        date={data.date}
        dateGroupIdx={dateGroupIdx}
        corporateAccounts={corporateAccounts}
        availableRoomTypes={availableRoomTypes}
        register={register}
        control={control}
        canManageAdjustments={canManageAdjustments}
        form={form}
      />
    );
  },
  areEqual
);

const Header = memo(({ availableRoomTypes }: { availableRoomTypes: RoomType[] }) => {
  return (
    <>
      <TableCell width={300}>
        <Typography variant="body1">Account</Typography>
      </TableCell>
      {getSortedRoomTypes(availableRoomTypes).map(roomType => (
        <TableCell width={200} key={roomType.id}>
          <Typography variant="body1">{roomType.name}</Typography>
        </TableCell>
      ))}
      <TableCell></TableCell>
    </>
  );
}, areEqual);

const DateGroupTable = memo(
  ({
    date,
    dateGroupIdx,
    corporateAccounts,
    availableRoomTypes,
    register,
    control,
    canManageAdjustments,
    form,
  }: {
    date: string;
    dateGroupIdx: number;
    corporateAccounts: CorporateAccount[];
    availableRoomTypes: RoomType[];
    register: FormContextValues<InventoryAdjustmentFormFields>['register'];
    control: FormContextValues<InventoryAdjustmentFormFields>['control'];
    canManageAdjustments: boolean;
    form: InventoryAdjustmentFormFields;
  }) => {
    const { dateRow } = useStyles();

    return (
      <>
        <TableRow className={dateRow}>
          <TableCell key={`Row_${dateGroupIdx}`}>
            <DateDisplay date={parseISO(date)} dateFormat="MM/dd/yyyy" />
            <input type="hidden" ref={register()} name={`groupAdjustments[${dateGroupIdx}].date`} />
          </TableCell>
          <TableCell>
            <Typography variant="body1">Total Adjustments</Typography>
          </TableCell>
          {getSortedRoomTypes([...availableRoomTypes]).map((roomType, roomTypeIdx) => {
            return (
              <TableCell key={`DateGroupTable_${dateGroupIdx}_${roomTypeIdx}_Total`} width={200}>
                <Typography variant="body1">
                  {getTotalAdjustments(form, roomType.id, dateGroupIdx)}
                </Typography>
              </TableCell>
            );
          })}
          <TableCell></TableCell>
        </TableRow>

        <PropertyAdjustmentGroup
          dateGroupIdx={dateGroupIdx}
          register={register}
          control={control}
          date={date}
          canManageAdjustments={canManageAdjustments}
        />
        <CorporateAdjustmentGroup
          dateGroupIdx={dateGroupIdx}
          control={control}
          corporateAccounts={corporateAccounts}
          register={register}
          availableRoomTypes={availableRoomTypes}
          canManageAdjustments={canManageAdjustments}
          form={form}
        />
      </>
    );
  },
  areEqual
);

const CorporateAdjustmentGroup = memo(
  ({
    dateGroupIdx,
    corporateAccounts,
    availableRoomTypes,
    register,
    control,
    canManageAdjustments,
    form,
  }: {
    dateGroupIdx: number;
    corporateAccounts: CorporateAccount[];
    availableRoomTypes: RoomType[];
    register: FormContextValues<InventoryAdjustmentFormFields>['register'];
    control: FormContextValues<InventoryAdjustmentFormFields>['control'];
    canManageAdjustments: boolean;
    form: InventoryAdjustmentFormFields;
  }) => {
    const { fields, append, remove } = useFieldArray<CorporateGroupFields>({
      name: `groupAdjustments[${dateGroupIdx}].corporateAdjustments`,
      control,
    });

    const addedCorporateAccountIds = fields.map(s => s.corporateAccountId);
    const filteredCorporateAccounts = corporateAccounts.filter(
      f => !addedCorporateAccountIds?.includes(f.id)
    );

    return (
      <>
        {fields.map((corpGroup, corporateGroupIdx) => {
          if (!corpGroup.corporateAccountId || !corpGroup.inventoryAdjustments) return null;

          return (
            <TableRow key={corpGroup.corporateAccountId}>
              <TableCell />
              <TableCell>
                <Typography variant="body1">
                  {corporateAccounts.find(f => f.id === corpGroup.corporateAccountId)?.name} Holds
                </Typography>
                <input
                  ref={register()}
                  type="hidden"
                  name={`groupAdjustments[${dateGroupIdx}].corporateAdjustments[${corporateGroupIdx}].corporateAccountId`}
                  defaultValue={corpGroup.corporateAccountId}
                />
              </TableCell>
              {corpGroup.inventoryAdjustments.map((corpAdj, roomTypeIdx) => {
                return (
                  <TableCell key={roomTypeIdx}>
                    <FormNumberField
                      disabled={!canManageAdjustments}
                      name={`groupAdjustments[${dateGroupIdx}].corporateAdjustments[${corporateGroupIdx}].inventoryAdjustments[${roomTypeIdx}].quantity`}
                      {...register}
                      validationOptions={{
                        max: 0,
                      }}
                      helperText={errors => {
                        return errors.groupAdjustments &&
                          errors.groupAdjustments[dateGroupIdx] &&
                          errors.groupAdjustments[dateGroupIdx].corporateAdjustments[
                            corporateGroupIdx
                          ] &&
                          errors.groupAdjustments[dateGroupIdx].corporateAdjustments[
                            corporateGroupIdx
                          ].inventoryAdjustments[roomTypeIdx] &&
                          errors.groupAdjustments[dateGroupIdx].corporateAdjustments[
                            corporateGroupIdx
                          ].inventoryAdjustments[roomTypeIdx].quantity?.type === 'max'
                          ? 'Corporate room holds cannot be greater than 0'
                          : '';
                      }}
                      defaultValue={corpAdj.quantity ?? 0}
                    />
                    <input
                      ref={register()}
                      type="hidden"
                      name={`groupAdjustments[${dateGroupIdx}].corporateAdjustments[${corporateGroupIdx}].inventoryAdjustments[${roomTypeIdx}].roomTypeId`}
                      defaultValue={corpAdj.roomTypeId}
                    />
                  </TableCell>
                );
              })}
              {canManageAdjustments && (
                <TableCell>
                  <Button
                    startIcon={<FontAwesomeIcon icon="times" />}
                    variant="outlined"
                    style={{ color: AppColors.Red }}
                    onClick={() => {
                      remove(corporateGroupIdx);
                    }}
                  >
                    Remove
                  </Button>
                </TableCell>
              )}
            </TableRow>
          );
        })}
        {canManageAdjustments && filteredCorporateAccounts.length > 0 && (
          <TableRow>
            <TableCell />
            <TableCell colSpan={availableRoomTypes.length + 1}>
              <Select
                label="Corporate Account"
                fullWidth
                value="-"
                onChange={({ target }) => {
                  if (target.value === '-') return;
                  append({
                    corporateAccountId: `${target.value}`,
                    inventoryAdjustments: availableRoomTypes.map(roomType => ({
                      roomTypeId: roomType.id,
                      quantity: 0,
                    })),
                  });
                }}
              >
                <MenuItem value="-">Add Corporate Account for Room Holds</MenuItem>
                {filteredCorporateAccounts.map(corporateAccount => (
                  <MenuItem key={corporateAccount.id} value={corporateAccount.id}>
                    {corporateAccount.name}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  },
  areEqual
);

function getTotal(quantities: number[]) {
  return quantities.reduce((accumulator, current) => {
    if ((current?.toString() ?? '') === '') current = 0;
    return accumulator + current;
  }, 0);
}

function getSortedRoomTypes(roomTypes: RoomType[]) {
  return roomTypes.sort((x: RoomType, y: RoomType) =>
    x.id.localeCompare(y.id, undefined, { sensitivity: 'base' })
  );
}

function getTotalAdjustments(
  form: InventoryAdjustmentFormFields,
  roomTypeId: string,
  dateGroupIdx: number
) {
  const totals = form.groupAdjustments[dateGroupIdx].corporateAdjustments
    ? form.groupAdjustments[dateGroupIdx].corporateAdjustments
        .map(p => p.inventoryAdjustments)
        .reduce(function (a, b) {
          return a.concat(b);
        }, [])
        .filter(f => f.roomTypeId === roomTypeId)
    : [];

  return getTotal([
    ...totals.map(m => m.quantity ?? 0),
    ...form.groupAdjustments[dateGroupIdx].propertyAdjustments
      .filter(f => f.roomTypeId === roomTypeId)
      .map(m => m.quantity),
  ]);
}
