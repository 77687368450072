import { QueryEntity } from '@datorama/akita';
import { switchMap } from 'rxjs/operators';
import { HousekeeperState, housekeeperStore, HousekeeperStore } from './house-keeper.store';
import { sortByName } from '../utils';

export class HousekeeperQuery extends QueryEntity<HousekeeperState> {
  constructor(protected store: HousekeeperStore) {
    super(store);
  }

  active = this.selectActive();

  housekeepers = this.select(({ ui }) => ui).pipe(
    switchMap(({ propertyId }) => {
      return this.selectAll({
        filterBy: x => x.propertyId === propertyId,
        sortBy: (a, b) => sortByName(a.name, b.name),
      });
    })
  );
}

export const housekeeperQuery = new HousekeeperQuery(housekeeperStore);
