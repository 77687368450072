import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, IconButton, MenuItem } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import Close from '@material-ui/icons/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfDay, isBefore, isSameDay } from 'date-fns';

import { FormStatus } from '@lib/state';
import {
  FormNumberField,
  FormSelect,
  useFormEvents,
  SubmitButton,
  ErrorDisplay,
  EnumValueDisplay,
  useCommonStyle,
} from '@lib/common';
import { RateRestrictionTypeMap, RateRestrictionForms, RateRestrictionTypeEnum } from 'app/state';

const rateRestrictionTypes = Array.from(RateRestrictionTypeMap, ([name, value]) => ({
  name,
  value,
}));

export interface CreateRateRestrictionFormFields {
  startDate?: Date;
  endDate?: Date;
  type: RateRestrictionTypeEnum;
  minLOS: number;
  maxLOS: number;
}

export const CreateRateRestrictionForm: React.FC = () => {
  const styles = useCommonStyle();

  const [{ status, error }] = useFormEvents(RateRestrictionForms.Create);

  const { register, getValues, setValue, watch, formState, errors } = useFormContext();

  useEffect(() => {
    const validate = (): boolean => {
      const { startDate, endDate } = getValues();
      return (
        !startDate || !endDate || isBefore(startDate!, endDate!) || isSameDay(startDate!, endDate!)
      );
    };

    register({ name: 'startDate' }, { validate });
    register({ name: 'endDate' }, { validate });

    // eslint-disable-next-line
  }, []);

  const { startDate, endDate } = watch({ nest: true });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <DatePicker
          autoOk
          disableToolbar
          disablePast
          fullWidth
          variant="inline"
          format="MMMM d"
          label="Start Date"
          name="startDate"
          value={startDate ?? null}
          error={!!errors.startDate}
          onChange={value => {
            if (value) {
              setValue('startDate', value && startOfDay(value), true);
            }
          }}
          color="primary"
          size="small"
          InputProps={{
            endAdornment: (
              <>
                <IconButton
                  edge="end"
                  size="small"
                  className={styles.clickable}
                  disabled={!startDate}
                  onClick={() => setValue('startDate', undefined, true)}
                >
                  <Close />
                </IconButton>
                <FontAwesomeIcon icon="calendar-week" />
              </>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          autoOk
          disableToolbar
          fullWidth
          variant="inline"
          format="MMMM d"
          label="End Date"
          name="endDate"
          value={endDate ?? null}
          error={!!errors.endDate}
          onChange={value => {
            if (value) {
              setValue('endDate', value && startOfDay(value), true);
            }
          }}
          color="primary"
          size="small"
          InputProps={{
            endAdornment: (
              <>
                <IconButton
                  edge="end"
                  size="small"
                  className={styles.clickable}
                  disabled={!endDate}
                  onClick={() => setValue('endDate', undefined, true)}
                >
                  <Close />
                </IconButton>
                <FontAwesomeIcon icon="calendar-week" />
              </>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormNumberField name="minLOS" label="Min Length of Stay" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <FormNumberField name="maxLOS" label="Max Length of Stay" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <FormSelect
          name="type"
          label="Rate Restriction Type"
          validationOptions={{ required: 'This field is required.' }}
          fullWidth
          required
        >
          {rateRestrictionTypes.map(x => (
            <MenuItem key={x.value} value={x.name}>
              <EnumValueDisplay value={x.name} disableTypography />
            </MenuItem>
          ))}
        </FormSelect>
      </Grid>
      <Grid item xs={12}>
        <ErrorDisplay error={error} />
        <SubmitButton
          variant="contained"
          color="secondary"
          fullWidth
          pending={status === FormStatus.Pending}
          disabled={
            !formState.isValid || status === FormStatus.Pending || status === FormStatus.Error
          }
        >
          Submit
        </SubmitButton>
      </Grid>
    </Grid>
  );
};
