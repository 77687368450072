import React, { useCallback, useEffect } from 'react';
import { GuestProfile, Reservation, reservationService, sessionQuery } from '@lib/state';
import {
  useObservable,
  Section,
  LoadingIndicator,
  FormHub,
  GuestActivity,
  useGuestCurrentReservations,
  useGuestFutureReservations,
  CurrentGuestStays,
  FutureGuestStays,
  GuestRelationsForm,
} from '@lib/common';
import { guestManagementService, guestManagementQuery } from 'app/state';
import { BreadcrumbBackButton, AuditLogs } from 'app/shared';
import { GuestProfileForm } from '../components';
import { GuestDetailsRouteProps } from '../guest-detail-route-props';
import { useHistory } from 'react-router-dom';

export const GuestDetailsPage: React.FC<GuestDetailsRouteProps> = ({ match }) => {
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const userId = match.params.userId;
  const history = useHistory();

  const guest = useObservable(guestManagementQuery.activeGuest, 'async');

  useEffect(() => {
    guestManagementService.getGuestById(userId);
    guestManagementService.select(userId);

    // Remove active guest
    return () => guestManagementService.select();
  }, [userId]);

  const current = useGuestCurrentReservations(userId, propertyId);
  const future = useGuestFutureReservations(userId, propertyId);

  const onExport = useCallback(
    status => {
      if (propertyId)
        reservationService.exportGuestReservations({
          userId,
          propertyId,
          status,
        });
    },
    [userId, propertyId]
  );

  const onConfirmationClick = (reservation: Reservation) => {
    history.push(`/reservations/${reservation.id}?guestId=${guest?.userId}`);
  };

  if (!propertyId) return null;
  if (!guest) return <LoadingIndicator loadingText="Loading Guest" fillContainer />;

  return (
    <Section>
      <BreadcrumbBackButton destination="/guests" />
      <FormHub
        onSubmit={(guest: GuestProfile) => {
          guestManagementService.updateGuestProfile({ ...guest, userId });
        }}
      >
        <GuestProfileForm guest={guest} />
      </FormHub>
      <GuestRelationsForm guest={guest} readOnly={true} />
      <CurrentGuestStays
        userId={userId}
        reservations={current.reservations}
        page={current.page}
        onLoadMore={current.loadMore}
        onExport={onExport}
        onConfirmationClick={onConfirmationClick}
        createForGuest={true}
      />
      <FutureGuestStays
        userId={userId}
        reservations={future.reservations}
        page={future.page}
        onLoadMore={future.loadMore}
        onExport={onExport}
        onConfirmationClick={onConfirmationClick}
        createForGuest={true}
      />
      <GuestActivity userId={userId} createForGuest={true} />
      <Section title="Audit Logs" disableGutters>
        <AuditLogs scope="user" id={userId} />
      </Section>
    </Section>
  );
};
