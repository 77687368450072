import { StyledDialog } from '@lib/common';
import { CorporateAccount, FormStatus } from '@lib/state';
import { DialogContent } from '@material-ui/core';
import {
  CorporateContactModel,
  CreateCorporateContactRequest,
  UpdateCorporateContactRequest,
  corporateContactService,
} from 'app/state';
import React, { useCallback } from 'react';
import CorporateContactForm from './corporate-contact-form';

interface Props {
  contact?: CorporateContactModel;
  account: CorporateAccount;
  open: boolean;
  onClose: () => void;
  status: FormStatus;
  error?: Error;
}

export default function CorporateContactModal({
  contact,
  account,
  onClose,
  open,
  status,
  error,
}: Readonly<Props>) {
  const onSubmit = useCallback(
    (c: CorporateContactModel) => {
      if (contact) {
        const request: UpdateCorporateContactRequest = { ...c };
        request.id = contact.id;
        corporateContactService.updateContact(account.id, request);
      } else {
        const request: CreateCorporateContactRequest = { ...c };
        request.corporateAccountId = account.id;
        corporateContactService.createContact(request);
      }
    },
    [contact, account]
  );

  return (
    <StyledDialog
      title={`${contact ? 'Edit' : 'Add'} Contact`}
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent>
        <CorporateContactForm contact={contact} onSubmit={onSubmit} status={status} error={error} />
      </DialogContent>
    </StyledDialog>
  );
}
