import { EntityStore, EntityState, StoreConfig } from '@datorama/akita';

import { PaginationState } from '../models.common';
import { ActivityReport } from './activity-report.model';
import { BookedByUserType } from '..';

export interface StayInfoFilters {
  recordNumber?: number;
  start?: Date;
  end?: Date;
  firstName?: string;
  lastName?: string;
  crew?: string;
  bookedBy?: BookedByUserType;
  userId?: string;
  corporateAccountId?: string;
}

export interface ActivityReportState
  extends EntityState<ActivityReport, ActivityReport['transactionId']> {
  ui: StayInfoFilters;
  pagination: PaginationState;
}

@StoreConfig({ name: 'activity-report', resettable: true, idKey: 'transactionId' })
export class ActivityReportStore extends EntityStore<ActivityReportState> {
  constructor() {
    super({
      ui: {
        firstName: '',
        lastName: '',
        crew: '',
        recordNumber: undefined,
        start: undefined,
        end: undefined,
        bookedBy: undefined,
        userId: undefined,
        corporateAccountId: undefined,
      },
      pagination: {
        isDone: true,
        continuationToken: undefined,
      },
    });
  }
}

export const activityReportStore = new ActivityReportStore();
