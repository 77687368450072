import { useEffect, useMemo } from 'react';

import { reservationQuery, reservationService } from '@lib/state';
import { useObservable } from './useObservable';

export function useBookingStatus() {
  const bookingStatus = useObservable(reservationQuery.bookingStatus);

  useEffect(() => {
    return reservationService.resetBooking();
  }, []);

  return useMemo(() => {
    if (!bookingStatus) return null;

    if (!bookingStatus?.reservations?.length || bookingStatus.reservations.length === 0)
      return { reservation: null, ...bookingStatus };

    //get first reservation to look up user id and check in status
    const reservation = bookingStatus.reservations[0];

    return { reservation: reservation, ...bookingStatus };
  }, [bookingStatus]);
}
