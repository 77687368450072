import { useObservable } from '@lib/common';
import { housekeepingAssignmentQuery, housekeepingAssignmentService } from '@lib/state';
import { useEffect } from 'react';

export function useGetRoomAssignmentsByUser(propertyId: string, userId: string) {
  useEffect(() => {
    housekeepingAssignmentService.getHousekeepingAssignmentsForUser(propertyId);
  }, [propertyId]);

  const unfilteredAssignments = useObservable(housekeepingAssignmentQuery.allRooms);

  const assignments = unfilteredAssignments.filter(
    a => a.housekeeperUserId === userId && (a.endTime?.length === 0 || !a.endTime)
  );

  return {
    assignments,
    loading: useObservable(housekeepingAssignmentQuery.isLoading),
  };
}
