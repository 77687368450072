import { from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { RoomAccessStore, roomAccessStore, RoomAccessUIState } from './room-access.store';
import { AccessControl, dispatchForm, Property } from '@lib/state';
import { RoomAccessForms } from './room-access.form';
import { getDate, PropertyConfiguration } from '@lib/state';

export class RoomAccessService {
  constructor(
    private readonly store: RoomAccessStore,
    private readonly entryCodeApi: AccessControl.EntryCodeApi
  ) {}

  loadRoomStaff(propertyId: string, roomNumber: string): void {
    this.updateRoomStaffAssignment(propertyId, roomNumber)
      .pipe(dispatchForm(RoomAccessForms.StaffList))
      .subscribe();
  }

  assignRoom(
    propertyId: string,
    roomId: string,
    userId: string,
    roomNumber: string,
    propertyConfiguration: PropertyConfiguration
  ) {
    let d = getDate(propertyConfiguration, 1);
    from(
      this.entryCodeApi.entrycodePropertyIdStaffPost(propertyId, {
        roomId,
        roomNumber,
        userId,
        validUntil: d,
      })
    )
      .pipe(
        dispatchForm(RoomAccessForms.AssignRoom),
        switchMap(() => this.updateRoomStaffAssignment(propertyId, roomNumber))
      )
      .subscribe();
  }

  private updateRoomStaffAssignment(propertyId: Property['id'], roomNumber: string) {
    return from(this.entryCodeApi.entrycodePropertyIdGet(propertyId, roomNumber)).pipe(
      map(response => response.data.data),
      tap(data => {
        this.store.remove(r => r.roomNumber === roomNumber);
        this.store.upsertMany(data);
        this.updateUI({ propertyId, roomNumber });
      })
    );
  }

  private updateUI(values: Partial<RoomAccessUIState>) {
    this.store.update(({ ui }) => ({
      ui: {
        ...ui,
        ...values,
      },
    }));
  }

  reset() {
    this.store.reset();
  }
}

export const roomAccessService = new RoomAccessService(
  roomAccessStore,
  new AccessControl.EntryCodeApi()
);
