import { parse, lightFormat } from 'date-fns';

import { AcceptCardData } from '@lib/state';
import { CardFields } from '../components';

export function getAcceptCardData(card?: CardFields) {
  if (!card?.cardNumber || !card?.expiryDate || !card?.cvc) return;

  const expDate = parse(card.expiryDate, 'MM / yy', new Date());

  return {
    cardNumber: card.cardNumber.replace(/\s/g, ''),
    month: lightFormat(expDate, 'MM'),
    year: lightFormat(expDate, 'yyyy'),
    cardCode: card.cvc,
  } as AcceptCardData;
}
