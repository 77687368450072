import { QueryEntity } from '@datorama/akita';
import {
  PropertyConfigurationState,
  propertyConfigurationStore,
} from './property-configuration.store';

export class PropertyConfigurationQuery extends QueryEntity<PropertyConfigurationState> {
  activeProperty = this.selectActive();
}

export const propertyConfigurationQuery = new PropertyConfigurationQuery(
  propertyConfigurationStore
);
