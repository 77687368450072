import {
  CostCenterFields,
  getGuestType,
  GuestLookupProfile,
  GuestProfile,
  sessionQuery,
  sessionService,
} from '@lib/state';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import { GuestEntryProps, GuestRelationField, GuestPictureContainerComponent } from '..';
import {
  FormEmailField,
  FormPhoneField,
  NameForm,
  useFeatureEnabled,
  useFieldLabelProps,
  useFormPrefix,
  useObservable,
} from '../..';
import { GuestAutocompleteMenu, useGuestAutocomplete } from './guest-autocomplete-menu';
import CostCenterFieldRequired from './cost-center-field-required';
import { useBookingRole } from './useBookingRole';

export const GuestEntryForm: React.FC<GuestEntryProps> = ({
  propertyId,
  corporateBooking,
  guestCount,
  isPrimary,
  prefix,
  useRearCam,
}) => {
  const employeeRoles = useObservable(sessionQuery.employeeRoles);
  const existingEmployee = !!employeeRoles && employeeRoles.length > 0;
  const [guest, setGuest] = useState<GuestProfile | undefined>(undefined);
  const { watch, setValue, register, unregister } = useFormContext();
  const formPrefix = useFormPrefix(prefix);

  const emailLabelProps = useFieldLabelProps(formPrefix('contact.email'));
  const phoneLabelProps = useFieldLabelProps(formPrefix('contact.phone'));

  useEffect(() => {
    register(formPrefix('contactType'));
    return () => unregister(formPrefix('contactType'));
  }, [formPrefix]);

  useEffect(() => {
    setValue(formPrefix('contactType'), getGuestType(isPrimary));
  }, [isPrimary, setValue, formPrefix]);

  useEffect(() => {
    if (propertyId) sessionService.getEmployeeRoles(propertyId);
  }, [propertyId]);

  const showRelationField =
    useFeatureEnabled('GuestRelationships') && !!guestCount && guestCount > 1;

  const employeeNumberField = useMemo(
    () => corporateBooking?.fields?.find(x => x.key === CostCenterFields.EmployeeNumber),
    [corporateBooking]
  );

  const selectGuest = useCallback(
    (profile: GuestLookupProfile) => {
      const { contact, name, employeeNumber } = profile;
      setValue(formPrefix('contact'), contact);
      setValue(formPrefix('name'), name);
      setValue(formPrefix('employeeNumber'), employeeNumber);
      setGuest(profile);
    },
    [formPrefix, setValue, setGuest]
  );

  const form = watch(prefix);
  const autocomplete = useGuestAutocomplete({
    ...form,
    corporateAccountId: corporateBooking?.id,
    hasEmployeeRole: existingEmployee,
  });

  const { isEmployee, isDelegate } = useBookingRole(corporateBooking?.id);

  return (
    <GuestPictureContainerComponent prefix={prefix} guest={guest} defaultToRearCamera={useRearCam}>
      <Grid container spacing={1}>
        {!!corporateBooking && employeeNumberField?.isEnabled && (
          <Grid item xs={12}>
            <CostCenterFieldRequired
              name={formPrefix('employeeNumber')}
              corporateAccountId={corporateBooking.id}
              config={employeeNumberField}
              isDelegateOrEmployee={isDelegate || isEmployee}
              {...autocomplete.getInputProps()}
            />
          </Grid>
        )}
        <Grid item md={6} xs={12}>
          <FormEmailField
            name={formPrefix('contact.email')}
            label="Email"
            fullWidth
            InputLabelProps={emailLabelProps}
            {...autocomplete.getInputProps()}
            InputProps={{
              autoComplete: 'off',
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormPhoneField
            name={formPrefix('contact.phone')}
            fullWidth
            InputLabelProps={phoneLabelProps}
            {...autocomplete.getInputProps()}
          />
        </Grid>
        <Grid item xs={12}>
          <NameForm
            prefix={prefix}
            optional={!isPrimary}
            inputProps={autocomplete.getInputProps()}
          />
        </Grid>
        {showRelationField && (
          <Grid item md={6} xs={12}>
            <GuestRelationField prefix={prefix} />
          </Grid>
        )}
      </Grid>
      <GuestAutocompleteMenu
        open={autocomplete.open}
        loading={autocomplete.loading}
        anchorEl={autocomplete.anchorEl}
        matches={autocomplete.matches}
        onSelect={match => {
          autocomplete.setOpen(false);
          selectGuest(match);
        }}
      />
    </GuestPictureContainerComponent>
  );
};
