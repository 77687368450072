import { QueryEntity } from '@datorama/akita';
import { switchMap } from 'rxjs/operators';
import {
  HousekeepingAssignmentState,
  HousekeepingAssignmentStore,
  housekeepingAssignmentStore,
} from './house-keeping-assignment.store';
import { isEqual, parseISO } from 'date-fns';
import { HousekeepingAssignment } from './house-keeping-assignment.model';

export class HousekeepingAssignmentQuery extends QueryEntity<HousekeepingAssignmentState> {
  constructor(protected store: HousekeepingAssignmentStore) {
    super(store);
  }

  isLoading = this.selectLoading();

  currentRoom = this.select(({ ui }) => ui).pipe(
    switchMap(({ date, roomId }) => {
      return this.selectAll({
        filterBy: x => {
          return isEqual(parseISO(x.date), parseISO(date)) && x.roomId === roomId;
        },
        sortBy: 'assignedTime',
      });
    })
  );

  allRooms = this.selectAll({
    sortBy: sortRoomNumbers,
  });

  roomsByDate = this.select(({ ui }) => ui).pipe(
    switchMap(({ date, propertyId }) => {
      return this.selectAll({
        filterBy: x => {
          return isEqual(parseISO(x.date), parseISO(date)) && x.propertyId === propertyId;
        },
        sortBy: sortRoomNumbers,
      });
    })
  );
}

export const housekeepingAssignmentQuery = new HousekeepingAssignmentQuery(
  housekeepingAssignmentStore
);
function sortRoomNumbers(
  { roomNumber: a }: HousekeepingAssignment,
  { roomNumber: b }: HousekeepingAssignment
): number {
  return a.localeCompare(b) || a.localeCompare(b);
}
