import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AccountForms, sessionService, sessionQuery } from '@lib/state';
import { useObservable, usePageTitle, useFormEvents, LoadingIndicator } from '@lib/common';
import {
  scanQRCode,
  ArmQRCodeResult,
  ArmQRCodeResultType,
} from 'app/shared/native-interface/nativebridge';

export const QrLoginPage: React.FC<RouteComponentProps> = props => {
  const history = props.history;
  const queryString = props.location.search;
  const params = new URLSearchParams(queryString);
  const nextRoute = params.get('next') ?? `/kiosk/reservations`;
  const shouldPop = params.get('popOnLogin') === 'true';

  usePageTitle('Scan QR Code');

  const isLoggedIn = useObservable(sessionQuery.isLoggedIn);
  useEffect(() => {
    if (isLoggedIn) {
      if (shouldPop) {
        history.goBack();
      } else {
        history.push(nextRoute);
      }
    }
  }, [isLoggedIn, history, shouldPop, nextRoute]);

  const [{ error }] = useFormEvents(AccountForms.QRLogin);

  if (error) {
    history.replace('/kiosk/qr-error');
  }

  useEffect(() => {
    const handleQRResponse = (result?: ArmQRCodeResult) => {
      if (result) {
        let willAttemptLogin: boolean = false;
        if (result.type === ArmQRCodeResultType.Success && result.qrCode) {
          willAttemptLogin = true;
          sessionService.loginWithUserKey(result.qrCode.user);
        }

        if (!willAttemptLogin) {
          if (result.type === ArmQRCodeResultType.CancelledByUser) {
            history.goBack();
          } else {
            history.replace('/kiosk/qr-error');
          }
        }
      } else {
        // unable to parse result
        history.goBack();
      }
    };

    scanQRCode(handleQRResponse);
  }, [history]);

  return <LoadingIndicator loadingText="Scanning QR" fillContainer />;
};
