import { QueryEntity, Order } from '@datorama/akita';
import { isAfter, isBefore, isWithinInterval, parseISO } from 'date-fns';
import isEqual from 'lodash.isequal';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ActivityReportModel } from '../api/generated/reservations';

import {
  ActivityReportState,
  ActivityReportStore,
  activityReportStore,
} from './activity-report.store';
import { BookedByUserType } from '..';

export function activityDateRangeFilter(activity: ActivityReportModel, start?: Date, end?: Date) {
  if (start && end) return isWithinInterval(parseISO(activity.timestamp), { start, end });
  else if (start) return isAfter(parseISO(activity.timestamp), start);
  else if (end) return isBefore(parseISO(activity.timestamp), end);

  return true;
}

export class ActivityReportQuery extends QueryEntity<ActivityReportState> {
  constructor(protected store: ActivityReportStore) {
    super(store);
  }

  isLoading = this.selectLoading();

  filters = this.select(({ ui }) => ui).pipe(distinctUntilChanged(isEqual));

  pagination = this.select(({ pagination }) => pagination);

  activity = this.filters.pipe(
    switchMap(ui =>
      this.selectAll({
        filterBy: [
          x => activityDateRangeFilter(x, ui.start, ui.end),
          x =>
            !ui.firstName ||
            !x.guest ||
            !x.guest.name.first ||
            x.guest?.name.first?.toLowerCase().startsWith(ui.firstName!.toLowerCase()),
          x =>
            !ui.lastName ||
            !x.guest ||
            !x.guest.name.last ||
            x.guest?.name.last?.toLowerCase().startsWith(ui.lastName!.toLowerCase()),
          x =>
            !ui.crew ||
            (x.affiliation &&
              x.affiliation.crew &&
              x.affiliation.crew.toLowerCase().startsWith(ui.crew.toLowerCase())) ||
            false,
          x => !ui.recordNumber || !x.recordNumber || ui.recordNumber.toString() === x.recordNumber,
          x =>
            !ui.bookedBy ||
            (ui.bookedBy === x.createdByUserType && ui.bookedBy !== BookedByUserType.You) ||
            (ui.bookedBy === BookedByUserType.You && ui.userId === x.createdByUserId),
          x =>
            !ui.corporateAccountId ||
            !x.affiliation ||
            !x.affiliation.corporateAccountId ||
            ui.corporateAccountId == x.affiliation.corporateAccountId,
        ],
        sortBy: 'timestamp',
        sortByOrder: Order.DESC,
      })
    )
  );
}

export const activityReportQuery = new ActivityReportQuery(activityReportStore);
