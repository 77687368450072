import React from 'react';

import { FormTextField, FormTextFieldProps, TextFieldProps } from '../form-text-field';

type Props = Omit<FormTextFieldProps, 'validationOptions' | 'type'> & TextFieldProps;

export const FormLastNameField: React.FC<Props> = ({
  autoComplete = 'family-name',
  label = 'Last Name',
  required,
  ...props
}) => {
  return (
    <FormTextField
      {...props}
      type="text"
      label={label}
      autoComplete={autoComplete}
      validationOptions={{ required, maxLength: 50 }}
      data-testid="formLastNameInput"
    />
  );
};
