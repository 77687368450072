import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PropertyConfiguration } from './property-configuration.model';

export interface PropertyConfigurationState
  extends EntityState<PropertyConfiguration, PropertyConfiguration['id']>,
    ActiveState {}

@StoreConfig({ name: 'property-configuration', resettable: true })
export class PropertyConfigurationStore extends EntityStore<PropertyConfigurationState> {}

export const propertyConfigurationStore = new PropertyConfigurationStore();
