import { QueryEntity } from '@datorama/akita';
import { switchMap, debounceTime, startWith } from 'rxjs/operators';

import { sessionQuery, SessionQuery } from '../session';
import { RoomsState, RoomsStore, roomsStore, initialFilters } from './rooms.store';
import { Room } from './rooms.model';

export class RoomsQuery extends QueryEntity<RoomsState> {
  constructor(store: RoomsStore, private readonly sessionQuery: SessionQuery) {
    super(store);
  }

  loading = this.selectLoading();

  activeRoom = this.selectActive();

  roomsFilters = this.select(({ ui }) => ui).pipe(debounceTime(1000), startWith(initialFilters));

  rooms = this.sessionQuery.propertyId.pipe(
    switchMap(propertyId =>
      this.selectAll({
        filterBy: x => x.propertyId === propertyId,
        sortBy: (a, b) => compare(a, b),
      })
    )
  );

  roomCount = this.sessionQuery.propertyId.pipe(
    switchMap(propertyId => this.selectCount(x => x.propertyId === propertyId))
  );
}
function compare(a: Room, b: Room) {
  const displayOrdersEqual = (a.displayOrder ?? 0) - (b.displayOrder ?? 0);
  if (displayOrdersEqual !== 0) {
    return displayOrdersEqual;
  }
  return a.roomNumber.localeCompare(b.roomNumber);
}

export const roomsQuery = new RoomsQuery(roomsStore, sessionQuery);
