import React from 'react';
import { Box, Paper, Typography, Grid } from '@material-ui/core';
import { getGuests, Reservation } from '@lib/state';
import { DateDisplay, EnumValueDisplay, NameDisplay, useCommonStyle } from '@lib/common';
import { parseISO } from 'date-fns';

interface Props {
  reservation?: Reservation;
}
export const ReservationMaintenanceDetails: React.FC<Props> = ({ reservation }) => {
  const styles = useCommonStyle();

  return (
    <Box height="100%" clone>
      <Paper className={styles.paper} elevation={3}>
        <Grid container spacing={1}>
          {!!reservation ? (
            <>
              <Grid item xs={6} md={2}>
                <Typography>
                  <strong>Reservation Number</strong>
                </Typography>
                <Typography>{reservation.recordNumber}</Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography>
                  <strong>Guest</strong>
                </Typography>
                <NameDisplay {...getGuests(reservation)?.[0]?.name} />
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography>
                  <strong>Company</strong>
                </Typography>
                <Typography>{reservation.affiliation?.corporateAccountName} </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography>
                  <strong>Sleep Schedule</strong>
                </Typography>
                <EnumValueDisplay value={reservation.affiliation?.sleepSchedule} />
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography>
                  <strong>Scheduled Check Out</strong>
                </Typography>
                <DateDisplay date={parseISO(reservation.checkOutDate)} dateFormat="M/d/yyyy" />
              </Grid>
            </>
          ) : (
            <Typography>
              <strong>Room is unoccupied</strong>
            </Typography>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};
