import React, { useCallback } from 'react';
import {
  PropertyConfigurationForms,
  propertyConfigurationService,
  PropertyConfiguration,
} from 'app/state';
import { FormHub, useFormEvents } from '@lib/common';
import {
  PropertyNotificationFormFields,
  PropertyNotificationsForm,
} from './property-notifications.form';

interface Props {
  property: PropertyConfiguration;
}

export const ManagePropertyNotificationsComponent: React.FC<Props> = ({ property }) => {
  const [{ status, error }, resetUI] = useFormEvents(PropertyConfigurationForms.Update);

  const submit = useCallback(
    ({
      backOfficeEmail,
      reportEmail,
      maintenanceEmail,
      maintenancePhone,
      monitoringEmail,
      refundAlertEmail,
      guestSurveyEmail,
      guestSurveyThreshold,
      vipReservationNotificationPhone,
    }: PropertyNotificationFormFields) => {
      propertyConfigurationService.updatePropertyNotifications(property.id, {
        backOfficeEmail,
        reportEmail,
        maintenanceEmail,
        maintenancePhone,
        monitoringEmail,
        refundAlertEmail,
        //this value cannot be null or else it will remove the existing value
        taxCreditPeriod: property.taxCreditPeriod ?? undefined,
        guestSurveyEmail,
        guestSurveyThreshold: guestSurveyThreshold ?? undefined,
        vipReservationNotificationPhone,
      });
    },
    [property]
  );

  return (
    <FormHub<PropertyNotificationFormFields>
      formOptions={{
        defaultValues: {
          backOfficeEmail: property.backOfficeEmail ?? '',
          reportEmail: property.reportEmail ?? '',
          maintenanceEmail: property.maintenanceEmail ?? '',
          maintenancePhone: property.maintenancePhone ?? '',
          monitoringEmail: property.monitoringEmail ?? '',
          refundAlertEmail: property.refundAlertEmail ?? '',
          guestSurveyEmail: property.guestSurveyNotificationEmail ?? '',
          guestSurveyThreshold: property.guestSurveyNotificationThreshold ?? undefined,
          vipReservationNotificationPhone: property.vipReservationNotificationPhone ?? '',
        },
      }}
      onSubmit={submit}
    >
      <PropertyNotificationsForm status={status} error={error} resetUI={resetUI} />
    </FormHub>
  );
};
