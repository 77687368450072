import { QueryEntity, Order } from '@datorama/akita';
import { defer, of, combineLatest } from 'rxjs';
import { map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { isWithinInterval, parseISO, isAfter } from 'date-fns';
import isEqual from 'lodash.isequal';

import { todayAndAfter } from '../utils';
import {
  reservationStore,
  ReservationStore,
  ReservationState,
  BookedByUserType,
  authUserTypeFromBookedBy,
} from './reservation.store';
import {
  Reservation,
  ReservationStatus,
  reservationDateRangeFilter,
  isReservationAvailableForCheckin,
  getDefaultCheckInDate,
  reservationNumberRangeFilter,
  ContactType,
} from './reservation.model';

export class ReservationQuery extends QueryEntity<ReservationState> {
  constructor(protected store: ReservationStore) {
    super(store);
  }

  isLoading = this.selectLoading();

  filters = this.select(({ ui }) => ui);

  pagination = this.select(({ pagination }) => pagination);
  currentPagination = this.select(({ current }) => current);
  futurePagination = this.select(({ future }) => future);

  private getNextReservation(reservations: Reservation[]): Reservation | undefined {
    const filtered = todayAndAfter(reservations);
    return filtered.length > 0
      ? filtered.reduce((a, b) =>
          isAfter(parseISO(a.checkInDate), parseISO(b.checkInDate)) ? b : a
        )
      : undefined;
  }

  reservations = this.selectAll();
  activeReservation = this.selectActive().pipe(distinctUntilChanged<Reservation>(isEqual));

  bookingStatus = this.select(({ bookingStatus }) => bookingStatus);

  containsIds = (ids: Reservation['id'][]) => {
    return this.selectAll().pipe(
      map(reservations => reservations.filter(reservation => ids.includes(reservation.id)))
    );
  };

  currentReservations = this.filters.pipe(
    switchMap(ui =>
      this.selectAll({
        filterBy: [
          x =>
            [
              ReservationStatus.CheckedIn,
              ReservationStatus.CheckedOut,
              // Check out confirm / results dialog was unmounted before results dialog could be displayed.
              // Checked out reservations are hidden in list (CSS) and not returned by API.
            ].includes(x.status),
          x => ui.propertyId == null || x.propertyId === ui.propertyId,
          x => ui.userId == null || x.contacts.some(c => c.userId === ui.userId),
          x => reservationNumberRangeFilter(x, ui.recordNumber, ui.recordNumberRange),
          x => reservationDateRangeFilter(x, ui.start, ui.end),
          x =>
            !ui.firstName ||
            x.contacts.some(c =>
              c.name.first?.toLowerCase().startsWith(ui.firstName!.toLowerCase())
            ),
          x =>
            !ui.lastName ||
            x.contacts.some(c => c.name.last?.toLowerCase().startsWith(ui.lastName!.toLowerCase())),
          x =>
            !ui.crew ||
            (x.affiliation &&
              x.affiliation.crew &&
              x.affiliation.crew.toLowerCase().startsWith(ui.crew.toLowerCase())) ||
            false,
          x => !ui.roomTypeId || x.rooms.some(r => r.roomTypeId == ui.roomTypeId),
          x =>
            !ui.bookedBy ||
            (ui.bookedBy === 'Staff' && x.createdByUserType === 'Staff') ||
            (ui.bookedBy === 'You' && x.createdByUserType === 'Guest') ||
            (ui.bookedBy === 'Delegate' && x.createdByUserType === 'Delegate'),
          x =>
            !ui.corporateAccountId ||
            !x.affiliation ||
            !x.affiliation.corporateAccountId ||
            ui.corporateAccountId == x.affiliation.corporateAccountId,
        ],
        sortBy: reservationSort,
      })
    )
  );

  upcomingReservations = this.filters.pipe(
    switchMap(ui =>
      this.selectAll({
        filterBy: [
          x =>
            [
              ReservationStatus.Open,
              ReservationStatus.Canceled,
              // Cancelled confirm / results dialog was unmounted before results dialog could be displayed.
              // Cancelled reservations are hidden in list (CSS) and not returned by API.
            ].includes(x.status),
          x => ui.propertyId == null || x.propertyId === ui.propertyId,
          x => ui.userId == null || x.contacts.some(c => c.userId === ui.userId),
          x => reservationNumberRangeFilter(x, ui.recordNumber, ui.recordNumberRange),
          x => reservationDateRangeFilter(x, ui.start, ui.end),
          x =>
            !ui.firstName ||
            x.contacts.some(c =>
              c.name.first?.toLowerCase().startsWith(ui.firstName!.toLowerCase())
            ),
          x =>
            !ui.lastName ||
            x.contacts.some(c => c.name.last?.toLowerCase().startsWith(ui.lastName!.toLowerCase())),
          x =>
            !ui.crew ||
            (x.affiliation &&
              x.affiliation.crew &&
              x.affiliation.crew.toLowerCase().startsWith(ui.crew.toLowerCase())) ||
            false,
          x => !ui.roomTypeId || x.rooms.some(r => r.roomTypeId == ui.roomTypeId),
          x =>
            !ui.bookedBy ||
            (ui.bookedBy === 'Staff' && x.createdByUserType === 'Staff') ||
            (ui.bookedBy === 'You' && x.createdByUserType === 'Guest') ||
            (ui.bookedBy === 'Delegate' && x.createdByUserType === 'Delegate'),
          x =>
            !ui.corporateAccountId ||
            !x.affiliation ||
            !x.affiliation.corporateAccountId ||
            ui.corporateAccountId == x.affiliation.corporateAccountId,
        ],
        sortBy: reservationSort,
      })
    )
  );

  nextReservation = this.selectAll().pipe(
    map(reservations => this.getNextReservation(reservations))
  );

  reservationsAvailableForCheckIn = this.filters.pipe(
    switchMap(ui =>
      this.selectAll({
        filterBy: [
          x => x.propertyId === ui.propertyId,
          x =>
            isWithinInterval(new Date().valueOf(), {
              start: parseISO(x.checkInPeriod.start),
              end: parseISO(x.checkInPeriod.end),
            }),
          x => x.status === ReservationStatus.Open,
        ],
      })
    )
  );

  searchResults = this.filters.pipe(
    switchMap(ui =>
      this.selectAll({
        filterBy: [
          x => x.propertyId === ui.propertyId,
          x => reservationDateRangeFilter(x, ui.start, ui.end),
          x => reservationNumberRangeFilter(x, ui.recordNumber, ui.recordNumberRange),
          x =>
            !ui.firstName ||
            x.contacts.some(c =>
              c.name.first?.toLowerCase().startsWith(ui.firstName!.toLowerCase())
            ),
          x =>
            !ui.lastName ||
            x.contacts.some(c => c.name.last?.toLowerCase().startsWith(ui.lastName!.toLowerCase())),
          x =>
            !ui.crew ||
            (x.affiliation &&
              x.affiliation.crew &&
              x.affiliation.crew.toLowerCase().startsWith(ui.crew.toLowerCase())) ||
            false,
          x =>
            !ui.planName ||
            x.rooms.some(r =>
              r.rates.some(t =>
                t.ratePlan.toLocaleLowerCase().startsWith(ui.planName!.toLocaleLowerCase())
              )
            ),
          x => !ui.isFilterByZeroCharges || filterByZeroCharges(x),
          x =>
            !ui.email ||
            x.contacts.some(c =>
              c.contact.email?.toLowerCase().startsWith(ui.email!.toLowerCase())
            ),
          x =>
            !ui.phone ||
            x.contacts.some(c => c.contact.phone?.toLowerCase() === ui.phone?.toLowerCase()),
          x => !ui.status || x.status === ui.status,
          x =>
            !ui.corporateAccountName ||
            !!x.affiliation?.corporateAccountName
              ?.toLowerCase()
              .startsWith(ui.corporateAccountName!.toLowerCase()),
          x =>
            !ui.bookedBy ||
            (authUserTypeFromBookedBy(ui.bookedBy) === x.createdByUserType &&
              ui.bookedBy !== BookedByUserType.You) ||
            (ui.bookedBy === BookedByUserType.You && ui.userId === x.createdByUserId),
          x => !ui.roomTypeId || x.rooms.some(r => r.roomTypeId == ui.roomTypeId),
          x =>
            !ui.vipStatus ||
            x.contacts.some(
              c => c.vipStatus == ui.vipStatus && c.contactType != ContactType.Delegate
            ),
        ],
        sortBy: 'createdTime',
        sortByOrder: Order.DESC,
      })
    )
  );

  searchResultsAvailableForCheckin = this.filters.pipe(
    switchMap(ui =>
      this.selectAll({
        filterBy: [
          x => x.propertyId === ui.propertyId,
          x => isReservationAvailableForCheckin(x),
          x => reservationNumberRangeFilter(x, ui.recordNumber, ui.recordNumberRange),
          x =>
            !ui.firstName ||
            x.contacts.some(c => c.name.first?.toLowerCase() === ui.firstName?.toLowerCase()),
          x =>
            !ui.lastName ||
            x.contacts.some(c => c.name.last?.toLowerCase() === ui.lastName?.toLowerCase()),
          x =>
            !ui.crew ||
            (x.affiliation &&
              x.affiliation.crew &&
              x.affiliation.crew.toLowerCase().startsWith(ui.crew.toLowerCase())) ||
            false,
          x =>
            !ui.email ||
            x.contacts.some(c => c.contact.email?.toLowerCase() === ui.email?.toLowerCase()),
          x =>
            !ui.phone ||
            x.contacts.some(c => c.contact.phone?.toLowerCase() === ui.phone?.toLowerCase()),
          x => x.status === ReservationStatus.Open,
          x => !ui.roomTypeId || x.rooms.some(r => r.roomTypeId == ui.roomTypeId),
        ],
      })
    )
  );

  defaultCheckInDate = combineLatest(
    this.select(({ config }) => config.nextDayTime),
    defer(() => of(new Date()))
  ).pipe(map(([nextDayTime, date]) => getDefaultCheckInDate(date, nextDayTime)));

  verificationCode = this.select(x => x.verificationCode);

  claimGuests = this.select(x => x.verifiedGuests);

  lastActivity = this.select(({ lastActivity }) => lastActivity);
}

function filterByZeroCharges(reservation: Reservation): boolean {
  const total = reservation.charges.reduce((total, eachCharge) => total + eachCharge.amount, 0);
  return total === 0 && reservation.status !== ReservationStatus.Canceled;
}

const reservationSort = (a: Reservation, b: Reservation) => {
  return b.recordNumber - a.recordNumber;
};

export const reservationQuery = new ReservationQuery(reservationStore);
