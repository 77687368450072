import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { AppColors } from '../theme';

export interface ShowMoreButtonProps {
  isDone: boolean;
  loadMore: () => void;
}

export const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({ isDone, loadMore }) => {
  if (isDone) return null;
  return (
    <Box borderTop={1} borderColor={AppColors.RowDivider} textAlign={'center'}>
      <Button onClick={() => loadMore()} color="secondary">
        <Typography>Show more</Typography>
      </Button>
    </Box>
  );
};
