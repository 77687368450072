import { Reservations } from '../api';
import { Address } from '../models.common';
import { CorporateAccount } from '../models.common';

export const INVOICE_ACCOUNT_PREFIX = 'INVOICE_';

export type PaymentMethod = Reservations.PaymentMethod;

export interface AvailablePaymentMethods {
  creditCards: PaymentMethod[];
  invoiceAccounts: CorporateAccount[];
}

export type UpdatePaymentMethodModel = Reservations.UpdatePaymentMethodRequest;

export function formatBillingAddress(address: Address): string {
  let fullAddress = address.line1 ? `${address.line1} ` : '';
  fullAddress += address.line2 ? `${address.line2} ` : '';
  fullAddress += address.city && address.state ? '\n' : '';
  fullAddress += address.city ? `${address.city}` : '';
  fullAddress += address.city && address.state ? ', ' : '';
  fullAddress += address.state ? `${address.state} ` : '';
  fullAddress += address.zip ?? '';

  return fullAddress;
}

export function formatPaymentMethod(paymentProfileId?: string | null) {
  const savedMethodProp = paymentProfileId?.startsWith(INVOICE_ACCOUNT_PREFIX)
    ? 'invoiceAccount'
    : 'paymentProfileId';

  return {
    [savedMethodProp]: paymentProfileId?.replace(INVOICE_ACCOUNT_PREFIX, ''),
  };
}
