import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { propertyQuery } from '@lib/state';
import { useObservable, usePageTitle, Section } from '@lib/common';
import { corporateAccountQuery, corporateAccountService, corporateInvoiceService } from 'app/state';
import { CorporateAccountLookup, CorporateAccountFilters } from '../components';
import createInvoiceStatusQuery from '@lib/state/create-invoice-status/create-invoice-status.query';

export const CorporateAccountSearchPage: React.FC<RouteComponentProps<{ propertyId: string }>> = ({
  match,
}) => {
  const customerId = useObservable(propertyQuery.activeProperty)?.customerId;
  const accounts = useObservable(corporateAccountQuery.searchResults);
  const createInvoicesStatus = useObservable(createInvoiceStatusQuery.createInvoicesStatus);

  const filters = useObservable(corporateAccountQuery.filters);
  const pagination = useObservable(corporateAccountQuery.pagination);

  usePageTitle('Corporate Accounts');

  const onChange = useCallback(
    (filters: CorporateAccountFilters) =>
      customerId &&
      corporateAccountService.getCorporateAccounts(customerId, {
        ...filters,
      }),
    [customerId]
  );

  const loadMore = () =>
    customerId &&
    corporateAccountService.getCorporateAccounts(
      customerId,
      { ...filters },
      pagination.continuationToken
    );

  const [fetchedAccountIds, setFetchedAccountIds] = useState<string[]>([]);

  useEffect(() => {
    // Extract account ids that are not already in createInvoicesStatus or fetchedAccountIds
    const accountIds = accounts
      .map(x => x.id)
      .filter(
        id =>
          !createInvoicesStatus.some(status => status.accountId === id) &&
          !fetchedAccountIds.includes(id)
      );

    // Only call getInvoiceStatuses if there are new account ids to fetch
    if (accountIds.length > 0) {
      corporateInvoiceService.getInvoiceStatuses(accountIds);
      setFetchedAccountIds(prevIds => [...prevIds, ...accountIds]); // Add the new account ids to fetchedAccountIds
    }
  }, [accounts, createInvoicesStatus, fetchedAccountIds]);

  return (
    <Section>
      <CorporateAccountLookup
        accounts={accounts}
        createInvoicesStatus={createInvoicesStatus}
        onChange={onChange}
        filters={filters}
        isDone={pagination.isDone}
        loadMore={loadMore}
      />
    </Section>
  );
};
