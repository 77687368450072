import React, { useEffect, useState } from 'react';
import { Button, FormControlLabel, Radio, RadioGroup, Box, Typography } from '@material-ui/core';

import {
  HousekeepingStatusEnum,
  HousekeepingStatusMap,
  HousekeepingAssignment,
  ServicePerformedMap,
  ShowHousekeepingStatus,
} from '@lib/state';
import { DateDisplay, getMapKeyByValue, DataTable } from '@lib/common';
import { RoomUpdateFormProps, useFormStyles } from './room-forms';
import { CompleteHousekeepingAssignmentButton } from './complete-housekeeping-assignment-button';
import { parseISO } from 'date-fns';
import clsx from 'clsx';

const housekeepingStatuses = Object.values(HousekeepingStatusEnum);

interface Props extends RoomUpdateFormProps {
  assignments: HousekeepingAssignment[];
}

export const HousekeepingStatus: React.FC<Props> = ({ room, assignments, onSave }) => {
  const styles = useFormStyles();
  const { housekeepingStatus: initialStatus } = room;

  const [selectedStatus, setSelectedStatus] = useState(initialStatus);
  useEffect(() => {
    setSelectedStatus(initialStatus);
  }, [initialStatus]);

  const onSelectStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const stringValue = (event.target as HTMLInputElement).value;
    const status = getMapKeyByValue(HousekeepingStatusMap, stringValue);
    if (status) setSelectedStatus(status);
  };

  return (
    <>
      <Box className={clsx(styles.container, styles.buttonContainer)}>
        <RadioGroup
          row
          value={HousekeepingStatusMap.get(selectedStatus)}
          onChange={onSelectStatusChange}
        >
          {housekeepingStatuses.map((m: HousekeepingStatusEnum) => (
            <FormControlLabel
              key={m}
              value={HousekeepingStatusMap.get(m)}
              label={HousekeepingStatusMap.get(m)}
              control={<Radio data-testid={`status${m}Radio`} />}
            />
          ))}
        </RadioGroup>
        <Button
          onClick={() => onSave(room, { housekeepingStatus: selectedStatus })}
          variant="contained"
          color="secondary"
          data-testid="statusSaveButton"
        >
          Save
        </Button>
      </Box>
      {(assignments.length > 0 ||
        room.housekeepingStatus !== HousekeepingStatusEnum.NoCleaningRequired) && (
        <DataTable
          items={assignments}
          getItemKey={item => item.id ?? ''}
          columns={[
            {
              title: 'Clean Requested',
              valueFactory: item => (
                <Typography>
                  {ShowHousekeepingStatus(item.serviceRequested, item.isUpcomingCheckOut)}
                </Typography>
              ),
            },
            {
              title: 'Clean Performed',
              valueFactory: item =>
                !!item.servicePerformed || item.isComeBackLater ? (
                  <Typography>
                    {ServicePerformedMap.get(item.servicePerformed ?? undefined)}
                  </Typography>
                ) : (
                  '--'
                ),
            },
            {
              title: 'Disinfected',
              valueFactory: item =>
                !item.servicePerformed ? (
                  '--'
                ) : (
                  <Typography>{item.roomDisinfected ? 'Y' : 'N'}</Typography>
                ),
            },
            {
              title: 'Assigned',
              valueFactory: item =>
                item.assignedTime ? (
                  <DateDisplay date={parseISO(item.assignedTime)} dateFormat="h:mm a" />
                ) : (
                  '--'
                ),
            },
            {
              title: 'Start Time',
              valueFactory: item =>
                item.startTime ? (
                  <DateDisplay date={parseISO(item.startTime)} dateFormat="h:mm a" />
                ) : (
                  '--'
                ),
            },
            {
              title: 'End Time',
              valueFactory: item =>
                item.endTime ? (
                  <DateDisplay date={parseISO(item.endTime)} dateFormat="h:mm a" />
                ) : (
                  '--'
                ),
            },
            {
              title: 'Actions',
              valueFactory: item => {
                if (item.endTime || !item.assignedTime) return null;

                return <CompleteHousekeepingAssignmentButton assignment={item} variant="text" />;
              },
            },
          ]}
        />
      )}
    </>
  );
};
