import React, { useCallback, useMemo } from 'react';
import { checkInService } from '@lib/state';
import {
  useReservation,
  useCheckInRequirements,
  BillingFields,
  getAcceptCardData,
  ReservationRouteProps,
  LoadingIndicator,
} from '@lib/common';
import { KioskCheckIn } from '../components';

export const KioskCheckInPage: React.FC<ReservationRouteProps> = ({ match, location }) => {
  const selectedRoomIds = useMemo(() => {
    const query = new URLSearchParams(location.search);
    const roomIds = query.getAll('roomIds');
    if (roomIds.length > 0) return roomIds;
  }, [location.search]);
  const requirements = useCheckInRequirements(match.params['reservationId']);
  const reservation = useReservation(match.params['reservationId']);

  const onSubmit = useCallback(
    ({ paymentProfileId, billing, card, savePaymentMethod, overrideCode }: BillingFields = {}) => {
      if (reservation) {
        checkInService.checkInReservation(
          reservation,
          billing,
          savePaymentMethod,
          paymentProfileId,
          getAcceptCardData(card),
          overrideCode,
          selectedRoomIds
        );
      }
    },
    [reservation, selectedRoomIds]
  );

  if (reservation) {
    return (
      <KioskCheckIn
        reservation={reservation}
        requirements={requirements}
        onCheckIn={onSubmit}
        data-testid="kioskCheckIn"
      />
    );
  }
  return <LoadingIndicator loadingText="Checking In..." fillContainer />;
};
