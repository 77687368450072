import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, DialogContent, Grid } from '@material-ui/core';

import { FormStatus } from '@lib/state';
import { ErrorDisplay, FormHub, StyledDialog, SubmitButton, useFormEvents } from '@lib/common';
import { CorporateAccount, CorporateAccountForms, corporateAccountService } from 'app/state';
import { GuestEmailLookupInput } from 'app/shared';
import { CorporateAccountDelegateModel } from '@lib/state/api/generated/reservations';

interface Props {
  account: CorporateAccount;
  delegates: CorporateAccountDelegateModel[];
}

export const AddDelegate: React.FC<Props> = ({ account, delegates }) => {
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState<string | undefined>();

  const [{ status, error }, resetUI] = useFormEvents(CorporateAccountForms.AddDelegate);

  useEffect(() => {
    if (status === FormStatus.Success) {
      setShowModal(false);
      resetUI();
    }
  }, [status, resetUI]);

  const onSubmit = useCallback(() => {
    if (userId && !delegates.some(s => s.userId === userId))
      corporateAccountService.addDelegate(account, userId);
  }, [account, userId, delegates]);

  const onAddDelegate = useCallback(() => {
    setShowModal(true);
    resetUI();
  }, [resetUI]);

  return (
    <>
      <Box my="1rem" display="flex" justifyContent="flex-end" flexDirection="row">
        <Button
          color="secondary"
          variant="contained"
          onClick={() => onAddDelegate()}
          data-testid="corporateAddDelegateButton"
        >
          Add Delegate
        </Button>
      </Box>
      <StyledDialog
        title="Add Delegate"
        open={showModal}
        onClose={() => setShowModal(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <FormHub onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <GuestEmailLookupInput
                  name="email"
                  label="Email Lookup"
                  onGuestSelected={x => setUserId(x?.userId)}
                  required
                  fullWidth
                  excludeEmails={delegates.map(m => m.contact.email ?? '')}
                />
              </Grid>
              <Grid item xs={12}>
                <ErrorDisplay error={error} />
                <SubmitButton
                  variant="contained"
                  color="secondary"
                  fullWidth
                  pending={status === FormStatus.Pending}
                  disabled={!userId || status === FormStatus.Pending || status === FormStatus.Error}
                  data-testid="addDelegateSubmitButton"
                >
                  Submit
                </SubmitButton>
              </Grid>
            </Grid>
          </FormHub>
        </DialogContent>
      </StyledDialog>
    </>
  );
};
