import React from 'react';
import { Box, Button, Divider, createStyles, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(theme =>
  createStyles({
    loginButton: {
      flexGrow: 1,
      fontSize: '1.5rem !important',
    },
    loginDivider: {
      margin: '0 1.5em',
    },
    icon: {
      color: theme.palette.background.default,
      marginRight: '0.5em',
    },
  })
);

interface FormLoginOptions {
  signInClickHandler?: () => void;
  signInQRClickHandler?: () => void;
}

export const FormLoginOptions: React.FC<FormLoginOptions> = ({
  signInClickHandler,
  signInQRClickHandler,
}) => {
  const styles = useStyles();
  return (
    <Box display="flex" flexDirection="row" mt={3} mb={1}>
      {signInClickHandler && (
        <Button
          className={styles.loginButton}
          onClick={signInClickHandler}
          color="primary"
          variant="contained"
          data-testid="formAccountLoginButton"
        >
          Account Login
        </Button>
      )}
      {signInClickHandler && signInQRClickHandler && (
        <Divider className={styles.loginDivider} orientation="vertical" flexItem />
      )}
      {signInQRClickHandler && (
        <Button
          className={styles.loginButton}
          onClick={signInQRClickHandler}
          color="primary"
          variant="contained"
          data-testid="formScanQRCodeButton"
        >
          <span className={styles.icon}>
            <FontAwesomeIcon icon={['far', 'qrcode']} size="1x" fixedWidth />
          </span>
          Scan QR Code
        </Button>
      )}
    </Box>
  );
};
