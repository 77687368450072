import React, { useCallback } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Box, Button, Grid } from '@material-ui/core';

import { Section } from '../section';
import { GuestInformationForm } from './guest-information.form';
import { CardFields, CreditCardForm } from './credit-card.form';
import { FormStatus, Address, Name } from '@lib/state';
import { SubmitButton, ErrorDisplay } from '../../forms';
import { withAuthorizeNet } from '../with-authorize-net';

export interface PaymentFields {
  billing?: {
    name: Name;
    address: Address;
  };
  card?: CardFields;
}

interface Props {
  status: FormStatus;
  error?: Error;

  onSubmit: (fields: PaymentFields) => void;
  onCancel: () => void;
}

const AddPaymentMethodFormComponent: React.FC<Props> = ({ error, onSubmit, onCancel }) => {
  const form = useForm<PaymentFields>({ mode: 'onBlur', reValidateMode: 'onChange' });

  const submit = useCallback(
    (values: PaymentFields) => {
      onSubmit(values);
    },
    [onSubmit]
  );

  return (
    <Section title="Payment Info" disableGutters>
      <FormContext {...form}>
        <form onSubmit={form.handleSubmit(submit)}>
          <ErrorDisplay error={error} />
          <GuestInformationForm
            prefix="billing"
            billing
            disablePersonalDetails
            showArchived={false}
          />
          <CreditCardForm />
          <Box py={3}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button variant="contained" fullWidth onClick={onCancel}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <SubmitButton
                  variant="contained"
                  color="secondary"
                  fullWidth
                  pending={status === FormStatus.Pending}
                  disabled={status === FormStatus.Pending}
                >
                  Add Payment
                </SubmitButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </FormContext>
    </Section>
  );
};

export const AddPaymentMethodForm = withAuthorizeNet<Props>(AddPaymentMethodFormComponent);
