import { useEffect } from 'react';

import { roomTypeService, roomTypeQuery, Property } from '@lib/state';
import { useObservable } from './useObservable';

export function usePropertyRoomTypes(propertyId?: Property['id'] | null) {
  useEffect(() => {
    if (!propertyId) return;

    roomTypeService.getPropertyRoomTypes(propertyId);
  }, [propertyId]);

  return useObservable(roomTypeQuery.propertyRoomTypes);
}
