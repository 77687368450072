import React, { memo } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { format } from 'date-fns';
import { format as tzFormat, utcToZonedTime } from 'date-fns-tz';

interface Props extends TypographyProps {
  date?: number | Date | null;
  dateFormat?: string;
  timeZone?: string;
  testId?: string;
}

export const DateDisplay: React.FC<Props> = memo(
  ({ date, dateFormat = 'M/d', timeZone, children, testId, ...props }) => {
    let displayDate;
    if (!!date && !!timeZone) {
      displayDate = tzFormat(utcToZonedTime(date, timeZone), dateFormat, {
        timeZone: timeZone,
      });
    } else if (!!date) {
      displayDate = format(date, dateFormat);
    } else {
      displayDate = children ?? '--';
    }

    return (
      <Typography component={'span'} noWrap display="block" {...props} data-testid={testId}>
        {displayDate}
      </Typography>
    );
  }
);
