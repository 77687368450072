import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';

import { Reservations } from '../api';
import { PaginatedQuery, PaginationState, CommonForm } from '../models.common';
import { ActivityReportStore, activityReportStore } from './activity-report.store';
import { lightFormat } from 'date-fns';
import { activityMapper, exportFileName } from './activity-report.model';
import { saveAs } from 'file-saver';
import { StayInfoFilters } from '.';
import { dispatchForm, BookedByUserType, authUserTypeFromBookedBy } from '..';

export class ActivityReportService {
  constructor(
    private store: ActivityReportStore,
    private readonly activityApi: Reservations.ActivityApi
  ) {}

  getMyActivity(filters: StayInfoFilters, page?: PaginatedQuery): void {
    this.updateUI({
      ...filters,
    });

    from(
      this.activityApi.activityGet(
        filters.recordNumber ?? undefined,
        filters.firstName ?? undefined,
        filters.lastName ?? undefined,
        filters.crew ?? undefined,
        filters.start && lightFormat(filters.start, 'yyyy-MM-dd'),
        filters.end && lightFormat(filters.end, 'yyyy-MM-dd'),
        filters.bookedBy === BookedByUserType.You ? filters.userId : undefined,
        authUserTypeFromBookedBy(filters.bookedBy),
        filters.corporateAccountId,
        page?.continuationToken ?? undefined,
        page?.limit
      )
    )
      .pipe(map(({ data }) => data))
      .subscribe(({ data, ...page }) =>
        applyTransaction(() => {
          this.store.upsertMany(activityMapper(page)(data));
          this.updatePaginationState(page);
        })
      );
  }

  private updateUI(state: Partial<StayInfoFilters>) {
    this.store.update(({ ui }) => ({
      ui: { ...ui, ...state },
    }));
  }

  private updatePaginationState({ isDone, continuationToken }: PaginationState) {
    this.store.update(() => ({
      pagination: { isDone, continuationToken },
    }));
  }

  exportMyCharges(start: Date, end: Date, userId?: string, bookedBy?: BookedByUserType) {
    from(
      this.activityApi.activityExportGet(
        lightFormat(start, 'yyyy-MM-dd'),
        lightFormat(end, 'yyyy-MM-dd'),
        bookedBy === BookedByUserType.You ? userId : undefined,
        authUserTypeFromBookedBy(bookedBy),
        {
          responseType: 'blob',
        }
      )
    )
      .pipe(
        map(x => new Blob([x.data], { type: 'text/csv' })),
        dispatchForm(CommonForm.Export)
      )
      .subscribe(x => saveAs(x, exportFileName(start, end)));
  }

  getGuestActivity(userId: string, propertyId: string, page?: PaginatedQuery): void {
    from(
      this.activityApi.activityPropertyIdUserIdGet(
        propertyId,
        userId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        page?.continuationToken ?? undefined,
        page?.limit
      )
    )
      .pipe(map(({ data }) => data))
      .subscribe(({ data, ...page }) =>
        applyTransaction(() => {
          this.store.upsertMany(activityMapper(page)(data));
          this.updatePaginationState(page);
        })
      );
  }

  exportGuestCharges(userId: string, propertyId: string, start: Date, end: Date) {
    from(
      this.activityApi.activityPropertyIdUserIdExportGet(
        propertyId,
        userId,
        lightFormat(start, 'yyyy-MM-dd'),
        lightFormat(end, 'yyyy-MM-dd'),
        {
          responseType: 'blob',
        }
      )
    )
      .pipe(
        map(x => new Blob([x.data], { type: 'text/csv' })),
        dispatchForm(CommonForm.Export)
      )
      .subscribe(x => saveAs(x, exportFileName(start, end)));
  }

  reset(): void {
    this.store.reset();
  }
}

export const activityReportService = new ActivityReportService(
  activityReportStore,
  new Reservations.ActivityApi()
);
