import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';

import { Property } from '@lib/state';
import { GuestNote } from './guest-notes.model';

export interface GuestNoteFilters {
  note: string;
  noteType: string;
  status: string;
}

export interface GuestNoteUIState {
  propertyId: Property['id'] | null;
  filters: GuestNoteFilters;
}

export interface GuestNoteState
  extends EntityState<GuestNote, GuestNote['id']>,
    ActiveState<GuestNote['id']> {
  ui: GuestNoteUIState;
}

export const initialGuestNoteFilters = {
  note: '',
  noteType: '',
  status: '',
};

@StoreConfig({ name: 'guestNotes', resettable: true })
export class GuestNoteStore extends EntityStore<GuestNoteState> {
  constructor() {
    super({
      ui: {
        propertyId: null,
        filters: initialGuestNoteFilters,
      },
    });
  }
}

export const guestNoteStore = new GuestNoteStore();
