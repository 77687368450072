import { Properties } from '@lib/state';

import { EmployeeFilters } from './employee.store';

export type Employee = Properties.EmployeeModel;
export type EmployeeRole = Properties.EmployeePropertyRoleModel;

function nameFilter(nameFilterValue: string, name: string | null | undefined) {
  return nameFilterValue === '' || (name && name.indexOf(nameFilterValue) > -1);
}

function propertyRolesFilter(rolesFilterValue: string[], roles: string[]) {
  return (
    // If we have 0 filter roles
    rolesFilterValue.length === 0 ||
    // Or if there are filter roles and search roles
    // Check if search roles has all filter roles
    (roles.length > 0 &&
      rolesFilterValue.length > 0 &&
      rolesFilterValue.every(r => roles.includes(r)))
  );
}

export function filterEmployee(filters: EmployeeFilters, employee: Employee) {
  return (
    nameFilter(filters.firstName, employee.name.first) &&
    nameFilter(filters.lastName, employee.name.last) &&
    propertyRolesFilter(
      filters.roles,
      employee.propertyRoles.map(x => x.role)
    )
  );
}
