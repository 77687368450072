import React from 'react';

import { FormTextFieldProps, TextFieldProps } from '../form-text-field';
import { FormSelect } from '../form-select';
import { MenuItem } from '@material-ui/core';
import { Gender } from '@lib/state/api/generated/reservations';

type Props = Omit<FormTextFieldProps, 'validationOptions' | 'type'> & TextFieldProps;

export const FormGenderField: React.FC<Props> = ({
  label = 'Gender',
  defaultValue,
  required,
  ...props
}) => {
  return (
    <FormSelect {...props} label={label} defaultValue={defaultValue} fullWidth required={required}>
      {Genders.map(x => (
        <MenuItem key={x.value} value={x.value}>
          {x.label}
        </MenuItem>
      ))}
    </FormSelect>
  );
};

export const Genders = [
  { value: Gender.Male, label: 'Male' },
  { value: Gender.Female, label: 'Female' },
  { value: Gender.Other, label: 'Other' },
];
