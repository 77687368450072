import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isBefore, startOfDay, addDays } from 'date-fns';

import {
  Reservation,
  FormStatus,
  reservationService,
  AvailablePaymentMethods,
  GuestProfile,
  PropertyPolicy,
} from '@lib/state';
import {
  withAuthorizeNet,
  PaymentInfoForm,
  ManagerOverrideInput,
  LineItemDisplay,
} from '../../components';
import { SubmitButton, ErrorDisplay, FormCheckbox } from '../../forms';
import { RulesAndRestrictionsModal } from '../../reservations/rules-and-restrictions.modal';

interface Props {
  reservation: Reservation;
  paymentMethods: AvailablePaymentMethods;
  paymentGuest: GuestProfile;
  onDateChange: (checkInDate: Date, checkOutDate: Date) => void;
  availabilityStatus: FormStatus;
  availabilityError?: Error;
  rebookStatus: FormStatus;
  rebookOnly: boolean;
  rebookLabelText: string;
  rebookError?: Error;
  isManagerOverrideRequired?: boolean;
  overrideable?: boolean;
  defaultCheckInDate: Date | null;
  defaultCheckOutDate: Date | null;
  onClose?: () => void;
  policy?: PropertyPolicy;
}

const RebookReservationFormComponent: React.FC<Props> = ({
  reservation,
  paymentMethods,
  paymentGuest,
  onDateChange,
  rebookStatus,
  rebookOnly,
  rebookLabelText,
  rebookError,
  availabilityStatus,
  availabilityError,
  isManagerOverrideRequired = false,
  overrideable,
  defaultCheckInDate,
  defaultCheckOutDate,
  policy,
  onClose,
}) => {
  const { register, getValues, watch, setValue, errors } = useFormContext();
  const [isPaymentInfoLoaded, setIsPaymentInfoLoaded] = useState<boolean>(false);

  useEffect(() => {
    reservationService.getReservationModificationOptions(reservation);
  }, [reservation.id]);

  useEffect(() => {
    const validate = (): boolean => {
      const { checkInDate, checkOutDate } = getValues();
      if (checkOutDate === null || checkInDate === null) {
        return true;
      }
      return isBefore(checkInDate, checkOutDate);
    };

    register({ name: 'checkInDate' }, { required: true, validate });
    register({ name: 'checkOutDate' }, { required: true, validate });

    setValue('checkInDate', defaultCheckInDate);
    setValue('checkOutDate', defaultCheckOutDate);
  }, []);

  const checkInDate = watch('checkInDate', defaultCheckInDate);
  const checkOutDate = watch('checkOutDate', defaultCheckOutDate);

  useEffect(() => onDateChange(checkInDate, checkOutDate), [
    checkInDate,
    checkOutDate,
    onDateChange,
  ]);

  const onCheckInDateChange = useCallback(
    (value: any) => {
      if (!!checkOutDate && checkOutDate <= value) return setValue('checkOutDate', null);
      setValue('checkInDate', value && startOfDay(value), true);
    },
    [checkOutDate]
  );

  const showForm: boolean =
    reservation.cancelRefundsWithOverrideCode !== undefined &&
    reservation.cancelRefundsWithoutOverrideCode !== undefined &&
    reservation.createdReservationTotalCharges !== undefined;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            autoOk
            disableToolbar
            fullWidth
            variant="inline"
            format="MMMM d"
            label="Check-In Date"
            name="checkInDate"
            value={checkInDate}
            minDate={startOfDay(new Date())}
            error={!!errors.checkInDate}
            onChange={value => onCheckInDateChange(value)}
            color="primary"
            size="small"
            InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
            data-testid="rebookCheckInDatePicker"
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            disabled={checkInDate === null}
            autoOk
            disableToolbar
            disablePast
            fullWidth
            variant="inline"
            format="MMMM d"
            label="Check-Out Date"
            name="checkOutDate"
            minDate={checkInDate ? startOfDay(addDays(checkInDate, 1)) : undefined}
            value={checkOutDate}
            error={!!errors.checkOutDate}
            onChange={value => setValue('checkOutDate', value && startOfDay(value), true)}
            color="primary"
            size="small"
            InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
            data-testid="rebookCheckOutDatePicker"
          />
        </Grid>
        {(!!errors.checkInDate || !!errors.checkOutDate) && (
          <Grid item xs={12}>
            <Typography>Check-out must be after check-in</Typography>
          </Grid>
        )}
      </Grid>

      {showForm && (
        <Grid container spacing={2}>
          {!rebookOnly && (
            <>
              <Grid item xs={12}>
                <LineItemDisplay
                  variant="body2"
                  label="Refunds for cancel"
                  amount={reservation.cancelRefundsWithoutOverrideCode}
                />
              </Grid>
              {overrideable && (
                <Grid item xs={12}>
                  <LineItemDisplay
                    variant="body2"
                    label="Refunds for cancel with manager code"
                    amount={reservation.cancelRefundsWithOverrideCode}
                  />
                </Grid>
              )}
              {(isManagerOverrideRequired || overrideable) && (
                <Grid item xs={6}>
                  <ManagerOverrideInput
                    required={isManagerOverrideRequired}
                    requiredMessage={
                      'Required to complete this opertation because this reservation was booked without flexibility.'
                    }
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <LineItemDisplay
              variant="body2"
              label="Charges to book new reservation"
              amount={reservation.createdReservationTotalCharges}
            />
          </Grid>
          <Grid item xs={12} data-testid="rebookPaymentForm">
            <PaymentInfoForm
              paymentMethods={paymentMethods}
              guest={paymentGuest}
              onPaymentInfoLoaded={() => setIsPaymentInfoLoaded(true)}
            />
          </Grid>
        </Grid>
      )}

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <FormCheckbox
          name="acceptPolicy"
          label={
            <Typography variant="body2">
              I agree to the <RulesAndRestrictionsModal policy={policy} />
            </Typography>
          }
          validationOptions={{
            validate: {
              accepted: value => value || 'Rules and restrictions must be accepted.',
            },
          }}
          helperText={errors => errors['acceptPolicy']?.message}
          data-testid="rebookAcceptPolicyCheck"
        />
      </Box>
      {rebookStatus === FormStatus.Success ? (
        <Box my={3}>
          <Button type="button" variant="contained" color="secondary" fullWidth onClick={onClose}>
            Close
          </Button>
        </Box>
      ) : (
        <Box my={3}>
          <ErrorDisplay error={availabilityError ?? rebookError} />
          <SubmitButton
            variant="contained"
            color="secondary"
            fullWidth
            pending={
              rebookStatus === FormStatus.Pending || availabilityStatus === FormStatus.Pending
            }
            disabled={
              availabilityStatus === FormStatus.Pending ||
              availabilityStatus === FormStatus.Error ||
              rebookStatus === FormStatus.Pending ||
              rebookStatus === FormStatus.Error ||
              !isPaymentInfoLoaded
            }
            data-testid="rebookSubmitButton"
          >
            <span>{rebookLabelText}</span>
          </SubmitButton>
        </Box>
      )}
    </>
  );
};

export const RebookReservationForm = withAuthorizeNet(RebookReservationFormComponent);
