import {
  StyledDialog,
  useObservable,
  useFormEvents,
  SubmitButton,
  FormHub,
  ErrorDisplay,
  useHasRole,
  Feedback,
} from '@lib/common';
import {
  FormStatus,
  roomsQuery,
  roomsService,
  Properties,
  Manager,
  Admin,
  Maintenance,
  reservationQuery,
  reservationService,
  Reservation,
} from '@lib/state';
import {
  DialogContent,
  Tabs,
  Tab,
  Box,
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { TabContext, TabPanel } from '@material-ui/lab';
import { AuditLogs } from 'app/shared';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  employeeQuery,
  employeeService,
  MaintenanceTicketForms,
  maintenanceTicketQuery,
  maintenanceTicketService,
} from 'app/state';
import { MaintenanceTicketForm } from './maintenance-ticket-form';
import { MaintenanceTicketPhotoUpload } from './maintenance-ticket-photo-upload';
import { MaintenanceJournal } from './maintenance-journal';

export interface MaintenanceTicketFormFields {
  status: Properties.MaintenanceTicketStatusEnum;
  priority: Properties.PriorityOptions;
  roomId?: string | null;
  area?: string;
  assignedTo?: string | null;
  description?: string | null;
  isActive?: boolean;
}

interface Props {
  propertyId: string;
  onClose: () => void;
  open: boolean;
}

const useStyles = makeStyles(theme =>
  createStyles({
    fullWidth: {
      width: '100%',
    },
  })
);

export const MaintenanceTicketModal: React.FC<Props> = ({ propertyId, open, onClose }) => {
  const [{ status, error }, resetUI] = useFormEvents(MaintenanceTicketForms.Manage);
  const [{ status: deleteStatus, error: deleteError }] = useFormEvents(
    MaintenanceTicketForms.Delete
  );
  const styles = useStyles();

  const reservations = useObservable(reservationQuery.reservations);
  const existingPhotos = useObservable(maintenanceTicketQuery.photos);

  const ticket = useObservable(maintenanceTicketQuery.selectedTicket);

  const [selectedRoomId, setSelectedRoomId] = useState<string | undefined>(
    ticket?.roomId ?? undefined
  );
  const [area, setArea] = useState<string | undefined>(ticket?.area ?? undefined);

  const [selectedReservation, setReservation] = useState<Reservation | undefined>(undefined);

  const hasManagerRole = useHasRole(Admin, Manager);
  const hasMaintenanceRole = useHasRole(Maintenance);
  const employees = useObservable(employeeQuery.maintenanceEligibleEmployees);

  useEffect(() => {
    if (!!ticket?.id) maintenanceTicketService.getMaintenanceTicketPhotos(ticket.id);
  }, [ticket]);

  useEffect(() => {
    if (selectedRoomId) reservationService.getReservationByRoomId(selectedRoomId);
  }, [selectedRoomId]);

  useEffect(() => {
    if (!!reservations?.length && selectedRoomId)
      setReservation(reservations.filter(x => x.rooms.some(f => f.roomId === selectedRoomId))?.[0]);
  }, [reservations, selectedRoomId]);

  const defaultValues: MaintenanceTicketFormFields = useMemo(
    () => ({
      roomId: ticket?.roomId,
      status: ticket?.status ?? Properties.MaintenanceTicketStatusEnum.Open,
      priority: ticket?.priority ?? Properties.PriorityOptions.Normal,
      area: ticket?.area ?? '',
      assignedTo: ticket?.assignedTo?.id ?? '',
    }),
    [ticket]
  );

  useEffect(() => {
    roomsService.getRooms(propertyId);
    employeeService.getEmployees(propertyId);
  }, [propertyId]);

  const rooms = useObservable(roomsQuery.rooms);

  const submit = useCallback(
    (fields: MaintenanceTicketFormFields) => {
      if (!ticket?.id) {
        maintenanceTicketService.createMaintenanceTicket({
          propertyId: propertyId,
          priority: fields.priority,
          roomId: fields.roomId,
          area: fields.area,
          description: fields.description,
          assignedTo: fields.assignedTo === '' ? undefined : fields.assignedTo,
        });
      } else {
        maintenanceTicketService.updateMaintenanceTicket(ticket.id, {
          propertyId: propertyId,
          priority: fields.priority,
          roomId: fields.roomId,
          status: fields.status,
          area: fields.area,
          assignedTo: fields.assignedTo === '' ? undefined : fields.assignedTo,
          isActive: fields.isActive,
        });
      }
    },
    [propertyId, ticket]
  );

  const deleteTicket = useCallback(() => {
    maintenanceTicketService.deleteMaintenanceTicket(ticket!.id);
  }, [ticket]);

  const deletePhoto = useCallback(
    (photoId: string) => {
      maintenanceTicketService.deleteMaintenanceTicketPhoto(ticket.id, photoId);
    },
    [ticket]
  );

  useEffect(() => {
    if (deleteStatus === FormStatus.Success) onClose();
  }, [deleteStatus, onClose]);

  const [tab, setTab] = useState('ticket');

  return (
    <>
      <StyledDialog
        title={ticket?.id ? `Ticket ${ticket.ticketNumber}` : 'Create Ticket'}
        onClose={onClose}
        open={open}
        maxWidth="lg"
        scroll="paper"
      >
        <TabContext value={tab}>
          <Tabs
            value={tab}
            onChange={(_, x) => setTab(x)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Details" value="ticket" />
            {!!ticket?.id && <Tab label="Notes" value="notes" />}
            {!!ticket?.id && <Tab label="Photos" value="photos" />}
            {!!ticket?.id && <Tab label="History" value="history" />}
          </Tabs>
          <DialogContent>
            <TabPanel value="ticket">
              <ErrorDisplay error={error ?? deleteError} />
              <FormHub onSubmit={submit} formOptions={{ defaultValues }}>
                <MaintenanceTicketForm
                  ticket={ticket}
                  rooms={rooms}
                  employees={employees}
                  hasManagerRole={hasManagerRole}
                  hasMaintenanceRole={hasMaintenanceRole}
                  setSelectedRoomId={setSelectedRoomId}
                  selectedRoomId={selectedRoomId}
                  setArea={setArea}
                  area={area}
                  selectedReservation={selectedReservation}
                />
                <Feedback show={status === FormStatus.Success} severity="success" onHide={resetUI}>
                  Maintenance ticket saved successfully!
                </Feedback>
                <Box mt={2} display="flex">
                  {ticket && hasManagerRole && (
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={!!deleteStatus && deleteStatus === FormStatus.Pending}
                      fullWidth
                      style={{ margin: '0 6px 0 0', opacity: '60%' }}
                      onClick={deleteTicket}
                    >
                      Delete
                    </Button>
                  )}
                  <SubmitButton
                    variant="contained"
                    color="primary"
                    disabled={!!status && status === FormStatus.Pending}
                    fullWidth
                  >
                    Save
                  </SubmitButton>
                </Box>
              </FormHub>
            </TabPanel>
            <TabPanel value="photos">
              <Box mt={2} display="flex">
                <ImageList cols={3} className={styles.fullWidth}>
                  {existingPhotos &&
                    existingPhotos.map(item => {
                      return (
                        <ImageListItem key={item.id}>
                          <img src={item.url} alt={item.id} loading="lazy" />
                          <ImageListItemBar
                            actionIcon={
                              <IconButton color="secondary" onClick={() => deletePhoto(item.id)}>
                                <DeleteIcon />
                              </IconButton>
                            }
                          />
                        </ImageListItem>
                      );
                    })}
                </ImageList>
              </Box>
              <Box mt={2} display="flex">
                <MaintenanceTicketPhotoUpload ticket={ticket} />
              </Box>
            </TabPanel>
            {ticket && (
              <TabPanel value="history">
                <AuditLogs scope="maintenance-ticket" id={ticket.id} />
              </TabPanel>
            )}
            {ticket && (
              <TabPanel value="notes">
                <MaintenanceJournal ticketId={ticket.id} propertyId={ticket.propertyId} />
              </TabPanel>
            )}
          </DialogContent>
        </TabContext>
      </StyledDialog>
    </>
  );
};
