import React from 'react';
import { Grid } from '@material-ui/core';
import { Reservation } from '@lib/state';
import { ReservationInfoMetaData } from './reservation-info-meta';
import { ReservationInfoBookingSource } from './reservation-info-booking-source';
import { ReservationInfoGuestInfo } from './reservation-info-guest-info';
import { ReservationInfoSpecialRequests } from './reservation-info-special-requests';
import { GuestStay } from 'app/state';

interface Props {
  reservation: Reservation;
  guestStays: GuestStay[];
}

export const ReservationInfo: React.FC<Props> = ({ reservation, guestStays }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <ReservationInfoMetaData reservation={reservation} guestStays={guestStays} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReservationInfoBookingSource reservation={reservation} />
      </Grid>
      <Grid item xs={12}>
        <ReservationInfoGuestInfo reservation={reservation} />
      </Grid>
      <Grid item xs={12}>
        <ReservationInfoSpecialRequests reservation={reservation} />
      </Grid>
    </Grid>
  );
};
