import { InventoryAdjustmentStore, inventoryAdjustmentStore } from './inventory-adjustment.store';
import { from, throwError, EMPTY } from 'rxjs';
import { catchError, map, tap, switchMap } from 'rxjs/operators';
import { InventoryAdjustmentForms } from './inventory-adjustment.forms';
import { applyTransaction, setLoading } from '@datorama/akita';
import { isAxiosError, Reservations, Property, dispatchForm } from '..';
import { UpdateInventoryAdjustmentRequest } from '../api/generated/reservations';
import { lightFormat } from 'date-fns';

export class InventoryAdjustmentService {
  constructor(
    private readonly store: InventoryAdjustmentStore,
    private readonly inventoryAdjustmentApi: Reservations.InventoryAdjustmentApi
  ) {}

  getInventoryAdjustments(
    propertyId: Property['id'],
    corporateAccountId?: string,
    roomTypeId?: string,
    startDate?: Date,
    endDate?: Date
  ) {
    this.store.reset();

    this.loadAdjustmentsObs(
      propertyId,
      corporateAccountId,
      roomTypeId,
      startDate,
      endDate
    ).subscribe();
  }

  updateInventoryAdjustments(
    propertyId: Property['id'],
    request: UpdateInventoryAdjustmentRequest
  ) {
    from(this.inventoryAdjustmentApi.inventoryadjustmentPost(request))
      .pipe(
        catchError((e: Error) => {
          if (isAxiosError(e) && e.response?.status === 500) {
            return throwError(
              new Error('An error occurred while updating the inventory adjustments.')
            );
          }

          return throwError(e);
        }),
        dispatchForm(InventoryAdjustmentForms.Update),
        catchError(() => EMPTY), // do not propogate error
        switchMap(_ => this.loadAdjustmentsObs(propertyId))
      )
      .subscribe();
  }

  private loadAdjustmentsObs(
    propertyId: Property['id'],
    corporateAccountId?: string,
    roomTypeId?: string,
    startDate?: Date,
    endDate?: Date
  ) {
    var start = undefined;
    var end = undefined;

    if (!!startDate && !!endDate) {
      start = lightFormat(startDate, 'yyyy-MM-dd');
      end = lightFormat(endDate, 'yyyy-MM-dd');
    }
    return from(
      this.inventoryAdjustmentApi.inventoryadjustmentGet(
        propertyId,
        corporateAccountId,
        roomTypeId,
        start,
        end
      )
    ).pipe(
      setLoading(this.store),
      map(data => data.data.data),
      tap(adjustments =>
        applyTransaction(() => {
          this.store.update({
            inventoryAdjustmentDateGroups: adjustments,
          });
        })
      )
    );
  }
}

export const inventoryAdjustmentService = new InventoryAdjustmentService(
  inventoryAdjustmentStore,
  new Reservations.InventoryAdjustmentApi()
);
