import React, { useState } from 'react';
import { startOfDay } from 'date-fns';
import { usePageTitle } from '@lib/common';
import { TimelineView, useCurrentStayDate } from 'app/shared';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { GuestStayTapeChartController } from '../components/guest-stay-tape-chart-controller';

export const TapeChartPage: React.FC = () => {
  const [startDate, setStartDate] = useCurrentStayDate();
  const [selectedView, setView] = useState(TimelineView.TwoWeek);
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const view = onMobile ? TimelineView.OneDay : selectedView;

  usePageTitle('Tape Chart');

  return (
    <GuestStayTapeChartController
      startDate={startDate}
      view={view}
      onChangeDate={date => setStartDate(startOfDay(date))}
      onChangeView={view => setView(view)}
    />
  );
};
