import React, { useEffect, useState, useMemo } from 'react';
import {
  housekeeperQuery,
  housekeepingService,
  housekeepingAssignmentQuery,
  sessionQuery,
  housekeepingAssignmentService,
  roomZoneQuery,
  roomZoneService,
  filterRoomAssignmentByType,
  filterRoomAssignmentByStatus,
} from '@lib/state';
import { Section, useObservable, usePageTitle } from '@lib/common';
import { Container } from '@material-ui/core';
import { HousekeepingFilter, RoomAssignmentsList, RoomAssignmentsFilter } from '../components';
import { startOfToday } from 'date-fns';

export const RoomAssignmentsPage: React.FC = () => {
  const housekeepers = useObservable(housekeeperQuery.housekeepers);
  const roomZones = useObservable(roomZoneQuery.roomZones);
  const allRooms = useObservable(housekeepingAssignmentQuery.roomsByDate);
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const [date, setDate] = useState(startOfToday());
  const [filter, setFilter] = useState<HousekeepingFilter>({
    date: startOfToday(),
  });

  usePageTitle('Housekeeping');

  useEffect(() => {
    if (propertyId) {
      housekeepingService.loadHousekeepers(propertyId);
      housekeepingAssignmentService.loadHousekeepingAssignments(propertyId, date);
      roomZoneService.getPropertyRoomZones(propertyId);
    }
  }, [propertyId, date]);

  const filteredRooms = useMemo(() => {
    let rooms = allRooms;

    rooms = filterRoomAssignmentByType(rooms, filter.roomAssignmentType);

    if (!!filter.housekeepingStatus)
      rooms = filterRoomAssignmentByStatus(rooms, filter.housekeepingStatus);

    if (!!filter.housekeeperId)
      rooms = rooms.filter(f => f.housekeeperUserId === filter.housekeeperId);

    if (!!filter.zoneId) rooms = rooms.filter(f => f.roomZoneId === filter.zoneId);

    if (!!filter.roomNumber) {
      rooms = rooms.filter(f => f.roomNumber.indexOf(filter.roomNumber!) >= 0);
    }
    if (filter.date) {
      setDate(filter.date);
    }
    if (filter.sleepSchedule) {
      rooms = rooms.filter(r => r.affiliation?.sleepSchedule === filter.sleepSchedule);
    }
    if (filter.servicePerformed) {
      rooms = rooms.filter(
        r =>
          r.servicePerformed === filter.servicePerformed?.replace(/\s/g, '') ||
          (r.isComeBackLater && filter.servicePerformed === 'ComeBackLater')
      );
    }
    return rooms;
  }, [filter, allRooms]);

  if (!propertyId) return null;

  return (
    <Container maxWidth="xl">
      <RoomAssignmentsFilter
        filter={filter}
        rooms={filteredRooms}
        zones={roomZones}
        housekeepers={housekeepers}
        onFilterChange={setFilter}
      />
      <Section disableGutters>
        <RoomAssignmentsList
          allRooms={filteredRooms}
          housekeepers={housekeepers}
          propertyId={propertyId}
          date={date}
          disable={date < startOfToday()}
        />
      </Section>
    </Container>
  );
};
