import React, { useEffect, useMemo } from 'react';
import { Observable, EMPTY } from 'rxjs';
import { Property, propertyQuery, propertyService } from '@lib/state';
import { useObservable } from './useObservable';

export const PropertyFeatureContext = React.createContext<string[]>([]);

export function withPropertyFeatures<P>(
  WrappedComponent: React.ComponentType<P>,
  selectedProperty: Observable<Property['id'] | null | undefined>
) {
  const ComponentWithFlags = (props: P) => {
    const propertyId = useObservable(selectedProperty, 'async');

    useEffect(() => {
      if (propertyId) {
        propertyService.loadPropertyConfiguration(propertyId);
      }
    }, [propertyId]);

    const configurationQuery = useMemo(() => {
      if (propertyId) {
        return propertyQuery.selectEntity(propertyId, 'configuration');
      }
      return EMPTY;
    }, [propertyId]);

    const configuration = useObservable(configurationQuery, 'async');

    return (
      <PropertyFeatureContext.Provider value={configuration?.features ?? []}>
        <WrappedComponent {...props} />
      </PropertyFeatureContext.Provider>
    );
  };

  return ComponentWithFlags;
}
