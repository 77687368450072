import { makeStyles, createStyles } from '@material-ui/core';
import { AppColors } from '@lib/common';

export const useTableStyles = makeStyles(theme =>
  createStyles({
    tableHeader: {
      fontWeight: 'bold',
    },
    tableCell: {
      borderBottom: 'none',
      padding: theme.spacing(0.75),
      '&:not(:last-child)': {
        borderRight: `1px solid ${AppColors.RowDivider}`,
      },
    },
    zebraTableRow: {
      '&:nth-child(odd)': {
        backgroundColor: AppColors.LightGray,
      },
    },
    tableRow: {
      borderBottom: `1px solid ${AppColors.RowDivider}`,
    },
  })
);
