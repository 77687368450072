import React from 'react';
import { Typography, makeStyles, createStyles, Grid, Button, Paper } from '@material-ui/core';

import { Room, Reservation, ReservationStatus } from '@lib/state';
import {
  EditReservationButton,
  CheckOutReservationButton,
  KioskOnly,
  useCommonStyle,
  LateCheckOutReservationButton,
  GuestNameListDisplay,
} from '@lib/common';
import { GuestStay } from 'app/state';
import { GuestRoomDates } from './guest-room-dates';
import { PrintQRCodeButton } from 'app/shared';
import { ResendUserKeyButton } from 'app/reservations/components/resend-user-key-button';

const useStyles = makeStyles(theme =>
  createStyles({
    title: {
      fontSize: '150%',
      fontWeight: 'bold',
    },
    additionalGuest: {
      fontSize: '150%',
    },
    button: {
      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
  })
);

interface GuestStayDetailProps {
  stay?: GuestStay;
  reservation?: Reservation;
  room: Room;
  startChangeRoom: () => void;
  closeModal: () => void;
}

export const GuestStayDetails: React.FC<GuestStayDetailProps> = ({
  stay,
  reservation,
  room,
  startChangeRoom,
  closeModal,
}) => {
  const styles = useStyles();
  const { paper } = useCommonStyle();

  return (
    <Paper elevation={4} className={paper}>
      {stay && reservation ? (
        <Grid container spacing={1}>
          <Grid item xs={4} alignItems="flex-start">
            <GuestNameListDisplay
              className={i => (i === 0 ? styles.title : styles.additionalGuest)}
              guests={stay?.guests}
            />
          </Grid>
          <Grid item xs={8} container alignItems="flex-start" justify="flex-end">
            {reservation.status === ReservationStatus.CheckedIn ? (
              <KioskOnly>
                <PrintQRCodeButton
                  color="secondary"
                  userId={reservation.userId}
                  reservation={reservation}
                  printAllGuests={true}
                >
                  Print Guest Room Keys
                </PrintQRCodeButton>
              </KioskOnly>
            ) : null}
            {reservation.status === ReservationStatus.CheckedIn && (
              <ResendUserKeyButton reservation={reservation} variant="text" />
            )}
            {!stay.hasLateCheckout && (
              <LateCheckOutReservationButton reservation={reservation} variant="text" />
            )}
            <Button color="secondary" onClick={startChangeRoom}>
              Change Room
            </Button>
          </Grid>
          <Grid item xs={6}>
            <GuestRoomDates stay={stay} room={room} />
          </Grid>
          <Grid item xs={6} container alignItems="center" justify="flex-end">
            <EditReservationButton reservation={reservation} onSuccess={closeModal} />
            <CheckOutReservationButton
              reservation={reservation}
              onSuccess={closeModal}
              overrideable={true}
            />
          </Grid>
        </Grid>
      ) : (
        <Typography className={styles.title}>No current guest</Typography>
      )}
    </Paper>
  );
};
