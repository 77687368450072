import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { ReservationPolicy } from '@lib/state';
import { TimeThresholdDisplay } from 'app/shared';
import { formatIncomingPolicy } from 'app/state';

interface Props {
  policy: ReservationPolicy;
}

export const ReservationPolicyDisplay: React.FC<Props> = ({ policy }) => {
  const formattedReservationPolicy = useMemo(() => {
    return formatIncomingPolicy(policy);
  }, [policy]);

  if (!formattedReservationPolicy) return null;

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TimeThresholdDisplay
          label="Check In"
          asDays={false}
          timeOfDay={formattedReservationPolicy.checkIn.timeOfDay}
          gracePeriodInMinutes={formattedReservationPolicy.checkIn.gracePeriodInMinutes}
        />
      </Grid>
      <Grid item xs={6}>
        <TimeThresholdDisplay
          label="Check Out"
          asDays={false}
          isPrior={true}
          timeOfDay={formattedReservationPolicy.checkOut.timeOfDay}
          gracePeriodInMinutes={formattedReservationPolicy.checkOut.gracePeriodInMinutes}
        />
      </Grid>
      <Grid item xs={6}>
        <TimeThresholdDisplay
          label="Early Check In"
          timeOfDay={formattedReservationPolicy.earlyCheckIn.timeOfDay}
          gracePeriodInMinutes={formattedReservationPolicy.earlyCheckIn.gracePeriodInMinutes}
          asDays={false}
        />
      </Grid>
      <Grid item xs={6}>
        <TimeThresholdDisplay
          label="Late Check Out"
          asDays={false}
          isPrior={true}
          timeOfDay={formattedReservationPolicy.lateCheckOut.timeOfDay}
          gracePeriodInMinutes={formattedReservationPolicy.lateCheckOut.gracePeriodInMinutes}
        />
      </Grid>
      <Grid item xs={6}>
        <TimeThresholdDisplay
          label="Changeable Threshold"
          asDays={true}
          timeOfDay={formattedReservationPolicy.changeableThreshold.timeOfDay}
          gracePeriodInMinutes={formattedReservationPolicy.changeableThreshold.gracePeriodInMinutes}
          isPrior={true}
          gracePeriodDetailText="plus a period of"
        />
      </Grid>
      <Grid item xs={6}>
        <TimeThresholdDisplay
          label="Cancelable Threshold"
          timeOfDay={formattedReservationPolicy.cancelableThreshold.timeOfDay}
          gracePeriodInMinutes={formattedReservationPolicy.cancelableThreshold.gracePeriodInMinutes}
          asDays={true}
          isPrior={true}
          gracePeriodDetailText="plus a period of"
        />
      </Grid>
    </Grid>
  );
};
