import React, { useEffect, useMemo } from 'react';
import { roomsQuery, roomsService } from '../../state';
import { useRoomType, useObservable } from '../utils';

interface Props {
  propertyId: string;
  roomTypeId: string;
  roomId?: string;
}

export const RoomDisplay: React.FC<Props> = props => {
  const { roomId } = props;
  if (roomId) {
    return <RoomNumberDisplay {...props} roomId={roomId} />;
  } else {
    return <RoomTypeOnlyDisplay {...props} />;
  }
};

const RoomTypeOnlyDisplay: React.FC<Omit<Props, 'roomId'>> = ({ roomTypeId }) => {
  const roomType = useRoomType(roomTypeId);

  if (!roomType) return null;

  return <>{roomType?.name}</>;
};

const RoomNumberDisplay: React.FC<Required<Props>> = ({ propertyId, roomId }) => {
  useEffect(() => {
    roomsService.getRoomNumber(propertyId, roomId);
  }, [propertyId, roomId]);

  const query = useMemo(() => roomsQuery.selectEntity(roomId), [roomId]);
  const room = useObservable(query);

  if (!room) return null;

  return (
    <>
      {room.roomType.name} / {room.roomNumber}
    </>
  );
};
