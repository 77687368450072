import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

import { FormStatus } from '@lib/state';
import { useFormEvents, WebOnly } from '@lib/common';
import { JournalEntriesForms } from 'app/state';
import { DownloadButton, FilterSection, formatDateMidnight } from 'app/shared';
import { LedgerUploadForm, LedgerUploadFormFields } from './ledger-upload.form';

interface LedgerHeaderProps {
  startDate?: string | null;
  endDate?: string | null;
  reference?: string;
  invoice?: string;
  recordNumber?: string;
  recordNumberRange?: string;
  email?: string;
  setStartDate: (date: string | null) => void;
  setEndDate: (date: string | null) => void;
  setReference: (reference: string) => void;
  setInvoice: (invoice: string) => void;
  setRecordNumber: (recordNumber: string) => void;
  setRecordNumberRange: (recordNumber: string) => void;
  setEmail: (email: string) => void;
  uploadFile: ({ file }: LedgerUploadFormFields) => void;
  exportLedger: () => void;
}

export const LedgerHeader: React.FC<LedgerHeaderProps> = ({
  startDate,
  endDate,
  reference,
  invoice,
  recordNumber,
  recordNumberRange,
  email,
  setStartDate,
  setEndDate,
  setReference,
  setInvoice,
  setRecordNumber,
  setRecordNumberRange,
  setEmail,
  uploadFile,
  exportLedger,
}) => {
  const [{ status, error }, resetFormUI] = useFormEvents(JournalEntriesForms.UploadLedgerData);

  return (
    <FilterSection>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3} lg={1}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={date => {
              setStartDate(!!date ? formatDateMidnight(date) : null);
            }}
            format="MM/dd/yyyy"
            autoOk
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3} lg={1}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={date => {
              setEndDate(!!date ? formatDateMidnight(date) : null);
            }}
            format="MM/dd/yyyy"
            autoOk
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <TextField
            label="Reference"
            value={reference}
            onChange={x => {
              setReference(x.target.value);
            }}
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <TextField
            label="Invoice"
            value={invoice}
            onChange={x => {
              setInvoice(x.target.value);
            }}
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <TextField
            label={recordNumberRange ? 'Res # From' : 'Reservation #'}
            value={recordNumber}
            onChange={x => {
              setRecordNumber(x.target.value);
            }}
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <TextField
            label={'Res # To'}
            value={recordNumberRange}
            onChange={x => {
              setRecordNumberRange(x.target.value);
            }}
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <TextField
            label="User Email"
            value={email}
            onChange={x => {
              setEmail(x.target.value);
            }}
            fullWidth
          />
        </Grid>
        <WebOnly>
          <Grid container item xs={12} spacing={1} justify="flex-end">
            <Grid item>
              <LedgerUploadForm
                pending={status === FormStatus.Pending}
                success={status === FormStatus.Success}
                error={error}
                onSubmit={uploadFile}
                onResetFeedback={resetFormUI}
              />
            </Grid>
            <Grid item>
              <DownloadButton onDownload={exportLedger} />
            </Grid>
          </Grid>
        </WebOnly>
      </Grid>
    </FilterSection>
  );
};
