import React, { useMemo } from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { Fee } from '@lib/state';
import { LineItemDisplay } from '../components';

interface Props {
  charges?: Fee[];
  refunds?: Fee[];
  paymentView?: React.ReactChild;
}

export const ReservationFeeList: React.FC<Props> = ({
  charges = [],
  refunds = [],
  paymentView,
}) => {
  const total = useMemo(() => {
    const totalCharges = charges.reduce((sum, x) => sum + x.total, 0);
    return {
      totalCharges,
      grandTotal: totalCharges + refunds.reduce((sum, x) => sum + x.total, 0),
    };
  }, [charges, refunds]);

  if (!charges.length && !refunds.length) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {refunds.map((refund, i) => (
          <React.Fragment key={i}>
            <LineItemDisplay variant="body2" label={refund.description} amount={refund.total} />
            <Box my={0.5}>
              <Divider />
            </Box>
          </React.Fragment>
        ))}

        {charges.map((charge, i) => (
          <React.Fragment key={i}>
            <LineItemDisplay variant="body2" label={charge.description} amount={charge.amount} />
            <LineItemDisplay variant="body2" label="Tax" amount={charge.tax.total} />

            <Box my={0.5}>
              <Divider />
            </Box>
          </React.Fragment>
        ))}

        <LineItemDisplay variant="subtitle1" label="Total" amount={total.grandTotal} />
      </Grid>

      {total.totalCharges > 0 && paymentView && (
        <Grid item xs={12}>
          {paymentView}
        </Grid>
      )}
    </Grid>
  );
};
