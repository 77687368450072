import React from 'react';
import { Grid } from '@material-ui/core';
import { ArmadilloLogoPng } from '@lib/common/assets/index';
import { makeStyles, createStyles } from '@material-ui/core';
import { Section, useObservable, useLookup, PrintContext } from '@lib/common';
import {
  Reservation,
  RefundReservationChargeModel,
  roomTypeQuery,
  propertyQuery,
} from '@lib/state';
import { JournalAccount } from 'app/state';
import { ReservationInfoGuestInfo } from './reservation-info-guest-info';
import { ReservationInfoSpecialRequests } from './reservation-info-special-requests';
import { ReservationRoomBreakdown } from './reservation-room-breakdown';
import { ReservationChargesTable } from './reservation-charges-table';
import { ReservationInfoMetaData } from './reservation-info-meta';

interface PrintableReservationReceiptProps {
  reservation: Reservation;
  charges: Reservation['charges'];
  accounts: JournalAccount[];
  refundCharge: (model: RefundReservationChargeModel) => void;
}

const useStyles = makeStyles(theme =>
  createStyles({
    header: {
      marginBottom: '1rem',
      textAlign: 'center',
      backgroundColor: theme.palette.common.white,
    },
    screen: {
      marginRight: '2em',
      marginLeft: '2em',
    },
    dense: {
      lineHeight: 1,
    },
    image: {},
  })
);

export const PrintableReservationReceipt: React.FC<PrintableReservationReceiptProps> = ({
  reservation,
  accounts,
  refundCharge,
  charges,
}) => {
  const styles = useStyles();
  const roomTypes = useObservable(roomTypeQuery.roomTypes);
  const roomTypeLookup = useLookup(roomTypes, x => x.id);
  const properties = useObservable(propertyQuery.properties);
  const property = properties.find(p => p.id === reservation.propertyId);
  const titleInfo = () => {
    if (!property) {
      return '';
    }
    return `\n The Armadillo Hotel - ${property.name} \n ${property.location.city}, ${property.location.state}`;
  };

  return (
    <PrintContext>
      <div className={styles.screen}>
        <Section textAlign="left" title={`Reservation ${reservation.recordNumber} ${titleInfo()}`}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <img src={ArmadilloLogoPng} className={styles.image} alt="The Armadillo Hotel" />
            </Grid>
            <Grid item xs={9}>
              <ReservationInfoMetaData reservation={reservation} guestStays={[]} />
            </Grid>
          </Grid>
        </Section>
        <Section title="Guests">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ReservationInfoGuestInfo reservation={reservation} />
            </Grid>
          </Grid>
        </Section>
        <Section title="Details">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ReservationInfoSpecialRequests reservation={reservation} />
            </Grid>
          </Grid>
        </Section>
        <Section title="Rooms" disableGutters>
          {reservation.rooms.map((room, roomGroup) => (
            <ReservationRoomBreakdown
              reservation={reservation}
              accounts={accounts}
              charges={charges}
              refundCharge={refundCharge}
              key={room.id}
              room={room}
              fees={roomGroup === 0 ? reservation.fees : []}
              checkOutDate={reservation.checkOutDate}
              roomType={roomTypeLookup.get(room.roomTypeId)}
            />
          ))}
        </Section>
        <Section title="Charges">
          <ReservationChargesTable
            charges={reservation.charges}
            accounts={accounts}
            refundCharge={refundCharge}
          />
        </Section>
      </div>
    </PrintContext>
  );
};
