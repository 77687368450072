import React from 'react';
import { Grid } from '@material-ui/core';
import { Reservation } from '@lib/state';
import { KioskUserRevervation } from './kiosk-user-reservation';

interface Props {
  reservations: Reservation[];
}

export const KioskUserReservationsList: React.FC<Props> = ({ reservations }) => {
  return (
    <Grid container>
      {reservations.map(r => (
        <KioskUserRevervation key={r.id} reservation={r} />
      ))}
    </Grid>
  );
};
