import { Properties, Reservations } from './api';

// The definitions for Address, Name, and Contact are identical
// from both the Properties api and Reservations Api
export type Address = Properties.Address & Reservations.Address;
export type Name = Properties.Name & Reservations.Name;
export type Contact = Properties.Contact & Reservations.Contact;

export type TaxAmounts = Reservations.TaxAmounts;

export type TaxRates = Reservations.TaxRates;

export type Fee = Reservations.FeeModel;

export type CorporateAccount = Reservations.CorporateAccountModel;
export type CorporateAffiliation = Properties.CorporateAffiliation &
  Reservations.CorporateAffiliation;

export type CostCenterFieldValue = Reservations.CostCenterFieldValue;

export type CostCenterFieldStatus = Reservations.CostCenterFieldStatus;
export const CostCenterFieldStatus = Reservations.CostCenterFieldStatus;

export enum CostCenterFields {
  Crew = 'Crew',
  CostCenter1 = 'CostCenter1',
  CostCenter2 = 'CostCenter2',
  EmployeeNumber = 'EmployeeNumber',
  BillingReference = 'BillingReference',
}

export type SleepSchedule = Reservations.SleepSchedule;
export const SleepSchedule = Reservations.SleepSchedule;

export type ImportStrategy = Reservations.ImportStrategy;
export const ImportStrategy = Reservations.ImportStrategy;

export type HousekeepingPolicy = Reservations.HousekeepingPolicy;
export type ReservationPolicy = Reservations.ReservationPolicy;

export type PolicyForm = Reservations.ReservationPolicy | Reservations.HousekeepingPolicy;

export const Admin = 'Admin';
export const Accounting = 'Accounting';
export const Manager = 'Manager';
export const FrontDesk = 'FrontDesk';
export const Housekeeping = 'Housekeeping';
export const HousekeepingSupervisor = 'HousekeepingSupervisor';
export const Maintenance = 'Maintenance';

export const propertyRoles: string[] = [
  Admin,
  Accounting,
  Manager,
  FrontDesk,
  Housekeeping,
  HousekeepingSupervisor,
  Maintenance,
];

export interface PaginationState {
  isDone: boolean;
  continuationToken?: string | null;
}

export interface PaginatedQuery {
  continuationToken?: string | null;
  limit?: number;
}

export enum CommonForm {
  Export = 'Export',
}
