import React, { useEffect } from 'react';
import { Box, makeStyles, createStyles, Typography, Grid } from '@material-ui/core';
import { parseISO } from 'date-fns';
import { Contact, Reservation, roomTypeQuery, roomTypeService } from '@lib/state';
import {
  NameDisplay,
  EnumValueDisplay,
  DateDisplay,
  PhoneDisplay,
  Section,
  Link,
  ShowMoreButton,
  ButtonLink,
  CorporateAffiliationDisplay,
  useObservable,
  useLookup,
  DataTable,
  VipDisplay,
} from '@lib/common';
import { Tile, DownloadButton } from 'app/shared';
import { ReservationFilters, ReservationSearchFilter } from './reservation-search-filter';
import { CorporateAccount, RoomRatePlan } from 'app/state';

const useStyles = makeStyles(theme =>
  createStyles({
    downloadButton: {
      marginRight: theme.spacing(2),
    },
  })
);

interface Props {
  reservations: Array<Reservation>;
  propertyId: string;
  roomRatePlans: Array<RoomRatePlan>;
  corporateAccounts: Array<CorporateAccount>;
  filters: ReservationFilters;
  onChange: (filters: ReservationFilters) => void;
  onExport: (filters: ReservationFilters) => void;
  loadMore: () => void;
  isDone: boolean;
  userId?: string;
}

export const ReservationLookup: React.FC<Props> = ({
  reservations,
  propertyId,
  roomRatePlans,
  corporateAccounts,
  filters,
  onChange,
  onExport,
  loadMore,
  isDone,
  userId,
}) => {
  const styles = useStyles();

  useEffect(() => {
    if (propertyId) roomTypeService.getPropertyRoomTypes(propertyId);
  }, [propertyId]);

  const roomTypes = useObservable(roomTypeQuery.propertyRoomTypes);

  const roomTypeLookup = useLookup(roomTypes, x => x.id);

  const getEmail = (contact: Contact) => {
    return contact.email ?? contact.alternateEmail;
  };

  return (
    <Tile>
      <Box display="flex" justifyContent="flex-end" flexDirection="row">
        <DownloadButton onDownload={() => onExport(filters)} className={styles.downloadButton} />
        <ButtonLink
          to="/reservations/add"
          color="primary"
          variant="contained"
          data-testid="lookupCreateReservationButton"
        >
          Create Reservation
        </ButtonLink>
      </Box>
      <ReservationSearchFilter
        filters={filters}
        roomRatePlans={roomRatePlans}
        roomTypes={roomTypes}
        corporateAccounts={corporateAccounts}
        onChange={onChange}
        userId={userId}
      />

      {reservations.length > 0 && (
        <Section title="Results" textAlign="left" disableGutters>
          <DataTable
            items={reservations}
            getItemKey={res => res.id}
            children={<ShowMoreButton isDone={isDone} loadMore={loadMore} />}
            columns={[
              {
                title: 'Reservation',
                valueFactory: res => <Link to={`/reservations/${res.id}`}>{res.recordNumber}</Link>,
              },
              {
                title: 'Status',
                valueFactory: res => <EnumValueDisplay value={res.status} />,
              },
              {
                title: 'Company',
                valueFactory: res => <CorporateAffiliationDisplay {...res.affiliation} />,
                colSpan: 2,
              },
              {
                title: 'Guest Name',
                valueFactory: res => (
                  <Grid container>
                    <Grid item xs={11}>
                      <NameDisplay
                        {...(res.contacts.length > 0 ? res.contacts[0].name : '')}
                        directory
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {res.contacts.length > 0 ? (
                        <VipDisplay
                          vipStatus={res.contacts[0].vipStatus}
                          key={res.contacts[0].userId}
                        />
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                ),
                colSpan: 2,
              },
              {
                title: 'Guest Email',
                valueFactory: res => (
                  <Typography>
                    {res.contacts.length > 0 ? getEmail(res.contacts[0].contact) : ''}
                  </Typography>
                ),
                colSpan: 2,
              },
              {
                title: 'Guest Phone',
                valueFactory: res => (
                  <PhoneDisplay
                    phone={res.contacts.length > 0 ? res.contacts[0].contact.phone : ''}
                  />
                ),
                colSpan: 2,
              },
              {
                title: 'Check In',
                valueFactory: res => (
                  <DateDisplay date={parseISO(res.checkInDate)} dateFormat="M/d/yyyy" />
                ),
                colSpan: 2,
              },
              {
                title: 'Check Out',
                valueFactory: res => (
                  <DateDisplay date={parseISO(res.checkOutDate)} dateFormat="M/d/yyyy" />
                ),
                colSpan: 2,
              },
              {
                title: 'Created At',
                valueFactory: res => (
                  <DateDisplay date={parseISO(res.createdTime)} dateFormat="M/d/yyyy h:mm a" />
                ),
                colSpan: 2,
              },
              {
                title: 'Room Type',
                valueFactory: res => (
                  <Typography>
                    {res.rooms.length > 0 ? roomTypeLookup.get(res.rooms[0].roomTypeId)?.name : ''}
                  </Typography>
                ),
                colSpan: 2,
              },
            ]}
          />
        </Section>
      )}
    </Tile>
  );
};
