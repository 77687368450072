import { EnumValueDisplay, FormHub, FormSelect, FormTextField, SubmitButton } from '@lib/common';
import { FormStatus, QRCode, QRCodeFormFeilds, QRCodeStatus, QRCodeType, Room } from '@lib/state';
import { Grid, MenuItem } from '@material-ui/core';
import React from 'react';

interface Props {
  qrCode?: QRCode;
  propertyId: string;
  rooms: Array<Room>;
  formStatus: FormStatus;
  error?: Error;
  onSubmit: (code: QRCodeFormFeilds) => void;
}

export function QRCodeForm({ qrCode, propertyId, rooms, onSubmit, formStatus, error }: Props) {
  const defaultValues = {
    ...qrCode,
    status: qrCode?.status ?? QRCodeStatus.Active,
    propertyId,
  };

  return (
    <FormHub onSubmit={onSubmit} formOptions={{ defaultValues }}>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <FormTextField label="Name" name="name" required defaultValue={qrCode?.name} fullWidth />
        </Grid>
        <Grid item sm={12}>
          <FormTextField
            label="Location"
            name="location"
            defaultValue={qrCode?.location}
            fullWidth
            required
          />
        </Grid>
        <Grid item sm={12}>
          <FormSelect
            name="roomId"
            label="Room"
            fullWidth
            data-testid="qrCodeRoomIdField"
            defaultValue={rooms.find(r => r.roomNumber === qrCode?.roomNumber)?.id}
          >
            <MenuItem value={undefined}>None</MenuItem>
            {rooms.map(m => (
              <MenuItem key={m.id} value={m.id}>
                {m.roomNumber}
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <Grid item sm={12}>
          <FormSelect
            name="status"
            label="Status"
            defaultValue={qrCode?.status}
            fullWidth
            disabled={!qrCode?.id}
            data-testid="qrCodeStatusField"
          >
            {Object.values(QRCodeStatus).map(ss => (
              <MenuItem key={ss} value={ss}>
                <EnumValueDisplay value={ss} />
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <Grid item sm={12}>
          <FormSelect
            name="type"
            label="Type"
            defaultValue={qrCode?.type ?? QRCodeType.MaintenanceTicket}
            fullWidth
            disabled={!!qrCode?.id}
            data-testid="qrCodeTypeField"
            required
          >
            <MenuItem value={undefined}>None</MenuItem>
            {Object.values(QRCodeType).map(ss => (
              <MenuItem key={ss} value={ss}>
                <EnumValueDisplay value={ss} />
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <FormTextField name="id" defaultValue={qrCode?.id} style={{ visibility: 'hidden' }} />
        <FormTextField
          name="propertyId"
          defaultValue={propertyId}
          style={{ visibility: 'hidden' }}
        />
        <Grid item sm={12}>
          <SubmitButton
            variant="contained"
            color="secondary"
            disabled={formStatus === 'pending'}
            pending={formStatus === 'pending'}
            style={{ margin: '0 auto', display: 'flex', width: '50%' }}
          >
            Submit
          </SubmitButton>
        </Grid>
      </Grid>
    </FormHub>
  );
}
