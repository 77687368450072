import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { FormStatus } from '@lib/state';
import { kioskStyles } from '../../guest-kiosk.theme';
import { FormNumberField, FormSubmitButton } from '../../forms';

interface Props {
  onResendCode: () => void;
}

export const VerifyReservationCodeForm: React.FC<Props> = ({ onResendCode }) => {
  const { kioskTextButton, kioskSecondaryTextButton } = kioskStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormNumberField
          name="code"
          label="Enter Verification Code"
          validationOptions={{
            validate: x => (isNaN(parseInt(x)) ? 'Invalid Code' : undefined),
          }}
          helperText={errors => errors?.['code']?.message}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormSubmitButton
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          className={kioskTextButton}
        >
          Verify
        </FormSubmitButton>
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={onResendCode}
          color="primary"
          disabled={status === FormStatus.Pending}
          className={kioskSecondaryTextButton}
          fullWidth
        >
          Resend Code
        </Button>
      </Grid>
    </Grid>
  );
};
