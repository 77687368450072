import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { PaginationState, Property } from '@lib/state';
import { Room } from './room-list.model';

export interface RoomListFilters {
  roomNumber?: string;
  roomTypeId?: string;
  roomZoneId?: string;
}

export interface RoomListUIState {
  propertyId?: Property['id'];
  filters: RoomListFilters;
}

export interface RoomListState extends EntityState<Room, Room['id']>, ActiveState<Room['id']> {
  ui: RoomListUIState;
  pagination: PaginationState;
}

@StoreConfig({ name: 'room-list', resettable: true, idKey: 'id' })
export class RoomListStore extends EntityStore<RoomListState> {
  constructor() {
    super({
      ui: {
        propertyId: undefined,
        filters: {},
      },
      pagination: {
        isDone: false,
        continuationToken: undefined,
      },
    });
  }
}

export const roomListStore = new RoomListStore();
