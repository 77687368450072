import { EntityStore, StoreConfig, EntityState } from '@datorama/akita';
import { NavMenuItemSection } from './nav-menu.model';

export interface NavMenuUIState {
  isNavDrawerOpen: boolean;
}

export interface NavMenuState extends EntityState<NavMenuItemSection, NavMenuItemSection['title']> {
  ui: NavMenuUIState;
}

@StoreConfig({ name: 'nav-menu', resettable: true, idKey: 'title' })
export class NavMenuStore extends EntityStore<NavMenuState> {
  constructor() {
    super({
      ui: {
        isNavDrawerOpen: false,
      },
    });
  }
}

export const navMenuStore = new NavMenuStore();
