import React from 'react';
import {
  FormTextField,
  FormSelect,
  EnumValueDisplay,
  NameDisplay,
  FormCheckbox,
  useHasRole,
} from '@lib/common';
import { Admin, Manager, Properties, Reservation } from '@lib/state';
import { Grid, MenuItem } from '@material-ui/core';
import { TicketStatus } from '../../state/maintenance/maintenance-ticket.model';
import { ReservationMaintenanceDetails } from 'app/reservations/components/reservation-maintenance-details';

interface Props {
  rooms: Properties.RoomModel[];
  employees: Properties.EmployeeModel[];
  ticket?: Properties.MaintenanceTicketModel;
  hasManagerRole: boolean;
  hasMaintenanceRole: boolean;
  setSelectedRoomId: (roomId?: string) => void;
  selectedRoomId?: string;
  setArea: (area?: string) => void;
  area?: string;
  selectedReservation?: Reservation;
}

export const MaintenanceTicketForm: React.FC<Props> = ({
  ticket,
  rooms,
  employees,
  hasManagerRole,
  hasMaintenanceRole,
  setSelectedRoomId,
  selectedRoomId,
  setArea,
  area,
  selectedReservation,
}) => {
  const canUpdateActive = useHasRole(Admin, Manager);

  const onRoomChange = (e: EventTarget) => {
    setSelectedRoomId((e as HTMLInputElement).value);
  };
  const onAreaChange = (e: EventTarget) => {
    setArea((e as HTMLInputElement).value);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormSelect
            name="roomId"
            label="Room"
            fullWidth
            required={!area}
            onClick={e => {
              onRoomChange(e.target);
            }}
          >
            <MenuItem value={undefined}>None</MenuItem>
            {rooms.map(m => (
              <MenuItem key={m.id} value={m.id}>
                {m.roomNumber}
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            name="area"
            label="Area"
            fullWidth
            multiline={true}
            required={!selectedRoomId}
            onChange={e => {
              onAreaChange(e.target);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {!!selectedRoomId && (
            <ReservationMaintenanceDetails
              reservation={selectedReservation}
            ></ReservationMaintenanceDetails>
          )}
        </Grid>
        {ticket?.id && (
          <Grid item xs={12} md={6}>
            <FormSelect name="status" label="Status" fullWidth required>
              {Object.values(Properties.MaintenanceTicketStatusEnum).map(x => (
                <MenuItem
                  key={x}
                  value={x}
                  dense
                  disabled={x === TicketStatus.Closed && !hasManagerRole}
                >
                  <EnumValueDisplay value={x} disableTypography />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <FormSelect name="priority" label="Priority" fullWidth required>
            {Object.values(Properties.PriorityOptions).map(x => (
              <MenuItem key={x} value={x} dense>
                <EnumValueDisplay value={x} disableTypography />
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSelect
            name="assignedTo"
            label="Assigned To"
            fullWidth
            disabled={!(hasManagerRole || hasMaintenanceRole)}
          >
            <MenuItem value={''}>Unassigned</MenuItem>
            {employees.map(m => (
              <MenuItem key={m.userId} value={m.userId}>
                {<NameDisplay {...m.name} directory disableTypography />}
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        {ticket?.id && (
          <Grid container item xs={12} md={6} justifyContent="flex-end">
            <Grid item xs={1}>
              <FormCheckbox
                name="isActive"
                label="Active"
                labelPlacement="top"
                defaultChecked={ticket?.isActive}
                disabled={canUpdateActive === false}
              />
            </Grid>
          </Grid>
        )}
        {!ticket?.id && (
          <Grid item xs={12}>
            <FormTextField
              name="description"
              label="Note"
              fullWidth
              minRows={3}
              multiline={true}
              required
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
