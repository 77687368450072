import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Typography } from '@material-ui/core';
import clsx from 'clsx';

import {
  BookingRequest,
  bookingService,
  FormStatus,
  isEmployeeBookingAllowed,
  propertyQuery,
  ReservationForms,
  reservationService,
  RoomRateForms,
  roomRateService,
  RoomType,
  roomTypeQuery,
  roomTypeService,
  sessionQuery,
} from '@lib/state';
import {
  FormHub,
  getAcceptCardData,
  LocationForm,
  LocationFormFields,
  PaymentDetailsForm,
  ReservationConfirmation,
  RoomRateForm,
  useBookingStatus,
  useCommonStyle,
  useFormEvents,
  useObservable,
  usePageTitle,
  useRoomRates,
  withPropertyFeatures,
} from '@lib/common';
import { BreadcrumbBackButton, isFrontDesk } from 'app/shared';
import { GuestLookupForm } from '../components';
import { SelectCorporateDelegateForm } from '../components/select-corporate-delegate.form';
import { GuestProfileModel } from '@lib/state/api/generated/reservations';
const CreateReservation: React.FC = () => {
  const commonStyles = useCommonStyle();
  usePageTitle('Add Reservation');

  const [roomType, setRoomType] = useState<RoomType | undefined>(undefined);
  const [delegateGuest, setDelegateGuest] = useState<GuestProfileModel | undefined>(undefined);
  const [readyForCheckAvailability, setReadyForCheckAvailability] = useState<boolean | undefined>(
    true
  );
  const [corporateAccountSelected, setCorporateAccountSelected] = useState<string | undefined>(
    undefined
  );
  const [delegateUserId, setDelegateUserId] = useState<string | undefined>(undefined);
  const [formEvent, resetReservationFormStatus] = useFormEvents(ReservationForms.CreateReservation);
  const [{ error: availabilityError, status: availabilityStatus }] = useFormEvents(
    RoomRateForms.Availability
  );

  const propertyId = useObservable(sessionQuery.propertyId);
  const properties = useObservable(propertyQuery.properties);
  const roomTypes = useObservable(roomTypeQuery.propertyRoomTypes);
  const bookingStatus = useBookingStatus();

  const [userId, setUserId] = useState<string | null>(null);
  const {
    uiState,
    roomRates,
    checkInStartOfDay,
    checkOutStartOfDay,
    checkInDefaultDate,
    corporateBooking,
    quantity,
    policy,
  } = useRoomRates(userId, corporateAccountSelected);

  useEffect(() => {
    //reset the reservation form status when the quantity is changed
    resetReservationFormStatus();
  }, [quantity, resetReservationFormStatus]);

  const [editLocation, setEditLocation] = useState(true);
  const [didSelectDates, setDidSelectDates] = useState(
    !!(uiState.checkInDate && uiState.checkOutDate)
  );

  const [requiresPayment, setRequiresPayment] = useState(true);

  const isManagerOverrideRequired = useMemo(
    () => !isEmployeeBookingAllowed(corporateBooking, checkOutStartOfDay),
    [corporateBooking, checkOutStartOfDay]
  );

  const corporateAccountId = useMemo(() => {
    return corporateBooking?.id;
  }, [corporateBooking]);

  useEffect(() => {
    if (availabilityStatus === FormStatus.Success && didSelectDates) {
      setEditLocation(false);
    }
  }, [availabilityStatus, didSelectDates]);

  useEffect(
    () => () => {
      reservationService.selectReservation();
      roomRateService.selectRoomRate();
      roomRateService.resetUI();
    },
    []
  );
  const delegateError = useMemo(() => {
    if (userId && delegateUserId !== userId && corporateBooking) {
      setEditLocation(true);
      setDidSelectDates(false);
      return {
        message:
          'To book a corporate reservation, please select the corporate account and delegate here.',
      } as Error;
    }
    return undefined;
  }, [delegateUserId, userId, corporateBooking]);

  const submitLocationForm = useCallback(
    (values: LocationFormFields) => {
      roomTypeService.getPropertyRoomTypes(values.propertyId);
      // set the userId first so that it is available for the room rate query
      setUserId(delegateGuest?.userId ?? null);
      roomRateService.updateUI({ ...values });
      setDidSelectDates(true);
      resetReservationFormStatus();
    },
    [resetReservationFormStatus, delegateGuest]
  );

  const selectRoomType = useCallback(
    (roomType: RoomType) => {
      setRoomType(roomType);
    },
    [setRoomType]
  );

  const submit = useCallback(
    ({ card, ...booking }: any) => {
      const request: BookingRequest = {
        card: getAcceptCardData(card),
        ...booking,
        corporateAccountId: corporateAccountId,
      };

      if (userId) request.contact.userId = userId;
      if (uiState.promoCode) request.promoCode = uiState.promoCode;

      bookingService.createReservationForGuest(request);
    },
    [uiState, userId, corporateAccountId]
  );

  return (
    <Container maxWidth="md">
      <BreadcrumbBackButton destination="/reservations" />
      <Typography
        variant="h4"
        color="textPrimary"
        align="center"
        paragraph
        className={clsx({ [commonStyles.hidden]: !editLocation })}
      >
        Select the dates for your stay.
      </Typography>
      {bookingStatus ? (
        <ReservationConfirmation
          reservations={bookingStatus.reservations ?? []}
          failures={bookingStatus.failures ?? []}
          consumedHolds={bookingStatus.consumedHolds}
          properties={properties}
          policy={policy}
        />
      ) : (
        <FormHub onSubmit={submit}>
          <SelectCorporateDelegateForm
            className={clsx({ [commonStyles.hidden]: !editLocation })}
            onSelectDelegateGuest={(isReadyForCheckAvailability, corporateAccountId, guest) => {
              setCorporateAccountSelected(corporateAccountId);
              setDelegateUserId(guest?.userId);
              setReadyForCheckAvailability(isReadyForCheckAvailability);
              setDelegateGuest(guest);
            }}
          />
          <LocationForm
            className={clsx({
              [commonStyles.hidden]: !editLocation,
            })}
            hideLocationForPropertyId
            itemSpacing={3}
            properties={properties}
            minCheckIn={checkInDefaultDate}
            defaultCheckIn={checkInStartOfDay}
            defaultCheckOut={checkOutStartOfDay}
            defaultPropertyId={propertyId}
            defaultCorporateAccountId={corporateAccountId}
            onStepComplete={submitLocationForm}
            status={availabilityStatus}
            error={availabilityError ?? delegateError}
            readyForCheckAvailability={readyForCheckAvailability}
          />
          {!editLocation && roomRates && quantity && (
            <>
              <RoomRateForm
                properties={properties}
                roomTypes={roomTypes}
                roomRates={roomRates}
                policy={policy}
                usePaper={false}
                onEditLocation={() => {
                  setEditLocation(true);
                  setDidSelectDates(false);
                }}
                setRequiresPayment={setRequiresPayment}
                variant="contained"
                status={availabilityStatus}
                quantity={quantity}
                selectRoomType={selectRoomType}
                availabilityError={availabilityError}
                multipleCorpAccts={true}
              />
              <GuestLookupForm
                variant="contained"
                setUserId={setUserId}
                quantity={quantity}
                corporateBooking={corporateBooking}
                guestCount={roomType?.adultOccupancy?.maxOccupancy ?? undefined}
                useRearCam={isFrontDesk()}
                delegateGuest={delegateGuest}
              />
              <PaymentDetailsForm
                {...formEvent}
                requiresPayment={requiresPayment}
                useGuestPaymentMethods={true}
                isManagerOverrideRequired={isManagerOverrideRequired}
                guestUserId={userId}
                variant="contained"
                error={formEvent?.error ?? availabilityError}
                corporateAccountId={corporateAccountId}
              />
            </>
          )}
        </FormHub>
      )}
    </Container>
  );
};

export const AddReservationPage = withPropertyFeatures(CreateReservation, sessionQuery.propertyId);
