import { useEffect } from 'react';
import { qrCodeService } from '@lib/state/qr-codes/qr-code.service';
import { qrCodeQuery } from '@lib/state/qr-codes/qr-code.query';
import { useObservable } from '@lib/common/utils';
import { QRCodeFilter } from '@lib/state/qr-codes/qr-code.model';

export function useQRCodes(filters: QRCodeFilter) {
  useEffect(() => {
    qrCodeService.getQRCodes({ ...filters });
  }, [filters]);

  return {
    qrCodes: useObservable(qrCodeQuery.searchResults),
    loading: useObservable(qrCodeQuery.loading),
  };
}

export function useQRCodePagination() {
  return useObservable(qrCodeQuery.pagination);
}
