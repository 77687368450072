import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';

import { CarouselControl } from './carousel-control';

const useStyles = makeStyles(
  createStyles({
    root: {
      position: 'relative',
    },
  })
);

interface Props {
  autoCycleTimeout?: number;
  className?: string;
}

export const Carousel: React.FC<Props> = ({ autoCycleTimeout, className, children }) => {
  const styles = useStyles();

  const count = React.Children.count(children);

  const [index, _setIndex] = useState(0);
  const setIndex = useCallback((value: number) => _setIndex(Math.abs(value % count)), [count]);

  const currentItem = useMemo(() => React.Children.toArray(children)[index], [children, index]);

  useEffect(() => {
    if (autoCycleTimeout) {
      const handle = setTimeout(() => setIndex(index + 1), autoCycleTimeout);
      return () => clearTimeout(handle);
    }
  }, [index]);

  return (
    <div className={clsx(styles.root, className)}>
      {currentItem}
      <CarouselControl index={index} itemCount={count} setIndex={setIndex} />
    </div>
  );
};
