// tslint:disable
/**
 * Point of Sale
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { ProblemDetails } from '../models';
import { PropertyConfigurationModel } from '../models';
import { UpdatePropertyConfigurationRequest } from '../models';
/**
 * PropertyConfigurationApi - axios parameter creator
 * @export
 */
export const PropertyConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyconfigurationPropertyIdGet(propertyId: string, options: any = {}): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyconfigurationPropertyIdGet.'
        );
      }
      const localVarPath = `/propertyconfiguration/{propertyId}`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {UpdatePropertyConfigurationRequest} [updatePropertyConfigurationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyconfigurationPropertyIdPut(
      propertyId: string,
      updatePropertyConfigurationRequest?: UpdatePropertyConfigurationRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyconfigurationPropertyIdPut.'
        );
      }
      const localVarPath = `/propertyconfiguration/{propertyId}`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updatePropertyConfigurationRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            updatePropertyConfigurationRequest !== undefined
              ? updatePropertyConfigurationRequest
              : {}
          )
        : updatePropertyConfigurationRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PropertyConfigurationApi - functional programming interface
 * @export
 */
export const PropertyConfigurationApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyconfigurationPropertyIdGet(
      propertyId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyConfigurationModel> {
      const localVarAxiosArgs = PropertyConfigurationApiAxiosParamCreator(
        configuration
      ).propertyconfigurationPropertyIdGet(propertyId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {UpdatePropertyConfigurationRequest} [updatePropertyConfigurationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyconfigurationPropertyIdPut(
      propertyId: string,
      updatePropertyConfigurationRequest?: UpdatePropertyConfigurationRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyConfigurationModel> {
      const localVarAxiosArgs = PropertyConfigurationApiAxiosParamCreator(
        configuration
      ).propertyconfigurationPropertyIdPut(propertyId, updatePropertyConfigurationRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PropertyConfigurationApi - factory interface
 * @export
 */
export const PropertyConfigurationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyconfigurationPropertyIdGet(
      propertyId: string,
      options?: any
    ): AxiosPromise<PropertyConfigurationModel> {
      return PropertyConfigurationApiFp(configuration).propertyconfigurationPropertyIdGet(
        propertyId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {UpdatePropertyConfigurationRequest} [updatePropertyConfigurationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyconfigurationPropertyIdPut(
      propertyId: string,
      updatePropertyConfigurationRequest?: UpdatePropertyConfigurationRequest,
      options?: any
    ): AxiosPromise<PropertyConfigurationModel> {
      return PropertyConfigurationApiFp(configuration).propertyconfigurationPropertyIdPut(
        propertyId,
        updatePropertyConfigurationRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * PropertyConfigurationApi - object-oriented interface
 * @export
 * @class PropertyConfigurationApi
 * @extends {BaseAPI}
 */
export class PropertyConfigurationApi extends BaseAPI {
  /**
   *
   * @param {string} propertyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyConfigurationApi
   */
  public propertyconfigurationPropertyIdGet(propertyId: string, options?: any) {
    return PropertyConfigurationApiFp(this.configuration).propertyconfigurationPropertyIdGet(
      propertyId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {UpdatePropertyConfigurationRequest} [updatePropertyConfigurationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyConfigurationApi
   */
  public propertyconfigurationPropertyIdPut(
    propertyId: string,
    updatePropertyConfigurationRequest?: UpdatePropertyConfigurationRequest,
    options?: any
  ) {
    return PropertyConfigurationApiFp(this.configuration).propertyconfigurationPropertyIdPut(
      propertyId,
      updatePropertyConfigurationRequest,
      options
    )(this.axios, this.basePath);
  }
}
