import React from 'react';
import { Redirect } from 'react-router-dom';
import { Container } from '@material-ui/core';

import { sessionQuery } from '@lib/state';
import { useFormEvents, useObservable, usePageTitle } from '@lib/common';
import { CorporateAccountForms, corporateAccountQuery, corporateAccountService } from 'app/state';
import { BreadcrumbBackButton } from 'app/shared';
import { CorporateAccountForm, CorporateAccountFormFields } from '../components';

export const CreateCorporateAccountPage: React.FC = () => {
  usePageTitle('New Corporate Account');

  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const account = useObservable(corporateAccountQuery.activeAccount, 'async');

  const [{ status, error }, reset] = useFormEvents(CorporateAccountForms.Create);

  if (account != null) return <Redirect to={`/corporate-accounts/${account.id}`} />;

  if (!propertyId) return null;

  const onSubmit = (values: CorporateAccountFormFields) =>
    corporateAccountService.createCorporateAccount(
      propertyId,
      values.name,
      values.invoiceSubAccount ?? undefined
    );

  return (
    <Container maxWidth="lg">
      <BreadcrumbBackButton destination="/corporate-accounts" />
      <CorporateAccountForm
        account={account}
        status={status}
        error={error}
        onSubmit={onSubmit}
        resetFormUI={reset}
      />
    </Container>
  );
};
