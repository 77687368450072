import { createStyles, makeStyles } from '@material-ui/core';

export const useCommonStyle = makeStyles(theme =>
  createStyles({
    paper: {
      border: `2px solid ${theme.palette.primary.dark}`,
      padding: '0.5rem',
    },
    notPaper: {
      backgroundColor: 'transparent',
      boxShadow: 'none !important',
    },
    hidden: {
      display: 'none',
    },
    dense: {
      lineHeight: 1.1,
    },
    clickable: {
      cursor: 'pointer',
    },
  })
);
