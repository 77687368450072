import React, { useMemo, memo } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { formatName, formatNameDirectory } from '@lib/state/utils';

type Props = Omit<TypographyProps, 'prefix'> & {
  first?: string | null;
  middle?: string | null;
  last?: string | null;
  directory?: boolean;
  abbreviated?: boolean;

  prefix?: React.ReactNode;
  disableTypography?: boolean;
};

export const NameDisplay: React.FC<Props> = memo(
  ({
    first,
    middle,
    last,
    directory = false,
    abbreviated = false,
    disableTypography = false,
    prefix,
    children,
    ...props
  }) => {
    const name = useMemo(() => {
      const firstName = first && abbreviated ? `${first.slice(0, 1)}.` : first;
      if (directory) return formatNameDirectory(firstName, middle, last);

      return formatName(firstName, middle, last);
    }, [first, middle, last, directory, abbreviated]);

    return disableTypography ? (
      <>{name}</>
    ) : (
      <Typography {...props} data-testid="nameDisplay">
        {prefix}
        {name}
        {children}
      </Typography>
    );
  }
);
