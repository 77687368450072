import { Audit } from '@lib/state';

export type AuditLog = Audit.AuditLog & Pick<Audit.AuditQueryResponse, 'log' | 'key' | 'scope'>;
const HasImageKey: string = 'HasImageKey';

export function mapAuditLogResponses(
  data?: Audit.AuditQueryResponse[]
): Array<AuditLog> | undefined {
  return !!data ? data.flatMap(x => x.data.map(y => ({ ...y } as AuditLog))) : undefined;
}

export enum SecurityImageStatus {
  Pending = 'Pending',
  Loaded = 'Loaded',
  NotFound = 'NotFound',
}

export function showImageIcon(log: AuditLog): boolean {
  return log.type === 'DoorAccessAuditEvent' && log.features.some(s => s === HasImageKey);
}

export interface SecurityImage {
  url: string | undefined;
  status: SecurityImageStatus;
}
