// tslint:disable
/**
 * Property Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CreateRoomRequest } from '../models';
import { CreateRoomsRequest } from '../models';
import { GetRoomListResponse } from '../models';
import { GetRoomManagePaginatedListResponse } from '../models';
import { GetRoomResponse } from '../models';
import { UpdateRoomHousekeepingStatusRequest } from '../models';
/**
 * RoomsApi - axios parameter creator
 * @export
 */
export const RoomsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {string} [roomNumber]
     * @param {string} [roomTypeId]
     * @param {string} [roomZoneId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsGet(
      propertyId: string,
      continuationToken?: string,
      limit?: number,
      roomNumber?: string,
      roomTypeId?: string,
      roomZoneId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdRoomsGet.'
        );
      }
      const localVarPath = `/{propertyId}/rooms`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (continuationToken !== undefined) {
        localVarQueryParameter['ContinuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['Limit'] = limit;
      }

      if (roomNumber !== undefined) {
        localVarQueryParameter['RoomNumber'] = roomNumber;
      }

      if (roomTypeId !== undefined) {
        localVarQueryParameter['RoomTypeId'] = roomTypeId;
      }

      if (roomZoneId !== undefined) {
        localVarQueryParameter['RoomZoneId'] = roomZoneId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {CreateRoomsRequest} [createRoomsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsPost(
      propertyId: string,
      createRoomsRequest?: CreateRoomsRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdRoomsPost.'
        );
      }
      const localVarPath = `/{propertyId}/rooms`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createRoomsRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createRoomsRequest !== undefined ? createRoomsRequest : {})
        : createRoomsRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsRoomIdDelete(
      propertyId: string,
      roomId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdRoomsRoomIdDelete.'
        );
      }
      // verify required parameter 'roomId' is not null or undefined
      if (roomId === null || roomId === undefined) {
        throw new RequiredError(
          'roomId',
          'Required parameter roomId was null or undefined when calling propertyIdRoomsRoomIdDelete.'
        );
      }
      const localVarPath = `/{propertyId}/rooms/{roomId}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'roomId'}}`, encodeURIComponent(String(roomId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsRoomIdDeleteValidatePost(
      propertyId: string,
      roomId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdRoomsRoomIdDeleteValidatePost.'
        );
      }
      // verify required parameter 'roomId' is not null or undefined
      if (roomId === null || roomId === undefined) {
        throw new RequiredError(
          'roomId',
          'Required parameter roomId was null or undefined when calling propertyIdRoomsRoomIdDeleteValidatePost.'
        );
      }
      const localVarPath = `/{propertyId}/rooms/{roomId}/delete/validate`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'roomId'}}`, encodeURIComponent(String(roomId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsRoomIdGet(propertyId: string, roomId: string, options: any = {}): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdRoomsRoomIdGet.'
        );
      }
      // verify required parameter 'roomId' is not null or undefined
      if (roomId === null || roomId === undefined) {
        throw new RequiredError(
          'roomId',
          'Required parameter roomId was null or undefined when calling propertyIdRoomsRoomIdGet.'
        );
      }
      const localVarPath = `/{propertyId}/rooms/{roomId}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'roomId'}}`, encodeURIComponent(String(roomId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomId
     * @param {UpdateRoomHousekeepingStatusRequest} [updateRoomHousekeepingStatusRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsRoomIdHousekeepingPost(
      propertyId: string,
      roomId: string,
      updateRoomHousekeepingStatusRequest?: UpdateRoomHousekeepingStatusRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdRoomsRoomIdHousekeepingPost.'
        );
      }
      // verify required parameter 'roomId' is not null or undefined
      if (roomId === null || roomId === undefined) {
        throw new RequiredError(
          'roomId',
          'Required parameter roomId was null or undefined when calling propertyIdRoomsRoomIdHousekeepingPost.'
        );
      }
      const localVarPath = `/{propertyId}/rooms/{roomId}/housekeeping`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'roomId'}}`, encodeURIComponent(String(roomId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateRoomHousekeepingStatusRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            updateRoomHousekeepingStatusRequest !== undefined
              ? updateRoomHousekeepingStatusRequest
              : {}
          )
        : updateRoomHousekeepingStatusRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomId
     * @param {string} propertyId
     * @param {CreateRoomRequest} [createRoomRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsRoomIdPut(
      roomId: string,
      propertyId: string,
      createRoomRequest?: CreateRoomRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'roomId' is not null or undefined
      if (roomId === null || roomId === undefined) {
        throw new RequiredError(
          'roomId',
          'Required parameter roomId was null or undefined when calling propertyIdRoomsRoomIdPut.'
        );
      }
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdRoomsRoomIdPut.'
        );
      }
      const localVarPath = `/{propertyId}/rooms/{roomId}`
        .replace(`{${'roomId'}}`, encodeURIComponent(String(roomId)))
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createRoomRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createRoomRequest !== undefined ? createRoomRequest : {})
        : createRoomRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RoomsApi - functional programming interface
 * @export
 */
export const RoomsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {string} [roomNumber]
     * @param {string} [roomTypeId]
     * @param {string} [roomZoneId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsGet(
      propertyId: string,
      continuationToken?: string,
      limit?: number,
      roomNumber?: string,
      roomTypeId?: string,
      roomZoneId?: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetRoomManagePaginatedListResponse> {
      const localVarAxiosArgs = RoomsApiAxiosParamCreator(configuration).propertyIdRoomsGet(
        propertyId,
        continuationToken,
        limit,
        roomNumber,
        roomTypeId,
        roomZoneId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {CreateRoomsRequest} [createRoomsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsPost(
      propertyId: string,
      createRoomsRequest?: CreateRoomsRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomListResponse> {
      const localVarAxiosArgs = RoomsApiAxiosParamCreator(configuration).propertyIdRoomsPost(
        propertyId,
        createRoomsRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsRoomIdDelete(
      propertyId: string,
      roomId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = RoomsApiAxiosParamCreator(
        configuration
      ).propertyIdRoomsRoomIdDelete(propertyId, roomId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsRoomIdDeleteValidatePost(
      propertyId: string,
      roomId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = RoomsApiAxiosParamCreator(
        configuration
      ).propertyIdRoomsRoomIdDeleteValidatePost(propertyId, roomId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsRoomIdGet(
      propertyId: string,
      roomId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomResponse> {
      const localVarAxiosArgs = RoomsApiAxiosParamCreator(configuration).propertyIdRoomsRoomIdGet(
        propertyId,
        roomId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomId
     * @param {UpdateRoomHousekeepingStatusRequest} [updateRoomHousekeepingStatusRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsRoomIdHousekeepingPost(
      propertyId: string,
      roomId: string,
      updateRoomHousekeepingStatusRequest?: UpdateRoomHousekeepingStatusRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomResponse> {
      const localVarAxiosArgs = RoomsApiAxiosParamCreator(
        configuration
      ).propertyIdRoomsRoomIdHousekeepingPost(
        propertyId,
        roomId,
        updateRoomHousekeepingStatusRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} roomId
     * @param {string} propertyId
     * @param {CreateRoomRequest} [createRoomRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsRoomIdPut(
      roomId: string,
      propertyId: string,
      createRoomRequest?: CreateRoomRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomResponse> {
      const localVarAxiosArgs = RoomsApiAxiosParamCreator(configuration).propertyIdRoomsRoomIdPut(
        roomId,
        propertyId,
        createRoomRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * RoomsApi - factory interface
 * @export
 */
export const RoomsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {string} [roomNumber]
     * @param {string} [roomTypeId]
     * @param {string} [roomZoneId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsGet(
      propertyId: string,
      continuationToken?: string,
      limit?: number,
      roomNumber?: string,
      roomTypeId?: string,
      roomZoneId?: string,
      options?: any
    ): AxiosPromise<GetRoomManagePaginatedListResponse> {
      return RoomsApiFp(configuration).propertyIdRoomsGet(
        propertyId,
        continuationToken,
        limit,
        roomNumber,
        roomTypeId,
        roomZoneId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {CreateRoomsRequest} [createRoomsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsPost(
      propertyId: string,
      createRoomsRequest?: CreateRoomsRequest,
      options?: any
    ): AxiosPromise<GetRoomListResponse> {
      return RoomsApiFp(configuration).propertyIdRoomsPost(
        propertyId,
        createRoomsRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsRoomIdDelete(
      propertyId: string,
      roomId: string,
      options?: any
    ): AxiosPromise<void> {
      return RoomsApiFp(configuration).propertyIdRoomsRoomIdDelete(
        propertyId,
        roomId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsRoomIdDeleteValidatePost(
      propertyId: string,
      roomId: string,
      options?: any
    ): AxiosPromise<void> {
      return RoomsApiFp(configuration).propertyIdRoomsRoomIdDeleteValidatePost(
        propertyId,
        roomId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsRoomIdGet(
      propertyId: string,
      roomId: string,
      options?: any
    ): AxiosPromise<GetRoomResponse> {
      return RoomsApiFp(configuration).propertyIdRoomsRoomIdGet(
        propertyId,
        roomId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomId
     * @param {UpdateRoomHousekeepingStatusRequest} [updateRoomHousekeepingStatusRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsRoomIdHousekeepingPost(
      propertyId: string,
      roomId: string,
      updateRoomHousekeepingStatusRequest?: UpdateRoomHousekeepingStatusRequest,
      options?: any
    ): AxiosPromise<GetRoomResponse> {
      return RoomsApiFp(configuration).propertyIdRoomsRoomIdHousekeepingPost(
        propertyId,
        roomId,
        updateRoomHousekeepingStatusRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} roomId
     * @param {string} propertyId
     * @param {CreateRoomRequest} [createRoomRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomsRoomIdPut(
      roomId: string,
      propertyId: string,
      createRoomRequest?: CreateRoomRequest,
      options?: any
    ): AxiosPromise<GetRoomResponse> {
      return RoomsApiFp(configuration).propertyIdRoomsRoomIdPut(
        roomId,
        propertyId,
        createRoomRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * RoomsApi - object-oriented interface
 * @export
 * @class RoomsApi
 * @extends {BaseAPI}
 */
export class RoomsApi extends BaseAPI {
  /**
   *
   * @param {string} propertyId
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {string} [roomNumber]
   * @param {string} [roomTypeId]
   * @param {string} [roomZoneId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomsApi
   */
  public propertyIdRoomsGet(
    propertyId: string,
    continuationToken?: string,
    limit?: number,
    roomNumber?: string,
    roomTypeId?: string,
    roomZoneId?: string,
    options?: any
  ) {
    return RoomsApiFp(this.configuration).propertyIdRoomsGet(
      propertyId,
      continuationToken,
      limit,
      roomNumber,
      roomTypeId,
      roomZoneId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {CreateRoomsRequest} [createRoomsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomsApi
   */
  public propertyIdRoomsPost(
    propertyId: string,
    createRoomsRequest?: CreateRoomsRequest,
    options?: any
  ) {
    return RoomsApiFp(this.configuration).propertyIdRoomsPost(
      propertyId,
      createRoomsRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} roomId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomsApi
   */
  public propertyIdRoomsRoomIdDelete(propertyId: string, roomId: string, options?: any) {
    return RoomsApiFp(this.configuration).propertyIdRoomsRoomIdDelete(
      propertyId,
      roomId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} roomId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomsApi
   */
  public propertyIdRoomsRoomIdDeleteValidatePost(
    propertyId: string,
    roomId: string,
    options?: any
  ) {
    return RoomsApiFp(this.configuration).propertyIdRoomsRoomIdDeleteValidatePost(
      propertyId,
      roomId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} roomId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomsApi
   */
  public propertyIdRoomsRoomIdGet(propertyId: string, roomId: string, options?: any) {
    return RoomsApiFp(this.configuration).propertyIdRoomsRoomIdGet(
      propertyId,
      roomId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} roomId
   * @param {UpdateRoomHousekeepingStatusRequest} [updateRoomHousekeepingStatusRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomsApi
   */
  public propertyIdRoomsRoomIdHousekeepingPost(
    propertyId: string,
    roomId: string,
    updateRoomHousekeepingStatusRequest?: UpdateRoomHousekeepingStatusRequest,
    options?: any
  ) {
    return RoomsApiFp(this.configuration).propertyIdRoomsRoomIdHousekeepingPost(
      propertyId,
      roomId,
      updateRoomHousekeepingStatusRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} roomId
   * @param {string} propertyId
   * @param {CreateRoomRequest} [createRoomRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomsApi
   */
  public propertyIdRoomsRoomIdPut(
    roomId: string,
    propertyId: string,
    createRoomRequest?: CreateRoomRequest,
    options?: any
  ) {
    return RoomsApiFp(this.configuration).propertyIdRoomsRoomIdPut(
      roomId,
      propertyId,
      createRoomRequest,
      options
    )(this.axios, this.basePath);
  }
}
