import useScript from 'react-script-hook';
import { AppConfig } from '@lib/state';

export function useAcceptJs() {
  const authNetScript =
    AppConfig.AuthorizeNet.Env === 'production'
      ? 'https://js.authorize.net/v1/Accept.js'
      : 'https://jstest.authorize.net/v1/Accept.js';

  return useScript({ src: authNetScript, checkForExisting: true });
}
