import { StoreConfig, EntityStore, EntityState, ActiveState } from '@datorama/akita';

import { FailedReservation } from '../booking';
import { Property } from '../property';
import { PaginationState } from '../models.common';
import { GuestProfile } from '../guest-profile';
import {
  Reservation,
  ReservationActivity,
  ReservationStatus,
  UpdateCost,
  ClaimRequest,
  ConsumedHolds,
  VipStatus,
} from './reservation.model';
import { AuthorizedUserType } from '../api/generated/reservations';

export interface ReservationConfig {
  nextDayTime: string;
}

export interface ReservationUIState {
  propertyId?: Property['id'];
  userId?: GuestProfile['userId'];
  start?: Date;
  end?: Date;
  recordNumber?: number;
  recordNumberRange?: number;
  firstName?: string;
  lastName?: string;
  crew?: string;
  email?: string;
  phone?: string;
  status?: ReservationStatus;
  corporateAccountName?: string;
  corporateAccountId?: string;
  planName?: string;
  isFilterByZeroCharges?: boolean;
  bookedBy?: BookedByUserType;
  roomTypeId?: string;
  vipStatus?: VipStatus;
}
export interface ReservationBookingState {
  failures?: FailedReservation[];
  reservations?: Reservation[];
  consumedHolds?: ConsumedHolds;
}

export enum BookedByUserType {
  You = 'You',
  Guest = 'Guest',
  Delegate = 'Delegate',
  Staff = 'Staff',
}

export function authUserTypeFromBookedBy(bookedBy?: BookedByUserType) {
  if (bookedBy == BookedByUserType.Delegate) return AuthorizedUserType.Delegate;
  if (bookedBy == BookedByUserType.Guest) return AuthorizedUserType.Guest;
  if (bookedBy == BookedByUserType.Staff) return AuthorizedUserType.Staff;
  else return undefined;
}

export interface ReservationState
  extends EntityState<Reservation, Reservation['id']>,
    ActiveState<Reservation['id']> {
  ui: ReservationUIState;
  pagination: PaginationState;
  current: PaginationState;
  future: PaginationState;
  config: ReservationConfig;
  verificationCode: number | null;
  verifiedGuests: ClaimRequest | null;
  lastActivity: { activity: ReservationActivity; cost: UpdateCost } | null;
  bookingStatus: ReservationBookingState;
}

@StoreConfig({ name: 'reservation', resettable: true })
export class ReservationStore extends EntityStore<ReservationState> {
  constructor() {
    super({
      ui: {
        firstName: '',
        lastName: '',
        crew: '',
        email: '',
        phone: '',
        recordNumber: undefined,
        recordNumberRange: undefined,
        bookedBy: undefined,
        userId: undefined,
        roomTypeId: undefined,
      },
      pagination: {
        isDone: true,
        continuationToken: undefined,
      },
      current: {
        isDone: true,
        continuationToken: undefined,
      },
      future: {
        isDone: true,
        continuationToken: undefined,
      },
      config: {
        nextDayTime: '07:00:00',
      },
      active: null,
      verifiedGuests: null,
      policy: null,
      loading: false,
    });
  }
}

export const reservationStore = new ReservationStore();
