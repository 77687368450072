import React from 'react';
import { ButtonProps, Button } from '@material-ui/core';
import { AccountForms, FormStatus, Reservation, accountService } from '@lib/state';
import { ErrorDisplay, Feedback, useFormEvents } from '@lib/common';

interface Props extends Omit<ButtonProps, 'action' | 'onClick'> {
  reservation?: Reservation;
}

export const ResendUserKeyButton: React.FC<Props> = ({ reservation, ...props }) => {
  const [{ status, error }, reset] = useFormEvents(AccountForms.RegenerateUserKey);

  const regenerateKey = async () => {
    if (reservation?.userId) accountService.resendKey(reservation?.userId);
  };

  return (
    <>
      <ErrorDisplay error={error} />
      <Feedback severity="success" show={status === FormStatus.Success} onHide={reset} autoHide>
        A text message with the room key has been sent to the guest
      </Feedback>
      <Button variant="contained" color="secondary" onClick={regenerateKey} {...props}>
        Send Key via SMS
      </Button>
    </>
  );
};
