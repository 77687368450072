// tslint:disable
/**
 * Property Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { AreaItemResponse } from '../models';
import { AreaListResponse } from '../models';
import { CreateAreaRequest } from '../models';
import { UpdateAreaRequest } from '../models';
/**
 * AreasApi - axios parameter creator
 * @export
 */
export const AreasApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} areaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdAreasAreaIdDelete(
      propertyId: string,
      areaId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdAreasAreaIdDelete.'
        );
      }
      // verify required parameter 'areaId' is not null or undefined
      if (areaId === null || areaId === undefined) {
        throw new RequiredError(
          'areaId',
          'Required parameter areaId was null or undefined when calling propertyIdAreasAreaIdDelete.'
        );
      }
      const localVarPath = `/{propertyId}/areas/{areaId}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'areaId'}}`, encodeURIComponent(String(areaId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} areaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdAreasAreaIdGet(propertyId: string, areaId: string, options: any = {}): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdAreasAreaIdGet.'
        );
      }
      // verify required parameter 'areaId' is not null or undefined
      if (areaId === null || areaId === undefined) {
        throw new RequiredError(
          'areaId',
          'Required parameter areaId was null or undefined when calling propertyIdAreasAreaIdGet.'
        );
      }
      const localVarPath = `/{propertyId}/areas/{areaId}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'areaId'}}`, encodeURIComponent(String(areaId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} areaId
     * @param {UpdateAreaRequest} [updateAreaRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdAreasAreaIdPost(
      propertyId: string,
      areaId: string,
      updateAreaRequest?: UpdateAreaRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdAreasAreaIdPost.'
        );
      }
      // verify required parameter 'areaId' is not null or undefined
      if (areaId === null || areaId === undefined) {
        throw new RequiredError(
          'areaId',
          'Required parameter areaId was null or undefined when calling propertyIdAreasAreaIdPost.'
        );
      }
      const localVarPath = `/{propertyId}/areas/{areaId}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'areaId'}}`, encodeURIComponent(String(areaId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateAreaRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateAreaRequest !== undefined ? updateAreaRequest : {})
        : updateAreaRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [name]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdAreasGet(
      propertyId: string,
      name?: string,
      continuationToken?: string,
      limit?: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdAreasGet.'
        );
      }
      const localVarPath = `/{propertyId}/areas`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['ContinuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['Limit'] = limit;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {CreateAreaRequest} [createAreaRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdAreasPost(
      propertyId: string,
      createAreaRequest?: CreateAreaRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdAreasPost.'
        );
      }
      const localVarPath = `/{propertyId}/areas`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createAreaRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createAreaRequest !== undefined ? createAreaRequest : {})
        : createAreaRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AreasApi - functional programming interface
 * @export
 */
export const AreasApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} areaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdAreasAreaIdDelete(
      propertyId: string,
      areaId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AreasApiAxiosParamCreator(
        configuration
      ).propertyIdAreasAreaIdDelete(propertyId, areaId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} areaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdAreasAreaIdGet(
      propertyId: string,
      areaId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaItemResponse> {
      const localVarAxiosArgs = AreasApiAxiosParamCreator(configuration).propertyIdAreasAreaIdGet(
        propertyId,
        areaId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} areaId
     * @param {UpdateAreaRequest} [updateAreaRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdAreasAreaIdPost(
      propertyId: string,
      areaId: string,
      updateAreaRequest?: UpdateAreaRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaItemResponse> {
      const localVarAxiosArgs = AreasApiAxiosParamCreator(configuration).propertyIdAreasAreaIdPost(
        propertyId,
        areaId,
        updateAreaRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [name]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdAreasGet(
      propertyId: string,
      name?: string,
      continuationToken?: string,
      limit?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaListResponse> {
      const localVarAxiosArgs = AreasApiAxiosParamCreator(configuration).propertyIdAreasGet(
        propertyId,
        name,
        continuationToken,
        limit,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {CreateAreaRequest} [createAreaRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdAreasPost(
      propertyId: string,
      createAreaRequest?: CreateAreaRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaItemResponse> {
      const localVarAxiosArgs = AreasApiAxiosParamCreator(configuration).propertyIdAreasPost(
        propertyId,
        createAreaRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AreasApi - factory interface
 * @export
 */
export const AreasApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} areaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdAreasAreaIdDelete(
      propertyId: string,
      areaId: string,
      options?: any
    ): AxiosPromise<void> {
      return AreasApiFp(configuration).propertyIdAreasAreaIdDelete(
        propertyId,
        areaId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} areaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdAreasAreaIdGet(
      propertyId: string,
      areaId: string,
      options?: any
    ): AxiosPromise<AreaItemResponse> {
      return AreasApiFp(configuration).propertyIdAreasAreaIdGet(
        propertyId,
        areaId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} areaId
     * @param {UpdateAreaRequest} [updateAreaRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdAreasAreaIdPost(
      propertyId: string,
      areaId: string,
      updateAreaRequest?: UpdateAreaRequest,
      options?: any
    ): AxiosPromise<AreaItemResponse> {
      return AreasApiFp(configuration).propertyIdAreasAreaIdPost(
        propertyId,
        areaId,
        updateAreaRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [name]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdAreasGet(
      propertyId: string,
      name?: string,
      continuationToken?: string,
      limit?: number,
      options?: any
    ): AxiosPromise<AreaListResponse> {
      return AreasApiFp(configuration).propertyIdAreasGet(
        propertyId,
        name,
        continuationToken,
        limit,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {CreateAreaRequest} [createAreaRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdAreasPost(
      propertyId: string,
      createAreaRequest?: CreateAreaRequest,
      options?: any
    ): AxiosPromise<AreaItemResponse> {
      return AreasApiFp(configuration).propertyIdAreasPost(
        propertyId,
        createAreaRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * AreasApi - object-oriented interface
 * @export
 * @class AreasApi
 * @extends {BaseAPI}
 */
export class AreasApi extends BaseAPI {
  /**
   *
   * @param {string} propertyId
   * @param {string} areaId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AreasApi
   */
  public propertyIdAreasAreaIdDelete(propertyId: string, areaId: string, options?: any) {
    return AreasApiFp(this.configuration).propertyIdAreasAreaIdDelete(
      propertyId,
      areaId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} areaId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AreasApi
   */
  public propertyIdAreasAreaIdGet(propertyId: string, areaId: string, options?: any) {
    return AreasApiFp(this.configuration).propertyIdAreasAreaIdGet(
      propertyId,
      areaId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} areaId
   * @param {UpdateAreaRequest} [updateAreaRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AreasApi
   */
  public propertyIdAreasAreaIdPost(
    propertyId: string,
    areaId: string,
    updateAreaRequest?: UpdateAreaRequest,
    options?: any
  ) {
    return AreasApiFp(this.configuration).propertyIdAreasAreaIdPost(
      propertyId,
      areaId,
      updateAreaRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} [name]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AreasApi
   */
  public propertyIdAreasGet(
    propertyId: string,
    name?: string,
    continuationToken?: string,
    limit?: number,
    options?: any
  ) {
    return AreasApiFp(this.configuration).propertyIdAreasGet(
      propertyId,
      name,
      continuationToken,
      limit,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {CreateAreaRequest} [createAreaRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AreasApi
   */
  public propertyIdAreasPost(
    propertyId: string,
    createAreaRequest?: CreateAreaRequest,
    options?: any
  ) {
    return AreasApiFp(this.configuration).propertyIdAreasPost(
      propertyId,
      createAreaRequest,
      options
    )(this.axios, this.basePath);
  }
}
