import {
  BillingReferenceEditableDisplay,
  CorporateAffiliationDisplay,
  DateDisplay,
  useCommonStyle,
} from '@lib/common';
import { Reservation } from '@lib/state';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { parseISO } from 'date-fns';
import React from 'react';

interface Props {
  reservation: Reservation;
}

export const ReservationInfoBookingSource: React.FC<Props> = ({ reservation }) => {
  const styles = useCommonStyle();

  return (
    <Box height="100%" clone>
      <Paper className={styles.paper} elevation={3} square>
        <Typography variant="h5" paragraph>
          Booking Source
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography>
              <strong>Booking Source</strong>
            </Typography>
            <Typography>{reservation.bookingSource}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Created On</strong>
            </Typography>
            <DateDisplay date={parseISO(reservation.createdTime)} dateFormat="M/d/yyyy hh:mm:ss" />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Booking Brand</strong>
            </Typography>
            <Typography>{reservation.bookingBrand}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>OTA Booking ID</strong>
            </Typography>
            <Typography>{reservation.bookingExternalId}</Typography>
          </Grid>
          {!!reservation.currency && (
            <Grid item xs={6}>
              <Typography>
                <strong>Currency</strong>
              </Typography>
              <Typography>{reservation.currency}</Typography>
            </Grid>
          )}
          {!!reservation.otaSettlementType && (
            <Grid item xs={6}>
              <Typography>
                <strong>OTA Settlement Type</strong>
              </Typography>
              <Typography>{reservation.otaSettlementType}</Typography>
            </Grid>
          )}
          {!!reservation.affiliation && (
            <Grid item xs={6}>
              <Typography>
                <strong>Company</strong>
              </Typography>
              <CorporateAffiliationDisplay {...reservation.affiliation} />
            </Grid>
          )}
          <Grid item xs={6}>
            <BillingReferenceEditableDisplay reservation={reservation} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
