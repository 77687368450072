import { useFormContext } from 'react-hook-form';

interface InputLabelProps {
  shrink?: boolean;
}

interface InputProps {
  InputLabelProps?: InputLabelProps;
}

export function useFieldLabelProps(
  fieldName: string,
  defaultValue?: any,
  { InputLabelProps = {} }: InputProps = {}
) {
  const { watch } = useFormContext();

  const value = watch(fieldName, defaultValue);

  return { shrink: value ? true : undefined, ...InputLabelProps };
}
