import React from 'react';
import { Grid } from '@material-ui/core';
import { TimeThreshold } from 'app/state';
import { TimeThresholdInput } from 'app/shared';

export interface PolicyTimeConfigurationFormFields {
  checkIn?: TimeThreshold;
  checkOut?: TimeThreshold;
  earlyCheckIn?: TimeThreshold;
  lateCheckOut?: TimeThreshold;
  changeableThreshold?: TimeThreshold;
  cancelableThreshold?: TimeThreshold;
}

interface Props {
  configuration?: PolicyTimeConfigurationFormFields | null;
}

export const PolicyTimeConfigurationForm: React.FC<Props> = ({ configuration }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} data-testid="corporatePolicyCheckInThresholdDiv">
          <TimeThresholdInput
            prefixName="checkIn"
            label="Check In"
            timeOfDay={configuration?.checkIn?.timeOfDay}
            asDays={false}
            gracePeriodInMinutes={configuration?.checkIn?.gracePeriodInMinutes}
            required={true}
          />
        </Grid>
        <Grid item xs={12} data-testid="corporatePolicyCheckOutThresholdDiv">
          <TimeThresholdInput
            prefixName="checkOut"
            label="Check Out"
            timeOfDay={configuration?.checkOut?.timeOfDay}
            asDays={false}
            gracePeriodInMinutes={configuration?.checkOut?.gracePeriodInMinutes}
            required={true}
            isPrior={false}
          />
        </Grid>
        <Grid item xs={12} data-testid="corporatePolicyEarlyCheckInThresholdDiv">
          <TimeThresholdInput
            prefixName="earlyCheckIn"
            label="Early Check In"
            timeOfDay={configuration?.earlyCheckIn?.timeOfDay}
            asDays={false}
            gracePeriodInMinutes={configuration?.earlyCheckIn?.gracePeriodInMinutes}
            required={true}
          />
        </Grid>
        <Grid item xs={12} data-testid="corporateLateCheckOutThresholdDiv">
          <TimeThresholdInput
            prefixName="lateCheckOut"
            label="Late Check Out"
            timeOfDay={configuration?.lateCheckOut?.timeOfDay}
            asDays={false}
            gracePeriodInMinutes={configuration?.lateCheckOut?.gracePeriodInMinutes}
            required={true}
            isPrior={false}
          />
        </Grid>
        <Grid item xs={12} data-testid="corporatePolicyChangeableThresholdDiv">
          <TimeThresholdInput
            prefixName="changeableThreshold"
            label="Changeable Threshold"
            timeOfDay={configuration?.changeableThreshold?.timeOfDay}
            asDays={true}
            gracePeriodInMinutes={configuration?.changeableThreshold?.gracePeriodInMinutes}
            required={true}
            gracePeriodDetailText="Plus a Period of"
          />
        </Grid>
        <Grid item xs={12} data-testid="corporatePolicyCancelableThresholdDiv">
          <TimeThresholdInput
            prefixName="cancelableThreshold"
            label="Cancelable Threshold"
            timeOfDay={configuration?.cancelableThreshold?.timeOfDay}
            asDays={true}
            gracePeriodInMinutes={configuration?.cancelableThreshold?.gracePeriodInMinutes}
            required={true}
            gracePeriodDetailText="Plus a Period of"
          />
        </Grid>
      </Grid>
    </>
  );
};
