import React, { useCallback } from 'react';
import QRCode from 'qrcode.react';
import { Button, Grid, Typography } from '@material-ui/core';
import { MdmEnrollmentTokenData } from '@lib/state';
import { isKiosk } from 'app/shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  enrollmentToken: MdmEnrollmentTokenData;
}

export const DeviceEnrollmentQRCodeComponent: React.FC<Props> = ({ enrollmentToken }) => {
  const renderAsValue = isKiosk() ? 'svg' : 'canvas';

  const downloadQRCode = useCallback(() => {
    const qrCodeImageLink = (document.getElementById('qrCode') as HTMLCanvasElement).toDataURL(
      'image/png'
    );
    var link = document.createElement('a');
    link.download = `${enrollmentToken.policyName}-exp-${enrollmentToken.expirationTimestamp}.jpg`;
    link.href = qrCodeImageLink;
    link.click();
  }, [enrollmentToken]);

  return (
    <Grid
      item
      xs={12}
      style={{
        marginTop: '1rem',
        color: '#7c7c7c',
      }}
    >
      <Grid container justify="center">
        <QRCode
          id="qrCode"
          value={enrollmentToken.qrCode}
          size={300}
          includeMargin={true}
          renderAs={renderAsValue}
        />
      </Grid>
      {enrollmentToken.policyName && (
        <Typography variant="h5" align="center">
          QR Code for {enrollmentToken.policyName}
        </Typography>
      )}
      {enrollmentToken.expirationTimestamp && (
        <Typography variant="h5" align="center">
          Expires on {enrollmentToken.expirationTimestamp}
        </Typography>
      )}
      <Grid container justify="center">
        <Button onClick={downloadQRCode} variant="contained" color="secondary">
          <FontAwesomeIcon icon="download" />
          <Typography variant="button" align="center" style={{ marginLeft: '0.4rem' }}>
            Download QR Code
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
