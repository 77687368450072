import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@material-ui/core';

import { propertyQuery, propertyService, Property } from '@lib/state';
import { useObservable } from '@lib/common';
import { KioskPropertyButton } from '../components';

export const SelectPropertyPage: React.FC<RouteComponentProps> = props => {
  const properties = useObservable(propertyQuery.properties);

  const handleSelectProperty = (property: Property) => {
    propertyService.selectProperty(property.id);
    props.history.push('/kiosk');
  };

  return (
    <div>
      <Box py={6} clone>
        <Container maxWidth="md">
          <Typography variant="h2" color="textPrimary" align="center" paragraph>
            Select a Property
          </Typography>
          <Grid container spacing={2}>
            {properties.map(property => (
              <KioskPropertyButton
                key={property.id}
                clickHandler={handleSelectProperty}
                property={property}
              />
            ))}
          </Grid>
        </Container>
      </Box>
    </div>
  );
};
