import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Container, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AccountForms, FormStatus, sessionService, sessionQuery } from '@lib/state';
import { SignInForm, SignInFormFields, useFormEvents, useObservable, Link } from '@lib/common';
import { SignedInRedirect, EmployeeInviteRedirect } from '../components';

export const SignInPage: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();

  const [{ status, error }, resetFormUI] = useFormEvents(AccountForms.Login);
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');
  const createAccountLink = `/account/create${search}`;

  const onSubmit = ({ username, password }: SignInFormFields) =>
    sessionService.login(username, password);

  const onForgotPasswordRedirect = () => {
    history.push(`/account/forgot`);
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" align="center" color="textSecondary" paragraph>
        Armadillo Management Portal
      </Typography>
      <EmployeeInviteRedirect isLoggedIn={isLoggedIn} search={search}>
        <SignedInRedirect isLoggedIn={isLoggedIn} search={search} />
      </EmployeeInviteRedirect>
      <SignInForm
        onSubmit={onSubmit}
        error={error}
        pending={status === FormStatus.Pending}
        onResetFeedback={resetFormUI}
        usernameLabel="Username"
        onForgotPasswordRedirect={onForgotPasswordRedirect}
        buttonColor="secondary"
      >
        <Box display="flex" flexDirection="column" alignItems="center" my={2}>
          <Typography variant="h4">Don't have an account?</Typography>
          <Link to={createAccountLink} variant="button" color="secondary">
            Let's Get Started <FontAwesomeIcon icon="chevron-double-right" />
          </Link>
        </Box>
      </SignInForm>
    </Container>
  );
};
