import { ResponsiveContainer, Section, useObservable, usePageTitle } from '@lib/common';
import { QRCodeFilter, qrCodeService, roomsQuery, roomsService, sessionQuery } from '@lib/state';
import React, { useCallback, useEffect, useState } from 'react';
import { CreateQRCodeModal, QRCodeList } from '../components';
import { useQRCodePagination, useQRCodes } from '../hooks';
import { AuditLogs } from 'app/shared';
import { Box, Button } from '@material-ui/core';
import { QRCodeSearchFilter } from '../components/qr-code-search-filter';

export function QRCodeListPage() {
  usePageTitle('QR Code Stickers');
  const propertyId = useObservable(sessionQuery.propertyId);
  const [filters, setFilters] = useState<QRCodeFilter>({ propertyId });
  const { qrCodes, loading } = useQRCodes(filters);
  const pagination = useQRCodePagination();
  const [newOpen, setNewOpen] = useState(false);

  const onNewClick = useCallback(() => {
    setNewOpen(true);
  }, [setNewOpen]);

  const onClose = useCallback(() => {
    setNewOpen(false);
  }, [setNewOpen]);

  useEffect(() => {
    roomsService.getRooms(propertyId ?? '');
  }, [propertyId]);

  const rooms = useObservable(roomsQuery.rooms);
  const roomsLoading = useObservable(roomsQuery.loading);

  const loadMore = useCallback(() => {
    qrCodeService.getQRCodes(filters, pagination.continuationToken);
  }, [pagination, filters]);

  const onChange = useCallback(
    (newFilters: QRCodeFilter) => {
      setFilters({ ...newFilters, propertyId });
    },
    [setFilters, propertyId]
  );

  if (!propertyId) return null;

  return (
    <ResponsiveContainer>
      <Section>
        {newOpen && (
          <CreateQRCodeModal
            propertyId={propertyId}
            rooms={rooms}
            open={newOpen}
            onClose={onClose}
          />
        )}
        <Box display="flex" justifyContent="flex-end" flexDirection="row">
          <Button variant="contained" color="primary" onClick={onNewClick}>
            New QR Code
          </Button>
        </Box>
        <Section>
          <QRCodeSearchFilter filters={filters} onChange={onChange} />

          <QRCodeList
            propertyId={propertyId}
            qrCodes={qrCodes}
            rooms={rooms}
            loading={loading && roomsLoading}
            isDone={pagination.isDone}
            loadMore={loadMore}
          />
        </Section>
        <Section title="Audit Logs">
          <AuditLogs scope="qr-codes" id={propertyId} />
        </Section>
      </Section>
    </ResponsiveContainer>
  );
}
