import React, { useEffect } from 'react';
import { TextField, Button, Typography, Grid, Box } from '@material-ui/core';
import {
  FormProps,
  Feedback,
  ErrorDisplay,
  useInput,
  useForm,
  required,
  SubmitButton,
} from '../../forms';
import { ButtonLink } from '../link';

export interface VerifyPhoneNumberFormFields {
  code: string;
}
interface Props extends FormProps<VerifyPhoneNumberFormFields> {
  onResend: () => void;
  onCancel: () => void;
}

export const VerifyPhoneNumberForm: React.FC<Props> = ({
  onResend,
  onCancel,
  pending,
  success,
  error,
  onSubmit,
  onResetFeedback,
}) => {
  const code = useInput('', { validators: [required()] });
  const form = useForm(() => {
    onResetFeedback();
    onSubmit({
      code: code.value,
    });
  }, code);

  return (
    <form {...form.bind}>
      <Box>
        {error && (
          <>
            <ErrorDisplay error={error}>
              <Typography variant="subtitle1" align="center" color="textSecondary">
                Sorry, we couldn't verify your phone number. Please try requesting a new
                verification code.
              </Typography>
            </ErrorDisplay>
            <Button
              type="button"
              onClick={onResend}
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
            >
              Resend Code
            </Button>
          </>
        )}
        {!!success && (
          <>
            <Feedback show={!!success} severity="success">
              <Typography variant="subtitle1" align="center" color="textSecondary">
                Thank you! Your phone number is now verified.
              </Typography>
            </Feedback>
            <ButtonLink to="/account/signin" fullWidth variant="contained" color="primary">
              Continue
            </ButtonLink>
          </>
        )}
      </Box>
      {!success && !error && (
        <>
          <TextField
            name="code"
            label="Verification Code"
            value={code}
            {...code.bind}
            type="text"
            fullWidth
            style={{ marginBottom: 5 }}
          />

          <Grid container spacing={4}>
            <Grid item xs>
              <SubmitButton
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                disabled={!form.valid}
              >
                Verify
              </SubmitButton>
            </Grid>
            <Grid item xs>
              <Button onClick={onCancel} variant="outlined" size="large" fullWidth>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </form>
  );
};
