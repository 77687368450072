import { useEffect, useCallback, useMemo } from 'react';
import { getFormEvents, resetFormUI, FormEvent, watchFormEvents, FormStatus } from '@lib/state';
import { useObservable } from '../utils';

export const useFormEvents = (formId: string): [FormEvent, () => void] => {
  // Reset the form UI when the component loads and is destroyed.
  const reset = useCallback(() => resetFormUI(formId), [formId]);

  useEffect(() => {
    reset();
    return reset;
  }, [reset]);

  // Use the same observable unless formId changes.
  const events = useMemo(
    () => ({
      observable: getFormEvents(formId),
      formId,
    }),
    [formId]
  );

  // Watch form events
  const formEvent = useObservable(events.observable);
  return [formEvent, reset];
};

export const useFormWatch = (...formId: string[]) => {
  const events = useMemo(() => watchFormEvents(FormStatus.Success, ...formId), [...formId]);

  return useObservable(events, 'async');
};
