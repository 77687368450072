import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

import { CorporateAffiliation } from '@lib/state';

type Props = Pick<CorporateAffiliation, 'corporateAccountId' | 'corporateAccountName'> &
  Pick<TypographyProps, 'className' | 'variant' | 'color'>;

export const CorporateAffiliationDisplay: React.FC<Props> = ({
  corporateAccountId,
  corporateAccountName,
  className,
  variant,
  color,
}) => {
  if (!corporateAccountId) return null;

  const typographyProps = { className, variant, color };

  return <Typography {...typographyProps}>{corporateAccountName}</Typography>;
};
