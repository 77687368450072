function stripPhoneNumberFormatting(phoneNumber: string) {
  return ('' + phoneNumber).replace('+1', '').replace(/\D/g, '');
}

/**
 * Format a phone number in the following format:
 * (123) 456-7890
 *
 * @param phoneNumber : expected format is E.164
 * https://www.twilio.com/docs/glossary/what-e164
 * ex: +15551234, 5551234
 */
export function formatPhoneNumber(phoneNumber: string) {
  const cleaned = stripPhoneNumberFormatting(phoneNumber);

  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export function getE164FormattedPhoneNumber(phoneNumber?: string | null) {
  if (!phoneNumber) return null;

  return `+1${stripPhoneNumberFormatting(phoneNumber)}`;
}
