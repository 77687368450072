import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';

import {
  usePageTitle,
  ReservationRouteProps,
  useObservable,
  kioskStyles,
  LoadingIndicator,
} from '@lib/common';
import { reservationQuery, reservationService } from '@lib/state';

export const BypassReservationCodePage: React.FC<ReservationRouteProps> = ({ match, history }) => {
  const { kioskForm } = kioskStyles();

  const reservationId = match.params['reservationId'];

  const pageTitle = 'Verifying';

  usePageTitle(pageTitle);

  const verificationCode = useObservable(reservationQuery.verificationCode);
  useEffect(() => reservationService.retrieveVerificationCode(reservationId), [reservationId]);

  const accountRoute = `/kiosk/${reservationId}/account?code=${verificationCode}`;

  useEffect(() => {
    if (verificationCode) history.replace(accountRoute);
  }, [history, verificationCode, accountRoute]);

  return (
    <Container maxWidth="md" className={kioskForm}>
      <LoadingIndicator loadingText="Verifying Code..." fillContainer />
    </Container>
  );
};
