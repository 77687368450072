import { Query, selectPersistStateInit } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map, switchMapTo } from 'rxjs/operators';

import { SessionState, sessionStore } from './session.store';

export class SessionQuery extends Query<SessionState> {
  isLoggedIn = selectPersistStateInit().pipe(switchMapTo(this.select(x => x.auth.authenticated)));
  currentUser = this.select(x => x.user);

  isEmployee = combineLatest([this.isLoggedIn, this.currentUser]).pipe(
    map(([loggedIn, currentUser]) => loggedIn && currentUser?.role?.includes('Employee'))
  );

  propertyId = selectPersistStateInit().pipe(switchMapTo(this.select(x => x.propertyId)));

  employeeCode = this.select(x => x.employeeCode);
  employeeRoles = this.select(x => x.employeeRoles);
  isLoadingRoles = this.select(x => !x.employeeRoles?.length);

  guestPortalUrl = this.select(x => x.guestPortalUrl);

  bypassLandingPage = selectPersistStateInit().pipe(
    switchMapTo(this.select(state => state.ui.ignoreLandingPage))
  );
}

export const sessionQuery = new SessionQuery(sessionStore);
