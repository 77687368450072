import React, { useState, useCallback } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import { Room } from '@lib/state';

const filterOptions = createFilterOptions({
  ignoreCase: true,
  trim: true,
  stringify: (option: Room) => option.roomNumber,
});

interface Props {
  rooms: Array<Room>;
  loading?: boolean;
  selected: Array<string>;
  onSelected: (room: Room) => void;
}

export const RoomLookupInput: React.FC<Props & TextFieldProps> = ({
  rooms,
  selected,
  loading,
  onSelected,
  ...props
}) => {
  const [value, setValue] = useState<Room | null>(null);
  const [input, setInput] = useState<string>('');

  const onChange = useCallback(
    (_, opt) => {
      onSelected(opt);
      setInput('');
      setValue(null);
    },
    [onSelected]
  );

  const onInputChange = useCallback((_, x) => setInput(x), []);

  return (
    <Autocomplete
      options={rooms}
      filterOptions={filterOptions}
      getOptionLabel={room => room.roomNumber}
      getOptionSelected={(opt, val) => opt.id === val.id}
      getOptionDisabled={room => selected.includes(room.id)}
      loading={loading}
      renderOption={room => <strong>{room.roomNumber}</strong>}
      renderInput={(params: any) => <TextField {...params} {...props} />}
      value={value}
      onChange={onChange}
      inputValue={input}
      onInputChange={onInputChange}
    />
  );
};
