import { EntityStore, EntityState, StoreConfig } from '@datorama/akita';

import { CorporateAccount } from '../models.common';
import { PaymentMethod } from './payment-method.model';

export interface PaymentMethodState
  extends EntityState<PaymentMethod, PaymentMethod['authNetPaymentId']> {
  invoiceAccounts: Array<CorporateAccount>;
}

@StoreConfig({ name: 'payment-methods', resettable: true, idKey: 'authNetPaymentId' })
export class PaymentMethodStore extends EntityStore<PaymentMethodState> {
  constructor() {
    super({ invoiceAccounts: [] });
  }
}

export const paymentMethodStore = new PaymentMethodStore();
