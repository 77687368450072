import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CreateInvoiceStatusModel } from '../api/generated/reservations';

export interface CreateInvoiceStatusState extends EntityState<CreateInvoiceStatusModel, string> {}

@StoreConfig({ name: 'create-invoice-status-store', idKey: 'accountId' })
export class CreateInvoiceStatusStore extends EntityStore<CreateInvoiceStatusState> {
  constructor() {
    super({});
  }
}

const createInvoiceStatusStore: CreateInvoiceStatusStore = new CreateInvoiceStatusStore();
export default createInvoiceStatusStore;
