import { AppConfig } from '@lib/state';

// use this to override localhost for testing
const customerCode = AppConfig.Api.CustomerCode;

export function getCustomerCodeOrDefault(defaultCode?: string): string | undefined {
  // can override when testing locally
  if (customerCode) return customerCode;

  // could not use useLocation hook since this will be called prior to the mounting of the react router
  const host = window.location.hostname;
  const parts = host.split('.');

  // look for a subdomain as the code (url of the form subdomain.domain.tld)
  let code = host && parts.length > 2 ? parts[0] : undefined;

  // if there is no subdomain, or the subdomain is guest or www, use the default code
  if (!code || code === 'guest' || code === 'www') {
    return defaultCode;
  }

  return code;
}

export function useCurrentCustomerCode(defaultCode?: string) {
  return getCustomerCodeOrDefault(defaultCode);
}
