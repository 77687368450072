import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container, Box, Typography } from '@material-ui/core';

import { sessionQuery } from '@lib/state';
import { FormHub, useObservable, kioskStyles } from '@lib/common';
import { ReservationSearchForm } from '../components';

interface ReservationSearchFields {
  firstName?: string;
  lastName: string;
}

export const ReservationSearchPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { kioskForm } = kioskStyles();
  const propertyId = useObservable(sessionQuery.propertyId, 'async');

  const onSubmit = useCallback(
    ({ firstName, lastName }: ReservationSearchFields) =>
      history.push({
        pathname: '/kiosk/search/results',
        search: `?firstName=${firstName?.trim()}&lastName=${lastName?.trim()}`,
      }),
    [history]
  );

  if (!propertyId) return null;

  return (
    <Container maxWidth="md" className={kioskForm}>
      <Box my={3} clone>
        <Typography variant="h4" align="center">
          Enter your first and last name to search for your reservation
        </Typography>
      </Box>
      <FormHub<ReservationSearchFields> onSubmit={onSubmit}>
        <ReservationSearchForm />
      </FormHub>
    </Container>
  );
};
