import React, { useCallback, useEffect } from 'react';
import { Typography } from '@material-ui/core';

import {
  EmailDisplay,
  NameDisplay,
  RemoveButton,
  Section,
  useFormEvents,
  DataTable,
} from '@lib/common';
import {
  CorporateAccount,
  CorporateAccountForms,
  corporateAccountService,
  CorporateDelegate,
} from 'app/state';
import { useCorporateAccountDelegates } from '../useCorporateAccountDelegates';
import { AddDelegate } from './add-delegate';
import { FormStatus } from '@lib/state';

interface Props {
  account?: CorporateAccount;
}

export const CorporateAccountDelegates: React.FC<Props> = ({ account }) => {
  const delegates = useCorporateAccountDelegates(account?.id);

  const [{ status, error }, resetStatus] = useFormEvents(CorporateAccountForms.RemoveDelegate);

  const removeDelegate = useCallback(
    (delegate: CorporateDelegate) => corporateAccountService.removeDelegate(delegate),
    []
  );

  // when the delegate list changes, reset so that the remove button can be used again
  useEffect(() => {
    if (status === FormStatus.Success) resetStatus();
  }, [status, resetStatus]);

  return (
    <Section title="Account Delegates" textAlign="left">
      {account && <AddDelegate account={account} delegates={delegates} />}
      <DataTable
        items={delegates}
        getItemKey={x => x.userId}
        columns={[
          {
            title: 'Name',
            valueFactory: x => <NameDisplay {...x.name} />,
          },
          {
            title: 'Email',
            valueFactory: x => <EmailDisplay {...x.contact} />,
          },
          {
            title: '',
            align: 'right',
            valueFactory: x => (
              <RemoveButton
                onConfirm={() => removeDelegate(x)}
                status={status}
                error={error}
                confirmView={
                  <Typography>
                    Are you sure you want to remove <NameDisplay display="inline" {...x.name} />?
                  </Typography>
                }
                data-testid="corporateDelegateRemoveButton"
              />
            ),
          },
        ]}
      />
    </Section>
  );
};
