import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';

import { Property } from '@lib/state';
import { Employee } from './employee.model';

export interface EmployeeFilters {
  firstName: string;
  lastName: string;
  roles: string[];
}

export interface EmployeeUIState {
  propertyId: Property['id'] | null;
  filters: EmployeeFilters;
  inviteExists?: boolean;
}

export interface EmployeeState
  extends EntityState<Employee, Employee['userId']>,
    ActiveState<Employee['userId']> {
  ui: EmployeeUIState;
}

export const initialEmployeeFilters = {
  firstName: '',
  lastName: '',
  roles: [],
};

@StoreConfig({ name: 'employees', resettable: true, idKey: 'userId' })
export class EmployeeStore extends EntityStore<EmployeeState> {
  constructor() {
    super({
      ui: {
        propertyId: null,
        filters: initialEmployeeFilters,
        inviteExists: undefined,
      },
    });
  }
}

export const employeeStore = new EmployeeStore();
