import { QueryEntity, Order } from '@datorama/akita';
import { switchMap } from 'rxjs/operators';
import { SessionQuery, sessionQuery } from '@lib/state';
import {
  CorporateAccountState,
  corporateAccountStore,
  CorporateAccountStore,
} from './corporate-account.store';

export class CorporateAccountQuery extends QueryEntity<CorporateAccountState> {
  constructor(
    protected store: CorporateAccountStore,
    private readonly _sessionQuery: SessionQuery
  ) {
    super(store);
  }

  pagination = this.select(({ pagination }) => pagination);

  filters = this.select(({ ui }) => ui);

  corporations = this.selectAll({ sortBy: 'name', sortByOrder: Order.ASC });

  corporationsWithDelegates = this.selectAll({
    filterBy: x => !!x.hasDelegates,
    sortBy: 'name',
    sortByOrder: Order.ASC,
  });

  activeAccount = this.selectActive();

  activeAccountDelegates = this.selectActive(({ delegates }) => {
    if (!delegates) {
      return delegates;
    }

    return [...delegates].sort((a, b) => {
      const aFirstName = a.name?.first ?? '';
      const bFirstName = b.name?.first ?? '';
      const aLastName = a.name?.last ?? '';
      const bLastName = b.name?.last ?? '';
      const aEmail = a.contact?.email ?? '';
      const bEmail = b.contact?.email ?? '';

      if (aFirstName < bFirstName) return -1;
      if (aFirstName > bFirstName) return 1;
      if (aLastName < bLastName) return -1;
      if (aLastName > bLastName) return 1;
      if (aEmail < bEmail) return -1;
      if (aEmail > bEmail) return 1;
      return 0;
    });
  });

  activeAccountZones = this._sessionQuery.propertyId.pipe(
    switchMap(propertyId =>
      this.selectActive(({ zones }) => {
        return zones?.filter(f => f.propertyId === propertyId);
      })
    )
  );

  searchResults = this.filters.pipe(
    switchMap(ui =>
      this.selectAll({
        filterBy: x =>
          (!ui.name || x.name.toLowerCase().startsWith(ui.name.toLowerCase())) &&
          (x.billingFrequencyType?.toString() === ui.billingFrequencyType ||
            ui.billingFrequencyType === 'All'),
        sortBy: 'name',
        sortByOrder: Order.ASC,
      })
    )
  );
}

export const corporateAccountQuery = new CorporateAccountQuery(corporateAccountStore, sessionQuery);
