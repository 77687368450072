import React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router-dom';

import { MaintenanceTicketListPage } from './pages';

export const MaintenanceTicketRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}`} exact component={MaintenanceTicketListPage} />
    <Route path={`${match.path}/:ticketId`} exact component={MaintenanceTicketListPage} />
  </Switch>
);
