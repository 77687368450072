/**
 * Constructs a promise that resolves after a given delay.
 * @param ms Milliseconds to wait before resolving.
 * @param signal Abort signal to use for cancellation.
 * @returns A promise that resolves after the specified delay, or rejects if the signal is aborted.
 */
export function delay(ms: number, signal?: AbortSignal) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      if (signal?.aborted) {
        reject(new Error('Aborted'));
      } else {
        resolve(null);
      }
    }, ms);

    signal?.addEventListener('abort', () => {
      clearTimeout(timeout);
      reject(new Error('Aborted'));
    });
  });
}
