import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Reservations } from '../api';
import { PaymentMethodStore, paymentMethodStore } from './payment-method.store';
import { Name, Address } from '../models.common';
import { applyTransaction } from '@datorama/akita';
import { AcceptCardData, getPaymentNonce } from '../utils';
import { dispatchForm } from '../forms';
import { PaymentMethodForms } from './payment-method.forms';

export class PaymentMethodService {
  constructor(
    private store: PaymentMethodStore,
    private readonly guestApi: Reservations.GuestApi,
    private readonly guestsApi: Reservations.GuestsApi
  ) {}

  getPaymentMethods(propertyId?: string, corporateAccountId?: string): void {
    from(this.guestApi.guestPaymentmethodsGet(propertyId, corporateAccountId))
      .pipe(map(({ data }) => data))
      .subscribe(({ data, invoiceAccounts }) =>
        applyTransaction(() => {
          this.store.set(data);
          this.store.update(() => ({ invoiceAccounts }));
        })
      );
  }

  getGuestPaymentMethods(userId: string, propertyId: string, corporateAccountId?: string): void {
    from(this.guestsApi.guestsGuestUserIdPaymentmethodsGet(userId, propertyId, corporateAccountId))
      .pipe(map(({ data }) => data))
      .subscribe(({ data, invoiceAccounts }) =>
        applyTransaction(() => {
          this.store.set(data);
          this.store.update(() => ({ invoiceAccounts }));
        })
      );
  }

  updatePaymentMethod(
    paymentProfileId: string,
    name: Name,
    address: Address,
    defaultMethod: boolean
  ): void {
    from(
      this.guestApi.guestPaymentmethodPaymentProfileIdPost(paymentProfileId, {
        name,
        address,
        defaultMethod,
      })
    ).subscribe(() => this.getPaymentMethods());
  }

  addPaymentMethod(name: Name, address: Address, card?: AcceptCardData): void {
    this.addPaymentMethodObs(name, address, card)
      .pipe(
        dispatchForm(PaymentMethodForms.AddPaymentMethod),
        map(response => response.data.data)
      )
      .subscribe(paymentMethod =>
        applyTransaction(() => {
          this.store.upsert(paymentMethod.authNetPaymentId, paymentMethod);
        })
      );
  }

  addPaymentMethodObs(name: Name, address: Address, card?: AcceptCardData) {
    return getPaymentNonce(card).pipe(
      switchMap(nonce =>
        this.guestApi.guestPaymentmethodPost({
          name,
          address,
          paymentNonce: nonce?.opaqueData,
        })
      )
    );
  }

  deletePaymentMethod(paymentProfileId: string, paymentCustomerId?: string): void {
    from(
      this.guestApi.guestPaymentmethodPaymentProfileIdDelete(paymentProfileId, {
        paymentCustomerId: paymentCustomerId ?? undefined,
      })
    )
      .pipe(dispatchForm(PaymentMethodForms.DeletePaymentMethod))
      .subscribe(() => this.store.remove(paymentProfileId));
  }

  reset(): void {
    this.store.reset();
  }
}

export const paymentMethodService = new PaymentMethodService(
  paymentMethodStore,
  new Reservations.GuestApi(),
  new Reservations.GuestsApi()
);
