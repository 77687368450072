import React, { useEffect } from 'react';
import { propertyService, Property, sessionService } from '@lib/state';
import {
  isKiosk,
  getPropertyInfo,
  ArmPropertyModel,
  startNativeBridgeListeners,
} from './nativebridge';
import { useHistory } from 'react-router-dom';
import { PropertyStatus } from '@lib/state/api/generated/properties';

export const NativeBridgeProvider: React.FC = () => {
  const _isClientKiosk = isKiosk();
  const history = useHistory();

  useEffect(() => {
    if (_isClientKiosk) {
      sessionService.logout();

      console.log('IsKiosk = true');
      // Ask kiosk for property info
      getPropertyInfo((property: ArmPropertyModel) => {
        console.log('Received property = ' + property.id);

        const storeProperty: Property = {
          id: property.id,
          customerId: '',
          name: property.name,
          url: '',
          location: {},
          timezone: '',
          status: PropertyStatus.Open,
        };

        propertyService.loadKiosk(storeProperty);
      });
    }
  }, [_isClientKiosk]);

  useEffect(() => {
    startNativeBridgeListeners(history);
  }, [history]);

  return null;
};
