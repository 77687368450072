import { QueryEntity } from '@datorama/akita';
import { RoomAccessState, roomAccessStore } from './room-access.store';
import { switchMap } from 'rxjs/operators';

export class RoomAccessQuery extends QueryEntity<RoomAccessState> {
  allRoomAccess = this.select(({ ui }) => ui).pipe(
    switchMap(ui => {
      return this.selectAll({
        filterBy: [x => x.propertyId === ui.propertyId && x.roomNumber === ui.roomNumber],
      });
    })
  );
}

export const roomAccessQuery = new RoomAccessQuery(roomAccessStore);
