import { QueryEntity } from '@datorama/akita';
import { RoomListState, RoomListStore, roomListStore } from './room-list.store';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { sessionQuery, SessionQuery } from '@lib/state';

export class RoomListQuery extends QueryEntity<RoomListState> {
  constructor(store: RoomListStore, private readonly sessionQuery: SessionQuery) {
    super(store);
  }
  filters = this.select(x => x.ui.filters);

  rooms = combineLatest([this.sessionQuery.propertyId, this.filters]).pipe(
    switchMap(([propertyId, filter]) =>
      this.selectAll({
        filterBy: [
          x => x.propertyId === propertyId,
          x => x.roomNumber.toLowerCase().indexOf(filter.roomNumber?.toLowerCase() ?? '') >= 0,
          x => {
            if (!filter.roomTypeId) return true;
            return x.roomTypeId === filter.roomTypeId;
          },
          x => {
            if (!filter.roomZoneId) return true;
            return x.roomZoneId === filter.roomZoneId;
          },
        ],
        sortBy: 'roomNumber',
      })
    )
  );

  pagination = this.select(({ pagination }) => pagination);
  activeRoom = this.selectActive();
}

export const roomListQuery = new RoomListQuery(roomListStore, sessionQuery);
