import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  makeStyles,
  createStyles,
  Typography,
  Divider,
  Link,
  Button,
  Grid,
} from '@material-ui/core';

import {
  Reservation,
  Property,
  PropertyPolicy,
  FailedReservation,
  ConsumedHolds,
  roomRateQuery,
} from '@lib/state';
import {
  CurrencyDisplay,
  Section,
  useLookup,
  ReservationInfo,
  RoomHoldsModal,
} from './../../common';
import { SectionVariant, Analytics } from '../components';
import { getReservationTransaction, useObservable } from '../utils';
import { ReservationFailures } from './reservation-booking-fail-list';

interface StyleProps {
  isContained: boolean;
}

const useStyles = makeStyles(theme =>
  createStyles({
    header: {
      padding: '0.5rem',
      marginBottom: '1rem',

      textAlign: 'center',
      backgroundColor: ({ isContained }: StyleProps) =>
        isContained ? theme.palette.common.white : theme.palette.grey['300'],
    },
    dense: {
      lineHeight: 1,
    },
  })
);

interface Props {
  reservations?: Reservation[];
  failures?: FailedReservation[];
  consumedHolds?: ConsumedHolds;
  properties: Array<Property>;
  variant?: SectionVariant;
  policy?: PropertyPolicy;
  onBookAgain?: () => void;
}

export const ReservationConfirmation: React.FC<Props> = ({
  reservations,
  failures,
  properties,
  variant,
  policy,
  consumedHolds,
  onBookAgain,
}) => {
  const isContained: boolean = variant === 'contained';
  const styles = useStyles({ isContained });
  const corporateBooking = useObservable(roomRateQuery.corporateBooking);
  const [openRoomHoldsModal, setOpenRoomHoldsModal] = useState(false);
  const propertyLookup = useLookup(properties, x => x.id);

  // todo: update the corporate holds store here.
  // this should only be called, once all the reservations have been booked. We will want to verify this statement is true.
  // it might be useful to create a new object that consists of Date / Remaining.
  // another option would be to extend ReservationHoldModel, and merge the Remaining Holds array with this array, grouping by date.

  const { grandTotal, paymentMethod } = useMemo(() => {
    // null values for totals prevents requiresPayment toggling which
    // can lead to rapid form swapping and react-hook-form errors
    const results: {
      grandTotal: number | null;
      paymentMethod: string | null;
    } = {
      grandTotal: null,
      paymentMethod: null,
    };

    if (!reservations || reservations.length === 0) return results;

    const charges = reservations.flatMap(reservation => reservation.charges);

    results.paymentMethod = charges[0]?.paymentMethod ?? null;
    results.grandTotal = charges.reduce((sum, current) => sum + current.totalAmount, 0);

    return results;
  }, [reservations]);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Section title="Reservation Confirmed" variant={variant} id="reservationComplete">
      <ReservationFailures failures={failures} />
      {reservations && reservations.length > 0 && (
        <>
          <Analytics {...getReservationTransaction(reservations)} />
          <div className={styles.header}>
            <Typography variant="body2">
              <strong>Thank You!</strong> Your
              {reservations.length > 1 ? ' reservations have ' : ' reservation has '}
              been confirmed.
            </Typography>
            <Typography variant="body2">
              Your account <strong>{paymentMethod}</strong> has been charged{' '}
              <CurrencyDisplay variant="body2" value={grandTotal} display="inline" />.
            </Typography>
          </div>
          {reservations.map((reservation, i) => (
            <React.Fragment key={reservation.id}>
              <ReservationInfo
                reservation={reservation}
                property={propertyLookup.get(reservation.propertyId)}
                highlight={!isContained}
                testIdNumber={i}
              ></ReservationInfo>
            </React.Fragment>
          ))}
          {onBookAgain && (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              style={{ paddingTop: '1.5em' }}
            >
              <Button
                onClick={onBookAgain}
                color="secondary"
                variant="contained"
                style={{ width: '50%' }}
              >
                Book Again
              </Button>
            </Grid>
          )}
          <Box mt={1.5} mb={1}>
            <Divider />
          </Box>
          <Typography className={styles.dense} variant="overline">
            <strong>Check-In Details:</strong>
          </Typography>
          {policy?.checkIn && (
            <Typography className={styles.dense} variant="body2" paragraph>
              {policy.checkIn}
            </Typography>
          )}
          <Typography className={styles.dense} variant="overline">
            <strong>Check-Out Details:</strong>
          </Typography>
          {policy?.checkOut && (
            <Typography className={styles.dense} variant="body2" paragraph>
              {policy.checkOut}
            </Typography>
          )}
          <Typography className={styles.dense} variant="overline">
            <strong>Changes and Cancellations:</strong>
          </Typography>
          {policy?.flex && (
            <Typography className={styles.dense} variant="body2">
              {policy.flex}
            </Typography>
          )}
          {corporateBooking && (
            <>
              <br />
              <Link onClick={() => setOpenRoomHoldsModal(true)}>
                <Typography className={styles.dense} variant="overline">
                  <strong>Show Room Hold Availability</strong>
                </Typography>
              </Link>
              <RoomHoldsModal
                propertyId={reservations[0].propertyId}
                open={openRoomHoldsModal}
                roomTypeId={reservations[0].rooms[0].roomTypeId}
                corporateAccount={corporateBooking}
                checkIn={new Date(reservations[0].checkInDate.replace(/-/g, '/'))}
                checkOut={new Date(reservations[0].checkOutDate.replace(/-/g, '/'))}
                onClose={() => setOpenRoomHoldsModal(false)}
                consumedHolds={consumedHolds}
              />
              <br />
            </>
          )}
        </>
      )}
    </Section>
  );
};
