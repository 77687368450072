import React, { useCallback } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { Button, Grid } from '@material-ui/core';

import { sessionQuery, propertyRoles } from '@lib/state';
import { useObservable, FormTextField, CheckboxesGroup } from '@lib/common';
import { employeeService } from 'app/state';

interface AddEmployeeFormFields {
  email: string;
  roles: Array<string>;
}

interface Props {
  onSubmitComplete: () => void;
}

export const AddEmployeeForm: React.FC<Props> = ({ onSubmitComplete }) => {
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const form = useForm<AddEmployeeFormFields>({});
  const { email, roles } = form.watch({ nest: true });

  const onSubmit = useCallback(() => {
    if (propertyId) {
      employeeService.inviteEmployee(propertyId, email, roles);
      onSubmitComplete();
    }
  }, [propertyId, email, roles, onSubmitComplete]);

  return (
    <FormContext {...form}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormTextField defaultValue={email} name="email" label="Employee Email" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <CheckboxesGroup name="roles" label="Employee Roles" options={propertyRoles} />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary" fullWidth onClick={onSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </FormContext>
  );
};
