import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Grid, GridProps, MenuItem } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDays, isBefore, startOfDay, startOfTomorrow, format } from 'date-fns';
import { Property, FormStatus, roomRateService, CorporateAccount } from '@lib/state';
import { FormTextField, ErrorDisplay, Feedback } from '../../forms';
import { StepProps } from '../step-props';
import { Analytics } from '../../components';

export interface LocationFormFields {
  propertyId: string;
  checkInDate: Date;
  checkOutDate: Date;
  quantity: number;
  promoCode?: string | null;
  corporateAccountId?: string | null;
}

interface LocationFormProps extends StepProps<LocationFormFields> {
  className?: string;
  hideLocationForPropertyId?: boolean;
  properties: Property[];
  corporateAccounts?: CorporateAccount[];
  iconColor?: string;
  itemSpacing?: GridProps['spacing'];
  itemClass?: string;
  defaultPropertyId?: string | null;
  defaultCorporateAccountId?: string | null;
  minCheckIn: Date;
  defaultCheckIn?: Date;
  defaultCheckOut?: Date;
  defaultQuantity?: number;

  status: FormStatus;
  error?: Error;

  sendToDataLayer?: boolean;

  readyForCheckAvailability?: boolean;
}

export const LocationForm: React.FC<LocationFormProps> = ({
  className,
  hideLocationForPropertyId,
  properties,
  corporateAccounts = [],
  iconColor,
  itemSpacing = 1,
  itemClass,
  defaultPropertyId,
  defaultCorporateAccountId,
  minCheckIn,
  defaultCheckIn,
  defaultCheckOut,
  defaultQuantity,
  status,
  error,
  onStepComplete,
  readyForCheckAvailability = true,
}) => {
  const hideLocation = defaultPropertyId && hideLocationForPropertyId;

  const { register, getValues, watch, setValue, errors, triggerValidation } = useFormContext();

  useEffect(() => {
    const validate = (): boolean => {
      const { checkInDate, checkOutDate } = getValues();
      return isBefore(checkInDate, checkOutDate) || !checkInDate || !checkOutDate;
    };

    register({ name: 'corporateAccountId' });
    register({ name: 'propertyId' }, { required: true });
    register({ name: 'checkInDate' }, { required: true, validate });
    register({ name: 'checkOutDate' }, { required: true, validate });
    register({ name: 'quantity' }, { required: true, min: 1 });
  }, []);

  const singlePropertyId = properties.length === 1 ? properties[0].id : undefined;

  useEffect(() => {
    if (defaultPropertyId || singlePropertyId) {
      setValue('propertyId', defaultPropertyId ?? singlePropertyId);
    }
  }, [defaultPropertyId, singlePropertyId]);

  useEffect(() => {
    setValue('checkInDate', checkInDate ?? defaultCheckIn);
  }, [defaultCheckIn]);

  useEffect(() => {
    setValue('checkOutDate', checkOutDate ?? defaultCheckOut);
  }, [defaultCheckOut]);

  useEffect(() => {
    setValue('quantity', quantity ?? defaultQuantity ?? 1);
  }, [defaultQuantity]);

  useEffect(() => {
    setValue('corporateAccountId', corporateAccountId ?? defaultCorporateAccountId);
  }, [defaultCorporateAccountId]);

  const { propertyId, checkInDate, checkOutDate, quantity, corporateAccountId } = watch();

  const submitStep = async () => {
    if (await triggerValidation(['propertyId', 'checkInDate', 'checkOutDate', 'quantity'])) {
      onStepComplete({ propertyId, checkInDate, checkOutDate, quantity, corporateAccountId });
    }
  };

  const minCheckOutDate = useMemo(
    () => (!!checkInDate ? addDays(checkInDate, 1) : startOfTomorrow()),
    [checkInDate]
  );

  const setPropertyId = useCallback(
    (id?: string) => {
      setValue('propertyId', id);
    },
    [setValue, roomRateService]
  );

  const setCorporateAccountId = useCallback(
    (id?: string) => {
      setValue('corporateAccountId', id);
    },
    [setValue, roomRateService]
  );

  const setCheckInDate = useCallback(
    (date?: Date) => {
      setValue('checkInDate', date);
      if (date && date >= checkOutDate) {
        // clear checkout date if it's invalid
        setValue('checkOutDate', undefined);
      }
    },
    [setValue, checkOutDate, roomRateService]
  );

  const setCheckOutDate = useCallback(
    (date?: Date) => {
      setValue('checkOutDate', date);
    },
    [setValue, roomRateService]
  );

  const setQuantity = useCallback(
    (quantity: string) => {
      setValue('quantity', parseInt(quantity));
    },
    [setValue]
  );

  const getAnalytics = () => {
    const { checkInDate, checkOutDate, propertyId } = getValues();
    const location = properties.filter(p => p.id === propertyId)[0]?.name ?? undefined;

    return {
      checkInDate: checkInDate ? format(checkInDate, 'MM/dd/yyyy') : undefined,
      checkOutDate: checkOutDate ? format(checkOutDate, 'MM/dd/yyyy') : undefined,
      location,
    };
  };

  return (
    <div className={className}>
      <Analytics {...getAnalytics()} />
      <Box mb={2}>
        <Grid container spacing={itemSpacing} alignItems="center">
          {corporateAccounts.length > 1 && (
            <Grid item lg={12} xs={12}>
              <FormTextField
                name="corporateAccountId"
                className={itemClass}
                color="primary"
                label="Select Company"
                value={corporateAccountId ?? ''}
                onChange={e => setCorporateAccountId(e.target.value)}
                select
                SelectProps={{
                  IconComponent: (props: any) => (
                    <FontAwesomeIcon
                      icon="chevron-down"
                      transform="down-6 left-4"
                      color={iconColor}
                      fixedWidth
                      {...props}
                    />
                  ),
                }}
                size="small"
                fullWidth
                controlled
              >
                {corporateAccounts.map(x => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </FormTextField>
            </Grid>
          )}

          {!hideLocation && (
            <Grid item md={3} xs={6}>
              <FormTextField
                name="propertyId"
                className={itemClass}
                color="primary"
                label="Select Location"
                value={propertyId ?? ''}
                onChange={e => setPropertyId(e.target.value)}
                select
                SelectProps={{
                  IconComponent: (props: any) => (
                    <FontAwesomeIcon
                      icon="chevron-down"
                      transform="down-6 left-4"
                      color={iconColor}
                      fixedWidth
                      {...props}
                    />
                  ),
                }}
                size="small"
                fullWidth
                controlled
                data-testid="locationPropertySelector"
              >
                {properties.map(x => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}, {x.location.state}
                  </MenuItem>
                ))}
              </FormTextField>
            </Grid>
          )}
          <Grid item md={hideLocation ? 3 : 2} xs={6}>
            <FormTextField
              name="quantity"
              className={itemClass}
              color="primary"
              label="# of Rooms"
              value={quantity ?? '1'}
              onChange={e => setQuantity(e.target.value)}
              select
              SelectProps={{
                IconComponent: (props: any) => (
                  <FontAwesomeIcon
                    icon="chevron-down"
                    transform="down-6 left-4"
                    color={iconColor}
                    fixedWidth
                    {...props}
                  />
                ),
              }}
              size="small"
              fullWidth
              controlled
              data-testid="locationQuantityInput"
            >
              {[...Array(25)].map((x, i) => (
                <MenuItem key={i} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </FormTextField>
          </Grid>
          <Grid item md={hideLocation ? 3 : 2} xs={6}>
            <DatePicker
              autoOk
              disableToolbar
              fullWidth
              variant="inline"
              format="MMMM d"
              label="Check-In Date"
              name="checkInDate"
              className={itemClass}
              value={checkInDate ?? null}
              minDate={minCheckIn}
              error={!!errors.checkInDate}
              onChange={value => setCheckInDate(value ? startOfDay(value) : undefined)}
              color="primary"
              size="small"
              InputProps={{
                endAdornment: <FontAwesomeIcon icon="calendar-week" color={iconColor} />,
              }}
              data-testid="locationCheckInDateSelector"
            />
          </Grid>
          <Grid item md={hideLocation ? 3 : 2} xs={6}>
            <DatePicker
              autoOk
              disableToolbar
              disablePast
              fullWidth
              variant="inline"
              format="MMMM d"
              label="Check-Out Date"
              name="checkOutDate"
              className={itemClass}
              value={checkOutDate ?? null}
              minDate={minCheckOutDate}
              error={!!errors.checkOutDate}
              onChange={value => setCheckOutDate(value ? startOfDay(value) : undefined)}
              color="primary"
              size="small"
              InputProps={{
                endAdornment: <FontAwesomeIcon icon="calendar-week" color={iconColor} />,
              }}
              data-testid="locationCheckOutDateSelector"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Button
              type="button"
              color="secondary"
              variant="contained"
              fullWidth
              onClick={submitStep}
              disabled={status === FormStatus.Pending || !readyForCheckAvailability}
              name="checkAvailability"
              data-testid="locationCheckAvailabilityButton"
            >
              Check Availability
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Feedback
        show={errors.checkInDate?.type === 'validate' || errors.checkOutDate?.type === 'validate'}
        severity="error"
      >
        Check-out must be after check-in
      </Feedback>
      <ErrorDisplay error={error} />
    </div>
  );
};
