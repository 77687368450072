import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import {
  faCalendarAlt,
  faFilter,
  faQrcode,
  faSearch as farSearch,
  faBed as farBed,
  faUser as farUser,
  faSquare,
  faKey as farKey,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAddressCard,
  faBarcode,
  faBars,
  faBed,
  faBuilding,
  faCalendarCheck,
  faCalendarTimes,
  faCalendarWeek,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCloudUploadAlt,
  faDownload,
  faEnvelope,
  faExclamationCircle,
  faFile,
  faFileInvoiceDollar,
  faFileSpreadsheet,
  faFireplace,
  faHotel,
  faInfoCircle,
  faKey,
  faMoonStars,
  faPhone,
  faPrint,
  faReceipt,
  faSearch as fasSearch,
  faSignIn,
  faSignInAlt,
  faSignOut,
  faSignOutAlt,
  faTape,
  faTimes,
  faTimesCircle,
  faUser,
  faUsers,
  faUserCircle,
  faVacuum,
  faCamera,
  faCogs,
  faCashRegister,
  faPlus,
  faPencilAlt,
  faCrown,
} from '@fortawesome/pro-solid-svg-icons';

export function registerIcons() {
  library.add(
    /* Brand Icons */
    faFacebookF as IconDefinition,
    faTwitter as IconDefinition,
    faInstagram as IconDefinition,
    /* Light Icons */
    faCreditCard as IconDefinition,
    /* Regular Icons */
    faAddressCard as IconDefinition,
    faCalendarAlt as IconDefinition,
    faFilter as IconDefinition,
    faQrcode as IconDefinition,
    farSearch as IconDefinition,
    farBed as IconDefinition,
    farUser as IconDefinition,
    farKey as IconDefinition,
    /* Solid Icons */
    faBarcode as IconDefinition,
    faBars as IconDefinition,
    faBed as IconDefinition,
    faBuilding as IconDefinition,
    faCalendarCheck as IconDefinition,
    faCalendarTimes as IconDefinition,
    faCalendarWeek as IconDefinition,
    faCheck as IconDefinition,
    faCheckCircle as IconDefinition,
    faChevronDoubleRight as IconDefinition,
    faChevronDown as IconDefinition,
    faChevronLeft as IconDefinition,
    faChevronRight as IconDefinition,
    faChevronUp as IconDefinition,
    faCloudUploadAlt as IconDefinition,
    faDownload as IconDefinition,
    faEnvelope as IconDefinition,
    faExclamationCircle as IconDefinition,
    faHotel as IconDefinition,
    faInfoCircle as IconDefinition,
    faKey as IconDefinition,
    faMoonStars as IconDefinition,
    faPhone as IconDefinition,
    faPrint as IconDefinition,
    faReceipt as IconDefinition,
    fasSearch as IconDefinition,
    faSignIn as IconDefinition,
    faSignInAlt as IconDefinition,
    faSignOut as IconDefinition,
    faSignOutAlt as IconDefinition,
    faTape as IconDefinition,
    faTimes as IconDefinition,
    faTimesCircle as IconDefinition,
    faUser as IconDefinition,
    faFireplace as IconDefinition,
    faUsers as IconDefinition,
    faUserCircle as IconDefinition,
    faFileInvoiceDollar as IconDefinition,
    faFile as IconDefinition,
    faVacuum as IconDefinition,
    faCheckSquare as IconDefinition,
    faSquare as IconDefinition,
    faFileSpreadsheet as IconDefinition,
    faCamera as IconDefinition,
    faCogs as IconDefinition,
    faCashRegister as IconDefinition,
    faPlus as IconDefinition,
    faPencilAlt as IconDefinition,
    faCrown as IconDefinition
  );
}
