import { Order, QueryEntity } from '@datorama/akita';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import isEqual from 'lodash.isequal';

import { sortByName } from '../utils';
import { CorporateEmployee } from './corporate-employee.model';
import {
  CorporateEmployeeState,
  corporateEmployeeStore,
  CorporateEmployeeStore,
} from './corporate-employee.store';

export class CorporateEmployeeQuery extends QueryEntity<CorporateEmployeeState> {
  constructor(protected store: CorporateEmployeeStore) {
    super(store);
  }

  loading = this.selectLoading();

  activeEmployee = this.selectActive().pipe(distinctUntilChanged<CorporateEmployee>(isEqual));

  pagination = this.select(({ pagination }) => pagination);

  filters = this.select(({ ui }) => ui);

  searchResults = this.filters.pipe(
    switchMap(ui =>
      this.selectAll({
        filterBy: [
          x => x.corporateAccountId == ui.accountId,
          x =>
            !ui.employeeNumber ||
            x.employeeNumber.toLowerCase().startsWith(ui.employeeNumber.toLowerCase()),
        ],
        sortBy: (a, b) => sortByName(a.name, b.name),
        sortByOrder: Order.DESC,
      })
    )
  );
}

export const corporateEmployeeQuery = new CorporateEmployeeQuery(corporateEmployeeStore);
