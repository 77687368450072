import { useEffect } from 'react';

import { useObservable } from '@lib/common';
import { corporateAccountQuery, corporateAccountService } from 'app/state';

export function useCorporateAccountDelegates(id?: string) {
  useEffect(() => {
    corporateAccountService.selectCorporateAccount(!!id ? id : undefined);

    if (!id) return;

    corporateAccountService.getAccountDelegates(id);

    return () => corporateAccountService.selectCorporateAccount();
    // eslint-disable-next-line
  }, [id]);

  const delegates = useObservable(corporateAccountQuery.activeAccountDelegates);
  return delegates ?? [];
}
