import React from 'react';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';
import { QRCodeListPage } from './pages';

export const QRCodeRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}`} component={QRCodeListPage} />
    <Redirect to={match.path} />
  </Switch>
);
