import { Properties, Reservations, PointOfSale } from '@lib/state';
export type InHouseGuest = Properties.InHouseGuestModel;
export type RoomOccupancy = Reservations.OccupancyReportModel;
export type PurchaseTransaction = PointOfSale.ProductTransactionModel;

export type OccupancyReportType = {
  type: 'OccupancyReport';
  data: RoomOccupancy[];
};

export type InHouseGuestReportType = {
  type: 'InHouseGuestReport';
  data: InHouseGuest[];
};

export type PurchaseTransactionReportType = {
  type: 'PurchaseTransactionReport';
  data: PurchaseTransaction[];
};

export type ReportType =
  | InHouseGuestReportType
  | OccupancyReportType
  | PurchaseTransactionReportType;

export function appendData<TReport extends ReportType>(
  report: ReportType,
  type: TReport['type'],
  data: TReport['data']
) {
  if (!report || report.type !== type)
    return {
      type,
      data,
    };

  return {
    type,
    data: [...report.data, ...data],
  };
}
