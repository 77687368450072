import React, { useEffect } from 'react';
import { TextField, Grid, Typography, Link, Button } from '@material-ui/core';
import {
  useForm,
  useInput,
  required,
  matches,
  FormProps,
  ErrorDisplay,
  SubmitButton,
  Feedback,
} from '../../forms';

interface Props extends FormProps<ResetPasswordFormFields> {
  onRedirectToSignIn: () => void;
}

export interface ResetPasswordFormFields {
  password: string;
}

export const ResetPasswordForm: React.FC<Props> = ({
  pending,
  success,
  error,
  onSubmit,
  onResetFeedback,
  onRedirectToSignIn,
}) => {
  const password = useInput('', { validators: required() });
  const confirmPassword = useInput('', {
    validators: [
      required(),
      matches(password, 'confirmed password and new password do not match.'),
    ],
  });

  const form = useForm(
    () => {
      onResetFeedback();
      onSubmit({
        password: password.value,
      });
    },
    password,
    confirmPassword
  );

  useEffect(() => {
    if (success || error) form.reset();
  }, [success, error, form]);

  return (
    <form {...form.bind}>
      <Feedback show={!!success} severity="success" onHide={onResetFeedback}>
        <Typography align="center">
          Your password has been updated successfully.
          <Link component="button" variant="body2" onClick={onRedirectToSignIn}>
            Click here to sign in.
          </Link>
        </Typography>
      </Feedback>
      <ErrorDisplay error={error} />
      {!success && (
        <>
          <TextField
            name="password"
            label="Password"
            value={password}
            {...password.bind}
            autoComplete="password"
            type="password"
            fullWidth
            style={{ marginBottom: 5 }}
          />
          <TextField
            name="confirmPassword"
            label="Confirm Password"
            {...confirmPassword.bind}
            type="password"
            fullWidth
            style={{ marginBottom: 5 }}
          />
          <Grid container spacing={4}>
            <Grid item xs>
              <SubmitButton
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                disabled={!form.valid}
              >
                Submit
              </SubmitButton>
            </Grid>
            <Grid item xs>
              <Button onClick={onRedirectToSignIn} variant="outlined" size="large" fullWidth>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </form>
  );
};
