import { Properties } from '../api';

export type QRCode = Properties.QRCodeModel;

export type CreateQRCodeRequest = Properties.CreateQRCodeRequest;
export type UpdateQRCodeRequest = Properties.UpdateQRCodeRequest;
export type SubmitGuestFeedbackRequest = Properties.SubmitGuestFeedbackRequest;

export type QRCodeFilter = {
  propertyId?: string | null;
  type?: QRCodeType | null;
  name?: string | null;
  status?: QRCodeStatus | null;
  roomNumber?: string | null;
  location?: string | null;
  qrCodeNumber?: string | null;
};

export type QRCodeType = Properties.QrCodeType;
export const QRCodeType = Properties.QrCodeType;
export type QRCodeStatus = Properties.QRCodeStatus;
export const QRCodeStatus = Properties.QRCodeStatus;

export type QRCodeFormFeilds = {
  id: string;
  propertyId: string;
  type: QRCodeType;
  name: string;
  roomId?: string | null;
  location: string;
  status: QRCodeStatus;
};

//Use Enum value display
