import React from 'react';
import { Typography, makeStyles, createStyles } from '@material-ui/core';
import { CurrencyDisplay, PercentDisplay, DataTable } from '@lib/common';
import { RoomOccupancy } from 'app/state';

const useStyles = makeStyles(theme =>
  createStyles({
    gridContainer: {
      marginTop: theme.spacing(1),
    },
  })
);

interface Props {
  occupancies: RoomOccupancy[];
}

export const OccupancyTable: React.FC<Props> = ({ occupancies }) => {
  const styles = useStyles();
  if (occupancies.length === 0) {
    return <Typography>No occupancies found.</Typography>;
  }

  return (
    <DataTable
      items={occupancies}
      getItemKey={i => i.date}
      columns={[
        {
          title: `Date`,
          valueFactory: item => item.date,
        },
        {
          title: 'Total Rooms',
          valueFactory: item => item.totalRooms,
        },
        {
          title: 'Out Of Service',
          valueFactory: item => item.outOfService,
        },
        {
          title: 'Available Rooms',
          valueFactory: item => item.availableRooms,
        },
        {
          title: 'Occupied Rooms',
          valueFactory: item => item.occupied,
        },
        {
          title: 'No Show Guests',
          valueFactory: item => item.noShow,
        },
        {
          title: 'Room OCC %',
          valueFactory: item => <PercentDisplay value={item.occupancyPercentage} fixedPoint={2} />,
        },
        {
          title: 'Total Revenue',
          align: 'right',
          valueFactory: item => <CurrencyDisplay value={item.totalRevenue} accounting />,
        },
        {
          title: 'ADR',
          align: 'right',
          valueFactory: item => <CurrencyDisplay value={item.averageDailyRate} accounting />,
        },
        {
          title: 'RevPAR',
          align: 'right',
          valueFactory: item => <CurrencyDisplay value={item.revPar} accounting />,
        },
      ]}
      tableClass={styles.gridContainer}
    />
  );
};
