import { Loading, ResponsiveContainer, useObservable, usePageTitle } from '@lib/common';
import { sessionQuery } from '@lib/state';
import { useGetRoomAssignmentsByUser } from '../hooks';
import { CompleteHousekeepingAssignmentForm, MyAssignmentsList } from '../components';
import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';

export function MyAssignmentsPage({ match }: RouteComponentProps<{ roomId: string }>) {
  const user = useObservable(sessionQuery.currentUser);
  const roomId = match.params.roomId;
  usePageTitle('My Assignments');
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const { assignments, loading } = useGetRoomAssignmentsByUser(propertyId ?? '', user?.sub ?? '');

  const activeAssignment = useMemo(() => assignments.find(ra => ra.startTime && !ra.endTime), [
    assignments,
  ]);

  if (loading) return <Loading />;

  return (
    <ResponsiveContainer>
      {activeAssignment ? (
        <CompleteHousekeepingAssignmentForm assignment={activeAssignment} />
      ) : (
        <MyAssignmentsList
          assignments={assignments}
          propertyId={propertyId ?? ''}
          roomId={roomId}
        />
      )}
    </ResponsiveContainer>
  );
}
