import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Container } from '@material-ui/core';

import { roomTypeQuery, roomTypeService, propertyQuery } from '@lib/state';
import {
  useObservable,
  Section,
  FormHub,
  useFormEvents,
  usePageTitle,
  usePropertyAmenities,
} from '@lib/common';
import {
  roomRatePlanQuery,
  roomRatePlanService,
  RoomRatePlanForms,
  corporateAccountService,
  corporateAccountQuery,
  RoomRatePlan,
} from 'app/state';
import { prepareFormValues, RatePlanForm, RatePlanFormFields } from '../components';
import { BreadcrumbBackButton } from 'app/shared';

export const CreateRoomRatePlanPage: React.FC<RouteComponentProps> = props => {
  const queryString = props.location.search;
  const params = new URLSearchParams(queryString);
  const corporateAccountId = params.get('corporateAccountId') ?? undefined;

  const [selectedRates, setSelectedRates] = useState<RoomRatePlan[]>([]);

  usePageTitle('New Room Rate');

  const propertyId = useObservable(propertyQuery.activeProperty, 'async')?.id;
  const roomTypes = useObservable(roomTypeQuery.propertyRoomTypes);
  const roomRatePlan = useObservable(roomRatePlanQuery.activeRatePlan, 'async');
  const amenities = usePropertyAmenities(propertyId);
  const corporateAccount = useObservable(corporateAccountQuery.activeAccount);

  useEffect(() => {
    if (propertyId) roomTypeService.getPropertyRoomTypes(propertyId);
  }, [propertyId]);

  useEffect(() => {
    if (corporateAccountId) corporateAccountService.getCorporateAccountById(corporateAccountId);
    return () => corporateAccountService.selectCorporateAccount();
  }, [corporateAccountId]);

  const [{ status, error }, reset] = useFormEvents(RoomRatePlanForms.Create);

  const defaultValues = useMemo(
    () =>
      prepareFormValues(amenities, {
        priority: 10,
        roomTypeId: '',
        name: corporateAccount?.name ?? '',
      }),
    [amenities, corporateAccount]
  );

  const sectionTitle = useMemo(
    () =>
      corporateAccount
        ? `Create Room Rate Plan for ${corporateAccount.name} (Corporate Account)`
        : 'Create Room Rate Plan',
    [corporateAccount]
  );

  if (roomRatePlan != null) return <Redirect to={`/rates/${roomRatePlan.id}`} />;

  if (!propertyId || !defaultValues) return null;

  const onSubmit = (values: RatePlanFormFields) =>
    roomRatePlanService.createPlan(
      {
        propertyId,
        ...values,
        amenities: values.amenities.filter(a => a.available),
        corporateAccountId: corporateAccount?.id,
      },
      selectedRates.map(rate => ({
        id: rate.id,
        propertyId: rate.propertyId,
        roomTypeId: rate.roomTypeId,
      }))
    );
  return (
    <Container maxWidth="lg">
      <BreadcrumbBackButton destination="/rates" />
      <Section title={sectionTitle} textAlign="left">
        <FormHub onSubmit={onSubmit} formOptions={{ defaultValues }}>
          <RatePlanForm
            plan={roomRatePlan}
            roomTypes={roomTypes}
            status={status}
            error={error}
            resetFormUI={reset}
            setSelectedRates={setSelectedRates}
            selectedRates={selectedRates}
            corporateAccount={corporateAccount}
          />
        </FormHub>
      </Section>
    </Container>
  );
};
