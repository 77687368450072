import React, { useEffect, useCallback } from 'react';
import { makeStyles, createStyles, Box } from '@material-ui/core';

import { sessionQuery } from '@lib/state';
import {
  useObservable,
  Section,
  usePageTitle,
  ConfirmedButton,
  AppColors,
  useFormEvents,
} from '@lib/common';
import { BreadcrumbBackButton } from 'app/shared';
import { employeeService, employeeQuery, EmployeeForms } from 'app/state';
import { EmployeeDetailRouteProps } from './../employee-detail-route-props';
import { EmployeeAuditLogComponent, EmployeeInfo } from './../components';

const useStyles = makeStyles(theme =>
  createStyles({
    deleteButton: {
      color: AppColors.Red,
    },
  })
);

export const EmployeeDetailPage: React.FC<EmployeeDetailRouteProps> = ({ history, match }) => {
  const styles = useStyles();

  const employeeId = match.params.employeeId;
  const [{ status, error }] = useFormEvents(EmployeeForms.DeleteEmployee);
  useEffect(() => {
    if (employeeId) {
      employeeService.selectEmployee(employeeId);
    }
  }, [employeeId]);

  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const employee = useObservable(employeeQuery.selectedEmployee, 'async');

  // In case page is refreshed this will re-populate the state
  useEffect(() => {
    if (propertyId && employeeId) {
      employeeService.getEmployeeById(employeeId, propertyId);
    }
  }, [propertyId, employeeId]);

  usePageTitle('Employee Details');

  const onDeleteEmployee = useCallback(() => {
    if (employee) {
      employeeService.deleteEmployee(employee.userId);

      // go back a page
      history.goBack();
    }
  }, [employee, history]);

  const propertyRoleCheckedChanged = (propertyId: string, role: string, isChecked: boolean) => {
    if (employee) {
      // Update property role selection for user
      var roles = employee.propertyRoles;
      if (isChecked) {
        // Add role
        roles = roles.concat({
          propertyId,
          role,
        });
      } else {
        // Remove role
        roles = roles.filter(x => x.role !== role);
      }
      employeeService.updateEmployeeUserRoles(employee.userId, propertyId, roles);
    }
  };

  return (
    <Section>
      <BreadcrumbBackButton destination="/user-management" />
      {employee && propertyId ? (
        <>
          <EmployeeInfo
            employee={employee}
            propertyId={propertyId}
            onRoleChange={propertyRoleCheckedChanged}
          />
          <Box pt={2}>
            <ConfirmedButton
              onConfirm={onDeleteEmployee}
              action="Delete Employee"
              className={styles.deleteButton}
              variant="outlined"
              status={status}
              error={error}
            />
          </Box>
          <EmployeeAuditLogComponent userId={employee.userId} />
        </>
      ) : null}
    </Section>
  );
};
