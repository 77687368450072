import React, { useMemo } from 'react';
import { DataTable, IDataTableColumn } from './data-table';
import { Section } from './section';
import { Box, Paper, Typography, createStyles, makeStyles } from '@material-ui/core';
import { BulkActivityType } from './bulk-change-utils';
import { ReservationStatus } from '@lib/state';

export interface ReservationRow {
  reservationId: string;
  recordNumber: string;
  reservationStatus?: ReservationStatus;
  checkIn?: string;
  checkOut?: string;
  guestFullName: string;
  guestEmail?: string | null;
  billingReference?: string | null;
  crew?: string | null;
  chargesAmount: number;
  chargesTax: number;
  refundsAmount: number;
  refundsTax: number;
}

interface BulkChangeDatesDisplayProps {
  data: ReservationRow[];
  checkInDate?: Date;
  checkOutDate?: Date;
  bulkActivityType?: BulkActivityType;
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
      flex: 1,
      display: 'flex',
      padding: '0 15px !important',
      overflow: 'hidden',
      minWidth: 0,
      alignItems: 'center',
      whiteSpace: 'nowrap',
      backgroundColor: 'pink !important',
    },
  },
  gridContainer: {
    marginTop: theme.spacing(1),
  },
  footer: {
    fontWeight: 'bold',
  },
  dense: {
    lineHeight: 1,
  },
  headerTitleContainer: {
    padding: '0 16px',
  },
  cellContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:hover': {
      overflow: 'visible',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
  },
  tooltip: {
    position: 'relative',
    display: 'inline-block',
    cursor: 'pointer',
    '& .tooltiptext': {
      visibility: 'hidden',
      width: 'max-content',
      backgroundColor: '#555',
      color: '#fff',
      textAlign: 'center',
      padding: '5px',
      borderRadius: '6px',
      position: 'absolute',
      zIndex: '1',
      bottom: '125%',
      left: '50%',
      transform: 'translateX(-50%)',
      opacity: '0',
      transition: 'opacity 0.3s',
    },
    '&:hover .tooltiptext': {
      visibility: 'visible',
      opacity: '1',
    },
  },
}));

export const BulkActivitiesDisplay: React.FC<BulkChangeDatesDisplayProps> = ({
  data,
  checkInDate,
  checkOutDate,
  bulkActivityType = BulkActivityType.ChangeDates,
}) => {
  const isChangeDates = bulkActivityType === BulkActivityType.ChangeDates;
  const styles = useStyles();

  const columns: IDataTableColumn<ReservationRow>[] = useMemo(
    () => [
      {
        title: 'Conf Code',
        valueFactory: (res: ReservationRow) => res.recordNumber,
        headerClass: styles.headerTitleContainer,
        itemClass: styles.cellContent,
      },
      {
        title: 'Status',
        valueFactory: (res: ReservationRow) => res.reservationStatus,
        itemClass: styles.cellContent,
      },
      {
        title: 'Check In',
        valueFactory: (res: ReservationRow) => res.checkIn,
        itemClass: styles.cellContent,
      },
      {
        title: 'Check Out',
        valueFactory: (res: ReservationRow) => res.checkOut,
        itemClass: styles.cellContent,
      },
      {
        title: 'Guest Name',
        valueFactory: (res: ReservationRow) => res.guestFullName,
        itemClass: styles.cellContent,
      },
      {
        title: 'Guest Email',
        valueFactory: (res: ReservationRow) => res.guestEmail,
        itemClass: styles.cellContent,
      },
      {
        title: 'Crew',
        valueFactory: (res: ReservationRow) => res.crew,
        itemClass: styles.cellContent,
      },
      {
        title: 'Charges Amount',
        align: 'right',
        hidden: !isChangeDates,
        valueFactory: (res: ReservationRow) =>
          res.chargesAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
        itemClass: styles.cellContent,
      },
      {
        title: 'Charges Tax',
        align: 'right',
        hidden: !isChangeDates,
        valueFactory: (res: ReservationRow) =>
          res.chargesTax.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
        itemClass: styles.cellContent,
      },
      {
        title: 'Total Charges',
        align: 'right',
        hidden: !isChangeDates,
        valueFactory: (res: ReservationRow) =>
          (res.chargesAmount + res.chargesTax).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          }),
        itemClass: styles.cellContent,
      },
      {
        title: 'Refund Amount',
        align: 'right',
        valueFactory: (res: ReservationRow) =>
          res.refundsAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
        itemClass: styles.cellContent,
      },
      {
        title: 'Refund Tax',
        align: 'right',
        hidden: !isChangeDates,
        valueFactory: (res: ReservationRow) =>
          res.refundsTax.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
        itemClass: styles.cellContent,
      },
      {
        title: 'Total Refunds',
        align: 'right',
        hidden: !isChangeDates,
        valueFactory: (res: ReservationRow) =>
          (res.refundsAmount + res.refundsTax).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          }),
        itemClass: styles.cellContent,
      },
    ],
    [isChangeDates, styles.cellContent, styles.headerTitleContainer]
  );

  const totalRow = useMemo(() => {
    let chargesAmountTotal = 0;
    let chargesTaxTotal = 0;
    let refundsAmountTotal = 0;
    let refundsTaxTotal = 0;
    data.forEach(b => {
      chargesAmountTotal += b.chargesAmount;
      chargesTaxTotal += b.chargesTax;
      refundsAmountTotal += b.refundsAmount;
      refundsTaxTotal += b.refundsTax;
    });
    const result: ReservationRow = {
      reservationId: '',
      recordNumber: 'Total',
      reservationStatus: undefined,
      checkIn: '',
      checkOut: '',
      guestFullName: '',
      guestEmail: '',
      billingReference: '',
      crew: '',
      chargesAmount: chargesAmountTotal,
      chargesTax: chargesTaxTotal,
      refundsAmount: refundsAmountTotal,
      refundsTax: refundsTaxTotal,
    };
    return result;
  }, [data]);

  const dataWithTotalRow = useMemo(() => [...data, totalRow], [data, totalRow]);

  return (
    <>
      {isChangeDates && (
        <Box display="flex">
          {checkInDate && (
            <Box m={1}>
              <Typography variant="overline">
                <strong>New Check-in Date</strong>
              </Typography>
              <Typography variant="body2">{checkInDate.toLocaleDateString()}</Typography>
            </Box>
          )}
          {checkOutDate && (
            <Box m={1}>
              <Typography variant="overline">
                <strong>New Check-out Date</strong>
              </Typography>
              <Typography variant="body2">{checkOutDate.toLocaleDateString()}</Typography>
            </Box>
          )}
        </Box>
      )}
      <Section
        title={
          isChangeDates
            ? 'Reservations for Bulk Change Dates'
            : 'Reservations for Bulk Cancellation'
        }
        variant="contained"
      >
        <Paper square>
          <DataTable
            items={dataWithTotalRow}
            columns={columns}
            getItemKey={(item: any) => item.reservationId}
            stickyHeader
            tableClass={styles.gridContainer}
            rowClass={item => (item.reservationId === 'Total' ? styles.footer : '')}
          />
        </Paper>
      </Section>
    </>
  );
};
