import { useEffect, useMemo } from 'react';
import { useObservable } from './useObservable';
import {
  roomRateQuery,
  reservationQuery,
  roomRateService,
  Reservation,
  ReservationActivity,
  getPromoCode,
} from '@lib/state';
import { parseISO, startOfDay } from 'date-fns';

export function useRoomRates(userId?: string | null, corporateAccountId?: string) {
  const uiState = useObservable(roomRateQuery.uiState);
  const roomRates = useObservable(roomRateQuery.roomRates);
  const checkInDefaultDate = useObservable(reservationQuery.defaultCheckInDate);
  const corporateBooking = useObservable(roomRateQuery.corporateBooking);
  const policy = useObservable(roomRateQuery.policy);
  const propertyId = uiState.propertyId;
  const checkInDate = uiState.checkInDate ?? checkInDefaultDate;
  const checkOutDate = uiState.checkOutDate;
  const promoCode = uiState.promoCode;
  const quantity = uiState.quantity;
  const checkInStartOfDay = checkInDate ? startOfDay(checkInDate) : undefined;
  const checkOutStartOfDay = checkOutDate ? startOfDay(checkOutDate) : undefined;

  useEffect(() => {
    if (propertyId && checkInDate && checkOutDate && quantity)
      roomRateService.getRoomRates(
        propertyId,
        checkInDate,
        checkOutDate,
        quantity,
        promoCode,
        userId || undefined,
        corporateAccountId || undefined
      );
  }, [propertyId, checkInDate, checkOutDate, quantity, promoCode, userId, corporateAccountId]);

  return {
    uiState,
    roomRates,
    checkInStartOfDay,
    checkOutStartOfDay,
    checkInDefaultDate,
    corporateBooking,
    quantity,
    policy,
  };
}

export function useRoomRatesByReservation(
  reservation: Reservation,
  activity: ReservationActivity,
  userId?: string | null,
  corporateAccountId?: string | null
) {
  const uiState = useObservable(roomRateQuery.uiState);
  const roomRates = useObservable(roomRateQuery.roomRates);
  var promoCode = uiState.promoCode;
  const changeBillingRefunds = useObservable(roomRateQuery.changeBillingRefunds);

  // the api accepts promo code now and can override the promo code on the reservation
  // this only will occur in the api if the activity that is passed is 'ChangeBilling'
  // We need to override the default value of the promoCode with the promo code on the reservation
  // this only needs to occur once
  const promo = useMemo(() => getPromoCode(reservation), [reservation.id]);
  useEffect(() => {
    promoCode = promo;
  }, [promo]);

  useEffect(() => {
    roomRateService.getRoomRatesByReservation(
      reservation.propertyId,
      parseISO(reservation.checkInDate),
      parseISO(reservation.checkOutDate),
      reservation.id,
      reservation.rooms[0].roomTypeId,
      activity,
      promoCode,
      userId,
      corporateAccountId
    );
  }, [
    reservation.propertyId,
    reservation.checkInDate,
    reservation.checkOutDate,
    reservation.id,
    reservation.rooms[0].roomTypeId,
    activity,
    promoCode,
    userId,
    corporateAccountId,
  ]);

  return {
    roomRates,
    promoCode,
    changeBillingRefunds,
  };
}
