import React, { useEffect } from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import {
  useForm,
  useInput,
  required,
  FormProps,
  ErrorDisplay,
  SubmitButton,
  Feedback,
  matches,
} from '../../forms';

export interface ChangePasswordFormFields {
  currentPassword: string;
  newPassword: string;
}

interface Props extends FormProps<ChangePasswordFormFields> {
  inputProps?: {};
}

export const ChangePasswordForm: React.FC<Props> = ({
  success,
  error,
  inputProps,
  onSubmit,
  onResetFeedback,
}) => {
  const currentPassword = useInput('', { validators: required() });
  const newPassword = useInput('', {
    validators: required(),
  });
  const confirmPassword = useInput('', {
    validators: [
      required(),
      matches(newPassword, 'confirmed password and new password do not match.'),
    ],
  });

  const form = useForm(
    () => {
      onResetFeedback();
      onSubmit({
        currentPassword: currentPassword.value,
        newPassword: newPassword.value,
      });
    },
    currentPassword,
    newPassword,
    confirmPassword
  );

  useEffect(() => {
    if (success) form.reset();
  }, [success, form]);

  return (
    <form {...form.bind}>
      <Feedback show={!!success} severity="success" onHide={onResetFeedback}>
        <Typography align="center">Your password has been updated successfully.</Typography>
      </Feedback>
      <ErrorDisplay error={error} />
      <TextField
        {...inputProps}
        name="currentPassword"
        label="Current Password"
        {...currentPassword.bind}
        type="password"
        fullWidth
        style={{ marginBottom: 5 }}
      />
      <TextField
        {...inputProps}
        name="newPassword"
        label="New Password"
        {...newPassword.bind}
        type="password"
        fullWidth
        style={{ marginBottom: 5 }}
      />
      <TextField
        {...inputProps}
        name="confirmPassword"
        label="Confirm Password"
        {...confirmPassword.bind}
        type="password"
        fullWidth
        style={{ marginBottom: 5 }}
      />
      <Box mt={1} display="flex" justifyContent="flex-end">
        <SubmitButton
          type="submit"
          variant="contained"
          color="secondary"
          size="small"
          disabled={!form.valid}
        >
          Change
        </SubmitButton>
      </Box>
    </form>
  );
};
