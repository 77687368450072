import React, { useMemo } from 'react';
import { addDays, isPast, parseISO } from 'date-fns';
import {
  Box,
  makeStyles,
  createStyles,
  Typography,
  Grid,
  Paper,
  Divider,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

import { Reservation, Property, ReservationStatus, getDelegate, getGuests } from '@lib/state';
import {
  AddressDisplay,
  CorporateAffiliationDisplay,
  DateDisplay,
  EmailDisplay,
  GuestRelationDisplay,
  NameDisplay,
  PhoneDisplay,
} from '../components';
import {
  CostCenterFieldEditableDisplay,
  SleepScheduleEditableDisplay,
  BillingReferenceEditableDisplay,
} from './forms';
import { RoomDisplay } from './room-display';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      border: ({ highlight }: StyleProps) =>
        highlight ? `2px solid ${theme.palette.secondary.main}` : undefined,
      padding: ({ highlight }: StyleProps) => (highlight ? '0.5rem' : 'calc(0.5rem + 2px)'),
    },
    dense: {
      lineHeight: 1,
    },
  })
);

interface StyleProps {
  highlight?: boolean;
  divider?: boolean;
}

interface Props extends StyleProps {
  reservation: Reservation;
  property?: Property;
  onConfirmationClick?: (reservation: Reservation) => void;
  testIdNumber?: number;
  isSelected?: boolean;
  onSelectionChanged?: (reservation: Reservation) => void;
  showSelectionCheckbox?: boolean;
}

export const ReservationInfo: React.FC<Props> = ({
  reservation,
  property,
  highlight,
  divider,
  children,
  onConfirmationClick,
  testIdNumber,
  isSelected,
  onSelectionChanged,
  showSelectionCheckbox = false,
}) => {
  const styles = useStyles({ highlight });

  const guests = useMemo(() => getGuests(reservation), [reservation]);

  const delegate = useMemo(() => getDelegate(reservation), [reservation]);

  const address = useMemo(() => property?.location ?? reservation.property?.location, [
    reservation,
    property,
  ]);

  const dividers = reservation.rooms.length > 1;

  const { actualCheckIn, actualCheckOut } = reservation;

  const handleSelectionChange = () => {
    if (onSelectionChanged) {
      onSelectionChanged(reservation);
    }
  };

  return (
    <>
      <Paper
        className={styles.paper}
        elevation={highlight ? 3 : 0}
        square
        data-testid={
          testIdNumber !== undefined ? `reservationConfDisplay:${testIdNumber}` : undefined
        }
      >
        {showSelectionCheckbox && (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={isSelected}
                name={`reservation-selected-${reservation.id}`}
                onChange={handleSelectionChange}
              />
            }
            label="Select for Bulk Changes"
          />
        )}
        <AddressDisplay addressVariant="cityState" variant="subtitle1" {...address} />
        <Grid container spacing={1}>
          <Grid item md={3} sm={6} xs={12}>
            <Typography className={styles.dense} variant="overline">
              <strong>Conf Code</strong>
            </Typography>
            {!!onConfirmationClick && (
              <>
                <br />
                <Button
                  className={styles.dense}
                  variant="text"
                  onClick={() => onConfirmationClick(reservation)}
                  data-testid="reservationConfButton"
                >
                  {reservation.recordNumber}
                </Button>
              </>
            )}
            {!onConfirmationClick && (
              <Typography
                className={styles.dense}
                variant="body2"
                data-testid="reservationConfCodeDisplay"
              >
                {reservation.recordNumber}
              </Typography>
            )}
          </Grid>
          {reservation.rooms.map((room, roomGroup) => (
            <React.Fragment key={room.id}>
              {dividers && roomGroup > 0 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
              <Grid item md={3} sm={6} xs={12}>
                <Typography className={styles.dense} variant="overline">
                  {dividers ? <strong>Room {roomGroup + 1}</strong> : <strong>Room</strong>}
                </Typography>
                <Typography className={styles.dense} variant="body2">
                  <RoomDisplay
                    propertyId={reservation.propertyId}
                    roomTypeId={room.roomTypeId}
                    roomId={room.roomId ?? undefined}
                  />
                </Typography>
              </Grid>
              {roomGroup === 0 && (
                <>
                  <Grid item md={3} sm={6} xs={12}>
                    <Typography className={styles.dense} variant="overline">
                      <strong>{!!actualCheckIn ? '' : 'Scheduled '}Check-In</strong>
                    </Typography>
                    <DateDisplay
                      className={styles.dense}
                      variant="body2"
                      color={hasNoShowWarning(reservation) ? 'error' : undefined}
                      date={parseISO(actualCheckIn ?? room.checkInTime)}
                      dateFormat="MM/dd/yyyy h:mm aa"
                      data-testid="scheduledCheckIn"
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <Typography className={styles.dense} variant="overline">
                      <strong>{!!actualCheckOut ? '' : 'Scheduled '}Check-Out</strong>
                    </Typography>
                    <DateDisplay
                      className={styles.dense}
                      variant="body2"
                      date={parseISO(actualCheckOut ?? room.checkOutTime)}
                      dateFormat="MM/dd/yyyy h:mm aa"
                      data-testid="scheduledCheckOut"
                    />
                  </Grid>
                </>
              )}
              {guests &&
                guests
                  .filter(({ roomGroup: r }) => roomGroup === r)
                  .map((guest, guestIndex) => (
                    <Grid
                      key={guestIndex}
                      item
                      container
                      spacing={1}
                      data-testid={`guestInfoItem:${guestIndex}`}
                    >
                      {dividers && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                      <Grid item md={3} sm={4} xs={12}>
                        <Typography className={styles.dense} variant="overline">
                          <strong>Guest</strong>
                        </Typography>
                        <NameDisplay {...guest.name} className={styles.dense} variant="body2" />
                      </Grid>
                      <Grid item md={3} sm={4} xs={6}>
                        <Typography className={styles.dense} variant="overline">
                          <strong>Email</strong>
                        </Typography>
                        <EmailDisplay
                          email={guest.contact.email}
                          alternateEmail={guest.contact.alternateEmail}
                          className={styles.dense}
                          variant="body2"
                          display="block"
                        />
                      </Grid>
                      <Grid item md={3} sm={4} xs={6}>
                        <Typography className={styles.dense} variant="overline">
                          <strong>Phone</strong>
                        </Typography>
                        <PhoneDisplay
                          phone={guest.contact.phone}
                          className={styles.dense}
                          variant="body2"
                          display="block"
                        />
                      </Grid>
                      {!!guest.relationType && (
                        <Grid item md={3} sm={4} xs={6}>
                          <Typography className={styles.dense} variant="overline">
                            <strong>Relation</strong>
                          </Typography>
                          <GuestRelationDisplay relation={guest.relationType} />
                        </Grid>
                      )}
                    </Grid>
                  ))}
            </React.Fragment>
          ))}

          {delegate && (
            <Grid item container spacing={1} data-testid="delegateInfoDiv">
              {dividers && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
              <Grid item md={3} sm={4} xs={12}>
                <Typography className={styles.dense} variant="overline">
                  <strong>Delegate</strong>
                </Typography>
                <NameDisplay {...delegate.name} className={styles.dense} variant="body2" />
              </Grid>
              <Grid item md={3} sm={4} xs={6}>
                <Typography className={styles.dense} variant="overline">
                  <strong>Email</strong>
                </Typography>
                <EmailDisplay
                  email={delegate.contact.email}
                  alternateEmail={delegate.contact.alternateEmail}
                  className={styles.dense}
                  variant="body2"
                  display="block"
                />
              </Grid>
              <Grid item md={3} sm={4} xs={6}>
                <Typography className={styles.dense} variant="overline">
                  <strong>Phone</strong>
                </Typography>
                <PhoneDisplay
                  phone={delegate.contact.phone}
                  className={styles.dense}
                  variant="body2"
                  display="block"
                />
              </Grid>
            </Grid>
          )}
          {!!reservation.affiliation && (
            <Grid item md={3} sm={4} xs={6}>
              <Typography className={styles.dense} variant="overline">
                <strong>Company</strong>
              </Typography>
              <CorporateAffiliationDisplay
                className={styles.dense}
                variant="body2"
                {...reservation.affiliation}
              />
            </Grid>
          )}
          {reservation.createdByUser && reservation.createdByUserType !== 'System' && (
            <Grid item md={3} sm={4} xs={6}>
              <Typography className={styles.dense} variant="overline">
                <strong>Created By</strong>
              </Typography>
              <NameDisplay
                {...reservation.createdByUser.name}
                className={styles.dense}
                variant="body2"
                abbreviated
              >
                {' '}
                ({reservation.createdByUserType})
              </NameDisplay>
            </Grid>
          )}
          <Grid item md={3} sm={4} xs={6}>
            <BillingReferenceEditableDisplay
              reservation={reservation}
              dense
              labelVariant="overline"
              valueVariant="body2"
            />
          </Grid>
          {reservation.fields.map(field => (
            <Grid key={field.key} item md={3} sm={4} xs={6}>
              <CostCenterFieldEditableDisplay
                reservation={{ id: reservation.id }}
                costCenterField={field}
                dense
                labelVariant="overline"
                valueVariant="body2"
              />
            </Grid>
          ))}
          {reservation.affiliation?.sleepSchedule && (
            <Grid item md={3} sm={4} xs={6}>
              <SleepScheduleEditableDisplay
                reservation={reservation}
                dense
                labelVariant="overline"
                valueVariant="body2"
              />
            </Grid>
          )}
        </Grid>
        {children}
      </Paper>
      {divider && (
        <Box mt={0.5} mb={1}>
          <Divider />
        </Box>
      )}
    </>
  );
};

function hasNoShowWarning(reservation: Reservation) {
  if (!!reservation.actualCheckIn) return false;
  if (reservation.status !== ReservationStatus.Open) return false;

  const secondStayDate = addDays(parseISO(reservation.checkInPeriod.day), 1);
  return isPast(secondStayDate);
}
