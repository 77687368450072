import React from 'react';
import { Typography, makeStyles, createStyles } from '@material-ui/core';
import { CurrencyDisplay, DateDisplay, Section, AppColors, DataTable, IDataTableColumn } from '@lib/common';
import { parseISO } from 'date-fns';
import { AccountTotals, JournalEntry } from 'app/state';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme =>
  createStyles({
    totalsRow: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: AppColors.LightBlue,
      },
    },
    selectedRow: {
      backgroundColor: `${AppColors.LightBlue} !important`,
    },
  })
);

interface Props {
  entries: JournalEntry[];
  totals: AccountTotals[];
  accountFilter: string[];
  toggleAccountFilter: (account: string) => void;
  showBalances: boolean;
}

export const LedgerTable: React.FC<Props> = ({
  entries,
  totals,
  accountFilter,
  toggleAccountFilter,
  showBalances,
}) => {
  const styles = useStyles();

  const balances: IDataTableColumn<AccountTotals>[] = [
    {
      title: 'Starting Balance',
      align: 'right',
      valueFactory: row => <CurrencyDisplay value={row.startingBalance} accounting />,
    },
    {
      title: 'Ending Balance',
      align: 'right',
      valueFactory: row => <CurrencyDisplay value={row.endingBalance} accounting />,
    },
  ];

  return (
    <>
      <Section title="Summary" disableGutters>
        {!showBalances && (
          <Alert severity="info">
            <Typography>
              Account balances are not available without both a start and end date.
            </Typography>
          </Alert>
        )}
        <DataTable
          items={totals}
          getItemKey={row => `${row.account}-${row.subAccount}`}
          rowClass={row =>
            `${styles.totalsRow} ${accountFilter.includes(row.account) ? styles.selectedRow : null}`
          }
          columns={[
            {
              title: 'Account',
              valueFactory: ({ account, label }) => (label ? `${account} - ${label}` : account),
            },
            {
              title: 'SubAccount',
              valueFactory: row => row.subAccount,
            },
            ...(showBalances ? balances : []),
          ]}
          onRowClick={row => toggleAccountFilter(row.account)}
        />
      </Section>
      <Section title="Entries" disableGutters>
        <DataTable items={entries} getItemKey={row => row.id} columns={entriesColumns} />
      </Section>
    </>
  );
};

const entriesColumns: IDataTableColumn<JournalEntry>[] = [
  {
    title: 'Timestamp',
    valueFactory: row => (
      <DateDisplay date={parseISO(row.timestamp)} dateFormat="MM/dd/yyyy h:mm a" />
    ),
  },
  {
    title: 'Reference',
    valueFactory: row => row.reference,
  },
  {
    title: 'Company',
    valueFactory: row => row.company,
  },
  {
    title: 'Account',
    valueFactory: row => row.account,
  },
  {
    title: 'SubAccount',
    valueFactory: row => row.subAccount,
  },
  {
    title: 'Amount',
    align: 'right',
    valueFactory: row => <CurrencyDisplay value={row.amount} accounting />,
  },
  {
    title: 'Description',
    valueFactory: row => row.description,
    colSpan: 2,
  },
  {
    title: 'Invoice',
    valueFactory: row => row.invoice,
  },
];
