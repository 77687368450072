import React, { ReactNode } from 'react';
import { Box, Typography, TypographyProps } from '@material-ui/core';
import { CurrencyDisplay } from './currency-display';

interface Props extends TypographyProps {
  label: string | ReactNode;
  amount?: number;
  addon?: boolean;
  accounting?: boolean;
}

export const LineItemDisplay: React.FC<Props> = ({ label, amount, children, ...props }) => (
  <Box display="flex" justifyContent="space-between">
    <Box pr={2}>
      {typeof label === 'string' ? <Typography {...props}>{label}</Typography> : label}
    </Box>
    <CurrencyDisplay value={amount} {...props}>
      {children}
    </CurrencyDisplay>
  </Box>
);
