import React, { ReactNode } from 'react';
import { useFormContext, ValidationOptions } from 'react-hook-form';
import TextField, {
  TextFieldProps as MuiTextFieldProps,
  StandardTextFieldProps,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
} from '@material-ui/core/TextField';

import { autoCompleteIfEnabled } from '../utils';
import { getHelperText, getInputRef, useFieldError } from './form-methods';

type OmittedProps = 'helperText';

export type TextFieldProps<TOmit extends keyof MuiTextFieldProps = never> =
  | Omit<StandardTextFieldProps, OmittedProps | TOmit>
  | Omit<FilledTextFieldProps, OmittedProps | TOmit>
  | Omit<OutlinedTextFieldProps, OmittedProps | TOmit>;

export interface FormTextFieldProps {
  name: string;
  validationOptions?:
    | Partial<ValidationOptions>
    | ((values: Record<string, any>) => Partial<ValidationOptions>);
  helperText?: ReactNode | ((errors: any) => string);
  controlled?: boolean;
}

export const FormTextField: React.FC<FormTextFieldProps & TextFieldProps> = ({
  name,
  autoComplete,
  helperText,
  validationOptions,
  controlled,
  error,
  ...props
}) => {
  const { register, errors, getValues } = useFormContext();

  const fieldError = useFieldError(name, errors);
  const text = getHelperText(errors, helperText ?? fieldError?.message);

  const inputRef = !controlled ? getInputRef(register, getValues(), validationOptions) : undefined;

  return (
    <TextField
      {...props}
      name={name}
      autoComplete={autoCompleteIfEnabled(autoComplete)}
      error={error || !!fieldError}
      helperText={text}
      inputRef={inputRef}
    />
  );
};
