// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ReservationStatus {
  Open = 'Open',
  CheckedIn = 'CheckedIn',
  Canceled = 'Canceled',
  NoShow = 'NoShow',
  CheckedOut = 'CheckedOut',
}
