import React from 'react';
import { Name } from '@lib/state';
import { AutocompleteField, AutocompleteOption } from './autocomplete-field';
import { AppColors } from '@lib/common';

export interface IEmployeeSelectOption {
  name: Name;
  userId: string;
}

export interface EmployeeSelectProps {
  options: IEmployeeSelectOption[];
  placeholder?: string;
  onEmployeeSelect: (userId?: string) => void;
  iconColor?: AppColors;
  variant?: 'outlined' | 'filled';
  resetKey?: string;
}

export const EmployeeSelect: React.FC<EmployeeSelectProps> = ({
  options,
  placeholder,
  onEmployeeSelect,
  iconColor,
  variant,
  resetKey,
}) => {
  return (
    <AutocompleteField
      options={options.map(m => {
        return {
          value: m.userId,
          label: `${m.name.last}, ${m.name.first}`,
        } as AutocompleteOption;
      })}
      placeholder={placeholder}
      onChangeHandler={(newValue: AutocompleteOption | null) => {
        onEmployeeSelect(newValue?.value);
      }}
      iconColor={iconColor}
      variant={variant}
      resetKey={resetKey}
    />
  );
};
