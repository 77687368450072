import { DateDisplay } from '@lib/common';
import { Room, roomsService } from '@lib/state';
import { Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';

interface Props {
  room: Room;
}

export function RoomAccessControlTab({ room }: Props) {
  useEffect(() => {
    if (room) {
      roomsService.getRoomDeviceDetails(room);
    }
    // eslint-disable-next-line
  }, [room?.id]);

  const doorController = room.doorController;

  return (
    <>
      {doorController && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textPrimary">
              Battery Level
            </Typography>
            <Typography>{doorController.batteryLevel}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textPrimary">
              Battery Status
            </Typography>
            <Typography>{doorController.batteryStatus}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textPrimary">
              Access Point
            </Typography>
            <Typography>{doorController.accessPoint}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textPrimary">
              Mac Address
            </Typography>
            <Typography>{doorController.macAddress}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textPrimary">
              Last Seen
            </Typography>
            <DeviceDateDisplay dateString={doorController.lastSeen ?? ''} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textPrimary">
              Last Offline
            </Typography>
            <DeviceDateDisplay dateString={doorController.lastOffline ?? ''} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textPrimary">
              Last Online
            </Typography>
            <DeviceDateDisplay dateString={doorController.lastOnline ?? ''} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textPrimary">
              Last Low Battery
            </Typography>
            <DeviceDateDisplay dateString={doorController.lastBatteryLow ?? ''} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textPrimary">
              Last Power Loss
            </Typography>
            <DeviceDateDisplay dateString={doorController.lastPowerLoss ?? ''} />
          </Grid>
        </Grid>
      )}
    </>
  );
}

interface DeviceDateDisplayProps {
  dateString: string;
}

function DeviceDateDisplay({ dateString }: DeviceDateDisplayProps) {
  const date = new Date(dateString);
  if (date.getMilliseconds() === 0) return <Typography>None</Typography>;

  return <DateDisplay date={date} dateFormat="M/d/yy h:mm a" />;
}
