import React, { useCallback, useEffect, useState } from 'react';
import { DialogContent, ButtonProps, Button } from '@material-ui/core';
import { lightFormat } from 'date-fns';
import {
  Reservation,
  reservationService,
  Name,
  Address,
  roomRateQuery,
  roomRateService,
  ReservationForms,
  FormStatus,
  RoomRateForms,
  FormEvent,
  CheckReservationChangeableState,
  ReservationActivity,
} from '@lib/state';
import { StyledDialog } from '../components';
import { FormHub, useFormEvents } from '../forms';
import { EditReservationForm } from './forms';
import {
  useObservable,
  getAcceptCardData,
  useLookup,
  useReservationPaymentMethods,
} from '../utils';

interface FormFields {
  checkInDate: Date;
  checkOutDate: Date;
  billing: {
    name: Name;
    address: Address;
  };
  card?: {
    cardNumber: string;
    expiryDate: string;
    cvc: string;
  };
  overrideCode?: number;
  savePaymentMethod?: boolean;
  paymentProfileId: string;
}

interface Props extends Omit<ButtonProps, 'action' | 'onClick'> {
  reservation: Reservation;
  isManagerOverrideRequired?: boolean;
  onSuccess?: () => void;
}

const EditReservationButtonComponent: React.FC<Props> = ({
  reservation,
  isManagerOverrideRequired = false,
  onSuccess,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [{ status, error }, resetUpdateReservationForm] = useFormEvents(
    ReservationForms.UpdateReservation
  );

  const closeDialog = () => {
    roomRateService.resetUI();
    roomRateService.clearUpdateCost();
    resetUpdateReservationForm();
    setOpen(false);
  };

  useEffect(() => {
    if (status === FormStatus.Success) {
      closeDialog();
      if (onSuccess) onSuccess();
    }
  }, [status]);

  const allowed = CheckReservationChangeableState(reservation);

  if (!allowed) return null;

  return (
    <>
      <Button type="button" name="editReservation" onClick={() => setOpen(true)} {...props}>
        Change Dates
      </Button>
      <StyledDialog title="Edit Stay" open={open} onClose={() => closeDialog()}>
        <DialogContent>
          <EditReservationFormContainer
            reservation={reservation}
            status={status}
            error={error}
            isManagerOverrideRequired={isManagerOverrideRequired}
          />
        </DialogContent>
      </StyledDialog>
    </>
  );
};

const EditReservationFormContainer: React.FC<
  { reservation: Reservation; isManagerOverrideRequired?: boolean } & Pick<
    FormEvent,
    'status' | 'error'
  >
> = ({ reservation, status, error, isManagerOverrideRequired = false }) => {
  const [{ error: availabilityError, status: availabilityStatus }] = useFormEvents(
    RoomRateForms.Availability
  );

  const { paymentMethods, guest } = useReservationPaymentMethods(reservation);
  const roomRates = useObservable(roomRateQuery.roomRates, 'async');
  const updateCost = useObservable(roomRateQuery.updateCost, 'async');
  const roomRateLookup = useLookup(roomRates, x => x.roomTypeId);

  const onDatesChanged = useCallback(
    (checkInDate: Date, checkOutDate: Date) => {
      if (reservation.propertyId && reservation.id) {
        roomRateService.getRoomRatesByReservation(
          reservation.propertyId,
          checkInDate,
          checkOutDate,
          reservation.id,
          reservation.rooms[0].roomTypeId,
          ReservationActivity.ModifyReservation
        );
      }
    },
    [reservation.propertyId, reservation.id]
  );

  const onSubmit = useCallback(
    ({
      checkInDate,
      checkOutDate,
      billing,
      savePaymentMethod,
      paymentProfileId,
      card,
      overrideCode,
    }: FormFields) => {
      if (reservation) {
        reservationService.updateReservation(
          reservation,
          checkInDate,
          checkOutDate,
          reservation.rooms.map(x => ({
            id: x.id,
            roomTypeId: x.roomTypeId,
            dailyRates:
              roomRateLookup.get(x.roomTypeId)?.dailyRates.map(x => ({
                ...x,
                date: lightFormat(x.date, 'yyyy-MM-dd'),
              })) ?? [],
          })),
          billing,
          !!savePaymentMethod,
          paymentProfileId,
          getAcceptCardData(card),
          overrideCode
        );
      }
    },
    [reservation, roomRateLookup]
  );

  return (
    <FormHub onSubmit={onSubmit}>
      <EditReservationForm
        reservation={reservation}
        paymentMethods={paymentMethods}
        updateCost={updateCost}
        onDateChange={onDatesChanged}
        status={status}
        error={error}
        availabilityStatus={availabilityStatus}
        availabilityError={availabilityError}
        paymentGuest={guest}
        isManagerOverrideRequired={isManagerOverrideRequired}
      />
    </FormHub>
  );
};

export const EditReservationButton = EditReservationButtonComponent;
