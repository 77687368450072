import React from 'react';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';

import {
  ManagePropertyConfigurationPage,
  ManagerOverrideCodePage,
  PropertyQRCodePage,
} from './pages';
import { RoomListPage } from 'app/room-list/pages/room-list.page';

export const PropertyRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/manager-code`} component={ManagerOverrideCodePage} />
    <Route path={`${match.path}/qr-code`} component={PropertyQRCodePage} />
    <Route path={`${match.path}/configure`} component={ManagePropertyConfigurationPage} />
    <Route path={`${match.path}/rooms`} component={RoomListPage} />
    <Redirect to={match.path} />
  </Switch>
);
