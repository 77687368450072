// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { GetInventoryAdjustmentListResponse } from '../models';
import { ProblemDetails } from '../models';
import { UpdateInventoryAdjustmentRequest } from '../models';
/**
 * InventoryAdjustmentApi - axios parameter creator
 * @export
 */
export const InventoryAdjustmentApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [corporateAccountId]
     * @param {string} [roomTypeId]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryadjustmentGet(
      propertyId: string,
      corporateAccountId?: string,
      roomTypeId?: string,
      startDate?: string,
      endDate?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling inventoryadjustmentGet.'
        );
      }
      const localVarPath = `/inventoryadjustment`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (corporateAccountId !== undefined) {
        localVarQueryParameter['corporateAccountId'] = corporateAccountId;
      }

      if (roomTypeId !== undefined) {
        localVarQueryParameter['roomTypeId'] = roomTypeId;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateInventoryAdjustmentRequest} [updateInventoryAdjustmentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryadjustmentPost(
      updateInventoryAdjustmentRequest?: UpdateInventoryAdjustmentRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/inventoryadjustment`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateInventoryAdjustmentRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            updateInventoryAdjustmentRequest !== undefined ? updateInventoryAdjustmentRequest : {}
          )
        : updateInventoryAdjustmentRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InventoryAdjustmentApi - functional programming interface
 * @export
 */
export const InventoryAdjustmentApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [corporateAccountId]
     * @param {string} [roomTypeId]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryadjustmentGet(
      propertyId: string,
      corporateAccountId?: string,
      roomTypeId?: string,
      startDate?: string,
      endDate?: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetInventoryAdjustmentListResponse> {
      const localVarAxiosArgs = InventoryAdjustmentApiAxiosParamCreator(
        configuration
      ).inventoryadjustmentGet(
        propertyId,
        corporateAccountId,
        roomTypeId,
        startDate,
        endDate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {UpdateInventoryAdjustmentRequest} [updateInventoryAdjustmentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryadjustmentPost(
      updateInventoryAdjustmentRequest?: UpdateInventoryAdjustmentRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = InventoryAdjustmentApiAxiosParamCreator(
        configuration
      ).inventoryadjustmentPost(updateInventoryAdjustmentRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * InventoryAdjustmentApi - factory interface
 * @export
 */
export const InventoryAdjustmentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [corporateAccountId]
     * @param {string} [roomTypeId]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryadjustmentGet(
      propertyId: string,
      corporateAccountId?: string,
      roomTypeId?: string,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<GetInventoryAdjustmentListResponse> {
      return InventoryAdjustmentApiFp(configuration).inventoryadjustmentGet(
        propertyId,
        corporateAccountId,
        roomTypeId,
        startDate,
        endDate,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {UpdateInventoryAdjustmentRequest} [updateInventoryAdjustmentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inventoryadjustmentPost(
      updateInventoryAdjustmentRequest?: UpdateInventoryAdjustmentRequest,
      options?: any
    ): AxiosPromise<void> {
      return InventoryAdjustmentApiFp(configuration).inventoryadjustmentPost(
        updateInventoryAdjustmentRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * InventoryAdjustmentApi - object-oriented interface
 * @export
 * @class InventoryAdjustmentApi
 * @extends {BaseAPI}
 */
export class InventoryAdjustmentApi extends BaseAPI {
  /**
   *
   * @param {string} propertyId
   * @param {string} [corporateAccountId]
   * @param {string} [roomTypeId]
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryAdjustmentApi
   */
  public inventoryadjustmentGet(
    propertyId: string,
    corporateAccountId?: string,
    roomTypeId?: string,
    startDate?: string,
    endDate?: string,
    options?: any
  ) {
    return InventoryAdjustmentApiFp(this.configuration).inventoryadjustmentGet(
      propertyId,
      corporateAccountId,
      roomTypeId,
      startDate,
      endDate,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {UpdateInventoryAdjustmentRequest} [updateInventoryAdjustmentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryAdjustmentApi
   */
  public inventoryadjustmentPost(
    updateInventoryAdjustmentRequest?: UpdateInventoryAdjustmentRequest,
    options?: any
  ) {
    return InventoryAdjustmentApiFp(this.configuration).inventoryadjustmentPost(
      updateInventoryAdjustmentRequest,
      options
    )(this.axios, this.basePath);
  }
}
