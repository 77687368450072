import { ErrorDisplay, FormHub, useFormEvents, useObservable } from '@lib/common';
import { Grid } from '@material-ui/core';
import { MaintenanceTicketJournalForms } from '../../state/maintenance-journals/maintenance-journal.form';
import maintenanceTicketJournalQuery from '../../state/maintenance-journals/maintenance-journal.query';
import maintenanceTicketJournalService from '../../state/maintenance-journals/maintenance-journal.service';
import React, { useCallback, useEffect } from 'react';
import { CreateTicketJournalFormFields, MainternanceJournalForm } from './maintenance-journal-form';
import { MaintenanceJournalList } from './maintenance-journal-list';

interface Props {
  ticketId: string;
  propertyId: string;
}

export const MaintenanceJournal: React.FC<Props> = ({ ticketId, propertyId }) => {
  const journals = useObservable(maintenanceTicketJournalQuery.journals);

  useEffect(() => {
    if (ticketId && propertyId) {
      maintenanceTicketJournalService.getJournalsByTicketId(propertyId, ticketId);
    }
  }, [ticketId, propertyId]);

  const [{ status, error }] = useFormEvents(MaintenanceTicketJournalForms.Create);

  const submit = useCallback(
    ({ description }: CreateTicketJournalFormFields) => {
      maintenanceTicketJournalService.addJournal(description, propertyId, ticketId);
    },
    [ticketId, propertyId]
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormHub onSubmit={submit}>
            <ErrorDisplay error={error} />
            <MainternanceJournalForm status={status} />
          </FormHub>
        </Grid>
        <Grid item xs={12}>
          <MaintenanceJournalList journals={journals.filter(j => j.maintenanceId === ticketId)} />
        </Grid>
      </Grid>
    </>
  );
};
