import { CostCenterFieldStatus } from '@lib/state';
import { CostCenterFieldConfiguration } from '@lib/state/api/generated/reservations';
import { FormCheckbox, FormTextField } from '../../forms';
import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  corporateAccountId?: string;
  config: CostCenterFieldConfiguration;
  isDelegateOrEmployee: boolean;
}

const CostCenterFieldRequired: React.FC<Props> = ({
  name,
  config,
  isDelegateOrEmployee,
  ...props
}) => {
  const [isRequiredOverridden, setIsRequiredOverridden] = useState<boolean>(false);

  const shouldDisplayRequiredCheckbox: boolean = useMemo(() => {
    return isDelegateOrEmployee && config.status === CostCenterFieldStatus.Required;
  }, [isDelegateOrEmployee, config.status]);

  const { setValue } = useFormContext();

  return (
    <>
      <FormTextField
        {...props}
        name={name}
        label={
          config.status === CostCenterFieldStatus.Required
            ? config.label
            : `${config.label} (Optional)`
        }
        type="text"
        fullWidth
        disabled={isRequiredOverridden}
        validationOptions={{
          required: config.status === CostCenterFieldStatus.Required && !isRequiredOverridden,
        }}
        data-testid={`${config.key}Input`}
      />
      {shouldDisplayRequiredCheckbox && (
        <FormCheckbox
          name={`${name}_Override_Required`}
          label="Required billing info missing"
          defaultChecked={isRequiredOverridden}
          onChange={() => {
            setValue(name, '');
            setIsRequiredOverridden(!isRequiredOverridden);
          }}
        />
      )}
    </>
  );
};

export default CostCenterFieldRequired;
