import { from } from 'rxjs';
import { Properties } from '../api';
import { GuestStaysStore, guestStaysStore } from './guest-stays.store';
import { map } from 'rxjs/operators';
import { applyTransaction, setLoading } from '@datorama/akita';

export class GuestStaysService {
  constructor(
    private readonly guestStaysApi: Properties.GuestStaysApi,
    private readonly store: GuestStaysStore
  ) {}

  getGuestStays(propertyId: string, date?: string, reservationId?: string) {
    from(this.guestStaysApi.propertyIdGueststaysGet(propertyId, date, reservationId))
      .pipe(
        setLoading(this.store),
        map(resp => resp.data.data)
      )
      .subscribe(stays => applyTransaction(() => this.store.set({ ...stays })));
  }

  getGuestStayById(propertyId: string, guestStayId: string) {
    from(this.guestStaysApi.propertyIdGueststaysGuestStayIdGet(propertyId, guestStayId))
      .pipe(
        setLoading(this.store),
        map(resp => resp.data.data)
      )
      .subscribe(stay =>
        applyTransaction(() => this.store.upsert(stay.id, stay), this.store.setActive(stay.id))
      );
  }
}

export const guestStaysService = new GuestStaysService(
  new Properties.GuestStaysApi(),
  guestStaysStore
);
