import { useMemo } from 'react';

export function useLookup<T, TKey>(
  collection: Array<T> | undefined,
  keySelector: (item: T) => TKey
) {
  return useMemo(() => new Map<TKey, T>(collection?.map(x => [keySelector(x), x])), [
    collection,
    keySelector,
  ]);
}
