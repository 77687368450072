import React from 'react';
import { responsiveFontSizes, createTheme } from '@material-ui/core';
import { AppColors } from './theme';

export enum PrintColors {
  ViewBackground = '#FFFFFF',
  PrintText = '#2B282D',
}

export const printTheme = responsiveFontSizes(
  createTheme({
    typography: {
      body1: {
        fontSize: '12px',
      },
      body2: {
        fontSize: '10px',
      },
      h5: {
        fontSize: '14px',
      },
    },
    palette: {
      background: {
        default: PrintColors.ViewBackground,
      },
      primary: {
        main: AppColors.LightBlue,
        contrastText: PrintColors.PrintText,
      },
      secondary: {
        main: AppColors.LightBlue,
        contrastText: PrintColors.PrintText,
      },
      text: {
        primary: PrintColors.PrintText,
      },
    },
    tapeChart: {
      headerSpacingTop: '2rem',
      rowHeight: 48,
    },
    loadingIndicator: {
      dotSize: 10,
    },
    shape: {
      borderRadius: 12,
    },
    mixins: {
      toolbar: {
        minHeight: 0,
      },
    },
    overrides: {
      MuiTableCell: {
        head: {
          color: PrintColors.PrintText,
        },
      },
    },
  })
);
