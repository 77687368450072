import { EntityStore, EntityState, StoreConfig } from '@datorama/akita';
import { startOfToday, addDays, Interval } from 'date-fns';

import { RoomCount } from './room-count.model';

export interface RoomCountState extends EntityState<RoomCount> {
  ui: Interval;
}

@StoreConfig({ name: 'room-counts', resettable: true })
export class RoomCountStore extends EntityStore<RoomCountState> {
  constructor() {
    super({
      ui: {
        start: startOfToday(),
        end: addDays(startOfToday(), 14),
      },
    });
  }
}

export const roomCountStore = new RoomCountStore();
