import React, { useMemo } from 'react';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import {
  DataGridPro as DataGrid,
  GridColDef,
  GridSelectionModel,
  GridAlignment,
} from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import { AppColors } from '../theme';
import { useIsPrinting } from './print-context';

export type IDataTableColumn<T> = {
  valueFactory?: (item: T) => React.ReactNode;
  headerClass?: string;
  itemClass?: string;
  sortable?: boolean;
  filterable?: boolean;
  colSpan?: number;
  align?: GridAlignment;
  hidden?: boolean;
  printable?: boolean;
} & ColumnTitle;

type ColumnTitle = { title: string; name?: string } | { title: React.ReactNode; name: string };

export interface IDataTableNonValueColumn {
  value?: React.ReactNode;
  className?: string;
  colSpan?: number;
  align?: GridAlignment;
}

export interface DataTableProps<T> {
  items: T[];
  columns: IDataTableColumn<T>[];
  getItemKey: (item: T) => number | string;
  stickyHeader?: boolean;
  tableClass?: string;
  rowClass?: (item: T) => string;
  headerClass?: string;
  footerClass?: string;
  additionalHeaderColumns?: IDataTableNonValueColumn[];
  zebra?: boolean;
  onRowClick?: (item: T) => void;
  checkboxSelection?: boolean;
  onSelectionModelChange?: (model: GridSelectionModel) => void;
  pagination?: boolean;
  pageSize?: number;
  selectionModel?: any;
  loading?: boolean;
  isRowSelectable?: (params: T, details?: any) => boolean;
}
const useStyles = makeStyles(() => {
  return {
    root: {
      // The following styles are required to allow the data grid cell contents to wrap and have grid rows grow in height
      // this essentially completely disables the grid's automatic sizing behavior until the following github issue is resolved:
      // https://github.com/mui-org/material-ui-x/issues/417
      '& .MuiDataGrid-window, & .MuiDataGrid-columnsContainer': {
        position: 'relative',
        top: '0 !important',
      },
      '& .MuiDataGrid-windowContainer, & .MuiDataGrid-viewport, & .MuiDataGrid-renderingZone, & .MuiDataGrid-row, & .MuiDataGrid-cell': {
        height: 'unset !important',
        maxHeight: 'fit-content !important',
      },
      '& .MuiDataGrid-viewport': {
        minHeight: 56, // an empty grid still needs 1 "row" of height
      },
      '& .MuiDataGrid-dataContainer': {
        minHeight: 'unset !important',
      },
      '& .MuiDataGrid-row': {},
      '& .MuiDataGrid-cell': {
        flexWrap: 'wrap',
        lineHeight: '1rem !important',
        whiteSpace: 'normal',
        paddingTop: 8,
        paddingBottom: 8,
      },
      '& .MuiDataGrid-columnsContainer': {
        lineHeight: '1rem !important',
      },
      '& .MuiDataGrid-columnHeaderWrapper': {
        height: 56,
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        whiteSpace: 'normal',
      },
    },
    zebra: {
      '& .MuiDataGrid-row:nth-child(odd)': {
        backgroundColor: AppColors.LightGray,
      },
    },
  };
});

export function DataTable<T = any>(props: React.PropsWithChildren<DataTableProps<T>>) {
  const {
    items,
    columns,
    getItemKey,
    rowClass,
    checkboxSelection,
    onSelectionModelChange,
    zebra = true,
    children,
    onRowClick,
    loading,
    isRowSelectable,
  } = props;

  const styles = useStyles();
  const gridCols: GridColDef[] = useMemo(
    () =>
      columns.map((x, i) => {
        let gridCol: GridColDef = {
          field: typeof x.title === 'string' ? x.title : x.name!,
          disableColumnMenu: true,
          sortable: x.sortable,
          filterable: x.filterable,
          headerAlign: x.align,
          headerClassName: x.headerClass,
          renderHeader: () => x.title,
          cellClassName: x.itemClass,
          flex: x.colSpan ?? 1,
          align: x.align,
          hide: x.hidden,
          renderCell: !!x.valueFactory ? params => x.valueFactory?.(params.row as T) : undefined,
        };
        return gridCol;
      }),
    [columns]
  );

  const isPrinting = useIsPrinting();

  if (isPrinting) return <PrintableDataTable {...props} />;

  return (
    <DataGrid
      ref={attachCopyBehavior}
      autoHeight
      hideFooterPagination={!props.pagination}
      disableColumnResize
      disableColumnReorder
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      disableMultipleColumnsSorting
      disableMultipleColumnsFiltering
      disableDensitySelector
      disableVirtualization
      selectionModel={props.selectionModel}
      className={clsx(styles.root, zebra && styles.zebra, props.tableClass)}
      columns={gridCols}
      columnBuffer={0}
      disableExtendRowFullWidth={true}
      density="compact"
      components={{ Footer: !props.pagination ? () => <>{children}</> : undefined }}
      checkboxSelection={checkboxSelection}
      isRowSelectable={isRowSelectable && (x => isRowSelectable(x.row as T))}
      rows={items}
      onRowClick={onRowClick && (x => onRowClick(x.row as T))}
      getRowClassName={rowClass && (x => rowClass(x.row as T))}
      onSelectionModelChange={onSelectionModelChange}
      getRowId={row => getItemKey(row as T)}
      pageSize={props.pageSize}
      pagination={props.pagination}
      loading={loading ?? undefined}
    />
  );
}

function PrintableDataTable<T>(props: DataTableProps<T>) {
  const cols = props.columns.filter(col => !col.hidden && col.printable !== false);
  return (
    <TableContainer className={props.tableClass}>
      <Table size="small">
        <TableHead>
          <TableRow className={props.headerClass}>
            {cols.map((col, i) => (
              <TableCell key={i} align={col.align}>
                {col.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((item, r) => (
            <TableRow key={r} className={props.rowClass?.(item)}>
              {cols.map((col, c) => (
                <TableCell key={c} align={col.align}>
                  {col.valueFactory?.(item)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function attachCopyBehavior(node: HTMLDivElement | null) {
  const listener = (e: ClipboardEvent) => {
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);
    const doc = range?.cloneContents();

    if (!doc || !e.clipboardData) return;

    // get the selected rows
    const rows: Array<ParentNode> = Array.from(
      doc.querySelectorAll('.MuiDataGrid-row,.MuiDataGrid-columnsContainer')
    );

    // for a single row, treat the selected range as the row
    if (!rows.length) rows.push(doc);

    // transform mui row/cell divs to tab delimited data
    const tab = rows
      .map(row =>
        Array.from(row.querySelectorAll('.MuiDataGrid-cell,.MuiDataGrid-columnHeader'))
          .map(col => col.textContent)
          .join('\t')
      )
      .join('\n');

    if (tab) {
      // if we have rows selected, use the transformed data
      e.clipboardData.setData('text/plain', tab);
      // prevent the default copy-paste behavior
      e.preventDefault();
    }

    // otherwise, the default copy-paste behavior will proceed
  };

  if (node) {
    node.addEventListener('copy', listener);
  }
}
