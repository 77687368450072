import React, { useEffect, useCallback } from 'react';

import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { sessionQuery } from '@lib/state';
import { Section, useObservable } from '@lib/common';
import { journalEntryQuery, journalEntryService, JournalFilters } from 'app/state';
import { AuditLogs } from 'app/shared';
import { LedgerUploadFormFields } from './ledger-upload.form';
import { LedgerHeader } from './ledger-header';
import { LedgerTable } from './ledger-table';

export const Ledger: React.FC = () => {
  const {
    accounts: accountFilter,
    startDate,
    endDate,
    reference,
    invoice,
    recordNumber,
    recordNumberRange,
    email,
  } = useObservable(journalEntryQuery.filters);

  const showBalances = !!startDate && !!endDate;

  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const journalEntries = useObservable(journalEntryQuery.journalEntries);
  const accountTotals = useObservable(journalEntryQuery.accountTotals);
  const queryLimitWarning = useObservable(journalEntryQuery.queryLimitWarning);

  const filter = useCallback((filters: Partial<JournalFilters>) => {
    journalEntryService.updateFilters(filters);
  }, []);

  useEffect(() => {
    return () => journalEntryService.reset();
  }, []);

  useEffect(() => {
    filter({ propertyId });
  }, [propertyId, filter]);

  const uploadFile = useCallback(({ file }: LedgerUploadFormFields) => {
    if (file) {
      journalEntryService.uploadLedgerData(file);
    }
  }, []);

  const exportLedger = useCallback(() => {
    journalEntryService.exportLedger();
  }, []);

  const toggleFilter = useCallback(
    (account: string) => {
      const accounts = accountFilter.includes(account)
        ? accountFilter.filter(x => x !== account)
        : accountFilter.concat(account);

      filter({ accounts });
    },
    [accountFilter, filter]
  );

  if (!propertyId) return null;

  return (
    <>
      <LedgerHeader
        startDate={startDate}
        endDate={endDate}
        reference={reference}
        invoice={invoice}
        recordNumber={recordNumber}
        recordNumberRange={recordNumberRange}
        email={email}
        setStartDate={val => filter({ startDate: val })}
        setEndDate={val => filter({ endDate: val })}
        setReference={val => filter({ reference: val })}
        setInvoice={val => filter({ invoice: val })}
        setRecordNumber={val => filter({ recordNumber: val })}
        setRecordNumberRange={val => filter({ recordNumberRange: val })}
        setEmail={val => filter({ email: val })}
        uploadFile={uploadFile}
        exportLedger={exportLedger}
      />
      {queryLimitWarning && (
        <Alert severity="info">
          <Typography>
            There are too many entries to display. Download ledger data for a complete list.
          </Typography>
        </Alert>
      )}
      <LedgerTable
        entries={journalEntries}
        totals={accountTotals}
        accountFilter={accountFilter}
        toggleAccountFilter={toggleFilter}
        showBalances={showBalances}
      />
      <Section title="Audit Logs" disableGutters>
        <AuditLogs scope="ledger" id={propertyId} />
      </Section>
    </>
  );
};
