import React, { useEffect, useState } from 'react';
import { useObservable, usePageTitle, GuestSurveyExportButton } from '@lib/common';
import { propertyConfigurationQuery, propertyConfigurationService } from 'app/state';
import { ManagePropertyConfigurationComponent } from '../components/manage-property.component';
import { Box, Container, Tab, Tabs } from '@material-ui/core';
import { BreadcrumbBackButton } from 'app/shared';
import { sessionQuery, guestSurveyService } from '@lib/state';
import { TabContext, TabPanel } from '@material-ui/lab';
import { ManagePropertyNotificationsComponent } from '../components/manage-notifications.component';
import { VipGuestsTab } from '../components/vip-guests';

export const ManagePropertyConfigurationPage: React.FC = () => {
  usePageTitle('Configure Property');
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const property = useObservable(propertyConfigurationQuery.activeProperty, 'async');

  const onExport = (propertyId: string, start: Date, end: Date) => {
    guestSurveyService.exportGuestSurveys(propertyId, start, end);
  };
  const [tab, setTab] = useState('propertyDetails');

  useEffect(() => {
    if (propertyId) propertyConfigurationService.getProperty(propertyId);
  }, [propertyId]);

  if (!property) return null;

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <BreadcrumbBackButton destination="/properties" />
        <GuestSurveyExportButton propertyId={property.id} exportChanges={onExport} />
      </Box>
      <TabContext value={tab}>
        <Tabs
          value={tab}
          onChange={(_, x) => setTab(x)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Property Details" value="propertyDetails" />
          <Tab label="Property Notifications" value="propertyNotifications" />
          <Tab label="VIP SETTINGS" value="vipGuests" />
        </Tabs>
        <TabPanel value="propertyDetails">
          <ManagePropertyConfigurationComponent property={property} />
        </TabPanel>
        <TabPanel value="propertyNotifications">
          <ManagePropertyNotificationsComponent property={property} />
        </TabPanel>
        <TabPanel value="vipGuests">
          <VipGuestsTab propertyId={property.id} />
        </TabPanel>
      </TabContext>
    </Container>
  );
};
