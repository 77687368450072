import React, { useEffect } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Container } from '@material-ui/core';

import { FormHub, Section, useFormEvents, useObservable, usePageTitle } from '@lib/common';
import { roomTypeQuery, roomTypeService, sessionQuery, RoomTypeForms } from '@lib/state';
import { AuditLogs, BreadcrumbBackButton } from 'app/shared';
import { RoomTypeForm, RoomTypeFields, mapRoomTypeFields } from '../components';

export const EditRoomTypePage: React.FC<RouteComponentProps<{ roomTypeId: string }>> = ({
  match,
}) => {
  const roomTypeId = match.params.roomTypeId;
  usePageTitle('Edit Room Type');
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const roomType = useObservable(roomTypeQuery.activeRoomType, 'async');

  const [{ status, error }, reset] = useFormEvents(RoomTypeForms.UpdateRoomType);

  useEffect(() => {
    roomTypeService.selectRoomType(roomTypeId);

    return () => roomTypeService.selectRoomType();
  }, [roomTypeId]);

  if (!propertyId || !roomType) return null;

  if (roomType.id !== roomTypeId) return <Redirect to={`/room-types/${roomType.id}`} />;

  const onSubmit = (values: RoomTypeFields) =>
    roomTypeService.updateRoomType(roomType.id, propertyId, mapRoomTypeFields(values));

  return (
    <Container maxWidth="lg">
      <BreadcrumbBackButton destination="/room-types" />
      <Section title="Edit Room Type" textAlign="left" disableGutters>
        <FormHub
          onSubmit={onSubmit}
          onReset={reset}
          formOptions={{
            defaultValues: {
              name: roomType.name,
              abbreviation: roomType.abbreviation ?? '',
              description: roomType.description ?? '',
              type: roomType.type ?? undefined,
              bedType: roomType.bedType ?? undefined,
              bedSize: roomType.bedSize ?? undefined,
              smokingPreference: roomType.smokingPreference ?? undefined,
              adultMinAge: roomType.adultOccupancy?.minAge ?? undefined,
              adultMaxOccupancy: roomType.adultOccupancy?.maxOccupancy ?? undefined,
              childMinAge: roomType.childOccupancy?.minAge ?? undefined,
              childMaxOccupancy: roomType.childOccupancy?.maxOccupancy ?? undefined,
              occupancyType: roomType.occupancyType ?? undefined,
            },
          }}
        >
          <RoomTypeForm roomType={roomType} error={error} status={status} reset={reset} />
        </FormHub>
      </Section>
      <Section title="Audit Logs" disableGutters>
        <AuditLogs scope="room-type" id={roomTypeId} />
      </Section>
    </Container>
  );
};
