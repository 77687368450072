import React from 'react';
import { Grid } from '@material-ui/core';

import { Name } from '@lib/state';
import {
  FormFirstNameField,
  FormLastNameField,
  TextFieldProps,
  useFieldLabelProps,
  useFormPrefix,
} from '../../forms';

interface Props {
  prefix?: string;

  name?: Name;

  optional?: boolean;
  disabled?: boolean;
  billing?: boolean;
  inputProps?: TextFieldProps;
}

export const NameForm: React.FC<Props> = ({
  prefix,
  name,
  optional,
  disabled,
  billing,
  inputProps = {},
}) => {
  const fieldName = useFormPrefix(prefix);

  const firstLabelProps = useFieldLabelProps(fieldName('name.first'), name?.first, inputProps);
  const lastLabelProps = useFieldLabelProps(fieldName('name.last'), name?.last, inputProps);

  return (
    <Grid container spacing={1}>
      <Grid item md={6} xs={12}>
        <FormFirstNameField
          {...inputProps}
          name={fieldName('name.first')}
          defaultValue={name?.first}
          autoComplete={billing ? 'billing given-name' : undefined}
          disabled={disabled}
          required={!optional}
          fullWidth
          InputLabelProps={firstLabelProps}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormLastNameField
          {...inputProps}
          name={fieldName('name.last')}
          defaultValue={name?.last}
          autoComplete={billing ? 'billing family-name' : undefined}
          disabled={disabled}
          required={!optional}
          fullWidth
          InputLabelProps={lastLabelProps}
        />
      </Grid>
    </Grid>
  );
};
