import { applyTransaction } from '@datorama/akita';
import { from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';

import {
  dispatchForm,
  HousekeepingPolicy,
  ImportStrategy,
  MediaTypes,
  PaginationState,
  Property,
  ReservationPolicy,
  Reservations,
  CommonForm,
} from '@lib/state';
import { PropertyConfiguration } from 'app/state';
import { formatOutgoingPolicy } from '../property-configuration/property-configuration.model';
import { CorporateAccountForms } from './corporate-account.forms';
import {
  CorporateAccount,
  CorporateDelegate,
  formatReservationPolicy,
} from './corporate-account.model';
import {
  corporateAccountStore,
  CorporateAccountStore,
  CorporateAccountUIState,
} from './corporate-account.store';
import {
  BillingFrequencyType,
  SetInvoiceGroupingRequest,
} from '@lib/state/api/generated/reservations';

export class CorporateAccountService {
  constructor(
    private readonly store: CorporateAccountStore,
    private readonly corporateAccountApi: Reservations.CorporateAccountApi
  ) {}

  getCorporateAccounts(
    customerId: string,
    { name, billingFrequencyType }: CorporateAccountUIState,
    continuationToken?: string | null,
    limit?: number,
    hasDelegates?: boolean
  ) {
    from(
      this.corporateAccountApi.corporateaccountGet(
        customerId ?? undefined,
        undefined,
        name ?? undefined,
        continuationToken ?? undefined,
        limit,
        hasDelegates ?? false,
        billingFrequencyType && billingFrequencyType !== 'All'
          ? (Object.keys(BillingFrequencyType).find(
              k =>
                BillingFrequencyType[k as keyof typeof BillingFrequencyType] ===
                billingFrequencyType
            ) as BillingFrequencyType)
          : undefined
      )
    )
      .pipe(map(response => response.data))
      .subscribe(({ data, ...pagination }) =>
        applyTransaction(() => {
          this.store.upsertMany(data);

          this.updateUI({
            name,
            billingFrequencyType: billingFrequencyType ?? 'All',
          });
          this.updatePaginationState(pagination);
        })
      );
  }

  getAllCorporateAccounts(customerId: string, hasDelegates = false) {
    return this.getCorporateAccounts(customerId, {}, null, 1000, hasDelegates);
  }

  getCorporateAccountById(corporateAccountId: CorporateAccount['id']) {
    from(this.corporateAccountApi.corporateaccountIdGet(corporateAccountId))
      .pipe(map(response => formatReservationPolicy(response.data.data)))
      .subscribe(corporateAccount => {
        this.store.upsert(corporateAccountId, corporateAccount);
        this.store.setActive(corporateAccount.id);
      });
  }

  createCorporateAccount(propertyId: string, name: string, invoiceSubAccount?: string) {
    from(
      this.corporateAccountApi.corporateaccountPost({
        propertyId,
        name,
        invoiceSubAccount: invoiceSubAccount ?? undefined,
      })
    )
      .pipe(
        dispatchForm(CorporateAccountForms.Create),
        map(response => formatReservationPolicy(response.data.data))
      )
      .subscribe(corporateAccount => {
        this.store.upsert(corporateAccount.id, corporateAccount);
        this.store.setActive(corporateAccount.id);
      });
  }

  updateCorporateAccount(corporateAccount: CorporateAccount) {
    from(
      this.corporateAccountApi.corporateaccountIdPost(corporateAccount.id, {
        ...corporateAccount,
      })
    )
      .pipe(
        dispatchForm(CorporateAccountForms.Edit),
        map(response => response.data.data)
      )
      .subscribe(result => {
        this.store.upsert(result.id, result);
      });
  }

  setInvoiceGrouping(id: CorporateAccount['id'], invoiceGrouping: SetInvoiceGroupingRequest) {
    from(
      this.corporateAccountApi.corporateaccountIdInvoicegroupingPut(id, {
        ...invoiceGrouping,
      })
    )
      .pipe(
        dispatchForm(CorporateAccountForms.SetInvoiceGrouping),
        map(response => response.data.data)
      )
      .subscribe(result => {
        this.store.upsert(result.id, result);
      });
  }

  addHousekeepingOverride(account: CorporateAccount) {
    this.setHousekeepingPolicy(account.id, {})
      .pipe(dispatchForm(CorporateAccountForms.AddHousekeeping))
      .subscribe();
  }

  updateHousekeepingPolicy(account: CorporateAccount, policy: HousekeepingPolicy) {
    this.setHousekeepingPolicy(account.id, policy)
      .pipe(dispatchForm(CorporateAccountForms.UpdateHousekeeping))
      .subscribe();
  }

  removeHousekeepingPolicy(account: CorporateAccount) {
    this.setHousekeepingPolicy(account.id)
      .pipe(dispatchForm(CorporateAccountForms.RemoveHousekeeping))
      .subscribe();
  }

  private setHousekeepingPolicy(id: CorporateAccount['id'], policy?: HousekeepingPolicy) {
    policy = policy && {
      touchUpCleanFrequency: policy.touchUpCleanFrequency || null,
      fullCleanFrequency: policy.fullCleanFrequency || null,
    };

    return from(
      this.corporateAccountApi.corporateaccountIdPolicyHousekeepingPut(id, { policy })
    ).pipe(
      map(({ data }) => data.data),
      tap(account => this.store.upsert(id, account))
    );
  }

  addReservationOverride(account: CorporateAccount, property: PropertyConfiguration) {
    this.setReservationPolicy(account.id, property)
      .pipe(dispatchForm(CorporateAccountForms.AddReservationPolicy))
      .subscribe();
  }

  updateReservationPolicy(account: CorporateAccount, policy: ReservationPolicy) {
    this.setReservationPolicy(account.id, policy)
      .pipe(dispatchForm(CorporateAccountForms.UpdateReservationPolicy))
      .subscribe();
  }

  removeReservationPolicy(account: CorporateAccount) {
    this.setReservationPolicy(account.id)
      .pipe(dispatchForm(CorporateAccountForms.RemoveReservationPolicy))
      .subscribe();
  }

  private setReservationPolicy(id: CorporateAccount['id'], policy?: ReservationPolicy) {
    return from(
      this.corporateAccountApi.corporateaccountIdPolicyReservationPut(id, {
        policy: formatOutgoingPolicy(policy),
      })
    ).pipe(
      map(({ data }) => formatReservationPolicy(data.data)),
      tap(account => this.store.upsert(id, account))
    );
  }

  getAccountDelegates(id: CorporateAccount['id']) {
    from(this.corporateAccountApi.corporateaccountIdDelegatesGet(id))
      .pipe(map(({ data }) => data))
      .subscribe(delegates => this.store.upsert(id, { delegates }));
  }

  addDelegate(account: CorporateAccount, userId: string) {
    from(this.corporateAccountApi.corporateaccountIdDelegatesUserIdPut(account.id, userId))
      .pipe(
        map(({ data }) => data),
        dispatchForm(CorporateAccountForms.AddDelegate)
      )
      .subscribe(delegate => {
        const delegateIds = new Set<string>();
        const delegates = [...(account.delegates || []), delegate].reduce((uniqueDelegates, d) => {
          if (delegateIds.has(d.userId)) return uniqueDelegates;

          delegateIds.add(d.userId);
          return [...uniqueDelegates, d];
        }, new Array<CorporateDelegate>());

        this.store.update(account.id, { delegates });
      });
  }

  removeDelegate(delegate: CorporateDelegate) {
    from(
      this.corporateAccountApi.corporateaccountIdDelegatesUserIdDelete(
        delegate.corporateAccountId,
        delegate.userId
      )
    )
      .pipe(dispatchForm(CorporateAccountForms.RemoveDelegate))
      .subscribe(() => {
        this.store.update(delegate.corporateAccountId, account => {
          const delegates = [...(account.delegates || [])];
          delegates.splice(
            delegates.findIndex(x => x.userId === delegate.userId),
            1
          );
          return { delegates };
        });
      });
  }

  getZonePreferences(account: CorporateAccount, propertyId: Property['id']) {
    from(this.corporateAccountApi.corporateaccountIdZonesPropertyIdGet(account.id, propertyId))
      .pipe(map(response => response.data.data))
      .subscribe(zones => this.store.upsert(account.id, { zones }));
  }

  setZonePreferences(
    account: CorporateAccount,
    propertyId: Property['id'],
    preferences: Reservations.UpdateZonePreferenceSequence[]
  ) {
    from(
      this.corporateAccountApi.corporateaccountIdZonesPropertyIdPut(account.id, propertyId, {
        preferences: preferences,
      })
    )
      .pipe(
        map(({ data }) => data.data),
        dispatchForm(CorporateAccountForms.UpdateZonePreferences)
      )
      .subscribe(zones => this.store.upsert(account.id, { zones }));
  }

  importEmployees(account: CorporateAccount, strategy: ImportStrategy, file: File) {
    from(this.corporateAccountApi.corporateaccountIdEmployeesImportPost(account.id, strategy, file))
      .pipe(dispatchForm(CorporateAccountForms.ImportEmployees))
      .subscribe();
  }

  exportEmployees(account: CorporateAccount) {
    from(
      this.corporateAccountApi.corporateaccountIdEmployeesExportGet(account.id, {
        responseType: 'blob',
      })
    )
      .pipe(
        map(x => new Blob([x.data], { type: MediaTypes.CsvFile })),
        dispatchForm(CommonForm.Export)
      )
      .subscribe(x => saveAs(x, `${account.name}-employees.csv`));
  }

  exportAllCorporateAccounts(customerId: string, propertyId?: string) {
    from(
      this.corporateAccountApi.corporateaccountExportGet(
        customerId ?? undefined,
        propertyId,
        undefined,
        undefined,
        0
      )
    )
      .pipe(
        map(x => new Blob([x.data], { type: MediaTypes.CsvFile })),
        dispatchForm(CommonForm.Export)
      )
      .subscribe(x => saveAs(x, 'CorporateAccounts.csv'));
  }

  private updateUI(state: Partial<CorporateAccountUIState>) {
    this.store.update(({ ui }) => ({
      ui: { ...ui, ...state },
    }));
  }

  private updatePaginationState({ isDone, continuationToken }: PaginationState) {
    this.store.update(() => ({
      pagination: { isDone, continuationToken },
    }));
  }

  selectCorporateAccount(corporateAccountId?: CorporateAccount['id']) {
    this.store.setActive(corporateAccountId ?? null);
  }
}

export const corporateAccountService = new CorporateAccountService(
  corporateAccountStore,
  new Reservations.CorporateAccountApi()
);
