import { createStyles, makeStyles } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { Room, RoomUpdate } from '@lib/state';
import { AppColors } from '@lib/common';
import { GuestStay } from 'app/state';

export interface RoomUpdateFormProps {
  room: Room;
  stay?: GuestStay;
  onSave: (room: Room, update: RoomUpdate) => void;
}

export const useFormStyles = makeStyles(theme =>
  createStyles({
    container: {
      marginBottom: theme.spacing(1),
    },
    title: {
      fontSize: '150%',
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
    },
    status: {
      padding: theme.spacing(1),
      marginRight: theme.spacing(0.5),
      border: '1px solid',
    },
    blueButton: {
      color: common.white,
      fontWeight: 'bold',
      backgroundColor: AppColors.LightBlue,
      '&:hover': {
        backgroundColor: AppColors.LightBlue,
      },
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  })
);
