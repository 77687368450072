import React, { useCallback, useState } from 'react';
import {
  Typography,
  makeStyles,
  createStyles,
  Box,
  DialogContent,
  Button,
  MenuItem,
} from '@material-ui/core';

import {
  FormStatus,
  FormEvent,
  Room,
  HousekeepingStatusEnum,
  HousekeepingStatusMap,
} from '@lib/state';
import {
  Loading,
  AppColors,
  StyledDialog,
  NameDisplay,
  ErrorDisplay,
  FormSelect,
  FormHub,
  FormSubmitButton,
} from '@lib/common';
import { GuestStay } from 'app/state';

const useStyles = makeStyles(() =>
  createStyles({
    bold: { fontWeight: 700 },
    oldRoomNumber: { fontWeight: 700, display: 'inline', color: AppColors.Red },
    newRoomNumber: { fontWeight: 700, display: 'inline', color: AppColors.Green },
  })
);

interface ChangeRoomProps {
  open: boolean;
  stay: GuestStay | undefined;
  currentRoom: Room | undefined;
  newRoom: Room | undefined;
  changeRoomResults: FormEvent;
  onClose: () => void;
  onSave: (cleaningStatus?: HousekeepingStatusEnum) => void;
  onSuccess: () => void;
}

export const ChangeRoomModal: React.FC<ChangeRoomProps> = ({
  open,
  stay,
  currentRoom,
  newRoom,
  changeRoomResults,
  onClose,
  onSave,
  onSuccess,
}) => {
  const styles = useStyles();

  const [promptForCleaning, setPromptForCleaning] = useState(false);

  const submit = useCallback(
    ({ cleaningStatus }) => {
      if (!promptForCleaning && !currentRoom?.doorControllerStatus?.isProvisioned) {
        setPromptForCleaning(true);
      } else {
        onSave(cleaningStatus);
      }
    },
    [currentRoom, onSave, promptForCleaning]
  );

  const formStatus = () => {
    switch (changeRoomResults.status) {
      case FormStatus.Success: {
        return (
          <>
            <Box p={1} mb={2} fontSize="125%" textAlign="center">
              <Typography variant="subtitle1" align="center" color="textSecondary">
                Change room request was successful.
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setPromptForCleaning(false);
                onSuccess();
              }}
            >
              Ok
            </Button>
          </>
        );
      }
      case FormStatus.Error: {
        return (
          <>
            <Button variant="contained" color="primary" onClick={onClose}>
              Choose Another Room
            </Button>
            <ErrorDisplay error={changeRoomResults.error}>
              <Typography variant="subtitle1" align="center" color="textSecondary">
                There was an error processing the change room request.
              </Typography>
            </ErrorDisplay>
          </>
        );
      }
      case FormStatus.Pending: {
        return <Loading />;
      }
      case FormStatus.Initial: {
        return (
          <>
            {!promptForCleaning ? (
              <>
                <Box p={1} mb={2} fontSize="125%" textAlign="center">
                  You are about to switch{' '}
                  <NameDisplay
                    className={styles.bold}
                    display="inline"
                    {...stay?.checkInGuest?.name}
                  />{' '}
                  from room{' '}
                  <Typography className={styles.oldRoomNumber}>
                    {currentRoom?.roomNumber}
                  </Typography>{' '}
                  to <Typography className={styles.newRoomNumber}>{newRoom?.roomNumber}</Typography>
                  .<br />
                  Are you sure you want to continue with these changes?
                </Box>
                <Button variant="contained" color="secondary" onClick={submit}>
                  Change Room
                </Button>
              </>
            ) : (
              <FormHub onSubmit={submit}>
                <Box p={1} mb={2} fontSize="125%">
                  <Typography paragraph align="center">
                    <strong>{currentRoom?.roomNumber}</strong> is not provisioned. Please select a
                    housekeeping status for <strong>{currentRoom?.roomNumber}</strong>.
                  </Typography>
                  <Box textAlign="initial" clone>
                    <FormSelect
                      name="cleaningStatus"
                      label="Housekeeping Status"
                      defaultValue={HousekeepingStatusEnum.FullClean}
                      fullWidth
                      required
                    >
                      {[...HousekeepingStatusMap.entries()].map(([value, display]) => (
                        <MenuItem key={value} value={value}>
                          {display}
                        </MenuItem>
                      ))}
                    </FormSelect>
                  </Box>
                </Box>
                <FormSubmitButton variant="contained" color="secondary">
                  Change Room
                </FormSubmitButton>
              </FormHub>
            )}
          </>
        );
      }
      default:
        return null;
    }
  };

  return (
    <StyledDialog
      title="Change Room Confirmation"
      onClose={() => {
        setPromptForCleaning(false);
        onClose();
      }}
      aria-labelledby="room-info-title"
      open={open}
    >
      <DialogContent>
        <Box textAlign="center">{formStatus()}</Box>
      </DialogContent>
    </StyledDialog>
  );
};
