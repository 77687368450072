// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { ProblemDetails } from '../models';
import { RatePlanSort } from '../models';
import { RoomRatePlanModel } from '../models';
import { UpsertRatePlanRequest } from '../models';
/**
 * RatePlanApi - axios parameter creator
 * @export
 */
export const RatePlanApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} corporateAccountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanCorporateGet(corporateAccountId: string, options: any = {}): RequestArgs {
      // verify required parameter 'corporateAccountId' is not null or undefined
      if (corporateAccountId === null || corporateAccountId === undefined) {
        throw new RequiredError(
          'corporateAccountId',
          'Required parameter corporateAccountId was null or undefined when calling rateplanCorporateGet.'
        );
      }
      const localVarPath = `/rateplan/corporate`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (corporateAccountId !== undefined) {
        localVarQueryParameter['corporateAccountId'] = corporateAccountId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [roomTypeName]
     * @param {string} [roomTypeId]
     * @param {boolean} [includeDisabled]
     * @param {string} [corporateAccountId]
     * @param {RatePlanSort} [ratePlanSort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanExportGet(
      propertyId: string,
      roomTypeName?: string,
      roomTypeId?: string,
      includeDisabled?: boolean,
      corporateAccountId?: string,
      ratePlanSort?: RatePlanSort,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling rateplanExportGet.'
        );
      }
      const localVarPath = `/rateplan/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (roomTypeName !== undefined) {
        localVarQueryParameter['roomTypeName'] = roomTypeName;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (roomTypeId !== undefined) {
        localVarQueryParameter['roomTypeId'] = roomTypeId;
      }

      if (includeDisabled !== undefined) {
        localVarQueryParameter['includeDisabled'] = includeDisabled;
      }

      if (corporateAccountId !== undefined) {
        localVarQueryParameter['corporateAccountId'] = corporateAccountId;
      }

      if (ratePlanSort !== undefined) {
        localVarQueryParameter['ratePlanSort'] = ratePlanSort;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [roomTypeId]
     * @param {boolean} [includeDisabled]
     * @param {string} [corporateAccountId]
     * @param {RatePlanSort} [ratePlanSort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanGet(
      propertyId: string,
      roomTypeId?: string,
      includeDisabled?: boolean,
      corporateAccountId?: string,
      ratePlanSort?: RatePlanSort,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling rateplanGet.'
        );
      }
      const localVarPath = `/rateplan`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (roomTypeId !== undefined) {
        localVarQueryParameter['roomTypeId'] = roomTypeId;
      }

      if (includeDisabled !== undefined) {
        localVarQueryParameter['includeDisabled'] = includeDisabled;
      }

      if (corporateAccountId !== undefined) {
        localVarQueryParameter['corporateAccountId'] = corporateAccountId;
      }

      if (ratePlanSort !== undefined) {
        localVarQueryParameter['ratePlanSort'] = ratePlanSort;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanIdDelete(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling rateplanIdDelete.'
        );
      }
      const localVarPath = `/rateplan/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanIdEnablePost(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling rateplanIdEnablePost.'
        );
      }
      const localVarPath = `/rateplan/{id}/enable`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanIdGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling rateplanIdGet.'
        );
      }
      const localVarPath = `/rateplan/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanIdHistoryGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling rateplanIdHistoryGet.'
        );
      }
      const localVarPath = `/rateplan/{id}/history`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpsertRatePlanRequest} [upsertRatePlanRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanIdPost(
      id: string,
      upsertRatePlanRequest?: UpsertRatePlanRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling rateplanIdPost.'
        );
      }
      const localVarPath = `/rateplan/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof upsertRatePlanRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(upsertRatePlanRequest !== undefined ? upsertRatePlanRequest : {})
        : upsertRatePlanRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpsertRatePlanRequest} [upsertRatePlanRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanPost(upsertRatePlanRequest?: UpsertRatePlanRequest, options: any = {}): RequestArgs {
      const localVarPath = `/rateplan`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof upsertRatePlanRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(upsertRatePlanRequest !== undefined ? upsertRatePlanRequest : {})
        : upsertRatePlanRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RatePlanApi - functional programming interface
 * @export
 */
export const RatePlanApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} corporateAccountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanCorporateGet(
      corporateAccountId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoomRatePlanModel>> {
      const localVarAxiosArgs = RatePlanApiAxiosParamCreator(configuration).rateplanCorporateGet(
        corporateAccountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [roomTypeName]
     * @param {string} [roomTypeId]
     * @param {boolean} [includeDisabled]
     * @param {string} [corporateAccountId]
     * @param {RatePlanSort} [ratePlanSort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanExportGet(
      propertyId: string,
      roomTypeName?: string,
      roomTypeId?: string,
      includeDisabled?: boolean,
      corporateAccountId?: string,
      ratePlanSort?: RatePlanSort,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = RatePlanApiAxiosParamCreator(configuration).rateplanExportGet(
        propertyId,
        roomTypeName,
        roomTypeId,
        includeDisabled,
        corporateAccountId,
        ratePlanSort,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [roomTypeId]
     * @param {boolean} [includeDisabled]
     * @param {string} [corporateAccountId]
     * @param {RatePlanSort} [ratePlanSort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanGet(
      propertyId: string,
      roomTypeId?: string,
      includeDisabled?: boolean,
      corporateAccountId?: string,
      ratePlanSort?: RatePlanSort,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoomRatePlanModel>> {
      const localVarAxiosArgs = RatePlanApiAxiosParamCreator(configuration).rateplanGet(
        propertyId,
        roomTypeId,
        includeDisabled,
        corporateAccountId,
        ratePlanSort,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanIdDelete(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomRatePlanModel> {
      const localVarAxiosArgs = RatePlanApiAxiosParamCreator(configuration).rateplanIdDelete(
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanIdEnablePost(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomRatePlanModel> {
      const localVarAxiosArgs = RatePlanApiAxiosParamCreator(configuration).rateplanIdEnablePost(
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanIdGet(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomRatePlanModel> {
      const localVarAxiosArgs = RatePlanApiAxiosParamCreator(configuration).rateplanIdGet(
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanIdHistoryGet(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoomRatePlanModel>> {
      const localVarAxiosArgs = RatePlanApiAxiosParamCreator(configuration).rateplanIdHistoryGet(
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpsertRatePlanRequest} [upsertRatePlanRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanIdPost(
      id: string,
      upsertRatePlanRequest?: UpsertRatePlanRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomRatePlanModel> {
      const localVarAxiosArgs = RatePlanApiAxiosParamCreator(configuration).rateplanIdPost(
        id,
        upsertRatePlanRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {UpsertRatePlanRequest} [upsertRatePlanRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanPost(
      upsertRatePlanRequest?: UpsertRatePlanRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomRatePlanModel> {
      const localVarAxiosArgs = RatePlanApiAxiosParamCreator(configuration).rateplanPost(
        upsertRatePlanRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * RatePlanApi - factory interface
 * @export
 */
export const RatePlanApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} corporateAccountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanCorporateGet(
      corporateAccountId: string,
      options?: any
    ): AxiosPromise<Array<RoomRatePlanModel>> {
      return RatePlanApiFp(configuration).rateplanCorporateGet(corporateAccountId, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [roomTypeName]
     * @param {string} [roomTypeId]
     * @param {boolean} [includeDisabled]
     * @param {string} [corporateAccountId]
     * @param {RatePlanSort} [ratePlanSort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanExportGet(
      propertyId: string,
      roomTypeName?: string,
      roomTypeId?: string,
      includeDisabled?: boolean,
      corporateAccountId?: string,
      ratePlanSort?: RatePlanSort,
      options?: any
    ): AxiosPromise<any> {
      return RatePlanApiFp(configuration).rateplanExportGet(
        propertyId,
        roomTypeName,
        roomTypeId,
        includeDisabled,
        corporateAccountId,
        ratePlanSort,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [roomTypeId]
     * @param {boolean} [includeDisabled]
     * @param {string} [corporateAccountId]
     * @param {RatePlanSort} [ratePlanSort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanGet(
      propertyId: string,
      roomTypeId?: string,
      includeDisabled?: boolean,
      corporateAccountId?: string,
      ratePlanSort?: RatePlanSort,
      options?: any
    ): AxiosPromise<Array<RoomRatePlanModel>> {
      return RatePlanApiFp(configuration).rateplanGet(
        propertyId,
        roomTypeId,
        includeDisabled,
        corporateAccountId,
        ratePlanSort,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanIdDelete(id: string, options?: any): AxiosPromise<RoomRatePlanModel> {
      return RatePlanApiFp(configuration).rateplanIdDelete(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanIdEnablePost(id: string, options?: any): AxiosPromise<RoomRatePlanModel> {
      return RatePlanApiFp(configuration).rateplanIdEnablePost(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanIdGet(id: string, options?: any): AxiosPromise<RoomRatePlanModel> {
      return RatePlanApiFp(configuration).rateplanIdGet(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanIdHistoryGet(id: string, options?: any): AxiosPromise<Array<RoomRatePlanModel>> {
      return RatePlanApiFp(configuration).rateplanIdHistoryGet(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {UpsertRatePlanRequest} [upsertRatePlanRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanIdPost(
      id: string,
      upsertRatePlanRequest?: UpsertRatePlanRequest,
      options?: any
    ): AxiosPromise<RoomRatePlanModel> {
      return RatePlanApiFp(configuration).rateplanIdPost(
        id,
        upsertRatePlanRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {UpsertRatePlanRequest} [upsertRatePlanRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rateplanPost(
      upsertRatePlanRequest?: UpsertRatePlanRequest,
      options?: any
    ): AxiosPromise<RoomRatePlanModel> {
      return RatePlanApiFp(configuration).rateplanPost(upsertRatePlanRequest, options)(
        axios,
        basePath
      );
    },
  };
};

/**
 * RatePlanApi - object-oriented interface
 * @export
 * @class RatePlanApi
 * @extends {BaseAPI}
 */
export class RatePlanApi extends BaseAPI {
  /**
   *
   * @param {string} corporateAccountId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RatePlanApi
   */
  public rateplanCorporateGet(corporateAccountId: string, options?: any) {
    return RatePlanApiFp(this.configuration).rateplanCorporateGet(corporateAccountId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} [roomTypeName]
   * @param {string} [roomTypeId]
   * @param {boolean} [includeDisabled]
   * @param {string} [corporateAccountId]
   * @param {RatePlanSort} [ratePlanSort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RatePlanApi
   */
  public rateplanExportGet(
    propertyId: string,
    roomTypeName?: string,
    roomTypeId?: string,
    includeDisabled?: boolean,
    corporateAccountId?: string,
    ratePlanSort?: RatePlanSort,
    options?: any
  ) {
    return RatePlanApiFp(this.configuration).rateplanExportGet(
      propertyId,
      roomTypeName,
      roomTypeId,
      includeDisabled,
      corporateAccountId,
      ratePlanSort,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} [roomTypeId]
   * @param {boolean} [includeDisabled]
   * @param {string} [corporateAccountId]
   * @param {RatePlanSort} [ratePlanSort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RatePlanApi
   */
  public rateplanGet(
    propertyId: string,
    roomTypeId?: string,
    includeDisabled?: boolean,
    corporateAccountId?: string,
    ratePlanSort?: RatePlanSort,
    options?: any
  ) {
    return RatePlanApiFp(this.configuration).rateplanGet(
      propertyId,
      roomTypeId,
      includeDisabled,
      corporateAccountId,
      ratePlanSort,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RatePlanApi
   */
  public rateplanIdDelete(id: string, options?: any) {
    return RatePlanApiFp(this.configuration).rateplanIdDelete(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RatePlanApi
   */
  public rateplanIdEnablePost(id: string, options?: any) {
    return RatePlanApiFp(this.configuration).rateplanIdEnablePost(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RatePlanApi
   */
  public rateplanIdGet(id: string, options?: any) {
    return RatePlanApiFp(this.configuration).rateplanIdGet(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RatePlanApi
   */
  public rateplanIdHistoryGet(id: string, options?: any) {
    return RatePlanApiFp(this.configuration).rateplanIdHistoryGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {UpsertRatePlanRequest} [upsertRatePlanRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RatePlanApi
   */
  public rateplanIdPost(id: string, upsertRatePlanRequest?: UpsertRatePlanRequest, options?: any) {
    return RatePlanApiFp(this.configuration).rateplanIdPost(
      id,
      upsertRatePlanRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {UpsertRatePlanRequest} [upsertRatePlanRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RatePlanApi
   */
  public rateplanPost(upsertRatePlanRequest?: UpsertRatePlanRequest, options?: any) {
    return RatePlanApiFp(this.configuration).rateplanPost(upsertRatePlanRequest, options)(
      this.axios,
      this.basePath
    );
  }
}
