import React, { useCallback, useEffect } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import {
  roomTypeQuery,
  roomTypeService,
  roomZoneQuery,
  roomZoneService,
  sessionQuery,
} from '@lib/state';
import {
  FormSelect,
  FormTextField,
  Section,
  useFormEvents,
  useObservable,
  usePageTitle,
} from '@lib/common';
import { RoomListFilters, RoomListForms, roomListQuery, roomListService } from 'app/state';
import { ListFilters, Tile } from 'app/shared';
import { RoomListResults } from '../components/room-list-result';

export const RoomListPage: React.FC = () => {
  usePageTitle('Rooms');

  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const roomList = useObservable(roomListQuery.rooms);
  const roomTypes = useObservable(roomTypeQuery.propertyRoomTypes);
  const roomZones = useObservable(roomZoneQuery.roomZones);
  const filters = useObservable(roomListQuery.filters);
  const pagination = useObservable(roomListQuery.pagination);

  const [{ status, error }] = useFormEvents(RoomListForms.DeleteRoom);

  useEffect(() => {
    if (propertyId) {
      roomTypeService.getPropertyRoomTypes(propertyId);
      roomZoneService.getPropertyRoomZones(propertyId);
    }
  }, [propertyId]);

  const onChange = useCallback(
    (x: RoomListFilters) => propertyId && roomListService.getRoomList(propertyId, x),
    [propertyId]
  );

  const loadMore = useCallback(
    () =>
      propertyId && roomListService.getRoomList(propertyId, filters, pagination.continuationToken),
    [propertyId, filters, pagination]
  );

  const onDeleteRoom = useCallback(
    (id: string) => {
      if (propertyId) {
        roomListService.deleteRoom(propertyId, id);
      }
    },
    [propertyId]
  );

  if (roomTypes.length === 0 || roomZones.length === 0) {
    return null;
  }

  return (
    <Section>
      <Tile>
        <ListFilters filters={filters} onChange={onChange}>
          <Grid container spacing={1}>
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <FormTextField name="roomNumber" label="Room Number" fullWidth size="small" />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <FormSelect
                name="roomTypeId"
                label="Room Types"
                fullWidth
                size="small"
                defaultValue=""
              >
                <MenuItem value="">All</MenuItem>
                {roomTypes.map(rt => (
                  <MenuItem key={rt.id} value={rt.id}>
                    {rt.name}
                  </MenuItem>
                ))}
              </FormSelect>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <FormSelect
                name="roomZoneId"
                label="Room Zones"
                fullWidth
                size="small"
                defaultValue=""
              >
                <MenuItem value="">All</MenuItem>
                {roomZones.map(rz => (
                  <MenuItem key={rz.id} value={rz.id}>
                    {rz.name ?? 'N/A'}
                  </MenuItem>
                ))}
              </FormSelect>
            </Grid>
          </Grid>
        </ListFilters>
        <RoomListResults
          rooms={roomList}
          propertyId={propertyId ?? ''}
          onDeleteRoom={id => {
            onDeleteRoom(id);
          }}
          loadMore={loadMore}
          isDone={pagination.isDone}
          error={error}
          status={status}
        />
      </Tile>
    </Section>
  );
};
