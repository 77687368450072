import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';

import { CorporateAccount, propertyQuery } from '@lib/state';
import { Section, Link, ButtonLink, ShowMoreButton, useLookup, useObservable, DataTable } from '@lib/common';
import { GridSelectionModel } from '@mui/x-data-grid-pro';
import { Tile, DownloadButton } from 'app/shared';
import {
  CorporateAccountSearchFilter,
  CorporateAccountFilters,
} from './corporate-account-search-filter';
import { CreateInvoice } from './create-invoice';
import { corporateAccountService } from 'app/state';
import {
  CreateInvoiceStatus,
  CreateInvoiceStatusModel,
} from '@lib/state/api/generated/reservations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  accounts: Array<CorporateAccount>;
  createInvoicesStatus: Array<CreateInvoiceStatusModel>;
  filters: CorporateAccountFilters;
  isDone: boolean;
  loadMore: () => void;
  onChange: (filters: CorporateAccountFilters) => void;
}

export const CorporateAccountLookup: React.FC<Props> = ({
  accounts,
  createInvoicesStatus,
  filters,
  isDone,
  loadMore,
  onChange,
}) => {
  const [invoiceButtonHidden, setInvoiceButtonHidden] = useState(true);
  const [selectedAccs, setSelectedAcc] = useState<CorporateAccount[]>([]);

  useEffect(() => {
    if (filters.billingFrequencyType !== 'All') {
      setSelectedAcc(accounts);
      setInvoiceButtonHidden(accounts.length === 0);
    } else {
      setSelectedAcc([]);
      setInvoiceButtonHidden(true);
    }
  }, [filters.billingFrequencyType, setSelectedAcc, accounts]);

  const accountLookup = useLookup(accounts, x => x.id);
  const activeProperty = useObservable(propertyQuery.activeProperty, 'async');

  const getCreateInvoiceStatus = (aId: string) => {
    if (createInvoicesStatus && createInvoicesStatus.length > 0) {
      const result = createInvoicesStatus.find(x => x.accountId === aId);
      if (result) {
        if (result.status === CreateInvoiceStatus.Failed) {
          return (
            <Typography>
              <FontAwesomeIcon icon="times-circle" color="red" /> {' ' + result.statusMessage}
            </Typography>
          );
        } else {
          return (
            <Typography>
              <FontAwesomeIcon icon="check-circle" color="green" />
              {' ' + result.statusMessage}
            </Typography>
          );
        }
      }
    }
    return <Typography></Typography>;
  };

  return (
    <Tile>
      <Box display="flex" justifyContent="flex-end" flexDirection="row">
        {!invoiceButtonHidden && (
          <Box mr={2}>
            <CreateInvoice
              account={accounts[0]}
              accounts={selectedAccs}
              createMultipleInvoices={true}
            />
          </Box>
        )}
        <Box display="flex" justifyContent="flex-end" flexDirection="row" marginRight={2}>
          <DownloadButton
            onDownload={() =>
              corporateAccountService.exportAllCorporateAccounts(
                accounts[0].customerId,
                activeProperty?.id
              )
            }
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" flexDirection="row">
          <ButtonLink to="/corporate-accounts/add" color="primary" variant="contained">
            Create Corporate Account
          </ButtonLink>
        </Box>
      </Box>
      <CorporateAccountSearchFilter filters={filters} onChange={onChange} />
      {accounts.length > 0 && (
        <Section title="Results" textAlign="left" disableGutters>
          <DataTable
            items={accounts}
            getItemKey={account => account.id}
            checkboxSelection={true}
            children={<ShowMoreButton isDone={isDone} loadMore={loadMore} />}
            onSelectionModelChange={(model: GridSelectionModel) => {
              setSelectedAcc(model.map(x => accountLookup.get(x as string)!));
              setInvoiceButtonHidden(model.length === 0);
            }}
            selectionModel={selectedAccs.map(x => x.id)}
            columns={[
              {
                title: 'Name',
                valueFactory: account => (
                  <Link to={`/corporate-accounts/${account.id}`}>
                    <Typography>{account.name}</Typography>
                  </Link>
                ),
              },
              {
                title: 'Invoice Account',
                valueFactory: account => <Typography>{account.invoiceSubAccount}</Typography>,
              },
              {
                title: 'Billing Frequency',
                valueFactory: account => <Typography>{account.billingFrequencyType}</Typography>,
              },
              {
                title: 'Create Invoice Status',
                valueFactory: account => getCreateInvoiceStatus(account.id),
              },
            ]}
          />
        </Section>
      )}
    </Tile>
  );
};
