import { useObservable } from '@lib/common';
import { vipHousekeepingOverrideQuery, vipHousekeepingOverrideService } from '@lib/state';
import { useEffect } from 'react';

export function useVipHousekeepingOverride(propertyId: string) {
  useEffect(() => {
    vipHousekeepingOverrideService.getVipOverrides(propertyId);
  }, [propertyId]);

  return {
    overrides: useObservable(vipHousekeepingOverrideQuery.vipHousekeepingOverrides),
    loading: useObservable(vipHousekeepingOverrideQuery.loading),
  };
}
