import { EntityStore, EntityState, StoreConfig } from '@datorama/akita';

import { JournalEntry, AccountTotals, JournalAccount } from './journal-entries.model';

export interface JournalEntryUIState {
  accountTotals: AccountTotals[];
  accounts: JournalAccount[];
  queryLimitWarning: boolean;
  filters: {
    propertyId: string | null;
    accounts: string[];
    startDate: string | null;
    endDate: string | null;
    reference: string;
    invoice: string;
    recordNumber: string;
    recordNumberRange: string;
    email: string;
  };
}

export interface JournalEntryState extends EntityState<JournalEntry, JournalEntry['id']> {
  ui: JournalEntryUIState;
}

@StoreConfig({ name: 'journal-entries', resettable: true })
export class JournalEntryStore extends EntityStore<JournalEntryState> {
  constructor() {
    super({
      ui: {
        accountTotals: [],
        accounts: [],
        queryLimitWarning: false,
        filters: {
          propertyId: null,
          accounts: [],
          startDate: null,
          endDate: null,
          reference: '',
          invoice: '',
          recordNumber: '',
          recordNumberRange: '',
          email: '',
        },
      },
    });
  }
}

export const journalEntryStore = new JournalEntryStore();
