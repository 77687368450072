import React, { useEffect, useMemo } from 'react';
import {
  DateDisplay,
  useObservable,
  FormProps,
  ErrorDisplay,
  Feedback,
  useInput,
  required,
  useForm,
  useFormEvents,
  usePropertyConfiguration,
  AppColors,
  DataTable,
} from '@lib/common';
import { Room } from '@lib/state';
import { Box, Button, Grid, makeStyles, createStyles } from '@material-ui/core';
import {
  roomAccessService,
  roomAccessQuery,
  RoomAccessForms,
  employeeQuery,
  employeeService,
  GuestStay,
} from 'app/state';
import { EmployeeSelect, getDate, IEmployeeSelectOption } from 'app/shared';

const useStyles = makeStyles(theme =>
  createStyles({
    selectContainer: {
      paddingTop: theme.spacing(1),
    },
  })
);

export interface RoomAssignmentFields {
  userId: string;
}

interface Props extends FormProps<RoomAssignmentFields> {
  room: Room;
  stay?: GuestStay;
  occupants?: GuestStay[];
}

export const StaffAssignmentSection: React.FC<Props> = ({
  pending,
  success,
  error,
  onSubmit,
  onResetFeedback,
  room,
  stay,
  occupants,
}) => {
  const styles = useStyles();

  const [staffListResult] = useFormEvents(RoomAccessForms.StaffList);

  const userInput = useInput<string>('', { validators: [required()] });

  const form = useForm(() => {
    onResetFeedback();
    onSubmit({
      userId: userInput.value,
    });
  }, userInput);

  useEffect(() => {
    employeeService.getEmployees(room.propertyId);
    roomAccessService.loadRoomStaff(room.propertyId, room.roomNumber);
  }, [room, occupants]);

  const employees = useObservable(employeeQuery.employees);
  const roomAccess = useObservable(roomAccessQuery.allRoomAccess);
  const propertyConfiguration = usePropertyConfiguration(room.propertyId);
  const guests = stay?.guests;

  // produce access list
  const { accessList, userList } = useMemo(() => {
    // produce user list of all employees and stay guest (if available and room access is granted)
    const userMap = new Map(employees.map(e => [e.userId, { userId: e.userId, name: e.name }]));

    if (guests)
      guests.forEach(guest => {
        if (!userMap.has(guest.userId))
          userMap.set(guest.userId, {
            userId: guest.userId,
            name: guest.name,
          });
      });

    if (occupants) {
      occupants.forEach(occupant => {
        occupant.guests.forEach(guest => {
          if (!userMap.has(guest.userId))
            userMap.set(guest.userId, {
              userId: guest.userId,
              name: guest.name,
            });
        });
      });
    }

    const accessList = roomAccess.map(a => {
      const user = userMap.get(a.userId);
      if (user && user.name && user.name.first && user.name.last) {
        return { ...a, name: user.name };
      } else {
        return { ...a, name: { first: '', last: '' } };
      }
    });

    // remove users already assigned
    roomAccess.forEach(a => userMap.delete(a.userId));

    // produce users who are not assigned (does not include the guest)
    const userList = Array.from(userMap.values());

    return { accessList, userList };
  }, [roomAccess, employees, guests, occupants]);

  if (!propertyConfiguration) return null;

  const validUntil = (validUntil: string) => {
    return (
      <DateDisplay
        date={getDate(validUntil)}
        dateFormat="M/d/yy - h:mm a"
        timeZone={propertyConfiguration.timezone}
      />
    );
  };

  return (
    <Box>
      <ErrorDisplay boxProps={{ marginRight: 2 }} error={error} />
      <Feedback show={!!success} severity="success" onHide={onResetFeedback}>
        Staff assigned successfully!
      </Feedback>
      {!!staffListResult.error && (
        <ErrorDisplay boxProps={{ marginRight: 2 }} error={staffListResult.error} />
      )}
      {!staffListResult.error && (
        <>
          <DataTable
            items={accessList}
            getItemKey={item => item.userId}
            columns={[
              {
                title: 'Name',
                valueFactory: item => `${item.name?.last}, ${item.name?.first}`,
              },
              {
                title: 'Valid Until',
                valueFactory: item => validUntil(item.validUntil),
              },
              {
                title: 'Type',
                valueFactory: item => item.type,
              },
            ]}
          />
          <form {...form.bind}>
            <Grid container spacing={1} className={styles.selectContainer}>
              <Grid item>
                <EmployeeSelect
                  options={userList.map(
                    m => ({ name: m.name, userId: m.userId } as IEmployeeSelectOption)
                  )}
                  placeholder="Select Staff"
                  onEmployeeSelect={userId => {
                    if (userId) userInput.setValue(userId);
                  }}
                  iconColor={AppColors.DarkBlue}
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={pending === true || !form.valid}
                >
                  Add Staff
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </Box>
  );
};
