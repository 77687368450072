import { Reservations } from '@lib/state/api';
import { from } from 'rxjs';

import { map } from 'rxjs/operators';

import { saveAs } from 'file-saver';

import { dispatchForm, MediaTypes, CommonForm, PaginationState } from '@lib/state';
import { CorporateAccount } from '../corporate-account';
import { CorporateInvoice, InvoiceType } from './corporate-invoice.model';
import { corporateInvoiceStore, CorporateInvoiceStore } from './corporate-invoice.store';
import { CorporateInvoiceForms } from './corporate-invoice.forms';
import createInvoiceStatusStore, {
  CreateInvoiceStatusStore,
} from '@lib/state/create-invoice-status/create-invoice-status.store';

export class CorporateInvoiceService {
  constructor(
    private readonly ciStore: CreateInvoiceStatusStore,
    private readonly store: CorporateInvoiceStore,
    private readonly corporateAccountApi: Reservations.CorporateAccountApi
  ) {}

  getInvoices(
    corporateAccountId: CorporateAccount['id'],
    continuationToken?: string | null,
    limit?: number
  ) {
    from(
      this.corporateAccountApi.corporateaccountIdInvoiceGet(
        corporateAccountId,
        continuationToken ?? undefined,
        limit
      )
    )
      .pipe(map(response => response.data))
      .subscribe(({ data, ...pagination }) => {
        this.store.upsertMany(data);
        this.updatePaginationState(pagination);
      });
  }

  getInvoiceStatuses(corporateAccountIds: Array<CorporateAccount['id']>) {
    from(this.corporateAccountApi.corporateaccountInvoiceStatusesGet(corporateAccountIds))
      .pipe(map(x => x.data))
      .subscribe(x => this.ciStore.upsertMany(x));
  }

  createInvoice(
    corporateAccountId: CorporateAccount['id'],
    startDate: string,
    endDate: string,
    type: InvoiceType,
    isDraft = false
  ) {
    from(
      this.corporateAccountApi.corporateaccountInvoicePost({
        corporateAccounts: [corporateAccountId],
        startDate,
        endDate,
        isDraft,
        type,
      })
    )
      .pipe(
        map(({ data }) => data),
        dispatchForm(CorporateInvoiceForms.Create)
      )
      .subscribe(x => {
        this.store.upsertMany(x.data);
        this.ciStore.reset();
        this.ciStore.upsertMany(x.invoiceStatus);
      });
  }

  createMultipleInvoices(
    corporateAccounts: CorporateAccount[],
    startDate: string,
    endDate: string,
    type: InvoiceType,
    isDraft = false
  ) {
    this.ciStore.reset();
    const accounts = [...corporateAccounts];
    while (accounts.length > 0) {
      const selectedAccounts = accounts.splice(0, accounts.length > 9 ? 10 : accounts.length);
      from(
        this.corporateAccountApi.corporateaccountInvoicePost({
          corporateAccounts: selectedAccounts.map(a => a.id),
          startDate,
          endDate,
          isDraft,
          type,
        })
      )
        .pipe(
          map(({ data }) => data),
          dispatchForm(CorporateInvoiceForms.Create)
        )
        .subscribe(x => {
          this.store.upsertMany(x.data);
          this.ciStore.upsertMany(x.invoiceStatus);
        });
    }
  }

  removeInvoice({ id, corporateAccountId }: CorporateInvoice) {
    from(this.corporateAccountApi.corporateaccountIdInvoiceInvoiceIdDelete(corporateAccountId, id))
      .pipe(dispatchForm(CorporateInvoiceForms.Remove))
      .subscribe(() => this.store.remove(id));
  }

  resendInvoice({ id, corporateAccountId }: CorporateInvoice) {
    from(
      this.corporateAccountApi.corporateaccountIdInvoiceInvoiceIdResendGet(corporateAccountId, id)
    )
      .pipe(dispatchForm(CorporateInvoiceForms.Resend))
      .subscribe();
  }

  downloadInvoice({ id, corporateAccountId, invoiceNumber }: CorporateInvoice) {
    from(
      this.corporateAccountApi.corporateaccountIdInvoiceInvoiceIdGet(corporateAccountId, id, {
        responseType: 'blob',
      })
    )
      .pipe(
        map(x => new Blob([x.data], { type: MediaTypes.ExcelFile })),
        dispatchForm(CommonForm.Export)
      )
      .subscribe(x => saveAs(x, `${invoiceNumber}.xlsx`));
  }

  private updatePaginationState({ isDone, continuationToken }: PaginationState) {
    this.store.update(() => ({
      pagination: { isDone, continuationToken },
    }));
  }
}

export const corporateInvoiceService = new CorporateInvoiceService(
  createInvoiceStatusStore,
  corporateInvoiceStore,
  new Reservations.CorporateAccountApi()
);
