import React from 'react';

import { FormTextField, FormTextFieldProps, TextFieldProps } from '../form-text-field';

type Props = Omit<FormTextFieldProps, 'validationOptions' | 'type'> & TextFieldProps;

export const FormCityField: React.FC<Props> = ({
  autoComplete = 'address-level2',
  label = 'City',
  required,
  ...props
}) => {
  return (
    <FormTextField
      {...props}
      type="text"
      label={label}
      autoComplete={autoComplete}
      validationOptions={{ required, maxLength: 50 }}
      data-testid="formCityInput"
    />
  );
};
