import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import { Reservations, dispatchForm, Property, RoomType } from '@lib/state';
import {
  RateRestrictionStore,
  rateRestrictionStore,
  RateRestrictionUIState,
} from './room-rate-restriction.store';
import { RateRestriction, mapRateRestriction } from './room-rate-restriction.model';
import { RateRestrictionForms } from './room-rate-restriction.forms';
import { CreateRateRestrictionRequest } from '@lib/state/api/generated/reservations';
import { applyTransaction } from '@datorama/akita';

export class RateRestrictionService {
  constructor(
    private readonly store: RateRestrictionStore,
    private readonly rateRestrictionsApi: Reservations.RateRestrictionsApi
  ) {}

  getRateRestrictions(
    propertyId: Property['id'],
    roomTypeId: RoomType['id'],
    roomRatePlanId?: string
  ) {
    from(
      this.rateRestrictionsApi.raterestrictionsGet(
        propertyId,
        roomTypeId,
        roomRatePlanId ?? undefined
      )
    )
      .pipe(map(({ data }) => data.data.map(mapRateRestriction)))
      .subscribe(rateRestrictions =>
        applyTransaction(() => {
          this.updateUI({ roomTypeId, ratePlanId: roomRatePlanId ?? null });
          this.store.upsertMany(rateRestrictions);
        })
      );
  }

  getRateRestrictionById(rateRestrictionId: RateRestriction['id']) {
    from(this.rateRestrictionsApi.raterestrictionsIdGet(rateRestrictionId))
      .pipe(map(x => x.data))
      .subscribe(rateRestriction =>
        this.store.upsert(rateRestrictionId, mapRateRestriction(rateRestriction))
      );
  }

  createRateRestriction(request: CreateRateRestrictionRequest) {
    from(this.rateRestrictionsApi.raterestrictionsPost(request))
      .pipe(dispatchForm(RateRestrictionForms.Create))
      .pipe(map(x => x.data.data))
      .subscribe(rateRestriction =>
        this.store.upsert(rateRestriction.id, mapRateRestriction(rateRestriction))
      );
  }

  deleteRateRestriction(rateRestriction: RateRestriction) {
    from(this.rateRestrictionsApi.raterestrictionsIdDelete(rateRestriction.id))
      .pipe(
        dispatchForm(RateRestrictionForms.Delete),
        map(x => x.data)
      )
      .subscribe(response => {
        this.store.upsert(response.id, mapRateRestriction(response));
      });
  }

  private updateUI(uiState: Partial<RateRestrictionUIState>) {
    this.store.update(({ ui }) => ({ ui: { ...ui, ...uiState } }));
  }
}

export const rateRestrictionService = new RateRestrictionService(
  rateRestrictionStore,
  new Reservations.RateRestrictionsApi()
);
