import { addDays, startOfDay, addMinutes, differenceInMinutes } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

import { Properties, Reservations, AccessControl } from '../api';

export type PropertyConfiguration = Reservations.PropertyConfigurationModel & {
  features: Array<FeatureFlag>;
};
export type PropertyPolicy = Reservations.PropertyPolicyModel;
export type PropertyAmenity = Properties.PropertyAmenityModel;

export type AccessControlType = AccessControl.AccessControlType;
export const AccessControlType = AccessControl.AccessControlType;

enum DeviceType {
  AccessControlQRCodeScanner = 'AccessControlQRCodeScanner',
}

export const ArmDeviceProvisioningPolicyType = {
  ...AccessControl.ArmDeviceProvisioningPolicyType,
  ...DeviceType,
};
export type ArmDeviceProvisioningPolicyType =
  | AccessControl.ArmDeviceProvisioningPolicyType
  | DeviceType;

export type MdmEnrollmentTokenData = AccessControl.MdmEnrollmentTokenResponse;

export type FeatureFlag = ArrayElement<typeof FeatureFlags>;
export const FeatureFlags = ['GuestPhotos', 'GuestRelationships', 'AllowMultiRoomBooking'] as const;

type ArrayElement<
  ArrayType extends readonly unknown[]
> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type Property = Properties.PropertyModel & {
  provisioningCode?: string | null;
  configuration?: PropertyConfiguration;
  amenities?: Array<PropertyAmenity>;
};

interface Schedule {
  timezone: string;
  daySwitchesTimeInMinutes: number;
}

export function getDate(schedule: Schedule, plusDays?: number): string;
export function getDate(schedule: Schedule, plusDays: number, asDate: true): Date;
export function getDate(
  { timezone, daySwitchesTimeInMinutes }: Schedule,
  plusDays = 0,
  asDate = false
): string | Date {
  // get the current property-zoned instant
  const now = utcToZonedTime(new Date().toJSON(), timezone);
  const today = startOfDay(now);

  // check if we are before or after the property day switches time
  const timeInMinutes = differenceInMinutes(now, today);

  let date: Date;
  if (timeInMinutes < daySwitchesTimeInMinutes) {
    // the date is actually yesterday's date
    date = addDays(today, -1 + plusDays);
  } else {
    date = addDays(today, plusDays);
  }

  // get the date + time, formatted as a string with the property time zone
  const dateTime = addMinutes(date, daySwitchesTimeInMinutes);

  if (asDate) return dateTime;

  return format(dateTime, `yyyy-MM-dd'T'HH:mm:ssXXX`, { timeZone: timezone });
}
