import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import { Properties } from '../api';
import { userStaysStore, UserStaysStore } from './user-stays.store';
import { ReservationApi } from '../api/generated/reservations';
import { setLoading, applyTransaction } from '@datorama/akita';
import { UserStayModel } from '../api/generated/properties';
import { SleepSchedule } from '../models.common';

export class UserStaysService {
  constructor(
    private store: UserStaysStore,
    private userStaysApi: Properties.UserStaysApi,
    private reservationApi: ReservationApi
  ) {}

  clearActive(): void {
    this.store.setActive(null);
  }

  getUserStays(): void {
    from(this.userStaysApi.userstaysGet())
      .pipe(map(response => response.data.data))
      .subscribe(data => {
        this.store.upsertMany(data);
      });
  }

  toggleSleepSchedule(stay: UserStayModel, sleepSchedule: SleepSchedule) {
    from(
      this.reservationApi.reservationIdSleepSchedulePut(stay.reservationId, {
        sleepSchedule: sleepSchedule,
      })
    )
      .pipe(
        map(data => data.data.data),
        setLoading(this.store)
      )
      .subscribe(data =>
        applyTransaction(() =>
          this.store.upsert(stay!.id, { ...stay, sleepSchedule: data.affiliation?.sleepSchedule })
        )
      );
  }
}

export const userStaysService = new UserStaysService(
  userStaysStore,
  new Properties.UserStaysApi(),
  new ReservationApi()
);
