/* eslint-disable */
import { ArmPrintKeysRequest, navMenuService } from '@lib/state';
import { KioskType as ArmKioskType } from '@lib/common';

import * as H from 'history';
import { handleKioskLogout, isFrontDeskOrPortal } from '../utils/handle-kiosk-logout';

export interface ArmNativeWindow extends Window {
  ArmKioskJsInterface: ArmKioskJsInterface | undefined;
}
declare let window: ArmNativeWindow;

interface ArmKioskJsInterface {
  scanQRCode: () => void;
  printPage: (headContent: string, bodyContent: string) => void;
  getQRPrinterStatus: () => string;
  getPropertyInfo: () => void;
  getKioskType: () => ArmKioskType;
  updateUI: (request: string) => void;
  forceLogoutOnBackAction: () => void;
  forceLogoutOnBackActionOn: (route: string) => void;
  printQRCode: (key: string) => void;
  handleUserInteraction: () => void;
  hasHardwareKeyboard: () => boolean;
}

interface ArmUIUpdateRequest {
  isNavigationVisible: boolean;
  invertNavigationBar: boolean;
  isFooterLogoVisible: boolean;
  backButtonVisible: boolean | undefined;
  menuButtonVisible: boolean | undefined;
}

export interface ArmPropertyModel {
  id: string;
  name: string;
}

export interface ArmQRCode {
  user: string;
  keys: [string];
}

export interface ArmQRCodeResult {
  type: ArmQRCodeResultType;
  qrCode?: ArmQRCode | undefined;
}

export type QRCodeResultCallback = (result?: ArmQRCodeResult) => void;
export type PropertyInfoResultCallback = (property: ArmPropertyModel) => void;
type ArmNativeMessage = {
  type: ArmNativeMessageType;
  payload: any;
};

enum ArmNativeMessageType {
  QRCodeResult = 'QRCodeResult',
  GetPropertyInfo = 'GetPropertyInfo',
  LogoutUser = 'LogoutUser',
  ToggleNavMenu = 'ToggleNavMenu',
}

enum ArmQRPrinterStatus {
  NoPrinterAvailable = 'NoPrinterAvailable',
  HasSelectedPrinter = 'HasSelectedPrinter',
  HasPairedPrinter = 'HasPairedPrinter',
}

export enum ArmQRCodeResultType {
  Success = 'Success',
  Failed = 'FailFailededToScan',
  CancelledByUser = 'CancelledByUser',
}

var qrCodeResultCallback: QRCodeResultCallback | null = null;
var propertyResultCallback: PropertyInfoResultCallback | null = null;
var history: H.History | null = null;
var kioskType: ArmKioskType = ArmKioskType.Undefined;
var hasKeyboard: boolean | null = null;

type ArmQRPrinterStatusStrings = keyof typeof ArmQRPrinterStatus;
type HistoryListenerCallback = () => void;
var historyListener: HistoryListenerCallback | null = null;

const nativeListener = (evt: MessageEvent): any => {
  // Check origin for security
  if (evt.origin !== 'https://appassets.androidplatform.net') {
    // Only log if isKiosk
    if (isKiosk()) {
      console.log(
        'Rejecting request origin ' + evt.origin + ' !== https://appassets.androidplatform.net'
      );
    }
    return;
  }

  let armMessage = evt.data as ArmNativeMessage;

  if (armMessage.type === ArmNativeMessageType.LogoutUser) {
    // Handle logout request
    handleKioskLogout();
    const _isFrontDesk = isFrontDeskOrPortal();
    if (history) {
      const currentPath = history.location.pathname;
      const loggedOutURL =
        _isFrontDesk && currentPath.includes('/kiosk') && !currentPath.includes('/front-desk')
          ? '/reservations'
          : _isFrontDesk
          ? '/kiosk/front-desk'
          : '/kiosk';
      history.replace(loggedOutURL);
    }

    console.log('Logging out user , history = ', history !== null);
  } else if (armMessage.type === ArmNativeMessageType.QRCodeResult) {
    if (armMessage.payload) {
      let qrCode = JSON.parse(armMessage.payload) as ArmQRCodeResult;
      qrCodeResultCallback?.(qrCode);
      return;
    }

    qrCodeResultCallback?.(undefined);
  } else if (armMessage.type === ArmNativeMessageType.GetPropertyInfo) {
    let property = JSON.parse(armMessage.payload) as ArmPropertyModel;
    if (property) {
      propertyResultCallback?.(property);
    }
  } else if (armMessage.type === ArmNativeMessageType.ToggleNavMenu) {
    navMenuService.toggleNavDrawer();
  }
};

export function isKiosk(): boolean {
  return typeof window.ArmKioskJsInterface != 'undefined';
}

export function isFrontDesk(): boolean {
  return getKioskType() === ArmKioskType.FrontDesk;
}

export function getKioskType(): ArmKioskType {
  kioskType = window.ArmKioskJsInterface?.getKioskType() ?? ArmKioskType.Undefined;

  return kioskType;
}

export function hasHardwareKeyboard(): boolean {
  if (!isKiosk()) return true;
  if (hasKeyboard) return hasKeyboard;

  hasKeyboard = window.ArmKioskJsInterface?.hasHardwareKeyboard() ?? null;
  return hasKeyboard ?? false;
}

export function scanQRCode(callback: QRCodeResultCallback) {
  qrCodeResultCallback = callback;
  window.ArmKioskJsInterface?.scanQRCode();
}

export function printPage(headContent: string, bodyContent: string) {
  window.ArmKioskJsInterface?.printPage(headContent, bodyContent);
}

export function isQRPrinterAvailable() {
  var statusString = window.ArmKioskJsInterface?.getQRPrinterStatus();
  if (!statusString) return false;

  var printerStatus = ArmQRPrinterStatus[statusString as ArmQRPrinterStatusStrings];

  // as long as there is a selected printer or its paired to a print
  // the user should be able to print via the QR Printer
  return printerStatus != ArmQRPrinterStatus.NoPrinterAvailable;
}

export function forceLogoutOnBackAction() {
  window.ArmKioskJsInterface?.forceLogoutOnBackAction();
}

export function forceLogoutOnBackActionOn(route: string) {
  window.ArmKioskJsInterface?.forceLogoutOnBackActionOn(route);
}

export function updateUI(request: ArmUIUpdateRequest) {
  window.ArmKioskJsInterface?.updateUI(JSON.stringify(request));
}

export function getPropertyInfo(callback: PropertyInfoResultCallback) {
  propertyResultCallback = callback;
  window.ArmKioskJsInterface?.getPropertyInfo();
}

export function printQRCodeNoGuest(key: string) {
  printQRCode({
    keys: [
      {
        key: key,
        guestName: null,
      },
    ],
    company: null,
  });
}

export function printQRCode(keysRequest: ArmPrintKeysRequest) {
  window.ArmKioskJsInterface?.printQRCode(JSON.stringify(keysRequest));
}

export function startNativeBridgeListeners(browserHistory?: H.History) {
  watchPostMessages();
  watchHistory(browserHistory);
  watchKeyUpEvents();
}

function watchPostMessages() {
  window.addEventListener('message', nativeListener);
}

function watchHistory(browserHistory?: H.History) {
  if (browserHistory && historyListener === null) {
    history = browserHistory;

    historyListener = browserHistory.listen(location => {
      toggleUIState(location.pathname);
    });
  }
}

function watchKeyUpEvents() {
  //start keyboard Listener
  window.addEventListener('keyup', event => {
    window.ArmKioskJsInterface?.handleUserInteraction();
  });
}

function toggleUIState(currentPath: string) {
  const _isFrontDesk: boolean = isFrontDesk();
  const dashboardRoutes: string[] = _isFrontDesk ? ['/kiosk/front-desk'] : ['/kiosk'];
  const backButtonDisabledRoutes: string[] = _isFrontDesk ? ['/', '/tape-chart'] : ['/kiosk'];
  const isDashboard: boolean = dashboardRoutes.includes(currentPath);
  const hasBackButton: boolean = !backButtonDisabledRoutes.includes(currentPath);
  const usingKioskRoute: boolean = currentPath.includes('/kiosk');

  updateUI({
    isNavigationVisible: !isDashboard,
    invertNavigationBar: _isFrontDesk && !usingKioskRoute,
    isFooterLogoVisible: (_isFrontDesk && isDashboard) || (!_isFrontDesk && !isDashboard),
    backButtonVisible: hasBackButton,
    menuButtonVisible: _isFrontDesk && !usingKioskRoute,
  });
}
