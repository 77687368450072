import React, { useCallback } from 'react';
import { DialogContent } from '@material-ui/core';

import { roomTypeQuery, sessionQuery } from '@lib/state';
import { FormHub, StyledDialog, useObservable } from '@lib/common';
import { rateRestrictionService, RateRestrictionTypeEnum, roomRatePlanQuery } from 'app/state';
import {
  CreateRateRestrictionForm,
  CreateRateRestrictionFormFields,
} from './create-rate-restriction.form';
import { format } from 'date-fns';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const CreateRateRestrictionModal: React.FC<Props> = ({ open, onClose }) => {
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const activeRoomType = useObservable(roomTypeQuery.activeRoomType, 'async');
  const activeRatePlan = useObservable(roomRatePlanQuery.activeRatePlan, 'async');

  const onSubmit = useCallback(
    ({ startDate, endDate, ...values }: CreateRateRestrictionFormFields) => {
      if (propertyId && activeRoomType) {
        rateRestrictionService.createRateRestriction({
          propertyId,
          roomTypeId: activeRoomType.id,
          roomRatePlanId: activeRatePlan?.id,
          startDate: startDate && format(startDate, 'yyyy-MM-dd'),
          endDate: endDate && format(endDate, 'yyyy-MM-dd'),
          ...values,
        });
        onClose();
      }
    },
    [propertyId, activeRoomType, activeRatePlan, onClose]
  );

  return (
    <StyledDialog title="Create Rate Restriction" open={open} onClose={onClose} keepMounted>
      <DialogContent>
        <FormHub<CreateRateRestrictionFormFields>
          formOptions={{
            defaultValues: {
              minLOS: 1,
              maxLOS: 28,
              type: RateRestrictionTypeEnum.Open,
            },
          }}
          onSubmit={onSubmit}
        >
          <CreateRateRestrictionForm />
        </FormHub>
      </DialogContent>
    </StyledDialog>
  );
};
