import { SleepSchedule } from '@lib/state';
import { InHouseGuest } from './report.model';

export type InHouseGuestExportModel = {
  Date: string;
  ReservationId: number;
  Location: string;
  Company: string;
  SleepSchedule?: SleepSchedule | null;
  Guest: string;
  RoomNumber: string;
  CheckIn: string;
  CheckOut: string;
  Source: string;
};

export function exportInHouseGuests(guest: InHouseGuest): InHouseGuestExportModel {
  return {
    Date: guest.date,
    ReservationId: guest.reservationNumber,
    Location: guest.propertyName,
    Company: guest.companyName,
    Guest: guest.guestList
      .map(({ name, sId }) => `${name.last}, ${name.first} (${sId})`)
      .join(', '),
    SleepSchedule: guest.sleepSchedule,
    RoomNumber: guest.roomNumber,
    CheckIn: guest.checkIn,
    CheckOut: guest.checkOut,
    Source: guest.bookingSource ?? '',
  };
}
