import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container, Typography } from '@material-ui/core';

import { AccountForms, sessionService, sessionQuery, FormStatus } from '@lib/state';
import { SignInForm, useObservable, useFormEvents, usePageTitle, kioskStyles } from '@lib/common';

export const AccountLoginPage: React.FC<RouteComponentProps> = props => {
  const { kioskForm, formInstructions } = kioskStyles();
  const history = props.history;
  const queryString = props.location.search;
  const params = new URLSearchParams(queryString);
  const nextRoute = params.get('next') ?? `/kiosk/reservations`;
  const shouldPop = params.get('popOnLogin') === 'true';

  usePageTitle('Account Login');

  const isLoggedIn = useObservable(sessionQuery.isLoggedIn);

  const [{ status, error }, resetForm] = useFormEvents(AccountForms.Login);

  useEffect(() => {
    if (isLoggedIn) {
      if (shouldPop) {
        history.goBack();
      } else {
        history.push(nextRoute);
      }
    }
  }, [isLoggedIn, history, shouldPop, nextRoute]);

  return (
    <Container maxWidth="md" className={kioskForm}>
      <Typography
        variant="h4"
        color="textPrimary"
        align="center"
        paragraph
        className={formInstructions}
      >
        Enter your account email and password to login
      </Typography>
      <SignInForm
        onSubmit={({ username, password }) => sessionService.login(username, password)}
        onResetFeedback={resetForm}
        usernameLabel="Email"
        pending={status === FormStatus.Pending}
        success={status === FormStatus.Success}
        error={error}
        buttonColor="primary"
      />
    </Container>
  );
};
