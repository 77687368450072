import React, { ReactNode, useEffect, useMemo } from 'react';
import { useFormContext, ValidationOptions } from 'react-hook-form';
import {
  DatePicker,
  DatePickerProps as MuiDatePickerProps,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightFormat as format, parse } from 'date-fns';

import { useFieldError } from './form-methods';

type OmittedProps = 'helperText';

export type DatePickerProps<TOmit extends keyof MuiDatePickerProps = never> = Omit<
  KeyboardDatePickerProps,
  OmittedProps | TOmit
>;

interface FormTimePickerProps {
  name: string;
  validationOptions?: ValidationOptions;
  helperText?: ReactNode | ((errors: any) => string);
}

const dateFormat = 'yyyy-MM-dd';

export const FormDatePicker: React.FC<
  FormTimePickerProps & DatePickerProps<'value' | 'onChange' | 'onFocus' | 'onBlur'>
> = ({
  name,
  validationOptions,
  defaultValue,
  helperText,
  label = 'Date',
  error,
  InputLabelProps,
  ...props
}) => {
  const { errors, register, setValue, watch } = useFormContext();

  useEffect(() => {
    register(name, validationOptions);
  }, []);

  const formValue = watch(name, defaultValue);

  const fieldError = useFieldError(name, errors);

  const value = useMemo(() => (!!formValue ? parse(formValue, dateFormat, new Date()) : null), [
    formValue,
  ]);

  return (
    <DatePicker
      name={name}
      value={value}
      label={InputLabelProps?.prefix ? `${InputLabelProps.prefix} ${label}` : label}
      onChange={e => setValue(name, !!e ? format(e, dateFormat) : null, true)}
      error={!!fieldError}
      InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
      autoOk
      clearable
      {...props}
    />
  );
};
