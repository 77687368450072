import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, createStyles, Grid, IconButton, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import { Room, roomsQuery, roomsService, sessionQuery } from '@lib/state';
import { AppColors, Section, useLookup, useObservable, DataTable, IDataTableColumn } from '@lib/common';
import { Area } from 'app/state';
import { AddRooms } from 'app/shared/components/add-rooms';

const useStyles = makeStyles(
  createStyles({
    added: {
      '& $item': {
        fontStyle: 'italic',
        fontWeight: 'bold',
      },
    },
    removed: {
      '& $item': {
        textDecoration: 'line-through',
        opacity: '50%',
      },
    },
    item: {},
  })
);

interface Props {
  area: Area;
}

export const AreaRoomsForm: React.FC<Props> = ({ area }) => {
  const styles = useStyles();

  const { register, unregister, watch, setValue } = useFormContext();
  const roomIds: Array<string> = watch('roomIds', []);

  const { added, removed, items } = useMemo(() => {
    const areaRooms = new Set(area.rooms?.map(x => x.id));

    return {
      added: new Set(roomIds.filter(x => !areaRooms.has(x))),
      removed: new Set(area.rooms?.map(x => x.id).filter(x => !roomIds.includes(x))),
      items: Array.from(new Set([...roomIds, ...areaRooms])),
    };
  }, [area, roomIds]);

  useEffect(() => {
    register('roomIds');

    return () => unregister('roomIds');

    // eslint-disable-next-line
  }, []);

  const propertyId = useObservable(sessionQuery.propertyId, 'async');

  const rooms = useObservable(roomsQuery.rooms);
  const roomLookup = useLookup(rooms, x => x.id);

  useEffect(() => {
    if (!!propertyId) roomsService.getRooms(propertyId);
  }, [propertyId]);

  const onRoomSelected = useCallback((room: Room) => setValue('roomIds', [room.id, ...roomIds]), [
    roomIds,
    setValue,
  ]);

  const onMultipleRoomsSelected = useCallback(
    (ids: string[]) => {
      setValue('roomIds', [...ids, ...roomIds]);
    },
    [roomIds, setValue]
  );

  const onRoomRemoved = useCallback(
    (roomId: Room['id']) =>
      setValue(
        'roomIds',
        roomIds.filter(x => x !== roomId)
      ),
    // eslint-disable-next-line
    [rooms, roomIds, setValue]
  );

  const columns: Array<IDataTableColumn<Room['id']>> = useMemo(
    () => [
      {
        title: 'Room Number',
        itemClass: styles.item,
        valueFactory: x => (
          <>
            {removed.has(x) ? (
              <Box display="inline-block" color={AppColors.Green} mr={1} clone>
                <IconButton size="small" onClick={() => onRoomSelected(roomLookup.get(x)!)}>
                  <FontAwesomeIcon icon="plus" size="sm" fixedWidth />
                </IconButton>
              </Box>
            ) : (
              <Box display="inline-block" color={AppColors.Red} mr={1} clone>
                <IconButton size="small" onClick={() => onRoomRemoved(x)}>
                  <FontAwesomeIcon icon="times" size="sm" fixedWidth />
                </IconButton>
              </Box>
            )}
            {roomLookup.get(x)?.roomNumber}
          </>
        ),
      },
      {
        title: 'Zone',
        itemClass: styles.item,
        valueFactory: x => roomLookup.get(x)?.roomZoneName,
      },
      {
        title: 'Type',
        itemClass: styles.item,
        valueFactory: x => roomLookup.get(x)?.roomType.name,
      },
    ],
    [roomLookup, removed, styles.item, onRoomRemoved, onRoomSelected]
  );

  return (
    <Section title="Area Rooms" textAlign="left">
      <Box pb={2}>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item md={4} xs={12}>
            <AddRooms
              rooms={rooms}
              previouslySelected={roomIds}
              onSubmission={onMultipleRoomsSelected}
            />
          </Grid>
        </Grid>
      </Box>
      <DataTable
        items={items}
        getItemKey={i => i}
        columns={columns}
        rowClass={x =>
          clsx({
            [styles.added]: added.has(x),
            [styles.removed]: removed.has(x),
          })
        }
      />
    </Section>
  );
};
