import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { guestManagementQuery, guestManagementService } from '../../state/guest-management';
import { useObservable, usePageTitle, Section } from '@lib/common';
import { GuestLookup, GuestFilters } from '../components';
import { VipStatus } from '@lib/state';

export const GuestSearchPage: React.FC<RouteComponentProps> = () => {
  const guests = useObservable(guestManagementQuery.searchResults);

  const filters = useObservable(guestManagementQuery.filters);
  const pagination = useObservable(guestManagementQuery.pagination);

  usePageTitle('Guests');

  const onChange = useCallback(
    (x: GuestFilters) =>
      guestManagementService.getGuests({
        ...x,
        vipStatus: VipStatus[x.vipStatus as keyof typeof VipStatus],
      }),
    []
  );

  const loadMore = () => guestManagementService.getGuests(filters, pagination.continuationToken);
  const exportGuests = (filters: GuestFilters) => {
    guestManagementService.exportMyGuests({
      ...filters,
      vipStatus: VipStatus[filters.vipStatus as keyof typeof VipStatus],
    });
  };

  return (
    <Section>
      <GuestLookup
        guests={guests}
        onChange={onChange}
        filters={filters}
        isDone={pagination.isDone}
        onExport={exportGuests}
        loadMore={loadMore}
      />
    </Section>
  );
};
