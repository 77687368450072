import {
  getIsCancelledNoShowOrCheckedOut,
  getIsOpenAndFlex,
  PropertyPolicy,
  Reservation,
} from '@lib/state';
import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { RebookButton } from '../../reservations/rebook-button';

interface Props extends Omit<ButtonProps, 'action' | 'onClick'> {
  reservation: Reservation;
  createForGuest: boolean;
}

const GuestRebookComponent: React.FC<Props> = ({
  reservation,
  createForGuest = false,
  ...props
}) => {
  const isOpenAndFlex = getIsOpenAndFlex(reservation);
  const isCancelledNoShowOrCheckedOut = getIsCancelledNoShowOrCheckedOut(reservation);

  if (!isOpenAndFlex && !isCancelledNoShowOrCheckedOut) {
    return null;
  }

  return (
    <>
      <RebookButton
        reservation={reservation}
        color="secondary"
        variant="contained"
        isManagerOverrideRequired={false}
        overrideable={false}
        createForGuest={createForGuest}
        rebookOnly={!isOpenAndFlex && isCancelledNoShowOrCheckedOut}
        {...props}
      />
    </>
  );
};

export const GuestRebookButton = GuestRebookComponent;
