import React, { useEffect, useMemo } from 'react';
import { makeStyles, createStyles, Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { inventoryAdjustmentService, inventoryAdjustmentQuery, ConsumedHolds } from '@lib/state';
import { StyledDialog, Loading, useObservable, AppColors } from '../index';
import { CorporateAccountModel } from '@lib/state/api/generated/reservations';
import { addDays } from 'date-fns';

const useStyles = makeStyles(theme =>
  createStyles({
    dialog: {
      padding: '0 16px',
    },
    sectionHeader: {
      display: 'flex',
      position: 'sticky',
      top: 0,
      zIndex: 100,

      padding: theme.spacing(1),
      alignItems: 'center',

      color: theme.palette.common.white,
      backgroundColor: AppColors.DarkBlue,
      fontWeight: 'bold',
      fontSize: '200%',
    },
    sectionContainer: {
      marginBottom: theme.spacing(1),
    },
    sectionBody: {
      marginTop: theme.spacing(1),
      padding: `${theme.spacing(1)}px 0`,
    },
    title: {
      marginLeft: 'auto',
    },
  })
);

interface Row {
  date: string;
  quantity: number;
  roomTypeId: string;
}

interface RoomHoldsProps {
  propertyId: string;
  open: boolean;
  corporateAccount: CorporateAccountModel;
  roomTypeId: string;
  checkIn: Date;
  checkOut: Date;
  onClose: () => void;
  consumedHolds?: ConsumedHolds;
}

export const RoomHoldsModal: React.FC<RoomHoldsProps> = ({
  propertyId,
  open,
  corporateAccount,
  roomTypeId,
  checkIn,
  checkOut,
  onClose,
  consumedHolds,
}) => {
  const inventoryAdjustmentDateGroups = useObservable(
    inventoryAdjustmentQuery.inventoryAdjustmentDateGroups,
    'async'
  );

  useEffect(() => {
    if (propertyId) {
      inventoryAdjustmentService.getInventoryAdjustments(
        propertyId,
        corporateAccount.id,
        roomTypeId,
        checkIn,
        addDays(checkOut, -1)
      );
    }
  }, [propertyId, corporateAccount, roomTypeId, checkIn, checkOut]);

  const roomHolds = useMemo(() => {
    let rows: Row[] = new Array();
    inventoryAdjustmentDateGroups?.forEach(inv => {
      inv.corporateInventories.forEach(ci =>
        ci.inventories.forEach(i => {
          rows.push({ date: inv.date, quantity: -1 * i.quantity, roomTypeId: i.roomTypeId });
        })
      );
    });
    return rows;
  }, [inventoryAdjustmentDateGroups]);

  if (!open) return null;

  if (!inventoryAdjustmentDateGroups) return <Loading />;

  return (
    <StyledDialog title="Room Hold Availability" open={open} onClose={onClose}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              {consumedHolds && <TableCell align="left">Consumed Holds</TableCell>}
              <TableCell align="left">Available Holds</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roomHolds.map(row => (
              <TableRow key={row.date}>
                <TableCell component="th" scope="row">
                  {row.date}
                </TableCell>
                {consumedHolds && (
                  <TableCell align="left">
                    {consumedHolds[row.date] ? consumedHolds[row.date].consumed : 0}
                  </TableCell>
                )}
                <TableCell align="left">{row.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledDialog>
  );
};
