import { AppActions } from 'app/shared/actions/app-actions-interface';
import { printPage } from 'app/shared/native-interface/nativebridge';

export class NativeActions implements AppActions {
  onPrint(): void {
    const documentPrintWindow = document.getElementById('printWindow') as HTMLIFrameElement;
    if (documentPrintWindow.contentWindow) {
      const bodyContent = documentPrintWindow.contentWindow.document.body.innerHTML;
      const headContent = documentPrintWindow.contentWindow.document.head.innerHTML;
      printPage(headContent, bodyContent);
    }
  }
}
