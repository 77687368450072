import React, { useEffect } from 'react';
import {
  useFormEvents,
  ErrorDisplay,
  Loading,
  useObservable,
  ResponsiveContainer,
} from '@lib/common';
import { accountService, AccountForms, FormStatus } from '@lib/state';
import { Typography } from '@material-ui/core';
import { VerifyPhoneNumberComponent } from '@lib/common';
import { guestManagementQuery, guestManagementService } from 'app/state';
import { GuestDetailsRouteProps } from 'app/guest-management/guest-detail-route-props';

export const VerifyPhoneNumberPage: React.FC<GuestDetailsRouteProps> = ({ match }) => {
  const userId = match.params.userId;
  const guest = useObservable(guestManagementQuery.activeGuest, 'async');
  const [result] = useFormEvents(AccountForms.SendPhoneNumberVerificationCode);

  useEffect(() => {
    guestManagementService.getGuestById(userId);
    guestManagementService.select(userId);
  }, [userId]);

  useEffect(() => {
    if (guest && guest.contact.phone)
      accountService.sendVerifyPhoneNumberCode(guest.contact.phone, guest.userId);
  }, [guest]);

  if (!guest) return null;

  const onResendToken = () => {
    if (guest.contact.phone)
      accountService.sendVerifyPhoneNumberCode(guest.contact.phone, guest.userId);
  };

  const sendVerificationToken = () => {
    switch (result.status) {
      case FormStatus.Success: {
        return (
          <VerifyPhoneNumberComponent
            phone={guest.contact.phone ?? undefined}
            userId={guest.userId}
            sendTokenResult={result.status}
            resendToken={onResendToken}
          />
        );
      }
      case FormStatus.Error: {
        return (
          <ErrorDisplay error={result.error}>
            <Typography align="center">
              There was error sending a verification token to your registered phone number.
            </Typography>
          </ErrorDisplay>
        );
      }
      default:
        return <Loading />;
    }
  };
  return <ResponsiveContainer>{sendVerificationToken()}</ResponsiveContainer>;
};
