import React, { ReactNode, useCallback } from 'react';
import { Controller, useFormContext, ValidationOptions } from 'react-hook-form';
import { TextField } from '@material-ui/core';

import { getHelperText, useFieldError } from './form-methods';
import { TextFieldProps } from './form-text-field';

export interface FormCurrencyFieldProps {
  name: string;
  validationOptions?: ValidationOptions;
  helperText?: ReactNode | ((errors: any) => string);
}

export const FormCurrencyField: React.FC<
  FormCurrencyFieldProps & TextFieldProps<'onChange' | 'onBlur' | 'onFocus'>
> = ({ name, helperText, validationOptions, error, ...props }) => {
  const { errors } = useFormContext();

  const fieldError = useFieldError(name, errors);
  const text = getHelperText(errors, helperText ?? fieldError?.message);

  return (
    <Controller
      name={name}
      as={TextField}
      inputProps={{ style: { textAlign: 'right' } }}
      rules={validationOptions}
      type="text"
      error={error || !!fieldError}
      helperText={text}
      {...props}
    />
  );
};
