import React from 'react';
import ReactDOM from 'react-dom';

import { configureStores } from '@lib/state';
import { registerIcons } from '@lib/common';
import '@lib/common/assets/fonts/fonts.css';

import { App } from './app';

configureStores();
registerIcons();

ReactDOM.render(<App />, document.getElementById('root'));
