import React from 'react';
import { Switch, SwitchProps, createStyles, makeStyles } from '@material-ui/core';
import { AppColors } from '../theme';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: AppColors.Green,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: AppColors.Green,
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
      color: theme.palette.common.white,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.grey[300],
      opacity: 1,
    },
    checked: {},
    focusVisible: {},
  })
);

export const IOSSwitch: React.FC<SwitchProps> = ({ ...props }: SwitchProps) => {
  const classes = useStyles();

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};
