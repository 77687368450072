import { QueryEntity, Order } from '@datorama/akita';
import { switchMap } from 'rxjs/operators';

import { RateRestrictionState, rateRestrictionStore } from './room-rate-restriction.store';

export class RateRestrictionQuery extends QueryEntity<RateRestrictionState> {
  rateRestrictions = this.select(({ ui }) => ui).pipe(
    switchMap(({ ratePlanId, roomTypeId }) => {
      return this.selectAll({
        filterBy: [
          x => x.roomRatePlanId === ratePlanId || ratePlanId == null,
          x => x.roomTypeId === roomTypeId || roomTypeId == null,
        ],
        sortBy: 'createdTime',
        sortByOrder: Order.DESC,
      });
    })
  );
}

export const rateRestrictionQuery = new RateRestrictionQuery(rateRestrictionStore);
