import { useIsFetching, useQuery, useQueryClient } from 'react-query';
import { formatISO } from 'date-fns';
import {
  delay,
  formatContact,
  getCancelToken,
  Reservations,
  roomRateQuery,
} from '@lib/state';
import { useObservable } from '../../utils';
import {
  ValidateBookingResponse,
  ValidateBulkReservationChangesRequest,
  ValidateBulkReservationChangesResponse,
} from '@lib/state/api/generated/reservations';

const reservationApi = new Reservations.ReservationApi();
const queryKeyScope = ['booking', 'validation'];

interface BookingValidationParams {
  corporateAccountId?: string | null;
  guests: Array<Reservations.CreateGuestModel>;
}

export function useBookingValidationQuery({ corporateAccountId, guests }: BookingValidationParams) {
  const { propertyId, checkInDate, checkOutDate } = useObservable(roomRateQuery.uiState);
  const request: Partial<Reservations.ValidateBookingRequest> = {
    propertyId,
    checkInDate: checkInDate ? formatISO(checkInDate, { representation: 'date' }) : undefined,
    checkOutDate: checkOutDate ? formatISO(checkOutDate, { representation: 'date' }) : undefined,
    corporateAccountId,
    guests: guests?.map(m => ({
      ...m,
      contact: formatContact({
        contact: m.contact,
      })?.contact,
    })),
  };

  const enabled = isEnabled(request);

  const query = useQuery({
    queryKey: [...queryKeyScope, request],
    queryFn: async ({ signal }) => {
      if (!enabled) throw new Error('Request is not enabled');

      await delay(500, signal); // debounce by 500ms

      const { data } = await reservationApi.reservationValidatePost(
        request as Reservations.ValidateBookingRequest,
        getCancelToken(signal)
      );
      return data;
    },
    enabled,
    cacheTime: 0,
    staleTime: 0,
  });

  return query;
}

interface BulkBookingValidationParams {
  corporateAccountId: string;
  propertyId: string;
  reservationIds: Array<string>;
  checkInDate?: Date;
  checkOutDate?: Date;
}

function validateBookingConverter(
  bulk: ValidateBulkReservationChangesResponse
): ValidateBookingResponse {
  let validateBooking: ValidateBookingResponse = {
    isValid: bulk.isValid,
    employeeNumberEnabled: bulk.employeeNumberEnabled,
    message: null,
    reservations: [],
  };

  if (bulk.data && bulk.data.length > 0) {
    for (let dataItem of bulk.data) {
      if (validateBooking.message === null) {
        //Use the message from the first item
        validateBooking.message = dataItem.message;
      }

      if (dataItem.reservations && dataItem.reservations.length > 0) {
        if (validateBooking.reservations) {
          validateBooking.reservations.push(...dataItem.reservations);
        } else {
          validateBooking.reservations = [...dataItem.reservations];
        }
      }
    }
  }

  return validateBooking;
}

export function useBulkBookingValidationQuery({
  corporateAccountId,
  propertyId,
  reservationIds,
  checkInDate,
  checkOutDate,
}: BulkBookingValidationParams) {
  const request: ValidateBulkReservationChangesRequest = {
    propertyId,
    corporateAccountId,
    reservationIds,
    checkInDate: checkInDate ? formatISO(checkInDate, { representation: 'date' }) : undefined,
    checkOutDate: checkOutDate ? formatISO(checkOutDate, { representation: 'date' }) : undefined,
  };
  const enabled = isBulkEnabled(request);
  const query = useQuery({
    queryKey: [queryKeyScope, request],
    queryFn: async ({ signal }) => {
      await delay(500, signal);
      const { data } = await reservationApi.reservationBulkValidatePost(
        request,
        getCancelToken(signal)
      );
      return validateBookingConverter(data);
    },
    enabled,
    cacheTime: 0,
    staleTime: 0,
  });

  return query;
}

export function useBookingValidationQueryStatus() {
  const queryClient = useQueryClient();
  const isFetching = useIsFetching(queryKeyScope);

  const responses = queryClient
    .getQueriesData<Reservations.ValidateBookingResponse>(queryKeyScope)
    .filter(([, response]) => !!response)
    .map(([, response]) => response);

  const isValid = responses.every(response => response.isValid);
  const employeeNumberEnabled = responses.every(response => response.employeeNumberEnabled);

  const reservations = responses
    .filter(response => response.reservations && response.reservations.length > 0)
    .flatMap(response => response.reservations!);

  return {
    isFetching: isFetching > 0,
    isValid,
    message:
      reservations.length === 0
        ? undefined
        : 'Are you sure that none of these match and you want to create another reservation?',
    reservations,
    employeeNumberEnabled,
  };
}

function isEnabled(
  request: Partial<Reservations.ValidateBookingRequest>
): request is Reservations.ValidateBookingRequest {
  const { propertyId, checkInDate, checkOutDate, guests } = request;
  return !!propertyId && !!checkInDate && !!checkOutDate && !!guests && guests.length > 0;
}

function isBulkEnabled(
  request: ValidateBulkReservationChangesRequest
): request is ValidateBulkReservationChangesRequest {
  const { propertyId, corporateAccountId, reservationIds } = request;
  return !!propertyId && !!corporateAccountId && !!reservationIds && reservationIds.length > 0;
}
