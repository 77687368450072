import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { FormStatus, FormEvent, PolicyForm } from '@lib/state';
import {
  ConfirmedButton,
  ErrorDisplay,
  Feedback,
  FormHub,
  RemoveButton,
  Section,
  SubmitButton,
} from '@lib/common';
import { PolicyType } from 'app/state';

interface Props {
  policyType: PolicyType;
  addOverride: () => void;
  updateOverride: (policy: PolicyForm) => void;
  removeOverride: () => void;
  reset: () => void;
  updateEvent: FormEvent;
  removeEvent: FormEvent;
  addEvent: FormEvent;
}

export const CorporatePolicyForm: React.FC<Props> = ({
  policyType,
  reset,
  updateEvent,
  removeEvent,
  addEvent,
  addOverride,
  updateOverride,
  removeOverride,
  children,
}) => {
  return (
    <Section
      title={`${policyType.title} Policy`}
      textAlign="left"
      data-testid={`corporate${policyType.title}PolicyDiv`}
    >
      {!!policyType.policy ? (
        <FormHub onSubmit={updateOverride}>
          {children}
          <Box mt={2}>
            <Feedback
              show={updateEvent.status === FormStatus.Success}
              severity="success"
              onHide={reset}
            >
              {policyType.title} policy saved successfully!
            </Feedback>
            <ErrorDisplay error={updateEvent.error} />
          </Box>
          <Box py={2}>
            <Box display="flex" justifyContent="flex-end">
              <Box mr={2} clone>
                <RemoveButton
                  type="button"
                  action={`Remove ${policyType.title} Policy Override`}
                  status={removeEvent.status}
                  error={removeEvent.error}
                  onConfirm={removeOverride}
                  confirmView={
                    <Typography>
                      Are you sure you want to remove this {policyType.title.toLowerCase()} policy
                      override? This will only affect reservations booked after removing the policy
                      override.
                    </Typography>
                  }
                  data-testid="corporateRemovePolicyOverrideButton"
                >
                  Remove {policyType.title} Policy Override
                </RemoveButton>
              </Box>
              <SubmitButton
                name="setPolicy"
                variant="contained"
                color="secondary"
                pending={updateEvent.status === FormStatus.Pending}
                disabled={updateEvent.status === FormStatus.Pending}
                data-testid="corporateSavePolicyOverrideButton"
              >
                Save {policyType.title} Policy
              </SubmitButton>
            </Box>
          </Box>
        </FormHub>
      ) : (
        <Box py={2}>
          <Typography variant="body1" color="textSecondary" paragraph align="center">
            This corporate account does not have a {policyType.title.toLowerCase()} policy override
            configured and will use the policy of the respective property at time of booking.
          </Typography>
          <Box display="flex" justifyContent="flex-end">
            <ConfirmedButton
              type="button"
              action={`Enable ${policyType.title} Policy Override`}
              variant="contained"
              color="secondary"
              status={addEvent.status}
              error={addEvent.error}
              onConfirm={addOverride}
              confirmView={
                <Typography>
                  Are you sure you want to enable {policyType.title.toLowerCase()} policy overrides
                  for this account? This will only affect reservations booked after saving the
                  policy.
                </Typography>
              }
              data-testid="corporateEnablePolicyOverrideButton"
            >
              Enable {policyType.title} Policy Override
            </ConfirmedButton>
          </Box>
        </Box>
      )}
    </Section>
  );
};
