import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import { sessionQuery, roomTypeService, FormStatus } from '@lib/state';
import { useObservable, Section, FormHub, useFormEvents, usePageTitle } from '@lib/common';
import { RoomTypeForms } from '@lib/state';
import { BreadcrumbBackButton } from 'app/shared';
import { mapRoomTypeFields, RoomTypeFields, RoomTypeForm } from '../components';
import { RouteComponentProps } from 'react-router-dom';

export const AddRoomTypePage: React.FC<RouteComponentProps> = ({ history }) => {
  usePageTitle('New Room Type');
  const propertyId = useObservable(sessionQuery.propertyId, 'async');

  const [{ status, error }, reset] = useFormEvents(RoomTypeForms.CreateRoomType);

  useEffect(() => {
    if (status === FormStatus.Success) history.push('/room-types');
  }, [status, history]);

  if (!propertyId) return null;

  const onSubmit = (values: RoomTypeFields) =>
    roomTypeService.createRoomType({
      propertyId: propertyId,
      ...mapRoomTypeFields(values),
    });

  return (
    <Container maxWidth="lg">
      <BreadcrumbBackButton destination="/room-types" />
      <Section title="Create Room Type" textAlign="left">
        <FormHub onSubmit={onSubmit} formOptions={{ defaultValues: {} }}>
          <RoomTypeForm status={status} error={error} reset={reset} />
        </FormHub>
      </Section>
    </Container>
  );
};
