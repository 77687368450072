import React, { useEffect, useMemo } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { AppBar, Toolbar, makeStyles, Box, createStyles, Drawer } from '@material-ui/core';

import { sessionQuery, navMenuQuery, navMenuService, sessionService } from '@lib/state';
import {
  useObservable,
  NavDrawer,
  NavBarSpacer,
  ArmadilloHeaderLogoSvg,
  NavMenuList,
  EnvironmentBanner,
} from '@lib/common';
import { PropertySelector } from '../components';
import { isKiosk } from '../native-interface/nativebridge';
import { getMenuSections } from './menu-sections';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
    logo: {
      height: '56px',
      padding: '0 16px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& > img': {
        height: '80%',
        width: 'auto',
      },
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    signIn: {
      alignSelf: 'stretch',
      boxShadow: 'none',
    },
  })
);

export const NavLayout: React.FC = ({ children }) => {
  const { root, logo, content } = useStyles();

  const location = useLocation();

  const currentUser = useObservable(sessionQuery.currentUser);
  const guestPortalUrl = useObservable(sessionQuery.guestPortalUrl);
  const propertyId = useObservable(sessionQuery.propertyId);
  const isNavDrawerOpen = useObservable(navMenuQuery.isNavDrawerOpen);
  const activeMenuSections = useObservable(navMenuQuery.activeMenuSections);
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');

  useEffect(() => {
    if (isLoggedIn) {
      const menu = getMenuSections({
        isKiosk: isKiosk(),
        guestPortalUrl,
      });

      navMenuService.loadMenu(menu);
    }
  }, [isLoggedIn, currentUser, guestPortalUrl]);

  const isPublicRoute = useMemo(
    () =>
      location.pathname.startsWith('/kiosk') ||
      location.pathname.startsWith('/account') ||
      location.pathname.startsWith('/signup'),
    [location]
  );
  useEffect(() => {
    // Don't load employee roles on public routes
    if (isPublicRoute) return;

    if (isLoggedIn && propertyId) {
      sessionService.getEmployeeRoles(propertyId);
    }
  }, [isLoggedIn, propertyId, isPublicRoute]);

  const handleDrawerClose = () => navMenuService.toggleNavDrawer();
  const modalStyle = {
    zIndex: 1298,
  };

  if (isKiosk()) {
    return (
      <div className={root}>
        <div className={content}>
          <NavBarSpacer />
          {currentUser && (
            <Drawer
              variant="temporary"
              anchor="right"
              color="secondary"
              open={isNavDrawerOpen}
              style={modalStyle}
              onClose={handleDrawerClose}
            >
              <NavMenuList
                menuSections={activeMenuSections}
                handleDrawerClose={handleDrawerClose}
              />
            </Drawer>
          )}
          {children}
        </div>
      </div>
    );
  }

  const changeProperty = () => {
    const redirectSections = [
      '/reservations/:reservationId',
      '/rates/:ratePlanId',
      '/rates/add',
      '/user-management/:employeeId',
      '/room-types/:roomTypeId',
      '/room-types/add',
      '/areas/:areaId',
      '/areas/add',
    ];
    const currentPath = location.pathname;
    const isRedirect = matchPath(currentPath, {
      path: redirectSections,
    });

    if (isRedirect) {
      window.location.href = '/';
    } else {
      window.location.reload();
    }
  };

  return (
    <div className={root}>
      <AppBar position="fixed" color="primary">
        <EnvironmentBanner />
        <Toolbar variant="dense" disableGutters>
          <Link className={logo} to="/">
            <img src={ArmadilloHeaderLogoSvg} alt="The Armadillo Hotel" />
          </Link>
          <Box flexGrow={1} px={4}>
            <PropertySelector onChange={changeProperty} />
          </Box>
          {currentUser && (
            <NavDrawer
              icon="bars"
              anchor="right"
              color="primary"
              open={isNavDrawerOpen}
              toggleDrawer={handleDrawerClose}
              ModalProps={{ style: modalStyle }}
            >
              <NavBarSpacer />
              <NavMenuList
                menuSections={activeMenuSections}
                handleDrawerClose={handleDrawerClose}
              />
            </NavDrawer>
          )}
        </Toolbar>
      </AppBar>
      <main className={content}>
        <NavBarSpacer />
        {children}
      </main>
    </div>
  );
};
