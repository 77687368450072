import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Feedback, LoadingIndicator, useObservable } from '@lib/common';
import { employeeQuery, employeeService } from 'app/state';

interface Props {
  isLoggedIn: boolean | undefined;
  search: string;
}

export const EmployeeInviteRedirect: React.FC<Props> = ({ isLoggedIn, search, children }) => {
  const queryParams = new URLSearchParams(search);
  const employeeInviteId = queryParams.get('employeeInviteId');
  const employeeInviteExists = useObservable(employeeQuery.inviteExists);

  useEffect(() => {
    if (employeeInviteId) {
      employeeService.checkInviteExists(employeeInviteId);
    }
  }, [employeeInviteId]);

  if (employeeInviteId) {
    switch (employeeInviteExists) {
      case undefined:
        return <LoadingIndicator loadingText="Checking Employee Invite" />;
      case true:
        if (isLoggedIn) {
          // if there is an employeInviteId -> complete enrollment
          const redirectLink = `/account/employee-enrollment${search}`;
          return <Redirect to={redirectLink} />;
        }
        break;
      case false:
        return (
          <Feedback show severity="error">
            Invalid employee invite. Please request a new invite link from your administrator.
          </Feedback>
        );
    }
  }

  return <>{children}</>;
};
