import React, { HTMLAttributes } from 'react';
import { Box } from '@material-ui/core';

import { FormStatus, Reservations, NonNullableProps } from '@lib/state';
import { SubmitButton, ErrorDisplay } from '../../forms';
import { AddressForm, ContactInformationForm, NameForm, PasswordForm } from '../forms';

export interface CreateAccountFormFields {
  contact: NonNullableProps<Reservations.Contact>;
  name: Reservations.Name;
  address: Reservations.Address;
  password: string;
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  status: FormStatus;
  error?: Error;
}

export const CreateAccountForm: React.FC<Props> = ({ status, error, ...props }) => {
  return (
    <div {...props}>
      <ContactInformationForm required />
      <NameForm />
      <AddressForm />
      <PasswordForm />
      <Box py={2}>
        <ErrorDisplay error={error} />
        <SubmitButton
          name="createAccount"
          variant="contained"
          color="secondary"
          fullWidth
          pending={status === FormStatus.Pending}
          disabled={status === FormStatus.Pending}
        >
          Create Account
        </SubmitButton>
      </Box>
    </div>
  );
};
