import { StoreConfig, EntityStore, EntityState, MultiActiveState } from '@datorama/akita';
import { EntryCode } from './entry-codes.model';

export interface EntryCodesUIState {
  userKey?: string;
}

export interface EntryCodesState extends EntityState<EntryCode, EntryCode['key']> {
  ui: EntryCodesUIState;
}

@StoreConfig({ name: 'entry-codes', resettable: true, idKey: 'key' })
export class EntryCodesStore extends EntityStore<EntryCodesState> {
  constructor() {
    super({
      ui: {
        userKey: undefined,
      },
    });
  }
}

export const entryCodesStore = new EntryCodesStore();
