import { EnumValueDisplay, FormTextField, SubmitButton, useFormEvents } from '@lib/common';
import {
  HousekeepingAssignment,
  FormStatus,
  HousekeepingStatusEnum,
  HousekeepingStatusMap,
  HousekeepingAssignmentForms,
  housekeepingAssignmentService,
} from '@lib/state';
import {
  Grid,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import { DeferType, housekeepingStatuses } from 'app/shared';
import { formatISO } from 'date-fns';
import React, { useState, useCallback } from 'react';
import { useForm, FormContext } from 'react-hook-form';

interface Props {
  assignment: HousekeepingAssignment;
}

export function CompleteHousekeepingAssignmentForm({ assignment }: Props) {
  const [{ status }] = useFormEvents(HousekeepingAssignmentForms.UpdateHousekeepingRoomAssignment);

  const [service, setService] = useState<HousekeepingStatusEnum | null>(
    assignment.serviceRequested
  );
  const [disinfected, setDisinfected] = useState(false);
  const [vacant, setVacant] = useState(false);
  const [defer, setDefer] = useState<DeferType>();
  const [maintenanceRequired, setMaintenanceRequired] = useState(false);
  const [maintenanceNotes, setMaintenanceNotes] = useState<string>();

  const onMaintenanceRequiredChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const required = e.target.value === 'true';
      setMaintenanceRequired(required);
      if (!required) setMaintenanceNotes(undefined);
    },
    [setMaintenanceNotes, setMaintenanceRequired]
  );

  const onSubmit = useCallback(() => {
    housekeepingAssignmentService.completeHousekeepingAssignment(assignment, {
      housekeepingAssignmentDate: assignment.date,
      roomNumber: assignment.roomNumber,
      servicePerformed: service ?? undefined,
      roomDisinfected: disinfected,
      roomLooksVacant: vacant,
      endTime: formatISO(new Date()),
      maintenanceRequired: maintenanceRequired,
      maintenanceNotes: maintenanceNotes,
    });
  }, [service, disinfected, vacant, assignment, maintenanceRequired, maintenanceNotes]);

  const form = useForm();

  const onCheckboxChange = useCallback(
    (e: React.FormEvent<HTMLDivElement>) => {
      const element = e.target as HTMLInputElement;
      const deferType = DeferType[element.value as keyof typeof DeferType];
      if (deferType === defer) {
        setDefer(undefined);
        setService(assignment.serviceRequested);
      }

      if (deferType !== defer) {
        setDefer(deferType);

        if (deferType === DeferType.ComeBackLater) {
          setService(null);
        }

        if (deferType === DeferType.ComeBackTomorrow) {
          setService(HousekeepingStatusEnum.NoCleaningRequired);
        }
      }
    },
    [setDefer, defer, setService, assignment]
  );

  const onServiceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (defer) setDefer(undefined);
      setService(e.target.value as HousekeepingStatusEnum);
    },
    [defer, setDefer, setService]
  );

  return (
    <FormContext {...form}>
      <Grid container spacing={2} style={{ marginTop: '1em' }}>
        <Grid item xs={12}>
          <Typography>
            Please confirm the following details for the completed assignment.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Service Performed</FormLabel>
          <RadioGroup row value={service} onChange={onServiceChange}>
            {housekeepingStatuses.map(status => (
              <FormControlLabel
                key={status}
                value={status}
                label={HousekeepingStatusMap.get(status)}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Room Disinfected?</FormLabel>
          <RadioGroup
            row
            value={disinfected}
            onChange={e => setDisinfected(e.target.value === 'true')}
          >
            <FormControlLabel value={false} label="No" control={<Radio />} />
            <FormControlLabel value={true} label="Yes" control={<Radio />} />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Room Looks Vacant?</FormLabel>
          <RadioGroup row value={vacant} onChange={e => setVacant(e.target.value === 'true')}>
            <FormControlLabel value={false} label="No" control={<Radio />} />
            <FormControlLabel value={true} label="Yes" control={<Radio />} />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Defer?</FormLabel>
          <FormGroup row onChange={onCheckboxChange}>
            {Object.values(DeferType).map(d => (
              <FormControlLabel
                key={d}
                control={<Checkbox value={d} checked={defer === d} />}
                label={<EnumValueDisplay value={d} />}
              />
            ))}
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Maintenance Required?</FormLabel>
          <RadioGroup row value={maintenanceRequired} onChange={onMaintenanceRequiredChange}>
            <FormControlLabel value={false} label="No" control={<Radio />} />
            <FormControlLabel value={true} label="Yes" control={<Radio />} />
          </RadioGroup>
        </Grid>
        {maintenanceRequired && (
          <Grid item xs={12}>
            <FormLabel>Maintenance Notes</FormLabel>
            <FormTextField
              onChange={e => setMaintenanceNotes(e.target.value)}
              name="maintenanceNotes"
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <SubmitButton
            variant="contained"
            color="secondary"
            fullWidth
            pending={status === FormStatus.Pending}
            disabled={status === FormStatus.Pending}
            onClick={onSubmit}
            style={{ marginTop: 16 }}
          >
            Finish
          </SubmitButton>
        </Grid>
      </Grid>
    </FormContext>
  );
}
