// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { GetGuestRelationListResponse } from '../models';
import { ProblemDetails } from '../models';
import { UpdateGuestRelationsRequest } from '../models';
/**
 * GuestRelationApi - axios parameter creator
 * @export
 */
export const GuestRelationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestrelationUserIdGet(userId: string, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling guestrelationUserIdGet.'
        );
      }
      const localVarPath = `/guestrelation/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateGuestRelationsRequest} [updateGuestRelationsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestrelationUserIdPost(
      userId: string,
      updateGuestRelationsRequest?: UpdateGuestRelationsRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling guestrelationUserIdPost.'
        );
      }
      const localVarPath = `/guestrelation/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateGuestRelationsRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            updateGuestRelationsRequest !== undefined ? updateGuestRelationsRequest : {}
          )
        : updateGuestRelationsRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GuestRelationApi - functional programming interface
 * @export
 */
export const GuestRelationApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestrelationUserIdGet(
      userId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestRelationListResponse> {
      const localVarAxiosArgs = GuestRelationApiAxiosParamCreator(
        configuration
      ).guestrelationUserIdGet(userId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateGuestRelationsRequest} [updateGuestRelationsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestrelationUserIdPost(
      userId: string,
      updateGuestRelationsRequest?: UpdateGuestRelationsRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestRelationListResponse> {
      const localVarAxiosArgs = GuestRelationApiAxiosParamCreator(
        configuration
      ).guestrelationUserIdPost(userId, updateGuestRelationsRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GuestRelationApi - factory interface
 * @export
 */
export const GuestRelationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestrelationUserIdGet(
      userId: string,
      options?: any
    ): AxiosPromise<GetGuestRelationListResponse> {
      return GuestRelationApiFp(configuration).guestrelationUserIdGet(userId, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateGuestRelationsRequest} [updateGuestRelationsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestrelationUserIdPost(
      userId: string,
      updateGuestRelationsRequest?: UpdateGuestRelationsRequest,
      options?: any
    ): AxiosPromise<GetGuestRelationListResponse> {
      return GuestRelationApiFp(configuration).guestrelationUserIdPost(
        userId,
        updateGuestRelationsRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * GuestRelationApi - object-oriented interface
 * @export
 * @class GuestRelationApi
 * @extends {BaseAPI}
 */
export class GuestRelationApi extends BaseAPI {
  /**
   *
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestRelationApi
   */
  public guestrelationUserIdGet(userId: string, options?: any) {
    return GuestRelationApiFp(this.configuration).guestrelationUserIdGet(userId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} userId
   * @param {UpdateGuestRelationsRequest} [updateGuestRelationsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestRelationApi
   */
  public guestrelationUserIdPost(
    userId: string,
    updateGuestRelationsRequest?: UpdateGuestRelationsRequest,
    options?: any
  ) {
    return GuestRelationApiFp(this.configuration).guestrelationUserIdPost(
      userId,
      updateGuestRelationsRequest,
      options
    )(this.axios, this.basePath);
  }
}
