import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { isReservationAvailableForCheckin, Reservation, ReservationStatus } from '@lib/state';

interface Props extends Omit<ButtonProps, 'action'> {
  reservation: Reservation;
  override?: boolean;
}

export const CheckInReservationButton: React.FC<Props> = ({ reservation, ...props }) => {
  if (!isReservationAvailableForCheckin(reservation)) return null;

  return (
    <Button color="secondary" variant="contained" {...props}>
      Check-In
    </Button>
  );
};
