import React, { useEffect } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Grid, MenuItem } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfDay } from 'date-fns';

import { ReservationStatus, BookedByUserType, VipStatus } from '@lib/state';
import {
  FormTextField,
  useDebouncedEffect,
  FormSelect,
  EnumValueDisplay,
  FormCheckbox,
} from '@lib/common';
import { FilterSection } from 'app/shared';
import { CorporateAccount, RoomRatePlan } from 'app/state';
import { RoomTypeModel } from '@lib/state/api/generated/properties';

export interface ReservationFilters {
  start?: Date;
  end?: Date;
  recordNumber?: number;
  recordNumberRange?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  status?: ReservationStatus;
  corporateAccountName?: string;
  planName?: string;
  isFilterByZeroCharges?: boolean;
  bookedBy?: BookedByUserType;
  userId?: string;
  roomTypeId?: string;
  vipStatus?: VipStatus;
}

type ReservationFiltersForm = Omit<ReservationFilters, 'recordNumber' | 'recordNumberRange'> & {
  recordNumber: string;
  recordNumberRange: string;
};

interface ReservationSearchFilterProps {
  filters: ReservationFilters;
  roomRatePlans: Array<RoomRatePlan>;
  roomTypes: Array<RoomTypeModel>;
  corporateAccounts: Array<CorporateAccount>;
  onChange: (filters: ReservationFilters) => void;
  userId?: string;
}

export const ReservationSearchFilter: React.FC<ReservationSearchFilterProps> = ({
  filters,
  roomRatePlans,
  roomTypes,
  corporateAccounts,
  onChange,
  userId,
}) => {
  const form = useForm<ReservationFiltersForm>({
    defaultValues: {
      ...filters,
      recordNumber: filters.recordNumber ? String(filters.recordNumber) : '',
      recordNumberRange: filters.recordNumberRange ? String(filters.recordNumberRange) : '',
      corporateAccountName: filters.corporateAccountName ? filters.corporateAccountName : '',
      planName: filters.planName ? filters.planName : '',
    },
  });

  useEffect(() => {
    form.register('start');
    form.register('end');
    form.register('bookedBy');
    form.register('roomTypeId');

    // eslint-disable-next-line
  }, []);

  const {
    start,
    end,
    recordNumber,
    recordNumberRange,
    firstName,
    lastName,
    email,
    phone,
    status,
    corporateAccountName,
    planName,
    isFilterByZeroCharges,
    bookedBy,
    roomTypeId,
    vipStatus,
  } = form.watch({
    nest: true,
  });

  useDebouncedEffect(
    () => {
      onChange({
        start,
        end,
        recordNumber: recordNumber ? Number(recordNumber) : undefined,
        recordNumberRange: recordNumberRange ? Number(recordNumberRange) : undefined,
        firstName,
        lastName,
        email,
        phone,
        status,
        corporateAccountName,
        planName,
        isFilterByZeroCharges,
        bookedBy,
        userId,
        roomTypeId,
        vipStatus,
      });
    },
    1000,
    [
      start,
      end,
      recordNumber,
      recordNumberRange,
      firstName,
      lastName,
      email,
      phone,
      status,
      corporateAccountName,
      planName,
      isFilterByZeroCharges,
      bookedBy,
      userId,
      roomTypeId,
      vipStatus,
    ]
  );

  return (
    <FilterSection>
      <FormContext {...form}>
        <Grid container spacing={1}>
          <Grid item lg={2} md={4} sm={6}>
            <DatePicker
              name="start"
              label="Check-In After"
              format="MMMM d"
              value={start ?? null}
              error={!!form.errors.start}
              onChange={value => form.setValue('start', (value && startOfDay(value)) ?? undefined)}
              color="primary"
              InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
              size="small"
              clearable
              fullWidth
              autoOk
              data-testid="searchCheckInInput"
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6}>
            <DatePicker
              name="end"
              label="Check-In Before"
              format="MMMM d"
              value={end ?? null}
              error={!!form.errors.end}
              onChange={value => form.setValue('end', (value && startOfDay(value)) ?? undefined)}
              color="primary"
              InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
              size="small"
              clearable
              fullWidth
              autoOk
              data-testid="searchCheckOutInput"
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6}>
            <FormTextField
              name="recordNumber"
              label={recordNumberRange ? 'Res # From' : 'Reservation #'}
              fullWidth
              size="small"
              data-testid="searchRecordNumberInput"
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6}>
            <FormTextField
              name="recordNumberRange"
              label="Res # To"
              fullWidth
              size="small"
              data-testid="searchRecordNumberRangeInput"
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6}>
            <FormSelect
              name="status"
              label="Reservation Status"
              fullWidth
              size="small"
              data-testid="searchStatusSelect"
            >
              <MenuItem>All</MenuItem>
              {Object.values(ReservationStatus).map(x => (
                <MenuItem key={x} value={x} dense>
                  <EnumValueDisplay value={x} disableTypography />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item lg={2} md={4} sm={6}>
            <FormSelect
              defaultValue={''}
              name="roomTypeId"
              label="Room Type"
              fullWidth
              data-testid="searchRoomTypeSelect"
            >
              <MenuItem key="all" value="">
                All
              </MenuItem>
              {roomTypes.map(x => (
                <MenuItem key={x.id} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item lg={2} md={4} sm={6}>
            <FormTextField
              name="firstName"
              label="First Name"
              fullWidth
              size="small"
              data-testid="searchFirstNameInput"
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6}>
            <FormTextField
              name="lastName"
              label="Last Name"
              fullWidth
              size="small"
              data-testid="searchLastNameInput"
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6}>
            <FormTextField
              name="email"
              label="Email"
              fullWidth
              size="small"
              data-testid="searchEmailInput"
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6}>
            <FormTextField
              name="phone"
              label="Phone"
              fullWidth
              size="small"
              data-testid="searchPhoneInput"
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6}>
            <FormSelect
              name="corporateAccountName"
              label="Corporate Account"
              defaultValue={filters.corporateAccountName}
              fullWidth
              size="small"
              data-testid="searchCorporateAccountSelect"
            >
              <MenuItem value="">All</MenuItem>
              {corporateAccounts.map(x => (
                <MenuItem key={x.id} value={x.name}>
                  {x.name}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item lg={2} md={4} sm={6}>
            <FormSelect
              defaultValue={''}
              name="bookedBy"
              label="Booked By"
              fullWidth
              size="small"
              data-testid="searchBookedBySelect"
            >
              <MenuItem value={''}>Any</MenuItem>
              <MenuItem value={BookedByUserType.You}>Booked By You</MenuItem>
              <MenuItem value={BookedByUserType.Guest}>Booked By Guest</MenuItem>
              <MenuItem value={BookedByUserType.Delegate}>Booked By Delegate</MenuItem>
              <MenuItem value={BookedByUserType.Staff}>Booked By Hotel Staff</MenuItem>
            </FormSelect>
          </Grid>
          <Grid item lg={2} md={4} sm={6}>
            <FormSelect
              name="planName"
              label="Rate Plan"
              fullWidth
              size="small"
              data-testid="searchPlanNameSelect"
            >
              <MenuItem value="">All</MenuItem>
              {roomRatePlans.map(plan => (
                <MenuItem key={plan.id} value={plan.name}>
                  {plan.name}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item lg={2} md={4} sm={6}>
            <FormSelect defaultValue={''} name="vipStatus" label="VIP Status" fullWidth>
              <MenuItem value={undefined}>None</MenuItem>
              {Object.values(VipStatus).map(x => (
                <MenuItem value={x} key={x}>
                  <EnumValueDisplay value={x} />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item lg={2} md={4} sm={6}>
            <FormCheckbox
              name="isFilterByZeroCharges"
              label="Free / $0 Reservation"
              fullWidth
              size="small"
              data-testid="searchFreeCheckbox"
            />
          </Grid>
        </Grid>
      </FormContext>
    </FilterSection>
  );
};
