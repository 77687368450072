import React from 'react';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';

import { DeviceEnrollmentQRCodePage } from './pages';

export const DeviceEnrollmentRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/device-qr-code`} component={DeviceEnrollmentQRCodePage} />
    <Redirect to={match.path} />
  </Switch>
);
