import { guestStaysQuery, guestStaysService } from '@lib/state';
import { useEffect } from 'react';
import { useObservable } from './';

export function useGuestStays(propertyId: string, date?: string, reservationId?: string) {
  useEffect(() => {
    guestStaysService.getGuestStays(propertyId, date, reservationId);
  }, [propertyId, date, reservationId]);

  return {
    stays: useObservable(guestStaysQuery.guestStays),
    loading: useObservable(guestStaysQuery.loading),
  };
}

export function useGetGuestStayById(propertyId: string, guestStayId: string) {
  useEffect(() => {
    guestStaysService.getGuestStayById(propertyId, guestStayId);
  }, [propertyId, guestStayId]);

  return {
    stay: useObservable(guestStaysQuery.activeGuestStay),
    loading: useObservable(guestStaysQuery.loading),
  };
}

export function useActiveGuestStay() {
  return useObservable(guestStaysQuery.activeGuestStay);
}
