import React, { useCallback, useState } from 'react';
import {
  CircularProgress,
  IconButton,
  Typography,
  createStyles,
  makeStyles,
  TypographyProps,
  TextFieldProps,
} from '@material-ui/core';
import { FormContext, useForm, ValidationOptions } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import { FormTextField } from './form-text-field';
import { ErrorDisplay } from './error-display';

const useStyles = makeStyles(theme =>
  createStyles({
    dense: {
      lineHeight: 1,
    },
    button: {
      fontSize: '15px',
      padding: '0px',
      marginLeft: theme.spacing(1),

      '@media print': {
        display: 'none',
      },
    },
    save: {
      color: theme.palette.success.main,
    },
    cancel: {
      color: theme.palette.error.main,
    },
    value: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  })
);

interface FormValues {
  value?: string;
}

// in order to support contravariant input props, we must use a function component
type ValueDisplayComponent = (props: FormValues & TypographyProps) => React.ReactElement | null;

interface Props {
  label: string;
  value: string;

  validationOptions?: ValidationOptions;
  onSubmit: (value?: string) => void;

  input?: React.ComponentType<{
    name: string;
    defaultValue?: unknown;
    size: TextFieldProps['size'];
    validationOptions?: ValidationOptions;
    fullWidth?: boolean;
  }>;
  valueDisplay?: ValueDisplayComponent;

  readonly?: boolean;
  pending?: boolean;
  error?: Error;

  labelVariant?: TypographyProps['variant'];
  valueVariant?: TypographyProps['variant'];
  dense?: boolean;
}

export const EditableDisplay: React.FC<Props> = ({
  label,
  value,
  validationOptions,
  onSubmit,
  input: Input,
  valueDisplay: ValueDisplay,
  readonly,
  pending,
  error,
  labelVariant,
  valueVariant,
  dense,
}) => {
  const [editing, setEditing] = useState(false);
  const styles = useStyles();

  const submit = useCallback(
    ({ value }: FormValues) => {
      onSubmit(value);
      setEditing(false);
    },
    [onSubmit]
  );

  const form = useForm<FormValues>({ reValidateMode: 'onChange' });

  return (
    <FormContext {...form}>
      <form onSubmit={form.handleSubmit(submit)}>
        <Typography
          className={clsx({ [styles.dense]: dense })}
          variant={labelVariant}
          component="span"
        >
          <strong>{label}</strong>
        </Typography>
        {editing && (
          <>
            <IconButton
              type="submit"
              className={clsx(styles.button, styles.save)}
              data-testId="editableDisplaySubmitButton"
            >
              <FontAwesomeIcon icon="check" />
            </IconButton>
            <IconButton
              type="button"
              className={clsx(styles.button, styles.cancel)}
              onClick={() => setEditing(false)}
              data-testId="editableDisplayCancelButton"
            >
              <FontAwesomeIcon icon="times" />
            </IconButton>
          </>
        )}
        {!editing && !readonly && (
          <IconButton
            type="button"
            className={styles.button}
            color="primary"
            onClick={() => setEditing(true)}
            data-testId="editableDisplayEditButton"
          >
            <FontAwesomeIcon icon="pencil-alt" />
          </IconButton>
        )}
        {pending && (
          <CircularProgress size="15px" style={{ marginLeft: '5px', marginTop: '2px' }} />
        )}
        {editing && Input && (
          <Input
            name="value"
            size="small"
            defaultValue={value}
            validationOptions={validationOptions}
            fullWidth
            data-testId="editableDisplayTextBox"
          />
        )}
        {editing && !Input && (
          <FormTextField
            name="value"
            size="small"
            defaultValue={value}
            validationOptions={validationOptions}
            fullWidth
            data-testId="editableDisplayTextBox"
          />
        )}
        {!editing && ValueDisplay && (
          <ValueDisplay
            className={clsx(styles.value, { [styles.dense]: dense })}
            variant={valueVariant}
            value={value}
            data-testId="editableDisplayValueDisplay"
          />
        )}
        {!editing && !ValueDisplay && (
          <Typography
            className={clsx(styles.value, { [styles.dense]: dense })}
            variant={valueVariant}
            data-testId="editableDisplayValue"
          >
            {value}
          </Typography>
        )}
        {error && (
          <ErrorDisplay error={error}>
            <Typography color="error">
              <small>An error occurred while saving</small>
            </Typography>
          </ErrorDisplay>
        )}
      </form>
    </FormContext>
  );
};
