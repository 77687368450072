import React from 'react';
import { makeStyles, createStyles, Button, Grid } from '@material-ui/core';
import { Property } from '@lib/state';
import { common } from '@material-ui/core/colors';
import { AppColors } from '@lib/common';

const useStyles = makeStyles(theme =>
  createStyles({
    blueButton: {
      borderRadius: 0,
      color: common.white,
      fontWeight: 'bold',
      backgroundColor: AppColors.LightBlue,
      '&:hover': {
        backgroundColor: AppColors.LightBlue,
      },
    },
  })
);

interface Props {
  clickHandler: (reservation: Property) => void;
  property: Property;
}

export const KioskPropertyButton: React.FC<Props> = ({ property, ...props }) => {
  const styles = useStyles();

  const onSelect = () => {
    props.clickHandler(property);
  };

  return (
    <Grid item xs>
      <Button onClick={onSelect} size="large" fullWidth className={styles.blueButton}>
        {property.name}
      </Button>
    </Grid>
  );
};
