import React, { useEffect, useMemo } from 'react';
import { Controller, useFormContext, ValidationOptions } from 'react-hook-form';
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  createStyles,
} from '@material-ui/core';

import {
  CorporateAccount,
  FormStatus,
  INVOICE_ACCOUNT_PREFIX,
  PaymentMethod,
  PaymentMethodForms,
  ReservationForms,
} from '@lib/state';
import { ExistingCardForm } from '../../components';
import { getHelperText } from '../../forms/form-methods';
import { ErrorDisplay, Feedback, useFormEvents } from '../../forms';

const useStyles = makeStyles(
  createStyles({
    radioButton: {
      alignItems: 'start',
    },
    radioButtonLabel: {
      width: '100%',
    },
  })
);

interface Props {
  name: string;
  paymentMethods: PaymentMethod[];
  invoiceAccounts: CorporateAccount[];
  validationOptions?: Partial<ValidationOptions>;
  helperText?: string | ((errors: any) => string);
  optional?: boolean;
  paymentUserId: string;
}

export const PaymentMethodSelect: React.FC<Props> = ({
  name,
  paymentMethods,
  invoiceAccounts,
  validationOptions,
  helperText,
  optional,
  paymentUserId,
}) => {
  const styles = useStyles();
  const { errors, setValue, watch } = useFormContext();
  const [{ status: deletePaymentStatus, error: deletePaymentError }, resetUI] = useFormEvents(
    PaymentMethodForms.DeletePaymentMethod
  );
  const [_, resetReservationFormStatus] = useFormEvents(ReservationForms.CreateReservation);

  useEffect(() => {
    resetUI;
  }, []);

  const defaultSelection = useMemo(() => {
    if (invoiceAccounts.length > 0) return `${INVOICE_ACCOUNT_PREFIX}${invoiceAccounts[0].id}`;

    if (paymentMethods.length === 0) return '';

    const defaultMethod = paymentMethods.find(x => x.defaultMethod) ?? paymentMethods[0];
    return defaultMethod.authNetPaymentId ?? '';
  }, [invoiceAccounts, paymentMethods]);

  useEffect(() => {
    setValue(name, defaultSelection, true);
  }, [defaultSelection]);

  const controlHelperText = getHelperText(errors, helperText);

  const paymentProfileId = watch('paymentProfileId');

  useEffect(() => {
    if (!!resetReservationFormStatus) resetReservationFormStatus();
  }, [paymentProfileId, resetReservationFormStatus]);

  return (
    <>
      <FormControl fullWidth component="fieldset" required={!optional}>
        <Controller
          name={name}
          defaultValue={defaultSelection}
          as={RadioGroup}
          rules={validationOptions}
        >
          {invoiceAccounts.map(account => (
            <FormControlLabel
              key={account.id}
              value={`${INVOICE_ACCOUNT_PREFIX}${account.id}`}
              control={<Radio data-testid={`methodInvoiceAccountRadio`} />}
              label={`Use ${account.name} corporate invoice account`}
            />
          ))}
          {paymentMethods.map(paymentMethod => (
            <FormControlLabel
              classes={{
                root: styles.radioButton,
                label: styles.radioButtonLabel,
              }}
              key={paymentMethod.authNetPaymentId}
              value={paymentMethod.authNetPaymentId}
              control={<Radio data-testid={`paymentMethodRadio`} />}
              label={
                <ExistingCardForm paymentMethod={paymentMethod} paymentUserId={paymentUserId} />
              }
            />
          ))}
          <FormControlLabel
            value={''}
            control={<Radio data-testid="methodNewCardRadio" />}
            label="Use a new credit card"
          />
        </Controller>
        {controlHelperText && <FormHelperText>{controlHelperText}</FormHelperText>}
        <Feedback
          show={deletePaymentStatus === FormStatus.Success}
          severity="success"
          onHide={resetUI}
        >
          Payment method deleted successfully!
        </Feedback>
        <ErrorDisplay error={deletePaymentError} />
      </FormControl>
    </>
  );
};
