import React from 'react';
import { Typography } from '@material-ui/core';

interface Props {
  error: Error | undefined;
}

export const CheckInError: React.FC<Props> = ({ error }) => {
  if (!error) return null;

  return (
    <>
      <Typography variant="h1" align="center" paragraph>
        Please go to the front desk to complete check-in.
      </Typography>
      <Typography variant="h4" color="error" align="center" paragraph>
        {error.message}
      </Typography>
    </>
  );
};
