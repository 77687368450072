import React, { useCallback, useState } from 'react';

import {
  ButtonProps,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { FormContext, useForm } from 'react-hook-form';

import {
  HousekeepingAssignment,
  HousekeepingAssignmentForms,
  housekeepingAssignmentService,
  HousekeepingStatusEnum,
  HousekeepingStatusMap,
} from '@lib/state';
import { ConfirmedButton, EnumValueDisplay, useFormEvents } from '@lib/common';
import { formatISO } from 'date-fns';
import { DeferType, housekeepingStatuses } from 'app/shared';

interface Props extends Omit<ButtonProps, 'action' | 'onClick'> {
  assignment: HousekeepingAssignment;
}

export const CompleteHousekeepingAssignmentButton: React.FC<Props> = ({ assignment, ...props }) => {
  const [{ status, error }, reset] = useFormEvents(
    HousekeepingAssignmentForms.UpdateHousekeepingRoomAssignment
  );

  const onOpen = useCallback(() => reset(), [reset]);
  const [service, setService] = useState<HousekeepingStatusEnum | null>(
    assignment.serviceRequested
  );
  const [disinfected, setDisinfected] = useState(false);
  const [vacant, setVacant] = useState(false);
  const [defer, setDefer] = useState<DeferType>();

  const onCheckboxChange = useCallback(
    (e: React.FormEvent<HTMLDivElement>) => {
      const element = e.target as HTMLInputElement;
      const deferType = DeferType[element.value as keyof typeof DeferType];
      if (deferType === defer) {
        setDefer(undefined);
        setService(assignment.serviceRequested);
      }

      if (deferType !== defer) {
        setDefer(deferType);

        if (deferType === DeferType.ComeBackLater) {
          setService(null);
        }

        if (deferType === DeferType.ComeBackTomorrow) {
          setService(HousekeepingStatusEnum.NoCleaningRequired);
        }
      }
    },
    [setDefer, defer, setService, assignment]
  );

  const onServiceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (defer) setDefer(undefined);
      setService(e.target.value as HousekeepingStatusEnum);
    },
    [defer, setDefer, setService]
  );

  const form = useForm();

  const confirmView = (
    <FormContext {...form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Please confirm the following details for the completed assignment.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Clean Performed</FormLabel>
          <RadioGroup row value={service} onChange={onServiceChange}>
            {housekeepingStatuses.map(status => (
              <FormControlLabel
                key={status}
                value={status}
                label={HousekeepingStatusMap.get(status)}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        </Grid>
        <Grid item xs={6}>
          <FormLabel>Room Disinfected?</FormLabel>
          <RadioGroup
            row
            value={disinfected}
            onChange={e => setDisinfected(e.target.value === 'true')}
          >
            <FormControlLabel value={false} label="No" control={<Radio />} />
            <FormControlLabel value={true} label="Yes" control={<Radio />} />
          </RadioGroup>
        </Grid>
        <Grid item xs={6}>
          <FormLabel>Room Looks Vacant?</FormLabel>
          <RadioGroup row value={vacant} onChange={e => setVacant(e.target.value === 'true')}>
            <FormControlLabel value={false} label="No" control={<Radio />} />
            <FormControlLabel value={true} label="Yes" control={<Radio />} />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Defer?</FormLabel>
          <FormGroup row onChange={onCheckboxChange}>
            {Object.values(DeferType).map(d => (
              <FormControlLabel
                key={d}
                control={<Checkbox value={d} checked={defer === d} />}
                label={<EnumValueDisplay value={d} />}
              />
            ))}
          </FormGroup>
        </Grid>
      </Grid>
    </FormContext>
  );

  const resultView = (
    <div>
      <Typography>Housekeeping assignment completed.</Typography>
    </div>
  );

  return (
    <ConfirmedButton
      name="completeAssignment"
      size="small"
      color="secondary"
      action={`Complete Housekeeping`}
      onConfirm={() => {
        housekeepingAssignmentService.completeHousekeepingAssignment(assignment, {
          housekeepingAssignmentDate: assignment.date,
          roomNumber: assignment.roomNumber,
          servicePerformed: service ?? undefined,
          roomDisinfected: disinfected,
          roomLooksVacant: vacant,
          endTime: formatISO(new Date()),
        });
      }}
      onOpen={onOpen}
      buttonText={`Complete`}
      status={status}
      error={error}
      hidden={false}
      disabled={!!assignment.endTime}
      confirmView={confirmView}
      resultView={resultView}
      {...props}
    />
  );
};
