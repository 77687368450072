// tslint:disable
/**
 * Property Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum BedType {
  BunkBed = 'BunkBed',
  FullBed = 'FullBed',
  Futon = 'Futon',
  KingBed = 'KingBed',
  MurphyBed = 'MurphyBed',
  QueenBed = 'QueenBed',
  SofaBed = 'SofaBed',
  TrundleBed = 'TrundleBed',
  TwinBed = 'TwinBed',
  TwinXLBed = 'TwinXLBed',
  WaterBed = 'WaterBed',
}
