import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { FeatureFlags, FormStatus, Properties, TaxRates } from '@lib/state';
import {
  AddressForm,
  ContactInformationForm,
  EnumValueDisplay,
  ErrorDisplay,
  Feedback,
  FormCheckbox,
  FormNumberField,
  FormSubmitButton,
  FormTextField,
  Section,
} from '@lib/common';
import { PropertyConfiguration, TimeThreshold } from 'app/state';
import { AuditLogs, PolicyTimeConfigurationForm } from 'app/shared';
import { TaxCreditExportButton } from './tax-credit-export-button';

export interface PropertyConfigurationFormFields {
  name: string;
  location: Properties.Address;
  contact: Properties.Contact;
  groupBooking: Properties.Contact;
  taxRates: TaxRates;
  salesTaxRates: TaxRates;
  propertyCode: string;
  backOfficeEmail?: string;
  reportEmail?: string;
  maintenanceEmail?: string;
  maintenancePhone?: string;
  checkIn?: TimeThreshold;
  checkOut?: TimeThreshold;
  earlyCheckIn?: TimeThreshold;
  lateCheckOut?: TimeThreshold;
  changeableThreshold?: TimeThreshold;
  cancelableThreshold?: TimeThreshold;
  dayTimeSwitches?: string;
  taxCreditPeriod?: number;
  features: Record<string, boolean>;
}

interface Props {
  property: PropertyConfiguration;

  status: FormStatus;
  error?: Error;
  resetFormUI: () => void;
}
export const PropertyConfigurationForm: React.FC<Props> = ({
  property,
  status,
  error,
  resetFormUI,
}) => {
  const { formState } = useFormContext();
  return (
    <>
      <Section title="Property Details">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormTextField
              name="name"
              label="Name"
              validationOptions={{
                required: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormTextField
              name="propertyCode"
              label="Property Code"
              validationOptions={{
                required: true,
                maxLength: 20,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <AddressForm prefixName="location" optional={true} address={property.location} />
          </Grid>
          <Grid item xs={12}>
            <ContactInformationForm
              email={property.contact?.email}
              phone={property.contact?.phone}
              inputProps={{
                InputLabelProps: { prefix: 'Front Desk' },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ContactInformationForm
              prefixName="groupBooking"
              email={property.groupBooking?.email}
              phone={property.groupBooking?.phone}
              inputProps={{
                InputLabelProps: { prefix: 'Group Booking' },
              }}
            />
          </Grid>
        </Grid>
      </Section>
      <Section title="Property Features">
        <Grid container spacing={2}>
          {FeatureFlags.map(flag => (
            <Grid key={flag} item xs={12} sm={4}>
              <FormCheckbox name={`features.${flag}`} label={<EnumValueDisplay value={flag} />} />
            </Grid>
          ))}
        </Grid>
      </Section>
      <Section title="Occupancy Tax Rates">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormNumberField
              label="State Tax"
              defaultValue={property.taxRates?.state}
              name="taxRates.state"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormNumberField
              defaultValue={property.taxRates?.county}
              label="County Tax"
              name="taxRates.county"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormNumberField
              defaultValue={property.taxRates?.city}
              label="City Tax"
              name="taxRates.city"
              fullWidth
              required
            />
          </Grid>
          {property.allowTaxCredit && (
            <>
              <Grid item xs={12} sm={4}>
                <FormNumberField
                  label="Tax Credit Period In Days"
                  defaultValue={property.taxCreditPeriod ?? undefined}
                  name="taxCreditPeriod"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TaxCreditExportButton propertyId={property.id} />
              </Grid>
            </>
          )}
        </Grid>
      </Section>
      <Section title="Sales Tax Rates">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormNumberField
              label="State Tax"
              defaultValue={property.salesTaxRates?.state}
              name="salesTaxRates.state"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormNumberField
              defaultValue={property.salesTaxRates?.county}
              label="County Tax"
              name="salesTaxRates.county"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormNumberField
              defaultValue={property.salesTaxRates?.city}
              label="City Tax"
              name="salesTaxRates.city"
              fullWidth
              required
            />
          </Grid>
        </Grid>
      </Section>
      <Section title="Time Configuration">
        <PolicyTimeConfigurationForm configuration={property} />
        <Feedback show={status === FormStatus.Success} severity="success" onHide={resetFormUI}>
          Property saved successfully!
        </Feedback>
        <ErrorDisplay error={error}>
          <Typography>There was an error applying some updates.</Typography>
        </ErrorDisplay>
        <Box display="flex" justifyContent="flex-end" my={1}>
          <FormSubmitButton
            type="submit"
            color="secondary"
            variant="contained"
            disabled={status === FormStatus.Pending || !formState.dirty}
          >
            Save Property
          </FormSubmitButton>
        </Box>
      </Section>

      <Section title="Audit Logs" disableGutters>
        <AuditLogs scope="property" id={property.id} />
      </Section>
    </>
  );
};
