// tslint:disable
/**
 * Property Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CreateRoomTypeRequest } from '../models';
import { GetRoomTypeItemResponse } from '../models';
import { GetRoomTypeListResponse } from '../models';
import { ManageRoomTypeResponse } from '../models';
import { ProblemDetails } from '../models';
import { UpdateRoomTypeRequest } from '../models';
/**
 * RoomTypeApi - axios parameter creator
 * @export
 */
export const RoomTypeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {Array<string>} roomTypeIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypeGet(roomTypeIds: Array<string>, options: any = {}): RequestArgs {
      // verify required parameter 'roomTypeIds' is not null or undefined
      if (roomTypeIds === null || roomTypeIds === undefined) {
        throw new RequiredError(
          'roomTypeIds',
          'Required parameter roomTypeIds was null or undefined when calling roomtypeGet.'
        );
      }
      const localVarPath = `/roomtype`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (roomTypeIds) {
        localVarQueryParameter['RoomTypeIds'] = roomTypeIds;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypeGetallGet(options: any = {}): RequestArgs {
      const localVarPath = `/roomtype/getall`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypeIdGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling roomtypeIdGet.'
        );
      }
      const localVarPath = `/roomtype/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateRoomTypeRequest} [updateRoomTypeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypeIdPost(
      id: string,
      updateRoomTypeRequest?: UpdateRoomTypeRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling roomtypeIdPost.'
        );
      }
      const localVarPath = `/roomtype/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateRoomTypeRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateRoomTypeRequest !== undefined ? updateRoomTypeRequest : {})
        : updateRoomTypeRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {CreateRoomTypeRequest} [createRoomTypeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypeIdPut(
      id: string,
      createRoomTypeRequest?: CreateRoomTypeRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling roomtypeIdPut.'
        );
      }
      const localVarPath = `/roomtype/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createRoomTypeRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createRoomTypeRequest !== undefined ? createRoomTypeRequest : {})
        : createRoomTypeRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateRoomTypeRequest} [createRoomTypeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypePost(createRoomTypeRequest?: CreateRoomTypeRequest, options: any = {}): RequestArgs {
      const localVarPath = `/roomtype`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createRoomTypeRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createRoomTypeRequest !== undefined ? createRoomTypeRequest : {})
        : createRoomTypeRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypePropertyGet(propertyId: string, options: any = {}): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling roomtypePropertyGet.'
        );
      }
      const localVarPath = `/roomtype/property`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['PropertyId'] = propertyId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RoomTypeApi - functional programming interface
 * @export
 */
export const RoomTypeApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {Array<string>} roomTypeIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypeGet(
      roomTypeIds: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomTypeListResponse> {
      const localVarAxiosArgs = RoomTypeApiAxiosParamCreator(configuration).roomtypeGet(
        roomTypeIds,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypeGetallGet(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomTypeListResponse> {
      const localVarAxiosArgs = RoomTypeApiAxiosParamCreator(configuration).roomtypeGetallGet(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypeIdGet(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomTypeItemResponse> {
      const localVarAxiosArgs = RoomTypeApiAxiosParamCreator(configuration).roomtypeIdGet(
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateRoomTypeRequest} [updateRoomTypeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypeIdPost(
      id: string,
      updateRoomTypeRequest?: UpdateRoomTypeRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManageRoomTypeResponse> {
      const localVarAxiosArgs = RoomTypeApiAxiosParamCreator(configuration).roomtypeIdPost(
        id,
        updateRoomTypeRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {CreateRoomTypeRequest} [createRoomTypeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypeIdPut(
      id: string,
      createRoomTypeRequest?: CreateRoomTypeRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomTypeItemResponse> {
      const localVarAxiosArgs = RoomTypeApiAxiosParamCreator(configuration).roomtypeIdPut(
        id,
        createRoomTypeRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {CreateRoomTypeRequest} [createRoomTypeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypePost(
      createRoomTypeRequest?: CreateRoomTypeRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManageRoomTypeResponse> {
      const localVarAxiosArgs = RoomTypeApiAxiosParamCreator(configuration).roomtypePost(
        createRoomTypeRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypePropertyGet(
      propertyId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomTypeListResponse> {
      const localVarAxiosArgs = RoomTypeApiAxiosParamCreator(configuration).roomtypePropertyGet(
        propertyId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * RoomTypeApi - factory interface
 * @export
 */
export const RoomTypeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {Array<string>} roomTypeIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypeGet(roomTypeIds: Array<string>, options?: any): AxiosPromise<GetRoomTypeListResponse> {
      return RoomTypeApiFp(configuration).roomtypeGet(roomTypeIds, options)(axios, basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypeGetallGet(options?: any): AxiosPromise<GetRoomTypeListResponse> {
      return RoomTypeApiFp(configuration).roomtypeGetallGet(options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypeIdGet(id: string, options?: any): AxiosPromise<GetRoomTypeItemResponse> {
      return RoomTypeApiFp(configuration).roomtypeIdGet(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {UpdateRoomTypeRequest} [updateRoomTypeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypeIdPost(
      id: string,
      updateRoomTypeRequest?: UpdateRoomTypeRequest,
      options?: any
    ): AxiosPromise<ManageRoomTypeResponse> {
      return RoomTypeApiFp(configuration).roomtypeIdPost(
        id,
        updateRoomTypeRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {CreateRoomTypeRequest} [createRoomTypeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypeIdPut(
      id: string,
      createRoomTypeRequest?: CreateRoomTypeRequest,
      options?: any
    ): AxiosPromise<GetRoomTypeItemResponse> {
      return RoomTypeApiFp(configuration).roomtypeIdPut(
        id,
        createRoomTypeRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {CreateRoomTypeRequest} [createRoomTypeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypePost(
      createRoomTypeRequest?: CreateRoomTypeRequest,
      options?: any
    ): AxiosPromise<ManageRoomTypeResponse> {
      return RoomTypeApiFp(configuration).roomtypePost(createRoomTypeRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomtypePropertyGet(propertyId: string, options?: any): AxiosPromise<GetRoomTypeListResponse> {
      return RoomTypeApiFp(configuration).roomtypePropertyGet(propertyId, options)(axios, basePath);
    },
  };
};

/**
 * RoomTypeApi - object-oriented interface
 * @export
 * @class RoomTypeApi
 * @extends {BaseAPI}
 */
export class RoomTypeApi extends BaseAPI {
  /**
   *
   * @param {Array<string>} roomTypeIds
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomTypeApi
   */
  public roomtypeGet(roomTypeIds: Array<string>, options?: any) {
    return RoomTypeApiFp(this.configuration).roomtypeGet(roomTypeIds, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomTypeApi
   */
  public roomtypeGetallGet(options?: any) {
    return RoomTypeApiFp(this.configuration).roomtypeGetallGet(options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomTypeApi
   */
  public roomtypeIdGet(id: string, options?: any) {
    return RoomTypeApiFp(this.configuration).roomtypeIdGet(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {UpdateRoomTypeRequest} [updateRoomTypeRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomTypeApi
   */
  public roomtypeIdPost(id: string, updateRoomTypeRequest?: UpdateRoomTypeRequest, options?: any) {
    return RoomTypeApiFp(this.configuration).roomtypeIdPost(
      id,
      updateRoomTypeRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {CreateRoomTypeRequest} [createRoomTypeRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomTypeApi
   */
  public roomtypeIdPut(id: string, createRoomTypeRequest?: CreateRoomTypeRequest, options?: any) {
    return RoomTypeApiFp(this.configuration).roomtypeIdPut(
      id,
      createRoomTypeRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {CreateRoomTypeRequest} [createRoomTypeRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomTypeApi
   */
  public roomtypePost(createRoomTypeRequest?: CreateRoomTypeRequest, options?: any) {
    return RoomTypeApiFp(this.configuration).roomtypePost(createRoomTypeRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} propertyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomTypeApi
   */
  public roomtypePropertyGet(propertyId: string, options?: any) {
    return RoomTypeApiFp(this.configuration).roomtypePropertyGet(propertyId, options)(
      this.axios,
      this.basePath
    );
  }
}
