import React, { useState, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { format, parseISO, startOfMonth, endOfMonth } from 'date-fns';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Container,
  Grid,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Accounting, Admin, propertyQuery, sessionQuery } from '@lib/state';
import { useObservable, Section, WebOnly, useHasRole } from '@lib/common';

import { reportQuery, reportService, exportRoomOccupancy } from 'app/state';
import { getDate, formatDateMidnight, DownloadButton, AuditLogs } from 'app/shared';
import { OccupancyHeader } from './occupancy-header';
import { OccupancyTable } from './occupancy-table';

const useStyles = makeStyles(() => ({
  downloadButtonContainer: {
    marginLeft: 'auto',
  },
}));

export const OccupancyReport: React.FC = () => {
  const { downloadButtonContainer } = useStyles();

  const [startDate, setStartDate] = useState(() => formatDateMidnight(startOfMonth(new Date())));
  const [endDate, setEndDate] = useState(() => formatDateMidnight(endOfMonth(new Date())));
  const [excludeNotCharged, setExcludeNotCharged] = useState(false);

  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const property = useObservable(propertyQuery.activeProperty);

  const occupancies = useObservable(reportQuery.roomOccupancy);

  const csvLink = useRef<any>(null);
  const csvFilename = `occupancy-report-from-${format(
    parseISO(startDate),
    'MM-dd-yyyy'
  )}-to-${format(parseISO(endDate), 'MM-dd-yyyy')}.csv`;

  const xlsFilename = `occupancy-report-from-${format(
    parseISO(startDate),
    'MM-dd-yyyy'
  )}-to-${format(parseISO(endDate), 'MM-dd-yyyy')}.xlsx`;

  function exportCurrentProperty() {
    csvLink.current?.link.click();
  }

  function exportAllProperties() {
    reportService.exportDailyOccupancyReport(
      getDate(startDate),
      getDate(endDate),
      excludeNotCharged,
      xlsFilename
    );
  }

  useEffect(() => {
    if (propertyId && startDate && endDate) {
      reportService.loadRoomOccupancy(
        propertyId,
        getDate(startDate),
        getDate(endDate),
        excludeNotCharged
      );
    }
  }, [propertyId, startDate, endDate, excludeNotCharged]);

  if (!propertyId || !occupancies) return null;

  return (
    <Container maxWidth="xl">
      <OccupancyHeader
        startDate={startDate}
        endDate={endDate}
        excludeNotCharged={excludeNotCharged}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setExcludeNotCharged={setExcludeNotCharged}
      >
        <WebOnly>
          <CSVLink
            ref={csvLink}
            filename={csvFilename}
            data={occupancies.map(exportRoomOccupancy)}
          />
          <Grid className={downloadButtonContainer} item>
            <ExportButtons
              onExportCurrentProperty={exportCurrentProperty}
              onExportAllProperties={exportAllProperties}
            />
          </Grid>
        </WebOnly>
      </OccupancyHeader>
      <Section title="Occupancy Report" textAlign="left" disableGutters>
        <OccupancyTable occupancies={occupancies} />
      </Section>
      <Section title="Audit Logs" textAlign="left" disableGutters>
        <AuditLogs scope="daily-occupancy-report" id={property?.customerId} />
      </Section>
    </Container>
  );
};

interface ExportButtonProps {
  onExportCurrentProperty(): void;
  onExportAllProperties(): void;
}

function ExportButtons({ onExportCurrentProperty, onExportAllProperties }: ExportButtonProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleSelect = (exportFn: () => void) => () => {
    exportFn();
    setOpen(false);
  };

  const isAccounting = useHasRole(Admin, Accounting);

  if (!isAccounting) {
    return <DownloadButton onDownload={onExportCurrentProperty} />;
  }

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <DownloadButton onDownload={onExportCurrentProperty} />

        <Button
          color="secondary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <MenuItem onClick={handleSelect(onExportCurrentProperty)}>
                    Single Property Report
                  </MenuItem>
                  <MenuItem onClick={handleSelect(onExportAllProperties)}>
                    Combined Occupancy Report
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
