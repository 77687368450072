import React, { useEffect, useMemo } from 'react';
import {
  activityReportQuery,
  activityReportService,
  PaginatedQuery,
  reservationQuery,
  reservationService,
  sessionQuery,
} from '@lib/state';
import { Section } from '..';
import { useObservable } from '../../utils';
import { ActivityExportButton } from './activity-export.button';
import { ActivityHistory } from './activity-history.component';
import { WebOnly } from '../device-display';

interface Props {
  userId: string;
  createForGuest: boolean;
}

export const GuestActivity: React.FC<Props> = ({ userId, createForGuest }) => {
  const propertyId = useObservable(sessionQuery.propertyId);

  const loadMore = (page?: PaginatedQuery) => {
    if (userId && propertyId) activityReportService.getGuestActivity(userId, propertyId, page);
  };

  const exportChanges = (startDate: Date, endDate: Date) => {
    if (userId && propertyId)
      activityReportService.exportGuestCharges(userId, propertyId, startDate, endDate);
  };

  useEffect(() => {
    if (userId && propertyId) activityReportService.getGuestActivity(userId, propertyId);
    return () => activityReportService.reset();
  }, [userId, propertyId]);

  const activity = useObservable(activityReportQuery.activity);

  if (!userId) return null;

  const res = useObservable(reservationQuery.reservations);

  const activityResIds: string[] = useMemo(() => {
    let ids: string[] = [];
    activity
      .map(x => x.reservationId)
      .forEach(x => {
        if (x !== undefined && x !== null) {
          ids.push(x);
        }
      });
    return ids;
  }, [activity]);

  useEffect(() => {
    if (activityResIds === undefined || activityResIds === null || activityResIds.length === 0) {
      return;
    }
    reservationService.getReservationsByIds(activityResIds);
  }, [activityResIds]);

  return (
    <Section
      title="Past Activity"
      variant="contained"
      right={
        <WebOnly>
          <ActivityExportButton exportChanges={exportChanges} />
        </WebOnly>
      }
    >
      <ActivityHistory
        activity={activity}
        userId={userId}
        loadMore={loadMore}
        reservations={res}
        createForGuest={createForGuest}
      />
    </Section>
  );
};
