import { useMemo } from 'react';

import { sessionQuery } from '@lib/state';
import { useObservable } from './useObservable';

export function useHasRole(...roles: Array<string>) {
  const employeeRoles = useObservable(sessionQuery.employeeRoles);

  return useMemo(() => employeeRoles.some(x => roles.includes(x)), [employeeRoles]);
}
