// tslint:disable
/**
 * Property Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CreateMaintenanceTicketRequest } from '../models';
import { GetMaintenanceTicketPhotosResponse } from '../models';
import { GetMaintenanceTicketsListReponse } from '../models';
import { MaintenanceTicketJournalRequest } from '../models';
import { MaintenanceTicketJournalResponse } from '../models';
import { MaintenanceTicketJournalsListReponse } from '../models';
import { MaintenanceTicketResponse } from '../models';
import { MaintenanceTicketStatusEnum } from '../models';
import { PriorityOptions } from '../models';
import { UpdateMaintenanceTicketRequest } from '../models';
/**
 * MaintenanceApi - axios parameter creator
 * @export
 */
export const MaintenanceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [propertyId]
     * @param {string} [continuationToken]
     * @param {string} [from]
     * @param {string} [to]
     * @param {MaintenanceTicketStatusEnum} [status]
     * @param {string} [assignedTo]
     * @param {PriorityOptions} [priority]
     * @param {number} [limit]
     * @param {boolean} [onlyActiveTickets]
     * @param {boolean} [active]
     * @param {string} [textFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceExportGet(
      propertyId?: string,
      continuationToken?: string,
      from?: string,
      to?: string,
      status?: MaintenanceTicketStatusEnum,
      assignedTo?: string,
      priority?: PriorityOptions,
      limit?: number,
      onlyActiveTickets?: boolean,
      active?: boolean,
      textFilter?: string,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/maintenance/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['PropertyId'] = propertyId;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['ContinuationToken'] = continuationToken;
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = to;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (assignedTo !== undefined) {
        localVarQueryParameter['AssignedTo'] = assignedTo;
      }

      if (priority !== undefined) {
        localVarQueryParameter['Priority'] = priority;
      }

      if (limit !== undefined) {
        localVarQueryParameter['Limit'] = limit;
      }

      if (onlyActiveTickets !== undefined) {
        localVarQueryParameter['OnlyActiveTickets'] = onlyActiveTickets;
      }

      if (active !== undefined) {
        localVarQueryParameter['Active'] = active;
      }

      if (textFilter !== undefined) {
        localVarQueryParameter['TextFilter'] = textFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {string} [continuationToken]
     * @param {string} [from]
     * @param {string} [to]
     * @param {MaintenanceTicketStatusEnum} [status]
     * @param {string} [assignedTo]
     * @param {PriorityOptions} [priority]
     * @param {number} [limit]
     * @param {boolean} [onlyActiveTickets]
     * @param {boolean} [active]
     * @param {string} [textFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceGet(
      propertyId?: string,
      continuationToken?: string,
      from?: string,
      to?: string,
      status?: MaintenanceTicketStatusEnum,
      assignedTo?: string,
      priority?: PriorityOptions,
      limit?: number,
      onlyActiveTickets?: boolean,
      active?: boolean,
      textFilter?: string,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/maintenance`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['PropertyId'] = propertyId;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['ContinuationToken'] = continuationToken;
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = to;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (assignedTo !== undefined) {
        localVarQueryParameter['AssignedTo'] = assignedTo;
      }

      if (priority !== undefined) {
        localVarQueryParameter['Priority'] = priority;
      }

      if (limit !== undefined) {
        localVarQueryParameter['Limit'] = limit;
      }

      if (onlyActiveTickets !== undefined) {
        localVarQueryParameter['OnlyActiveTickets'] = onlyActiveTickets;
      }

      if (active !== undefined) {
        localVarQueryParameter['Active'] = active;
      }

      if (textFilter !== undefined) {
        localVarQueryParameter['TextFilter'] = textFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdDeletePost(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling maintenanceIdDeletePost.'
        );
      }
      const localVarPath = `/maintenance/{id}/delete`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdJournalsGet(id: string, propertyId?: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling maintenanceIdJournalsGet.'
        );
      }
      const localVarPath = `/maintenance/{id}/journals`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {MaintenanceTicketJournalRequest} [maintenanceTicketJournalRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdJournalsPost(
      id: string,
      maintenanceTicketJournalRequest?: MaintenanceTicketJournalRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling maintenanceIdJournalsPost.'
        );
      }
      const localVarPath = `/maintenance/{id}/journals`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof maintenanceTicketJournalRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            maintenanceTicketJournalRequest !== undefined ? maintenanceTicketJournalRequest : {}
          )
        : maintenanceTicketJournalRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdPhotoListGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling maintenanceIdPhotoListGet.'
        );
      }
      const localVarPath = `/maintenance/{id}/photo/list`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} photoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdPhotoPhotoIdDeletePost(
      id: string,
      photoId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling maintenanceIdPhotoPhotoIdDeletePost.'
        );
      }
      // verify required parameter 'photoId' is not null or undefined
      if (photoId === null || photoId === undefined) {
        throw new RequiredError(
          'photoId',
          'Required parameter photoId was null or undefined when calling maintenanceIdPhotoPhotoIdDeletePost.'
        );
      }
      const localVarPath = `/maintenance/{id}/photo/{photoId}/delete`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'photoId'}}`, encodeURIComponent(String(photoId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {any} files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdPhotoUploadPost(id: string, files: any, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling maintenanceIdPhotoUploadPost.'
        );
      }
      // verify required parameter 'files' is not null or undefined
      if (files === null || files === undefined) {
        throw new RequiredError(
          'files',
          'Required parameter files was null or undefined when calling maintenanceIdPhotoUploadPost.'
        );
      }
      const localVarPath = `/maintenance/{id}/photo/upload`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (files !== undefined) {
        localVarFormParams.append('Files', files as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateMaintenanceTicketRequest} [updateMaintenanceTicketRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdPost(
      id: string,
      updateMaintenanceTicketRequest?: UpdateMaintenanceTicketRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling maintenanceIdPost.'
        );
      }
      const localVarPath = `/maintenance/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateMaintenanceTicketRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            updateMaintenanceTicketRequest !== undefined ? updateMaintenanceTicketRequest : {}
          )
        : updateMaintenanceTicketRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateMaintenanceTicketRequest} [createMaintenanceTicketRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenancePost(
      createMaintenanceTicketRequest?: CreateMaintenanceTicketRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/maintenance`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createMaintenanceTicketRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            createMaintenanceTicketRequest !== undefined ? createMaintenanceTicketRequest : {}
          )
        : createMaintenanceTicketRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenancePropertyIdTicketsIdGet(
      propertyId: string,
      id: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling maintenancePropertyIdTicketsIdGet.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling maintenancePropertyIdTicketsIdGet.'
        );
      }
      const localVarPath = `/maintenance/{propertyId}/tickets/{id}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MaintenanceApi - functional programming interface
 * @export
 */
export const MaintenanceApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [propertyId]
     * @param {string} [continuationToken]
     * @param {string} [from]
     * @param {string} [to]
     * @param {MaintenanceTicketStatusEnum} [status]
     * @param {string} [assignedTo]
     * @param {PriorityOptions} [priority]
     * @param {number} [limit]
     * @param {boolean} [onlyActiveTickets]
     * @param {boolean} [active]
     * @param {string} [textFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceExportGet(
      propertyId?: string,
      continuationToken?: string,
      from?: string,
      to?: string,
      status?: MaintenanceTicketStatusEnum,
      assignedTo?: string,
      priority?: PriorityOptions,
      limit?: number,
      onlyActiveTickets?: boolean,
      active?: boolean,
      textFilter?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).maintenanceExportGet(
        propertyId,
        continuationToken,
        from,
        to,
        status,
        assignedTo,
        priority,
        limit,
        onlyActiveTickets,
        active,
        textFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {string} [continuationToken]
     * @param {string} [from]
     * @param {string} [to]
     * @param {MaintenanceTicketStatusEnum} [status]
     * @param {string} [assignedTo]
     * @param {PriorityOptions} [priority]
     * @param {number} [limit]
     * @param {boolean} [onlyActiveTickets]
     * @param {boolean} [active]
     * @param {string} [textFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceGet(
      propertyId?: string,
      continuationToken?: string,
      from?: string,
      to?: string,
      status?: MaintenanceTicketStatusEnum,
      assignedTo?: string,
      priority?: PriorityOptions,
      limit?: number,
      onlyActiveTickets?: boolean,
      active?: boolean,
      textFilter?: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetMaintenanceTicketsListReponse> {
      const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).maintenanceGet(
        propertyId,
        continuationToken,
        from,
        to,
        status,
        assignedTo,
        priority,
        limit,
        onlyActiveTickets,
        active,
        textFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdDeletePost(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceTicketResponse> {
      const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(
        configuration
      ).maintenanceIdDeletePost(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdJournalsGet(
      id: string,
      propertyId?: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<MaintenanceTicketJournalsListReponse> {
      const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(
        configuration
      ).maintenanceIdJournalsGet(id, propertyId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {MaintenanceTicketJournalRequest} [maintenanceTicketJournalRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdJournalsPost(
      id: string,
      maintenanceTicketJournalRequest?: MaintenanceTicketJournalRequest,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<MaintenanceTicketJournalResponse> {
      const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(
        configuration
      ).maintenanceIdJournalsPost(id, maintenanceTicketJournalRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdPhotoListGet(
      id: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetMaintenanceTicketPhotosResponse> {
      const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(
        configuration
      ).maintenanceIdPhotoListGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} photoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdPhotoPhotoIdDeletePost(
      id: string,
      photoId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(
        configuration
      ).maintenanceIdPhotoPhotoIdDeletePost(id, photoId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {any} files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdPhotoUploadPost(
      id: string,
      files: any,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetMaintenanceTicketPhotosResponse> {
      const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(
        configuration
      ).maintenanceIdPhotoUploadPost(id, files, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateMaintenanceTicketRequest} [updateMaintenanceTicketRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdPost(
      id: string,
      updateMaintenanceTicketRequest?: UpdateMaintenanceTicketRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceTicketResponse> {
      const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).maintenanceIdPost(
        id,
        updateMaintenanceTicketRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {CreateMaintenanceTicketRequest} [createMaintenanceTicketRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenancePost(
      createMaintenanceTicketRequest?: CreateMaintenanceTicketRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceTicketResponse> {
      const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).maintenancePost(
        createMaintenanceTicketRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenancePropertyIdTicketsIdGet(
      propertyId: string,
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceTicketResponse> {
      const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(
        configuration
      ).maintenancePropertyIdTicketsIdGet(propertyId, id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MaintenanceApi - factory interface
 * @export
 */
export const MaintenanceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} [propertyId]
     * @param {string} [continuationToken]
     * @param {string} [from]
     * @param {string} [to]
     * @param {MaintenanceTicketStatusEnum} [status]
     * @param {string} [assignedTo]
     * @param {PriorityOptions} [priority]
     * @param {number} [limit]
     * @param {boolean} [onlyActiveTickets]
     * @param {boolean} [active]
     * @param {string} [textFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceExportGet(
      propertyId?: string,
      continuationToken?: string,
      from?: string,
      to?: string,
      status?: MaintenanceTicketStatusEnum,
      assignedTo?: string,
      priority?: PriorityOptions,
      limit?: number,
      onlyActiveTickets?: boolean,
      active?: boolean,
      textFilter?: string,
      options?: any
    ): AxiosPromise<any> {
      return MaintenanceApiFp(configuration).maintenanceExportGet(
        propertyId,
        continuationToken,
        from,
        to,
        status,
        assignedTo,
        priority,
        limit,
        onlyActiveTickets,
        active,
        textFilter,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {string} [continuationToken]
     * @param {string} [from]
     * @param {string} [to]
     * @param {MaintenanceTicketStatusEnum} [status]
     * @param {string} [assignedTo]
     * @param {PriorityOptions} [priority]
     * @param {number} [limit]
     * @param {boolean} [onlyActiveTickets]
     * @param {boolean} [active]
     * @param {string} [textFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceGet(
      propertyId?: string,
      continuationToken?: string,
      from?: string,
      to?: string,
      status?: MaintenanceTicketStatusEnum,
      assignedTo?: string,
      priority?: PriorityOptions,
      limit?: number,
      onlyActiveTickets?: boolean,
      active?: boolean,
      textFilter?: string,
      options?: any
    ): AxiosPromise<GetMaintenanceTicketsListReponse> {
      return MaintenanceApiFp(configuration).maintenanceGet(
        propertyId,
        continuationToken,
        from,
        to,
        status,
        assignedTo,
        priority,
        limit,
        onlyActiveTickets,
        active,
        textFilter,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdDeletePost(id: string, options?: any): AxiosPromise<MaintenanceTicketResponse> {
      return MaintenanceApiFp(configuration).maintenanceIdDeletePost(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdJournalsGet(
      id: string,
      propertyId?: string,
      options?: any
    ): AxiosPromise<MaintenanceTicketJournalsListReponse> {
      return MaintenanceApiFp(configuration).maintenanceIdJournalsGet(
        id,
        propertyId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {MaintenanceTicketJournalRequest} [maintenanceTicketJournalRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdJournalsPost(
      id: string,
      maintenanceTicketJournalRequest?: MaintenanceTicketJournalRequest,
      options?: any
    ): AxiosPromise<MaintenanceTicketJournalResponse> {
      return MaintenanceApiFp(configuration).maintenanceIdJournalsPost(
        id,
        maintenanceTicketJournalRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdPhotoListGet(
      id: string,
      options?: any
    ): AxiosPromise<GetMaintenanceTicketPhotosResponse> {
      return MaintenanceApiFp(configuration).maintenanceIdPhotoListGet(id, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} photoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdPhotoPhotoIdDeletePost(
      id: string,
      photoId: string,
      options?: any
    ): AxiosPromise<void> {
      return MaintenanceApiFp(configuration).maintenanceIdPhotoPhotoIdDeletePost(
        id,
        photoId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {any} files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdPhotoUploadPost(
      id: string,
      files: any,
      options?: any
    ): AxiosPromise<GetMaintenanceTicketPhotosResponse> {
      return MaintenanceApiFp(configuration).maintenanceIdPhotoUploadPost(
        id,
        files,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {UpdateMaintenanceTicketRequest} [updateMaintenanceTicketRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenanceIdPost(
      id: string,
      updateMaintenanceTicketRequest?: UpdateMaintenanceTicketRequest,
      options?: any
    ): AxiosPromise<MaintenanceTicketResponse> {
      return MaintenanceApiFp(configuration).maintenanceIdPost(
        id,
        updateMaintenanceTicketRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {CreateMaintenanceTicketRequest} [createMaintenanceTicketRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenancePost(
      createMaintenanceTicketRequest?: CreateMaintenanceTicketRequest,
      options?: any
    ): AxiosPromise<MaintenanceTicketResponse> {
      return MaintenanceApiFp(configuration).maintenancePost(
        createMaintenanceTicketRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    maintenancePropertyIdTicketsIdGet(
      propertyId: string,
      id: string,
      options?: any
    ): AxiosPromise<MaintenanceTicketResponse> {
      return MaintenanceApiFp(configuration).maintenancePropertyIdTicketsIdGet(
        propertyId,
        id,
        options
      )(axios, basePath);
    },
  };
};

/**
 * MaintenanceApi - object-oriented interface
 * @export
 * @class MaintenanceApi
 * @extends {BaseAPI}
 */
export class MaintenanceApi extends BaseAPI {
  /**
   *
   * @param {string} [propertyId]
   * @param {string} [continuationToken]
   * @param {string} [from]
   * @param {string} [to]
   * @param {MaintenanceTicketStatusEnum} [status]
   * @param {string} [assignedTo]
   * @param {PriorityOptions} [priority]
   * @param {number} [limit]
   * @param {boolean} [onlyActiveTickets]
   * @param {boolean} [active]
   * @param {string} [textFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaintenanceApi
   */
  public maintenanceExportGet(
    propertyId?: string,
    continuationToken?: string,
    from?: string,
    to?: string,
    status?: MaintenanceTicketStatusEnum,
    assignedTo?: string,
    priority?: PriorityOptions,
    limit?: number,
    onlyActiveTickets?: boolean,
    active?: boolean,
    textFilter?: string,
    options?: any
  ) {
    return MaintenanceApiFp(this.configuration).maintenanceExportGet(
      propertyId,
      continuationToken,
      from,
      to,
      status,
      assignedTo,
      priority,
      limit,
      onlyActiveTickets,
      active,
      textFilter,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [propertyId]
   * @param {string} [continuationToken]
   * @param {string} [from]
   * @param {string} [to]
   * @param {MaintenanceTicketStatusEnum} [status]
   * @param {string} [assignedTo]
   * @param {PriorityOptions} [priority]
   * @param {number} [limit]
   * @param {boolean} [onlyActiveTickets]
   * @param {boolean} [active]
   * @param {string} [textFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaintenanceApi
   */
  public maintenanceGet(
    propertyId?: string,
    continuationToken?: string,
    from?: string,
    to?: string,
    status?: MaintenanceTicketStatusEnum,
    assignedTo?: string,
    priority?: PriorityOptions,
    limit?: number,
    onlyActiveTickets?: boolean,
    active?: boolean,
    textFilter?: string,
    options?: any
  ) {
    return MaintenanceApiFp(this.configuration).maintenanceGet(
      propertyId,
      continuationToken,
      from,
      to,
      status,
      assignedTo,
      priority,
      limit,
      onlyActiveTickets,
      active,
      textFilter,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaintenanceApi
   */
  public maintenanceIdDeletePost(id: string, options?: any) {
    return MaintenanceApiFp(this.configuration).maintenanceIdDeletePost(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {string} [propertyId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaintenanceApi
   */
  public maintenanceIdJournalsGet(id: string, propertyId?: string, options?: any) {
    return MaintenanceApiFp(this.configuration).maintenanceIdJournalsGet(
      id,
      propertyId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {MaintenanceTicketJournalRequest} [maintenanceTicketJournalRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaintenanceApi
   */
  public maintenanceIdJournalsPost(
    id: string,
    maintenanceTicketJournalRequest?: MaintenanceTicketJournalRequest,
    options?: any
  ) {
    return MaintenanceApiFp(this.configuration).maintenanceIdJournalsPost(
      id,
      maintenanceTicketJournalRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaintenanceApi
   */
  public maintenanceIdPhotoListGet(id: string, options?: any) {
    return MaintenanceApiFp(this.configuration).maintenanceIdPhotoListGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {string} photoId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaintenanceApi
   */
  public maintenanceIdPhotoPhotoIdDeletePost(id: string, photoId: string, options?: any) {
    return MaintenanceApiFp(this.configuration).maintenanceIdPhotoPhotoIdDeletePost(
      id,
      photoId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {any} files
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaintenanceApi
   */
  public maintenanceIdPhotoUploadPost(id: string, files: any, options?: any) {
    return MaintenanceApiFp(this.configuration).maintenanceIdPhotoUploadPost(
      id,
      files,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {UpdateMaintenanceTicketRequest} [updateMaintenanceTicketRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaintenanceApi
   */
  public maintenanceIdPost(
    id: string,
    updateMaintenanceTicketRequest?: UpdateMaintenanceTicketRequest,
    options?: any
  ) {
    return MaintenanceApiFp(this.configuration).maintenanceIdPost(
      id,
      updateMaintenanceTicketRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {CreateMaintenanceTicketRequest} [createMaintenanceTicketRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaintenanceApi
   */
  public maintenancePost(
    createMaintenanceTicketRequest?: CreateMaintenanceTicketRequest,
    options?: any
  ) {
    return MaintenanceApiFp(this.configuration).maintenancePost(
      createMaintenanceTicketRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaintenanceApi
   */
  public maintenancePropertyIdTicketsIdGet(propertyId: string, id: string, options?: any) {
    return MaintenanceApiFp(this.configuration).maintenancePropertyIdTicketsIdGet(
      propertyId,
      id,
      options
    )(this.axios, this.basePath);
  }
}
