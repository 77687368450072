import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CorporateAffiliation } from '@lib/state';

export const CorporateAffiliationIcon: React.FC<Pick<
  CorporateAffiliation,
  'corporateAccountId'
>> = ({ corporateAccountId }) =>
  corporateAccountId != null ? <FontAwesomeIcon icon="building" size="sm" fixedWidth /> : null;
