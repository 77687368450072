import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { PaginationState, Property } from '@lib/state';
import {
  MaintenanceTicket,
  MaintenanceTicketPhoto,
  PriorityOptions,
  TicketStatus,
} from './maintenance-ticket.model';
export interface MaintenanceTicketState
  extends EntityState<MaintenanceTicket, MaintenanceTicket['id']>,
    ActiveState<MaintenanceTicket['id']> {
  pagination: PaginationState;
  photos?: MaintenanceTicketPhoto[];
}

export interface MaintenanceTicketUIState {
  propertyId?: Property['id'];
  startDate?: Date;
  endDate?: Date;
  status?: TicketStatus;
  assignedTo?: string;
  priority?: PriorityOptions;
  active?: boolean;
  textFilter?: string;
}
@StoreConfig({ name: 'maintenance-tickets', resettable: true, idKey: 'id' })
export class MaintenanceTicketStore extends EntityStore<MaintenanceTicketState> {
  constructor() {
    super({
      ui: {
        status: TicketStatus.Open,
        priority: PriorityOptions.Normal,
      },
      pagination: {
        isDone: true,
        continuationToken: undefined,
      },
    });
  }
}

export const maintenanceTicketStore = new MaintenanceTicketStore();
