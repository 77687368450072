import { QRCode, QRCodeFormFeilds, QRCodeForms, Room, qrCodeService } from '@lib/state';
import { QRCodeForm } from './qr-code-form';
import { StyledDialog, useFormEvents } from '@lib/common';
import React, { useCallback, useEffect, useState } from 'react';
import { DialogContent, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { AuditLogs } from 'app/shared';
import { QRCodeView } from './qr-code-view';

interface Props {
  qrCode?: QRCode;
  propertyId: string;
  rooms: Array<Room>;
  open: boolean;
  onClose: () => void;
}

export function EditQRCodeModal({ qrCode, propertyId, rooms, open, onClose }: Props) {
  const [tab, setTab] = useState('edit');
  const [{ status, error }, reset] = useFormEvents(QRCodeForms.UpdateQRCode);

  useEffect(() => {
    if (status === 'success') onClose();
  }, [status, onClose]);

  const onSubmit = useCallback(
    (code: QRCodeFormFeilds) => {
      reset();
      qrCodeService.updateQRCode(propertyId, { ...code });
    },
    [reset, propertyId]
  );

  if (!qrCode) onClose();

  return (
    <StyledDialog
      title={`Edit QR Code ${qrCode?.qrCodeNumber}`}
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      scroll="paper"
    >
      <TabContext value={tab}>
        <Tabs
          value={tab}
          onChange={(_, x) => setTab(x)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Edit" value="edit" />
          <Tab label="QR Code" value="qrcode" />
          <Tab label="Logs" value="logs" />
        </Tabs>
        <DialogContent>
          <TabPanel value="edit">
            <QRCodeForm
              qrCode={qrCode}
              propertyId={propertyId}
              rooms={rooms}
              onSubmit={onSubmit}
              formStatus={status}
              error={error}
            />
          </TabPanel>
          <TabPanel value="qrcode">
            {qrCode && <QRCodeView qrCode={qrCode} propertyId={propertyId} />}
          </TabPanel>
          <TabPanel value="logs">
            <AuditLogs scope="qr-code" id={qrCode?.id} />
          </TabPanel>
        </DialogContent>
      </TabContext>
    </StyledDialog>
  );
}
