import React, { memo } from 'react';
import { Box, BoxProps } from '@material-ui/core';

interface Props extends BoxProps {
  val?: string | null;
  parenthesis?: boolean;
}

export const GuestSIdDisplay: React.FC<Props> = memo(({ val, parenthesis = false, ...props }) => (
  <Box {...props} component="span">
    {parenthesis ? ` (${val})` : val}
  </Box>
));
