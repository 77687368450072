import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import { HousekeepingPolicy } from '@lib/state';
import { FormNumberField, useFieldError } from '@lib/common';
interface Props {
  policy?: HousekeepingPolicy | null;
}

export const HousekeepingPolicyForm: React.FC<Props> = ({ policy }) => {
  const { errors } = useFormContext();

  const touchUpCleanError = useFieldError('touchUpCleanFrequency', errors);
  const fullCleanError = useFieldError('fullCleanFrequency', errors);

  const touchUpCleanHelperText = useMemo(
    () =>
      touchUpCleanError?.message ??
      'Interval in days that the room should be touch up cleaned. Leave blank for no cleaning.',
    [touchUpCleanError]
  );

  const fullCleanHelperText = useMemo(
    () =>
      fullCleanError?.message ??
      'Interval in days that the room should be full cleaned. Leave blank for no cleaning.',
    [fullCleanError]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormNumberField
          name="touchUpCleanFrequency"
          label="Touch Up Clean Frequency"
          defaultValue={policy?.touchUpCleanFrequency ?? ''}
          helperText={touchUpCleanHelperText}
          validationOptions={{ min: { value: 1, message: 'Value must be greater than 0.' } }}
          fullWidth
          data-testid="houseKeepingTouchUpFreqInput"
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumberField
          name="fullCleanFrequency"
          label="Full Clean Frequency"
          defaultValue={policy?.fullCleanFrequency ?? ''}
          helperText={fullCleanHelperText}
          validationOptions={{ min: { value: 1, message: 'Value must be greater than 0.' } }}
          fullWidth
          data-testid="houseKeepingFullCleanFreqInput"
        />
      </Grid>
    </Grid>
  );
};
