import { Reservations } from '../api';

export type GuestProfile = Reservations.GuestProfileModel & {
  emailVerfied?: boolean;
  phoneNumberVerified?: boolean;
};

export type Gender = Reservations.Gender;
export const Gender = Reservations.Gender;

export const guestPictureSize = 100;

export function mapReservationContact(contact?: Reservations.ReservationContactModel) {
  return {
    userId: contact?.userId ?? '',
    name: contact?.name ?? {},
    contact: contact?.contact ?? {},
    address: contact?.address ?? {},
    sId: contact?.sId ?? '',
  };
}
