import React from 'react';
import { Box } from '@material-ui/core';
import { usePageTitle } from '@lib/common';
import { DeviceProvisioningQRCodeComponent } from '../components/device-provisioning-qrcode';

export const DeviceEnrollmentQRCodePage: React.FC = () => {
  usePageTitle('Device Enrollment QR Code');

  return (
    <Box display="flex" alignItems="center" flexDirection="column" paddingTop={3}>
      <DeviceProvisioningQRCodeComponent />
    </Box>
  );
};
