// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CreateVipHousekeepingOverrideRequest } from '../models';
import { UpdateVipHousekeepingOverrideRequest } from '../models';
import { VipHousekeepingOverrideItemResponse } from '../models';
import { VipHousekeepingOverrideListResponse } from '../models';
/**
 * VipHousekeepingOverrideApi - axios parameter creator
 * @export
 */
export const VipHousekeepingOverrideApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdViphousekeepingoverrideGet(propertyId: string, options: any = {}): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdViphousekeepingoverrideGet.'
        );
      }
      const localVarPath = `/{propertyId}/viphousekeepingoverride`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdViphousekeepingoverrideIdDelete(
      id: string,
      propertyId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling propertyIdViphousekeepingoverrideIdDelete.'
        );
      }
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdViphousekeepingoverrideIdDelete.'
        );
      }
      const localVarPath = `/{propertyId}/viphousekeepingoverride/{id}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdViphousekeepingoverrideIdGet(
      id: string,
      propertyId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling propertyIdViphousekeepingoverrideIdGet.'
        );
      }
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdViphousekeepingoverrideIdGet.'
        );
      }
      const localVarPath = `/{propertyId}/viphousekeepingoverride/{id}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {UpdateVipHousekeepingOverrideRequest} [updateVipHousekeepingOverrideRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdViphousekeepingoverridePost(
      propertyId: string,
      updateVipHousekeepingOverrideRequest?: UpdateVipHousekeepingOverrideRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdViphousekeepingoverridePost.'
        );
      }
      const localVarPath = `/{propertyId}/viphousekeepingoverride`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateVipHousekeepingOverrideRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            updateVipHousekeepingOverrideRequest !== undefined
              ? updateVipHousekeepingOverrideRequest
              : {}
          )
        : updateVipHousekeepingOverrideRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {CreateVipHousekeepingOverrideRequest} [createVipHousekeepingOverrideRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdViphousekeepingoverridePut(
      propertyId: string,
      createVipHousekeepingOverrideRequest?: CreateVipHousekeepingOverrideRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdViphousekeepingoverridePut.'
        );
      }
      const localVarPath = `/{propertyId}/viphousekeepingoverride`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createVipHousekeepingOverrideRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            createVipHousekeepingOverrideRequest !== undefined
              ? createVipHousekeepingOverrideRequest
              : {}
          )
        : createVipHousekeepingOverrideRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * VipHousekeepingOverrideApi - functional programming interface
 * @export
 */
export const VipHousekeepingOverrideApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdViphousekeepingoverrideGet(
      propertyId: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<VipHousekeepingOverrideListResponse> {
      const localVarAxiosArgs = VipHousekeepingOverrideApiAxiosParamCreator(
        configuration
      ).propertyIdViphousekeepingoverrideGet(propertyId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdViphousekeepingoverrideIdDelete(
      id: string,
      propertyId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = VipHousekeepingOverrideApiAxiosParamCreator(
        configuration
      ).propertyIdViphousekeepingoverrideIdDelete(id, propertyId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdViphousekeepingoverrideIdGet(
      id: string,
      propertyId: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<VipHousekeepingOverrideItemResponse> {
      const localVarAxiosArgs = VipHousekeepingOverrideApiAxiosParamCreator(
        configuration
      ).propertyIdViphousekeepingoverrideIdGet(id, propertyId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {UpdateVipHousekeepingOverrideRequest} [updateVipHousekeepingOverrideRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdViphousekeepingoverridePost(
      propertyId: string,
      updateVipHousekeepingOverrideRequest?: UpdateVipHousekeepingOverrideRequest,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<VipHousekeepingOverrideItemResponse> {
      const localVarAxiosArgs = VipHousekeepingOverrideApiAxiosParamCreator(
        configuration
      ).propertyIdViphousekeepingoverridePost(
        propertyId,
        updateVipHousekeepingOverrideRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {CreateVipHousekeepingOverrideRequest} [createVipHousekeepingOverrideRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdViphousekeepingoverridePut(
      propertyId: string,
      createVipHousekeepingOverrideRequest?: CreateVipHousekeepingOverrideRequest,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<VipHousekeepingOverrideItemResponse> {
      const localVarAxiosArgs = VipHousekeepingOverrideApiAxiosParamCreator(
        configuration
      ).propertyIdViphousekeepingoverridePut(
        propertyId,
        createVipHousekeepingOverrideRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * VipHousekeepingOverrideApi - factory interface
 * @export
 */
export const VipHousekeepingOverrideApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdViphousekeepingoverrideGet(
      propertyId: string,
      options?: any
    ): AxiosPromise<VipHousekeepingOverrideListResponse> {
      return VipHousekeepingOverrideApiFp(configuration).propertyIdViphousekeepingoverrideGet(
        propertyId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdViphousekeepingoverrideIdDelete(
      id: string,
      propertyId: string,
      options?: any
    ): AxiosPromise<void> {
      return VipHousekeepingOverrideApiFp(configuration).propertyIdViphousekeepingoverrideIdDelete(
        id,
        propertyId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdViphousekeepingoverrideIdGet(
      id: string,
      propertyId: string,
      options?: any
    ): AxiosPromise<VipHousekeepingOverrideItemResponse> {
      return VipHousekeepingOverrideApiFp(configuration).propertyIdViphousekeepingoverrideIdGet(
        id,
        propertyId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {UpdateVipHousekeepingOverrideRequest} [updateVipHousekeepingOverrideRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdViphousekeepingoverridePost(
      propertyId: string,
      updateVipHousekeepingOverrideRequest?: UpdateVipHousekeepingOverrideRequest,
      options?: any
    ): AxiosPromise<VipHousekeepingOverrideItemResponse> {
      return VipHousekeepingOverrideApiFp(configuration).propertyIdViphousekeepingoverridePost(
        propertyId,
        updateVipHousekeepingOverrideRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {CreateVipHousekeepingOverrideRequest} [createVipHousekeepingOverrideRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdViphousekeepingoverridePut(
      propertyId: string,
      createVipHousekeepingOverrideRequest?: CreateVipHousekeepingOverrideRequest,
      options?: any
    ): AxiosPromise<VipHousekeepingOverrideItemResponse> {
      return VipHousekeepingOverrideApiFp(configuration).propertyIdViphousekeepingoverridePut(
        propertyId,
        createVipHousekeepingOverrideRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * VipHousekeepingOverrideApi - object-oriented interface
 * @export
 * @class VipHousekeepingOverrideApi
 * @extends {BaseAPI}
 */
export class VipHousekeepingOverrideApi extends BaseAPI {
  /**
   *
   * @param {string} propertyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VipHousekeepingOverrideApi
   */
  public propertyIdViphousekeepingoverrideGet(propertyId: string, options?: any) {
    return VipHousekeepingOverrideApiFp(this.configuration).propertyIdViphousekeepingoverrideGet(
      propertyId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {string} propertyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VipHousekeepingOverrideApi
   */
  public propertyIdViphousekeepingoverrideIdDelete(id: string, propertyId: string, options?: any) {
    return VipHousekeepingOverrideApiFp(
      this.configuration
    ).propertyIdViphousekeepingoverrideIdDelete(
      id,
      propertyId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {string} propertyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VipHousekeepingOverrideApi
   */
  public propertyIdViphousekeepingoverrideIdGet(id: string, propertyId: string, options?: any) {
    return VipHousekeepingOverrideApiFp(this.configuration).propertyIdViphousekeepingoverrideIdGet(
      id,
      propertyId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {UpdateVipHousekeepingOverrideRequest} [updateVipHousekeepingOverrideRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VipHousekeepingOverrideApi
   */
  public propertyIdViphousekeepingoverridePost(
    propertyId: string,
    updateVipHousekeepingOverrideRequest?: UpdateVipHousekeepingOverrideRequest,
    options?: any
  ) {
    return VipHousekeepingOverrideApiFp(this.configuration).propertyIdViphousekeepingoverridePost(
      propertyId,
      updateVipHousekeepingOverrideRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {CreateVipHousekeepingOverrideRequest} [createVipHousekeepingOverrideRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VipHousekeepingOverrideApi
   */
  public propertyIdViphousekeepingoverridePut(
    propertyId: string,
    createVipHousekeepingOverrideRequest?: CreateVipHousekeepingOverrideRequest,
    options?: any
  ) {
    return VipHousekeepingOverrideApiFp(this.configuration).propertyIdViphousekeepingoverridePut(
      propertyId,
      createVipHousekeepingOverrideRequest,
      options
    )(this.axios, this.basePath);
  }
}
