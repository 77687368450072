import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';

import { FormStatus } from '@lib/state';
import {
  ErrorDisplay,
  Feedback,
  FormTextField,
  RemoveButton,
  Section,
  SubmitButton,
  useFieldLabelProps,
  useFormEvents,
} from '@lib/common';
import { Area, AreaForms } from 'app/state';
import { AreaRoomsForm } from './area-rooms.form';
import { useFormContext } from 'react-hook-form';

export interface AreaFormFields {
  name: string;
  roomIds?: Array<string>;
}

interface Props {
  area?: Area;
  status: FormStatus;
  error?: Error;
  resetFormUI: () => void;
  onRemove?: () => void;
}

export const AreaForm: React.FC<Props> = ({ area, status, error, resetFormUI, onRemove }) => {
  const history = useHistory();

  const { setValue } = useFormContext();
  const [removeEvent] = useFormEvents(AreaForms.RemoveArea);

  useEffect(() => {
    setValue('name', area?.name ?? '');
    setValue('roomIds', area?.rooms?.map(x => x.id) ?? []);
    // eslint-disable-next-line
  }, [area]);

  const nameLabelProps = useFieldLabelProps('name', area?.name);

  return (
    <>
      <Section title={`${area ? 'Edit' : 'Create'} Common Area`} textAlign="left">
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <FormTextField
              name="name"
              label="Name"
              validationOptions={{ required: 'Name is required.' }}
              InputLabelProps={nameLabelProps}
              fullWidth
              required
            />
          </Grid>
        </Grid>
      </Section>
      {area?.rooms && <AreaRoomsForm area={area} />}
      <Box pt={2} pb={1}>
        <Feedback show={status === FormStatus.Success} severity="success" onHide={resetFormUI}>
          Common Area {area ? 'saved' : 'created'} successfully!
        </Feedback>
        <ErrorDisplay error={error} />
      </Box>
      <Box display="flex" justifyContent="flex-end" py={1}>
        {onRemove && (
          <RemoveButton
            onConfirm={onRemove}
            onSuccess={() => history.push('/areas')}
            confirmView={
              <Typography>
                Are you sure you want to remove {area?.name} and all provisioned devices?
              </Typography>
            }
            status={removeEvent.status}
            error={removeEvent.error}
          />
        )}
        <Box ml={2}>
          <SubmitButton
            variant="contained"
            color="secondary"
            pending={status === FormStatus.Pending}
            disabled={status === FormStatus.Pending}
          >
            {area ? 'Save' : 'Create'} Common Area
          </SubmitButton>
        </Box>
      </Box>
    </>
  );
};
