import React from 'react';
import { Box } from '@material-ui/core';
import { usePageTitle } from '@lib/common';
import { PropertyQRCodeComponent } from '../components/property-qrcode.component';

export const PropertyQRCodePage: React.FC = () => {
  usePageTitle('Property QR Code');

  return (
    <Box display="flex" alignItems="center" flexDirection="column" paddingTop={3}>
      <PropertyQRCodeComponent />
    </Box>
  );
};
