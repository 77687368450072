export function memoize<TArgs extends any[], TResult, TKey = any>(
  fn: (...args: TArgs) => TResult,
  keyBy: (...args: TArgs) => TKey = (...[first]) => first
): (...args: TArgs) => TResult {
  const cache = new Map<TKey, TResult>();

  return (...args: TArgs) => {
    const key = keyBy(...args);
    if (!cache.has(key)) {
      cache.set(key, fn(...args));
    }

    return <TResult>cache.get(key);
  };
}
