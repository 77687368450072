import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';

import { CorporateAccount, CostCenterFields } from '@lib/state';
import { Section, SectionProps } from '../../components';
import CostCenterFieldRequired from './cost-center-field-required';
import { useBookingRole } from './useBookingRole';

interface Props {
  corporateAccount?: CorporateAccount;
}

const excludedFields = [CostCenterFields.EmployeeNumber, CostCenterFields.BillingReference];

export const CostCenterForm: React.FC<Props & Omit<SectionProps, 'title' | 'children'>> = ({
  corporateAccount,
  ...props
}) => {
  const { register } = useFormContext();

  const fields = useMemo(
    () =>
      corporateAccount?.fields.filter(
        x => x.isEnabled && !excludedFields.includes(x.key as CostCenterFields)
      ),
    [corporateAccount]
  );

  const corporateAccountId = corporateAccount ? corporateAccount.id : undefined;
  const { isEmployee, isDelegate } = useBookingRole(corporateAccountId);

  if (!fields?.length) return null;

  return (
    <Section title="Cost Center Fields" maxWidth="md" {...props}>
      {fields.map((x, i) => (
        <Box key={x.key} mb={2}>
          <input type="hidden" name={`fields[${i}].key`} ref={register} defaultValue={x.key} />
          <CostCenterFieldRequired
            name={`fields[${i}].value`}
            corporateAccountId={corporateAccount?.id}
            config={x}
            isDelegateOrEmployee={isDelegate || isEmployee}
          />
        </Box>
      ))}
    </Section>
  );
};
