// tslint:disable
/**
 * Property Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CreateEmployeeInviteRequest } from '../models';
import { CreateEmployeeRequest } from '../models';
import { EmployeeInviteExistsResponse } from '../models';
import { EmployeeListResponse } from '../models';
import { EmployeeResponse } from '../models';
import { EnrollEmployeeRequest } from '../models';
import { GetMyEmployeeInfoResponse } from '../models';
import { UpdateEmployeeRolesRequest } from '../models';
/**
 * EmployeesApi - axios parameter creator
 * @export
 */
export const EmployeesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {EnrollEmployeeRequest} [enrollEmployeeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesEnrollPut(
      enrollEmployeeRequest?: EnrollEmployeeRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/employees/enroll`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof enrollEmployeeRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(enrollEmployeeRequest !== undefined ? enrollEmployeeRequest : {})
        : enrollEmployeeRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesGet(propertyId?: string, options: any = {}): RequestArgs {
      const localVarPath = `/employees`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['PropertyId'] = propertyId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesInviteInviteIdGet(inviteId: string, options: any = {}): RequestArgs {
      // verify required parameter 'inviteId' is not null or undefined
      if (inviteId === null || inviteId === undefined) {
        throw new RequiredError(
          'inviteId',
          'Required parameter inviteId was null or undefined when calling employeesInviteInviteIdGet.'
        );
      }
      const localVarPath = `/employees/invite/{inviteId}`.replace(
        `{${'inviteId'}}`,
        encodeURIComponent(String(inviteId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateEmployeeInviteRequest} [createEmployeeInviteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesInvitePost(
      createEmployeeInviteRequest?: CreateEmployeeInviteRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/employees/invite`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createEmployeeInviteRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            createEmployeeInviteRequest !== undefined ? createEmployeeInviteRequest : {}
          )
        : createEmployeeInviteRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesMeGet(propertyId?: string, options: any = {}): RequestArgs {
      const localVarPath = `/employees/me`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['PropertyId'] = propertyId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesUserIdDelete(userId: string, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling employeesUserIdDelete.'
        );
      }
      const localVarPath = `/employees/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesUserIdGet(userId: string, propertyId?: string, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling employeesUserIdGet.'
        );
      }
      const localVarPath = `/employees/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['PropertyId'] = propertyId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {CreateEmployeeRequest} [createEmployeeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesUserIdPut(
      userId: string,
      createEmployeeRequest?: CreateEmployeeRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling employeesUserIdPut.'
        );
      }
      const localVarPath = `/employees/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createEmployeeRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createEmployeeRequest !== undefined ? createEmployeeRequest : {})
        : createEmployeeRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {string} propertyId
     * @param {UpdateEmployeeRolesRequest} [updateEmployeeRolesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesUserIdRolesPropertyIdPut(
      userId: string,
      propertyId: string,
      updateEmployeeRolesRequest?: UpdateEmployeeRolesRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling employeesUserIdRolesPropertyIdPut.'
        );
      }
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling employeesUserIdRolesPropertyIdPut.'
        );
      }
      const localVarPath = `/employees/{userId}/roles/{propertyId}`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateEmployeeRolesRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateEmployeeRolesRequest !== undefined ? updateEmployeeRolesRequest : {})
        : updateEmployeeRolesRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateEmployeeRolesRequest} [updateEmployeeRolesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesUserIdRolesPut(
      userId: string,
      updateEmployeeRolesRequest?: UpdateEmployeeRolesRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling employeesUserIdRolesPut.'
        );
      }
      const localVarPath = `/employees/{userId}/roles`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateEmployeeRolesRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateEmployeeRolesRequest !== undefined ? updateEmployeeRolesRequest : {})
        : updateEmployeeRolesRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmployeesApi - functional programming interface
 * @export
 */
export const EmployeesApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {EnrollEmployeeRequest} [enrollEmployeeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesEnrollPut(
      enrollEmployeeRequest?: EnrollEmployeeRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse> {
      const localVarAxiosArgs = EmployeesApiAxiosParamCreator(configuration).employeesEnrollPut(
        enrollEmployeeRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesGet(
      propertyId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeListResponse> {
      const localVarAxiosArgs = EmployeesApiAxiosParamCreator(configuration).employeesGet(
        propertyId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesInviteInviteIdGet(
      inviteId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeInviteExistsResponse> {
      const localVarAxiosArgs = EmployeesApiAxiosParamCreator(
        configuration
      ).employeesInviteInviteIdGet(inviteId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {CreateEmployeeInviteRequest} [createEmployeeInviteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesInvitePost(
      createEmployeeInviteRequest?: CreateEmployeeInviteRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EmployeesApiAxiosParamCreator(configuration).employeesInvitePost(
        createEmployeeInviteRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesMeGet(
      propertyId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyEmployeeInfoResponse> {
      const localVarAxiosArgs = EmployeesApiAxiosParamCreator(configuration).employeesMeGet(
        propertyId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesUserIdDelete(
      userId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EmployeesApiAxiosParamCreator(configuration).employeesUserIdDelete(
        userId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} userId
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesUserIdGet(
      userId: string,
      propertyId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse> {
      const localVarAxiosArgs = EmployeesApiAxiosParamCreator(configuration).employeesUserIdGet(
        userId,
        propertyId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} userId
     * @param {CreateEmployeeRequest} [createEmployeeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesUserIdPut(
      userId: string,
      createEmployeeRequest?: CreateEmployeeRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse> {
      const localVarAxiosArgs = EmployeesApiAxiosParamCreator(configuration).employeesUserIdPut(
        userId,
        createEmployeeRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} userId
     * @param {string} propertyId
     * @param {UpdateEmployeeRolesRequest} [updateEmployeeRolesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesUserIdRolesPropertyIdPut(
      userId: string,
      propertyId: string,
      updateEmployeeRolesRequest?: UpdateEmployeeRolesRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse> {
      const localVarAxiosArgs = EmployeesApiAxiosParamCreator(
        configuration
      ).employeesUserIdRolesPropertyIdPut(userId, propertyId, updateEmployeeRolesRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateEmployeeRolesRequest} [updateEmployeeRolesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesUserIdRolesPut(
      userId: string,
      updateEmployeeRolesRequest?: UpdateEmployeeRolesRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse> {
      const localVarAxiosArgs = EmployeesApiAxiosParamCreator(
        configuration
      ).employeesUserIdRolesPut(userId, updateEmployeeRolesRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * EmployeesApi - factory interface
 * @export
 */
export const EmployeesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {EnrollEmployeeRequest} [enrollEmployeeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesEnrollPut(
      enrollEmployeeRequest?: EnrollEmployeeRequest,
      options?: any
    ): AxiosPromise<EmployeeResponse> {
      return EmployeesApiFp(configuration).employeesEnrollPut(enrollEmployeeRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesGet(propertyId?: string, options?: any): AxiosPromise<EmployeeListResponse> {
      return EmployeesApiFp(configuration).employeesGet(propertyId, options)(axios, basePath);
    },
    /**
     *
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesInviteInviteIdGet(
      inviteId: string,
      options?: any
    ): AxiosPromise<EmployeeInviteExistsResponse> {
      return EmployeesApiFp(configuration).employeesInviteInviteIdGet(inviteId, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {CreateEmployeeInviteRequest} [createEmployeeInviteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesInvitePost(
      createEmployeeInviteRequest?: CreateEmployeeInviteRequest,
      options?: any
    ): AxiosPromise<void> {
      return EmployeesApiFp(configuration).employeesInvitePost(
        createEmployeeInviteRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesMeGet(propertyId?: string, options?: any): AxiosPromise<GetMyEmployeeInfoResponse> {
      return EmployeesApiFp(configuration).employeesMeGet(propertyId, options)(axios, basePath);
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesUserIdDelete(userId: string, options?: any): AxiosPromise<void> {
      return EmployeesApiFp(configuration).employeesUserIdDelete(userId, options)(axios, basePath);
    },
    /**
     *
     * @param {string} userId
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesUserIdGet(
      userId: string,
      propertyId?: string,
      options?: any
    ): AxiosPromise<EmployeeResponse> {
      return EmployeesApiFp(configuration).employeesUserIdGet(
        userId,
        propertyId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} userId
     * @param {CreateEmployeeRequest} [createEmployeeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesUserIdPut(
      userId: string,
      createEmployeeRequest?: CreateEmployeeRequest,
      options?: any
    ): AxiosPromise<EmployeeResponse> {
      return EmployeesApiFp(configuration).employeesUserIdPut(
        userId,
        createEmployeeRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} userId
     * @param {string} propertyId
     * @param {UpdateEmployeeRolesRequest} [updateEmployeeRolesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesUserIdRolesPropertyIdPut(
      userId: string,
      propertyId: string,
      updateEmployeeRolesRequest?: UpdateEmployeeRolesRequest,
      options?: any
    ): AxiosPromise<EmployeeResponse> {
      return EmployeesApiFp(configuration).employeesUserIdRolesPropertyIdPut(
        userId,
        propertyId,
        updateEmployeeRolesRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateEmployeeRolesRequest} [updateEmployeeRolesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesUserIdRolesPut(
      userId: string,
      updateEmployeeRolesRequest?: UpdateEmployeeRolesRequest,
      options?: any
    ): AxiosPromise<EmployeeResponse> {
      return EmployeesApiFp(configuration).employeesUserIdRolesPut(
        userId,
        updateEmployeeRolesRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * EmployeesApi - object-oriented interface
 * @export
 * @class EmployeesApi
 * @extends {BaseAPI}
 */
export class EmployeesApi extends BaseAPI {
  /**
   *
   * @param {EnrollEmployeeRequest} [enrollEmployeeRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeesApi
   */
  public employeesEnrollPut(enrollEmployeeRequest?: EnrollEmployeeRequest, options?: any) {
    return EmployeesApiFp(this.configuration).employeesEnrollPut(enrollEmployeeRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} [propertyId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeesApi
   */
  public employeesGet(propertyId?: string, options?: any) {
    return EmployeesApiFp(this.configuration).employeesGet(propertyId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} inviteId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeesApi
   */
  public employeesInviteInviteIdGet(inviteId: string, options?: any) {
    return EmployeesApiFp(this.configuration).employeesInviteInviteIdGet(inviteId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {CreateEmployeeInviteRequest} [createEmployeeInviteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeesApi
   */
  public employeesInvitePost(
    createEmployeeInviteRequest?: CreateEmployeeInviteRequest,
    options?: any
  ) {
    return EmployeesApiFp(this.configuration).employeesInvitePost(
      createEmployeeInviteRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [propertyId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeesApi
   */
  public employeesMeGet(propertyId?: string, options?: any) {
    return EmployeesApiFp(this.configuration).employeesMeGet(propertyId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeesApi
   */
  public employeesUserIdDelete(userId: string, options?: any) {
    return EmployeesApiFp(this.configuration).employeesUserIdDelete(userId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} userId
   * @param {string} [propertyId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeesApi
   */
  public employeesUserIdGet(userId: string, propertyId?: string, options?: any) {
    return EmployeesApiFp(this.configuration).employeesUserIdGet(
      userId,
      propertyId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} userId
   * @param {CreateEmployeeRequest} [createEmployeeRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeesApi
   */
  public employeesUserIdPut(
    userId: string,
    createEmployeeRequest?: CreateEmployeeRequest,
    options?: any
  ) {
    return EmployeesApiFp(this.configuration).employeesUserIdPut(
      userId,
      createEmployeeRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} userId
   * @param {string} propertyId
   * @param {UpdateEmployeeRolesRequest} [updateEmployeeRolesRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeesApi
   */
  public employeesUserIdRolesPropertyIdPut(
    userId: string,
    propertyId: string,
    updateEmployeeRolesRequest?: UpdateEmployeeRolesRequest,
    options?: any
  ) {
    return EmployeesApiFp(this.configuration).employeesUserIdRolesPropertyIdPut(
      userId,
      propertyId,
      updateEmployeeRolesRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} userId
   * @param {UpdateEmployeeRolesRequest} [updateEmployeeRolesRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeesApi
   */
  public employeesUserIdRolesPut(
    userId: string,
    updateEmployeeRolesRequest?: UpdateEmployeeRolesRequest,
    options?: any
  ) {
    return EmployeesApiFp(this.configuration).employeesUserIdRolesPut(
      userId,
      updateEmployeeRolesRequest,
      options
    )(this.axios, this.basePath);
  }
}
