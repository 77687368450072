import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';

import { GuestProfilesForms, FormStatus, GuestProfile } from '@lib/state';
import {
  FormSubmitButton,
  GuestInformationForm,
  ContactInformationForm,
  useFormEvents,
  Feedback,
  ErrorDisplay,
  Section,
  AccountVerification,
} from '@lib/common';

interface Props {
  guest: GuestProfile;
}

export const GuestProfileForm: React.FC<Props> = ({ guest }) => {
  const [{ status, error }, reset] = useFormEvents(GuestProfilesForms.UpdateProfile);
  const { reset: resetForm } = useFormContext();

  useEffect(() => {
    if (guest) {
      resetForm(guest);
    }
  }, [guest, resetForm]);

  return (
    <Section title="Guest Info" variant="contained" banner={<AccountVerification guest={guest} />}>
      <ContactInformationForm
        email={guest.contact.email}
        emailVerified={guest.emailVerfied}
        phone={guest.contact.phone}
        phoneVerified={guest.phoneNumberVerified}
        isEmailDisabled={false}
      />
      <GuestInformationForm
        name={guest.name}
        address={guest.address}
        gender={guest.gender}
        language={guest.language}
        archived={guest.archived}
        optional={true}
        sId={guest.sId}
        vipStatus={guest.vipStatus}
        isManagementPortal={true}
      />
      <Box mt={1.5}>
        <Feedback show={status === FormStatus.Success} severity="success" onHide={reset}>
          Guest profile has been updated successfully.
        </Feedback>
        <ErrorDisplay error={error} />
        <Box display="flex" justifyContent="flex-end">
          <FormSubmitButton size="small" color="secondary" variant="contained">
            Save
          </FormSubmitButton>
        </Box>
      </Box>
    </Section>
  );
};
