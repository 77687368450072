import React from 'react';
import { DialogContent } from '@material-ui/core';
import { GuestNote } from 'app/state';
import { FormHub, StyledDialog, SubmitButton } from '@lib/common';
import { GuestNoteForm } from './guest-note-form';

interface Props {
  guestNote: GuestNote;
  open: boolean;
  onClose: () => void;
  onSubmit: (args: any) => void;
}

export const GuestNoteModal: React.FC<Props> = ({ guestNote, open, onSubmit, onClose }) => {
  return (
    <>
      <StyledDialog title="Guest Note" open={open} onClose={onClose}>
        <FormHub
          onSubmit={(arg: any) => {
            onSubmit({ guestNoteId: guestNote.id, note: arg.note, noteType: arg.noteType });
          }}
        >
          <DialogContent>
            <GuestNoteForm guestNote={guestNote}></GuestNoteForm>
          </DialogContent>
          <DialogContent>
            <SubmitButton variant="contained" color="secondary" fullWidth>
              Save
            </SubmitButton>
          </DialogContent>
        </FormHub>
      </StyledDialog>
    </>
  );
};
