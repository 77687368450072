import { Order, QueryEntity } from '@datorama/akita';
import maintenanceTicketJournalStore, {
  MaintenanceTicketJournalStore,
  MaintenanceTicketJournalState,
} from './maintenance-journal.store';

export class MaintenanceTicketJournalQuery extends QueryEntity<MaintenanceTicketJournalState> {
  constructor(protected store: MaintenanceTicketJournalStore) {
    super(store);
  }

  journals = this.selectAll({ sortBy: 'timestamp', sortByOrder: Order.DESC });
}

const maintenanceTicketJournalQuery: MaintenanceTicketJournalQuery = new MaintenanceTicketJournalQuery(
  maintenanceTicketJournalStore
);
export default maintenanceTicketJournalQuery;
