import { EntityState, ActiveState, EntityStore, StoreConfig } from '@datorama/akita';

import { Room } from './rooms.model';
import { RoomType, VipStatus } from '..';

export interface RoomsFilters {
  roomNumber: string;
  zone: string;
  roomTypeId: RoomType['id'];
  roomStatuses: Array<string>;
  houseKeepingStatuses: Array<string>;
  vipStatus?: VipStatus;
}

export interface RoomsState extends EntityState<Room, Room['id']>, ActiveState<Room['id']> {
  ui: RoomsFilters;
}

export const initialFilters = {
  roomNumber: '',
  zone: '',
  roomTypeId: '',
  roomStatuses: [],
  houseKeepingStatuses: [],
};

@StoreConfig({ name: 'rooms', resettable: true })
export class RoomsStore extends EntityStore<RoomsState> {
  constructor() {
    super({ ui: initialFilters });
  }
}

export const roomsStore = new RoomsStore();
