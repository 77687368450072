import { setLoading } from '@datorama/akita';
import isEqual from 'lodash.isequal';
import { from, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Reservations, GuestLookupFields, GuestLookupProfile } from '..';
import { guestLookupStore, GuestLookupStore } from './guest-lookup.store';

export class GuestLookupService {
  private readonly fields = new Subject<GuestLookupFields>();

  constructor(
    private readonly store: GuestLookupStore,
    private readonly guestsApi: Reservations.GuestsApi,
    private readonly corporateApi: Reservations.CorporateAccountApi
  ) {
    this.fields
      .pipe(distinctUntilChanged(isEqual), debounceTime(500))
      .subscribe(fields => !!fields && this.fetchMatches(fields));
  }

  private fetchMatches(fields: GuestLookupFields) {
    const requests: Array<Observable<GuestLookupProfile[]>> = [];
    if (
      fields.hasEmployeeRole &&
      [fields.name?.first, fields.name?.last, fields.contact?.email, fields.contact?.phone].some(
        s => s
      )
    )
      requests.push(
        from(
          this.guestsApi.guestsSearchGet(
            fields.name?.first ?? undefined,
            fields.name?.last ?? undefined,
            fields.contact?.email ?? undefined,
            fields.contact?.phone ?? undefined,
            undefined,
            false,
            undefined,
            10 // limit
          )
        ).pipe(map(({ data }) => data.data.map(g => ({ key: `user:${g.userId}`, ...g }))))
      );

    if (fields.corporateAccountId && fields.employeeNumber) {
      requests.push(
        from(
          this.corporateApi.corporateaccountIdEmployeesLookupGet(
            fields.corporateAccountId,
            fields.employeeNumber
          )
        ).pipe(
          map(({ data }) =>
            data.data.map(({ userId, ...e }) => ({
              key: userId ? `user:${userId}` : `emp:${e.corporateAccountId}:${e.employeeNumber}`,
              userId: userId ?? '',
              address: {},
              sId: '',
              ...e,
            }))
          )
        )
      );
    }

    merge(...requests)
      .pipe(setLoading(this.store))
      .subscribe(data => this.store.upsertMany(data));
  }

  getMatches(fields: GuestLookupFields) {
    if (!fields) return;

    const { contact, name, corporateAccountId, employeeNumber } = fields;

    this.store.update({ ui: fields });
    this.fields.next({
      contact,
      name,
      corporateAccountId,
      employeeNumber,
      hasEmployeeRole: fields.hasEmployeeRole,
    });
  }

  reset() {
    this.store.reset();

    this.fields.next();
  }
}

export const guestLookupService = new GuestLookupService(
  guestLookupStore,
  new Reservations.GuestsApi(),
  new Reservations.CorporateAccountApi()
);
