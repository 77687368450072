import { Store, StoreConfig } from '@datorama/akita';

import { GuestProfile } from './guest-profile.model';

export interface GuestProfileState {
  guest: GuestProfile | null;
  hasAccount: boolean | null;
  isDoNotDisturbOn: boolean | null;
}

@StoreConfig({ name: 'guest-profile', resettable: true })
export class GuestProfileStore extends Store<GuestProfileState> {
  constructor() {
    super({ guest: null, hasAccount: null, isDoNotDisturbOn: null });
  }
}

export const guestProfileStore = new GuestProfileStore();
