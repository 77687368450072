import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

interface Props extends Omit<ButtonProps, 'disabled'> {
  disabled?: boolean | ((errors: Record<string, string>) => boolean);
}

export const FormSubmitButton: React.FC<Props> = ({
  disabled = errors => Object.keys(errors).length !== 0,
  ...props
}) => {
  const { errors } = useFormContext();

  const isDisabled = (errors: Record<string, string>) =>
    typeof disabled === 'function' ? disabled(errors) : disabled;

  return <Button type="submit" disabled={isDisabled(errors)} {...props} />;
};
