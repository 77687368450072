// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { AddPaymentMethodRequest } from '../models';
import { DeletePaymentMethodRequest } from '../models';
import { GetGuestProfileItemResponse } from '../models';
import { GetPaymentMethodItemResponse } from '../models';
import { GetPaymentMethodsListResponse } from '../models';
import { ProblemDetails } from '../models';
import { UpdateGuestProfileRequest } from '../models';
import { UpdateGuestProfileResponse } from '../models';
import { UpdatePaymentMethodRequest } from '../models';
/**
 * GuestApi - axios parameter creator
 * @export
 */
export const GuestApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} paymentProfileId
     * @param {DeletePaymentMethodRequest} [deletePaymentMethodRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestPaymentmethodPaymentProfileIdDelete(
      paymentProfileId: string,
      deletePaymentMethodRequest?: DeletePaymentMethodRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'paymentProfileId' is not null or undefined
      if (paymentProfileId === null || paymentProfileId === undefined) {
        throw new RequiredError(
          'paymentProfileId',
          'Required parameter paymentProfileId was null or undefined when calling guestPaymentmethodPaymentProfileIdDelete.'
        );
      }
      const localVarPath = `/guest/paymentmethod/{paymentProfileId}`.replace(
        `{${'paymentProfileId'}}`,
        encodeURIComponent(String(paymentProfileId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof deletePaymentMethodRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(deletePaymentMethodRequest !== undefined ? deletePaymentMethodRequest : {})
        : deletePaymentMethodRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} paymentProfileId
     * @param {UpdatePaymentMethodRequest} [updatePaymentMethodRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestPaymentmethodPaymentProfileIdPost(
      paymentProfileId: string,
      updatePaymentMethodRequest?: UpdatePaymentMethodRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'paymentProfileId' is not null or undefined
      if (paymentProfileId === null || paymentProfileId === undefined) {
        throw new RequiredError(
          'paymentProfileId',
          'Required parameter paymentProfileId was null or undefined when calling guestPaymentmethodPaymentProfileIdPost.'
        );
      }
      const localVarPath = `/guest/paymentmethod/{paymentProfileId}`.replace(
        `{${'paymentProfileId'}}`,
        encodeURIComponent(String(paymentProfileId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updatePaymentMethodRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updatePaymentMethodRequest !== undefined ? updatePaymentMethodRequest : {})
        : updatePaymentMethodRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AddPaymentMethodRequest} [addPaymentMethodRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestPaymentmethodPost(
      addPaymentMethodRequest?: AddPaymentMethodRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/guest/paymentmethod`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof addPaymentMethodRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(addPaymentMethodRequest !== undefined ? addPaymentMethodRequest : {})
        : addPaymentMethodRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {string} [corporateAccountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestPaymentmethodsGet(
      propertyId?: string,
      corporateAccountId?: string,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/guest/paymentmethods`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (corporateAccountId !== undefined) {
        localVarQueryParameter['corporateAccountId'] = corporateAccountId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestProfileGet(options: any = {}): RequestArgs {
      const localVarPath = `/guest/profile`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateGuestProfileRequest} [updateGuestProfileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestProfilePost(
      updateGuestProfileRequest?: UpdateGuestProfileRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/guest/profile`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateGuestProfileRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateGuestProfileRequest !== undefined ? updateGuestProfileRequest : {})
        : updateGuestProfileRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GuestApi - functional programming interface
 * @export
 */
export const GuestApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} paymentProfileId
     * @param {DeletePaymentMethodRequest} [deletePaymentMethodRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestPaymentmethodPaymentProfileIdDelete(
      paymentProfileId: string,
      deletePaymentMethodRequest?: DeletePaymentMethodRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = GuestApiAxiosParamCreator(
        configuration
      ).guestPaymentmethodPaymentProfileIdDelete(
        paymentProfileId,
        deletePaymentMethodRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} paymentProfileId
     * @param {UpdatePaymentMethodRequest} [updatePaymentMethodRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestPaymentmethodPaymentProfileIdPost(
      paymentProfileId: string,
      updatePaymentMethodRequest?: UpdatePaymentMethodRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaymentMethodItemResponse> {
      const localVarAxiosArgs = GuestApiAxiosParamCreator(
        configuration
      ).guestPaymentmethodPaymentProfileIdPost(
        paymentProfileId,
        updatePaymentMethodRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {AddPaymentMethodRequest} [addPaymentMethodRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestPaymentmethodPost(
      addPaymentMethodRequest?: AddPaymentMethodRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaymentMethodItemResponse> {
      const localVarAxiosArgs = GuestApiAxiosParamCreator(configuration).guestPaymentmethodPost(
        addPaymentMethodRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {string} [corporateAccountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestPaymentmethodsGet(
      propertyId?: string,
      corporateAccountId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaymentMethodsListResponse> {
      const localVarAxiosArgs = GuestApiAxiosParamCreator(configuration).guestPaymentmethodsGet(
        propertyId,
        corporateAccountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestProfileGet(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestProfileItemResponse> {
      const localVarAxiosArgs = GuestApiAxiosParamCreator(configuration).guestProfileGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {UpdateGuestProfileRequest} [updateGuestProfileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestProfilePost(
      updateGuestProfileRequest?: UpdateGuestProfileRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateGuestProfileResponse> {
      const localVarAxiosArgs = GuestApiAxiosParamCreator(configuration).guestProfilePost(
        updateGuestProfileRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GuestApi - factory interface
 * @export
 */
export const GuestApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} paymentProfileId
     * @param {DeletePaymentMethodRequest} [deletePaymentMethodRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestPaymentmethodPaymentProfileIdDelete(
      paymentProfileId: string,
      deletePaymentMethodRequest?: DeletePaymentMethodRequest,
      options?: any
    ): AxiosPromise<void> {
      return GuestApiFp(configuration).guestPaymentmethodPaymentProfileIdDelete(
        paymentProfileId,
        deletePaymentMethodRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} paymentProfileId
     * @param {UpdatePaymentMethodRequest} [updatePaymentMethodRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestPaymentmethodPaymentProfileIdPost(
      paymentProfileId: string,
      updatePaymentMethodRequest?: UpdatePaymentMethodRequest,
      options?: any
    ): AxiosPromise<GetPaymentMethodItemResponse> {
      return GuestApiFp(configuration).guestPaymentmethodPaymentProfileIdPost(
        paymentProfileId,
        updatePaymentMethodRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {AddPaymentMethodRequest} [addPaymentMethodRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestPaymentmethodPost(
      addPaymentMethodRequest?: AddPaymentMethodRequest,
      options?: any
    ): AxiosPromise<GetPaymentMethodItemResponse> {
      return GuestApiFp(configuration).guestPaymentmethodPost(addPaymentMethodRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} [propertyId]
     * @param {string} [corporateAccountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestPaymentmethodsGet(
      propertyId?: string,
      corporateAccountId?: string,
      options?: any
    ): AxiosPromise<GetPaymentMethodsListResponse> {
      return GuestApiFp(configuration).guestPaymentmethodsGet(
        propertyId,
        corporateAccountId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestProfileGet(options?: any): AxiosPromise<GetGuestProfileItemResponse> {
      return GuestApiFp(configuration).guestProfileGet(options)(axios, basePath);
    },
    /**
     *
     * @param {UpdateGuestProfileRequest} [updateGuestProfileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestProfilePost(
      updateGuestProfileRequest?: UpdateGuestProfileRequest,
      options?: any
    ): AxiosPromise<UpdateGuestProfileResponse> {
      return GuestApiFp(configuration).guestProfilePost(updateGuestProfileRequest, options)(
        axios,
        basePath
      );
    },
  };
};

/**
 * GuestApi - object-oriented interface
 * @export
 * @class GuestApi
 * @extends {BaseAPI}
 */
export class GuestApi extends BaseAPI {
  /**
   *
   * @param {string} paymentProfileId
   * @param {DeletePaymentMethodRequest} [deletePaymentMethodRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestApi
   */
  public guestPaymentmethodPaymentProfileIdDelete(
    paymentProfileId: string,
    deletePaymentMethodRequest?: DeletePaymentMethodRequest,
    options?: any
  ) {
    return GuestApiFp(this.configuration).guestPaymentmethodPaymentProfileIdDelete(
      paymentProfileId,
      deletePaymentMethodRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} paymentProfileId
   * @param {UpdatePaymentMethodRequest} [updatePaymentMethodRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestApi
   */
  public guestPaymentmethodPaymentProfileIdPost(
    paymentProfileId: string,
    updatePaymentMethodRequest?: UpdatePaymentMethodRequest,
    options?: any
  ) {
    return GuestApiFp(this.configuration).guestPaymentmethodPaymentProfileIdPost(
      paymentProfileId,
      updatePaymentMethodRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {AddPaymentMethodRequest} [addPaymentMethodRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestApi
   */
  public guestPaymentmethodPost(addPaymentMethodRequest?: AddPaymentMethodRequest, options?: any) {
    return GuestApiFp(this.configuration).guestPaymentmethodPost(addPaymentMethodRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} [propertyId]
   * @param {string} [corporateAccountId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestApi
   */
  public guestPaymentmethodsGet(propertyId?: string, corporateAccountId?: string, options?: any) {
    return GuestApiFp(this.configuration).guestPaymentmethodsGet(
      propertyId,
      corporateAccountId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestApi
   */
  public guestProfileGet(options?: any) {
    return GuestApiFp(this.configuration).guestProfileGet(options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {UpdateGuestProfileRequest} [updateGuestProfileRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestApi
   */
  public guestProfilePost(updateGuestProfileRequest?: UpdateGuestProfileRequest, options?: any) {
    return GuestApiFp(this.configuration).guestProfilePost(updateGuestProfileRequest, options)(
      this.axios,
      this.basePath
    );
  }
}
