import React, { useEffect, useMemo } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Grid, MenuItem } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDays, differenceInDays, startOfDay, startOfToday } from 'date-fns';

import {
  HousekeepingAssignment,
  Housekeeper,
  RoomZone,
  RoomAssignmentTypeEnum,
  HousekeepingStatusMap,
  SleepSchedule,
} from '@lib/state';
import { EnumValueDisplay, FormSelect, FormTextField, NameDisplay } from '@lib/common';
import { FilterSection } from 'app/shared';
import { PrintableHousekeeperReportButton } from './printable-housekeeper-report-button';

export interface HousekeepingFilter {
  date?: Date;
  housekeeperId?: string;
  zoneId?: string;
  roomNumber?: string;
  roomAssignmentType?: RoomAssignmentTypeEnum;
  housekeepingStatus?: string;
  sleepSchedule?: SleepSchedule;
  servicePerformed?: string;
}

interface Props {
  filter: HousekeepingFilter;
  rooms: HousekeepingAssignment[];
  housekeepers: Housekeeper[];
  zones: RoomZone[];
  onFilterChange: (filters: HousekeepingFilter) => void;
}

const typesToFilter: RoomAssignmentTypeEnum[] = [
  RoomAssignmentTypeEnum.UnAssigned,
  RoomAssignmentTypeEnum.Assigned,
  RoomAssignmentTypeEnum.Completed,
  RoomAssignmentTypeEnum.NotCompleted,
];

const statusesToFilter = [...Array.from(HousekeepingStatusMap.values())]
  .filter(f => f !== 'No Cleaning Required')
  .sort();

export const RoomAssignmentsFilter: React.FC<Props> = ({
  filter,
  rooms,
  housekeepers,
  zones,
  onFilterChange,
}) => {
  const form = useForm<HousekeepingFilter>({
    defaultValues: {
      date: startOfToday(),
      housekeeperId: undefined,
      zoneId: undefined,
      roomNumber: undefined,
      roomAssignmentType: undefined,
      housekeepingStatus: undefined,
      sleepSchedule: undefined,
      servicePerformed: undefined,
    },
  });
  const { register, watch, setValue, errors } = form;

  useEffect(() => {
    register({ name: 'date' });
    register({ name: 'housekeeperId' });
    register({ name: 'zoneId' });
    register({ name: 'roomAssignmentType' });
    register({ name: 'sleepSchedule' });
    register({ name: 'servicePerformed' });
  }, [register]);
  const {
    date,
    housekeeperId,
    zoneId,
    roomNumber,
    roomAssignmentType,
    housekeepingStatus,
    sleepSchedule,
    servicePerformed,
  } = watch();

  useEffect(() => {
    onFilterChange({
      date,
      housekeeperId,
      roomNumber,
      zoneId,
      roomAssignmentType,
      housekeepingStatus,
      sleepSchedule,
      servicePerformed,
    });
  }, [
    date,
    housekeeperId,
    zoneId,
    roomNumber,
    roomAssignmentType,
    housekeepingStatus,
    sleepSchedule,
    servicePerformed,
    onFilterChange,
  ]);

  const datePickerMax = useMemo(() => {
    return addDays(startOfDay(new Date()), 2);
  }, []);

  const isFutureDate = useMemo(() => {
    if (!!date) return differenceInDays(startOfDay(new Date()), date) < 0;
  }, [date]);

  return (
    <FilterSection>
      <FormContext {...form}>
        <Grid container spacing={1}>
          <Grid item xs={2} lg={1}>
            <DatePicker
              name="date"
              label="Date"
              format="MMM d"
              value={date ?? filter.date}
              error={!!errors.date}
              onChange={value => setValue('date', value ? startOfDay(value) : startOfToday())}
              color="primary"
              InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
              maxDate={datePickerMax}
              fullWidth
              clearable
              autoOk
            />
          </Grid>
          <Grid item xs={3} lg={2}>
            <FormSelect
              name="housekeeperId"
              label="Housekeepers"
              defaultValue={filter.housekeeperId ?? ''}
              fullWidth
            >
              <MenuItem>None</MenuItem>
              {housekeepers.map(x => (
                <MenuItem key={x.userId} value={x.userId}>
                  <NameDisplay {...x.name} directory disableTypography />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item md={2} lg={1}>
            <FormSelect name="zoneId" label="Zone" defaultValue={filter.zoneId ?? ''} fullWidth>
              <MenuItem>None</MenuItem>
              {zones.map(x => (
                <MenuItem key={x.id} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item md={2} lg={1}>
            <FormTextField
              type="text"
              label="Room #"
              defaultValue={filter.roomNumber}
              name="roomNumber"
              fullWidth
            />
          </Grid>
          <Grid item md={2} lg={1}>
            <FormSelect
              name="roomAssignmentType"
              label="Status"
              defaultValue={filter.roomAssignmentType ?? ''}
              fullWidth
            >
              <MenuItem>All</MenuItem>
              {typesToFilter.map(typeToFilter => (
                <MenuItem key={typeToFilter} value={typeToFilter}>
                  {typeToFilter}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item md={3} lg={2}>
            <FormSelect
              name="housekeepingStatus"
              label="Clean Requested"
              defaultValue={filter.housekeepingStatus ?? ''}
              fullWidth
            >
              <MenuItem>All</MenuItem>
              <MenuItem key="TouchUpClean" value="TouchUpClean">
                {'Touch Up Clean'}
              </MenuItem>
              <MenuItem key="FullClean" value="FullClean">
                {'Full Clean'}
              </MenuItem>
              <MenuItem key="Checkout" value="Checkout">
                {'Checkout'}
              </MenuItem>
              <MenuItem key="UpcomingCheckout" value="UpcomingCheckout">
                {'Upcoming Checkout'}
              </MenuItem>
            </FormSelect>
          </Grid>
          <Grid item md={2} lg={1}>
            <FormSelect
              name="sleepSchedule"
              label="Sleep Schedule"
              defaultValue={filter.sleepSchedule ?? ''}
              fullWidth
            >
              <MenuItem>All</MenuItem>
              {Object.values(SleepSchedule).map(ss => (
                <MenuItem key={ss} value={ss}>
                  <EnumValueDisplay value={ss} />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          {!!date && !isFutureDate && (
            <>
              <Grid item md={3} lg={2}>
                <FormSelect
                  name="servicePerformed"
                  label="Clean Performed"
                  defaultValue={filter.housekeepingStatus ?? ''}
                  fullWidth
                >
                  <MenuItem>All</MenuItem>
                  <MenuItem key={'ComeBackLater'} value={'ComeBackLater'}>
                    Come Back Later
                  </MenuItem>
                  <MenuItem key={'NoCleaningRequired'} value={'NoCleaningRequired'}>
                    Come Back Tomorrow
                  </MenuItem>
                  {statusesToFilter.map(status => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </FormSelect>
              </Grid>
              <Grid item>
                <PrintableHousekeeperReportButton
                  rooms={rooms}
                  housekeepers={housekeepers}
                  date={date}
                />
              </Grid>
            </>
          )}
        </Grid>
      </FormContext>
    </FilterSection>
  );
};
