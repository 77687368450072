import React from 'react';

import { Link, Section, ShowMoreButton, ShowMoreButtonProps, DataTable } from '@lib/common';
import { Area } from 'app/state';
import { Typography } from '@material-ui/core';

interface Props extends ShowMoreButtonProps {
  areas: Array<Area>;
}

export const AreaListResults: React.FC<Props> = ({ areas, ...loadMoreProps }) => {
  return (
    <Section title="Results" textAlign="left" disableGutters>
      <DataTable
        items={areas}
        getItemKey={x => x.id}
        columns={[
          {
            title: 'Name',
            valueFactory: x => (
              <Link to={`/areas/${x.id}`}>
                <Typography>{x.name}</Typography>
              </Link>
            ),
          },
        ]}
      >
        <ShowMoreButton {...loadMoreProps} />
      </DataTable>
    </Section>
  );
};
