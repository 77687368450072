import React, { useCallback } from 'react';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Typography, Container, Box } from '@material-ui/core';

import { useObservable, usePageTitle } from '@lib/common';
import { EnrollEmployeeForm } from 'app/user-management';
import { sessionQuery } from '@lib/state';

export const EmployeeEnrollmentPage: React.FC = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const employeeInviteId = queryParams.get('employeeInviteId');

  usePageTitle('Employee Enrollment');
  const onSubmitComplete = useCallback(() => history.push('/tape-chart'), [history]);
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');
  const user = useObservable(sessionQuery.currentUser, 'async');
  const employeeRoles = useObservable(sessionQuery.employeeRoles);
  const existingEmployee = !!employeeRoles && employeeRoles.length > 0;

  const signInBaseUrl = '/account/signin';
  if (employeeInviteId === null) {
    return <Redirect to={signInBaseUrl} />;
  }

  const signInLink = `${signInBaseUrl}?employeeInviteId=${employeeInviteId}`;
  const signOutLink = `/account/signout?returnUrl=${signInLink}`;

  if (isLoggedIn === undefined) return null;
  if (isLoggedIn === false) {
    return <Redirect to={signInLink} />;
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="h5" align="center" color="textSecondary" paragraph>
        Employee Enrollment
      </Typography>
      <Typography align="center" color="textSecondary" paragraph>
        {existingEmployee ? 'Please click the enroll button' : 'Please fill out the form'} below to
        complete Employee Enrollment. Upon completion you will be logged in.
      </Typography>
      <EnrollEmployeeForm existingEmployee={existingEmployee} onSubmitComplete={onSubmitComplete} />
      <Box mt={2}>
        <Typography align="center" color="textSecondary" paragraph>
          You are currently enrolling as {user?.email} <br />
          Not you? <Link to={signOutLink}>Click here.</Link>
        </Typography>
      </Box>
    </Container>
  );
};
