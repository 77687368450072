import React from 'react';
import { Typography } from '@material-ui/core';
import { FailedReservation } from '@lib/state';
import { NameDisplay } from '../components';
import { ErrorDisplay } from '../forms';

interface Props {
  failures?: FailedReservation[];
}

export const ReservationFailures: React.FC<Props> = ({ failures }) => {
  if (!failures || failures.length === 0) return null;

  return (
    <>
      <Typography variant="body2">
        <strong>Booking failed for the following guests.</strong>
      </Typography>
      {failures.map(({ guest, error }, i) => (
        <React.Fragment key={`failure_${i}`}>
          <ErrorDisplay error={error ?? undefined}>
            <NameDisplay {...guest?.name} />
          </ErrorDisplay>
        </React.Fragment>
      ))}
    </>
  );
};
