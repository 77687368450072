import React from 'react';
import { Box, makeStyles, createStyles, Typography, Grid, Paper, Divider } from '@material-ui/core';
import { parseISO } from 'date-fns';

import { ActivityReport, PropertyPolicy, Reservation } from '@lib/state';
import {
  AddressDisplay,
  DateDisplay,
  CurrencyDisplay,
  EmailDisplay,
  NameDisplay,
  PhoneDisplay,
  CorporateAffiliationDisplay,
  GuestRebookButton,
} from '../../components';
import { CostCenterFieldEditableDisplay } from '../../reservations/forms/cost-center-field-editable-display';
import { BillingReferenceEditableDisplay } from '../../reservations/forms/billing-reference-editable-display';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      border: ({ highlight }: StyleProps) =>
        highlight ? `2px solid ${theme.palette.secondary.main}` : undefined,
      padding: ({ highlight }: StyleProps) => (highlight ? '0.5rem' : 'calc(0.5rem + 2px)'),
    },
    dense: {
      lineHeight: 1,
    },
    button: {
      marginTop: theme.spacing(1),
      '&:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
  })
);

interface StyleProps {
  highlight?: boolean;
  divider?: boolean;
}

interface Props extends StyleProps {
  activity: ActivityReport;
  showGuest?: boolean;
  showActions?: boolean;
  reservation?: Reservation;
  createForGuest: boolean;
}

export const ActivityDisplay: React.FC<Props> = ({
  activity,
  showGuest,
  highlight,
  divider,
  children,
  showActions = false,
  reservation,
  createForGuest,
}) => {
  const styles = useStyles({ highlight });

  return (
    <>
      <Paper
        className={styles.paper}
        elevation={highlight ? 3 : 0}
        square
        data-testid={`pastActivitiesItem:${activity.recordNumber}`}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AddressDisplay
              variant="subtitle1"
              addressVariant="cityState"
              city={activity.city}
              state={activity.state}
            />
            <Typography className={styles.dense} variant="body2">
              {activity.hotel}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={6}>
            <Typography className={styles.dense} variant="overline">
              <strong>Conf Code</strong>
            </Typography>
            <Typography className={styles.dense} variant="body2">
              {activity.recordNumber}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Typography className={styles.dense} variant="overline">
              <strong>Transaction Id</strong>
            </Typography>
            <Typography className={styles.dense} variant="body2">
              {activity.transactionId}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Typography className={styles.dense} variant="overline">
              <strong>Payment Method</strong>
            </Typography>
            <Typography className={styles.dense} variant="body2">
              {activity.paymentMethod}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={6}>
            <Typography className={styles.dense} variant="overline">
              <strong>Date</strong>
            </Typography>
            <DateDisplay
              className={styles.dense}
              variant="body2"
              date={parseISO(activity.timestamp)}
              dateFormat="MM/dd/yyyy"
            />
          </Grid>
          <Grid item sm={2} xs={6}>
            <Typography className={styles.dense} variant="overline">
              <strong>Amount</strong>
            </Typography>
            <CurrencyDisplay className={styles.dense} value={activity.amount} variant="body2" />
          </Grid>
          {showGuest && activity.guest && (
            <>
              <Grid item sm={2} xs={6}>
                <Typography className={styles.dense} variant="overline">
                  <strong>Guest</strong>
                </Typography>
                <NameDisplay {...activity.guest.name} className={styles.dense} variant="body2" />
              </Grid>
              <Grid item sm={3} xs={6}>
                <Typography className={styles.dense} variant="overline">
                  <strong>Email</strong>
                </Typography>
                <EmailDisplay
                  email={activity.guest.contact.email}
                  alternateEmail={activity.guest.contact.alternateEmail}
                  className={styles.dense}
                  variant="body2"
                  display="block"
                />
              </Grid>
              <Grid item sm={3} xs={6}>
                <Typography className={styles.dense} variant="overline">
                  <strong>Phone</strong>
                </Typography>
                <PhoneDisplay
                  phone={activity.guest.contact.phone}
                  className={styles.dense}
                  variant="body2"
                  display="block"
                />
              </Grid>
            </>
          )}
          {!!activity.affiliation && (
            <Grid item sm={2} xs={6}>
              <Typography className={styles.dense} variant="overline">
                <strong>Company</strong>
              </Typography>
              <CorporateAffiliationDisplay
                className={styles.dense}
                variant="body2"
                {...activity.affiliation}
              />
            </Grid>
          )}

          {activity.createdByUser && activity.createdByUserType !== 'System' && (
            <Grid item sm={2} xs={6}>
              <Typography className={styles.dense} variant="overline">
                <strong>Created By</strong>
              </Typography>
              <NameDisplay
                {...activity.createdByUser.name}
                className={styles.dense}
                variant="body2"
                abbreviated
              >
                {' '}
                ({activity.createdByUserType})
              </NameDisplay>
            </Grid>
          )}
          {activity.reservationId &&
            activity.fields &&
            activity.fields.map(field => (
              <Grid key={field.key} item sm={2} xs={6}>
                <CostCenterFieldEditableDisplay
                  reservation={{ id: activity.reservationId! }}
                  costCenterField={field}
                  dense
                  labelVariant="overline"
                  valueVariant="body2"
                />
              </Grid>
            ))}
          {activity.reservationId && activity.billingReference && (
            <Grid item sm={2} xs={6}>
              <BillingReferenceEditableDisplay
                reservation={{
                  id: activity.reservationId,
                  billingReference: activity.billingReference,
                }}
                dense
                labelVariant="overline"
                valueVariant="body2"
              />
            </Grid>
          )}
          <Grid container spacing={1} xs={12}>
            <Grid item xs={10}>
              <Typography className={styles.dense} variant="overline">
                <strong>Description</strong>
              </Typography>
              <Typography variant="body2">{activity.description}</Typography>
            </Grid>
            {showActions && reservation && (
              <Grid item xs={2} style={{ textAlign: 'center', margin: 'auto' }}>
                <GuestRebookButton
                  className={styles.button}
                  reservation={reservation}
                  color="secondary"
                  variant="contained"
                  createForGuest={createForGuest}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {children}
      </Paper>
      {divider && (
        <Box mt={0.5} mb={1}>
          <Divider />
        </Box>
      )}
    </>
  );
};
