import React, { ReactNode } from 'react';
import { Controller, useFormContext, ValidationOptions } from 'react-hook-form';
import { TextField } from '@material-ui/core';

import { getHelperText, useFieldError } from './form-methods';
import { TextFieldProps } from './form-text-field';

export interface FormNumberFieldProps {
  name: string;
  validationOptions?: ValidationOptions;
  helperText?: ReactNode | ((errors: any) => string);
  integer?: boolean;
  radix?: number;
}

export const FormNumberField: React.FC<
  FormNumberFieldProps & TextFieldProps<'onChange' | 'onBlur' | 'onFocus'>
> = ({ name, helperText, validationOptions, integer, radix, label, error, ...props }) => {
  const { errors } = useFormContext();

  const fieldError = useFieldError(name, errors);
  const text = getHelperText(errors, helperText ?? fieldError?.message);

  return (
    <Controller
      name={name}
      as={TextField}
      label={props.InputLabelProps?.prefix ? `${props.InputLabelProps.prefix} ${label}` : label}
      inputProps={{ inputMode: 'numeric' }}
      onChange={([e]) => {
        const value = integer ? parseInt(e.target.value, radix) : parseFloat(e.target.value);
        return isNaN(value) ? '' : value;
      }}
      rules={validationOptions}
      type="number"
      error={error || !!fieldError}
      helperText={text}
      {...props}
    />
  );
};
