import React from 'react';
import { Box, BoxProps, Typography } from '@material-ui/core';

import { Feedback } from './feedback';
import { ValidationError } from '@lib/state';

interface Props {
  error?: Error;
  onCancel?: () => void;
  boxProps?: BoxProps;
}

export const ErrorDisplay: React.FC<Props> = ({
  error,
  onCancel,
  children,
  boxProps = { marginBottom: 2 },
}) => {
  if (!error) return null;
  return (
    <Box {...boxProps} clone>
      <Feedback show severity="error" onHide={onCancel}>
        {children}
        {error instanceof ValidationError ? (
          error.errors.map((fieldError, key) => (
            <Typography key={key}>{`${fieldError.field}: ${fieldError.error}`}</Typography>
          ))
        ) : (
          <Typography data-testid="errorDisplay">{error.message}</Typography>
        )}
      </Feedback>
    </Box>
  );
};
