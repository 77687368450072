import {
  NavMenuItemSection,
  Manager,
  Admin,
  Accounting,
  FrontDesk,
  HousekeepingSupervisor,
  Maintenance,
} from '@lib/state';

const rolesAll: string[] = [];
const rolesAdmin: string[] = [Admin];
const rolesAdminManagerFrontDesk: string[] = [Admin, Manager, FrontDesk];
const rolesAdminManagerFrontDeskHousekeepingSupervisorMaintenance: string[] = [
  Admin,
  Manager,
  FrontDesk,
  HousekeepingSupervisor,
  Maintenance,
];

const rolesManager: string[] = [Manager];

export function getMenuSections({
  isKiosk,
  guestPortalUrl,
}: {
  isKiosk?: boolean;
  guestPortalUrl?: string | null;
}): NavMenuItemSection[] {
  return [
    {
      title: 'Rooms',
      items: [
        {
          title: 'Tape Chart',
          icon: 'calendar-week',
          url: '/tape-chart',
          accessibleUserRoles: rolesAdminManagerFrontDeskHousekeepingSupervisorMaintenance,
          testId: 'menuTapeChartButton',
        },
        {
          title: 'Reservations',
          icon: 'calendar-check',
          url: '/reservations',
          accessibleUserRoles: rolesAdminManagerFrontDesk,
          testId: 'menuReservationsButton',
        },
        {
          title: 'Occupancy',
          icon: 'file-invoice-dollar',
          url: '/reports/occupancy',
          accessibleUserRoles: rolesAdminManagerFrontDesk,
        },
        {
          title: 'In-House Guests',
          icon: 'users',
          url: '/reports/in-house-guests',
          accessibleUserRoles: rolesAdminManagerFrontDesk,
        },
        {
          title: 'Search Guests',
          icon: 'bed',
          url: '/guests',
          accessibleUserRoles: rolesAdminManagerFrontDesk,
        },
        {
          title: 'Inventory Adjustments',
          icon: 'search',
          url: '/inventory-adjustments',
          accessibleUserRoles: rolesAdminManagerFrontDesk,
        },
      ],
    },
    {
      title: 'Housekeeping',
      items: [
        {
          title: 'Assignments',
          icon: 'vacuum',
          url: '/housekeeping/assignments',
          accessibleUserRoles: rolesAdminManagerFrontDeskHousekeepingSupervisorMaintenance,
        },
        {
          title: 'My Assignments',
          icon: 'vacuum',
          url: '/housekeeping/myAssignments',
          accessibleUserRoles: rolesAdminManagerFrontDeskHousekeepingSupervisorMaintenance,
        },
        {
          title: 'Performance Report',
          icon: 'file-spreadsheet',
          url: '/housekeeping/report',
          accessibleUserRoles: rolesAdminManagerFrontDeskHousekeepingSupervisorMaintenance,
        },
        {
          title: 'Maintenance Tickets',
          icon: 'receipt',
          url: '/maintenance',
          accessibleUserRoles: rolesAdminManagerFrontDeskHousekeepingSupervisorMaintenance,
        },
      ],
    },
    {
      title: 'Accounting',
      items: [
        {
          title: 'Ledger',
          icon: 'file-invoice-dollar',
          url: '/ledger',
          accessibleUserRoles: [Accounting],
        },
        {
          title: 'Purchase Report',
          icon: 'cash-register',
          url: '/reports/purchases',
          accessibleUserRoles: [Admin, Accounting],
        },
      ],
    },
    {
      title: 'Setup',
      items: [
        {
          title: 'Property',
          icon: 'cogs',
          url: '/property/configure',
          accessibleUserRoles: rolesAdmin,
        },
        {
          title: 'Rooms',
          icon: 'cogs',
          url: '/property/rooms',
          accessibleUserRoles: [Admin, Manager],
        },
        {
          title: 'Employees',
          icon: 'users',
          url: '/user-management',
          accessibleUserRoles: rolesAdmin,
        },
        {
          title: 'Room Types',
          icon: 'bed',
          url: '/room-types',
          accessibleUserRoles: rolesAdmin,
        },
        {
          title: 'Room Rates',
          icon: 'receipt',
          url: '/rates',
          accessibleUserRoles: rolesAdmin,
          testId: 'menuRoomRatesButton',
        },
        {
          title: 'Common Areas',
          icon: 'fireplace',
          url: '/areas',
          accessibleUserRoles: rolesAdmin,
        },
        {
          title: 'Property QR Code',
          icon: ['far', 'qrcode'],
          url: '/property/qr-code',
          accessibleUserRoles: rolesAdmin,
        },
        {
          title: 'Device QR Code',
          icon: ['far', 'qrcode'],
          url: '/enrollment/device-qr-code',
          accessibleUserRoles: rolesAdmin,
        },
        {
          title: 'Corporate Accounts',
          icon: 'building',
          url: '/corporate-accounts',
          accessibleUserRoles: rolesAdmin,
          testId: 'menuCorporateAccountsButton',
        },
        {
          title: 'QR Code Stickers',
          icon: ['far', 'qrcode'],
          url: '/qrcodes',
          accessibleUserRoles: [Admin, Manager],
        },
      ],
    },
    {
      title: 'Account',
      items: [
        ...(!isKiosk && guestPortalUrl
          ? [
              {
                title: 'Website',
                icon: 'hotel',
                url: '',
                href: guestPortalUrl,
                target: '_blank',
                accessibleUserRoles: rolesAll,
              } as const,
            ]
          : []),
        {
          title: 'Manager Code',
          icon: ['fas', 'key'],
          url: '/property/manager-code',
          accessibleUserRoles: rolesManager,
        },
        {
          title: 'Signout',
          icon: 'sign-out',
          url: '/account/signout',
          accessibleUserRoles: rolesAll,
          testId: 'menuSignoutButton',
        },
      ],
    },
  ];
}
