// tslint:disable
/**
 * Property Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CreateGuestNoteRequest } from '../models';
import { CreateGuestNoteResponse } from '../models';
import { DeleteGuestNoteItemResponse } from '../models';
import { GetGuestNoteItemResponse } from '../models';
import { GetGuestNotesResponse } from '../models';
import { ProblemDetails } from '../models';
import { UpdateGuestNoteRequest } from '../models';
import { UpdateGuestNoteResponse } from '../models';
/**
 * GuestNotesApi - axios parameter creator
 * @export
 */
export const GuestNotesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [userId]
     * @param {string} [guestStayId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestnotesGet(
      propertyId: string,
      userId?: string,
      guestStayId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdGuestnotesGet.'
        );
      }
      const localVarPath = `/{propertyId}/guestnotes`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userId !== undefined) {
        localVarQueryParameter['UserId'] = userId;
      }

      if (guestStayId !== undefined) {
        localVarQueryParameter['GuestStayId'] = guestStayId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestnotesIdDelete(propertyId: string, id: string, options: any = {}): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdGuestnotesIdDelete.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling propertyIdGuestnotesIdDelete.'
        );
      }
      const localVarPath = `/{propertyId}/guestnotes/{id}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestnotesIdGet(propertyId: string, id: string, options: any = {}): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdGuestnotesIdGet.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling propertyIdGuestnotesIdGet.'
        );
      }
      const localVarPath = `/{propertyId}/guestnotes/{id}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} id
     * @param {UpdateGuestNoteRequest} [updateGuestNoteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestnotesIdPut(
      propertyId: string,
      id: string,
      updateGuestNoteRequest?: UpdateGuestNoteRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdGuestnotesIdPut.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling propertyIdGuestnotesIdPut.'
        );
      }
      const localVarPath = `/{propertyId}/guestnotes/{id}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateGuestNoteRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateGuestNoteRequest !== undefined ? updateGuestNoteRequest : {})
        : updateGuestNoteRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {CreateGuestNoteRequest} [createGuestNoteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestnotesPost(
      propertyId: string,
      createGuestNoteRequest?: CreateGuestNoteRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdGuestnotesPost.'
        );
      }
      const localVarPath = `/{propertyId}/guestnotes`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createGuestNoteRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createGuestNoteRequest !== undefined ? createGuestNoteRequest : {})
        : createGuestNoteRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GuestNotesApi - functional programming interface
 * @export
 */
export const GuestNotesApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [userId]
     * @param {string} [guestStayId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestnotesGet(
      propertyId: string,
      userId?: string,
      guestStayId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestNotesResponse> {
      const localVarAxiosArgs = GuestNotesApiAxiosParamCreator(
        configuration
      ).propertyIdGuestnotesGet(propertyId, userId, guestStayId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestnotesIdDelete(
      propertyId: string,
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteGuestNoteItemResponse> {
      const localVarAxiosArgs = GuestNotesApiAxiosParamCreator(
        configuration
      ).propertyIdGuestnotesIdDelete(propertyId, id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestnotesIdGet(
      propertyId: string,
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestNoteItemResponse> {
      const localVarAxiosArgs = GuestNotesApiAxiosParamCreator(
        configuration
      ).propertyIdGuestnotesIdGet(propertyId, id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} id
     * @param {UpdateGuestNoteRequest} [updateGuestNoteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestnotesIdPut(
      propertyId: string,
      id: string,
      updateGuestNoteRequest?: UpdateGuestNoteRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateGuestNoteResponse> {
      const localVarAxiosArgs = GuestNotesApiAxiosParamCreator(
        configuration
      ).propertyIdGuestnotesIdPut(propertyId, id, updateGuestNoteRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {CreateGuestNoteRequest} [createGuestNoteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestnotesPost(
      propertyId: string,
      createGuestNoteRequest?: CreateGuestNoteRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateGuestNoteResponse> {
      const localVarAxiosArgs = GuestNotesApiAxiosParamCreator(
        configuration
      ).propertyIdGuestnotesPost(propertyId, createGuestNoteRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GuestNotesApi - factory interface
 * @export
 */
export const GuestNotesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [userId]
     * @param {string} [guestStayId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestnotesGet(
      propertyId: string,
      userId?: string,
      guestStayId?: string,
      options?: any
    ): AxiosPromise<GetGuestNotesResponse> {
      return GuestNotesApiFp(configuration).propertyIdGuestnotesGet(
        propertyId,
        userId,
        guestStayId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestnotesIdDelete(
      propertyId: string,
      id: string,
      options?: any
    ): AxiosPromise<DeleteGuestNoteItemResponse> {
      return GuestNotesApiFp(configuration).propertyIdGuestnotesIdDelete(
        propertyId,
        id,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestnotesIdGet(
      propertyId: string,
      id: string,
      options?: any
    ): AxiosPromise<GetGuestNoteItemResponse> {
      return GuestNotesApiFp(configuration).propertyIdGuestnotesIdGet(
        propertyId,
        id,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} id
     * @param {UpdateGuestNoteRequest} [updateGuestNoteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestnotesIdPut(
      propertyId: string,
      id: string,
      updateGuestNoteRequest?: UpdateGuestNoteRequest,
      options?: any
    ): AxiosPromise<UpdateGuestNoteResponse> {
      return GuestNotesApiFp(configuration).propertyIdGuestnotesIdPut(
        propertyId,
        id,
        updateGuestNoteRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {CreateGuestNoteRequest} [createGuestNoteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGuestnotesPost(
      propertyId: string,
      createGuestNoteRequest?: CreateGuestNoteRequest,
      options?: any
    ): AxiosPromise<CreateGuestNoteResponse> {
      return GuestNotesApiFp(configuration).propertyIdGuestnotesPost(
        propertyId,
        createGuestNoteRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * GuestNotesApi - object-oriented interface
 * @export
 * @class GuestNotesApi
 * @extends {BaseAPI}
 */
export class GuestNotesApi extends BaseAPI {
  /**
   *
   * @param {string} propertyId
   * @param {string} [userId]
   * @param {string} [guestStayId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestNotesApi
   */
  public propertyIdGuestnotesGet(
    propertyId: string,
    userId?: string,
    guestStayId?: string,
    options?: any
  ) {
    return GuestNotesApiFp(this.configuration).propertyIdGuestnotesGet(
      propertyId,
      userId,
      guestStayId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestNotesApi
   */
  public propertyIdGuestnotesIdDelete(propertyId: string, id: string, options?: any) {
    return GuestNotesApiFp(this.configuration).propertyIdGuestnotesIdDelete(
      propertyId,
      id,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestNotesApi
   */
  public propertyIdGuestnotesIdGet(propertyId: string, id: string, options?: any) {
    return GuestNotesApiFp(this.configuration).propertyIdGuestnotesIdGet(
      propertyId,
      id,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} id
   * @param {UpdateGuestNoteRequest} [updateGuestNoteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestNotesApi
   */
  public propertyIdGuestnotesIdPut(
    propertyId: string,
    id: string,
    updateGuestNoteRequest?: UpdateGuestNoteRequest,
    options?: any
  ) {
    return GuestNotesApiFp(this.configuration).propertyIdGuestnotesIdPut(
      propertyId,
      id,
      updateGuestNoteRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {CreateGuestNoteRequest} [createGuestNoteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestNotesApi
   */
  public propertyIdGuestnotesPost(
    propertyId: string,
    createGuestNoteRequest?: CreateGuestNoteRequest,
    options?: any
  ) {
    return GuestNotesApiFp(this.configuration).propertyIdGuestnotesPost(
      propertyId,
      createGuestNoteRequest,
      options
    )(this.axios, this.basePath);
  }
}
