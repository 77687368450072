import { QueryEntity } from '@datorama/akita';
import { switchMap } from 'rxjs/operators';

import { sessionQuery, SessionQuery } from '../session';
import { RoomZoneState, RoomZoneStore, roomZoneStore } from './room-zone.store';

export class RoomZoneQuery extends QueryEntity<RoomZoneState> {
  constructor(store: RoomZoneStore, private readonly sessionQuery: SessionQuery) {
    super(store);
  }

  roomZones = this.sessionQuery.propertyId.pipe(
    switchMap(propertyId =>
      this.selectAll({
        filterBy: x => x.propertyId === propertyId,
        sortBy: 'name',
      })
    )
  );
}

export const roomZoneQuery = new RoomZoneQuery(roomZoneStore, sessionQuery);
