import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Properties, Name, Property, dispatchForm, sessionService } from '@lib/state';
import { Employee, EmployeeRole } from './employee.model';
import { EmployeeForms } from './employee.forms';
import { EmployeeStore, employeeStore, EmployeeUIState, EmployeeFilters } from './employee.store';
import { applyTransaction } from '@datorama/akita';

export class EmployeeService {
  constructor(
    private readonly store: EmployeeStore,
    private readonly employeesApi: Properties.EmployeesApi
  ) {}

  getEmployees(propertyId?: Property['id']) {
    from(this.employeesApi.employeesGet(propertyId))
      .pipe(map(({ data }) => data.data))
      .subscribe(employees =>
        applyTransaction(() => {
          this.store.upsertMany(employees);
          this.updateUI({
            propertyId: propertyId,
          });
        })
      );
  }

  updateEmployeeUserRoles(userId: string, propertyId: Property['id'], roles: EmployeeRole[]) {
    from(
      this.employeesApi.employeesUserIdRolesPropertyIdPut(userId, propertyId, {
        roles: roles.map(x => x.role),
      })
    )
      .pipe(dispatchForm(EmployeeForms.UpdateEmployeeUserRoles))
      .pipe(map(({ data }) => data.data))
      .subscribe(employee => this.store.upsert(userId, employee));
  }

  deleteEmployee(userId: string) {
    from(this.employeesApi.employeesUserIdDelete(userId))
      .pipe(dispatchForm(EmployeeForms.DeleteEmployee))
      .subscribe(() => {
        this.store.remove(userId);
      });
  }

  getEmployeeById(userId: Employee['userId'], propertyId: Property['id']) {
    from(this.employeesApi.employeesUserIdGet(userId, propertyId))
      .pipe(map(({ data }) => data.data))
      .subscribe(employee =>
        applyTransaction(() => {
          this.store.upsert(userId, employee);
          this.updateUI({
            propertyId: propertyId ?? null,
          });
        })
      );
  }

  selectEmployee(userId?: Employee['userId']) {
    this.store.setActive(userId ?? null);
  }

  inviteEmployee(propertyId: Property['id'], email: string, propertyRoles: Array<string>) {
    from(this.employeesApi.employeesInvitePost({ propertyId, propertyRoles, email }))
      .pipe(dispatchForm(EmployeeForms.InviteEmployee))
      .subscribe(() => {});
  }

  checkInviteExists(inviteId: string) {
    if (!inviteId) return false;
    from(this.employeesApi.employeesInviteInviteIdGet(inviteId))
      .pipe(map(({ data }) => data.data))
      .subscribe(
        inviteExists => this.updateUI({ inviteExists }),
        () => this.updateUI({ inviteExists: false })
      );
  }

  submitEmployeeEnrollment(name: Name, employeeInviteId: string) {
    from(this.employeesApi.employeesEnrollPut({ name, employeeInviteId }))
      .pipe(
        // required to make sure the user roles are refreshed
        switchMap(() => sessionService.updateUserInfo()),
        dispatchForm(EmployeeForms.EnrollEmployee)
      )
      .subscribe(() => {});
  }

  updateFilters(filters: EmployeeFilters) {
    this.updateUI({ filters });
  }

  private updateUI(values: Partial<EmployeeUIState>) {
    this.store.update(({ ui }) => ({
      ui: {
        ...ui,
        ...values,
      },
    }));
  }
}

export const employeeService = new EmployeeService(employeeStore, new Properties.EmployeesApi());
