import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useFormEvents, ResetPasswordForm, ResetPasswordFormFields } from '@lib/common';
import { accountService, AccountForms, FormStatus } from '@lib/state';
import { Typography, Box } from '@material-ui/core';

export const ResetPasswordPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email') || '';
  const token = queryParams.get('token') || '';
  const history = useHistory();

  if (!email || !token) {
    history.push('/');
  }
  const submit = ({ password }: ResetPasswordFormFields) => {
    accountService.resetPassword(email, token, password);
  };

  const onRedirectToSignIn = () => {
    history.push(`/account/signin`);
  };

  const [{ status, error }, resetFormUI] = useFormEvents(AccountForms.ResetPassword);

  return (
    <Box>
      <Typography variant="subtitle1" align="center" color="textSecondary">
        RESET PASSWORD
      </Typography>
      <ResetPasswordForm
        pending={status === FormStatus.Pending}
        success={status === FormStatus.Success}
        error={error}
        onSubmit={submit}
        onResetFeedback={resetFormUI}
        onRedirectToSignIn={onRedirectToSignIn}
      />
    </Box>
  );
};
