import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ReservationAvailableRatesModel } from '../api/generated/reservations';
import { Reservation } from '../reservation';

export interface BulkAvailableRateUIState {
  propertyId?: string;
  corporateAccountId?: string;
  selectedReservations: Array<Reservation>;
  checkInDate?: Date;
  checkOutDate?: Date;
}

export interface BulkAvailableRateState
  extends EntityState<ReservationAvailableRatesModel, string> {
  ui: BulkAvailableRateUIState;
}

@StoreConfig({ name: 'bulk-available-rates', idKey: 'reservationId', resettable: true })
export class BulkAvailableRateStore extends EntityStore<BulkAvailableRateState> {
  constructor() {
    super({
      ui: {
        propertyId: undefined,
        corporateAccountId: undefined,
        selectedReservations: [],
      },
    });
  }
}

const bulkAvailableRateStore: BulkAvailableRateStore = new BulkAvailableRateStore();
export default bulkAvailableRateStore;
