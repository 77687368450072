import React, { useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightFormat, parseISO } from 'date-fns';
import { RoomUpdateFormProps, useFormStyles } from './room-forms';

const format = (date: Date | null | undefined) => (date ? lightFormat(date, 'yyyy-MM-dd') : null);
const unformat = (date: string | null | undefined) => (date ? parseISO(date) : null);

export const RoomAvailability: React.FC<RoomUpdateFormProps> = ({ room, stay, onSave }) => {
  const styles = useFormStyles();

  const [startDate, setStartDate] = useState(() => unformat(room.availableStartDate));
  const [endDate, setEndDate] = useState(() => unformat(room.availableEndDate));

  return (
    <Box className={styles.buttonContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <DatePicker
            name="startDate"
            label="Start Date"
            format="MMMM d, yyyy"
            value={startDate}
            maxDate={stay?.checkInDate}
            onChange={(value: Date | null) => {
              setStartDate(value);
              if (value && endDate && value > endDate) {
                setEndDate(null);
              }
            }}
            color="primary"
            InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
            autoOk
            clearable
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            name="endDate"
            label="End Date"
            format="MMMM d, yyyy"
            value={endDate}
            minDate={stay?.checkOutDate}
            onChange={(value: Date | null) => {
              setEndDate(value);
              if (value && startDate && value < startDate) {
                setStartDate(null);
              }
            }}
            color="primary"
            InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
            autoOk
            clearable
            fullWidth
          />
        </Grid>
      </Grid>
      <Button
        onClick={() =>
          onSave(room, { availableStartDate: format(startDate), availableEndDate: format(endDate) })
        }
        variant="contained"
        color="secondary"
      >
        Save
      </Button>
    </Box>
  );
};
