import React from 'react';
import { DialogContent } from '@material-ui/core';

import { StyledDialog } from '@lib/common';
import { AddEmployeeForm } from './add-employee-form';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const AddEmployeeModal: React.FC<Props> = ({ open, onClose }) => {
  return (
    <StyledDialog title="Add Employee" open={open} onClose={onClose} keepMounted>
      <DialogContent>
        <AddEmployeeForm onSubmitComplete={onClose} />
      </DialogContent>
    </StyledDialog>
  );
};
