import { HousekeepingStatusEnum } from '@lib/state';

export enum DeferType {
  ComeBackLater = 'ComeBackLater',
  ComeBackTomorrow = 'ComeBackTomorrow',
}

export const housekeepingStatuses = Object.values(HousekeepingStatusEnum).filter(
  s => s !== HousekeepingStatusEnum.NoCleaningRequired
);
