import { QueryEntity } from '@datorama/akita';

import { AuditLogStore, AuditLogState, auditLogStore } from './audit-log.store';

export class AuditLogQuery extends QueryEntity<AuditLogState> {
  constructor(protected store: AuditLogStore) {
    super(store);
  }

  securityImage = this.select(({ ui }) => ui.securityImage);
}

export const auditLogQuery = new AuditLogQuery(auditLogStore);
