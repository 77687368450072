import React, { useState } from 'react';
import { Link } from '@material-ui/core';
import { FormNumberField } from '../../forms';
import { useCommonStyle } from '../../commonStyle';

interface Props {
  required?: boolean;
  requiredMessage?: string;
}

export const ManagerOverrideInput: React.FC<Props> = ({
  required = false,
  requiredMessage = 'Required to complete this operation.',
}) => {
  const [showOverride, setShowOverride] = useState(required);
  const styles = useCommonStyle();

  if (showOverride)
    return (
      <FormNumberField
        name="overrideCode"
        label="Manager Override"
        defaultValue=""
        size="small"
        validationOptions={{ required }}
        required={required}
        helperText={required ? requiredMessage : undefined}
      />
    );

  return (
    <Link className={styles.clickable} component="span" onClick={() => setShowOverride(true)}>
      Manager Override
    </Link>
  );
};
