import React from 'react';
import { MenuItem } from '@material-ui/core';

import { SleepSchedule } from '@lib/state';
import { EnumValueDisplay } from '../../components';
import { FormSelectProps, FormSelect } from '../../forms';

export const SleepScheduleInput: React.FC<Omit<FormSelectProps, 'children'>> = ({
  defaultValue = '',
  ...props
}) => (
  <FormSelect defaultValue={defaultValue} {...props} data-testid="guestFormSleepScheduleSelect">
    <MenuItem value={SleepSchedule.DaySleeper} data-testid="selectDaySleeperItem">
      <EnumValueDisplay value={SleepSchedule.DaySleeper} disableTypography />
    </MenuItem>
    <MenuItem value={SleepSchedule.NightSleeper} data-testid="selectNightSleeperItem">
      <EnumValueDisplay value={SleepSchedule.NightSleeper} disableTypography />
    </MenuItem>
  </FormSelect>
);
