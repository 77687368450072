import React, { useMemo } from 'react';
import { Typography, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ErrorDisplay, useFormEvents } from '../../forms';
import {
  AccountForms,
  accountService,
  FormStatus,
  GuestProfile,
  sessionQuery,
  UserInfo,
} from '@lib/state';
import { Link } from '../link';
import { useObservable } from '../../utils';

interface UserVerification {
  email: string;
  phoneNumber: string;
  emailVerified: boolean;
  phoneNumberVerified: boolean;
}

interface Props {
  guest?: GuestProfile;
}

export const AccountVerification: React.FC<Props> = ({ guest }) => {
  const currentUser = useObservable(sessionQuery.currentUser);
  const verification: UserVerification = useMemo(() => {
    if (currentUser) return getVerification(currentUser, guest);
    return {
      email: '',
      phoneNumber: '',
      emailVerified: false,
      phoneNumberVerified: false,
    };
  }, [currentUser, guest]);

  const getPhoneVerificationUrl = () => {
    let url = '/account/verify/phone';
    if (guest) {
      url += `/${guest.userId}`;
    }
    return url;
  };

  const [result] = useFormEvents(AccountForms.SendEmailVerificationToken);

  const sendEmailVerification = () => {
    if (verification.email) accountService.sendVerifyEmailToken(verification.email);
  };

  if (result.status === FormStatus.Success) {
    return (
      <Alert severity="success">
        <Typography>
          Please check your inbox. We've sent a verification link to {verification.email}.
        </Typography>
      </Alert>
    );
  }
  if (result.status === FormStatus.Error) {
    return (
      <ErrorDisplay error={result.error}>
        <Typography align="center">
          Sorry, we had an issue sending your verification link. Please try again.
        </Typography>
      </ErrorDisplay>
    );
  }
  if (!verification.emailVerified && verification.email)
    return (
      <Alert severity="info">
        <Typography>
          You have not yet verified your email {verification.email}.&nbsp;
          <Button onClick={() => sendEmailVerification()} variant="text">
            Send Email Verification Link
          </Button>
        </Typography>
      </Alert>
    );
  if (!verification.phoneNumberVerified && verification.phoneNumber) {
    return (
      <Alert severity="info">
        <Typography>
          You have not yet verified your phone number.&nbsp;
          <Link to={getPhoneVerificationUrl()}>Send Mobile Verification Code</Link>
        </Typography>
      </Alert>
    );
  }
  return <></>;
};

function getVerification(user: UserInfo, guest?: GuestProfile): UserVerification {
  if (guest) {
    return {
      email: guest.contact.email,
      phoneNumber: guest.contact.phone,
      emailVerified: guest.emailVerfied,
      phoneNumberVerified: guest.phoneNumberVerified,
    } as UserVerification;
  }

  return {
    email: user.email,
    phoneNumber: user.phone_number,
    emailVerified: user.email_verified,
    phoneNumberVerified: user.phone_number_verified,
  } as UserVerification;
}
