export type HousekeepingAssignmentForms =
  | 'updateHousekeepingRoomAssignment'
  | 'removeHousekeepingRoomAssignment'
  | 'startHousekeepingRoomAssignment';

export const HousekeepingAssignmentForms = {
  UpdateHousekeepingRoomAssignment: <HousekeepingAssignmentForms>'updateHousekeepingRoomAssignment',
  RemoveHousekeepingRoomAssignment: <HousekeepingAssignmentForms>'removeHousekeepingRoomAssignment',
  StartHousekeepingRoomAssignment: <HousekeepingAssignmentForms>'startHousekeepingRoomAssignment',
};
