import { AxiosError } from 'axios';
import { ValidationError } from './validation-error';

export function isAxiosError(obj: any): obj is AxiosError {
  const err = obj as AxiosError;

  return !!err.isAxiosError;
}

export function getError(error: Error) {
  if (isAxiosError(error)) {
    if (error.response?.data.title === 'One or more validation errors occurred.') {
      return new ValidationError(error.response.data.errors);
    }
    if (error.response?.data.detail) return new Error(error.response?.data.detail);
    if (error.response?.data.title) return new Error(error.response?.data.title);
    return new Error(error.response?.data ?? error.message);
  }

  return error;
}

export function getErrorUnknown(error: unknown) {
  const objError = error as Error;
  return getError(objError);
}
