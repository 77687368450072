import React, { useMemo } from 'react';
import pluralize from 'pluralize';

import { HousekeepingPolicy } from '@lib/state';

export const HousekeepingPolicyDisplay: React.FC<HousekeepingPolicy> = ({
  touchUpCleanFrequency,
  fullCleanFrequency,
}) => {
  const touchUp = useMemo(() => {
    if (!touchUpCleanFrequency || touchUpCleanFrequency < 1) return;

    return `Touch up clean every ${pluralize(
      'day',
      touchUpCleanFrequency,
      touchUpCleanFrequency !== 1
    )}`;
  }, [touchUpCleanFrequency]);

  const full = useMemo(() => {
    if (!fullCleanFrequency || fullCleanFrequency < 1) return;

    return `Full clean every ${pluralize('day', fullCleanFrequency, fullCleanFrequency !== 1)}`;
  }, [fullCleanFrequency]);

  return (
    <>
      {useMemo(() => [touchUp, full, 'Check-out cleaning'].filter(x => x != null).join(', '), [
        touchUp,
        full,
      ])}
    </>
  );
};
