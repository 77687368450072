import { EntityStore, EntityState, StoreConfig } from '@datorama/akita';

import { PaginationState } from '@lib/state';
import { CorporateInvoice } from './corporate-invoice.model';

export interface CorporateInvoiceState
  extends EntityState<CorporateInvoice, CorporateInvoice['id']> {
  pagination: PaginationState;
}

@StoreConfig({ name: 'corporate-invoices', resettable: true })
export class CorporateInvoiceStore extends EntityStore<CorporateInvoiceState> {
  constructor() {
    super({
      ui: {},
      pagination: {
        isDone: true,
        continuationToken: undefined,
      },
    });
  }
}

export const corporateInvoiceStore = new CorporateInvoiceStore();
