import React, { useCallback } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Typography, Container } from '@material-ui/core';
import { AccountForms, accountService, FormStatus } from '@lib/state';
import { CreateAccountForm, FormHub, useFormEvents, CreateAccountFormFields } from '@lib/common';

export const CreateAccountPage: React.FC = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const employeeInviteId = queryParams.get('employeeInviteId');

  const [{ status, error }] = useFormEvents(AccountForms.CreateAccount);

  const submit = useCallback(
    ({ contact, name, address, password }: CreateAccountFormFields) =>
      accountService.createAccount(contact.email, contact.phone, name, address, password),
    []
  );

  if (status === FormStatus.Success) {
    // if there is an employeeInviteId -> complete enrollment
    if (employeeInviteId) {
      const redirectLink = `/account/employee-enrollment?employeeInviteId=${employeeInviteId}`;
      return <Redirect to={redirectLink} />;
    }
    return <Redirect to="/kiosk" />;
  }

  return (
    <Container maxWidth="md">
      <FormHub onSubmit={submit}>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Create Account
        </Typography>
        <CreateAccountForm status={status} error={error} />
      </FormHub>
    </Container>
  );
};
