import React from 'react';
import { makeStyles, createStyles, Typography } from '@material-ui/core';

import { DateDisplay, ConfirmedButton, AppColors, useFormEvents, DataTable } from '@lib/common';
import { RateRestriction, RateRestrictionForms } from 'app/state';

const useStyles = makeStyles(theme =>
  createStyles({
    deleteButton: {
      color: AppColors.Red,
    },
  })
);

interface Props {
  rateRestrictions: Array<RateRestriction>;
  onDeleteRateRestriction: (rateRestriction: RateRestriction) => void;
}

export const RateRestrictionList: React.FC<Props> = ({
  rateRestrictions,
  onDeleteRateRestriction,
}) => {
  const styles = useStyles();
  const [{ status, error }] = useFormEvents(RateRestrictionForms.Delete);

  if (rateRestrictions && rateRestrictions.length === 0) {
    return <Typography align="center">No Rate Restrictions found</Typography>;
  }

  return (
    <DataTable
      items={rateRestrictions}
      getItemKey={rateRestriction => rateRestriction.id}
      columns={[
        {
          title: 'Restriction Type',
          valueFactory: rateRestriction => rateRestriction.type,
        },
        {
          title: 'Start Date',
          valueFactory: rateRestriction => (
            <DateDisplay date={rateRestriction.startDate} dateFormat="M/d/yyyy" />
          ),
        },
        {
          title: 'End Date',
          valueFactory: rateRestriction => (
            <DateDisplay date={rateRestriction.endDate} dateFormat="M/d/yyyy" />
          ),
        },
        {
          title: 'Min LOS',
          valueFactory: rateRestriction => <Typography>{rateRestriction.minLOS}</Typography>,
        },
        {
          title: 'Max LOS',
          valueFactory: rateRestriction => <Typography>{rateRestriction.maxLOS}</Typography>,
        },
        {
          title: 'Created Time',
          valueFactory: rateRestriction => (
            <DateDisplay date={rateRestriction.createdTime} dateFormat="M/d/yyyy" />
          ),
        },
        {
          title: 'Deleted',
          valueFactory: rateRestriction =>
            !rateRestriction.deletedTime ? (
              <ConfirmedButton
                onConfirm={() => onDeleteRateRestriction(rateRestriction)}
                action="Delete"
                className={styles.deleteButton}
                variant="outlined"
                status={status}
                error={error}
              />
            ) : (
              <DateDisplay date={rateRestriction.deletedTime} dateFormat="M/d/yyyy" />
            ),
        },
      ]}
    />
  );
};
