import { DateDisplay } from '@lib/common';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { parseISO } from 'date-fns';
import React from 'react';
import { MaintenanceTicketJournal } from '../../state/maintenance-journals';

interface Props {
  journals: Array<MaintenanceTicketJournal>;
}

export const MaintenanceJournalList: React.FC<Props> = ({ journals }) => {
  return (
    <>
      <List style={{ width: '100%', maxHeight: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
        {journals.map((item, i) => (
          <div key={`list-item-div-${i}`}>
            <ListItem
              key={`list-item-${i}`}
              alignItems="flex-start"
              style={{
                marginBottom: '16px',
                backgroundColor: 'rgb(222,222,222)',
                borderRadius: '5px',
              }}
            >
              <ListItemText
                key={`list-item-text-${i}`}
                primary={
                  <React.Fragment>
                    <Typography style={{ display: 'inline' }} component="span" variant="body2">
                      {item.createdByName}
                      <DateDisplay
                        variant="body2"
                        style={{ display: 'inline', marginLeft: '15px' }}
                        date={parseISO(item.timestamp)}
                        dateFormat="M/d/yyyy h:mm a"
                      />
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <>
                      <Typography style={{ display: 'inline' }} component="span" variant="body1">
                        {item.description}
                      </Typography>
                    </>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
          </div>
        ))}
      </List>
    </>
  );
};
