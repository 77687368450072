import { QueryEntity, Order } from '@datorama/akita';
import { switchMap } from 'rxjs/operators';
import { CorporateAccountQuery, corporateAccountQuery } from '../corporate-account';
import {
  CorporateInvoiceState,
  CorporateInvoiceStore,
  corporateInvoiceStore,
} from './corporate-invoice.store';

export class CorporateInvoiceQuery extends QueryEntity<CorporateInvoiceState> {
  constructor(
    protected store: CorporateInvoiceStore,
    private readonly accounts: CorporateAccountQuery
  ) {
    super(store);
  }

  pagination = this.select(({ pagination }) => pagination);

  invoices = this.accounts.selectActive().pipe(
    switchMap(account =>
      this.selectAll({
        filterBy: ({ corporateAccountId, isDraft }) =>
          corporateAccountId === account?.id && !isDraft,
        sortBy: 'startDate',
        sortByOrder: Order.DESC,
      })
    )
  );
}

export const corporateInvoiceQuery = new CorporateInvoiceQuery(
  corporateInvoiceStore,
  corporateAccountQuery
);
