import React from 'react';
import { Typography, FormControlLabel, Checkbox, Grid, Paper } from '@material-ui/core';
import { Properties, propertyRoles } from '@lib/state';
import { NameDisplay, useCommonStyle } from '@lib/common';

interface Props {
  employee: Properties.EmployeeModel;
  propertyId: string;
  onRoleChange: (propertyId: string, role: string, isChecked: boolean) => void;
}

export const EmployeeInfo: React.FC<Props> = ({ employee, propertyId, onRoleChange }) => {
  const styles = useCommonStyle();

  return (
    <>
      <Paper className={styles.paper} elevation={3} square>
        <Typography variant="h5" paragraph>
          Employee Details
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>
              <strong>Name</strong>
            </Typography>
            <NameDisplay {...employee.name} directory />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <strong>User Roles</strong>
            </Typography>
            {propertyRoles.map(role => {
              const isChecked = employee.propertyRoles.map(x => x.role).indexOf(role) !== -1;
              return (
                <Grid item xs={12} key={role}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onRoleChange(propertyId, role, e.target.checked)
                        }
                        name={role}
                        color="primary"
                      />
                    }
                    label={role}
                    key={role}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
