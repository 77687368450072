import React from 'react';
import { Link } from '@lib/common';
import { Box } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isKiosk } from '../native-interface/nativebridge';

interface BreadcrumbBackButtonProps {
  destination: string;
}

export const BreadcrumbBackButton: React.FC<BreadcrumbBackButtonProps> = ({ destination }) => {
  if (isKiosk()) return null;

  return (
    <Box marginY={2}>
      <Link to={destination} variant="h5" data-testid="breadcrumbBackButton">
        <FontAwesomeIcon icon="chevron-left" /> Back
      </Link>
    </Box>
  );
};
