import {
  BulkUpdateReservationRoomsModel,
  ReservationAvailableRatesModel,
  ReservationContactModel,
  ReservationContactType,
  UpdateReservationRoomModel,
} from '@lib/state/api/generated/reservations';
import { ReservationRow } from './bulk-activities-display';
import { BulkCancellationPreview, Reservation, formatName } from '@lib/state';

export enum BulkActivityType {
  ChangeDates,
  Cancel,
}

export function transformRatesToBulkModify(
  reservationAvailableRates: ReservationAvailableRatesModel[],
  reservations: Reservation[]
): Array<BulkUpdateReservationRoomsModel> {
  const reservationRooms: Array<BulkUpdateReservationRoomsModel> = reservationAvailableRates.map(
    reservationRate => {
      const reservation = reservations.find(r => r.id === reservationRate.reservationId);

      return {
        reservationId: reservationRate.reservationId,
        rooms: reservationRate.rates.map(rate => {
          const room = reservation?.rooms.find(room => room.roomTypeId === rate.roomTypeId);
          return {
            id: room?.id,
            roomTypeId: rate.roomTypeId,
            dailyRates: rate.dailyRates,
          } as UpdateReservationRoomModel;
        }),
      } as BulkUpdateReservationRoomsModel;
    }
  );

  return reservationRooms;
}

function getGuestName(resContact?: ReservationContactModel) {
  if (!resContact) return '';
  const guestName = resContact.name;
  return formatName(guestName.first, guestName.middle, guestName.last);
}

export function createReservationRow(
  bulkItem: ReservationAvailableRatesModel | BulkCancellationPreview,
  reservation: Reservation
): ReservationRow {
  let chargesAmountTotal: number = 0;
  let chargesTaxTotal: number = 0;
  let refundsAmountTotal: number = 0;
  let refundsTaxTotal: number = 0;

  if ('charges' in bulkItem) {
    bulkItem.charges.forEach(c => {
      if (c.amount >= 0) {
        chargesAmountTotal += c.amount;
        chargesTaxTotal += c.tax.total;
      } else {
        refundsAmountTotal += c.amount * -1;
        refundsTaxTotal += c.tax.total * -1;
      }
    });
  }

  const guest = reservation?.contacts.find(c => c.contactType === ReservationContactType.Guest);

  return {
    reservationId: bulkItem.reservationId,
    recordNumber: bulkItem.recordNumber.toString(),
    reservationStatus: reservation?.status,
    checkIn: reservation?.checkInDate,
    checkOut: reservation?.checkOutDate,
    guestFullName: getGuestName(guest),
    guestEmail: guest?.contact?.email,
    billingReference: reservation?.billingReference,
    crew: reservation?.affiliation?.crew,
    chargesAmount: chargesAmountTotal,
    chargesTax: chargesTaxTotal,
    refundsAmount: refundsAmountTotal,
    refundsTax: refundsTaxTotal,
  } as ReservationRow;
}

export function availableRatesToReservationRows(
  bulk: ReservationAvailableRatesModel[],
  reservations: Reservation[]
): ReservationRow[] {
  const data: ReservationRow[] = [];

  bulk.forEach(b => {
    const res = reservations.find(r => r.id === b.reservationId);
    if (res) {
      data.push(createReservationRow(b, res));
    }
  });

  return data;
}

export function previewCancelItemsToReservationRows(
  bulk: BulkCancellationPreview[],
  reservations: Reservation[] = []
): ReservationRow[] {
  const data: ReservationRow[] = [];

  bulk.forEach(b => {
    const res = reservations.find(r => r.id === b.reservationId);
    if (res) {
      const reservationRow = createReservationRow(b, res);
      reservationRow.chargesAmount = 0;
      reservationRow.chargesTax = 0;
      reservationRow.refundsAmount = b.refund * -1;
      reservationRow.refundsTax = 0;
      data.push(reservationRow);
    }
  });

  return data;
}
