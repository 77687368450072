import { from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { dispatchForm } from '..';

import { Auth, Properties } from '../api';
import { Address, Name } from '../models.common';
import { PropertyService, propertyService, PropertyStore, propertyStore } from '../property';
import { SessionService, sessionService } from '../session';
import { CustomerForms } from './customer.forms';

export class CustomerService {
  constructor(
    private readonly propertyStore: PropertyStore,
    private readonly propertyService: PropertyService,
    private readonly sessionService: SessionService,
    private readonly customerApi: Properties.CustomerApi,
    private readonly accountApi: Auth.AccountApi
  ) {}

  createCustomer(
    {
      email,
      phone,
    }: {
      email: string;
      phone?: string | null;
    },
    password: string,
    adminName: Name,
    customerName: string,
    propertyName: string,
    timezone: string,
    initialRoomType: string,
    initialRoomRate: number,
    location: Address,
    numberOfRooms?: number
  ) {
    from(
      this.accountApi.accountCreatePost({
        email,
        phoneNumber: phone,
        password,
      })
    )
      .pipe(
        switchMap(() => this.sessionService.login(email, password)),
        switchMap(() =>
          this.customerApi.customerPost({
            adminName,
            customerName,
            propertyName,
            timezone,
            initialRoomType,
            initialRoomRate,
            numberOfRooms,
            location,
          })
        ),
        tap(({ data }) => this.propertyService.selectProperty(data.data.id)),
        switchMap(() => this.sessionService.updateUserInfo()),
        dispatchForm(CustomerForms.SignUp)
      )
      .subscribe();
  }
}

export const customerService = new CustomerService(
  propertyStore,
  propertyService,
  sessionService,
  new Properties.CustomerApi(),
  new Auth.AccountApi()
);
