import React, { HTMLAttributes, useEffect } from 'react';

import { FormStatus } from '@lib/state';
import { FormTextField, SubmitButton } from '@lib/common';
import { Box, Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

export interface CreateTicketJournalFormFields {
  description: string;
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  status: FormStatus;
}

export const MainternanceJournalForm: React.FC<Props> = ({ status, ...props }) => {
  const { reset } = useFormContext();

  useEffect(() => {
    if (status === FormStatus.Success) {
      reset();
    }
  }, [status, reset]);

  return (
    <div {...props}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormTextField
            name="description"
            label="Note"
            fullWidth
            minRows={3}
            multiline={true}
            required
          />
        </Grid>
      </Grid>
      <Box py={2}>
        <SubmitButton
          variant="contained"
          color="secondary"
          fullWidth
          pending={status === FormStatus.Pending}
          disabled={status === FormStatus.Pending}
        >
          Add Note
        </SubmitButton>
      </Box>
    </div>
  );
};
