import { Reservation, getPromoCode } from '@lib/state';
import { formatCurrency } from './../components';

export function getReservationTransaction(reservations: Reservation[]): {} {
  // Clear the previous ecommerce object.
  // removed for now to test ecommerce bugs
  //ecommerce.push({ ecommerce: null });

  if (!reservations?.length) return {};

  var revenue = 0.0;
  var promoCode = '';

  const products = reservations.map(reservation => {
    const total = reservation.charges.reduce((total, charge) => total + charge.totalAmount, 0);
    revenue += total;

    promoCode = getPromoCode(reservation) ?? '';

    return {
      // List of productFieldObjects.
      name: reservation.recordNumber.toString(), // Name or ID is required.
      id: reservation.recordNumber.toString(),
      price: formatCurrency(total, true),
      coupon: promoCode, // Optional fields may be omitted or set to empty string.
      //variant: todo: map room type name
      quantity: 1,
    };
  });

  const transaction = {
    event: 'transaction',
    ecommerce: {
      purchase: {
        actionField: {
          id: getTransactionId(reservations), // Transaction ID. Required
          revenue: formatCurrency(revenue, true), // Total transaction value (incl. tax and shipping)
          coupon: promoCode,
        },
        products: products,
      },
    },
  };

  return transaction;
}

function getTransactionId(reservations: Reservation[]): string {
  if (!reservations?.length) return '';

  if (reservations.length === 1) return reservations[0].recordNumber.toString();

  return `${reservations[0].recordNumber}-${reservations[reservations.length - 1].recordNumber}`;
}
