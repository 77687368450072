import React, { useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { makeStyles, createStyles, Grid } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfDay, startOfToday } from 'date-fns';

import { HousekeepingAssignment, Housekeeper } from '@lib/state';
import { FilterSection } from 'app/shared';
import { PrintableHousekeeperReportButton } from './printable-housekeeper-report-button';

const useStyles = makeStyles(
  createStyles({
    controls: {
      minWidth: '120px',
    },
  })
);

interface FormFields {
  date: Date;
}

interface Props {
  rooms: HousekeepingAssignment[];
  housekeepers: Housekeeper[];
  onDateChange: (date: Date) => void;
}

export const HousekeepingReportFilter: React.FC<Props> = ({
  rooms,
  housekeepers,
  onDateChange,
}) => {
  const styles = useStyles();

  const form = useForm<FormFields>({
    defaultValues: {
      date: startOfToday(),
    },
  });
  const { register, watch, setValue, errors } = form;

  useEffect(() => {
    register({ name: 'date' });
  }, [register]);
  const { date } = watch();

  useEffect(() => {
    onDateChange(date);
  }, [date, onDateChange]);

  return (
    <FilterSection>
      <FormContext {...form}>
        <Grid container spacing={1}>
          <Grid item>
            <DatePicker
              name="date"
              label="Date"
              format="MMMM d"
              value={date ?? startOfToday()}
              error={!!errors.date}
              onChange={value => setValue('date', value ? startOfDay(value) : startOfToday())}
              color="primary"
              InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
              className={styles.controls}
              fullWidth
              clearable
              autoOk
            />
          </Grid>
          <Grid item>
            <Grid item xs={3} sm={2} md={2} lg={1}>
              <PrintableHousekeeperReportButton
                rooms={rooms}
                housekeepers={housekeepers}
                date={date}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormContext>
    </FilterSection>
  );
};
