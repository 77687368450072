import React, { useState } from 'react';
import { FormTextField } from '@lib/common';
import { DialogContentText, Grid } from '@material-ui/core';
import { GuestNote } from 'app/state';
import { GuestNoteTypeSelect } from './guest-note-types-select';

interface Props {
  guestNote?: GuestNote;
}

export const GuestNoteForm: React.FC<Props> = ({ guestNote }) => {
  const [note, setNote] = useState(guestNote?.note);
  const [selectedNoteTypeId, setSelectedNoteTypeId] = useState(guestNote?.noteTypeId);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DialogContentText>
            Please enter the note and select the type to be saved.
          </DialogContentText>
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            type="text"
            name="note"
            label="Note"
            multiline={true}
            rows={4}
            value={note}
            onChange={event => setNote(event.target.value)}
            fullWidth
            validationOptions={{ required: true, maxLength: 2000 }}
          />
        </Grid>
        <Grid item xs={12}>
          <GuestNoteTypeSelect
            propertyId={guestNote?.propertyId}
            selectedNoteTypeId={selectedNoteTypeId}
            onSelect={noteType => setSelectedNoteTypeId(noteType?.id)}
          ></GuestNoteTypeSelect>
        </Grid>
      </Grid>
    </>
  );
};
