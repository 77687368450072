import { QueryEntity } from '@datorama/akita';
import corporateAccountStore, {
  CorporateAccountStore,
  CorporateAccountState,
} from './corporate-account.store';

export class CorporateAccountQuery extends QueryEntity<CorporateAccountState> {
  constructor(protected store: CorporateAccountStore) {
    super(store);
  }

  accounts = this.selectAll();
}

export const corporateAccountQuery = new CorporateAccountQuery(corporateAccountStore);
