import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { FormStatus } from '@lib/state';
import { ErrorDisplay, Feedback, FormCheckbox, SubmitButton, useFormEvents } from '@lib/common';
import { CorporateAccountForms } from 'app/state';

export interface InvoiceGroupingFormFields {
  isInvoiceGroupedByLocation: boolean;
  isInvoiceGroupedByCostCenter1: boolean;
  isInvoiceGroupedByCostCenter2: boolean;
}

interface Props {
  defaultValues: InvoiceGroupingFormFields;
}

export const InvoiceGroupingForm: React.FC<Props> = ({ defaultValues }) => {
  const [{ status, error }, resetFormUI] = useFormEvents(CorporateAccountForms.SetInvoiceGrouping);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={4} xs={12}>
          <FormCheckbox
            name="isInvoiceGroupedByLocation"
            label="Location"
            defaultChecked={defaultValues.isInvoiceGroupedByLocation}
            fullWidth
            helperText={'If selected, group invoices by the Location field.'}
            data-testid="invoiceGroupingLocationCheckbox"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormCheckbox
            name="isInvoiceGroupedByCostCenter1"
            label="Cost Center 1"
            defaultChecked={defaultValues.isInvoiceGroupedByCostCenter1}
            fullWidth
            helperText={'If selected, group invoices by the Cost Center 1 field.'}
            data-testid="invoiceGroupingCostCenter1Checkbox"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormCheckbox
            name="isInvoiceGroupedByCostCenter2"
            label="Cost Center 2"
            defaultChecked={defaultValues.isInvoiceGroupedByCostCenter2}
            fullWidth
            helperText={'If selected, group invoices by the Cost Center 2 field.'}
            data-testid="invoiceGroupingCostCenter2Checkbox"
          />
        </Grid>
      </Grid>
      <Box py={1}>
        <Feedback
          show={status === FormStatus.Success}
          severity="success"
          onHide={resetFormUI}
          data-testid="invoiceGroupingSuccessDisplay"
        >
          Grouping Fields saved successfully!
        </Feedback>
        <ErrorDisplay error={error} />
      </Box>
      <Box display="flex" justifyContent="flex-end" pb={2}>
        <SubmitButton
          name="updateInvoiceGroupingFields"
          variant="contained"
          color="secondary"
          pending={status === FormStatus.Pending}
          disabled={status === FormStatus.Pending}
          data-testid="invoiceGroupingSubmitButton"
        >
          Save Grouping Fields
        </SubmitButton>
      </Box>
    </>
  );
};
