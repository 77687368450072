import { NavMenuStore, navMenuStore } from './nav-menu.store';
import { NavMenuItemSection } from './nav-menu.model';

export class NavMenuService {
  constructor(private readonly store: NavMenuStore) {}

  loadMenu(menuSections: NavMenuItemSection[]) {
    this.store.set(menuSections);
  }

  toggleNavDrawer() {
    this.store.update(({ ui }) => {
      return {
        ui: {
          ...ui,
          isNavDrawerOpen: !ui.isNavDrawerOpen,
        },
      };
    });
  }
}

export const navMenuService = new NavMenuService(navMenuStore);
