import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { parseISO } from 'date-fns';
import { RefundReservationChargeModel, Accounting, Reservation } from '@lib/state';
import { DateDisplay, CurrencyDisplay, useHasRole, DataTable, IDataTableColumn } from '@lib/common';
import { ReservationRefundModal } from './reservation-refund.modal';
import { JournalAccount } from 'app/state';
import { ReservationChargeModel } from '@lib/state/api/generated/reservations';

interface Props {
  charges: Reservation['charges'];
  accounts: JournalAccount[];
  refundCharge: (model: RefundReservationChargeModel) => void;
}
export type TotalRow = {
  id: string;
  authNetTransactionId: string;
  timestamp: string;
  totalAmount: number;
};

export const ReservationChargesTable: React.FC<Props> = ({ charges, accounts, refundCharge }) => {
  const columns: IDataTableColumn<ReservationChargeModel | TotalRow>[] = [
    {
      title: 'Conf Code',
      valueFactory: charge =>
        charge.authNetTransactionId === 'Total' ? null : (
          <Typography>{charge.authNetTransactionId}</Typography>
        ),
    },
    {
      title: 'Payment Method',
      valueFactory: charge => (
        <Typography>{(charge as ReservationChargeModel).paymentMethod}</Typography>
      ),
    },
    {
      title: 'Date',
      valueFactory: charge => (
        <DateDisplay
          date={
            (charge as ReservationChargeModel).timestamp !== ''
              ? parseISO((charge as ReservationChargeModel).timestamp)
              : null
          }
          dateFormat="M/d/yyyy h:mm a"
        />
      ),
    },
    {
      title: 'Amount',
      align: 'right',
      valueFactory: charge => (
        <CurrencyDisplay value={(charge as ReservationChargeModel).totalAmount} />
      ),
    },
    {
      title: 'Description',
      valueFactory: charge => (
        <Typography>{(charge as ReservationChargeModel).description}</Typography>
      ),
    },
  ];

  if (useHasRole(Accounting)) {
    columns.push({
      title: 'Refund',
      align: 'right',
      printable: false,
      valueFactory: charge => {
        if (charge.totalAmount > 0)
          return charge.authNetTransactionId !== 'Total' ? (
            <ReservationRefundModal
              charge={charge as ReservationChargeModel}
              accounts={accounts}
              onSubmit={refundCharge}
            />
          ) : null;
      },
    });
  }

  var finalRow: TotalRow = {
    authNetTransactionId: 'Total',
    timestamp: '',
    totalAmount: 0,
    id: '',
  };

  var chargesToDisplay: (ReservationChargeModel | TotalRow)[] = charges.map(x =>
    Object.assign({}, x)
  );
  finalRow.totalAmount = useMemo(
    () =>
      charges.reduce((accumulator, row) => {
        return accumulator + row.totalAmount;
      }, 0),
    [charges]
  );
  if (charges.length > 0) {
    chargesToDisplay.push(finalRow);
  }

  return <DataTable items={chargesToDisplay} getItemKey={charge => charge.id} columns={columns} />;
};
