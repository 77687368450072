import React, { useCallback } from 'react';

import { ButtonProps } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useFormEvents } from '../forms';

import {
  Reservation,
  ReservationForms,
  reservationQuery,
  reservationService,
  CheckReservationCancelableState,
  ReservationActivity,
} from '@lib/state';
import { ConfirmedButton } from '../components';
import { useObservable } from '../utils';
import { ReservationFeeList } from './reservation-fee-list';
import { RefundableConfirmationForm } from '.';

interface Props extends Omit<ButtonProps, 'action' | 'onClick'> {
  reservation: Reservation;
  overrideable?: boolean;
}

export const CancelReservationButton: React.FC<Props> = ({
  reservation,
  overrideable = false,
  ...props
}) => {
  const [{ status, error }, resetCancelReservationForm] = useFormEvents(
    ReservationForms.CancelReservation
  );

  const form = useForm();
  const activity = useObservable(reservationQuery.lastActivity);
  const overrideCode = form.watch('overrideCode');

  const validState = CheckReservationCancelableState(reservation);

  const validPolicy = reservation.cancelable;

  const allowed = validState && (validPolicy || overrideable);

  const overrideRequired = !validPolicy;

  const resultView = <ReservationFeeList {...activity?.cost} />;

  const onOpen = useCallback(() => {
    resetCancelReservationForm();
  }, [resetCancelReservationForm]);

  const onConfirm = useCallback(async () => {
    const valid = await form.triggerValidation();

    if (valid) {
      // Use null instead of default value (empty string) if overrideCode was left blank
      reservationService.cancelReservation(reservation, overrideCode || null);
    }
  }, [form, reservation, overrideCode]);

  return (
    <>
      {allowed && (
        <ConfirmedButton
          name="cancelReservation"
          variant="contained"
          color="secondary"
          action={`Cancel Reservation ${reservation.recordNumber}`}
          onConfirm={onConfirm}
          onOpen={onOpen}
          buttonText="Cancel"
          status={status}
          error={error}
          hidden={!allowed}
          confirmView={
            <RefundableConfirmationForm
              reservation={reservation}
              actionType={ReservationActivity.CancelReservation}
              overrideable={overrideable}
              overrideRequired={overrideRequired}
              form={form}
            />
          }
          resultView={resultView}
          {...props}
        ></ConfirmedButton>
      )}
    </>
  );
};
