import React, { useContext } from 'react';

export enum DeviceType {
  Undefined = 'Undefined',
  Kiosk = 'Kiosk',
  Web = 'Web',
}

export enum KioskType {
  Undefined = 'Undefined',
  CheckIn = 'CheckIn',
  FrontDesk = 'FrontDesk',
}

export const DisplayContext = React.createContext<{
  deviceType: DeviceType;
  kioskType?: KioskType;
}>({ deviceType: DeviceType.Web });

export const WebOnly: React.FC<any> = ({ children }) => {
  const { deviceType } = useContext(DisplayContext);

  if (deviceType === DeviceType.Web) return <>{children}</>;

  return null;
};

type KioskSwitch = {
  checkIn?: boolean | undefined;
  frontDesk?: boolean | undefined;
};

export const KioskOnly: React.FC<KioskSwitch> = ({ children, checkIn, frontDesk }) => {
  const { deviceType, kioskType } = useContext(DisplayContext);

  const anyKiosk = [checkIn, frontDesk].every(flag => flag === undefined);

  if (
    (deviceType === DeviceType.Kiosk && anyKiosk) ||
    (kioskType === KioskType.CheckIn && checkIn) ||
    (kioskType === KioskType.FrontDesk && frontDesk)
  )
    return <>{children}</>;

  return null;
};
