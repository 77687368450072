import React, { memo } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { GuestRelationTypeEnum } from '@lib/state';
import { EnumValueDisplay } from '.';

interface Props extends TypographyProps {
  relation?: GuestRelationTypeEnum;
  disableTypography?: boolean;
}

export const GuestRelationDisplay: React.FC<Props> = memo(
  ({ relation, disableTypography = false, ...props }) => {
    return disableTypography ? (
      <>
        <EnumValueDisplay value={relation} disableTypography={disableTypography} />
      </>
    ) : (
      <Typography {...props}>
        <EnumValueDisplay value={relation} disableTypography={disableTypography} />
      </Typography>
    );
  }
);
