import React, { useEffect, useMemo, useState } from 'react';
import { createStyles, Grid, makeStyles, MenuItem } from '@material-ui/core';

import {
  Reservation,
  propertyQuery,
  GuestProfile,
  getContactByType,
  ContactType,
} from '@lib/state';
import { useObservable, Section, FormSelect, useLookup } from '@lib/common';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import {
  corporateAccountQuery,
  corporateAccountService,
  CorporateDelegate,
  getCoporateDelegate,
  toGuestProfile,
} from 'app/state/corporate-account';
import { useCorporateAccountDelegates } from 'app/corporate-account/useCorporateAccountDelegates';

const useStyles = makeStyles(() =>
  createStyles({
    relative: {
      position: 'relative',
    },
  })
);

interface Props {
  reservation: Reservation;
  className?: string;
  onChangeBookingGuest: (guest?: GuestProfile, corporateAccountId?: string) => void;
}

export const ChangeDelegateLookupForm: React.FC<Props> = ({
  reservation,
  className,
  onChangeBookingGuest,
}) => {
  const { relative } = useStyles();
  const { setValue, watch } = useFormContext();
  const [bookingGuest, setBookingGuest] = useState<GuestProfile | undefined>(undefined);

  const { currentDelegate, currentGuest } = useMemo(() => {
    const options: {
      currentDelegate?: CorporateDelegate;
      currentGuest?: GuestProfile;
    } = {
      currentDelegate: undefined,
      currentGuest: undefined,
    };

    options.currentDelegate = getCoporateDelegate(reservation);

    const contact = getContactByType(reservation, ContactType.Guest);
    options.currentGuest = {
      userId: contact?.userId ?? '',
      name: contact?.name ?? {},
      contact: contact?.contact ?? {},
      address: contact?.address ?? {},
      sId: contact?.sId ?? '',
    };

    // if there is no delegate and it is a corporate booking, then the guest is the assumed "delegate"
    if (!options.currentDelegate && !!reservation?.affiliation?.corporateAccountId)
      options.currentDelegate = {
        userId: contact?.userId ?? '',
        name: contact?.name ?? {},
        corporateAccountId: reservation?.affiliation?.corporateAccountId,
        contact: contact?.contact ?? {},
      };

    return options;
  }, [reservation]);

  const corporateAccountId = watch('corporateAccountId', currentDelegate?.corporateAccountId ?? '');
  const delegateUserId = watch('delegateUserId', '');

  const customerId = useObservable(propertyQuery.activeProperty)?.customerId;

  const corporations = useObservable(corporateAccountQuery.corporationsWithDelegates);

  const delegates = useCorporateAccountDelegates(corporateAccountId);
  const delegateLookup = useLookup(delegates, x => x.userId);

  useEffect(() => {
    if (!!customerId) corporateAccountService.getAllCorporateAccounts(customerId, true);
  }, [customerId]);

  const { delegateOptions, defaultDelegate, defaultBookingGuest } = useMemo(() => {
    const options: {
      delegateOptions: Array<CorporateDelegate>;
      defaultDelegate: string;
      defaultBookingGuest?: GuestProfile;
    } = {
      delegateOptions: [],
      defaultDelegate: '',
      defaultBookingGuest: undefined,
    };

    // if no company is selected, then the default booking guest is set to the current guest
    if (!corporateAccountId) options.defaultBookingGuest = currentGuest;

    if (!delegates || delegates.length === 0) return options;

    function getCurrentDelegate() {
      const corporateId = delegates[0].corporateAccountId;

      // if the selected company matches the current delegate's, then select that delegate
      // if the delegate has since been removed, then set the default value to ''
      if (!!currentDelegate && currentDelegate.corporateAccountId === corporateId)
        return delegates.find(x => x.userId === currentDelegate?.userId);

      // otherwise the default delegate is the first delegate in the list
      return delegates[0];
    }

    const defaultDelegate = getCurrentDelegate();

    // set default delegate value to the first delegate in the list
    options.defaultDelegate = defaultDelegate?.userId ?? '';

    setValue('delegateUserId', options.defaultDelegate);
    // create a list of menu items, set the selected flag if the current delegate was found matches or if the index is 0
    options.delegateOptions = delegates;

    // set the default booking guest to the delegate profile
    options.defaultBookingGuest = toGuestProfile(defaultDelegate);

    return options;
  }, [delegates, currentDelegate, currentGuest, corporateAccountId, setValue]);

  //sets the delegate in the room rate query
  useEffect(() => {
    if (!onChangeBookingGuest) return;
    onChangeBookingGuest(bookingGuest, corporateAccountId);
  }, [onChangeBookingGuest, bookingGuest, corporateAccountId]);

  useEffect(() => {
    if (!defaultBookingGuest || !delegateLookup) return;

    const delegateProfile = delegateLookup.get(delegateUserId);

    // if no delegate is selected, then use the current guest
    const guest = !!delegateProfile ? toGuestProfile(delegateProfile) : defaultBookingGuest;

    setBookingGuest(guest);
    // eslint-disable-next-line
  }, [delegateUserId, defaultBookingGuest]);

  if (!corporations?.length) return null;

  return (
    <Section
      className={clsx(className, relative)}
      title="Change Corporate Account"
      maxWidth="md"
      variant="contained"
      data-testid="changeDelegateLookupForm"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormSelect
            name="corporateAccountId"
            label="Corporate Account"
            defaultValue={currentDelegate?.corporateAccountId ?? ''}
            fullWidth
            data-testid="changeCorporateAccountSelect"
          >
            <MenuItem value="">None</MenuItem>
            {corporations.map(x => (
              <MenuItem key={x.id} value={x.id}>
                {x.name}
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        {!!delegateOptions?.length && (
          <Grid item xs={12}>
            <FormSelect
              name="delegateUserId"
              label="Corporate Delegate"
              fullWidth
              defaultValue={defaultDelegate ?? ''}
              required
              data-testid="changeCorporateDelegateSelect"
            >
              {delegateOptions.map(x => (
                <MenuItem key={x.userId} value={x.userId}>
                  {x.name.first} {x.name.last}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
        )}
      </Grid>
    </Section>
  );
};
