import { GuestStaysState, GuestStaysStore, guestStaysStore } from './guest-stays.store';
import { QueryEntity } from '@datorama/akita';

export class GuestStaysQuery extends QueryEntity<GuestStaysState> {
  constructor(store: GuestStaysStore) {
    super(store);
  }

  guestStays = this.selectAll();

  activeGuestStay = this.selectActive();

  loading = this.selectLoading();
}

export const guestStaysQuery = new GuestStaysQuery(guestStaysStore);
