import { sessionService } from '@lib/state';
import { isFrontDesk, isKiosk } from 'app/shared/native-interface/nativebridge';

export function isFrontDeskOrPortal(): boolean {
  return isFrontDesk() || !isKiosk();
}

export function handleKioskLogout() {
  if (isFrontDeskOrPortal()) {
    sessionService.logoutAndResumePreviousSession();
  } else {
    sessionService.logout();
  }
}
