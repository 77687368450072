import { useMemo } from 'react';
import { useObservable } from '@lib/common';
import { roomsQuery } from '@lib/state';
import { guestStaysQuery } from 'app/state';

function filterHasValue(filterValue: string) {
  return !!filterValue && filterValue !== 'all' && filterValue.length > 0;
}

export function useTapeChartFilterCount() {
  const guestStaysFilters = useObservable(guestStaysQuery.guestStaysFilters);
  const roomsFilters = useObservable(roomsQuery.roomsFilters);

  return useMemo(() => {
    const guestStaysActiveFilterCount = Object.values(guestStaysFilters).filter(value =>
      filterHasValue(value)
    ).length;
    const roomsFiltersCount = Object.values(roomsFilters).filter(value => filterHasValue(value))
      .length;

    return guestStaysActiveFilterCount + roomsFiltersCount;
  }, [guestStaysFilters, roomsFilters]);
}
