import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import {
  formatPhoneNumber,
  GuestLookupFields,
  GuestLookupProfile,
  guestLookupQuery,
  guestLookupService,
  GuestProfile,
  mapToGuestProfile,
} from '@lib/state';
import { useObservable } from '../../utils';
import { NameDisplay } from '../../components';
import WorkIcon from '@material-ui/icons/Work';
import PersonIcon from '@material-ui/icons/Person';

interface Props {
  open?: boolean;
  loading?: boolean;
  anchorEl?: HTMLElement;
  matches: GuestLookupProfile[];
  onSelect(match: GuestLookupProfile, profile: GuestProfile): void;
}

export const GuestAutocompleteMenu: React.FC<Props> = ({
  open = false,
  anchorEl,
  matches,
  onSelect,
}) => {
  return (
    <Popper
      open={open && matches.length > 0}
      anchorEl={anchorEl}
      placement="bottom"
      style={{ width: anchorEl?.clientWidth }}
      keepMounted
    >
      <Paper>
        <List>
          {matches.map(m => (
            <ListItem key={m.key} button onMouseDown={() => onSelect(m, mapToGuestProfile(m))}>
              <ListItemAvatar>
                <Avatar>{!!m.employeeNumber ? <WorkIcon /> : <PersonIcon />}</Avatar>
              </ListItemAvatar>
              <Box>
                <NameDisplay {...m.name} directory />
                {getDetails(m).map((s, i) => (
                  <Typography key={i} variant="body2">
                    {s}
                  </Typography>
                ))}
              </Box>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Popper>
  );
};

function getDetails({ contact, employeeNumber }: GuestLookupProfile) {
  return [
    contact?.email,
    contact?.phone ? formatPhoneNumber(contact.phone) : null,
    employeeNumber,
  ].filter(s => !!s);
}

export function useGuestAutocomplete(form: GuestLookupFields) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [open, setOpen] = useState(false);
  const loading = useObservable(guestLookupQuery.loading);
  const matches = useObservable(guestLookupQuery.matches);

  useEffect(() => {
    // on mount and unmount, reset the store so that the next guest lookup does not have past state
    guestLookupService.reset();
    return () => guestLookupService.reset();
  }, []);

  useEffect(() => {
    if (open) guestLookupService.getMatches(form);
  }, [open, form]);

  return {
    open,
    setOpen,
    anchorEl,
    setAnchorEl,
    loading,
    matches,
    getInputProps() {
      return {
        onFocus: (e: React.FocusEvent<HTMLElement>) => {
          setAnchorEl(e.target);
          setOpen(true);
        },
        onBlur: () => setOpen(false),
      };
    },
  };
}
