import React from 'react';
import { Box, createStyles, makeStyles, Paper } from '@material-ui/core';

import { Section } from '@lib/common';

const useStyles = makeStyles(theme =>
  createStyles({
    section: {
      position: 'sticky',
      top: theme.tapeChart.headerSpacingTop,
      backgroundColor: theme.palette.background.default,
      zIndex: 5,
    },
  })
);

interface Props {
  children: NonNullable<React.ReactNode>;
}

export const FilterSection: React.FC<Props> = ({ children }) => {
  const { section } = useStyles();

  return (
    <Section className={section} disableGutters>
      <Paper elevation={2}>
        <Box padding={1}>{children}</Box>
      </Paper>
    </Section>
  );
};
