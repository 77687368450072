import { StoreConfig } from '@datorama/akita';
import { ReportType } from './report.model';
import { PaginationState, ResettableStore } from '@lib/state';
export interface ReportState {
  report: ReportType;
  pagination: PaginationState;
}

@StoreConfig({ name: 'report', resettable: true })
export class ReportStore extends ResettableStore<ReportState> {
  constructor() {
    super({});
  }
}

export const reportStore = new ReportStore();
