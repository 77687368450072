import { applyTransaction, setLoading } from '@datorama/akita';
import { from, combineLatest, of, defer } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccessControl, Properties, Reservations } from '../api';
import { join, map as mapCollection, poll, unsubscribeCallback } from '../utils';
import { Property } from '../property';
import { RoomsStore, roomsStore, RoomsFilters } from './rooms.store';
import { Room, RoomUpdate } from './rooms.model';
import { dispatchForm } from '../forms';
import { RoomForms } from './rooms.form';

export class RoomsService {
  constructor(
    private readonly store: RoomsStore,
    private readonly roomsApi: Properties.RoomsApi,
    private readonly reservationRoomsApi: Reservations.RoomsApi,
    private readonly propertyAccessApi: AccessControl.PropertyApi,
    private readonly roomThingApi: AccessControl.RoomThingApi
  ) {}

  getRooms(propertyId: Property['id']) {
    combineLatest([
      this.roomsApi.propertyIdRoomsGet(propertyId, undefined, 0),
      this.reservationRoomsApi.roomsGet(propertyId),
    ])
      .pipe(
        setLoading(this.store),
        map(([prop, res]) =>
          join(
            prop.data.data,
            res.data.data,
            x => x.id,
            x => x.roomId!
          )
        ),
        map(pairs => mapCollection(pairs, ([a, b]) => ({ ...a, ...b })))
      )
      .subscribe(rooms => this.store.upsertMany(Array.from(rooms)));
  }

  pollRooms(propertyId: Property['id'], interval = 30000) {
    const sub = defer(() =>
      combineLatest([
        this.roomsApi.propertyIdRoomsGet(propertyId, undefined, 0),
        this.reservationRoomsApi.roomsGet(propertyId),
      ])
    )
      .pipe(
        poll(interval),
        map(([propertyRoomsResponse, reservationRoomsResponse]) => [
          propertyRoomsResponse.data.data,
          reservationRoomsResponse.data.data,
        ])
      )
      .subscribe(([propertyRooms, reservationRooms]) =>
        applyTransaction(() => {
          const items = join<Properties.RoomModel, Reservations.RoomModel, string>(
            propertyRooms as Properties.RoomModel[],
            reservationRooms as Reservations.RoomModel[],
            x => x.id,
            x => x.roomId!
          );

          this.store.upsertMany(Array.from(items).map(([a, b]) => ({ ...a, ...b })));
        })
      );

    return unsubscribeCallback(sub);
  }

  // While changing rooms through tapechart, the rooms need to be updated in the store,
  // without setting the room as active. Thus, an active flag is explicitly set
  getRoomById(propertyId: Property['id'], roomId: string, setActive: boolean = false) {
    combineLatest([
      this.roomsApi.propertyIdRoomsRoomIdGet(propertyId, roomId),
      this.reservationRoomsApi.roomsRoomIdGet(roomId, propertyId),
    ])
      .pipe(
        map(([propertyRoomResponse, reservationRoomResponse]) => [
          propertyRoomResponse.data.data,
          reservationRoomResponse.data.data,
        ])
      )
      .subscribe(([propertyRoom, reservationRoom]) => {
        this.store.upsert(roomId, {
          ...propertyRoom,
          ...reservationRoom,
        });

        if (setActive) this.store.setActive(roomId);
      });
  }

  getRoomNumber(propertyId: Property['id'], roomId: string) {
    from(this.roomsApi.propertyIdRoomsRoomIdGet(propertyId, roomId)).subscribe(({ data }) => {
      this.store.upsert(roomId, {
        ...data.data,
      });
    });
  }

  getRoomDoorControllerStatus(room: Room) {
    from(
      this.propertyAccessApi.propertyPropertyIdRoomRoomNumberGet(room.propertyId, room.roomNumber)
    )
      .pipe(map(response => response.data))
      .subscribe(doorControllerStatus => this.store.upsert(room.id, { doorControllerStatus }));
  }

  getRoomDeviceDetails(room: Room) {
    from(this.roomThingApi.roomthingPropertyIdDeviceRoomNumberGet(room.propertyId, room.roomNumber))
      .pipe(map(data => data.data))
      .subscribe(doorController =>
        applyTransaction(() => this.store.upsert(room.id, { doorController }))
      );
  }

  updateRoom(room: Room, update: RoomUpdate) {
    const updates = [];

    updates.push(this.updateHouseKeepingStatus(room, update));
    updates.push(this.updateRoomAvailability(room, update));

    combineLatest(updates).subscribe(() => {
      this.store.update(room.id, update);

      // `propertyIdRoomsRoomIdHousekeepingPost()` returns the Room Model
      // from the property_api which lacks a few properties such as `readyForRent`.
      //
      // Fetch the latest data for this room so that we have an updated `readyForRent` value,
      // which will allow change rooms to work properly after updating a rooms' housekeeping status
      this.getRoomById(room.propertyId, room.id);
    });
  }

  markReadyForRent(room: Room, update: RoomUpdate) {
    const updates = [];

    updates.push(this.updateReadyForRent(room, update, true));
    updates.push(this.updateRoomAvailability(room, update));

    combineLatest(updates).subscribe(() => {
      this.store.update(room.id, update);
      this.getRoomById(room.propertyId, room.id);
    });
  }

  private updateRoomAvailability(room: Room, { availableStartDate, availableEndDate }: RoomUpdate) {
    if (
      (availableStartDate === undefined || availableStartDate === room.availableStartDate) &&
      (availableEndDate === undefined || availableEndDate === room.availableEndDate)
    )
      return of(undefined);

    return from(
      this.reservationRoomsApi.roomsIdAvailabilityPost(room.id, {
        startDate: availableStartDate,
        endDate: availableEndDate,
      })
    );
  }

  private updateHouseKeepingStatus(room: Room, { housekeepingStatus }: RoomUpdate) {
    if (housekeepingStatus === undefined) return of(undefined);

    return from(
      this.roomsApi.propertyIdRoomsRoomIdHousekeepingPost(room.propertyId, room.id, {
        housekeepingStatus,
      })
    );
  }

  private updateReadyForRent(
    room: Room,
    { housekeepingStatus }: RoomUpdate,
    readyForRentOverride: boolean
  ) {
    if (housekeepingStatus === undefined) return of(undefined);

    return from(
      this.roomsApi.propertyIdRoomsRoomIdHousekeepingPost(room.propertyId, room.id, {
        housekeepingStatus,
        readyForRentOverride,
      })
    );
  }

  unlockRoomDoor(propertyId: string, roomNumber: string) {
    from(this.roomThingApi.roomthingUnlockDoorPatch({ propertyId, roomNumber }))
      .pipe(dispatchForm(RoomForms.UnlockDoor))
      .subscribe();
  }

  updateRoomsFilters(filters: Partial<RoomsFilters>) {
    this.store.update(({ ui }) => {
      return {
        ui: {
          ...ui,
          ...filters,
        },
      };
    });
  }

  setActive(room?: Room) {
    this.store.setActive(room?.id ?? null);
  }

  reset() {
    this.store.setActive(null);
  }
}

export const roomsService = new RoomsService(
  roomsStore,
  new Properties.RoomsApi(),
  new Reservations.RoomsApi(),
  new AccessControl.PropertyApi(),
  new AccessControl.RoomThingApi()
);
