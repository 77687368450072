import { sessionQuery } from '@lib/state';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  MaintenanceTicketList,
  MaintenanceTicketFilters,
  MaintenanceTicketModal,
} from '../components';
import { usePageTitle, ResponsiveContainer, Section, useObservable } from '@lib/common';
import { AuditLogs } from 'app/shared';
import {
  maintenanceTicketQuery,
  maintenanceTicketService,
  PriorityOptions,
  TicketStatus,
  employeeQuery,
  employeeService,
  maintenanceTicketStore,
} from 'app/state';

export const MaintenanceTicketListPage: React.FC = () => {
  usePageTitle('Maintenance Tickets');
  const { ticketId } = useParams<any>();
  const [selectedTicketId, setSelectedTicketId] = useState<string | undefined>();
  const ticket = useObservable(maintenanceTicketQuery.selectedTicket, 'async');

  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState<undefined | Date>(undefined);
  const [endDate, setEndDate] = useState<undefined | Date>(undefined);
  const [status, setStatus] = useState<undefined | TicketStatus>(TicketStatus.Open);
  const [priority, setPriority] = useState<undefined | PriorityOptions>(undefined);
  const [assignedTo, setAssignedTo] = useState<undefined | string>(undefined);
  const [active, setActive] = useState(true);
  const [textFilter, setTextFilter] = useState<string>();
  const maintenanceTickets = useObservable(maintenanceTicketQuery.maintenanceTickets);
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const pagination = useObservable(maintenanceTicketQuery.pagination);
  const employees = useObservable(employeeQuery.maintenanceEligibleEmployees);
  useEffect(() => {
    if (!!ticketId) {
      setSelectedTicketId(ticketId);
      maintenanceTicketService.setActive(ticketId);
      setOpen(true);
    }
  }, [ticketId]);

  useEffect(() => {
    if (propertyId)
      maintenanceTicketService.getMaintenanceTickets(
        propertyId,
        startDate,
        endDate,
        status ?? undefined,
        assignedTo ?? undefined,
        priority ?? undefined,
        active,
        textFilter
      );
  }, [propertyId, startDate, endDate, status, assignedTo, priority, active, textFilter]);

  useEffect(() => {
    if (propertyId && selectedTicketId) {
      maintenanceTicketService.getMaintenanceTicketById(propertyId, selectedTicketId);
    }
  }, [propertyId, selectedTicketId]);

  const exportTickets = (
    from?: Date,
    to?: Date,
    status?: TicketStatus,
    priority?: PriorityOptions
  ) => {
    if (propertyId)
      maintenanceTicketService.exportMaintenanceTickets(
        propertyId,
        from,
        to,
        assignedTo ?? undefined,
        status ?? undefined,
        priority ?? undefined,
        active
      );
  };

  useEffect(() => {
    if (propertyId) employeeService.getEmployees(propertyId);
  }, [propertyId]);

  const loadMore = useCallback(() => {
    propertyId &&
      maintenanceTicketService.getMaintenanceTickets(
        propertyId,
        startDate,
        endDate,
        status,
        assignedTo,
        priority,
        active,
        textFilter,
        pagination.continuationToken
      );
  }, [
    propertyId,
    startDate,
    endDate,
    pagination,
    status,
    assignedTo,
    priority,
    active,
    textFilter,
  ]);

  if (!propertyId) return null;

  return (
    <ResponsiveContainer>
      <Section>
        <MaintenanceTicketFilters
          startDate={startDate}
          endDate={endDate}
          status={status}
          priority={priority}
          active={active}
          textFilter={textFilter}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setStatus={setStatus}
          setAssignedTo={setAssignedTo}
          setPriority={setPriority}
          onExport={exportTickets}
          setActive={() => setActive(!active)}
          setTextFilter={setTextFilter}
          onCreateTicket={() => {
            setSelectedTicketId(undefined);
            maintenanceTicketStore.setActive(null);
            setOpen(true);
          }}
          employees={employees}
          assignedTo={assignedTo}
        />

        <MaintenanceTicketList
          maintenanceTickets={maintenanceTickets}
          onSelect={id => {
            setSelectedTicketId(id);
            maintenanceTicketService.setActive(id);
            setOpen(true);
          }}
          loadMore={loadMore}
          isDone={pagination.isDone}
        />
        {open && (!selectedTicketId || (!!selectedTicketId && ticket)) && (
          <MaintenanceTicketModal
            propertyId={propertyId}
            open={open}
            onClose={() => {
              maintenanceTicketService.setActive();
              setSelectedTicketId(undefined);
              setOpen(false);
            }}
          />
        )}
      </Section>
      <Section title="Audit Logs" disableGutters>
        <AuditLogs scope="maintenance-tickets" id={propertyId} />
      </Section>
    </ResponsiveContainer>
  );
};
