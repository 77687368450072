import { Properties } from '@lib/state';
import { isAfter, isBefore, isWithinInterval, parseISO } from 'date-fns';

export type MaintenanceTicket = Properties.MaintenanceTicketModel;
export type MaintenanceTicketPhoto = Properties.MaintenanceTicketPhotoModel;

export type TicketStatus = Properties.MaintenanceTicketStatusEnum;
export const TicketStatus = Properties.MaintenanceTicketStatusEnum;

export type PriorityOptions = Properties.PriorityOptions;
export const PriorityOptions = Properties.PriorityOptions;

export function dateRangeFilter(maintenanceTicket: MaintenanceTicket, start?: Date, end?: Date) {
  const _end = getEndDate(end);
  if (start && _end)
    return isWithinInterval(parseISO(maintenanceTicket.timestamp), { start, end: _end });
  else if (start) return isAfter(parseISO(maintenanceTicket.timestamp), start);
  else if (_end) return isBefore(parseISO(maintenanceTicket.timestamp), _end);
  return true;
}
function getEndDate(end?: Date) {
  if (end) return new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1);
}
