import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles, TextField } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';

import { autoCompleteIfEnabled } from '../../utils';
import { FormTextFieldProps, TextFieldProps } from '../form-text-field';
import { useFieldError } from '../form-methods';
import { VerifiedIndicator } from './verified-indicator';

type Props = Omit<FormTextFieldProps, 'validationOptions' | 'type'> &
  TextFieldProps<'onChange'> & {
    verified?: boolean;
  };

export const FormPhoneField: React.FC<Props> = ({
  label = 'Phone Number',
  helperText,
  required,
  verified,
  defaultValue,
  name,
  InputProps,
  InputLabelProps,
  onFocus,
  onBlur,
  ...props
}) => {
  const { errors, watch } = useFormContext();
  const fieldError = useFieldError(name, errors);

  const [shrinkLabel, setShrinkLabel] = useState(InputLabelProps?.shrink ?? false);
  const currentValue = watch(name, defaultValue);

  useEffect(() => {
    // currentValue is what is displayed in the input
    // currently. If anything is displayed, the label
    // should shrink.
    setShrinkLabel(!!currentValue);
  }, [currentValue]);

  return (
    <Controller
      as={TextField}
      type="tel"
      label={InputLabelProps?.prefix ? `${InputLabelProps.prefix} ${label}` : label}
      name={name}
      defaultValue={defaultValue || null} // Empty string will cause label to overlap value
      onChange={([unformatted, country, event, formatted]) => {
        let newFormatted = formatted;

        if (!hasValue(newFormatted)) {
          // for initial blank inputs, do not display a +1 placeholder
          newFormatted = '';
        } else if (
          !!unformatted &&
          !!country?.dialCode &&
          !unformatted.startsWith(country.dialCode)
        ) {
          // if initial placeholder with +1 is missing, append dial code automatically
          newFormatted = country.dialCode + unformatted;
        }

        setShrinkLabel(hasValue(unformatted));

        return newFormatted;
      }}
      error={!!fieldError}
      rules={{
        required: required,
        validate: value =>
          !value || /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?(\d{3}[\s.-]?\d{4})$/.test(value),
      }}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: shrinkLabel,
      }}
      InputProps={{
        ...InputProps,
        onFocus,
        onBlur,
        startAdornment: <VerifiedIndicator name={name} value={defaultValue} verified={verified} />,
        inputComponent: WrappedPhoneInput,
        inputProps: {
          component: PhoneInput,
          country: 'us',
          onlyCountries: ['us'],
          disableDropdown: true,
          specialLabel: '',
          inputProps: {
            name: name,
            autoComplete: autoCompleteIfEnabled('tel'),
          },
        },
      }}
      {...props}
      data-testid="formPhoneInput"
    />
  );
};

function hasValue(value: string) {
  return !!value && value !== '+' && value !== '+1';
}

const usePhoneStyles = makeStyles({
  container: {
    width: '100%',
  },
});

const WrappedPhoneInput: React.FC<any> = ({
  component: Component,
  inputRef,
  className,
  onChange,
  ...props
}) => {
  const styles = usePhoneStyles();
  const ref = useRef<any>();

  useEffect(() => {
    const value = ref.current.numberInputRef.value;
    onChange(value, {}, {}, value);
  }, [ref.current]);

  useImperativeHandle(inputRef, () => ({
    focus: () => ref.current?.numberInputRef.focus(),
  }));

  return (
    <Component
      inputClass={className}
      containerClass={styles.container}
      ref={ref}
      onChange={onChange}
      {...props}
    />
  );
};
