import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Properties } from '../api';
import {
  HousekeepingAssignmentStore,
  housekeepingAssignmentStore,
  HousekeepingAssignmentUIState,
} from './house-keeping-assignment.store';
import { HousekeepingAssignmentForms } from './house-keeping-assignment.form';
import { dispatchForm } from '../forms';
import {
  HousekeepingAssignment,
  StartHousekeepingAssignmentRequest,
  mapToHousekeepingAssignmentModelList,
} from './house-keeping-assignment.model';
import { lightFormat, formatISO } from 'date-fns';
import { CompleteHousekeepingSurveyRequest } from '../api/generated/properties';
import { setLoading } from '@datorama/akita';

export class HousekeepingAssignmentService {
  constructor(
    private readonly store: HousekeepingAssignmentStore,
    private readonly housekeepingApi: Properties.HousekeepingApi
  ) {}

  loadHousekeepingAssignments(propertyId: string, date: Date): void {
    const d = lightFormat(date, 'yyyy-MM-dd');
    from(this.housekeepingApi.propertyIdHousekeepingAssignmentsGet(propertyId, d))
      .pipe(map(response => response.data.data))
      .pipe(map(list => mapToHousekeepingAssignmentModelList(list)))
      .subscribe(data => {
        this.updateUI({ date: d, propertyId });
        this.store.upsertMany(data);
      });
  }

  getHousekeepingAssignmentsForUser(propertyId: string) {
    from(this.housekeepingApi.propertyIdHousekeepingAssignmentsAssignedGet(propertyId))
      .pipe(
        map(data => data.data.data.assignedRooms ?? []),
        setLoading(this.store)
      )
      .pipe(map(list => mapToHousekeepingAssignmentModelList(list)))
      .subscribe(data => {
        this.store.upsertMany(data);
      });
  }

  getHousekeepingAssignmentForRoom(propertyId: string, roomId: string, date: Date) {
    const d = lightFormat(date, 'yyyy-MM-dd');

    this.updateUI({ date: d, propertyId, roomId });
    from(this.housekeepingApi.propertyIdHousekeepingAssignmentsRoomIdGet(propertyId, roomId, d))
      .pipe(map(response => response.data.data))
      .pipe(map(list => mapToHousekeepingAssignmentModelList(list)))
      .subscribe(data => {
        this.store.upsertMany(data);
      });
  }

  updateHousekeepingAssignment(
    propertyId: string,
    rooms: HousekeepingAssignment[],
    userId: string,
    date: Date,
    supervisorId?: string | undefined
  ): void {
    const d = lightFormat(date, 'yyyy-MM-dd');
    this.updateHousekeepingAssignmentStore(rooms, userId, supervisorId);
    from(
      this.housekeepingApi.propertyIdHousekeepingAssignmentsPost(propertyId, {
        date: d,
        housekeeperId: userId,
        supervisorId: supervisorId,
        roomIds: rooms.map(s => s.roomId),
      })
    )
      .pipe(
        dispatchForm(HousekeepingAssignmentForms.UpdateHousekeepingRoomAssignment),
        map(m => m.data.data)
      )
      .pipe(map(list => mapToHousekeepingAssignmentModelList(list)))
      .subscribe(assignments => this.overwriteStorePostUpdate(assignments, rooms));
  }

  completeHousekeepingAssignment(
    assignment: HousekeepingAssignment,
    survey: CompleteHousekeepingSurveyRequest
  ) {
    from(this.housekeepingApi.propertyIdHousekeepingCompletePost(assignment.propertyId, survey))
      .pipe(
        dispatchForm(HousekeepingAssignmentForms.UpdateHousekeepingRoomAssignment),
        switchMap(() =>
          from(
            this.housekeepingApi.propertyIdHousekeepingAssignmentsRoomIdGet(
              assignment.propertyId,
              assignment.roomId,
              assignment.date
            )
          )
            .pipe(map(response => response.data.data))
            .pipe(map(list => mapToHousekeepingAssignmentModelList(list)))
        )
      )
      .subscribe(data => {
        this.store.upsertMany(data);
      });
  }

  removeHousekeepingAssignment(
    propertyId: string,
    rooms: HousekeepingAssignment[],
    date: Date
  ): void {
    const d = lightFormat(date, 'yyyy-MM-dd');
    this.updateHousekeepingAssignmentStore(rooms);
    from(
      this.housekeepingApi.propertyIdHousekeepingAssignmentsDelete(propertyId, {
        date: d,
        roomIds: rooms.map(m => m.roomId),
      })
    )
      .pipe(dispatchForm(HousekeepingAssignmentForms.RemoveHousekeepingRoomAssignment))
      .subscribe();
  }

  startHousekeepingAssignemt(
    startAssignmentRequest: StartHousekeepingAssignmentRequest,
    propertyId: string
  ) {
    from(this.housekeepingApi.propertyIdHousekeepingStartPost(propertyId, startAssignmentRequest))
      .pipe(dispatchForm(HousekeepingAssignmentForms.StartHousekeepingRoomAssignment))
      .subscribe();
  }

  reset() {
    this.store.reset();
  }

  private updateHousekeepingAssignmentStore(
    assignments: HousekeepingAssignment[],
    housekeeperUserId?: string,
    supervisorUserId?: string
  ) {
    let ups = assignments.map(a => ({
      ...a,
      housekeeperUserId,
      supervisorUserId,
      assignedTime: !!housekeeperUserId ? formatISO(new Date()) : undefined,
      startTime: undefined,
    }));
    this.store.upsertMany(ups);
  }

  private overwriteStorePostUpdate(
    assignments: HousekeepingAssignment[],
    remove?: HousekeepingAssignment[]
  ) {
    if (remove?.length) {
      this.store.remove(remove.map(m => m.id));
    }
    this.store.upsertMany(assignments);
  }

  private updateUI(values: Partial<HousekeepingAssignmentUIState>) {
    this.store.update(({ ui }) => ({ ui: { ...ui, ...values } }));
  }
}

export const housekeepingAssignmentService = new HousekeepingAssignmentService(
  housekeepingAssignmentStore,
  new Properties.HousekeepingApi()
);
