import React, { useState, useCallback } from 'react';
import { makeStyles, createStyles, Box, Button } from '@material-ui/core';

import { Section } from '@lib/common';

import { RateRestriction } from 'app/state';
import { RateRestrictionList, CreateRateRestrictionModal } from '../components';

const useStyles = makeStyles(theme =>
  createStyles({
    buttonSpacing: {
      marginTop: '1em',
    },
  })
);

interface Props {
  rateRestrictions: Array<RateRestriction>;
  onDeleteRateRestriction: (rateRestriction: RateRestriction) => void;
}

export const RateRestrictionsSection: React.FC<Props> = ({
  rateRestrictions,
  onDeleteRateRestriction,
}) => {
  const styles = useStyles();

  const [openCreateRateRestrictionModal, setOpenCreateRateRestrictionModal] = useState(false);

  const onCloseCreateRateRestrictionModal = useCallback(() => {
    setOpenCreateRateRestrictionModal(false);
  }, []);

  const showCreateRateRestrictionModal = useCallback(() => {
    setOpenCreateRateRestrictionModal(true);
  }, []);

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        flexDirection="row"
        className={styles.buttonSpacing}
      >
        <Button color="secondary" variant="contained" onClick={showCreateRateRestrictionModal}>
          New Rate Restriction
        </Button>
      </Box>
      <Section title="Rate Restrictions" disableGutters>
        <RateRestrictionList
          rateRestrictions={rateRestrictions}
          onDeleteRateRestriction={onDeleteRateRestriction}
        />
      </Section>
      <CreateRateRestrictionModal
        open={openCreateRateRestrictionModal}
        onClose={onCloseCreateRateRestrictionModal}
      />
    </>
  );
};
