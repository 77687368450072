import { EntityStore, EntityState, StoreConfig, ActiveState } from '@datorama/akita';

import { RoomZone } from './room-zone.model';

export interface RoomZoneUIState {}

export interface RoomZoneState extends EntityState<RoomZone, RoomZone['id']>, ActiveState {
  ui: RoomZoneUIState;
}

@StoreConfig({ name: 'room-zones', resettable: true })
export class RoomZoneStore extends EntityStore<RoomZoneState> {
  constructor() {
    super({ active: null, ui: {} });
  }
}

export const roomZoneStore = new RoomZoneStore();
