import React from 'react';
import { FormNumberField, FormTimePicker, useFieldLabelProps, useFormPrefix } from '@lib/common';
import { Grid, TextFieldProps, makeStyles, Typography } from '@material-ui/core';

interface TimeThresholdProps {
  prefix?: string;
  prefixName?: string;
  label?: string;
  timeOfDay?: string;
  asDays?: boolean;
  gracePeriodInMinutes?: number;
  inputProps?: TextFieldProps;
  required?: boolean;
  isPrior?: boolean;
  gracePeriodDetailText?: string;
}

const useStyles = makeStyles({
  pTag: {
    marginTop: '0px',
    paddingTop: '1.5rem',
    marginBottom: '0px',
    fontSize: 14,
  },
});

export const TimeThresholdInput: React.FC<TimeThresholdProps> = ({
  prefix,
  prefixName,
  label,
  timeOfDay,
  asDays,
  gracePeriodInMinutes,
  inputProps,
  required = false,
  isPrior = true,
  gracePeriodDetailText = 'With a Grace Period of',
}) => {
  const fieldName = useFormPrefix(prefix);
  const timeOfDayProps = useFieldLabelProps(
    fieldName(`${prefixName}.timeOfDay`),
    timeOfDay,
    inputProps
  );
  const gracePeriodProps = useFieldLabelProps(
    fieldName(`${prefixName}.gracePeriodInMinutes`),
    timeOfDay,
    inputProps
  );

  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item md={2} xs={12}>
        <FormTimePicker
          disableToolbar
          defaultValue={timeOfDay}
          name={fieldName(`${prefixName}.timeOfDay`)}
          label={label}
          autoOk
          fullWidth
          InputLabelProps={timeOfDayProps}
          ampm={false}
          format="HH:mm"
          required={required}
          data-testid="timeThresholdTimePicker"
        />
      </Grid>
      <Grid item md={2} xs={12}>
        <Typography className={classes.pTag}>{gracePeriodDetailText}</Typography>
      </Grid>
      <Grid item md={2} xs={12}>
        <FormNumberField
          label={asDays ? 'Days' : 'Minutes'}
          fullWidth={false}
          inputProps={{ step: 1, min: 0 }}
          defaultValue={gracePeriodInMinutes}
          InputLabelProps={gracePeriodProps}
          name={fieldName(`${prefixName}.gracePeriodInMinutes`)}
          required={required}
          data-testid="timeThresholdGracePeriodInput"
        />
      </Grid>
      <Grid item md={2} xs={12}>
        <Typography className={classes.pTag}>
          {asDays ? 'Days' : 'Minutes'} {isPrior ? 'Prior' : 'After'}.{' '}
        </Typography>
      </Grid>
    </Grid>
  );
};
