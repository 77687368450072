import React, { useCallback } from 'react';
import { Container } from '@material-ui/core';

import {
  CustomerForms,
  customerService,
  FormStatus,
  getE164FormattedPhoneNumber,
} from '@lib/state';
import { FormHub, useFormEvents } from '@lib/common';
import { CustomerSignupFields, CustomerSignupForm } from '../components';
import { Redirect } from 'react-router';

export const CustomerSignupPage: React.FC = () => {
  const [{ status, error }, resetFormUI] = useFormEvents(CustomerForms.SignUp);

  const submit = useCallback(
    ({
      admin: { contact, name },
      password,
      customerName,
      propertyName,
      timezone,
      initialRoomType,
      initialRoomRate,
      numberOfRooms,
      address,
    }: CustomerSignupFields) => {
      resetFormUI();
      customerService.createCustomer(
        { ...contact, phone: getE164FormattedPhoneNumber(contact.phone) },
        password,
        name,
        customerName,
        propertyName,
        timezone,
        initialRoomType,
        initialRoomRate,
        address,
        numberOfRooms
      );
    },
    [resetFormUI]
  );

  if (status === FormStatus.Success) {
    return <Redirect to="/tape-chart" />;
  }

  return (
    <Container maxWidth="md">
      <FormHub onSubmit={submit}>
        <CustomerSignupForm status={status} error={error} variant="contained" />
      </FormHub>
    </Container>
  );
};
