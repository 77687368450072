import { useVipHousekeepingOverride } from 'app/property/hooks';
import { VipHousekeepingOverrideList } from './vip-housekeeping-override-list';
import React from 'react';
import { Section } from '@lib/common';
import { AuditLogs } from 'app/shared';

interface Props {
  propertyId: string;
}

export function VipGuestsTab({ propertyId }: Props) {
  const { overrides, loading } = useVipHousekeepingOverride(propertyId);

  return (
    <>
      <Section title="VIP Housekeeping Overrides">
        <VipHousekeepingOverrideList
          overrides={overrides}
          propertyId={propertyId}
          loading={loading}
        />
      </Section>
      <Section title="Audit Logs" disableGutters>
        <AuditLogs scope="vip-housekeeping-overrides" id={propertyId} />
      </Section>
    </>
  );
}
