import React, { useCallback, useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import {
  GuestProfile,
  GuestRelationForms,
  guestRelationQuery,
  guestRelationService,
  FormStatus,
} from '@lib/state';
import {
  ErrorDisplay,
  Feedback,
  FormHub,
  FormSubmitButton,
  Section,
  useFormEvents,
  useObservable,
} from '../..';
import { GuestRelationList } from './guest-relation-list.component';

interface Props {
  guest: GuestProfile;
  readOnly?: boolean;
}

export const GuestRelationsForm: React.FC<Props> = ({ guest, readOnly = false }) => {
  const [{ status, error }, reset] = useFormEvents(GuestRelationForms.UpdateRelations);
  const guestRelations = useObservable(guestRelationQuery.myHousehold);
  const otherHouseholds = useObservable(guestRelationQuery.otherHouseholds);
  useEffect(() => {
    guestRelationService.getGuestRelations(guest.userId);
  }, [guest.userId]);

  const submit = useCallback(
    ({ relations }: any) => {
      guestRelationService.updateGuestRelations(guest.userId, { relations });
    },
    [guest.userId]
  );

  return (
    <Section title="Guest Relationships" variant="contained">
      {!guestRelations?.length && !otherHouseholds?.length ? (
        <Typography>No Relationships with Other Guests</Typography>
      ) : (
        <FormHub onSubmit={submit}>
          <GuestRelationList
            relations={guestRelations}
            label={'My Household'}
            readOnly={readOnly}
          />
          <Box mt={1.5}>
            <GuestRelationList
              relations={otherHouseholds}
              label={'Households You Are A Member Of'}
              readOnly={true}
            />
          </Box>
          {!readOnly && !!guestRelations?.length && (
            <Box mt={1.5}>
              <Feedback show={status === FormStatus.Success} severity="success" onHide={reset}>
                Your guest relationships have been updated successfully.
              </Feedback>
              <ErrorDisplay error={error} />
              <Box display="flex" justifyContent="flex-end">
                <FormSubmitButton size="small" color="secondary" variant="contained">
                  Save
                </FormSubmitButton>
              </Box>
            </Box>
          )}
        </FormHub>
      )}
    </Section>
  );
};
