import React from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Grid, MenuItem, Checkbox, ListItemText } from '@material-ui/core';

import { propertyRoles } from '@lib/state';
import { FormTextField, useDebouncedEffect, FormSelect } from '@lib/common';
import { EmployeeFilters } from 'app/state';
import { FilterSection } from 'app/shared';

interface Props {
  filters: EmployeeFilters;
  onChange: (filters: EmployeeFilters) => void;
}

export const EmployeeSearchFilter: React.FC<Props> = ({ filters, onChange }) => {
  const form = useForm<EmployeeFilters>({
    defaultValues: filters,
  });

  const { firstName, lastName, roles } = form.watch({
    nest: true,
  });

  useDebouncedEffect(
    () => {
      onChange({
        firstName,
        lastName,
        roles,
      });
    },
    300,
    [firstName, lastName, roles]
  );

  return (
    <FilterSection>
      <FormContext {...form}>
        <Grid container spacing={1}>
          <Grid item lg={4} md={4} sm={6}>
            <FormTextField name="firstName" label="First Name" fullWidth size="small" />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <FormTextField name="lastName" label="Last Name" fullWidth size="small" />
          </Grid>
          <Grid item lg={4} md={4} sm={6}>
            <FormSelect
              defaultValue={roles}
              name="roles"
              label="User Roles"
              fullWidth
              multiple
              renderValue={(selected: any) => (selected as string[]).join(', ')}
              size="small"
            >
              {propertyRoles.map(x => (
                <MenuItem key={x} value={x}>
                  <Checkbox checked={roles.indexOf(x) > -1} />
                  <ListItemText primary={x} />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
        </Grid>
      </FormContext>
    </FilterSection>
  );
};
