import React, { useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { Container } from '@material-ui/core';

import { sessionQuery } from '@lib/state';
import { FormHub, useFormEvents, useObservable, usePageTitle } from '@lib/common';
import { AreaForms, areaQuery, areaService } from 'app/state';
import { BreadcrumbBackButton } from 'app/shared';
import { AreaForm, AreaFormFields } from '../components';

export const AddAreaPage: React.FC = () => {
  usePageTitle('Add Common Area');

  const propertyId = useObservable(sessionQuery.propertyId, 'async');

  const [{ status, error }, reset] = useFormEvents(AreaForms.AddArea);

  const area = useObservable(areaQuery.activeArea, 'async');

  const submit = useCallback(
    ({ name }: AreaFormFields) => {
      if (propertyId) areaService.createArea(propertyId, name);
    },
    [propertyId]
  );

  if (area != null) return <Redirect to={`/areas/${area.id}`} />;

  return (
    <Container maxWidth="lg">
      <BreadcrumbBackButton destination="/areas" />
      <FormHub onSubmit={submit}>
        <AreaForm status={status} error={error} resetFormUI={reset} />
      </FormHub>
    </Container>
  );
};
