import React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router-dom';

import { RoomAssignmentsPage, HousekeepingReportPage, MyAssignmentsPage } from './pages';

export const HousekeepingRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/assignments`} exact component={RoomAssignmentsPage} />
    <Route path={`${match.path}/report`} exact component={HousekeepingReportPage} />
    <Route path={`${match.path}/myAssignments`} exact component={MyAssignmentsPage} />
    <Route path={`${match.path}/myAssignments/:roomId`} component={MyAssignmentsPage} />
  </Switch>
);
