import React from 'react';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';

import { ReservationSearchPage, ReservationDetailsPage, AddReservationPage } from './pages';

export const ReservationsRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={match.path} exact component={ReservationSearchPage} />
    <Route path={`${match.path}/add`} component={AddReservationPage} />
    <Route path={`${match.path}/:reservationId`} component={ReservationDetailsPage} />
    <Redirect to={match.path} />
  </Switch>
);
