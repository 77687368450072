import { bindCallback } from 'rxjs';
import { AppConfig } from '../app.config';

interface Accept {
  dispatchData(data: AcceptData, callback: (response: AcceptResponse) => void): void;
}

interface AcceptData {
  authData: AcceptAuthData;
  cardData: AcceptCardData;
}

interface AcceptAuthData {
  clientKey?: string;
  apiLoginID?: string;
}

export interface AcceptCardData {
  cardNumber: string;
  month: string;
  year: string;
  cardCode: string;
}

export interface AcceptResponse {
  messages: AcceptResponseMessages;
  opaqueData: AcceptResponseData;
}

interface AcceptResponseMessages {
  resultCode: 'Error' | 'Ok';
  message: AcceptResponseMessage[];
}

interface AcceptResponseMessage {
  code: string;
  text: string;
}

// payment nonce
export interface AcceptResponseData {
  dataDescriptor: string;
  dataValue: string;
}

// Accept.js global variable
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

declare const Accept: Accept;

export function getPaymentNonce(card?: AcceptCardData): Observable<AcceptResponse | undefined> {
  if (!card) return of(undefined);
  const secureData = {
    authData: {
      clientKey: AppConfig.AuthorizeNet.ClientKey,
      apiLoginID: AppConfig.AuthorizeNet.LoginId,
    },
    cardData: card,
  };

  const getPaymentNonceObs = bindCallback(Accept.dispatchData);
  return getPaymentNonceObs(secureData).pipe(
    map(nonce => {
      if (nonce?.messages.resultCode === 'Error') {
        throw new Error(
          nonce.messages.message
            .map(m => m.text)
            .join(' ')
            .trim()
        );
      }
      return nonce;
    })
  );
}
