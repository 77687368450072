import React, { useMemo } from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Slide,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { bulkAvailableRateQuery, bulkAvailableService } from '@lib/state';
import { useObservable } from '../utils';
import { BulkChangeDatesButton } from './bulk-change-dates-button';
import { BulkCancelButton } from './bulk-cancel-button';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      position: 'fixed',
      bottom: 50,
      zIndex: 9999,
    },
    button: {
      marginTop: theme.spacing(1),
      '&:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
  })
);

export const BulkActionsSlide: React.FC = () => {
  const styles = useStyles();
  const bulkFilters = useObservable(bulkAvailableRateQuery.filters);

  const show = useMemo(() => {
    return (
      !!bulkFilters.propertyId &&
      !!bulkFilters.corporateAccountId &&
      !!bulkFilters.selectedReservations.length
    );
  }, [bulkFilters]);

  return (
    <Slide direction="up" in={show} mountOnEnter unmountOnExit>
      <div className={styles.container}>
        <Card square={false}>
          <CardContent>
            <Typography variant="h6" paragraph>
              Bulk Operations
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
                <BulkCancelButton
                  propertyId={bulkFilters.propertyId!}
                  corporateAccountId={bulkFilters.corporateAccountId!}
                  selectedReservations={bulkFilters.selectedReservations}
                  className={styles.button}
                  variant="contained"
                  color="secondary"
                  size="small"
                  disabled={bulkFilters.selectedReservations.length < 2}
                />
              </Grid>
              <Grid item>
                <BulkChangeDatesButton
                  propertyId={bulkFilters.propertyId!}
                  corporateAccountId={bulkFilters.corporateAccountId!}
                  selectedReservations={bulkFilters.selectedReservations}
                  className={styles.button}
                  variant="contained"
                  color="secondary"
                  size="small"
                  disabled={bulkFilters.selectedReservations.length < 2}
                />
              </Grid>
              <Grid item>
                <Button
                  className={styles.button}
                  size="small"
                  onClick={() => bulkAvailableService.resetUI()}
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </Slide>
  );
};
