import { QueryEntity } from '@datorama/akita';
import { EmployeeState, employeeStore, initialEmployeeFilters } from './employee.store';
import { switchMap, debounceTime, startWith } from 'rxjs/operators';
import { Maintenance, sortByName, Housekeeping } from '@lib/state';

export class EmployeeQuery extends QueryEntity<EmployeeState> {
  employees = this.select(({ ui }) => ui).pipe(
    switchMap(({ propertyId }) =>
      this.selectAll({
        filterBy: [
          x => propertyId == null || x.propertyRoles.some(r => r.propertyId === propertyId),
        ],
        sortBy: (a, b) => sortByName(a.name, b.name),
      })
    )
  );

  maintenanceEligibleEmployees = this.selectAll({
    filterBy: [x => x.propertyRoles.some(r => r.role === Housekeeping || r.role === Maintenance)],
    sortBy: (a, b) => sortByName(a.name, b.name),
  });

  selectedEmployee = this.selectActive();

  inviteExists = this.select(({ ui }) => ui.inviteExists);

  employeeSearchFilters = this.select(({ ui }) => ui.filters).pipe(
    debounceTime(1000),
    startWith(initialEmployeeFilters)
  );
}

export const employeeQuery = new EmployeeQuery(employeeStore);
