import React from 'react';

import { FormTextFieldProps, TextFieldProps } from '../form-text-field';
import { FormSelect } from '../form-select';
import { MenuItem } from '@material-ui/core';
import { VipStatus } from '@lib/state';
import { EnumValueDisplay } from '../../';

type Props = Omit<FormTextFieldProps, 'validationOptions' | 'type'> & TextFieldProps;

export const FormVipField: React.FC<Props> = ({
  label = 'VIP Status',
  defaultValue,
  required,
  ...props
}) => {
  return (
    <FormSelect {...props} label={label} defaultValue={defaultValue} fullWidth required={required}>
      <MenuItem key="" value={undefined}>
        None
      </MenuItem>
      {Object.values(VipStatus).map(x => (
        <MenuItem key={x} value={x}>
          <EnumValueDisplay value={x} />
        </MenuItem>
      ))}
    </FormSelect>
  );
};
