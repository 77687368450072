import { Store, StoreConfig } from '@datorama/akita';
import { GuestRelation } from './guest-relation.model';

export interface GuestRelationState {
  userId: string | null;
  relations?: GuestRelation[];
}

@StoreConfig({ name: 'guest-relation', resettable: true })
export class GuestRelationStore extends Store<GuestRelationState> {
  constructor() {
    super({ userId: null, relations: undefined });
  }
}

export const guestRelationStore = new GuestRelationStore();
