import React, { useMemo, useEffect } from 'react';
import { FormStatus, reservationQuery } from '@lib/state';
import {
  ReservationRouteProps,
  useClaimReservation,
  LoadingIndicator,
  useObservable,
  ErrorDisplay,
} from '@lib/common';
import { isFrontDeskOrPortal } from 'app/shared';

export const ClaimReservationPage: React.FC<ReservationRouteProps> = ({
  match,
  history,
  location,
}) => {
  const reservationId = match.params['reservationId'];

  const code = useMemo(() => {
    const query = new URLSearchParams(location.search);
    const value = query.get('code');
    return value ? parseInt(value) : 0;
  }, [location.search]);

  const claimGuests = useObservable(reservationQuery.claimGuests);

  const [status, error] = useClaimReservation(reservationId, code, claimGuests);

  useEffect(() => {
    if (status === FormStatus.Success) {
      if (isFrontDeskOrPortal())
        history.push(`/kiosk/front-desk/${reservationId}/check-in-options`);
      else history.push(`/kiosk/${reservationId}/check-in`);
    }
  }, [status, history, reservationId]);

  if (status === FormStatus.Error) {
    return <ErrorDisplay error={error} />;
  }

  return <LoadingIndicator loadingText="Claiming Reservation..." fillContainer />;
};
