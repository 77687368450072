import React from 'react';
import { FormStatus, FormEvent } from '@lib/state';
import { Box, Typography } from '@material-ui/core';
import { ErrorDisplay } from '../../forms';
import { Loading } from '../loading';
import { ButtonLink } from '../link';

interface Props {
  result: FormEvent;
}

export const VerifiedEmailConfirmation: React.FC<Props> = ({ result }) => {
  const formStatus = () => {
    switch (result.status) {
      case FormStatus.Success: {
        return (
          <>
            <Typography variant="subtitle1" align="center" color="textSecondary">
              Thank you! Your email is now verified.
            </Typography>
            <ButtonLink to="/account/signin" variant="contained" fullWidth color="primary">
              Continue
            </ButtonLink>
          </>
        );
      }
      case FormStatus.Error: {
        return (
          <>
            <ErrorDisplay error={result.error}>
              <Typography align="center">
                Sorry, we couldn't verify your email. Please try requesting a new verification link.
              </Typography>
            </ErrorDisplay>
            <ButtonLink to="/" variant="contained" fullWidth color="primary">
              Continue
            </ButtonLink>
          </>
        );
      }
      default:
        return <Loading />;
    }
  };

  return formStatus();
};
