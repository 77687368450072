import React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Divider, Grid, Typography, createStyles, makeStyles } from '@material-ui/core';
import {
  useObservable,
  useFormEvents,
  SignInForm,
  kioskStyles,
  ArmadilloHeaderDarkLogoSvg,
} from '@lib/common';
import { AccountForms, sessionService, sessionQuery, FormStatus } from '@lib/state';
import { TileLink } from 'app/shared';

const useStyles = makeStyles(
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    containedForm: {
      marginRight: '2em',
    },
    logo: {
      display: 'flex',
      flexDirection: 'column',
      height: '118px',
      width: '344x',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '18px',
      marginBottom: '18px',
      margin: 'auto',
    },
  })
);

export const FrontDeskLoginPage: React.FC = () => {
  const { root, logo, containedForm } = useStyles();
  const { primaryKioskButton, formInstructions } = kioskStyles();
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn);

  const [{ status, error }, resetForm] = useFormEvents(AccountForms.Login);

  if (isLoggedIn) {
    return <Redirect to="/tape-chart" />;
  }

  return (
    <Container maxWidth="md" className={root}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <img src={ArmadilloHeaderDarkLogoSvg} className={logo} alt="The Armadillo Hotel" />
        </Grid>
        <Grid item xs className={containedForm}>
          <Typography
            variant="h2"
            color="textPrimary"
            align="center"
            paragraph
            className={formInstructions}
          >
            Enter your account email and password to login
          </Typography>
          <SignInForm
            onSubmit={({ username, password }) => sessionService.login(username, password)}
            onResetFeedback={resetForm}
            usernameLabel="Email"
            pending={status === FormStatus.Pending}
            success={status === FormStatus.Success}
            error={error}
            buttonColor="primary"
          />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs>
          <TileLink
            to="/kiosk/front-desk/qr-code?next=/"
            variant="h1"
            icon={['far', 'qrcode']}
            iconProps={{ size: '6x' }}
            className={primaryKioskButton}
          >
            Scan QR Code
          </TileLink>
        </Grid>
      </Grid>
    </Container>
  );
};
