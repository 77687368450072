import React, { useState, useCallback } from 'react';
import { Button, Drawer, DrawerProps, makeStyles, createStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import { AppColors } from '../theme';

const useStyles = makeStyles(theme =>
  createStyles({
    drawer: {
      width: ({ width }: NavDrawerStyleProps) => width,
      flexShrink: 0,
    },
    drawerPaper: {
      width: ({ width }: NavDrawerStyleProps) => width,
      backgroundColor: ({ color = 'primary' }: NavDrawerStyleProps) => theme.palette[color].main,
      color: ({ color = 'primary' }: NavDrawerStyleProps) => theme.palette[color].contrastText,
    },
    drawerToggle: {
      alignSelf: 'stretch',
      boxShadow: 'none',
      borderRadius: '0',
      backgroundColor: AppColors.DarkBlue,
    },
  })
);

interface NavDrawerStyleProps {
  width?: number;
  color?: 'primary' | 'secondary';
}

interface NavDrawerProps extends NavDrawerStyleProps {
  icon: IconProp | ((open: boolean) => IconProp);
  anchor?: DrawerProps['anchor'];
  toggleDrawer: () => any;
  buttonClass?: string;
}

type Props = NavDrawerProps & Omit<DrawerProps, 'onClose' | 'classes'>;

export const NavDrawer: React.FC<Props> = ({
  width = 200,
  icon,
  color = 'primary',
  anchor,
  className,
  open,
  toggleDrawer,
  buttonClass,
  ModalProps = {},
  ...props
}) => {
  const classes = useStyles({ width, color });

  const getIcon = useCallback(
    (open: boolean): IconProp => {
      if (typeof icon === 'function') return icon(open);

      return icon;
    },
    [icon]
  );

  return (
    <>
      <Button
        className={clsx(classes.drawerToggle, buttonClass)}
        variant="contained"
        color={color}
        onClick={toggleDrawer}
        disableElevation
        data-testid="navHamburgerButton"
      >
        <FontAwesomeIcon icon={getIcon(!!open)} size="lg" fixedWidth />
      </Button>
      <Drawer
        variant="temporary"
        className={clsx(classes.drawer, className)}
        classes={{ paper: classes.drawerPaper }}
        open={open}
        anchor={anchor}
        ModalProps={{ BackdropProps: { invisible: true }, ...ModalProps }}
        onClose={toggleDrawer}
        disableScrollLock={true}
        {...props}
        data-testid="navDrawer"
      />
    </>
  );
};
