import { DataTable, EnumValueDisplay, IDataTableColumn, Section } from '@lib/common';
import { HousekeepingAssignment, groupBy, map } from '@lib/state';
import React, { useMemo } from 'react';
import { StartAssignmentButton } from './start-assignment-button';
import { Typography } from '@material-ui/core';

interface Props {
  assignments: Array<HousekeepingAssignment>;
  roomId?: string;
  propertyId: string;
}

export function MyAssignmentsList({ assignments, roomId, propertyId }: Props) {
  const myAssignments = useMemo(() => groupBy(assignments, x => x.roomZoneName), [assignments]);

  const roomAssignment = useMemo(() => assignments.find(r => r.roomId === roomId), [
    assignments,
    roomId,
  ]);

  const lists = useMemo(
    () =>
      Array.from(
        map(myAssignments, assignment => (
          <AssignmentsList
            zone={assignment.key}
            assignments={Array.from(assignment.values())}
            key={assignment.key}
          />
        ))
      ),
    [myAssignments]
  );

  return (
    <>
      {roomAssignment && (
        <StartAssignmentButton
          roomNumber={roomAssignment.roomNumber}
          assignmentDate={roomAssignment.assignedTime ?? ''}
          propertyId={propertyId}
        />
      )}
      {lists.length === 0 ? (
        <Typography align="center" style={{ marginTop: '1em', fontSize: '3em' }}>
          No current assignments
        </Typography>
      ) : (
        lists
      )}
    </>
  );
}

interface ListProps {
  zone: string;
  assignments: Array<HousekeepingAssignment>;
}

function AssignmentsList({ zone, assignments }: ListProps) {
  const columns: Array<IDataTableColumn<HousekeepingAssignment>> = useMemo(
    () => [
      {
        title: 'Room Number',
        valueFactory: assignment => assignment.roomNumber,
      },
      {
        title: 'Clean Requested',
        valueFactory: assignment => <EnumValueDisplay value={assignment.serviceRequested} />,
      },
      {
        title: 'Guests',
        valueFactory: assignment =>
          assignment.guestLastName?.length === 0 || !assignment.guestLastName
            ? '--'
            : assignment.guestLastName,
      },
    ],
    []
  );

  return (
    <Section title={zone}>
      <DataTable items={assignments} columns={columns} getItemKey={x => x.id ?? ''} />
    </Section>
  );
}
