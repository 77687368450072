// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { ActivityReportPaginatedListResponse } from '../models';
import { AuthorizedUserType } from '../models';
/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [createdByUserId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityExportGet(
      start?: string,
      end?: string,
      createdByUserId?: string,
      createdByUserType?: AuthorizedUserType,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/activity/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (start !== undefined) {
        localVarQueryParameter['start'] =
          (start as any) instanceof Date ? (start as any).toISOString() : start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] =
          (end as any) instanceof Date ? (end as any).toISOString() : end;
      }

      if (createdByUserId !== undefined) {
        localVarQueryParameter['createdByUserId'] = createdByUserId;
      }

      if (createdByUserType !== undefined) {
        localVarQueryParameter['createdByUserType'] = createdByUserType;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [recordNumber]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [createdByUserId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {string} [corporateAccountId]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityGet(
      recordNumber?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      start?: string,
      end?: string,
      createdByUserId?: string,
      createdByUserType?: AuthorizedUserType,
      corporateAccountId?: string,
      continuationToken?: string,
      limit?: number,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/activity`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (recordNumber !== undefined) {
        localVarQueryParameter['recordNumber'] = recordNumber;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['firstName'] = firstName;
      }

      if (lastName !== undefined) {
        localVarQueryParameter['lastName'] = lastName;
      }

      if (crew !== undefined) {
        localVarQueryParameter['crew'] = crew;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] =
          (start as any) instanceof Date ? (start as any).toISOString() : start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] =
          (end as any) instanceof Date ? (end as any).toISOString() : end;
      }

      if (createdByUserId !== undefined) {
        localVarQueryParameter['createdByUserId'] = createdByUserId;
      }

      if (createdByUserType !== undefined) {
        localVarQueryParameter['createdByUserType'] = createdByUserType;
      }

      if (corporateAccountId !== undefined) {
        localVarQueryParameter['corporateAccountId'] = corporateAccountId;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} userId
     * @param {string} [start]
     * @param {string} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityPropertyIdUserIdExportGet(
      propertyId: string,
      userId: string,
      start?: string,
      end?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling activityPropertyIdUserIdExportGet.'
        );
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling activityPropertyIdUserIdExportGet.'
        );
      }
      const localVarPath = `/activity/{propertyId}/{userId}/export`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (start !== undefined) {
        localVarQueryParameter['start'] =
          (start as any) instanceof Date ? (start as any).toISOString() : start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] =
          (end as any) instanceof Date ? (end as any).toISOString() : end;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} userId
     * @param {number} [recordNumber]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [createdByUserId]
     * @param {string} [corporateAccountId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityPropertyIdUserIdGet(
      propertyId: string,
      userId: string,
      recordNumber?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      start?: string,
      end?: string,
      createdByUserId?: string,
      corporateAccountId?: string,
      createdByUserType?: AuthorizedUserType,
      continuationToken?: string,
      limit?: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling activityPropertyIdUserIdGet.'
        );
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling activityPropertyIdUserIdGet.'
        );
      }
      const localVarPath = `/activity/{propertyId}/{userId}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (recordNumber !== undefined) {
        localVarQueryParameter['recordNumber'] = recordNumber;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['firstName'] = firstName;
      }

      if (lastName !== undefined) {
        localVarQueryParameter['lastName'] = lastName;
      }

      if (crew !== undefined) {
        localVarQueryParameter['crew'] = crew;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] =
          (start as any) instanceof Date ? (start as any).toISOString() : start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] =
          (end as any) instanceof Date ? (end as any).toISOString() : end;
      }

      if (createdByUserId !== undefined) {
        localVarQueryParameter['createdByUserId'] = createdByUserId;
      }

      if (corporateAccountId !== undefined) {
        localVarQueryParameter['corporateAccountId'] = corporateAccountId;
      }

      if (createdByUserType !== undefined) {
        localVarQueryParameter['createdByUserType'] = createdByUserType;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [createdByUserId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityExportGet(
      start?: string,
      end?: string,
      createdByUserId?: string,
      createdByUserType?: AuthorizedUserType,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = ActivityApiAxiosParamCreator(configuration).activityExportGet(
        start,
        end,
        createdByUserId,
        createdByUserType,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} [recordNumber]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [createdByUserId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {string} [corporateAccountId]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityGet(
      recordNumber?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      start?: string,
      end?: string,
      createdByUserId?: string,
      createdByUserType?: AuthorizedUserType,
      corporateAccountId?: string,
      continuationToken?: string,
      limit?: number,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<ActivityReportPaginatedListResponse> {
      const localVarAxiosArgs = ActivityApiAxiosParamCreator(configuration).activityGet(
        recordNumber,
        firstName,
        lastName,
        crew,
        start,
        end,
        createdByUserId,
        createdByUserType,
        corporateAccountId,
        continuationToken,
        limit,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} userId
     * @param {string} [start]
     * @param {string} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityPropertyIdUserIdExportGet(
      propertyId: string,
      userId: string,
      start?: string,
      end?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = ActivityApiAxiosParamCreator(
        configuration
      ).activityPropertyIdUserIdExportGet(propertyId, userId, start, end, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} userId
     * @param {number} [recordNumber]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [createdByUserId]
     * @param {string} [corporateAccountId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityPropertyIdUserIdGet(
      propertyId: string,
      userId: string,
      recordNumber?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      start?: string,
      end?: string,
      createdByUserId?: string,
      corporateAccountId?: string,
      createdByUserType?: AuthorizedUserType,
      continuationToken?: string,
      limit?: number,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<ActivityReportPaginatedListResponse> {
      const localVarAxiosArgs = ActivityApiAxiosParamCreator(
        configuration
      ).activityPropertyIdUserIdGet(
        propertyId,
        userId,
        recordNumber,
        firstName,
        lastName,
        crew,
        start,
        end,
        createdByUserId,
        corporateAccountId,
        createdByUserType,
        continuationToken,
        limit,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [createdByUserId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityExportGet(
      start?: string,
      end?: string,
      createdByUserId?: string,
      createdByUserType?: AuthorizedUserType,
      options?: any
    ): AxiosPromise<any> {
      return ActivityApiFp(configuration).activityExportGet(
        start,
        end,
        createdByUserId,
        createdByUserType,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} [recordNumber]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [createdByUserId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {string} [corporateAccountId]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityGet(
      recordNumber?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      start?: string,
      end?: string,
      createdByUserId?: string,
      createdByUserType?: AuthorizedUserType,
      corporateAccountId?: string,
      continuationToken?: string,
      limit?: number,
      options?: any
    ): AxiosPromise<ActivityReportPaginatedListResponse> {
      return ActivityApiFp(configuration).activityGet(
        recordNumber,
        firstName,
        lastName,
        crew,
        start,
        end,
        createdByUserId,
        createdByUserType,
        corporateAccountId,
        continuationToken,
        limit,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} userId
     * @param {string} [start]
     * @param {string} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityPropertyIdUserIdExportGet(
      propertyId: string,
      userId: string,
      start?: string,
      end?: string,
      options?: any
    ): AxiosPromise<any> {
      return ActivityApiFp(configuration).activityPropertyIdUserIdExportGet(
        propertyId,
        userId,
        start,
        end,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} userId
     * @param {number} [recordNumber]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [crew]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [createdByUserId]
     * @param {string} [corporateAccountId]
     * @param {AuthorizedUserType} [createdByUserType]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityPropertyIdUserIdGet(
      propertyId: string,
      userId: string,
      recordNumber?: number,
      firstName?: string,
      lastName?: string,
      crew?: string,
      start?: string,
      end?: string,
      createdByUserId?: string,
      corporateAccountId?: string,
      createdByUserType?: AuthorizedUserType,
      continuationToken?: string,
      limit?: number,
      options?: any
    ): AxiosPromise<ActivityReportPaginatedListResponse> {
      return ActivityApiFp(configuration).activityPropertyIdUserIdGet(
        propertyId,
        userId,
        recordNumber,
        firstName,
        lastName,
        crew,
        start,
        end,
        createdByUserId,
        corporateAccountId,
        createdByUserType,
        continuationToken,
        limit,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
  /**
   *
   * @param {string} [start]
   * @param {string} [end]
   * @param {string} [createdByUserId]
   * @param {AuthorizedUserType} [createdByUserType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityApi
   */
  public activityExportGet(
    start?: string,
    end?: string,
    createdByUserId?: string,
    createdByUserType?: AuthorizedUserType,
    options?: any
  ) {
    return ActivityApiFp(this.configuration).activityExportGet(
      start,
      end,
      createdByUserId,
      createdByUserType,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} [recordNumber]
   * @param {string} [firstName]
   * @param {string} [lastName]
   * @param {string} [crew]
   * @param {string} [start]
   * @param {string} [end]
   * @param {string} [createdByUserId]
   * @param {AuthorizedUserType} [createdByUserType]
   * @param {string} [corporateAccountId]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityApi
   */
  public activityGet(
    recordNumber?: number,
    firstName?: string,
    lastName?: string,
    crew?: string,
    start?: string,
    end?: string,
    createdByUserId?: string,
    createdByUserType?: AuthorizedUserType,
    corporateAccountId?: string,
    continuationToken?: string,
    limit?: number,
    options?: any
  ) {
    return ActivityApiFp(this.configuration).activityGet(
      recordNumber,
      firstName,
      lastName,
      crew,
      start,
      end,
      createdByUserId,
      createdByUserType,
      corporateAccountId,
      continuationToken,
      limit,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} userId
   * @param {string} [start]
   * @param {string} [end]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityApi
   */
  public activityPropertyIdUserIdExportGet(
    propertyId: string,
    userId: string,
    start?: string,
    end?: string,
    options?: any
  ) {
    return ActivityApiFp(this.configuration).activityPropertyIdUserIdExportGet(
      propertyId,
      userId,
      start,
      end,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} userId
   * @param {number} [recordNumber]
   * @param {string} [firstName]
   * @param {string} [lastName]
   * @param {string} [crew]
   * @param {string} [start]
   * @param {string} [end]
   * @param {string} [createdByUserId]
   * @param {string} [corporateAccountId]
   * @param {AuthorizedUserType} [createdByUserType]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityApi
   */
  public activityPropertyIdUserIdGet(
    propertyId: string,
    userId: string,
    recordNumber?: number,
    firstName?: string,
    lastName?: string,
    crew?: string,
    start?: string,
    end?: string,
    createdByUserId?: string,
    corporateAccountId?: string,
    createdByUserType?: AuthorizedUserType,
    continuationToken?: string,
    limit?: number,
    options?: any
  ) {
    return ActivityApiFp(this.configuration).activityPropertyIdUserIdGet(
      propertyId,
      userId,
      recordNumber,
      firstName,
      lastName,
      crew,
      start,
      end,
      createdByUserId,
      corporateAccountId,
      createdByUserType,
      continuationToken,
      limit,
      options
    )(this.axios, this.basePath);
  }
}
