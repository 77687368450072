import React from 'react';

import { FormTextFieldProps, TextFieldProps } from '../form-text-field';
import { FormSelect } from '../form-select';
import { MenuItem } from '@material-ui/core';

type Props = Omit<FormTextFieldProps, 'validationOptions' | 'type'> & TextFieldProps;

export const FormLanguageField: React.FC<Props> = ({
  label = 'Language',
  defaultValue,
  required,
  ...props
}) => {
  return (
    <FormSelect {...props} label={label} defaultValue={defaultValue} fullWidth required={required}>
      {Languages.map(x => (
        <MenuItem key={x.value} value={x.value}>
          {x.label}
        </MenuItem>
      ))}
    </FormSelect>
  );
};

export const Languages = [
  { value: 'en-US', label: 'English' },
  { value: 'es-ES', label: 'Spanish' },
];
