import React, { useCallback, useEffect } from 'react';
import { CorporateAccount, HousekeepingPolicy, PolicyForm } from '@lib/state';
import { useFormEvents } from '@lib/common';
import { CorporateAccountForms, corporateAccountService } from 'app/state';
import { CorporatePolicyForm } from './corporate-policy-form';
import { HousekeepingPolicyForm } from '../../shared';

interface Props {
  account?: CorporateAccount;
}

export const CorporateHousekeepingPolicy: React.FC<Props> = ({ account }) => {
  const [updateEvent, reset] = useFormEvents(CorporateAccountForms.UpdateHousekeeping);
  const [removeEvent, resetRemoveStatus] = useFormEvents(CorporateAccountForms.RemoveHousekeeping);
  const [addEvent, resetAddStatus] = useFormEvents(CorporateAccountForms.AddHousekeeping);

  useEffect(() => {
    resetRemoveStatus();
    resetAddStatus();
  }, [account, resetRemoveStatus, resetAddStatus]);

  const addOverride = useCallback(() => {
    if (account) corporateAccountService.addHousekeepingOverride(account);
  }, [account]);

  const updateOverride = useCallback(
    (policy: PolicyForm) => {
      if (account) {
        corporateAccountService.updateHousekeepingPolicy(account, policy as HousekeepingPolicy);
      }
    },
    [account]
  );

  const removeOverride = useCallback(() => {
    if (account) corporateAccountService.removeHousekeepingPolicy(account);
  }, [account]);

  return (
    <CorporatePolicyForm
      policyType={{ policy: account?.housekeeping, title: 'Housekeeping' }}
      addOverride={addOverride}
      updateOverride={updateOverride}
      removeOverride={removeOverride}
      reset={reset}
      updateEvent={updateEvent}
      removeEvent={removeEvent}
      addEvent={addEvent}
    >
      <HousekeepingPolicyForm policy={account?.housekeeping} />
    </CorporatePolicyForm>
  );
};
