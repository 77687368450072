import React from 'react';
import {
  responsiveFontSizes,
  createTheme,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { AlertProps } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

declare module '@material-ui/core/styles/props' {
  interface ComponentsPropsList {
    MuiAlert: AlertProps;
  }
}

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    tapeChart: {
      headerSpacingTop: string;
      rowHeight: number;
    };
    loadingIndicator: {
      dotSize: number;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    tapeChart?: {
      headerSpacingTop?: string;
      rowHeight?: number;
    };
    loadingIndicator?: {
      dotSize?: number;
    };
  }
}

export enum AppColors {
  LightBlue = '#00C1FF',
  Blue = '#054584',
  MediumBlue = '#133C65',
  DarkBlue = '#12263A',
  Red = '#BF2F2F',
  LighterGray = '#EFEFEF',
  LightGray = '#DEDEDE',
  Gray = '#828282',
  MediumGray = '#707070',
  DarkGray = '#12263A',
  White = '#FFFFFF',
  HeaderDivider = 'rgba(5,69,132,0.8)',
  RowDivider = 'rgba(5,69,132,0.4)',
  DarkGreen = '#228550',
  Green = '#2FBF71',
  TransparentDarkBlue = 'rgba(18,38,58,0.8)',
  TransparentDarkGreen = 'rgba(34,133,80,0.8)',
  TransparentRed = 'rgba(191,47,47,0.8)',
  DisabledHeaderItem = 'rgba(0,193,255,0.4)',
}

function responsiveTheme(theme: Theme): Theme {
  theme.overrides = {
    ...theme.overrides,
    MuiInputBase: {
      ...theme.overrides?.MuiInputBase,
      root: {},
    },
    MuiInputLabel: {
      ...theme.overrides?.MuiInputLabel,
      root: {},
    },
  };

  return responsiveFontSizes(theme);
}

export const guestServicesTheme = responsiveTheme(
  createTheme({
    palette: {
      background: {
        default: common.white,
        paper: common.white,
      },
      primary: {
        main: AppColors.Blue,
        dark: AppColors.DarkBlue,
        contrastText: common.white,
      },
      secondary: {
        main: AppColors.LightBlue,
        contrastText: common.white,
      },
      grey: {
        '300': AppColors.LightGray,
        '500': AppColors.Gray,
        '800': AppColors.DarkGray,
      },
      text: {
        secondary: AppColors.DarkGray,
      },
      action: {
        disabledBackground: AppColors.LightGray,
      },
    },
    tapeChart: {
      headerSpacingTop: '2rem',
      rowHeight: 48,
    },
    loadingIndicator: {
      dotSize: 10,
    },
    typography: {
      fontFamily: 'libre-franklin, sans-serif',
      h1: {
        fontWeight: 700,
      },
      h2: {
        fontWeight: 700,
      },
      h3: {
        fontWeight: 700,
      },
      h6: {
        fontWeight: 700,
      },
      subtitle1: {
        fontWeight: 700,
      },
      subtitle2: {
        fontWeight: 700,
      },
      button: {
        fontWeight: 700,
      },
      overline: {
        letterSpacing: 'normal',
      },
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
    props: {
      MuiPaper: {
        square: true,
      },
      MuiAlert: {
        iconMapping: {
          success: <FontAwesomeIcon icon="check-circle" />,
          info: <FontAwesomeIcon icon="info-circle" />,
          warning: <FontAwesomeIcon icon="exclamation-circle" />,
          error: <FontAwesomeIcon icon="times-circle" />,
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1140,
        xl: 1920,
      },
    },
    zIndex: {
      mobileStepper: 1000,
      speedDial: 1050,
      appBar: 1299,
      drawer: 1298,
      modal: 1300,
      snackbar: 1400,
      tooltip: 1500,
    },
    overrides: {
      MuiContainer: {
        root: {
          paddingLeft: '0.75rem',
          paddingRight: '0.75rem',
          '@media (min-width:600px)': {
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
          },
          '@media (min-width:960px)': {
            paddingLeft: '0',
            paddingRight: '0',
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          color: common.white,
          border: `1px solid ${common.white}`,
          borderRadius: '6px',
          '&::placeholder': {
            color: common.white,
          },
        },
      },
      MuiInputLabel: {
        outlined: {
          color: common.white,
        },
      },
      MuiInput: {
        underline: {
          '&.Mui-disabled:before': {
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgba(148,148,148,0.5)',
          },
        },
      },
      MuiButton: {
        outlinedSecondary: {
          color: AppColors.Red,
          backgroundColor: 'transparent',
          borderColor: AppColors.Red,
          '&:hover': {
            borderColor: AppColors.Red + ' !important',
            backgroundColor: 'rgba(191,47,47,0.06) !important',
          },
        },
      },
    },
  })
);

export const guestServicesDarkTheme = responsiveTheme(
  createTheme({
    palette: {
      type: 'dark',
      background: {
        default: AppColors.DarkBlue,
        paper: AppColors.DarkBlue,
      },
      primary: {
        main: common.white,
        contrastText: AppColors.DarkBlue,
      },
      secondary: {
        main: AppColors.LightBlue,
        contrastText: AppColors.DarkBlue,
      },
      text: {
        primary: common.white,
        secondary: common.white,
      },
    },
    typography: {
      fontFamily: 'libre-franklin, sans-serif',
      subtitle1: {
        fontWeight: 700,
      },
      subtitle2: {
        fontWeight: 700,
      },
      button: {
        fontWeight: 700,
      },
      overline: {
        letterSpacing: 'normal',
      },
    },
    overrides: {
      MuiSelect: {
        icon: {
          color: AppColors.LightBlue,
        },
      },
    },
  })
);

export const webStyles = makeStyles(theme =>
  createStyles({
    listContentBlock: {
      margin: '2em 0 1em !important',
    },
  })
);
