import React, { useEffect } from 'react';

import { propertyService } from '@lib/state';

interface Props {
  customerCode?: string;
}

export const ResourceLoader: React.FC<Props> = ({ customerCode, children }) => {
  useEffect(() => {
    propertyService.loadProperties(customerCode);
  }, [customerCode]);

  return <>{children}</>;
};
