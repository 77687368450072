import React, { useEffect } from 'react';
import { Container, Box } from '@material-ui/core';
import {
  Admin,
  FormStatus,
  Manager,
  propertyQuery,
  roomTypeQuery,
  roomTypeService,
  inventoryAdjustmentQuery,
  inventoryAdjustmentService,
  InventoryAdjustmentForms,
} from '@lib/state';
import {
  useObservable,
  FormHub,
  Section,
  useFormEvents,
  usePageTitle,
  FormSubmitButton,
  Loading,
  ErrorDisplay,
  Feedback,
  useHasRole,
} from '@lib/common';
import { corporateAccountQuery, corporateAccountService } from 'app/state';
import { InventoryAdjustmentsForm } from '../components';
import { Alert } from '@material-ui/lab';
import { AuditLogs } from 'app/shared';
import {
  InventoryAdjustmentGroupModel,
  InventoryAdjustmentModel,
  UpdateInventoryAdjustmentRequest,
} from '@lib/state/api/generated/reservations';

export interface InventoryAdjustmentField {
  roomTypeId: string;
  quantity: number;
}
export interface DateGroupFields {
  date: string;
  propertyAdjustments: InventoryAdjustmentField[];
  corporateAdjustments: CorporateGroupFields[];
}
export interface CorporateGroupFields {
  corporateAccountId: string;
  inventoryAdjustments: InventoryAdjustmentField[];
}

export interface InventoryAdjustmentFormFields {
  groupAdjustments: DateGroupFields[];
}

export const ManageInventoryAdjustmentsPage: React.FC = () => {
  const canManageAdjustments = useHasRole(Admin, Manager);

  usePageTitle('Manage Room Inventory Adjustments');

  const property = useObservable(propertyQuery.activeProperty, 'async');

  const inventoryAdjustmentDateGroups = useObservable(
    inventoryAdjustmentQuery.inventoryAdjustmentDateGroups
  );

  const roomTypes = useObservable(roomTypeQuery.propertyRoomTypes, 'async');
  const corporateAccounts = useObservable(corporateAccountQuery.corporations, 'async');

  useEffect(() => {
    if (property) {
      inventoryAdjustmentService.getInventoryAdjustments(property.id);
      roomTypeService.getPropertyRoomTypes(property.id);
      corporateAccountService.getAllCorporateAccounts(property.customerId);
    }
  }, [property]);

  const [{ status, error }, reset] = useFormEvents(InventoryAdjustmentForms.Update);

  if (!property || !inventoryAdjustmentDateGroups.length || !roomTypes || !corporateAccounts)
    return <Loading />;

  const onSubmit = (values: InventoryAdjustmentFormFields) => {
    const request: UpdateInventoryAdjustmentRequest = {
      propertyId: property.id,
      adjustments: values.groupAdjustments.map(dateGroup => ({
        date: dateGroup.date,
        propertyAdjustments: dateGroup.propertyAdjustments.map(propAdj => ({
          roomTypeId: propAdj.roomTypeId,
          quantity: propAdj.quantity,
        })),
        corporateAdjustments: dateGroup.corporateAdjustments?.map(corpGroup => ({
          corporateAccountId: corpGroup.corporateAccountId,
          corporateAdjustments: corpGroup.inventoryAdjustments.map(corpAdj => ({
            roomTypeId: corpAdj.roomTypeId,
            quantity: corpAdj.quantity,
          })),
        })),
      })),
    };
    inventoryAdjustmentService.updateInventoryAdjustments(property.id, request);
  };

  return (
    <Container maxWidth="lg">
      <Section
        title="Manage Inventory Adjustments"
        textAlign="left"
        disableGutters
        banner={
          <Alert severity="info">
            A positive Inventory Adjustment amount will allow oversells by adding inventory, whereas
            entering a negative amount will hold rooms as unavailable.
            <br />
            Room Holds may be added which will also hold rooms as unavailable except for the
            specified Corporate Account.
          </Alert>
        }
      >
        <FormHub<InventoryAdjustmentFormFields>
          onSubmit={onSubmit}
          formOptions={{
            defaultValues: getDefaultValue(inventoryAdjustmentDateGroups),
          }}
        >
          <InventoryAdjustmentsForm
            corporateAccounts={corporateAccounts}
            roomTypes={roomTypes}
            canManageAdjustments={canManageAdjustments}
          />
          <Box py={1}>
            <Feedback show={status === FormStatus.Success} severity="success" onHide={reset}>
              Inventory adjustments saved successfully!
            </Feedback>
            <ErrorDisplay error={error} />
          </Box>
          {canManageAdjustments && (
            <FormSubmitButton
              size="large"
              color="secondary"
              variant="contained"
              disabled={status === FormStatus.Pending}
            >
              Save
            </FormSubmitButton>
          )}
        </FormHub>
      </Section>
      <Section title="Audit Logs" disableGutters>
        <AuditLogs scope="inventory-adjustments" id={property.id} />
      </Section>
    </Container>
  );
};

function getDefaultValue(
  inventoryAdjustmentDateGroups: InventoryAdjustmentGroupModel[]
): InventoryAdjustmentFormFields {
  return {
    groupAdjustments: inventoryAdjustmentDateGroups.map(dateGroup => {
      return {
        date: dateGroup.date,
        propertyAdjustments: [...dateGroup.propertyAdjustments]
          .sort((x: InventoryAdjustmentModel, y: InventoryAdjustmentModel) =>
            x.roomTypeId.localeCompare(y.roomTypeId, undefined, { sensitivity: 'base' })
          )
          .map(propAdj => {
            return {
              quantity: propAdj.quantity,
              roomTypeId: propAdj.roomTypeId,
            };
          }),
        corporateAdjustments: dateGroup.corporateInventories.map(corpAdj => ({
          corporateAccountId: corpAdj.corporateAccountId,
          inventoryAdjustments: [...corpAdj.inventories]
            .sort((x: InventoryAdjustmentModel, y: InventoryAdjustmentModel) =>
              x.roomTypeId.localeCompare(y.roomTypeId, undefined, { sensitivity: 'base' })
            )
            .map(inv => ({
              quantity: inv.quantity,
              roomTypeId: inv.roomTypeId,
            })),
        })),
      };
    }),
  };
}
