import React, { memo } from 'react';
import { createStyles, Link, makeStyles, Typography, TypographyProps } from '@material-ui/core';
import { KioskOnly, WebOnly } from '../device-display';
import clsx from 'clsx';

const useStyles = makeStyles(theme =>
  createStyles({
    email: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '@media print': {
        color: '#000000',
      },
    },
  })
);

interface Props extends TypographyProps {
  email?: string | null;
  alternateEmail?: string | null;
}

export const EmailDisplay: React.FC<Props> = memo(
  ({ email, alternateEmail, className, ...props }) => {
    const styles = useStyles({});

    return (
      <>
        <WebOnly>
          <Link
            href={`mailto:${email ?? alternateEmail}`}
            underline="none"
            {...props}
            className={clsx(styles.email, className)}
            data-testid="emailDisplay"
          >
            {email ?? alternateEmail ?? 'N/A'}
          </Link>
        </WebOnly>
        <KioskOnly>
          <Typography
            className={clsx(styles.email, className)}
            {...props}
            data-testid="emailDisplay"
          >
            {email ?? alternateEmail ?? 'N/A'}
          </Typography>
        </KioskOnly>
      </>
    );
  }
);
