import { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';

import { sessionQuery, sessionService } from '../../session';

declare module 'axios' {
  interface AxiosRequestConfig {
    tokenRefreshed?: boolean;
  }
}

export function withAuthentication(client: AxiosInstance) {
  // OnRequest: add the Authorization header with Bearer token
  client.interceptors.request.use(async request => {
    // only set the authorization header if it's not already provided
    // .. refresh of userInfo sets it explicitly
    if (request.headers['Authorization']) return request;

    const token = getAuthToken();
    if (token == null) return request;

    request.headers['Authorization'] = `Bearer ${token.accessToken}`;

    if (request.tokenRefreshed === undefined) request.tokenRefreshed = false;

    return request;
  });

  // OnResponse: handle 401 authorized responses by attempting token refresh
  client.interceptors.response.use(undefined, async error => {
    // check if this is an auth error
    // .. if not, or if we already tried a refresh, then fail
    if (!isAuthError(error) || error.config.tokenRefreshed) throw error;

    // try to get our refresh token
    // .. if we don't have one, then fail
    const token = getAuthToken();
    if (token?.refreshToken == null) throw error;

    // prevent endlessly refreshing the token if something goes wrong
    // .. so set a flag for our one an only attempt
    error.config.tokenRefreshed = true;

    // attempt to refresh the token
    // .. if unsuccessful, then fail
    const refreshed = await sessionService.refreshToken(token);
    if (!refreshed) throw error;

    // remove the prior token from the request
    // .. it's now invalid
    delete error.config.headers['Authorization'];

    // retry the request
    return await client(error.config);
  });

  return client;
}

function getAuthToken() {
  const auth = sessionQuery.getValue().auth;

  if (!auth.authenticated) return null;

  return auth.token;
}

interface AuthError {
  config: AxiosRequestConfig;
  response: AxiosResponse;
}

function isAuthError(error: any): error is AuthError {
  const err = error as AuthError;
  return err.config !== undefined && err.response?.status === 401;
}
