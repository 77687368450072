import { ErrorDisplay, FormHub, Section, SubmitButton } from '@lib/common';
import { FormStatus, HousekeepingPolicy, VipHousekeepingOverride } from '@lib/state';
import { HousekeepingPolicyForm } from 'app/shared';
import React from 'react';

interface Props {
  override?: VipHousekeepingOverride;
  formStatus: FormStatus;
  onSubmit: (request: HousekeepingPolicy) => void;
  error?: Error;
}

export function VipHousekeepingOverrideForm({ override, onSubmit, formStatus, error }: Props) {
  return (
    <Section>
      <FormHub onSubmit={onSubmit}>
        <HousekeepingPolicyForm policy={override?.housekeepingPolicy} />
        <ErrorDisplay error={error} />
        <SubmitButton
          variant="contained"
          color="secondary"
          fullWidth
          disabled={formStatus !== FormStatus.Initial}
          pending={formStatus === FormStatus.Pending}
        >
          Submit
        </SubmitButton>
      </FormHub>
    </Section>
  );
}
