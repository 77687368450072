import React from 'react';
import { FormLabel, Grid } from '@material-ui/core';
import { Gender, Reservations, VipStatus } from '@lib/state';
import {
  FormCheckbox,
  FormGenderField,
  FormLanguageField,
  FormVipField,
  TextFieldProps,
} from '../../forms';
import { NameForm } from './name.form';
import { AddressForm } from './address.form';
import { GuestSIdDisplay } from '../display/guest-sid-display';

interface Props {
  prefix?: string;
  billing?: boolean;
  name?: Reservations.Name;
  address?: Reservations.Address;
  gender?: Gender | null;
  language?: string | null;
  archived?: boolean;
  optional?: boolean;
  inputProps?: TextFieldProps;
  allowNameChange?: boolean;
  disablePersonalDetails?: boolean;
  showArchived?: boolean;
  isManagementPortal?: boolean;
  sId?: string | null;
  vipStatus?: VipStatus;
}

export const GuestInformationForm: React.FC<Props> = ({
  prefix,
  billing,
  name,
  address,
  optional,
  gender,
  language,
  disablePersonalDetails,
  allowNameChange = true,
  archived = false,
  showArchived = true,
  isManagementPortal = false,
  sId,
  vipStatus,
  ...props
}) => {
  const inputProps = props.inputProps;
  return (
    <>
      <NameForm
        name={name}
        prefix={prefix}
        billing={billing}
        disabled={!allowNameChange && !!(name?.first && name?.last)}
        optional={optional}
        {...props}
      />
      {!disablePersonalDetails && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <FormGenderField
              {...inputProps}
              name={'gender'}
              prefix={prefix}
              required={false}
              fullWidth
              defaultValue={gender}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLanguageField
              {...inputProps}
              name={'language'}
              prefix={prefix}
              required={false}
              fullWidth
              defaultValue={language}
            />
          </Grid>
        </Grid>
      )}
      <AddressForm prefix={prefix} billing={billing} address={address} optional={true} {...props} />
      <Grid container spacing={1}>
        {isManagementPortal && (
          <Grid item xs={3}>
            <FormVipField
              {...inputProps}
              name={'vipStatus'}
              prefix={prefix}
              required={false}
              fullWidth
              defaultValue={vipStatus}
            />
          </Grid>
        )}
        {showArchived && (
          <>
            <Grid item xs={3} style={{ paddingTop: '10px' }}>
              <FormLabel>Guest SID</FormLabel>
              <GuestSIdDisplay val={sId} display="block" />
            </Grid>
            <Grid item xs={3}>
              <FormCheckbox
                name="archived"
                prefix={prefix}
                label="Archived"
                defaultChecked={archived}
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
