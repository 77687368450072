import { RebookButton } from '@lib/common';
import { Reservation, ReservationStatus } from '@lib/state';
import { ButtonProps } from '@material-ui/core';
import React from 'react';

interface Props extends Omit<ButtonProps, 'action' | 'onClick'> {
  reservation: Reservation;
  isManagerOverrideRequired?: boolean;
  createForGuest: boolean;
}

const ManagementRebookComponent: React.FC<Props> = ({
  reservation,
  isManagerOverrideRequired = false,
  createForGuest = true,
  ...props
}) => {
  if (reservation.status === ReservationStatus.CheckedIn) {
    return null;
  }
  return (
    <>
      <RebookButton
        reservation={reservation}
        color="secondary"
        variant="contained"
        isManagerOverrideRequired={isManagerOverrideRequired}
        overrideable
        createForGuest={createForGuest}
        rebookOnly={
          reservation.status !== ReservationStatus.Open &&
          reservation.status !== ReservationStatus.NoShow
        }
        {...props}
      />
    </>
  );
};

export const ManagementRebookButton = ManagementRebookComponent;
