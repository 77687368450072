import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from '@material-ui/core';

import { Section, useFormEvents, useObservable, usePageTitle } from '@lib/common';
import { AuditLogs, BreadcrumbBackButton } from 'app/shared';
import {
  CorporateAccountForms,
  corporateAccountQuery,
  corporateAccountService,
  corporateInvoiceQuery,
  corporateInvoiceService,
  propertyConfigurationQuery,
  propertyConfigurationService,
  useGetContactsByAccountId,
} from 'app/state';
import {
  CorporateAccountDelegates,
  CorporateAccountForm,
  CorporateAccountFormFields,
  CorporateAccountInvoiceList,
  CorporateAccountRatePlans,
  CorporateHousekeepingPolicy,
  CorporateReservationPolicy,
  CorporateZonePreferenceComponent,
} from '../components';
import { InvoiceGroupingComponent } from '../components/invoice-grouping-component';
import CorporateContactList from '../components/corporate-contact/corporate-contact-list';
import { sessionQuery } from '@lib/state';

export const EditCorporateAccountPage: React.FC<RouteComponentProps<{
  corporateAccountId: string;
}>> = ({ match }) => {
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const corporateAccountId = match.params.corporateAccountId;
  usePageTitle('Edit Corporate Account');

  const account = useObservable(corporateAccountQuery.activeAccount, 'async');
  const property = useObservable(propertyConfigurationQuery.activeProperty, 'async');

  const invoices = useObservable(corporateInvoiceQuery.invoices);
  const pagination = useObservable(corporateInvoiceQuery.pagination);

  const { contacts, loading: contactsLoading } = useGetContactsByAccountId(corporateAccountId);

  const [{ status, error }, reset] = useFormEvents(CorporateAccountForms.Edit);

  const defaultValues = {
    name: account?.name ?? '',
    invoiceSubAccount: account?.invoiceSubAccount ?? '',
  };

  useEffect(() => {
    if (propertyId) propertyConfigurationService.getPropertyConfiguration(propertyId);
  }, [propertyId]);

  useEffect(() => {
    if (corporateAccountId) {
      corporateAccountService.getCorporateAccountById(corporateAccountId);
      corporateInvoiceService.getInvoices(corporateAccountId);
    }
    return () => corporateAccountService.selectCorporateAccount();
  }, [corporateAccountId]);

  const onSubmit = useCallback(
    ({ automaticNoShowDays, automaticNoShowTime, ...values }: CorporateAccountFormFields) => {
      if (account) {
        corporateAccountService.updateCorporateAccount({
          ...account,
          ...values,
          cancelNoShows: false,
          automaticNoShowDays: automaticNoShowDays || null,
          automaticNoShowTime: automaticNoShowTime || null,
        });
      }
    },
    [account]
  );

  const loadMore = () => {
    corporateInvoiceService.getInvoices(corporateAccountId, pagination.continuationToken);
  };

  if (account == null || !defaultValues) return null;

  return (
    <Container maxWidth="lg" data-testid="corporateAccountDetailsPage">
      <BreadcrumbBackButton destination="/corporate-accounts" />
      <CorporateAccountForm
        account={account}
        status={status}
        error={error}
        onSubmit={onSubmit}
        resetFormUI={reset}
      />
      <CorporateHousekeepingPolicy account={account} />
      <CorporateReservationPolicy account={account} property={property} />
      <CorporateAccountRatePlans account={account} />
      <CorporateZonePreferenceComponent account={account} />
      <CorporateAccountDelegates account={account} />
      <CorporateContactList contacts={contacts} account={account} loading={contactsLoading} />
      <InvoiceGroupingComponent account={account} />
      <CorporateAccountInvoiceList
        account={account}
        invoices={invoices}
        formStatus={status}
        formError={error}
        onSubmit={onSubmit}
        resetFormUI={reset}
        loadMore={loadMore}
        isDone={pagination.isDone}
      />
      <Section title="Audit Logs" disableGutters>
        <AuditLogs scope="corporate-account" id={account.id} />
      </Section>
    </Container>
  );
};
