import React, { useCallback } from 'react';
import { Grid, MenuItem } from '@material-ui/core';

import { VipStatus, sessionQuery } from '@lib/state';
import {
  EnumValueDisplay,
  FormCheckbox,
  FormSelect,
  FormTextField,
  useObservable,
} from '@lib/common';
import { ListFilters } from 'app/shared';

export interface GuestFilters {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  propertyId?: string;
  showArchived?: boolean;
  vipStatus?: string;
}

interface GuestSearchFilterProps {
  filters: GuestFilters;
  onChange: (filters: GuestFilters) => void;
}

export const GuestSearchFilter: React.FC<GuestSearchFilterProps> = ({ filters, onChange }) => {
  const propertyId = useObservable(sessionQuery.propertyId, 'async');

  const change = useCallback(
    (x: GuestFilters) => {
      if (propertyId) {
        onChange({
          ...x,
          propertyId,
        });
      }
    },
    [propertyId, onChange]
  );

  if (!propertyId) return null;

  return (
    <ListFilters filters={filters} onChange={change}>
      <Grid container spacing={1}>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <FormTextField name="firstName" label="First Name" fullWidth size="small" />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <FormTextField name="lastName" label="Last Name" fullWidth size="small" />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <FormTextField name="email" label="Email" fullWidth size="small" />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <FormTextField name="phone" label="Phone" fullWidth size="small" />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <FormSelect defaultValue="" name="vipStatus" label="VIP Status" fullWidth>
            <MenuItem value="all" key="all">
              All
            </MenuItem>
            {Object.values(VipStatus).map(x => (
              <MenuItem value={x} key={x}>
                <EnumValueDisplay value={x} />
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={6}>
          <FormCheckbox name="showArchived" label="Show Archived" fullWidth size="small" />
        </Grid>
      </Grid>
    </ListFilters>
  );
};
