import React from 'react';
import { useFormEvents } from '../../forms';
import { accountService, AccountForms, FormStatus } from '@lib/state';
import { VerifyPhoneNumberForm } from './verify-phone-number.form';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { PhoneDisplay } from '../display';

interface Props {
  phone?: string;
  userId?: string;
  sendTokenResult: FormStatus;
  resendToken: () => void;
}
export const VerifyPhoneNumberComponent: React.FC<Props> = ({
  phone,
  userId,
  sendTokenResult,
  resendToken,
}) => {
  const history = useHistory();
  const [verifyTokenResult, resetFormUI] = useFormEvents(AccountForms.VerifyPhoneNumber);
  const onVerifyToken = (code: string) => {
    if (phone) accountService.verifyPhoneNumber(phone, code, userId);
  };

  const onResendToken = () => {
    resendToken();
  };

  const onCancel = () => {
    history.goBack();
  };

  const renderVerifyToken = () => {
    return (
      <>
        {sendTokenResult === FormStatus.Success && (
          <>
            {verifyTokenResult.status === FormStatus.Initial && (
              <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
                Please check for a text message sent to <PhoneDisplay phone={phone ?? ''} /> and
                enter the verification code below.
              </Typography>
            )}
            <VerifyPhoneNumberForm
              onResend={onResendToken}
              pending={verifyTokenResult.status === FormStatus.Pending}
              success={verifyTokenResult.status === FormStatus.Success}
              error={verifyTokenResult.error}
              onSubmit={e => onVerifyToken(e.code)}
              onResetFeedback={resetFormUI}
              onCancel={() => onCancel()}
            />
          </>
        )}
      </>
    );
  };
  return renderVerifyToken();
};
