import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import {
  housekeeperQuery,
  housekeepingService,
  housekeepingAssignmentQuery,
  sessionQuery,
  housekeepingAssignmentService,
} from '@lib/state';
import { Section, useObservable, usePageTitle } from '@lib/common';
import { HousekeepingReport, HousekeepingReportFilter } from '../components';
import { startOfToday } from 'date-fns';

export const HousekeepingReportPage: React.FC = () => {
  usePageTitle('Housekeeping Report');
  const housekeepers = useObservable(housekeeperQuery.housekeepers);
  const rooms = useObservable(housekeepingAssignmentQuery.roomsByDate);
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const [date, setDate] = useState(startOfToday());

  useEffect(() => {
    if (propertyId) {
      housekeepingService.loadHousekeepers(propertyId);
      housekeepingAssignmentService.loadHousekeepingAssignments(propertyId, date);
    }
  }, [propertyId, date]);

  if (!propertyId) return null;

  return (
    <Container maxWidth="xl">
      <HousekeepingReportFilter
        rooms={rooms}
        housekeepers={housekeepers}
        onDateChange={value => setDate(value)}
      />
      <Section disableGutters>
        <HousekeepingReport rooms={rooms} housekeepers={housekeepers} date={date} />
      </Section>
    </Container>
  );
};
