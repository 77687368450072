import React from 'react';

import { HousekeepingAssignment, Housekeeper } from '@lib/state';
import { PrintableHousekeepingReport } from './printable-housekeeper-report';
import { PrintButton } from 'app/shared';

interface Props {
  rooms: HousekeepingAssignment[];
  housekeepers: Housekeeper[];
  date: Date;
}

export const PrintableHousekeeperReportButton: React.FC<Props> = ({
  rooms,
  housekeepers,
  date,
}) => {
  const componentToPrint = () => {
    return (
      <PrintableHousekeepingReport
        rooms={rooms.filter(
          f => !!f.housekeeperUserId && housekeepers.some(s => s.userId === f.housekeeperUserId)
        )}
        housekeepers={housekeepers}
        date={date}
      />
    );
  };

  return <PrintButton componentToPrint={componentToPrint} />;
};
