import React, { useMemo } from 'react';
import { Grid, TypographyProps, Typography, createStyles, makeStyles } from '@material-ui/core';
import { format, parseISO } from 'date-fns';

import { Reservation, Room } from '@lib/state';
import {
  ButtonLink,
  GuestNameListDisplay,
  KioskOnly,
  Section,
  SectionVariant,
} from '../components';
import { kioskStyles } from '../guest-kiosk.theme';
import { StayDates } from './stay-dates';
import { RoomInfo } from './room-info';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      border: `2px solid ${theme.palette.secondary.main}`,
      padding: '0.5rem',
      marginTop: '2rem',
    },
    messageBlock: {
      marginBottom: '2rem',
    },
  })
);

interface Props {
  reservation: Reservation;
  rooms: Room[];
  showGuestInfo?: boolean | null;
  variant?: TypographyProps['variant'];
  containerClass?: string;
  contentClass?: string;
  finishText?: string | null;
  finishRoute?: string | null;
  sectionVariant?: SectionVariant;
  onFinish?: () => void | null;
  printView?: React.ReactChild;
}

export const CheckInConfirmation: React.FC<Props> = ({
  reservation,
  rooms,
  showGuestInfo = rooms.length > 1,
  variant,
  containerClass,
  contentClass,
  finishRoute,
  finishText,
  sectionVariant,
  onFinish,
  printView,
}) => {
  const { listContentBlock, internalRow, kioskTextButton } = kioskStyles();

  const styles = useStyles();

  const checkOutTime = reservation.rooms[0]?.checkOutTime;
  const roomType = rooms[0]?.roomType;
  const roomNumber = rooms.map(r => r.roomNumber).join(', ');
  const stayDates = useMemo(
    () => ({
      checkInDate: parseISO(reservation.checkInDate),
      checkOutDate: parseISO(reservation.checkOutDate),
      roomType: roomType,
    }),
    [reservation, roomType]
  );

  const finishTitle = finishText ?? 'Finish Check In';
  const finishCheckInRoute: string = finishRoute ?? '/';
  const textVariant: TypographyProps['variant'] = variant ?? 'body2';
  const className = sectionVariant === 'contained' ? undefined : containerClass ?? styles.paper;
  const contentClassName = contentClass ?? listContentBlock;
  const checkedInTitle: string = `Check-In complete for room ${roomNumber}`;

  return (
    <Section title={checkedInTitle} variant={sectionVariant} data-testid="checkInConfirmationDiv">
      <Typography variant={textVariant} align="center" className={styles.messageBlock}>
        Enjoy your stay! <br />
        You can check-out at {format(parseISO(checkOutTime), 'MMM d hh:mm a')}
      </Typography>
      <Grid container>
        {reservation.rooms.map((room, i) => (
          <React.Fragment key={room.id}>
            <Grid item xs={12} className={className}>
              <RoomInfo {...rooms[i]} />
              <StayDates {...stayDates} />
              {showGuestInfo && (
                <Grid item xs={12}>
                  <GuestNameListDisplay
                    guests={reservation.contacts.filter(({ roomGroup }) => roomGroup === i)}
                  />
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12} className={contentClassName}>
          <div className={internalRow}>
            <Typography variant={textVariant} align="center">
              Please go to the "My Key" page to access your room key.
            </Typography>
          </div>
        </Grid>
        <KioskOnly>
          <Grid item xs={12} className={contentClassName}>
            <div className={internalRow}>{printView}</div>
          </Grid>
        </KioskOnly>
        <Grid item xs={12} className={contentClassName}>
          <ButtonLink
            to={finishCheckInRoute}
            className={kioskTextButton}
            variant="contained"
            onClick={onFinish}
            fullWidth
            data-testid="checkInFinishButton"
          >
            {finishTitle}
          </ButtonLink>
        </Grid>
      </Grid>
    </Section>
  );
};
