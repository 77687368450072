import { Reservations } from '../api';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import bulkAvailableRateStore, {
  BulkAvailableRateStore,
  BulkAvailableRateUIState,
} from './bulk-available-rate.store';
import { Reservation } from '../reservation';
import { format } from 'date-fns';
import { GetBulkAvailableRatesByReservationRequest } from '../api/generated/reservations';
import { dispatchForm } from '../forms';
import { RoomRateForms } from '../room-rate';

export class BulkAvailableService {
  constructor(
    private readonly store: BulkAvailableRateStore,
    private readonly rateApi: Reservations.AvailabilityApi
  ) {}

  getBulkAvailability(
    propertyId: string,
    corporateAccountId: string,
    reservations?: Array<Reservation>,
    checkInDate?: Date,
    checkOutDate?: Date
  ): void {
    from(
      this.rateApi.availabilityRatesReservationBulkPost({
        propertyId: propertyId,
        corporateAccountId: corporateAccountId,
        reservationIds: reservations?.map(r => r.id),
        checkInDate: checkInDate ? format(checkInDate, 'yyyy-MM-dd') : undefined,
        checkOutDate: checkOutDate ? format(checkOutDate, 'yyyy-MM-dd') : undefined,
      } as GetBulkAvailableRatesByReservationRequest)
    )
      .pipe(
        dispatchForm(RoomRateForms.BulkAvailableRates),
        map(response => response.data)
      )
      .subscribe(data => {
        this.store.set(data.data);
        this.updateUI({
          propertyId,
          corporateAccountId,
          selectedReservations: reservations,
          checkInDate,
          checkOutDate,
        });
      });
  }

  selectReservation(reservation: Reservation) {
    this.store.update(({ ui }) => {
      const selectedReservations = [...ui.selectedReservations, reservation];
      return {
        ui: {
          selectedReservations,
          propertyId: ui.propertyId ?? reservation.propertyId,
          corporateAccountId: ui.corporateAccountId ?? reservation.affiliation?.corporateAccountId,
          checkInDate: ui.checkInDate,
          checkOutDate: ui.checkOutDate,
        },
      };
    });
  }

  deselectReservation(reservation: Reservation) {
    this.store.update(({ ui }) => {
      const selectedReservations = ui.selectedReservations.filter(x => x.id !== reservation.id);

      return {
        ui: {
          selectedReservations,
          propertyId: selectedReservations.length ? ui.propertyId : undefined,
          corporateAccountId: selectedReservations.length ? ui.corporateAccountId : undefined,
          checkInDate: selectedReservations.length ? ui.checkInDate : undefined,
          checkOutDate: selectedReservations.length ? ui.checkOutDate : undefined,
        },
      };
    });
  }

  updateUI(state: Partial<BulkAvailableRateUIState>) {
    this.store.update(({ ui }) => ({
      ui: { ...ui, ...state },
    }));
  }

  resetUI() {
    this.store.update({
      ui: { selectedReservations: [] },
    });
  }
}
export const bulkAvailableService = new BulkAvailableService(
  bulkAvailableRateStore,
  new Reservations.AvailabilityApi()
);
