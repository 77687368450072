import { JwtToken, UserInfo as ApiUserInfo } from '../api/auth';

export interface IAuthToken {
  accessToken: string;
  refreshToken?: string;
}

export interface Authenticated {
  authenticated: true;
  token: IAuthToken;
}

export interface Unauthenticated {
  authenticated: false;
}

export type AuthState = Unauthenticated | Authenticated;
export type UserInfo = ApiUserInfo;

export function mapAuthState(token?: JwtToken): AuthState {
  if (token == null) {
    return {
      authenticated: false,
    };
  }

  return {
    authenticated: true,
    token: {
      accessToken: token.access_token,
      refreshToken: token.refresh_token,
    },
  };
}
