import { EnumValueDisplay, Loading, useQRCodeUrlRoomQuery } from '@lib/common';
import { QRCode as QRCodeModel } from '@lib/state';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import QRCode from 'qrcode.react';

interface Props {
  roomNumber: string;
  qrCodes: Array<QRCodeModel>;
  propertyId: string;
}

export function RoomQRCodes({ roomNumber, qrCodes, propertyId }: Props) {
  const { data, isLoading } = useQRCodeUrlRoomQuery({ roomNumber, propertyId });

  if (isLoading) return <Loading />;

  return (
    <Grid container spacing={2}>
      {data?.map(({ qrCodeId, uri }) => (
        <Grid item xs={12} sm={6} key={qrCodeId}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subtitle2">
              {qrCodes.find(q => q.id === qrCodeId)?.qrCodeNumber}
            </Typography>
            <EnumValueDisplay value={qrCodes.find(q => q.id === qrCodeId)?.type} />
            <Grid item>
              <QRCode value={uri ?? ''} size={150} includeMargin={true} renderAs="canvas" />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
