import { QueryEntity } from '@datorama/akita';
import { NavMenuState, navMenuStore, NavMenuStore } from './nav-menu.store';
import { NavMenuItemSection } from './nav-menu.model';
import { sessionQuery, SessionQuery } from '../session';
import { switchMap, map } from 'rxjs/operators';

export class NavMenuQuery extends QueryEntity<NavMenuState> {
  constructor(protected store: NavMenuStore, private readonly sessionQuery: SessionQuery) {
    super(store);
  }

  activeMenuSections = this.sessionQuery.employeeRoles.pipe(
    switchMap(employeeRoles => this.getNavMenuItems(employeeRoles))
  );

  isNavDrawerOpen = this.select(({ ui }) => ui.isNavDrawerOpen);

  getNavMenuItems(employeeRoles: Array<string> = []) {
    return this.selectAll().pipe(
      map(navMenuSectionItems => {
        const filteredNavSectionItems: NavMenuItemSection[] = [];

        navMenuSectionItems.forEach(navMenuSectionItem => {
          var filteredItems = [];
          if (employeeRoles.length > 0) {
            filteredItems = navMenuSectionItem.items.filter(
              x =>
                // Include Nav Items that have no UserRole requirements
                x.accessibleUserRoles.length == 0 ||
                // Ensure the current user has the role for this items
                employeeRoles.some(role => x.accessibleUserRoles.includes(role))
            );
          } else {
            filteredItems = navMenuSectionItem.items.filter(x => x.accessibleUserRoles.length == 0);
          }

          if (filteredItems.length > 0)
            filteredNavSectionItems.push({
              title: navMenuSectionItem.title,
              items: filteredItems,
            });
        });

        return filteredNavSectionItems;
      })
    );
  }
}

export const navMenuQuery = new NavMenuQuery(navMenuStore, sessionQuery);
