import React from 'react';
import { Typography, TypographyProps, createStyles, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Reservations } from '@lib/state';
import { NameDisplay } from './name-display';
import { ContactDisplay } from './contact-display';
import { AddressDisplay } from './address-display';

interface Props extends TypographyProps {
  name: Reservations.Name;
  contact: Reservations.Contact;
  address: Reservations.Address;
}

const useStyles = makeStyles(
  createStyles({
    guestInfo: { fontWeight: 700, fontSize: '1rem' },
  })
);

export const GuestInformationDisplay: React.FC<Props> = ({ name, contact, address, ...props }) => {
  const styles = useStyles();
  return (
    <>
      <Typography variant="h4" paragraph>
        <FontAwesomeIcon icon="user-circle" /> Your Information
      </Typography>
      <br />
      <NameDisplay className={styles.guestInfo} {...name} {...props} />
      <ContactDisplay className={styles.guestInfo} {...contact} {...props} />
      <br />
      <AddressDisplay className={styles.guestInfo} {...address} {...props} />
    </>
  );
};
