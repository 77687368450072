import { QueryEntity } from '@datorama/akita';
import {
  VipHousekeepingOverrideState,
  VipHousekeepingOverrideStore,
  vipHousekeepingOverrideStore,
} from './vip-housekeeping-overrides.store';

export class VipHousekeepingOverrideQuery extends QueryEntity<VipHousekeepingOverrideState> {
  constructor(store: VipHousekeepingOverrideStore) {
    super(store);
  }

  vipHousekeepingOverrides = this.selectAll();

  loading = this.selectLoading();
}

export const vipHousekeepingOverrideQuery = new VipHousekeepingOverrideQuery(
  vipHousekeepingOverrideStore
);
