// tslint:disable
/**
 * Authentication
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { ChangePasswordRequest } from '../models';
import { CheckIfAccountExistsRequest } from '../models';
import { ConfirmEmailRequest } from '../models';
import { ConfirmPhoneRequest } from '../models';
import { CreateAccountRequest } from '../models';
import { EmailVerificationTokenRequest } from '../models';
import { ForgotPasswordRequest } from '../models';
import { GetAccountVerificationItemResponse } from '../models';
import { PhoneNumberVerificationTokenRequest } from '../models';
import { ResetPasswordRequest } from '../models';
import { UpdateAccountRequest } from '../models';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateAccountRequest} [createAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountCreatePost(createAccountRequest?: CreateAccountRequest, options: any = {}): RequestArgs {
      const localVarPath = `/account/create`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createAccountRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createAccountRequest !== undefined ? createAccountRequest : {})
        : createAccountRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CheckIfAccountExistsRequest} [checkIfAccountExistsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountExistsPost(
      checkIfAccountExistsRequest?: CheckIfAccountExistsRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/account/exists`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof checkIfAccountExistsRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            checkIfAccountExistsRequest !== undefined ? checkIfAccountExistsRequest : {}
          )
        : checkIfAccountExistsRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountKeyRegeneratePost(options: any = {}): RequestArgs {
      const localVarPath = `/account/key/regenerate`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [reservationUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountKeyResendPost(reservationUser?: string, options: any = {}): RequestArgs {
      const localVarPath = `/account/key/resend`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (reservationUser !== undefined) {
        localVarQueryParameter['reservationUser'] = reservationUser;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ForgotPasswordRequest} [forgotPasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountPasswordForgotPost(
      forgotPasswordRequest?: ForgotPasswordRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/account/password/forgot`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof forgotPasswordRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(forgotPasswordRequest !== undefined ? forgotPasswordRequest : {})
        : forgotPasswordRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ChangePasswordRequest} [changePasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountPasswordPost(
      changePasswordRequest?: ChangePasswordRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/account/password`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof changePasswordRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(changePasswordRequest !== undefined ? changePasswordRequest : {})
        : changePasswordRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ResetPasswordRequest} [resetPasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountPasswordResetPost(
      resetPasswordRequest?: ResetPasswordRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/account/password/reset`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof resetPasswordRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(resetPasswordRequest !== undefined ? resetPasswordRequest : {})
        : resetPasswordRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} user
     * @param {Array<string>} [keys]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountQrCodeGet(user: string, keys?: Array<string>, options: any = {}): RequestArgs {
      // verify required parameter 'user' is not null or undefined
      if (user === null || user === undefined) {
        throw new RequiredError(
          'user',
          'Required parameter user was null or undefined when calling accountQrCodeGet.'
        );
      }
      const localVarPath = `/account/qr-code`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (user !== undefined) {
        localVarQueryParameter['User'] = user;
      }

      if (keys) {
        localVarQueryParameter['Keys'] = keys;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateAccountRequest} [updateAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountUpdatePost(updateAccountRequest?: UpdateAccountRequest, options: any = {}): RequestArgs {
      const localVarPath = `/account/update`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateAccountRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateAccountRequest !== undefined ? updateAccountRequest : {})
        : updateAccountRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountVerificationGet(userId?: string, options: any = {}): RequestArgs {
      const localVarPath = `/account/verification`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ConfirmEmailRequest} [confirmEmailRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountVerifyEmailConfirmPost(
      confirmEmailRequest?: ConfirmEmailRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/account/verify/email/confirm`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof confirmEmailRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(confirmEmailRequest !== undefined ? confirmEmailRequest : {})
        : confirmEmailRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EmailVerificationTokenRequest} [emailVerificationTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountVerifyEmailPost(
      emailVerificationTokenRequest?: EmailVerificationTokenRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/account/verify/email`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof emailVerificationTokenRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            emailVerificationTokenRequest !== undefined ? emailVerificationTokenRequest : {}
          )
        : emailVerificationTokenRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ConfirmPhoneRequest} [confirmPhoneRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountVerifyPhoneConfirmPost(
      confirmPhoneRequest?: ConfirmPhoneRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/account/verify/phone/confirm`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof confirmPhoneRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(confirmPhoneRequest !== undefined ? confirmPhoneRequest : {})
        : confirmPhoneRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PhoneNumberVerificationTokenRequest} [phoneNumberVerificationTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountVerifyPhonePost(
      phoneNumberVerificationTokenRequest?: PhoneNumberVerificationTokenRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/account/verify/phone`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof phoneNumberVerificationTokenRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            phoneNumberVerificationTokenRequest !== undefined
              ? phoneNumberVerificationTokenRequest
              : {}
          )
        : phoneNumberVerificationTokenRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateAccountRequest} [createAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountCreatePost(
      createAccountRequest?: CreateAccountRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountCreatePost(
        createAccountRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {CheckIfAccountExistsRequest} [checkIfAccountExistsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountExistsPost(
      checkIfAccountExistsRequest?: CheckIfAccountExistsRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountExistsPost(
        checkIfAccountExistsRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountKeyRegeneratePost(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountKeyRegeneratePost(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [reservationUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountKeyResendPost(
      reservationUser?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountKeyResendPost(
        reservationUser,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {ForgotPasswordRequest} [forgotPasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountPasswordForgotPost(
      forgotPasswordRequest?: ForgotPasswordRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(
        configuration
      ).accountPasswordForgotPost(forgotPasswordRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {ChangePasswordRequest} [changePasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountPasswordPost(
      changePasswordRequest?: ChangePasswordRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountPasswordPost(
        changePasswordRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {ResetPasswordRequest} [resetPasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountPasswordResetPost(
      resetPasswordRequest?: ResetPasswordRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountPasswordResetPost(
        resetPasswordRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} user
     * @param {Array<string>} [keys]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountQrCodeGet(
      user: string,
      keys?: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountQrCodeGet(
        user,
        keys,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {UpdateAccountRequest} [updateAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountUpdatePost(
      updateAccountRequest?: UpdateAccountRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountUpdatePost(
        updateAccountRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountVerificationGet(
      userId?: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetAccountVerificationItemResponse> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountVerificationGet(
        userId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {ConfirmEmailRequest} [confirmEmailRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountVerifyEmailConfirmPost(
      confirmEmailRequest?: ConfirmEmailRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(
        configuration
      ).accountVerifyEmailConfirmPost(confirmEmailRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {EmailVerificationTokenRequest} [emailVerificationTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountVerifyEmailPost(
      emailVerificationTokenRequest?: EmailVerificationTokenRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountVerifyEmailPost(
        emailVerificationTokenRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {ConfirmPhoneRequest} [confirmPhoneRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountVerifyPhoneConfirmPost(
      confirmPhoneRequest?: ConfirmPhoneRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(
        configuration
      ).accountVerifyPhoneConfirmPost(confirmPhoneRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {PhoneNumberVerificationTokenRequest} [phoneNumberVerificationTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountVerifyPhonePost(
      phoneNumberVerificationTokenRequest?: PhoneNumberVerificationTokenRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountVerifyPhonePost(
        phoneNumberVerificationTokenRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {CreateAccountRequest} [createAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountCreatePost(
      createAccountRequest?: CreateAccountRequest,
      options?: any
    ): AxiosPromise<void> {
      return AccountApiFp(configuration).accountCreatePost(createAccountRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {CheckIfAccountExistsRequest} [checkIfAccountExistsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountExistsPost(
      checkIfAccountExistsRequest?: CheckIfAccountExistsRequest,
      options?: any
    ): AxiosPromise<void> {
      return AccountApiFp(configuration).accountExistsPost(checkIfAccountExistsRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountKeyRegeneratePost(options?: any): AxiosPromise<void> {
      return AccountApiFp(configuration).accountKeyRegeneratePost(options)(axios, basePath);
    },
    /**
     *
     * @param {string} [reservationUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountKeyResendPost(reservationUser?: string, options?: any): AxiosPromise<void> {
      return AccountApiFp(configuration).accountKeyResendPost(reservationUser, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {ForgotPasswordRequest} [forgotPasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountPasswordForgotPost(
      forgotPasswordRequest?: ForgotPasswordRequest,
      options?: any
    ): AxiosPromise<void> {
      return AccountApiFp(configuration).accountPasswordForgotPost(forgotPasswordRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {ChangePasswordRequest} [changePasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountPasswordPost(
      changePasswordRequest?: ChangePasswordRequest,
      options?: any
    ): AxiosPromise<void> {
      return AccountApiFp(configuration).accountPasswordPost(changePasswordRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {ResetPasswordRequest} [resetPasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountPasswordResetPost(
      resetPasswordRequest?: ResetPasswordRequest,
      options?: any
    ): AxiosPromise<void> {
      return AccountApiFp(configuration).accountPasswordResetPost(resetPasswordRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} user
     * @param {Array<string>} [keys]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountQrCodeGet(user: string, keys?: Array<string>, options?: any): AxiosPromise<string> {
      return AccountApiFp(configuration).accountQrCodeGet(user, keys, options)(axios, basePath);
    },
    /**
     *
     * @param {UpdateAccountRequest} [updateAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountUpdatePost(
      updateAccountRequest?: UpdateAccountRequest,
      options?: any
    ): AxiosPromise<void> {
      return AccountApiFp(configuration).accountUpdatePost(updateAccountRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountVerificationGet(
      userId?: string,
      options?: any
    ): AxiosPromise<GetAccountVerificationItemResponse> {
      return AccountApiFp(configuration).accountVerificationGet(userId, options)(axios, basePath);
    },
    /**
     *
     * @param {ConfirmEmailRequest} [confirmEmailRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountVerifyEmailConfirmPost(
      confirmEmailRequest?: ConfirmEmailRequest,
      options?: any
    ): AxiosPromise<void> {
      return AccountApiFp(configuration).accountVerifyEmailConfirmPost(
        confirmEmailRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {EmailVerificationTokenRequest} [emailVerificationTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountVerifyEmailPost(
      emailVerificationTokenRequest?: EmailVerificationTokenRequest,
      options?: any
    ): AxiosPromise<void> {
      return AccountApiFp(configuration).accountVerifyEmailPost(
        emailVerificationTokenRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {ConfirmPhoneRequest} [confirmPhoneRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountVerifyPhoneConfirmPost(
      confirmPhoneRequest?: ConfirmPhoneRequest,
      options?: any
    ): AxiosPromise<void> {
      return AccountApiFp(configuration).accountVerifyPhoneConfirmPost(
        confirmPhoneRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {PhoneNumberVerificationTokenRequest} [phoneNumberVerificationTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountVerifyPhonePost(
      phoneNumberVerificationTokenRequest?: PhoneNumberVerificationTokenRequest,
      options?: any
    ): AxiosPromise<void> {
      return AccountApiFp(configuration).accountVerifyPhonePost(
        phoneNumberVerificationTokenRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
  /**
   *
   * @param {CreateAccountRequest} [createAccountRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountCreatePost(createAccountRequest?: CreateAccountRequest, options?: any) {
    return AccountApiFp(this.configuration).accountCreatePost(createAccountRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {CheckIfAccountExistsRequest} [checkIfAccountExistsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountExistsPost(
    checkIfAccountExistsRequest?: CheckIfAccountExistsRequest,
    options?: any
  ) {
    return AccountApiFp(this.configuration).accountExistsPost(checkIfAccountExistsRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountKeyRegeneratePost(options?: any) {
    return AccountApiFp(this.configuration).accountKeyRegeneratePost(options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} [reservationUser]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountKeyResendPost(reservationUser?: string, options?: any) {
    return AccountApiFp(this.configuration).accountKeyResendPost(reservationUser, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {ForgotPasswordRequest} [forgotPasswordRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountPasswordForgotPost(forgotPasswordRequest?: ForgotPasswordRequest, options?: any) {
    return AccountApiFp(this.configuration).accountPasswordForgotPost(
      forgotPasswordRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {ChangePasswordRequest} [changePasswordRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountPasswordPost(changePasswordRequest?: ChangePasswordRequest, options?: any) {
    return AccountApiFp(this.configuration).accountPasswordPost(changePasswordRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {ResetPasswordRequest} [resetPasswordRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountPasswordResetPost(resetPasswordRequest?: ResetPasswordRequest, options?: any) {
    return AccountApiFp(this.configuration).accountPasswordResetPost(resetPasswordRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} user
   * @param {Array<string>} [keys]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountQrCodeGet(user: string, keys?: Array<string>, options?: any) {
    return AccountApiFp(this.configuration).accountQrCodeGet(
      user,
      keys,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {UpdateAccountRequest} [updateAccountRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountUpdatePost(updateAccountRequest?: UpdateAccountRequest, options?: any) {
    return AccountApiFp(this.configuration).accountUpdatePost(updateAccountRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountVerificationGet(userId?: string, options?: any) {
    return AccountApiFp(this.configuration).accountVerificationGet(userId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {ConfirmEmailRequest} [confirmEmailRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountVerifyEmailConfirmPost(confirmEmailRequest?: ConfirmEmailRequest, options?: any) {
    return AccountApiFp(this.configuration).accountVerifyEmailConfirmPost(
      confirmEmailRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {EmailVerificationTokenRequest} [emailVerificationTokenRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountVerifyEmailPost(
    emailVerificationTokenRequest?: EmailVerificationTokenRequest,
    options?: any
  ) {
    return AccountApiFp(this.configuration).accountVerifyEmailPost(
      emailVerificationTokenRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {ConfirmPhoneRequest} [confirmPhoneRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountVerifyPhoneConfirmPost(confirmPhoneRequest?: ConfirmPhoneRequest, options?: any) {
    return AccountApiFp(this.configuration).accountVerifyPhoneConfirmPost(
      confirmPhoneRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {PhoneNumberVerificationTokenRequest} [phoneNumberVerificationTokenRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountVerifyPhonePost(
    phoneNumberVerificationTokenRequest?: PhoneNumberVerificationTokenRequest,
    options?: any
  ) {
    return AccountApiFp(this.configuration).accountVerifyPhonePost(
      phoneNumberVerificationTokenRequest,
      options
    )(this.axios, this.basePath);
  }
}
