import React, { useEffect, useRef } from 'react';
import QRCode from 'qrcode.react';
import { Grid, Typography, Box } from '@material-ui/core';
import { propertyQuery, propertyService, AppConfig } from '@lib/state';
import { useObservable, LoadingIndicator } from '@lib/common';
import {
  isKiosk,
  isQRPrinterAvailable,
  printQRCodeNoGuest,
} from 'app/shared/native-interface/nativebridge';
import { PrintButton } from 'app/shared';

interface Props {}

export const PropertyQRCodeComponent: React.FC<Props> = () => {
  const property = useObservable(propertyQuery.activeProperty);
  const provisioningCode = useObservable(propertyQuery.currentProvisioningCode);
  const environmentName: string = AppConfig.Api.EnvironmentName;
  const componentToPrintRef = useRef(null);

  const qrCode: string = JSON.stringify({
    property: {
      key: provisioningCode,
      name: property?.name,
      environment: environmentName,
      propertyId: property?.id,
    },
  });

  const willInterceptPrint = () => {
    const _isKiosk = isKiosk();
    const _isQRPrinterAvailable = isQRPrinterAvailable();

    if (_isKiosk && _isQRPrinterAvailable) {
      printQRCodeNoGuest(qrCode);
      return false;
    }

    return true;
  };

  const renderAsValue = isKiosk() ? 'svg' : 'canvas';

  useEffect(() => {
    if (property) {
      propertyService.getPropertyProvisioningCode(property?.id);
    }
  }, [property]);

  if (!provisioningCode) return <LoadingIndicator loadingText="Generating QR Code" fillContainer />;

  return (
    <>
      <div ref={componentToPrintRef}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            {property?.name} QR Code
          </Typography>

          <Grid container justify="center">
            <QRCode value={qrCode} size={300} includeMargin={true} renderAs={renderAsValue} />
          </Grid>
        </Grid>
      </div>
      <Typography variant="body1">
        Use this QR Code when adding new tablets for kiosks, front desk and room access.
      </Typography>
      <Box paddingTop={3}>
        <PrintButton componentToPrint={componentToPrintRef} onBeforePrint={willInterceptPrint} />
      </Box>
    </>
  );
};
