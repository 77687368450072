import { QueryEntity } from '@datorama/akita';
import { GuestNoteState, guestNoteStore, initialGuestNoteFilters } from './guest-notes.store';
import { switchMap, debounceTime, startWith } from 'rxjs/operators';

export class GuestNoteQuery extends QueryEntity<GuestNoteState> {
  guestNotes = this.select(({ ui }) => ui).pipe(
    switchMap(({ propertyId }) =>
      this.selectAll({
        filterBy: x => x.propertyId.toLowerCase() === propertyId?.toLowerCase(),
      })
    )
  );

  selectedGuestNote = this.selectActive();

  guestNoteSearchFilters = this.select(({ ui }) => ui.filters).pipe(
    debounceTime(1000),
    startWith(initialGuestNoteFilters)
  );
}

export const guestNoteQuery = new GuestNoteQuery(guestNoteStore);
