import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useObservable } from '@lib/common';
import { sessionQuery, sessionService } from '@lib/state';

export const SignOutPage: React.FC = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const returnUrl = queryParams.get('returnUrl');
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');

  useEffect(() => {
    sessionService.logout();
  }, []);

  if (isLoggedIn) return null;

  if (returnUrl) {
    return <Redirect to={returnUrl} />;
  }

  return <Redirect to="/" />;
};
