import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container, createStyles, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

import {
  AppColors,
  FormHub,
  getAcceptCardData,
  GuestForm,
  kioskStyles,
  LocationForm,
  LocationFormFields,
  PaymentDetailsForm,
  ReservationConfirmation,
  RoomRateForm,
  useBookingStatus,
  useCommonStyle,
  useCurrentUserId,
  useFormEvents,
  useObservable,
  usePageTitle,
  useRoomRates,
  withPropertyFeatures,
} from '@lib/common';
import {
  BookingRequest,
  bookingService,
  corporateAccountQuery,
  corporateAccountService,
  FormStatus,
  guestProfileQuery,
  guestProfileService,
  isReservationAvailableForCheckin,
  propertyQuery,
  ReservationForms,
  RoomRateForms,
  roomRateQuery,
  roomRateService,
  RoomType,
  roomTypeQuery,
  roomTypeService,
  sessionQuery,
} from '@lib/state';
import { forceLogoutOnBackAction } from 'app/shared/native-interface/nativebridge';
import { KioskFormLoginOptions } from '../components/kiosk-form-login-options';

const useStyles = makeStyles(
  createStyles({
    formField: {
      '& svg': {
        color: AppColors.LightBlue,
      },
    },
    paddedForm: {
      paddingTop: '1em !important',
      paddingBottom: '1em !important',
    },
  })
);

const CreateReservation: React.FC<RouteComponentProps> = ({ history }) => {
  const { kioskForm, formInstructions, containedListItem } = kioskStyles();
  const styles = useStyles();
  const commonStyles = useCommonStyle();
  usePageTitle('Get A Room');

  const [corporateAccountSelected, setCorporateAccountSelected] = useState<string | undefined>(
    undefined
  );
  const [roomType, setRoomType] = useState<RoomType | undefined>(undefined);
  const [{ error, status }, resetReservationFormStatus] = useFormEvents(
    ReservationForms.CreateReservation
  );
  const [{ error: availabilityError, status: availabilityStatus }] = useFormEvents(
    RoomRateForms.Availability
  );

  const isLoggedIn = useObservable(sessionQuery.isLoggedIn);
  const guest = useObservable(guestProfileQuery.guest);
  const userId = useCurrentUserId();
  const sessionPropertyId = useObservable(sessionQuery.propertyId);
  const properties = useObservable(propertyQuery.properties);
  const roomTypes = useObservable(roomTypeQuery.propertyRoomTypes);
  const bookingStatus = useBookingStatus();
  const myCorporateAccounts = useObservable(corporateAccountQuery.accounts);

  const {
    uiState,
    roomRates,
    checkInStartOfDay,
    checkOutStartOfDay,
    checkInDefaultDate,
    corporateBooking,
    quantity,
    policy,
  } = useRoomRates(userId, corporateAccountSelected);

  useEffect(() => {
    //reset the reservation form status when the quantity is changed
    resetReservationFormStatus();
  }, [quantity, resetReservationFormStatus]);

  const [editLocation, setEditLocation] = useState(true);
  const [didSelectDates, setDidSelectDates] = useState(
    !!(uiState.checkInDate && uiState.checkOutDate)
  );

  const [requiresPayment, setRequiresPayment] = useState(true);

  useEffect(() => {
    if (isLoggedIn) {
      // make sure user is logged out on back action
      forceLogoutOnBackAction();
      // get guest profile
      guestProfileService.getGuestProfile();
      corporateAccountService.getMyAccounts();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (availabilityStatus === FormStatus.Success && didSelectDates) {
      setEditLocation(false);
    }
  }, [availabilityStatus, didSelectDates]);

  useEffect(() => {
    if (
      bookingStatus?.reservation?.userId &&
      isReservationAvailableForCheckin(bookingStatus.reservation)
    ) {
      history.push(`/kiosk/${bookingStatus.reservation.id}/check-in`);
    }
    // eslint-disable-next-line
  }, [bookingStatus?.reservation?.id]);

  const corporateAccountId = useMemo(() => {
    return corporateBooking?.id;
  }, [corporateBooking]);

  const submitLocationForm = useCallback(
    (values: LocationFormFields) => {
      roomTypeService.getPropertyRoomTypes(values.propertyId);
      roomRateService.updateUI({ ...values });
      setDidSelectDates(true);
      resetReservationFormStatus();
      setCorporateAccountSelected(values.corporateAccountId ?? undefined);
    },
    [resetReservationFormStatus]
  );

  const selectRoomType = useCallback(
    (roomType: RoomType) => {
      setRoomType(roomType);
    },
    [setRoomType]
  );

  const submit = useCallback(
    ({ password, card, ...booking }: any) => {
      const request: BookingRequest = {
        card: getAcceptCardData(card),
        ...booking,
        corporateAccountId: corporateAccountId,
      };

      if (userId) request.contact.userId = userId;
      if (uiState.promoCode) request.promoCode = uiState.promoCode;

      if (isLoggedIn) {
        bookingService.createReservation(request);
      } else {
        bookingService.createAccountAndReservation(password, request);
      }
    },
    [uiState, isLoggedIn, userId, corporateAccountId]
  );

  return (
    <Container maxWidth="md" className={clsx(kioskForm, styles.paddedForm)}>
      <Typography
        variant="h4"
        color="textPrimary"
        align="center"
        paragraph
        className={clsx(formInstructions, { [commonStyles.hidden]: !editLocation })}
      >
        Select the dates for your stay.
      </Typography>
      {bookingStatus ? (
        <ReservationConfirmation
          reservations={bookingStatus.reservations ?? []}
          failures={bookingStatus.failures ?? []}
          consumedHolds={bookingStatus.consumedHolds}
          properties={properties}
          policy={policy}
        />
      ) : (
        <FormHub onSubmit={submit}>
          <LocationForm
            className={clsx({ [commonStyles.hidden]: !editLocation })}
            hideLocationForPropertyId
            itemSpacing={3}
            properties={properties}
            corporateAccounts={myCorporateAccounts}
            itemClass={styles.formField}
            minCheckIn={checkInDefaultDate}
            defaultCheckIn={checkInStartOfDay}
            defaultCheckOut={checkOutStartOfDay}
            defaultPropertyId={sessionPropertyId}
            defaultQuantity={quantity}
            defaultCorporateAccountId={corporateAccountId}
            onStepComplete={submitLocationForm}
            status={availabilityStatus}
            error={availabilityError}
          />
          {!editLocation && roomRates && quantity && (
            <>
              <RoomRateForm
                className={containedListItem}
                properties={properties}
                roomTypes={roomTypes}
                roomRates={roomRates}
                policy={policy}
                usePaper={false}
                onEditLocation={() => {
                  setEditLocation(true);
                  setDidSelectDates(false);
                }}
                setRequiresPayment={setRequiresPayment}
                status={availabilityStatus}
                quantity={quantity}
                selectRoomType={selectRoomType}
                availabilityError={availabilityError}
                multipleCorpAccts={myCorporateAccounts.length > 1}
              />
              <GuestForm
                guest={guest}
                quantity={quantity}
                corporateBooking={corporateBooking}
                guestCount={roomType?.adultOccupancy?.maxOccupancy ?? undefined}
                useRearCam={false}
              >
                {!isLoggedIn && <KioskFormLoginOptions history={history} />}
              </GuestForm>
              <PaymentDetailsForm
                status={status}
                error={error ?? availabilityError}
                requiresPayment={requiresPayment}
                variant="contained"
                corporateAccountId={corporateAccountId}
              />
            </>
          )}
        </FormHub>
      )}
    </Container>
  );
};

export const CreateReservationPage = withPropertyFeatures(
  CreateReservation,
  roomRateQuery.propertyId
);
