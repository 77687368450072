import React from 'react';
import { makeStyles } from '@material-ui/core';
import { AppColors, DateDisplay } from '@lib/common';
import { TimelineProps, useTimeline } from './useTimeline';
import { TimelineColumn } from './timeline-column';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    top: 0,

    display: 'flex',
    height: '2rem',

    backgroundColor: AppColors.DarkBlue,
    color: theme.palette.common.white,
  },
  date: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.75rem',
    fontWeight: 700,
    borderLeft: `1px solid ${AppColors.HeaderDivider}`,
  },
}));

export const Header: React.FC<TimelineProps> = ({ startDate, view, children }) => {
  const { root, date } = useStyles();
  const dates = useTimeline(startDate, view);
  return (
    <div className={root}>
      {children}
      <TimelineColumn header>
        {dates.map(x => (
          <DateDisplay key={x.valueOf()} className={date} variant="body2" date={x} />
        ))}
      </TimelineColumn>
    </div>
  );
};
