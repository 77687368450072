import React from 'react';
import { Paper, PaperProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { AppColors } from '@lib/common';

interface Props {
  useBorder?: boolean;
  disablePadding?: boolean;
}

const useStyles = makeStyles({
  tile: {
    padding: ({ disablePadding }: Props) => (disablePadding ? undefined : '0.5rem'),
    border: ({ useBorder }: Props) => (useBorder ? `4px solid ${AppColors.LightBlue}` : `none`),
    borderRadius: ({ useBorder }: Props) => (useBorder ? '8px' : 'none'),
  },
});

export const Tile: React.FC<Props & PaperProps> = ({
  useBorder,
  disablePadding,
  className,
  ...props
}) => {
  const { tile } = useStyles({ disablePadding, useBorder });

  return <Paper className={clsx(tile, className)} elevation={0} {...props} />;
};
