import { FormHub, Section } from '@lib/common';
import { CorporateAccount, corporateAccountService } from 'app/state';
import React, { useCallback } from 'react';
import { InvoiceGroupingForm, InvoiceGroupingFormFields } from './invoice-grouping-form';

interface Props {
  account: CorporateAccount;
}

export const InvoiceGroupingComponent: React.FC<Props> = ({ account }) => {
  const submit = useCallback(
    (fields: InvoiceGroupingFormFields) => {
      corporateAccountService.setInvoiceGrouping(account.id, {
        isInvoiceGroupedByCostCenter1: fields.isInvoiceGroupedByCostCenter1,
        isInvoiceGroupedByCostCenter2: fields.isInvoiceGroupedByCostCenter2,
        isInvoiceGroupedByLocation: fields.isInvoiceGroupedByLocation,
      });
    },
    [account]
  );

  return (
    <Section title="Invoice Grouping" textAlign="left">
      <FormHub onSubmit={submit}>
        <InvoiceGroupingForm
          defaultValues={{
            isInvoiceGroupedByLocation: !!account.isInvoiceGroupedByLocation,
            isInvoiceGroupedByCostCenter1: !!account.isInvoiceGroupedByCostCenter1,
            isInvoiceGroupedByCostCenter2: !!account.isInvoiceGroupedByCostCenter2,
          }}
        />
      </FormHub>
    </Section>
  );
};
