import { Query } from '@datorama/akita';
import { ReportState, reportStore } from './report.store';
import {
  InHouseGuestReportType,
  OccupancyReportType,
  PurchaseTransactionReportType,
  ReportType,
} from './report.model';
import { map, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

export class ReportQuery extends Query<ReportState> {
  isLoading = this.selectLoading();

  inHouseGuests = this.createReportQuery<InHouseGuestReportType>('InHouseGuestReport');

  roomOccupancy = this.createReportQuery<OccupancyReportType>('OccupancyReport');

  purchaseTransactions = this.createReportQuery<PurchaseTransactionReportType>(
    'PurchaseTransactionReport'
  );

  pagination = this.select(({ pagination }) => pagination);

  private createReportQuery<T extends ReportType>(type: T['type']): Observable<T['data']> {
    return this.select(x => x.report).pipe(
      filter(f => !!f && f.type === type),
      map(m => m.data as T['data'])
    );
  }
}

export const reportQuery = new ReportQuery(reportStore);
