import { QueryEntity } from '@datorama/akita';
import { switchMap, debounceTime, startWith } from 'rxjs/operators';

import { reservationQuery, ReservationQuery, sessionQuery, SessionQuery } from '@lib/state';
import {
  GuestStaysState,
  GuestStaysStore,
  guestStaysStore,
  initialFilters,
} from './guest-stays.store';

export class GuestStaysQuery extends QueryEntity<GuestStaysState> {
  constructor(
    store: GuestStaysStore,
    private readonly sessionQuery: SessionQuery,
    private readonly reservationQuery: ReservationQuery
  ) {
    super(store);
  }

  guestStaysFilters = this.select(({ ui }) => ui.filters).pipe(
    debounceTime(1000),
    startWith(initialFilters)
  );

  guestStays = this.sessionQuery.propertyId.pipe(
    switchMap(propertyId =>
      this.selectAll({
        filterBy: x => x.propertyId === propertyId,
      })
    )
  );

  reservationGuestStays = this.reservationQuery.activeReservation.pipe(
    switchMap(reservation =>
      this.selectAll({
        filterBy: x => reservation && x.reservationId === reservation.id,
      })
    )
  );

  activeGuestStay = this.selectActive();
}

export const guestStaysQuery = new GuestStaysQuery(guestStaysStore, sessionQuery, reservationQuery);
