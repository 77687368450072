import React, { memo } from 'react';
import { Box, Grid, Typography, TypographyProps } from '@material-ui/core';

import { ReservationContact } from '@lib/state';
import {
  AddressDisplay,
  ContactDisplay,
  EnumValueDisplay,
  GuestRelationDisplay,
  NameDisplay,
} from '../components';

interface Props extends Omit<ReservationContact, 'id'>, TypographyProps {}

export const ReservationContactDisplay: React.FC<Props> = memo(
  ({ userId, name, contact, address, contactType, relationType, ...props }) => (
    <Box my={1}>
      <Typography {...props}>
        <strong>
          <NameDisplay {...name} disableTypography />
        </strong>
        <Box pl={0.75} color="text.secondary" component="span">
          (<EnumValueDisplay value={contactType} disableTypography={true} />
          {!!relationType && (
            <>
              {' - '}
              <GuestRelationDisplay relation={relationType} disableTypography={true} />
            </>
          )}
          )
        </Box>
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <ContactDisplay {...contact} {...props} />
        </Grid>
        <Grid item xs={6}>
          <AddressDisplay {...address} {...props} />
        </Grid>
      </Grid>
    </Box>
  )
);
