import React, { useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { DialogContent, Button, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledDialog } from '../../components';
import { addMonths } from 'date-fns';

interface ButtonProps {
  propertyId: string;
  exportChanges: (propertyId: string, startDate: Date, endDate: Date) => void;
}

interface Props {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  onConfirm: () => void;
}

const ExportButton: React.FC<ButtonProps> = ({ propertyId, exportChanges }) => {
  const [open, setOpen] = useState(false);
  const today = new Date();
  const [startDate, setStartDate] = useState(addMonths(today, -1));
  const [endDate, setEndDate] = useState(today);

  const closeDialog = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    exportChanges(propertyId, startDate, endDate);
    closeDialog();
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="text"
        color="primary"
        size="small"
        startIcon={<FontAwesomeIcon icon="download" />}
      >
        Export Guest Surveys
      </Button>
      <StyledDialog
        title="Export Date Range"
        open={open}
        onClose={() => closeDialog()}
        maxWidth="xs"
      >
        <DialogContent>
          <DownloadDateSelection
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            onConfirm={onConfirm}
          ></DownloadDateSelection>
        </DialogContent>
      </StyledDialog>
    </>
  );
};

const DownloadDateSelection: React.FC<Props> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onConfirm,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={date => {
            if (date) setStartDate(date);
          }}
          format="MM/dd/yyyy"
          autoOk
          required={true}
          fullWidth
          InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={date => {
            if (date) setEndDate(date);
          }}
          format="MM/dd/yyyy"
          autoOk
          required={true}
          fullWidth
          InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={onConfirm} variant="contained" color="secondary" fullWidth>
          Export
        </Button>
      </Grid>
    </Grid>
  );
};
export const GuestSurveyExportButton = ExportButton;
