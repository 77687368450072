import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BulkCancellationPreview } from './bulk-cancel-preview.models';

export interface BulkCancellationPreviewState
  extends EntityState<BulkCancellationPreview, string> {}

@StoreConfig({ name: 'bulk-cancel-previews', idKey: 'reservationId', resettable: true })
export class BulkCancelPreviewStore extends EntityStore<BulkCancellationPreviewState> {
  constructor() {
    super({});
  }
}

const bulkCancelPreviewStore: BulkCancelPreviewStore = new BulkCancelPreviewStore();
export default bulkCancelPreviewStore;
