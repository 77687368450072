// tslint:disable
/**
 * Access Control
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { DoorAccessControllerStatusModel } from '../models';
import { DoorAccessImageRequest } from '../models';
import { DoorAccessImageResponse } from '../models';
import { PropertyGroupResponse } from '../models';
import { UpdatePropertyGroupRequest } from '../models';
/**
 * PropertyApi - axios parameter creator
 * @export
 */
export const PropertyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {DoorAccessImageRequest} [doorAccessImageRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyDoorAccessImagePost(
      doorAccessImageRequest?: DoorAccessImageRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/property/door-access-image`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof doorAccessImageRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(doorAccessImageRequest !== undefined ? doorAccessImageRequest : {})
        : doorAccessImageRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling propertyIdGet.'
        );
      }
      const localVarPath = `/property/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdatePropertyGroupRequest} [updatePropertyGroupRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdPatch(
      id: string,
      updatePropertyGroupRequest?: UpdatePropertyGroupRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling propertyIdPatch.'
        );
      }
      const localVarPath = `/property/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updatePropertyGroupRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updatePropertyGroupRequest !== undefined ? updatePropertyGroupRequest : {})
        : updatePropertyGroupRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyPropertyIdRoomRoomNumberGet(
      propertyId: string,
      roomNumber: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyPropertyIdRoomRoomNumberGet.'
        );
      }
      // verify required parameter 'roomNumber' is not null or undefined
      if (roomNumber === null || roomNumber === undefined) {
        throw new RequiredError(
          'roomNumber',
          'Required parameter roomNumber was null or undefined when calling propertyPropertyIdRoomRoomNumberGet.'
        );
      }
      const localVarPath = `/property/{propertyId}/room/{roomNumber}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'roomNumber'}}`, encodeURIComponent(String(roomNumber)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PropertyApi - functional programming interface
 * @export
 */
export const PropertyApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {DoorAccessImageRequest} [doorAccessImageRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyDoorAccessImagePost(
      doorAccessImageRequest?: DoorAccessImageRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoorAccessImageResponse> {
      const localVarAxiosArgs = PropertyApiAxiosParamCreator(
        configuration
      ).propertyDoorAccessImagePost(doorAccessImageRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGet(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyGroupResponse> {
      const localVarAxiosArgs = PropertyApiAxiosParamCreator(configuration).propertyIdGet(
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdatePropertyGroupRequest} [updatePropertyGroupRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdPatch(
      id: string,
      updatePropertyGroupRequest?: UpdatePropertyGroupRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyGroupResponse> {
      const localVarAxiosArgs = PropertyApiAxiosParamCreator(configuration).propertyIdPatch(
        id,
        updatePropertyGroupRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyPropertyIdRoomRoomNumberGet(
      propertyId: string,
      roomNumber: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoorAccessControllerStatusModel> {
      const localVarAxiosArgs = PropertyApiAxiosParamCreator(
        configuration
      ).propertyPropertyIdRoomRoomNumberGet(propertyId, roomNumber, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PropertyApi - factory interface
 * @export
 */
export const PropertyApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {DoorAccessImageRequest} [doorAccessImageRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyDoorAccessImagePost(
      doorAccessImageRequest?: DoorAccessImageRequest,
      options?: any
    ): AxiosPromise<DoorAccessImageResponse> {
      return PropertyApiFp(configuration).propertyDoorAccessImagePost(
        doorAccessImageRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdGet(id: string, options?: any): AxiosPromise<PropertyGroupResponse> {
      return PropertyApiFp(configuration).propertyIdGet(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {UpdatePropertyGroupRequest} [updatePropertyGroupRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdPatch(
      id: string,
      updatePropertyGroupRequest?: UpdatePropertyGroupRequest,
      options?: any
    ): AxiosPromise<PropertyGroupResponse> {
      return PropertyApiFp(configuration).propertyIdPatch(
        id,
        updatePropertyGroupRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} roomNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyPropertyIdRoomRoomNumberGet(
      propertyId: string,
      roomNumber: string,
      options?: any
    ): AxiosPromise<DoorAccessControllerStatusModel> {
      return PropertyApiFp(configuration).propertyPropertyIdRoomRoomNumberGet(
        propertyId,
        roomNumber,
        options
      )(axios, basePath);
    },
  };
};

/**
 * PropertyApi - object-oriented interface
 * @export
 * @class PropertyApi
 * @extends {BaseAPI}
 */
export class PropertyApi extends BaseAPI {
  /**
   *
   * @param {DoorAccessImageRequest} [doorAccessImageRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyDoorAccessImagePost(
    doorAccessImageRequest?: DoorAccessImageRequest,
    options?: any
  ) {
    return PropertyApiFp(this.configuration).propertyDoorAccessImagePost(
      doorAccessImageRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyIdGet(id: string, options?: any) {
    return PropertyApiFp(this.configuration).propertyIdGet(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {UpdatePropertyGroupRequest} [updatePropertyGroupRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyIdPatch(
    id: string,
    updatePropertyGroupRequest?: UpdatePropertyGroupRequest,
    options?: any
  ) {
    return PropertyApiFp(this.configuration).propertyIdPatch(
      id,
      updatePropertyGroupRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} roomNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertyApi
   */
  public propertyPropertyIdRoomRoomNumberGet(
    propertyId: string,
    roomNumber: string,
    options?: any
  ) {
    return PropertyApiFp(this.configuration).propertyPropertyIdRoomRoomNumberGet(
      propertyId,
      roomNumber,
      options
    )(this.axios, this.basePath);
  }
}
