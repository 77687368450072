// tslint:disable
/**
 * Access Control
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { GetRoomAccessListReponse } from '../models';
import { GetUserEntryCodesListResponse } from '../models';
import { GetUserQRAccessKeyResponse } from '../models';
import { GrantStaffRoomAccessRequest } from '../models';
import { ReservationQRCodesResponse } from '../models';
/**
 * EntryCodeApi - axios parameter creator
 * @export
 */
export const EntryCodeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} thingName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodeAreasThingNamePost(thingName: string, options: any = {}): RequestArgs {
      // verify required parameter 'thingName' is not null or undefined
      if (thingName === null || thingName === undefined) {
        throw new RequiredError(
          'thingName',
          'Required parameter thingName was null or undefined when calling entrycodeAreasThingNamePost.'
        );
      }
      const localVarPath = `/entrycode/areas/{thingName}`.replace(
        `{${'thingName'}}`,
        encodeURIComponent(String(thingName))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [roomNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodePropertyIdGet(
      propertyId: string,
      roomNumber?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling entrycodePropertyIdGet.'
        );
      }
      const localVarPath = `/entrycode/{propertyId}`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (roomNumber !== undefined) {
        localVarQueryParameter['roomNumber'] = roomNumber;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodePropertyIdReservationsReservationIdGet(
      propertyId: string,
      reservationId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling entrycodePropertyIdReservationsReservationIdGet.'
        );
      }
      // verify required parameter 'reservationId' is not null or undefined
      if (reservationId === null || reservationId === undefined) {
        throw new RequiredError(
          'reservationId',
          'Required parameter reservationId was null or undefined when calling entrycodePropertyIdReservationsReservationIdGet.'
        );
      }
      const localVarPath = `/entrycode/{propertyId}/reservations/{reservationId}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'reservationId'}}`, encodeURIComponent(String(reservationId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {GrantStaffRoomAccessRequest} [grantStaffRoomAccessRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodePropertyIdStaffPost(
      propertyId: string,
      grantStaffRoomAccessRequest?: GrantStaffRoomAccessRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling entrycodePropertyIdStaffPost.'
        );
      }
      const localVarPath = `/entrycode/{propertyId}/staff`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof grantStaffRoomAccessRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            grantStaffRoomAccessRequest !== undefined ? grantStaffRoomAccessRequest : {}
          )
        : grantStaffRoomAccessRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodePropertyIdUserGet(
      propertyId: string,
      userId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling entrycodePropertyIdUserGet.'
        );
      }
      const localVarPath = `/entrycode/{propertyId}/user`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} thingName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodeThingNamePost(thingName: string, options: any = {}): RequestArgs {
      // verify required parameter 'thingName' is not null or undefined
      if (thingName === null || thingName === undefined) {
        throw new RequiredError(
          'thingName',
          'Required parameter thingName was null or undefined when calling entrycodeThingNamePost.'
        );
      }
      const localVarPath = `/entrycode/{thingName}`.replace(
        `{${'thingName'}}`,
        encodeURIComponent(String(thingName))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodeUserGet(options: any = {}): RequestArgs {
      const localVarPath = `/entrycode/user`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EntryCodeApi - functional programming interface
 * @export
 */
export const EntryCodeApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} thingName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodeAreasThingNamePost(
      thingName: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EntryCodeApiAxiosParamCreator(
        configuration
      ).entrycodeAreasThingNamePost(thingName, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [roomNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodePropertyIdGet(
      propertyId: string,
      roomNumber?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomAccessListReponse> {
      const localVarAxiosArgs = EntryCodeApiAxiosParamCreator(configuration).entrycodePropertyIdGet(
        propertyId,
        roomNumber,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodePropertyIdReservationsReservationIdGet(
      propertyId: string,
      reservationId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationQRCodesResponse> {
      const localVarAxiosArgs = EntryCodeApiAxiosParamCreator(
        configuration
      ).entrycodePropertyIdReservationsReservationIdGet(propertyId, reservationId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {GrantStaffRoomAccessRequest} [grantStaffRoomAccessRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodePropertyIdStaffPost(
      propertyId: string,
      grantStaffRoomAccessRequest?: GrantStaffRoomAccessRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EntryCodeApiAxiosParamCreator(
        configuration
      ).entrycodePropertyIdStaffPost(propertyId, grantStaffRoomAccessRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodePropertyIdUserGet(
      propertyId: string,
      userId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserQRAccessKeyResponse> {
      const localVarAxiosArgs = EntryCodeApiAxiosParamCreator(
        configuration
      ).entrycodePropertyIdUserGet(propertyId, userId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} thingName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodeThingNamePost(
      thingName: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EntryCodeApiAxiosParamCreator(configuration).entrycodeThingNamePost(
        thingName,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodeUserGet(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserEntryCodesListResponse> {
      const localVarAxiosArgs = EntryCodeApiAxiosParamCreator(configuration).entrycodeUserGet(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * EntryCodeApi - factory interface
 * @export
 */
export const EntryCodeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} thingName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodeAreasThingNamePost(thingName: string, options?: any): AxiosPromise<void> {
      return EntryCodeApiFp(configuration).entrycodeAreasThingNamePost(thingName, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [roomNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodePropertyIdGet(
      propertyId: string,
      roomNumber?: string,
      options?: any
    ): AxiosPromise<GetRoomAccessListReponse> {
      return EntryCodeApiFp(configuration).entrycodePropertyIdGet(
        propertyId,
        roomNumber,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} reservationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodePropertyIdReservationsReservationIdGet(
      propertyId: string,
      reservationId: string,
      options?: any
    ): AxiosPromise<ReservationQRCodesResponse> {
      return EntryCodeApiFp(configuration).entrycodePropertyIdReservationsReservationIdGet(
        propertyId,
        reservationId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {GrantStaffRoomAccessRequest} [grantStaffRoomAccessRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodePropertyIdStaffPost(
      propertyId: string,
      grantStaffRoomAccessRequest?: GrantStaffRoomAccessRequest,
      options?: any
    ): AxiosPromise<void> {
      return EntryCodeApiFp(configuration).entrycodePropertyIdStaffPost(
        propertyId,
        grantStaffRoomAccessRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodePropertyIdUserGet(
      propertyId: string,
      userId?: string,
      options?: any
    ): AxiosPromise<GetUserQRAccessKeyResponse> {
      return EntryCodeApiFp(configuration).entrycodePropertyIdUserGet(
        propertyId,
        userId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} thingName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodeThingNamePost(thingName: string, options?: any): AxiosPromise<void> {
      return EntryCodeApiFp(configuration).entrycodeThingNamePost(thingName, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entrycodeUserGet(options?: any): AxiosPromise<GetUserEntryCodesListResponse> {
      return EntryCodeApiFp(configuration).entrycodeUserGet(options)(axios, basePath);
    },
  };
};

/**
 * EntryCodeApi - object-oriented interface
 * @export
 * @class EntryCodeApi
 * @extends {BaseAPI}
 */
export class EntryCodeApi extends BaseAPI {
  /**
   *
   * @param {string} thingName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryCodeApi
   */
  public entrycodeAreasThingNamePost(thingName: string, options?: any) {
    return EntryCodeApiFp(this.configuration).entrycodeAreasThingNamePost(thingName, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} [roomNumber]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryCodeApi
   */
  public entrycodePropertyIdGet(propertyId: string, roomNumber?: string, options?: any) {
    return EntryCodeApiFp(this.configuration).entrycodePropertyIdGet(
      propertyId,
      roomNumber,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} reservationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryCodeApi
   */
  public entrycodePropertyIdReservationsReservationIdGet(
    propertyId: string,
    reservationId: string,
    options?: any
  ) {
    return EntryCodeApiFp(this.configuration).entrycodePropertyIdReservationsReservationIdGet(
      propertyId,
      reservationId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {GrantStaffRoomAccessRequest} [grantStaffRoomAccessRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryCodeApi
   */
  public entrycodePropertyIdStaffPost(
    propertyId: string,
    grantStaffRoomAccessRequest?: GrantStaffRoomAccessRequest,
    options?: any
  ) {
    return EntryCodeApiFp(this.configuration).entrycodePropertyIdStaffPost(
      propertyId,
      grantStaffRoomAccessRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryCodeApi
   */
  public entrycodePropertyIdUserGet(propertyId: string, userId?: string, options?: any) {
    return EntryCodeApiFp(this.configuration).entrycodePropertyIdUserGet(
      propertyId,
      userId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} thingName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryCodeApi
   */
  public entrycodeThingNamePost(thingName: string, options?: any) {
    return EntryCodeApiFp(this.configuration).entrycodeThingNamePost(thingName, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryCodeApi
   */
  public entrycodeUserGet(options?: any) {
    return EntryCodeApiFp(this.configuration).entrycodeUserGet(options)(this.axios, this.basePath);
  }
}
