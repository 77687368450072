import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, DialogContent, DialogContentText, Grid } from '@material-ui/core';

import { FormStatus } from '@lib/state';
import {
  ErrorDisplay,
  FormCheckbox,
  FormHub,
  FormTextField,
  StyledDialog,
  SubmitButton,
  useFormEvents,
} from '@lib/common';
import { GuestNotesForms, guestNotesService } from 'app/state';
import { GuestNoteTypeSelect } from './guest-note-types-select';

interface Props {
  propertyId: string;
  userId: string;
  guestStayId?: string;
}

interface FormFields {
  note: string;
  noteType: string;
  sendNotification: boolean;
}

export const CreateGuestNote: React.FC<Props> = ({ propertyId, userId, guestStayId }) => {
  const [showModal, setShowModal] = useState(false);
  const [showNotificationCheck, setShowNotificationCheck] = useState(false);

  const [{ status, error }, resetUI] = useFormEvents(GuestNotesForms.Create);

  useEffect(() => {
    if (status === FormStatus.Success) {
      setShowModal(false);
      resetUI();
    }
  }, [status, resetUI]);

  const onSubmit = useCallback(
    ({ note, noteType, sendNotification }: FormFields) => {
      guestNotesService.createGuestNote(
        propertyId,
        userId,
        note,
        noteType,
        sendNotification,
        guestStayId
      );
    },
    [propertyId, userId, guestStayId]
  );

  return (
    <>
      <Box my="1rem" display="flex" justifyContent="flex-end" flexDirection="row">
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            resetUI();
            setShowModal(true);
          }}
        >
          Create Guest Note
        </Button>
      </Box>
      <StyledDialog title="Create Guest Note" open={showModal} onClose={() => setShowModal(false)}>
        <DialogContent>
          <FormHub<FormFields> onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DialogContentText>
                  Please enter the note and select the type to be saved.
                </DialogContentText>
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  type="text"
                  name="note"
                  label="Note"
                  fullWidth
                  validationOptions={{ required: true, maxLength: 2000 }}
                />
              </Grid>
              <Grid item xs={12}>
                <GuestNoteTypeSelect
                  propertyId={propertyId}
                  validationOptions={{ required: true }}
                  onSelect={noteType => {
                    setShowNotificationCheck(!!guestStayId && noteType?.label === 'Package');
                  }}
                  selectedNoteTypeId={''}
                ></GuestNoteTypeSelect>
              </Grid>
              {showNotificationCheck && (
                <Grid item xs={12}>
                  <FormCheckbox
                    name="sendNotification"
                    label="Send Notification"
                    defaultChecked={false}
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <ErrorDisplay error={error} />
                <SubmitButton
                  variant="contained"
                  color="secondary"
                  fullWidth
                  pending={status === FormStatus.Pending}
                  disabled={status === FormStatus.Pending}
                >
                  Create
                </SubmitButton>
              </Grid>
            </Grid>
          </FormHub>
        </DialogContent>
      </StyledDialog>
    </>
  );
};
