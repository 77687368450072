import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Property, RoomType } from '@lib/state';
import { RatePlanSort, RoomRatePlan } from './room-rate-plan.model';

export interface RoomRatePlanUIState {
  propertyId: Property['id'] | null;
  roomTypeId: RoomType['id'] | null;
  corporateAccountId: string | null;
  showDisabled: boolean;
  ratePlanSort?: RatePlanSort;
}

export interface RoomRatePlanExportState {
  propertyId: Property['id'] | null;
  roomTypeId: RoomType['id'] | null;
  corporateAccountId: string | null;
  showDisabled: boolean;
  roomTypeName: string;
  ratePlanSort?: RatePlanSort;
}

export interface RoomRatePlanState
  extends EntityState<RoomRatePlan, RoomRatePlan['id']>,
    ActiveState<RoomRatePlan['id']> {
  ui: RoomRatePlanUIState;
}

@StoreConfig({ name: 'room-rate-plans', resettable: true })
export class RoomRatePlanStore extends EntityStore<RoomRatePlanState> {
  constructor() {
    super({
      ui: {
        propertyId: null,
        roomTypeId: null,
        corporateAccountId: null,
        showDisabled: false,
        ratePlanSort: RatePlanSort.Priority,
      },
    });
  }
}

export const roomRatePlanStore = new RoomRatePlanStore();
