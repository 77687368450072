import { compareAsc, compareDesc, isAfter, isBefore, parseISO, startOfToday } from 'date-fns';

interface CheckInDateObject {
  checkInDate: string;
}

interface ActiveStayDateObject {
  roomAssignmentStart: string;
  roomAssignmentEnd: string;
}

export function sortByCheckIn<T extends CheckInDateObject>(
  items: T[],
  ascending: boolean = true
): T[] {
  return items.sort((a, b) => {
    const checkInA = parseISO(a.checkInDate);
    const checkInB = parseISO(b.checkInDate);
    return ascending ? compareAsc(checkInA, checkInB) : compareDesc(checkInA, checkInB);
  });
}

export function todayAndAfter<T extends CheckInDateObject>(items: T[]): T[] {
  const today = startOfToday();
  return items.filter(x => compareAsc(today, parseISO(x.checkInDate)) <= 0);
}

export function todayAndBefore<T extends CheckInDateObject>(items: T[]): T[] {
  const today = startOfToday();
  return items.filter(x => compareDesc(today, parseISO(x.checkInDate)) <= 0);
}

export function activeStay<T extends ActiveStayDateObject>(items: T[]): T[] {
  const today = new Date();
  return items.filter(
    x =>
      isAfter(today, parseISO(x.roomAssignmentStart)) &&
      isBefore(today, parseISO(x.roomAssignmentEnd))
  );
}

export function dateFromTimeString(timeString: string): Date {
  const date = new Date();

  const nextDayParts = timeString.split(':');
  const nextDayHour = parseInt(nextDayParts[0]);
  const nextDayMinute = parseInt(nextDayParts[1]);

  date.setHours(nextDayHour);
  date.setMinutes(nextDayMinute);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}
