import React, { useCallback } from 'react';
import { MenuItem, TypographyProps } from '@material-ui/core';

import {
  FormStatus,
  Reservation,
  ReservationForms,
  reservationService,
  SleepSchedule,
} from '@lib/state';
import { EnumValueDisplay } from '../../components';
import { EditableDisplay, useFormEvents } from '../../forms';
import { useUniqueId } from '../../utils';
import { SleepScheduleInput } from './sleep-schedule-input';

interface Props {
  reservation: Reservation;

  labelVariant?: TypographyProps['variant'];
  valueVariant?: TypographyProps['variant'];
  dense?: boolean;
}

export const SleepScheduleEditableDisplay: React.FC<Props> = ({ reservation, ...props }) => {
  const formId = useUniqueId(ReservationForms.UpdateSleepSchedule);

  const [{ status, error }] = useFormEvents(formId);

  const onSubmit = useCallback(
    (value?: string) => {
      reservationService.updateSleepSchedule(reservation, value! as SleepSchedule, formId);
    },
    [reservation, formId]
  );

  return (
    <EditableDisplay
      label="Sleep Schedule"
      value={reservation.affiliation?.sleepSchedule ?? ''}
      onSubmit={onSubmit}
      input={SleepScheduleInput}
      valueDisplay={EnumValueDisplay}
      validationOptions={{
        required: {
          value: true,
          message: 'This field is required.',
        },
      }}
      pending={status === FormStatus.Pending}
      error={error}
      {...props}
    />
  );
};
