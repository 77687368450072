import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, MenuItem } from '@material-ui/core';
import { propertyQuery } from '@lib/state';
import { FormSelect, useObservable } from '@lib/common';
import {
  corporateAccountQuery,
  corporateAccountService,
  guestManagementQuery,
  guestManagementService,
} from 'app/state';
import { useCorporateAccountDelegates } from 'app/corporate-account/useCorporateAccountDelegates';
import { GuestProfileModel } from '@lib/state/api/generated/reservations';

interface Props {
  className?: string;
  onSelectDelegateGuest: (
    isReadyForCheckAvailability: boolean,
    corporateAccountId?: undefined,
    delegateGuest?: GuestProfileModel
  ) => void;
}

export const SelectCorporateDelegateForm: React.FC<Props> = ({
  className,
  onSelectDelegateGuest,
}) => {
  const { register, getValues, watch } = useFormContext();
  const corporateAccountId = watch('corporateAccountId', '');
  const delegateUserId = watch('delegateUserId', '');

  useEffect(() => {
    const validate = (): boolean => {
      const { _corporateAccountId, _delegateUserId } = getValues();
      return !(_corporateAccountId && !_delegateUserId);
    };
    register({ name: 'corporateAccountId' });
    register({ name: 'delegateUserId' }, { validate });
    // eslint-disable-next-line
  }, []);

  const customerId = useObservable(propertyQuery.activeProperty)?.customerId;
  const corporations = useObservable(corporateAccountQuery.corporationsWithDelegates, 'async');
  const delegates = useCorporateAccountDelegates(corporateAccountId);
  const delegateGuest = useObservable(guestManagementQuery.activeGuest);

  const readyForCheckAvailability = useMemo(() => {
    if (corporateAccountId && delegateUserId && delegates) {
      return delegates.some(
        d => d.corporateAccountId === corporateAccountId && d.userId === delegateUserId
      );
    } else if (corporateAccountId) {
      return false;
    }
    return true;
  }, [corporateAccountId, delegates, delegateUserId]);

  useEffect(() => {
    if (!!customerId) corporateAccountService.getAllCorporateAccounts(customerId, true);
  }, [customerId]);

  useEffect(() => {
    if (corporateAccountId && delegateUserId) {
      guestManagementService.getGuestById(delegateUserId);
      guestManagementService.select(delegateUserId);
    }
  }, [corporateAccountId, delegateUserId]);

  useEffect(() => {
    if (delegateGuest && delegateUserId && corporateAccountId && readyForCheckAvailability) {
      onSelectDelegateGuest(readyForCheckAvailability, corporateAccountId, delegateGuest);
    } else {
      onSelectDelegateGuest(readyForCheckAvailability, undefined);
    }
  }, [
    delegateUserId,
    corporateAccountId,
    delegateGuest,
    onSelectDelegateGuest,
    readyForCheckAvailability,
  ]);

  if (!corporations?.length) return null;
  return (
    <div className={className}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <FormSelect
            name="corporateAccountId"
            label="Corporate Account"
            defaultValue={corporateAccountId ?? ''}
            fullWidth
            data-testid="corporateAccountSelect"
          >
            <MenuItem value="">None</MenuItem>
            {corporations.map(x => (
              <MenuItem key={x.id} value={x.id}>
                {x.name}
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        {!!delegates?.length && (
          <Grid item xs={12} sm={6}>
            <FormSelect
              name="delegateUserId"
              label="Corporate Delegate"
              fullWidth
              defaultValue={delegateUserId ?? ''}
              required
              data-testid="corporateDelegateSelect"
            >
              {delegates.map(x => (
                <MenuItem key={x.userId} value={x.userId}>
                  {x.name.first} {x.name.last}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
