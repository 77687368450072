import React, { useEffect } from 'react';
import { Alert, AlertProps } from '@material-ui/lab';

interface Props extends Omit<AlertProps, 'onClose'> {
  show?: boolean;
  onHide?: () => void;
  autoHide?: boolean;
}

export const Feedback: React.FC<Props> = ({ show, onHide, autoHide = false, ...props }) => {
  useEffect(() => {
    if (!show || !onHide || !autoHide) return;

    const timerId = setTimeout(onHide, 15000);
    return () => clearTimeout(timerId);
  }, [show, autoHide]);

  if (!show) return null;

  return <Alert onClose={onHide} {...props} />;
};
