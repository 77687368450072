import React from 'react';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';

import * as Pages from './pages';

export const AreaRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}`} exact component={Pages.AreaListPage} />
    <Route path={`${match.path}/add`} exact component={Pages.AddAreaPage} />
    <Route path={`${match.path}/:areaId`} exact component={Pages.EditAreaPage} />
    <Redirect to={match.path} />
  </Switch>
);
