import React from 'react';
import { ActivityReport, activityReportQuery, PaginatedQuery, Reservation } from '@lib/state';
import { ShowMoreButton } from '..';
import { useObservable } from '../../utils';
import { Typography } from '@material-ui/core';
import { ActivityDisplay } from './activity-display.component';

interface Props {
  activity: ActivityReport[];
  reservations: Reservation[];
  userId: string;
  loadMore: (page?: PaginatedQuery) => void;
  showActions?: boolean;
  createForGuest: boolean;
}

export const ActivityHistory: React.FC<Props> = ({
  activity,
  reservations,
  userId,
  loadMore,
  showActions,
  createForGuest,
}) => {
  const pagination = useObservable(activityReportQuery.pagination);

  return (
    <>
      {activity.length === 0 ? (
        <EmptyActivityList />
      ) : (
        <ActivityList
          userId={userId}
          activity={activity}
          reservations={reservations}
          showActions={showActions}
          createForGuest={createForGuest}
        />
      )}

      <ShowMoreButton
        isDone={pagination.isDone}
        loadMore={() => loadMore({ continuationToken: pagination.continuationToken })}
      />
    </>
  );
};

const EmptyActivityList: React.FC = () => {
  return <Typography>No past activity</Typography>;
};

const ActivityList: React.FC<{
  userId?: string;
  showActions?: boolean;
  activity: ActivityReport[];
  reservations: Reservation[];
  createForGuest: boolean;
}> = ({ userId, activity, showActions, reservations, createForGuest }) => {
  return (
    <>
      {activity.map((a, i) => (
        <ActivityDisplay
          key={i}
          activity={a}
          divider={i !== activity.length - 1}
          showGuest={a.guest?.userId !== userId}
          showActions={showActions}
          reservation={reservations.find(x => x.id === a.reservationId)}
          createForGuest={createForGuest}
        />
      ))}
    </>
  );
};
