import React from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemText } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Section } from '../components';
import { NavMenuItemSection, NavMenuItemModel } from '@lib/state';

interface Props {
  menuSections: NavMenuItemSection[];
  handleDrawerClose: () => any;
}

export const NavMenuList: React.FC<Props> = ({ menuSections, handleDrawerClose }) => {
  return (
    <div>
      {menuSections.map(menuSection => (
        <Section title={menuSection.title} key={menuSection.title} textAlign="left" disableGutters>
          {menuSection.items.map(menuItem => (
            <NavMenuLink
              key={menuItem.title}
              menuItem={menuItem}
              handleDrawerClose={handleDrawerClose}
            >
              <ListItemText data-testid={menuItem.testId}>
                <FontAwesomeIcon icon={menuItem.icon} size="lg" fixedWidth />
                {' ' + menuItem.title}
              </ListItemText>
            </NavMenuLink>
          ))}
        </Section>
      ))}
    </div>
  );
};

interface LinkProps {
  menuItem: NavMenuItemModel;
  handleDrawerClose: () => any;
}

export const NavMenuLink: React.FC<LinkProps> = ({ menuItem, handleDrawerClose, children }) => {
  const AnchorLink = React.forwardRef<HTMLAnchorElement, React.HTMLProps<HTMLAnchorElement>>(
    (props, ref) => <a ref={ref} {...props} />
  );

  return (
    <>
      {menuItem.href ? (
        <ListItem
          button
          component={AnchorLink}
          href={menuItem.href}
          target={menuItem.target}
          onClick={handleDrawerClose}
        >
          {children}
        </ListItem>
      ) : (
        <ListItem
          button
          component={Link}
          to={menuItem.url}
          key={menuItem.title}
          onClick={handleDrawerClose}
        >
          {children}
        </ListItem>
      )}
    </>
  );
};
