import React, { useCallback } from 'react';
import { Box, Grid } from '@material-ui/core';

import { sessionQuery } from '@lib/state';
import { ButtonLink, FormTextField, Section, useObservable, usePageTitle } from '@lib/common';
import { AreaFilters, areaQuery, areaService } from 'app/state';
import { ListFilters, Tile } from 'app/shared';
import { AreaListResults } from '../components';

export const AreaListPage: React.FC = () => {
  usePageTitle('Common Areas');

  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const areas = useObservable(areaQuery.areas);

  const filters = useObservable(areaQuery.filters);
  const pagination = useObservable(areaQuery.pagination);

  const onChange = useCallback(
    (x: AreaFilters) => propertyId && areaService.getAreas(propertyId, x),
    [propertyId]
  );

  const loadMore = useCallback(
    () => propertyId && areaService.getAreas(propertyId, filters, pagination.continuationToken),
    [propertyId, filters, pagination]
  );

  return (
    <Section>
      <Tile>
        <Box display="flex" justifyContent="flex-end" flexDirection="row">
          <ButtonLink to="/areas/add" color="primary" variant="contained">
            New Common Area
          </ButtonLink>
        </Box>
        <ListFilters filters={filters} onChange={onChange}>
          <Grid container spacing={1}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormTextField name="name" label="Name" fullWidth size="small" />
            </Grid>
          </Grid>
        </ListFilters>
        <AreaListResults areas={areas} loadMore={loadMore} isDone={pagination.isDone} />
      </Tile>
    </Section>
  );
};
