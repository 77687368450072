import { from, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Properties } from '../api';
import { HousekeeperStore, housekeeperStore, HousekeeperUIState } from './house-keeper.store';

export class HousekeepingService {
  constructor(
    private readonly store: HousekeeperStore,
    private readonly housekeepingApi: Properties.HousekeepingApi
  ) {}

  loadHousekeepers(propertyId: string): void {
    from(this.housekeepingApi.propertyIdHousekeepingGet(propertyId))
      .pipe(map(response => response.data.data))
      .subscribe(data => {
        this.updateUI({ propertyId });
        this.store.upsertMany(data);
      });
  }

  reset() {
    this.store.reset();
  }

  private updateUI(values: Partial<HousekeeperUIState>) {
    this.store.update(({ ui }) => ({ ui: { ...ui, ...values } }));
  }
}

export const housekeepingService = new HousekeepingService(
  housekeeperStore,
  new Properties.HousekeepingApi()
);
