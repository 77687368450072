import React from 'react';
import { Button, Typography } from '@material-ui/core';

import { HousekeepingStatusEnum, RoomUpdate, Room } from '@lib/state';
import { Alert } from '@material-ui/lab';
import { GuestStay } from 'app/state';

interface Props {
  room: Room;
  stay?: GuestStay;
  onReadyForRent: (room: Room, update: RoomUpdate) => void;
}

export const ReadyForRent: React.FC<Props> = ({ room, stay, onReadyForRent }) => {
  if (
    !room.readyForRent &&
    room.housekeepingStatus === HousekeepingStatusEnum.NoCleaningRequired &&
    !stay
  ) {
    return (
      <Alert severity="warning">
        <Typography>
          This room has not had a checkout clean and is not considered ready to rent.
        </Typography>

        <Button
          onClick={() => {
            onReadyForRent(room, {
              housekeepingStatus: HousekeepingStatusEnum.NoCleaningRequired,
            });
          }}
          variant="contained"
          color="secondary"
        >
          Mark as Ready to Rent
        </Button>
      </Alert>
    );
  }

  return null;
};
