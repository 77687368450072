export enum CorporateAccountForms {
  Create = 'CreateCorporateAccount',
  Edit = 'EditCorporateAccount',
  AddHousekeeping = 'AddHousekeepingPolicy',
  UpdateHousekeeping = 'UpdateHousekeepingPolicy',
  RemoveHousekeeping = 'RemoveHousekeepingPolicy',
  AddDelegate = 'AddCorporateAccountDelegate',
  RemoveDelegate = 'RemoveCorporateAccountDelegate',
  AddReservationPolicy = 'AddReservationPolicy',
  UpdateReservationPolicy = 'UpdateReservationPolicy',
  RemoveReservationPolicy = 'RemoveReservationPolicy',
  UpdateZonePreferences = 'UpdateZonePreferences',
  ImportEmployees = 'ImportEmployees',
  SetInvoiceGrouping = 'SetInvoiceGrouping',
}
