import React, { useState } from 'react';
import { Typography, Button, Box, Link, makeStyles } from '@material-ui/core';
import { DateDisplay, DataTable } from '@lib/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuditLog, auditLogService, showImageIcon } from 'app/state';
import { SecurityImageModal } from './security-image.modal';

interface Props {
  logs: AuditLog[];
  isDone?: boolean;
  onLoadMore: () => void;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  detail: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto',
    flex: 1,
  },
});

export const LogTable: React.FC<Props> = ({ logs, isDone, onLoadMore }) => {
  const [showSecurityImageModal, setShowSecurityImageModal] = useState(false);
  const styles = useStyles();
  return (
    <>
      <DataTable
        stickyHeader
        items={logs}
        getItemKey={item => item.id}
        rowClass={item => `log-${item.category}`}
        columns={[
          {
            title: 'Date / Time',
            valueFactory: item =>
              item.timestamp ? (
                <DateDisplay date={new Date(item.timestamp)} dateFormat="M/d/yy - h:mm a" />
              ) : null,
          },
          {
            title: 'Property',
            valueFactory: item => (
              <div className={styles.container}>
                <Typography className={styles.detail}>{item.propertyName ?? 'N/A'}</Typography>
              </div>
            ),
          },
          {
            title: 'Details',
            colSpan: 4,
            valueFactory: item => (
              <div className={styles.container}>
                <Typography className={styles.detail}>{item.message} </Typography>
                {showImageIcon(item) ? (
                  <Link
                    onClick={() => {
                      auditLogService.loadDoorAccessSecurityImage(item.log, item.id);
                      setShowSecurityImageModal(true);
                    }}
                    variant="button"
                    color="secondary"
                  >
                    <FontAwesomeIcon icon="camera" />
                  </Link>
                ) : null}
              </div>
            ),
          },
        ]}
      />
      {!isDone ? (
        <Box textAlign="center">
          <Button onClick={() => onLoadMore()} color="secondary">
            <Typography>Show more</Typography>
          </Button>
        </Box>
      ) : null}
      {showSecurityImageModal ? (
        <SecurityImageModal
          show={showSecurityImageModal}
          onClose={() => setShowSecurityImageModal(false)}
        />
      ) : null}
    </>
  );
};
