import { useEffect } from 'react';
import { corporateContactService } from './corporate-contact.service';
import { corporateContactQuery } from './corporate-contact.query';
import { useObservable } from '@lib/common';

export function useGetContactsByAccountId(id: string) {
  useEffect(() => corporateContactService.getContactsByAccountId(id), [id]);

  return {
    contacts: useObservable(corporateContactQuery.contacts),
    loading: useObservable(corporateContactQuery.loading),
  };
}

export function useGetLoading() {
  return useObservable(corporateContactQuery.loading);
}
