import React from 'react';
import { RoomType } from '@lib/state';
import { Link, DataTable } from '@lib/common';

interface Props {
  roomTypes: Array<RoomType>;
}

export const RoomTypeList: React.FC<Props> = ({ roomTypes }) => {
  return (
    <DataTable
      items={roomTypes}
      getItemKey={roomType => roomType.id}
      columns={[
        {
          title: 'Name',
          valueFactory: roomType => <Link to={`/room-types/${roomType.id}`}>{roomType.name}</Link>,
        },
        {
          title: 'Room Type',
          valueFactory: roomType => roomType.description,
        },
      ]}
    />
  );
};
