import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  reservationQuery,
  sessionQuery,
  reservationService,
  propertyQuery,
  ReservationForms,
  FormStatus,
} from '@lib/state';
import {
  corporateAccountQuery,
  corporateAccountService,
  roomRatePlanQuery,
  roomRatePlanService,
} from 'app/state';
import { useObservable, usePageTitle, Section, useCurrentUserId, useFormEvents } from '@lib/common';
import { ReservationLookup, ReservationFilters } from '../components';

export const ReservationSearchPage: React.FC<RouteComponentProps> = () => {
  const customerId = useObservable(propertyQuery.activeProperty)?.customerId;
  const propertyId = useObservable(sessionQuery.propertyId);
  const reservations = useObservable(reservationQuery.searchResults);
  const filters = useObservable(reservationQuery.filters);
  const pagination = useObservable(reservationQuery.pagination);
  const roomRatePlans = useObservable(roomRatePlanQuery.ratePlans);
  const corporations = useObservable(corporateAccountQuery.corporations);
  const userId = useCurrentUserId();

  const [{ status: updateStatus }] = useFormEvents(ReservationForms.UpdateReservation);

  const [{ status: createStatus }] = useFormEvents(ReservationForms.CreateReservation);

  const [{ status: cancelStatus }] = useFormEvents(ReservationForms.CancelReservation);

  const [{ status: rebookStatus }] = useFormEvents(ReservationForms.RebookReservation);

  usePageTitle('Reservations');

  const onChange = useCallback(
    (filters: ReservationFilters) =>
      reservationService.getReservations({
        propertyId: propertyId!,
        ...filters,
      }),
    [propertyId]
  );

  useEffect(() => {
    if (
      updateStatus === FormStatus.Success ||
      createStatus === FormStatus.Success ||
      cancelStatus === FormStatus.Success ||
      rebookStatus === FormStatus.Success
    ) {
      reservationService.getReservations({
        propertyId: propertyId!,
        ...filters,
      });
    }
  }, [updateStatus, createStatus, cancelStatus, rebookStatus, propertyId, filters]);

  useEffect(() => {
    if (propertyId) {
      roomRatePlanService.getRatePlans({ propertyId, showDisabled: false });
    }
  }, [propertyId]);

  useEffect(() => {
    if (customerId) corporateAccountService.getAllCorporateAccounts(customerId);
  }, [customerId]);

  if (!propertyId) return null;

  const filterState = { propertyId, ...filters };

  const exportReservations = (filters: ReservationFilters) => {
    reservationService.exportReservations({
      propertyId: propertyId,
      ...filters,
    });
  };

  const loadMore = () =>
    reservationService.getReservations(filterState, pagination.continuationToken);

  return (
    <Section>
      <ReservationLookup
        reservations={reservations}
        propertyId={propertyId}
        roomRatePlans={roomRatePlans}
        corporateAccounts={corporations}
        onChange={onChange}
        onExport={exportReservations}
        loadMore={loadMore}
        filters={filterState}
        isDone={pagination.isDone}
        userId={userId}
      />
    </Section>
  );
};
