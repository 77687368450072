import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { Reservation } from '@lib/state';
import { NameDisplay, AddressDisplay, ButtonLink, kioskStyles } from '@lib/common';

interface Props {
  reservations: Array<Reservation>;
}

export const ReservationSearchResults: React.FC<Props> = ({ reservations }) => {
  const {
    hintText,
    listItem,
    internalRow,
    internalColumn,
    listItemButton,
    subtitleValue,
  } = kioskStyles();

  if (reservations.length === 0) {
    return (
      <Typography variant="h4" color="textPrimary" align="center" paragraph className={hintText}>
        No reservation was found for the provided name.
      </Typography>
    );
  } else {
    return (
      <Grid container>
        {reservations.map(x => (
          <Grid item xs={12} className={listItem}>
            <div className={internalRow}>
              <NameDisplay {...x.contacts[0].name} variant="h4" />
            </div>
            <div className={internalRow}>
              <AddressDisplay
                {...x.contacts[0].address}
                addressVariant="cityState"
                variant="subtitle2"
              />
            </div>
            <div className={internalRow}>
              <Typography variant="subtitle2">
                CONF CODE <span className={subtitleValue}>{x.recordNumber}</span>
              </Typography>
            </div>
            <div className={internalRow}>
              <div className={internalColumn}>
                <Typography variant="subtitle2">Email</Typography>
                <Typography variant="h4">{x.contacts[0].contact.email}</Typography>
              </div>
              <div className={internalColumn}>
                <Typography variant="subtitle2">Phone</Typography>
                <Typography variant="h4">{x.contacts[0].contact.phone}</Typography>
              </div>
            </div>
            <ButtonLink
              to={`/kiosk/${x.id}/verify`}
              size="large"
              fullWidth
              className={listItemButton}
            >
              Check-In
            </ButtonLink>
          </Grid>
        ))}
      </Grid>
    );
  }
};
