// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { BillingFrequencyType } from '../models';
import { CorporateAccountDelegateModel } from '../models';
import { CorporateAccountModel } from '../models';
import { CorporateAccountsListResponse } from '../models';
import { CorporateEmployeeSearchResponse } from '../models';
import { CreateCorporateAccountRequest } from '../models';
import { CreateInvoiceRequest } from '../models';
import { CreateInvoiceResponse } from '../models';
import { CreateInvoiceStatusModel } from '../models';
import { GetCorporateAccountItemResponse } from '../models';
import { GetCorporateZonePreferenceListResponse } from '../models';
import { GetInvoicesPaginatedListResponse } from '../models';
import { ImportStrategy } from '../models';
import { InvoiceType } from '../models';
import { LookupEmployeeListResponse } from '../models';
import { ProblemDetails } from '../models';
import { SetCorporateHousekeepingPolicyRequest } from '../models';
import { SetCorporateReservationPolicyRequest } from '../models';
import { SetInvoiceGroupingRequest } from '../models';
import { UpdateCorporateAccountRequest } from '../models';
import { UpdateCorporateZonePreferenceRequest } from '../models';
/**
 * CorporateAccountApi - axios parameter creator
 * @export
 */
export const CorporateAccountApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [customerId]
     * @param {string} [propertyId]
     * @param {string} [name]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {boolean} [hasDelegates]
     * @param {BillingFrequencyType} [billingFrequencyType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountExportGet(
      customerId?: string,
      propertyId?: string,
      name?: string,
      continuationToken?: string,
      limit?: number,
      hasDelegates?: boolean,
      billingFrequencyType?: BillingFrequencyType,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/corporateaccount/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerId !== undefined) {
        localVarQueryParameter['customerId'] = customerId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (hasDelegates !== undefined) {
        localVarQueryParameter['hasDelegates'] = hasDelegates;
      }

      if (billingFrequencyType !== undefined) {
        localVarQueryParameter['billingFrequencyType'] = billingFrequencyType;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [customerId]
     * @param {string} [propertyId]
     * @param {string} [name]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {boolean} [hasDelegates]
     * @param {BillingFrequencyType} [billingFrequencyType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountGet(
      customerId?: string,
      propertyId?: string,
      name?: string,
      continuationToken?: string,
      limit?: number,
      hasDelegates?: boolean,
      billingFrequencyType?: BillingFrequencyType,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/corporateaccount`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerId !== undefined) {
        localVarQueryParameter['customerId'] = customerId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (hasDelegates !== undefined) {
        localVarQueryParameter['hasDelegates'] = hasDelegates;
      }

      if (billingFrequencyType !== undefined) {
        localVarQueryParameter['billingFrequencyType'] = billingFrequencyType;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdDelegatesGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdDelegatesGet.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/delegates`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdDelegatesUserIdDelete(
      id: string,
      userId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdDelegatesUserIdDelete.'
        );
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling corporateaccountIdDelegatesUserIdDelete.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/delegates/{userId}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdDelegatesUserIdPut(
      id: string,
      userId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdDelegatesUserIdPut.'
        );
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling corporateaccountIdDelegatesUserIdPut.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/delegates/{userId}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdEmployeesExportGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdEmployeesExportGet.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/employees/export`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} [employeeNumber]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdEmployeesGet(
      id: string,
      employeeNumber?: string,
      continuationToken?: string,
      limit?: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdEmployeesGet.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/employees`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (employeeNumber !== undefined) {
        localVarQueryParameter['employeeNumber'] = employeeNumber;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {ImportStrategy} strategy
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdEmployeesImportPost(
      id: string,
      strategy: ImportStrategy,
      file: any,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdEmployeesImportPost.'
        );
      }
      // verify required parameter 'strategy' is not null or undefined
      if (strategy === null || strategy === undefined) {
        throw new RequiredError(
          'strategy',
          'Required parameter strategy was null or undefined when calling corporateaccountIdEmployeesImportPost.'
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          'file',
          'Required parameter file was null or undefined when calling corporateaccountIdEmployeesImportPost.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/employees/import`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (strategy !== undefined) {
        localVarFormParams.append('Strategy', strategy as any);
      }

      if (file !== undefined) {
        localVarFormParams.append('File', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} [employeeNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdEmployeesLookupGet(
      id: string,
      employeeNumber?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdEmployeesLookupGet.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/employees/lookup`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (employeeNumber !== undefined) {
        localVarQueryParameter['employeeNumber'] = employeeNumber;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdGet(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdGet.'
        );
      }
      const localVarPath = `/corporateaccount/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {string} [accountId]
     * @param {string} [propertyId]
     * @param {InvoiceType} [invoiceType]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdInvoiceGet(
      id: string,
      continuationToken?: string,
      limit?: number,
      accountId?: string,
      propertyId?: string,
      invoiceType?: InvoiceType,
      startDate?: string,
      endDate?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdInvoiceGet.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/invoice`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (accountId !== undefined) {
        localVarQueryParameter['accountId'] = accountId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (invoiceType !== undefined) {
        localVarQueryParameter['invoiceType'] = invoiceType;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdInvoiceInvoiceIdDelete(
      id: string,
      invoiceId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdInvoiceInvoiceIdDelete.'
        );
      }
      // verify required parameter 'invoiceId' is not null or undefined
      if (invoiceId === null || invoiceId === undefined) {
        throw new RequiredError(
          'invoiceId',
          'Required parameter invoiceId was null or undefined when calling corporateaccountIdInvoiceInvoiceIdDelete.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/invoice/{invoiceId}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'invoiceId'}}`, encodeURIComponent(String(invoiceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdInvoiceInvoiceIdGet(
      id: string,
      invoiceId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdInvoiceInvoiceIdGet.'
        );
      }
      // verify required parameter 'invoiceId' is not null or undefined
      if (invoiceId === null || invoiceId === undefined) {
        throw new RequiredError(
          'invoiceId',
          'Required parameter invoiceId was null or undefined when calling corporateaccountIdInvoiceInvoiceIdGet.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/invoice/{invoiceId}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'invoiceId'}}`, encodeURIComponent(String(invoiceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdInvoiceInvoiceIdResendGet(
      id: string,
      invoiceId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdInvoiceInvoiceIdResendGet.'
        );
      }
      // verify required parameter 'invoiceId' is not null or undefined
      if (invoiceId === null || invoiceId === undefined) {
        throw new RequiredError(
          'invoiceId',
          'Required parameter invoiceId was null or undefined when calling corporateaccountIdInvoiceInvoiceIdResendGet.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/invoice/{invoiceId}/resend`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'invoiceId'}}`, encodeURIComponent(String(invoiceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {SetInvoiceGroupingRequest} [setInvoiceGroupingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdInvoicegroupingPut(
      id: string,
      setInvoiceGroupingRequest?: SetInvoiceGroupingRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdInvoicegroupingPut.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/invoicegrouping`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof setInvoiceGroupingRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(setInvoiceGroupingRequest !== undefined ? setInvoiceGroupingRequest : {})
        : setInvoiceGroupingRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {SetCorporateHousekeepingPolicyRequest} [setCorporateHousekeepingPolicyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdPolicyHousekeepingPut(
      id: string,
      setCorporateHousekeepingPolicyRequest?: SetCorporateHousekeepingPolicyRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdPolicyHousekeepingPut.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/policy/housekeeping`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof setCorporateHousekeepingPolicyRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            setCorporateHousekeepingPolicyRequest !== undefined
              ? setCorporateHousekeepingPolicyRequest
              : {}
          )
        : setCorporateHousekeepingPolicyRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {SetCorporateReservationPolicyRequest} [setCorporateReservationPolicyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdPolicyReservationPut(
      id: string,
      setCorporateReservationPolicyRequest?: SetCorporateReservationPolicyRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdPolicyReservationPut.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/policy/reservation`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof setCorporateReservationPolicyRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            setCorporateReservationPolicyRequest !== undefined
              ? setCorporateReservationPolicyRequest
              : {}
          )
        : setCorporateReservationPolicyRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateCorporateAccountRequest} [updateCorporateAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdPost(
      id: string,
      updateCorporateAccountRequest?: UpdateCorporateAccountRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdPost.'
        );
      }
      const localVarPath = `/corporateaccount/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateCorporateAccountRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            updateCorporateAccountRequest !== undefined ? updateCorporateAccountRequest : {}
          )
        : updateCorporateAccountRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdZonesPropertyIdGet(
      id: string,
      propertyId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdZonesPropertyIdGet.'
        );
      }
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling corporateaccountIdZonesPropertyIdGet.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/zones/{propertyId}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {UpdateCorporateZonePreferenceRequest} [updateCorporateZonePreferenceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdZonesPropertyIdPut(
      id: string,
      propertyId: string,
      updateCorporateZonePreferenceRequest?: UpdateCorporateZonePreferenceRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling corporateaccountIdZonesPropertyIdPut.'
        );
      }
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling corporateaccountIdZonesPropertyIdPut.'
        );
      }
      const localVarPath = `/corporateaccount/{id}/zones/{propertyId}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateCorporateZonePreferenceRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            updateCorporateZonePreferenceRequest !== undefined
              ? updateCorporateZonePreferenceRequest
              : {}
          )
        : updateCorporateZonePreferenceRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateInvoiceRequest} [createInvoiceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountInvoicePost(
      createInvoiceRequest?: CreateInvoiceRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/corporateaccount/invoice`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createInvoiceRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createInvoiceRequest !== undefined ? createInvoiceRequest : {})
        : createInvoiceRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<string>} [corporateAccountIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountInvoiceStatusesGet(
      corporateAccountIds?: Array<string>,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/corporateaccount/invoice-statuses`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (corporateAccountIds) {
        localVarQueryParameter['corporateAccountIds'] = corporateAccountIds;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountMyAccountsGet(options: any = {}): RequestArgs {
      const localVarPath = `/corporateaccount/my-accounts`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateCorporateAccountRequest} [createCorporateAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountPost(
      createCorporateAccountRequest?: CreateCorporateAccountRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/corporateaccount`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createCorporateAccountRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            createCorporateAccountRequest !== undefined ? createCorporateAccountRequest : {}
          )
        : createCorporateAccountRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CorporateAccountApi - functional programming interface
 * @export
 */
export const CorporateAccountApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [customerId]
     * @param {string} [propertyId]
     * @param {string} [name]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {boolean} [hasDelegates]
     * @param {BillingFrequencyType} [billingFrequencyType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountExportGet(
      customerId?: string,
      propertyId?: string,
      name?: string,
      continuationToken?: string,
      limit?: number,
      hasDelegates?: boolean,
      billingFrequencyType?: BillingFrequencyType,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountExportGet(
        customerId,
        propertyId,
        name,
        continuationToken,
        limit,
        hasDelegates,
        billingFrequencyType,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [customerId]
     * @param {string} [propertyId]
     * @param {string} [name]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {boolean} [hasDelegates]
     * @param {BillingFrequencyType} [billingFrequencyType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountGet(
      customerId?: string,
      propertyId?: string,
      name?: string,
      continuationToken?: string,
      limit?: number,
      hasDelegates?: boolean,
      billingFrequencyType?: BillingFrequencyType,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CorporateAccountsListResponse> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountGet(
        customerId,
        propertyId,
        name,
        continuationToken,
        limit,
        hasDelegates,
        billingFrequencyType,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdDelegatesGet(
      id: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<Array<CorporateAccountDelegateModel>> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdDelegatesGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdDelegatesUserIdDelete(
      id: string,
      userId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdDelegatesUserIdDelete(id, userId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdDelegatesUserIdPut(
      id: string,
      userId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CorporateAccountDelegateModel> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdDelegatesUserIdPut(id, userId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdEmployeesExportGet(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdEmployeesExportGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} [employeeNumber]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdEmployeesGet(
      id: string,
      employeeNumber?: string,
      continuationToken?: string,
      limit?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CorporateEmployeeSearchResponse> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdEmployeesGet(id, employeeNumber, continuationToken, limit, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {ImportStrategy} strategy
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdEmployeesImportPost(
      id: string,
      strategy: ImportStrategy,
      file: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdEmployeesImportPost(id, strategy, file, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} [employeeNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdEmployeesLookupGet(
      id: string,
      employeeNumber?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LookupEmployeeListResponse> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdEmployeesLookupGet(id, employeeNumber, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdGet(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCorporateAccountItemResponse> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {string} [accountId]
     * @param {string} [propertyId]
     * @param {InvoiceType} [invoiceType]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdInvoiceGet(
      id: string,
      continuationToken?: string,
      limit?: number,
      accountId?: string,
      propertyId?: string,
      invoiceType?: InvoiceType,
      startDate?: string,
      endDate?: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetInvoicesPaginatedListResponse> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdInvoiceGet(
        id,
        continuationToken,
        limit,
        accountId,
        propertyId,
        invoiceType,
        startDate,
        endDate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdInvoiceInvoiceIdDelete(
      id: string,
      invoiceId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdInvoiceInvoiceIdDelete(id, invoiceId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdInvoiceInvoiceIdGet(
      id: string,
      invoiceId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdInvoiceInvoiceIdGet(id, invoiceId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdInvoiceInvoiceIdResendGet(
      id: string,
      invoiceId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdInvoiceInvoiceIdResendGet(id, invoiceId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {SetInvoiceGroupingRequest} [setInvoiceGroupingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdInvoicegroupingPut(
      id: string,
      setInvoiceGroupingRequest?: SetInvoiceGroupingRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCorporateAccountItemResponse> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdInvoicegroupingPut(id, setInvoiceGroupingRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {SetCorporateHousekeepingPolicyRequest} [setCorporateHousekeepingPolicyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdPolicyHousekeepingPut(
      id: string,
      setCorporateHousekeepingPolicyRequest?: SetCorporateHousekeepingPolicyRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCorporateAccountItemResponse> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdPolicyHousekeepingPut(id, setCorporateHousekeepingPolicyRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {SetCorporateReservationPolicyRequest} [setCorporateReservationPolicyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdPolicyReservationPut(
      id: string,
      setCorporateReservationPolicyRequest?: SetCorporateReservationPolicyRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCorporateAccountItemResponse> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdPolicyReservationPut(id, setCorporateReservationPolicyRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateCorporateAccountRequest} [updateCorporateAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdPost(
      id: string,
      updateCorporateAccountRequest?: UpdateCorporateAccountRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCorporateAccountItemResponse> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdPost(id, updateCorporateAccountRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdZonesPropertyIdGet(
      id: string,
      propertyId: string,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetCorporateZonePreferenceListResponse> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdZonesPropertyIdGet(id, propertyId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {UpdateCorporateZonePreferenceRequest} [updateCorporateZonePreferenceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdZonesPropertyIdPut(
      id: string,
      propertyId: string,
      updateCorporateZonePreferenceRequest?: UpdateCorporateZonePreferenceRequest,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetCorporateZonePreferenceListResponse> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountIdZonesPropertyIdPut(
        id,
        propertyId,
        updateCorporateZonePreferenceRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {CreateInvoiceRequest} [createInvoiceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountInvoicePost(
      createInvoiceRequest?: CreateInvoiceRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateInvoiceResponse> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountInvoicePost(createInvoiceRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {Array<string>} [corporateAccountIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountInvoiceStatusesGet(
      corporateAccountIds?: Array<string>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreateInvoiceStatusModel>> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountInvoiceStatusesGet(corporateAccountIds, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountMyAccountsGet(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CorporateAccountModel>> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountMyAccountsGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {CreateCorporateAccountRequest} [createCorporateAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountPost(
      createCorporateAccountRequest?: CreateCorporateAccountRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCorporateAccountItemResponse> {
      const localVarAxiosArgs = CorporateAccountApiAxiosParamCreator(
        configuration
      ).corporateaccountPost(createCorporateAccountRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * CorporateAccountApi - factory interface
 * @export
 */
export const CorporateAccountApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} [customerId]
     * @param {string} [propertyId]
     * @param {string} [name]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {boolean} [hasDelegates]
     * @param {BillingFrequencyType} [billingFrequencyType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountExportGet(
      customerId?: string,
      propertyId?: string,
      name?: string,
      continuationToken?: string,
      limit?: number,
      hasDelegates?: boolean,
      billingFrequencyType?: BillingFrequencyType,
      options?: any
    ): AxiosPromise<any> {
      return CorporateAccountApiFp(configuration).corporateaccountExportGet(
        customerId,
        propertyId,
        name,
        continuationToken,
        limit,
        hasDelegates,
        billingFrequencyType,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} [customerId]
     * @param {string} [propertyId]
     * @param {string} [name]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {boolean} [hasDelegates]
     * @param {BillingFrequencyType} [billingFrequencyType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountGet(
      customerId?: string,
      propertyId?: string,
      name?: string,
      continuationToken?: string,
      limit?: number,
      hasDelegates?: boolean,
      billingFrequencyType?: BillingFrequencyType,
      options?: any
    ): AxiosPromise<CorporateAccountsListResponse> {
      return CorporateAccountApiFp(configuration).corporateaccountGet(
        customerId,
        propertyId,
        name,
        continuationToken,
        limit,
        hasDelegates,
        billingFrequencyType,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdDelegatesGet(
      id: string,
      options?: any
    ): AxiosPromise<Array<CorporateAccountDelegateModel>> {
      return CorporateAccountApiFp(configuration).corporateaccountIdDelegatesGet(id, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdDelegatesUserIdDelete(
      id: string,
      userId: string,
      options?: any
    ): AxiosPromise<void> {
      return CorporateAccountApiFp(configuration).corporateaccountIdDelegatesUserIdDelete(
        id,
        userId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdDelegatesUserIdPut(
      id: string,
      userId: string,
      options?: any
    ): AxiosPromise<CorporateAccountDelegateModel> {
      return CorporateAccountApiFp(configuration).corporateaccountIdDelegatesUserIdPut(
        id,
        userId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdEmployeesExportGet(id: string, options?: any): AxiosPromise<any> {
      return CorporateAccountApiFp(configuration).corporateaccountIdEmployeesExportGet(id, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} [employeeNumber]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdEmployeesGet(
      id: string,
      employeeNumber?: string,
      continuationToken?: string,
      limit?: number,
      options?: any
    ): AxiosPromise<CorporateEmployeeSearchResponse> {
      return CorporateAccountApiFp(configuration).corporateaccountIdEmployeesGet(
        id,
        employeeNumber,
        continuationToken,
        limit,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {ImportStrategy} strategy
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdEmployeesImportPost(
      id: string,
      strategy: ImportStrategy,
      file: any,
      options?: any
    ): AxiosPromise<void> {
      return CorporateAccountApiFp(configuration).corporateaccountIdEmployeesImportPost(
        id,
        strategy,
        file,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {string} [employeeNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdEmployeesLookupGet(
      id: string,
      employeeNumber?: string,
      options?: any
    ): AxiosPromise<LookupEmployeeListResponse> {
      return CorporateAccountApiFp(configuration).corporateaccountIdEmployeesLookupGet(
        id,
        employeeNumber,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdGet(
      id: string,
      options?: any
    ): AxiosPromise<GetCorporateAccountItemResponse> {
      return CorporateAccountApiFp(configuration).corporateaccountIdGet(id, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} id
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {string} [accountId]
     * @param {string} [propertyId]
     * @param {InvoiceType} [invoiceType]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdInvoiceGet(
      id: string,
      continuationToken?: string,
      limit?: number,
      accountId?: string,
      propertyId?: string,
      invoiceType?: InvoiceType,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<GetInvoicesPaginatedListResponse> {
      return CorporateAccountApiFp(configuration).corporateaccountIdInvoiceGet(
        id,
        continuationToken,
        limit,
        accountId,
        propertyId,
        invoiceType,
        startDate,
        endDate,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {string} invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdInvoiceInvoiceIdDelete(
      id: string,
      invoiceId: string,
      options?: any
    ): AxiosPromise<void> {
      return CorporateAccountApiFp(configuration).corporateaccountIdInvoiceInvoiceIdDelete(
        id,
        invoiceId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {string} invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdInvoiceInvoiceIdGet(
      id: string,
      invoiceId: string,
      options?: any
    ): AxiosPromise<any> {
      return CorporateAccountApiFp(configuration).corporateaccountIdInvoiceInvoiceIdGet(
        id,
        invoiceId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {string} invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdInvoiceInvoiceIdResendGet(
      id: string,
      invoiceId: string,
      options?: any
    ): AxiosPromise<void> {
      return CorporateAccountApiFp(configuration).corporateaccountIdInvoiceInvoiceIdResendGet(
        id,
        invoiceId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {SetInvoiceGroupingRequest} [setInvoiceGroupingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdInvoicegroupingPut(
      id: string,
      setInvoiceGroupingRequest?: SetInvoiceGroupingRequest,
      options?: any
    ): AxiosPromise<GetCorporateAccountItemResponse> {
      return CorporateAccountApiFp(configuration).corporateaccountIdInvoicegroupingPut(
        id,
        setInvoiceGroupingRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {SetCorporateHousekeepingPolicyRequest} [setCorporateHousekeepingPolicyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdPolicyHousekeepingPut(
      id: string,
      setCorporateHousekeepingPolicyRequest?: SetCorporateHousekeepingPolicyRequest,
      options?: any
    ): AxiosPromise<GetCorporateAccountItemResponse> {
      return CorporateAccountApiFp(configuration).corporateaccountIdPolicyHousekeepingPut(
        id,
        setCorporateHousekeepingPolicyRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {SetCorporateReservationPolicyRequest} [setCorporateReservationPolicyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdPolicyReservationPut(
      id: string,
      setCorporateReservationPolicyRequest?: SetCorporateReservationPolicyRequest,
      options?: any
    ): AxiosPromise<GetCorporateAccountItemResponse> {
      return CorporateAccountApiFp(configuration).corporateaccountIdPolicyReservationPut(
        id,
        setCorporateReservationPolicyRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {UpdateCorporateAccountRequest} [updateCorporateAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdPost(
      id: string,
      updateCorporateAccountRequest?: UpdateCorporateAccountRequest,
      options?: any
    ): AxiosPromise<GetCorporateAccountItemResponse> {
      return CorporateAccountApiFp(configuration).corporateaccountIdPost(
        id,
        updateCorporateAccountRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdZonesPropertyIdGet(
      id: string,
      propertyId: string,
      options?: any
    ): AxiosPromise<GetCorporateZonePreferenceListResponse> {
      return CorporateAccountApiFp(configuration).corporateaccountIdZonesPropertyIdGet(
        id,
        propertyId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {string} propertyId
     * @param {UpdateCorporateZonePreferenceRequest} [updateCorporateZonePreferenceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountIdZonesPropertyIdPut(
      id: string,
      propertyId: string,
      updateCorporateZonePreferenceRequest?: UpdateCorporateZonePreferenceRequest,
      options?: any
    ): AxiosPromise<GetCorporateZonePreferenceListResponse> {
      return CorporateAccountApiFp(configuration).corporateaccountIdZonesPropertyIdPut(
        id,
        propertyId,
        updateCorporateZonePreferenceRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {CreateInvoiceRequest} [createInvoiceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountInvoicePost(
      createInvoiceRequest?: CreateInvoiceRequest,
      options?: any
    ): AxiosPromise<CreateInvoiceResponse> {
      return CorporateAccountApiFp(configuration).corporateaccountInvoicePost(
        createInvoiceRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {Array<string>} [corporateAccountIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountInvoiceStatusesGet(
      corporateAccountIds?: Array<string>,
      options?: any
    ): AxiosPromise<Array<CreateInvoiceStatusModel>> {
      return CorporateAccountApiFp(configuration).corporateaccountInvoiceStatusesGet(
        corporateAccountIds,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountMyAccountsGet(options?: any): AxiosPromise<Array<CorporateAccountModel>> {
      return CorporateAccountApiFp(configuration).corporateaccountMyAccountsGet(options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {CreateCorporateAccountRequest} [createCorporateAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    corporateaccountPost(
      createCorporateAccountRequest?: CreateCorporateAccountRequest,
      options?: any
    ): AxiosPromise<GetCorporateAccountItemResponse> {
      return CorporateAccountApiFp(configuration).corporateaccountPost(
        createCorporateAccountRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * CorporateAccountApi - object-oriented interface
 * @export
 * @class CorporateAccountApi
 * @extends {BaseAPI}
 */
export class CorporateAccountApi extends BaseAPI {
  /**
   *
   * @param {string} [customerId]
   * @param {string} [propertyId]
   * @param {string} [name]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {boolean} [hasDelegates]
   * @param {BillingFrequencyType} [billingFrequencyType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountExportGet(
    customerId?: string,
    propertyId?: string,
    name?: string,
    continuationToken?: string,
    limit?: number,
    hasDelegates?: boolean,
    billingFrequencyType?: BillingFrequencyType,
    options?: any
  ) {
    return CorporateAccountApiFp(this.configuration).corporateaccountExportGet(
      customerId,
      propertyId,
      name,
      continuationToken,
      limit,
      hasDelegates,
      billingFrequencyType,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [customerId]
   * @param {string} [propertyId]
   * @param {string} [name]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {boolean} [hasDelegates]
   * @param {BillingFrequencyType} [billingFrequencyType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountGet(
    customerId?: string,
    propertyId?: string,
    name?: string,
    continuationToken?: string,
    limit?: number,
    hasDelegates?: boolean,
    billingFrequencyType?: BillingFrequencyType,
    options?: any
  ) {
    return CorporateAccountApiFp(this.configuration).corporateaccountGet(
      customerId,
      propertyId,
      name,
      continuationToken,
      limit,
      hasDelegates,
      billingFrequencyType,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdDelegatesGet(id: string, options?: any) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdDelegatesGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdDelegatesUserIdDelete(id: string, userId: string, options?: any) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdDelegatesUserIdDelete(
      id,
      userId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdDelegatesUserIdPut(id: string, userId: string, options?: any) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdDelegatesUserIdPut(
      id,
      userId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdEmployeesExportGet(id: string, options?: any) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdEmployeesExportGet(
      id,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {string} [employeeNumber]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdEmployeesGet(
    id: string,
    employeeNumber?: string,
    continuationToken?: string,
    limit?: number,
    options?: any
  ) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdEmployeesGet(
      id,
      employeeNumber,
      continuationToken,
      limit,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {ImportStrategy} strategy
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdEmployeesImportPost(
    id: string,
    strategy: ImportStrategy,
    file: any,
    options?: any
  ) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdEmployeesImportPost(
      id,
      strategy,
      file,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {string} [employeeNumber]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdEmployeesLookupGet(id: string, employeeNumber?: string, options?: any) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdEmployeesLookupGet(
      id,
      employeeNumber,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdGet(id: string, options?: any) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {string} [accountId]
   * @param {string} [propertyId]
   * @param {InvoiceType} [invoiceType]
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdInvoiceGet(
    id: string,
    continuationToken?: string,
    limit?: number,
    accountId?: string,
    propertyId?: string,
    invoiceType?: InvoiceType,
    startDate?: string,
    endDate?: string,
    options?: any
  ) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdInvoiceGet(
      id,
      continuationToken,
      limit,
      accountId,
      propertyId,
      invoiceType,
      startDate,
      endDate,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {string} invoiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdInvoiceInvoiceIdDelete(id: string, invoiceId: string, options?: any) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdInvoiceInvoiceIdDelete(
      id,
      invoiceId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {string} invoiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdInvoiceInvoiceIdGet(id: string, invoiceId: string, options?: any) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdInvoiceInvoiceIdGet(
      id,
      invoiceId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {string} invoiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdInvoiceInvoiceIdResendGet(id: string, invoiceId: string, options?: any) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdInvoiceInvoiceIdResendGet(
      id,
      invoiceId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {SetInvoiceGroupingRequest} [setInvoiceGroupingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdInvoicegroupingPut(
    id: string,
    setInvoiceGroupingRequest?: SetInvoiceGroupingRequest,
    options?: any
  ) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdInvoicegroupingPut(
      id,
      setInvoiceGroupingRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {SetCorporateHousekeepingPolicyRequest} [setCorporateHousekeepingPolicyRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdPolicyHousekeepingPut(
    id: string,
    setCorporateHousekeepingPolicyRequest?: SetCorporateHousekeepingPolicyRequest,
    options?: any
  ) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdPolicyHousekeepingPut(
      id,
      setCorporateHousekeepingPolicyRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {SetCorporateReservationPolicyRequest} [setCorporateReservationPolicyRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdPolicyReservationPut(
    id: string,
    setCorporateReservationPolicyRequest?: SetCorporateReservationPolicyRequest,
    options?: any
  ) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdPolicyReservationPut(
      id,
      setCorporateReservationPolicyRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {UpdateCorporateAccountRequest} [updateCorporateAccountRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdPost(
    id: string,
    updateCorporateAccountRequest?: UpdateCorporateAccountRequest,
    options?: any
  ) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdPost(
      id,
      updateCorporateAccountRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {string} propertyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdZonesPropertyIdGet(id: string, propertyId: string, options?: any) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdZonesPropertyIdGet(
      id,
      propertyId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {string} propertyId
   * @param {UpdateCorporateZonePreferenceRequest} [updateCorporateZonePreferenceRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountIdZonesPropertyIdPut(
    id: string,
    propertyId: string,
    updateCorporateZonePreferenceRequest?: UpdateCorporateZonePreferenceRequest,
    options?: any
  ) {
    return CorporateAccountApiFp(this.configuration).corporateaccountIdZonesPropertyIdPut(
      id,
      propertyId,
      updateCorporateZonePreferenceRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {CreateInvoiceRequest} [createInvoiceRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountInvoicePost(createInvoiceRequest?: CreateInvoiceRequest, options?: any) {
    return CorporateAccountApiFp(this.configuration).corporateaccountInvoicePost(
      createInvoiceRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {Array<string>} [corporateAccountIds]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountInvoiceStatusesGet(corporateAccountIds?: Array<string>, options?: any) {
    return CorporateAccountApiFp(this.configuration).corporateaccountInvoiceStatusesGet(
      corporateAccountIds,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountMyAccountsGet(options?: any) {
    return CorporateAccountApiFp(this.configuration).corporateaccountMyAccountsGet(options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {CreateCorporateAccountRequest} [createCorporateAccountRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorporateAccountApi
   */
  public corporateaccountPost(
    createCorporateAccountRequest?: CreateCorporateAccountRequest,
    options?: any
  ) {
    return CorporateAccountApiFp(this.configuration).corporateaccountPost(
      createCorporateAccountRequest,
      options
    )(this.axios, this.basePath);
  }
}
