import { Reservations } from '../api';
import { format } from 'date-fns';

export type ActivityReport = Reservations.ActivityReportModel & {
  createdByUser?: Reservations.UserRefModel;
};

export function exportFileName(start: Date, end: Date) {
  return `past-activity-from-${format(start, 'MM-dd-yyyy')}-to-${format(end, 'MM-dd-yyyy')}.csv`;
}

export function activityMapper({ users = [] }: { users?: Reservations.UserRefModel[] } = {}) {
  const userMap = new Map(users.map(u => [u.id, u]));

  type Input = Reservations.ActivityReportModel;
  type Output = ActivityReport;

  function map(model: Input): Output {
    return {
      ...model,
      createdByUser: model.createdByUserId ? userMap.get(model.createdByUserId) : undefined,
    };
  }

  function mapper(model: Input): Output;
  function mapper(model: Input[]): Output[];
  function mapper(model: Input | Input[]) {
    if (Array.isArray(model)) {
      return model.map(map);
    }
    return map(model);
  }

  return mapper;
}
