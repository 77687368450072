import { from } from 'rxjs';
import {
  CreateVipHousekeepingOverrideRequest,
  UpdateVipHousekeepingOverrideRequest,
} from './vip-housekeeping-overrides.model';
import {
  VipHousekeepingOverrideStore,
  vipHousekeepingOverrideStore,
} from './vip-housekeeping-overrides.store';
import { map } from 'rxjs/operators';
import { dispatchForm } from '../forms';
import { VipHousekeepingOverrideForms } from './vip-housekeeping-override.form';
import { applyTransaction, setLoading } from '@datorama/akita';
import { HousekeepingPolicy } from '../models.common';
import { VipHousekeepingOverrideApi } from '../api/generated/reservations';

export class VipHousekeepingOverrideService {
  constructor(
    private readonly api: VipHousekeepingOverrideApi,
    private readonly store: VipHousekeepingOverrideStore
  ) {}

  public createVipOverride(request: CreateVipHousekeepingOverrideRequest, propertyId: string) {
    const policy: HousekeepingPolicy = {
      fullCleanFrequency: request.housekeepingPolicy?.fullCleanFrequency
        ? +request.housekeepingPolicy?.fullCleanFrequency
        : undefined,
      touchUpCleanFrequency: request.housekeepingPolicy?.touchUpCleanFrequency
        ? +request.housekeepingPolicy?.touchUpCleanFrequency
        : undefined,
    };

    from(
      this.api.propertyIdViphousekeepingoverridePut(propertyId, {
        ...request,
        housekeepingPolicy: policy,
      })
    )
      .pipe(
        map(data => data.data.data),
        dispatchForm(VipHousekeepingOverrideForms.CreateOverride)
      )
      .subscribe(data => applyTransaction(() => this.store.upsert(data.id, data)));
  }

  public updateVipOverride(request: UpdateVipHousekeepingOverrideRequest, propertyId: string) {
    const policy: HousekeepingPolicy = {
      fullCleanFrequency: request.housekeepingPolicy?.fullCleanFrequency
        ? +request.housekeepingPolicy?.fullCleanFrequency
        : undefined,
      touchUpCleanFrequency: request.housekeepingPolicy?.touchUpCleanFrequency
        ? +request.housekeepingPolicy?.touchUpCleanFrequency
        : undefined,
    };

    from(
      this.api.propertyIdViphousekeepingoverridePost(propertyId, {
        ...request,
        housekeepingPolicy: policy,
      })
    )
      .pipe(
        map(data => data.data.data),
        dispatchForm(VipHousekeepingOverrideForms.UpdateOverride)
      )
      .subscribe(data => applyTransaction(() => this.store.update(data.id, data)));
  }

  public getVipOverrideById(id: string, propertyId: string) {
    from(this.api.propertyIdViphousekeepingoverrideIdGet(id, propertyId))
      .pipe(
        map(data => data.data.data),
        setLoading(this.store)
      )
      .subscribe(data => applyTransaction(() => this.store.upsert(data.id, data)));
  }

  public getVipOverrides(propertyId: string) {
    from(this.api.propertyIdViphousekeepingoverrideGet(propertyId))
      .pipe(
        map(data => data.data.data || []),
        setLoading(this.store)
      )
      .subscribe(data => applyTransaction(() => this.store.upsertMany(data)));
  }

  public removeVipOverride(id: string, propertyId: string) {
    from(this.api.propertyIdViphousekeepingoverrideIdDelete(id, propertyId))
      .pipe(dispatchForm(VipHousekeepingOverrideForms.DeleteOverride))
      .subscribe(applyTransaction(() => this.store.remove(id)));
  }
}

export const vipHousekeepingOverrideService = new VipHousekeepingOverrideService(
  new VipHousekeepingOverrideApi(),
  vipHousekeepingOverrideStore
);
