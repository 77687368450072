import { useObservable } from './useObservable';
import { sessionQuery } from '@lib/state';

export function useCurrentUser() {
  const user = useObservable(sessionQuery.currentUser);
  return user;
}

export function useCurrentUserId() {
  const user = useCurrentUser();
  return user?.sub;
}
