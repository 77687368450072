import { resetStores } from '@datorama/akita';
import { AxiosResponse } from 'axios';
import { EMPTY, from, Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import { AuthApi, JwtToken } from '../api/auth';
import { Reservations, Properties } from '../api/generated';
import { Property } from '../property';
import { dispatchForm } from '../forms';
import { AccountForms } from '../account';
import { SessionStore, sessionStore, SessionState } from './session.store';
import { Authenticated, AuthState, IAuthToken, mapAuthState, UserInfo } from './session.model';

export class SessionService {
  constructor(
    private readonly store: SessionStore,
    private readonly authApi: AuthApi,
    private readonly employeeCodeApi: Reservations.EmployeeCodeApi,
    private readonly employeesApi: Properties.EmployeesApi
  ) {}

  login(username: string, password: string) {
    return from(this.authApi.login(username, password))
      .pipe(this.handleLoginResponse(), dispatchForm(AccountForms.Login), this.handleLoginError())
      .toPromise();
  }

  async loginWithUserKey(userKey: string) {
    return from(this.authApi.loginWithUserKey(userKey))
      .pipe(this.handleLoginResponse(), dispatchForm(AccountForms.QRLogin), this.handleLoginError())
      .toPromise();
  }

  private handleLoginResponse() {
    return (source: Observable<AxiosResponse<JwtToken>>) =>
      source.pipe(
        switchMap(async response => {
          const auth = mapAuthState(response.data);
          return await this.refreshUserInfo(auth);
        })
      );
  }

  private handleLoginError() {
    return (source: Observable<UserInfo | undefined>) =>
      source.pipe(
        catchError(err => {
          console.log('Login failed', err);
          return of(new Error('Login failed'));
        })
      );
  }

  private currentRefresh: Promise<boolean> | undefined;

  async refreshToken(token: IAuthToken) {
    // only a single refresh is necessary, even if multiple concurrent requests are executing
    let currentRefresh = this.currentRefresh;
    if (!currentRefresh) {
      this.currentRefresh = currentRefresh = this.refreshTokenImpl(token);
    }

    return await currentRefresh;
  }

  async refreshTokenImpl(token: IAuthToken) {
    try {
      if (token.refreshToken == null) throw new Error('No refresh token provided');
      const response = await this.authApi.refreshToken(token.refreshToken);
      const auth = mapAuthState(response.data);
      await this.refreshUserInfo(auth);
      return true;
    } catch {
      this.resetStores();
      return false;
    } finally {
      this.currentRefresh = undefined;
    }
  }

  async updateUserInfo() {
    let auth = this.store.getValue().auth;
    if (!auth.authenticated) throw new Error('User is not authenticated');
    if (!auth.token) throw new Error('No refresh token provided');
    return await this.refreshToken(auth.token);
  }

  private async refreshUserInfo(auth: AuthState) {
    let user: UserInfo | undefined;
    if (auth.authenticated) {
      const response = await this.authApi.userInfo(auth.token.accessToken);
      user = response.data;
    } else {
      user = undefined;
    }

    this.store.update({
      auth,
      user,
    });

    return user;
  }

  /**
   * Logs the current user out but keeps there session stored in a state variable so it can be resumed later..
   *
   * @remarks
   * This method is designed for a manager to log out so a guest is able to login and check-in before resuming the managers session.
   *
   * @param pathToResume - If you would like to store a reference to a path to resume to provide it here.
   */
  preserveSessionAndLogout() {
    let currentSession: SessionState = this.store.getValue();

    //logout session
    this.resetStores();

    //preserve session
    this.store.update(({ ui }) => ({
      ui: {
        ...ui,
        preservedSession: currentSession,
      },
    }));
  }

  /**
   * Logs the current user out and if there is a preserved session, that session will be resumed.
   *
   * @remarks
   * This method is designed for after a guest has completed their check-in to resume the managers session.
   *
   * @returns - Returns a boolean of if there was a sesison resumed or not.
   */
  logoutAndResumePreviousSession(): boolean {
    const preservedSession: SessionState | undefined = this.store.getValue().ui.preservedSession;

    //logout
    this.resetStores();

    // if a sesion was preserved, restore it's state
    if (preservedSession) {
      this.store.update(preservedSession);
    }

    //return if there was a session to restore or not
    return preservedSession !== null;
  }

  resetStores() {
    resetStores({
      exclude: ['properties'],
    });
  }

  logout() {
    let auth = this.store.getValue().auth;
    if (!auth.authenticated || !auth.token.refreshToken) {
      this.resetStores();
      return;
    }
    from(this.authApi.revokeToken(auth.token.refreshToken))
      .pipe(
        catchError(() => EMPTY) // do not propogate error
      )
      .subscribe(() => {
        this.resetStores();
      });
  }

  bypassLandingPage(bypass = true) {
    this.store.update(({ ui }) => ({
      ui: {
        ...ui,
        ignoreLandingPage: bypass,
      },
    }));
  }

  switchProperty(propertyId?: Property['id']) {
    this.store.update(({ propertyId: activePropertyId }) => {
      const newPropertyId = propertyId ?? null;

      // avoid resetting data if we don't have to
      if (activePropertyId === newPropertyId) return {};

      // if the property changed, then the code / roles may have changed too
      return {
        propertyId: newPropertyId,
        employeeCode: null,
        employeeRoles: [],
      };
    });
  }

  resetProperty(properties: Array<{ id: Property['id'] }>) {
    this.store.update(({ propertyId: activePropertyId }) => {
      // we loaded a matching property, so keep it active
      if (properties.some(property => property.id === activePropertyId)) return {};

      // if there is no match, default to the first one
      if (properties.length > 0) return { propertyId: properties[0].id };

      // finally, we loaded nothing
      return { propertyId: null };
    });
  }

  getEmployeeCode(propertyId: Property['id']) {
    from(this.employeeCodeApi.employeecodePropertyIdGet(propertyId)).subscribe(({ data }) =>
      this.store.update({ employeeCode: data.code })
    );
  }

  getEmployeeRoles(propertyId: Property['id']) {
    from(this.employeesApi.employeesMeGet(propertyId))
      .pipe(
        map(({ data }) => {
          return { employee: data.data, customer: data.customer };
        })
      )
      .subscribe(data => {
        this.store.update({
          employeeRoles: data.employee.propertyRoles.map(x => x.role),
          guestPortalUrl: data.customer?.guestPortalUrl,
        });
      });
  }
}

export const sessionService = new SessionService(
  sessionStore,
  new AuthApi(),
  new Reservations.EmployeeCodeApi(),
  new Properties.EmployeesApi()
);
