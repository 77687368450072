import { StyledDialog, useFormEvents } from '@lib/common';
import {
  FormStatus,
  HousekeepingPolicy,
  VipHousekeepingOverrideForms,
  VipStatus,
  vipHousekeepingOverrideService,
} from '@lib/state';
import React, { useCallback, useEffect } from 'react';
import { VipHousekeepingOverrideForm } from './vip-housekeeping-override-form';
import { DialogContent } from '@material-ui/core';

interface Props {
  vipStatus: VipStatus;
  onClose: () => void;
  open: boolean;
  propertyId: string;
}

export function CreateVipHousekeepingOverrideModal({
  vipStatus,
  onClose: close,
  open,
  propertyId,
}: Props) {
  const [{ status, error }, reset] = useFormEvents(VipHousekeepingOverrideForms.CreateOverride);

  const onClose = useCallback(() => {
    reset();
    close();
  }, [reset, close]);

  const onSubmit = useCallback(
    (housekeepingPolicy: HousekeepingPolicy) => {
      vipHousekeepingOverrideService.createVipOverride(
        { status: vipStatus, housekeepingPolicy, propertyId },
        propertyId
      );
    },
    [vipStatus, propertyId]
  );

  useEffect(() => {
    if (status === FormStatus.Success) onClose();
  }, [status, onClose]);

  return (
    <StyledDialog
      title={`Override Housekeeping Policy for ${vipStatus} VIP`}
      onClose={onClose}
      open={open}
    >
      <DialogContent>
        <VipHousekeepingOverrideForm formStatus={status} onSubmit={onSubmit} error={error} />
      </DialogContent>
    </StyledDialog>
  );
}
