import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useFormContext, ValidationOptions } from 'react-hook-form';
import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { GuestProfile } from '@lib/state';
import { useObservable } from '@lib/common';
import { guestManagementQuery, guestManagementService } from 'app/state';
import { invalidCharacter } from '@lib/common/forms/util';

interface Props {
  name: string;
  validationOptions?: ValidationOptions;
  excludeEmails?: string[];
  onGuestSelected?: (guest?: GuestProfile) => void;
  onClear?: () => void;
}

export const GuestEmailLookupInput: React.FC<Props & TextFieldProps> = ({
  name,
  validationOptions,
  excludeEmails = [],
  onGuestSelected,
  onClear,
  defaultValue = '',
  ...props
}) => {
  const { register, unregister, setValue, watch } = useFormContext();

  useEffect(() => {
    register(name, {
      ...validationOptions,
      validate: value => !invalidCharacter(value) || 'Invalid email address',
    });

    return () => unregister(name);
    // eslint-disable-next-line
  }, [name, validationOptions]);

  const [inputValue, setInputValue] = useState(
    (typeof defaultValue === 'string' ? defaultValue : undefined) ?? ''
  );
  const value = watch(name, defaultValue);

  const [hasError, setHasError] = useState<boolean>(false);

  const onInputChange = useCallback(
    (_, input) => {
      setInputValue(input || value);
      setValue(name, input || value);
      setHasError(invalidCharacter(input || value));
    },
    [value, name, setValue, setHasError]
  );
  const onChange = useCallback(
    (_, opt, reason) => {
      if (reason === 'clear') {
        if (onClear) {
          onClear();
        }
      }
      if (typeof opt === 'string' || opt == null) {
        onGuestSelected?.();
        setValue(name, opt ?? '');
        setHasError(invalidCharacter(opt ?? ''));
      } else {
        onGuestSelected?.(opt);
        setValue(name, opt.contact?.email ?? '');
        setHasError(invalidCharacter(opt.contact?.email ?? ''));
      }
    },
    [name, onGuestSelected, setValue, onClear, setHasError]
  );

  const [guests, loading] = useGuestSearch({ email: inputValue, excludeEmails });

  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      open={open && inputValue.length > 0}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={guests}
      getOptionLabel={(opt: any) => opt.contact?.email ?? ''}
      getOptionSelected={(opt: any, val: any) => opt.contact?.email === val}
      loading={loading}
      renderInput={(params: any) => <TextField {...params} {...props} error={hasError} />}
      value={value}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      freeSolo
      data-testid="guestEmailLookupInput"
    />
  );
};

function useGuestSearch({
  email,
  excludeEmails = [],
}: {
  email: string;
  excludeEmails?: string[];
}): [GuestProfile[], boolean] {
  const loading = useObservable(guestManagementQuery.loading);
  const guests = useObservable(guestManagementQuery.guests);

  useEffect(() => {
    if (email?.length > 0) guestManagementService.getGuests({ email });
  }, [email]);

  return useMemo(
    () => [guests.filter(g => !excludeEmails.includes(g.contact.email ?? '')), loading],
    [guests, loading, excludeEmails]
  );
}
