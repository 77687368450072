import { EnumValueDisplay } from '@lib/common';
import { Room } from '@lib/state';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';

interface Props {
  room: Room;
  occupied?: boolean;
}

export function RoomDetailTab({ room, occupied }: Props) {
  const lockStatus = room.doorControllerStatus?.isOnline ? ' Online' : ' Offline';

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2" color="textPrimary">
          Room Number
        </Typography>
        <Typography>{room.roomNumber}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2" color="textPrimary">
          Room Type
        </Typography>
        <Typography>{room.roomType.name}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2" color="textPrimary">
          Zone
        </Typography>
        <Typography>{room.roomZoneName}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2" color="textPrimary">
          Occupied
        </Typography>
        <Typography>{occupied ? 'Yes' : 'No'}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2" color="textPrimary">
          Clean Status
        </Typography>
        <EnumValueDisplay value={room.housekeepingStatus} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2" color="textPrimary">
          Provisioned Device
        </Typography>
        <Typography>{room.doorControllerStatus?.isProvisioned ? lockStatus : ' None'}</Typography>
      </Grid>
    </Grid>
  );
}
