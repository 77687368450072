// tslint:disable
/**
 * Property Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { GetRoomCountsResponse } from '../models';
import { ProblemDetails } from '../models';
import { RoomCountType } from '../models';
/**
 * RoomCountsApi - axios parameter creator
 * @export
 */
export const RoomCountsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [roomTypeId]
     * @param {RoomCountType} [roomCountType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomcountsGet(
      propertyId: string,
      startDate?: string,
      endDate?: string,
      roomTypeId?: string,
      roomCountType?: RoomCountType,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling propertyIdRoomcountsGet.'
        );
      }
      const localVarPath = `/{propertyId}/roomcounts`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (roomTypeId !== undefined) {
        localVarQueryParameter['roomTypeId'] = roomTypeId;
      }

      if (roomCountType !== undefined) {
        localVarQueryParameter['roomCountType'] = roomCountType;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RoomCountsApi - functional programming interface
 * @export
 */
export const RoomCountsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [roomTypeId]
     * @param {RoomCountType} [roomCountType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomcountsGet(
      propertyId: string,
      startDate?: string,
      endDate?: string,
      roomTypeId?: string,
      roomCountType?: RoomCountType,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomCountsResponse> {
      const localVarAxiosArgs = RoomCountsApiAxiosParamCreator(
        configuration
      ).propertyIdRoomcountsGet(propertyId, startDate, endDate, roomTypeId, roomCountType, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * RoomCountsApi - factory interface
 * @export
 */
export const RoomCountsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [roomTypeId]
     * @param {RoomCountType} [roomCountType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    propertyIdRoomcountsGet(
      propertyId: string,
      startDate?: string,
      endDate?: string,
      roomTypeId?: string,
      roomCountType?: RoomCountType,
      options?: any
    ): AxiosPromise<GetRoomCountsResponse> {
      return RoomCountsApiFp(configuration).propertyIdRoomcountsGet(
        propertyId,
        startDate,
        endDate,
        roomTypeId,
        roomCountType,
        options
      )(axios, basePath);
    },
  };
};

/**
 * RoomCountsApi - object-oriented interface
 * @export
 * @class RoomCountsApi
 * @extends {BaseAPI}
 */
export class RoomCountsApi extends BaseAPI {
  /**
   *
   * @param {string} propertyId
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {string} [roomTypeId]
   * @param {RoomCountType} [roomCountType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomCountsApi
   */
  public propertyIdRoomcountsGet(
    propertyId: string,
    startDate?: string,
    endDate?: string,
    roomTypeId?: string,
    roomCountType?: RoomCountType,
    options?: any
  ) {
    return RoomCountsApiFp(this.configuration).propertyIdRoomcountsGet(
      propertyId,
      startDate,
      endDate,
      roomTypeId,
      roomCountType,
      options
    )(this.axios, this.basePath);
  }
}
