import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container, Grid, Typography, Button } from '@material-ui/core';
import { kioskStyles, usePageTitle } from '@lib/common';

export const QrErrorPage: React.FC<RouteComponentProps> = props => {
  const history = props.history;
  const { hintText, listContentBlock, kioskTextButton } = kioskStyles();

  usePageTitle('Invalid QR Code');

  const acknowledgeError = () => {
    history.goBack();
  };

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Typography variant="h4" className={hintText}>
          The QR Code you have scanned is invalid. Please make sure you used your Key found under
          'My Key' at armadillo.com.
        </Typography>
        <Grid item xs={12} className={listContentBlock}>
          <Button
            onClick={acknowledgeError}
            className={kioskTextButton}
            variant="contained"
            fullWidth
          >
            Try again
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
