import React from 'react';
import { accountService, FormStatus, AccountForms } from '@lib/state';
import { useFormEvents, ForgotPasswordForm, ForgotPasswordFormFields } from '@lib/common';
import { Typography, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();

  const [{ status, error }, resetFormUI] = useFormEvents(AccountForms.ForgotPassword);

  const submit = ({ username }: ForgotPasswordFormFields) => {
    accountService.forgotPassword(username);
  };

  const onCancel = () => {
    history.push(`/account/signin`);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="subtitle1" align="center" color="textSecondary">
        FORGOT PASSWORD
      </Typography>
      <ForgotPasswordForm
        pending={status === FormStatus.Pending}
        success={status === FormStatus.Success}
        error={error}
        onSubmit={submit}
        onResetFeedback={resetFormUI}
        onCancel={onCancel}
      />
    </Container>
  );
};
