import React, { useCallback } from 'react';
import { DialogContent } from '@material-ui/core';

import { StyledDialog } from '@lib/common';
import { RoomZone, roomsService, RoomsFilters } from '@lib/state';
import { GuestStaysFilters, guestStaysService } from 'app/state';
import { GuestStaysFiltersForm } from './tape-chart-filters-form';
import { RoomTypeModel } from '@lib/state/api/generated/properties';

interface Props {
  guestStaysFilters: GuestStaysFilters;
  roomsFilters: RoomsFilters;
  open: boolean;
  zones: Array<RoomZone>;
  roomTypes: Array<RoomTypeModel>;
  onClose: () => void;
}

export const GuestStaysFiltersModal: React.FC<Props> = ({
  guestStaysFilters,
  roomsFilters,
  open,
  zones,
  roomTypes,
  onClose,
}) => {
  const updateGuestStaysFilters = useCallback(filters => {
    // Done changing/selecting filters
    guestStaysService.updateGuestStaysFilters(filters);
  }, []);

  const updateRoomsFilters = useCallback(filters => {
    // Done changing/selecting filters
    roomsService.updateRoomsFilters(filters);
  }, []);

  if (!open) return null;

  return (
    <StyledDialog title="Filter Tape Chart" open={open} onClose={onClose}>
      <DialogContent>
        <GuestStaysFiltersForm
          guestStaysFilters={guestStaysFilters}
          roomsFilters={roomsFilters}
          zones={zones}
          roomTypes={roomTypes}
          onGuestStaysFiltersChange={updateGuestStaysFilters}
          onRoomsFiltersChange={updateRoomsFilters}
        />
      </DialogContent>
    </StyledDialog>
  );
};
