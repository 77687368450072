import { useEffect, useMemo } from 'react';

import { corporateDelegateQuery, sessionQuery, corporateDelegateService } from '@lib/state';

import { useCurrentUserId, useObservable } from '../../utils';

export function useBookingRole(corporateAccountId?: string) {
  useEffect(() => {
    if (corporateAccountId) {
      corporateDelegateService.getDelegatesByAccountId(corporateAccountId);
    }
  }, [corporateAccountId]);

  const delegates = useObservable(corporateDelegateQuery.delegates);
  const loggedInUserId = useCurrentUserId();
  const isEmployee = useObservable(sessionQuery.isEmployee) ?? false;

  const isDelegate = useMemo(() => {
    if (delegates === undefined) return false;

    return delegates.find(d => d.userId === loggedInUserId) !== undefined;
  }, [delegates, loggedInUserId]);

  return { isEmployee, isDelegate };
}
