import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { AvailablePaymentMethods, GuestProfile } from '@lib/state';
import { PaymentMethodSelect } from './payment-method-select';
import { GuestInformationForm } from './guest-information.form';
import { CreditCardForm } from './credit-card.form';

interface Props {
  paymentMethods: AvailablePaymentMethods;
  guest: GuestProfile;
  optional?: boolean;
  noSave?: boolean;
  onPaymentInfoLoaded?: () => void;
}

export const PaymentInfoForm: React.FC<Props> = ({
  paymentMethods,
  guest,
  noSave,
  onPaymentInfoLoaded,
  ...props
}) => {
  const { creditCards, invoiceAccounts } = paymentMethods;
  const { watch } = useFormContext();

  const paymentProfileId = watch('paymentProfileId');

  const newCardNumber = watch('card.cardNumber');

  useEffect(() => {
    if (!onPaymentInfoLoaded) return;

    if (creditCards.length > 0 || invoiceAccounts.length > 0) onPaymentInfoLoaded();

    if (!!newCardNumber) onPaymentInfoLoaded();
  }, [onPaymentInfoLoaded, creditCards, invoiceAccounts, paymentProfileId, newCardNumber]);

  return (
    <>
      {(creditCards.length > 0 || invoiceAccounts.length > 0) && (
        <PaymentMethodSelect
          name="paymentProfileId"
          paymentMethods={creditCards}
          invoiceAccounts={invoiceAccounts}
          paymentUserId={guest.userId}
          {...props}
        />
      )}
      {!paymentProfileId && (
        <>
          <GuestInformationForm
            name={guest.name}
            address={guest.address}
            prefix="billing"
            billing={true}
            disablePersonalDetails={true}
            {...props}
          />
          <CreditCardForm {...props} />
          {/* {!noSave && (!guest.userId || guest?.userId === loggedInUserId) && (
            <FormCheckbox
              defaultChecked
              name="savePaymentMethod"
              label="Save this payment method"
            />
          )} */}
        </>
      )}
    </>
  );
};
