import { Overwrite } from 'utility-types';
import { parseISO } from 'date-fns';

import { Reservations } from '@lib/state';

interface DateProps {
  startDate: Date;
  endDate: Date;
  disabledAt: Date | null;
  createdAt: Date;
}

export type RatePlanSort = Reservations.RatePlanSort;
export const RatePlanSort = Reservations.RatePlanSort;

export type RoomRatePlan = Overwrite<Reservations.RoomRatePlanModel, DateProps>;
export type AmenityFee = Reservations.AmenityFeeModel;

export function mapRoomRatePlan(ratePlan: Reservations.RoomRatePlanModel) {
  return {
    ...ratePlan,
    startDate: parseISO(ratePlan.startDate),
    endDate: parseISO(ratePlan.endDate),
    disabledAt: (ratePlan.disabledAt != null && parseISO(ratePlan.disabledAt)) || null,
    createdAt: parseISO(ratePlan.createdAt),
  };
}
