import { EntityState, ActiveState, StoreConfig, EntityStore } from '@datorama/akita';
import { QRCode, QRCodeFilter } from './qr-code.model';
import { PaginationState } from '../models.common';

export interface QRCodeState extends EntityState<QRCode, QRCode['id']>, ActiveState<QRCode['id']> {
  pagination: PaginationState;
  filters: QRCodeFilter;
  maintenanceTicketNumber?: number;
}

@StoreConfig({ name: 'qr-codes', resettable: true })
export class QRCodeStore extends EntityStore<QRCodeState> {
  constructor() {
    super({
      pagination: {
        continuationToken: undefined,
        isDone: true,
      },
    });
  }
}

export const qrCodeStore = new QRCodeStore();
