import React, { memo } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

import { RoomType } from '@lib/state';
import { DateDisplay } from '../components';
import { kioskStyles } from '../guest-kiosk.theme';

interface Props {
  checkInDate: Date;
  checkOutDate: Date;
  roomType?: RoomType | null;
  variant?: TypographyProps['variant'];
}

export const StayDates: React.FC<Props> = memo(
  ({ checkInDate, checkOutDate, roomType, variant }) => {
    const { internalRow, internalColumn, internalColumnDouble } = kioskStyles();
    const textVariant: TypographyProps['variant'] = variant ?? 'body2';

    return (
      <div className={internalRow}>
        <div className={internalColumn}>
          <Typography variant="subtitle2">Check-In</Typography>
          <DateDisplay date={checkInDate} dateFormat="MMM d" variant={textVariant} />
        </div>
        <div className={internalColumn}>
          <Typography variant="subtitle2">Check-Out</Typography>
          <DateDisplay date={checkOutDate} dateFormat="MMM d" variant={textVariant} />
        </div>
        <div className={internalColumnDouble}>
          <Typography variant="subtitle2">Room Type</Typography>
          <Typography variant={textVariant}>{roomType?.name ?? '-'}</Typography>
        </div>
      </div>
    );
  }
);
