import React from 'react';
import { Grid } from '@material-ui/core';

import { FormTextField, TextFieldProps } from '../../forms';

interface Props {
  inputProps?: TextFieldProps;
}

export const PasswordForm: React.FC<Props> = ({ inputProps = {} }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <FormTextField
          {...inputProps}
          name="password"
          type="password"
          label="Password"
          validationOptions={{
            required: true,
            pattern: /^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^!&*+=]).*$/,
          }}
          helperText={errors =>
            errors.password?.type === 'pattern'
              ? 'Password must be at least 6 characters, must contain at least 1 Uppercase letter, 1 Lowercase letter, 1 Number and 1 special character(@#$%^!&*+=).'
              : ''
          }
          fullWidth
          data-testid="formPasswordInput"
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          {...inputProps}
          name="confirmPassword"
          type="password"
          label="Retype Password"
          validationOptions={values => ({
            required: true,
            validate: value => values.password === value,
          })}
          helperText={errors =>
            errors.confirmPassword?.type === 'validate'
              ? 'Confirm password must match password'
              : ''
          }
          fullWidth
          data-testid="formConfirmPasswordInput"
        />
      </Grid>
    </Grid>
  );
};
