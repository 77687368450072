import { useDebouncedEffect, FormTextField, FormSelect, EnumValueDisplay } from '@lib/common';
import { QRCodeFilter, QRCodeStatus, QRCodeType } from '@lib/state';
import { Grid, MenuItem } from '@material-ui/core';
import { FilterSection } from 'app/shared';
import React, { useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';

interface Props {
  filters: QRCodeFilter;
  onChange: (filters: QRCodeFilter) => void;
}

export function QRCodeSearchFilter({ filters, onChange }: Props) {
  const form = useForm<QRCodeFilter>({
    defaultValues: {
      ...filters,
      name: filters.name ? filters.name : '',
      location: filters.location ?? '',
      status: QRCodeStatus.Active,
    },
  });

  useEffect(() => {
    form.register({ name: 'type' });
    form.register({ name: 'status' });
    // eslint-disable-next-line
  }, []);

  const { type, roomNumber, location, name, status, qrCodeNumber } = form.watch({
    nest: true,
  });

  useDebouncedEffect(
    () => {
      onChange({ type, roomNumber, location, name, status, qrCodeNumber });
    },
    1000,
    [type, roomNumber, location, name, status, qrCodeNumber]
  );

  return (
    <FilterSection>
      <FormContext {...form}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <FormTextField
              id="name"
              name="name"
              label="Name"
              fullWidth
              size="small"
              data-testid="searchQRCodeNameInput"
            />
          </Grid>
          <Grid item xs={2}>
            <FormTextField
              id="qrCodeNumber"
              name="qrCodeNumber"
              label="QR Code Number"
              fullWidth
              size="small"
              data-testid="searchQRCodeNumberInput"
            />
          </Grid>
          <Grid item xs={2}>
            <FormSelect
              name="type"
              label="QR Code Type"
              fullWidth
              size="small"
              data-testid="searchQRCodeTypeSelect"
              defaultValue=""
            >
              <MenuItem value="">All</MenuItem>
              {Object.values(QRCodeType).map(x => (
                <MenuItem key={x} value={x} dense>
                  <EnumValueDisplay value={x} disableTypography />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={2}>
            <FormTextField
              name="roomNumber"
              label="Room Number"
              fullWidth
              size="small"
              data-testid="SearchQRCodeRoomNumberInput"
            />
          </Grid>
          <Grid item xs={2}>
            <FormTextField
              name="location"
              label="Location"
              fullWidth
              size="small"
              data-testid="searchQRCodeLocationInput"
            />
          </Grid>
          <Grid item xs={2}>
            <FormSelect
              name="status"
              label="QR Code Status"
              fullWidth
              size="small"
              data-testid="searchQRCodeStatusSelect"
              defaultValue=""
            >
              <MenuItem value="">All</MenuItem>
              {Object.values(QRCodeStatus).map(x => (
                <MenuItem key={x} value={x} dense>
                  <EnumValueDisplay value={x} disableTypography />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
        </Grid>
      </FormContext>
    </FilterSection>
  );
}
