// tslint:disable
/**
 * Access Control
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { ArmDeviceProvisioningPolicyType } from '../models';
import { IotDoorLockProvisioningRequest } from '../models';
import { MdmEnrollmentTokenResponse } from '../models';
import { ProvisionAreaRequest } from '../models';
import { ProvisionAreaThingResponse } from '../models';
import { ProvisionRoomRequest } from '../models';
import { ProvisionRoomResponse } from '../models';
/**
 * ProvisioningApi - axios parameter creator
 * @export
 */
export const ProvisioningApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ProvisionAreaRequest} [provisionAreaRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    provisioningAreaPost(
      provisionAreaRequest?: ProvisionAreaRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/provisioning/area`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof provisionAreaRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(provisionAreaRequest !== undefined ? provisionAreaRequest : {})
        : provisionAreaRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {ArmDeviceProvisioningPolicyType} policyType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    provisioningPropertyIdDeviceProvisioningQrCodePolicyTypeGet(
      propertyId: string,
      policyType: ArmDeviceProvisioningPolicyType,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling provisioningPropertyIdDeviceProvisioningQrCodePolicyTypeGet.'
        );
      }
      // verify required parameter 'policyType' is not null or undefined
      if (policyType === null || policyType === undefined) {
        throw new RequiredError(
          'policyType',
          'Required parameter policyType was null or undefined when calling provisioningPropertyIdDeviceProvisioningQrCodePolicyTypeGet.'
        );
      }
      const localVarPath = `/provisioning/{propertyId}/device-provisioning-qr-code/{policyType}`
        .replace(`{${'propertyId'}}`, encodeURIComponent(String(propertyId)))
        .replace(`{${'policyType'}}`, encodeURIComponent(String(policyType)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {IotDoorLockProvisioningRequest} [iotDoorLockProvisioningRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    provisioningPropertyIdDeviceQrCodePost(
      propertyId: string,
      iotDoorLockProvisioningRequest?: IotDoorLockProvisioningRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling provisioningPropertyIdDeviceQrCodePost.'
        );
      }
      const localVarPath = `/provisioning/{propertyId}/device-qr-code`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof iotDoorLockProvisioningRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            iotDoorLockProvisioningRequest !== undefined ? iotDoorLockProvisioningRequest : {}
          )
        : iotDoorLockProvisioningRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ProvisionRoomRequest} [provisionRoomRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    provisioningRoomPost(
      provisionRoomRequest?: ProvisionRoomRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/provisioning/room`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof provisionRoomRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(provisionRoomRequest !== undefined ? provisionRoomRequest : {})
        : provisionRoomRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProvisioningApi - functional programming interface
 * @export
 */
export const ProvisioningApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ProvisionAreaRequest} [provisionAreaRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    provisioningAreaPost(
      provisionAreaRequest?: ProvisionAreaRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProvisionAreaThingResponse> {
      const localVarAxiosArgs = ProvisioningApiAxiosParamCreator(
        configuration
      ).provisioningAreaPost(provisionAreaRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {ArmDeviceProvisioningPolicyType} policyType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    provisioningPropertyIdDeviceProvisioningQrCodePolicyTypeGet(
      propertyId: string,
      policyType: ArmDeviceProvisioningPolicyType,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MdmEnrollmentTokenResponse> {
      const localVarAxiosArgs = ProvisioningApiAxiosParamCreator(
        configuration
      ).provisioningPropertyIdDeviceProvisioningQrCodePolicyTypeGet(
        propertyId,
        policyType,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {IotDoorLockProvisioningRequest} [iotDoorLockProvisioningRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    provisioningPropertyIdDeviceQrCodePost(
      propertyId: string,
      iotDoorLockProvisioningRequest?: IotDoorLockProvisioningRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MdmEnrollmentTokenResponse> {
      const localVarAxiosArgs = ProvisioningApiAxiosParamCreator(
        configuration
      ).provisioningPropertyIdDeviceQrCodePost(propertyId, iotDoorLockProvisioningRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {ProvisionRoomRequest} [provisionRoomRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    provisioningRoomPost(
      provisionRoomRequest?: ProvisionRoomRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProvisionRoomResponse> {
      const localVarAxiosArgs = ProvisioningApiAxiosParamCreator(
        configuration
      ).provisioningRoomPost(provisionRoomRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProvisioningApi - factory interface
 * @export
 */
export const ProvisioningApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {ProvisionAreaRequest} [provisionAreaRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    provisioningAreaPost(
      provisionAreaRequest?: ProvisionAreaRequest,
      options?: any
    ): AxiosPromise<ProvisionAreaThingResponse> {
      return ProvisioningApiFp(configuration).provisioningAreaPost(provisionAreaRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} propertyId
     * @param {ArmDeviceProvisioningPolicyType} policyType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    provisioningPropertyIdDeviceProvisioningQrCodePolicyTypeGet(
      propertyId: string,
      policyType: ArmDeviceProvisioningPolicyType,
      options?: any
    ): AxiosPromise<MdmEnrollmentTokenResponse> {
      return ProvisioningApiFp(
        configuration
      ).provisioningPropertyIdDeviceProvisioningQrCodePolicyTypeGet(
        propertyId,
        policyType,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {IotDoorLockProvisioningRequest} [iotDoorLockProvisioningRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    provisioningPropertyIdDeviceQrCodePost(
      propertyId: string,
      iotDoorLockProvisioningRequest?: IotDoorLockProvisioningRequest,
      options?: any
    ): AxiosPromise<MdmEnrollmentTokenResponse> {
      return ProvisioningApiFp(configuration).provisioningPropertyIdDeviceQrCodePost(
        propertyId,
        iotDoorLockProvisioningRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {ProvisionRoomRequest} [provisionRoomRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    provisioningRoomPost(
      provisionRoomRequest?: ProvisionRoomRequest,
      options?: any
    ): AxiosPromise<ProvisionRoomResponse> {
      return ProvisioningApiFp(configuration).provisioningRoomPost(provisionRoomRequest, options)(
        axios,
        basePath
      );
    },
  };
};

/**
 * ProvisioningApi - object-oriented interface
 * @export
 * @class ProvisioningApi
 * @extends {BaseAPI}
 */
export class ProvisioningApi extends BaseAPI {
  /**
   *
   * @param {ProvisionAreaRequest} [provisionAreaRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningApi
   */
  public provisioningAreaPost(provisionAreaRequest?: ProvisionAreaRequest, options?: any) {
    return ProvisioningApiFp(this.configuration).provisioningAreaPost(
      provisionAreaRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {ArmDeviceProvisioningPolicyType} policyType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningApi
   */
  public provisioningPropertyIdDeviceProvisioningQrCodePolicyTypeGet(
    propertyId: string,
    policyType: ArmDeviceProvisioningPolicyType,
    options?: any
  ) {
    return ProvisioningApiFp(
      this.configuration
    ).provisioningPropertyIdDeviceProvisioningQrCodePolicyTypeGet(
      propertyId,
      policyType,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {IotDoorLockProvisioningRequest} [iotDoorLockProvisioningRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningApi
   */
  public provisioningPropertyIdDeviceQrCodePost(
    propertyId: string,
    iotDoorLockProvisioningRequest?: IotDoorLockProvisioningRequest,
    options?: any
  ) {
    return ProvisioningApiFp(this.configuration).provisioningPropertyIdDeviceQrCodePost(
      propertyId,
      iotDoorLockProvisioningRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {ProvisionRoomRequest} [provisionRoomRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProvisioningApi
   */
  public provisioningRoomPost(provisionRoomRequest?: ProvisionRoomRequest, options?: any) {
    return ProvisioningApiFp(this.configuration).provisioningRoomPost(
      provisionRoomRequest,
      options
    )(this.axios, this.basePath);
  }
}
