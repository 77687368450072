import { parseISO } from 'date-fns';

import { Reservations } from '../api';

export type AvailableAmenity = Reservations.AvailableAmenityModel & {
  selected?: boolean;
};

export interface OptionalAmenity {
  code: string;
  selected: boolean;
}

export interface DailyRate extends Omit<Reservations.DailyRateModel, 'date'> {
  date: Date;
}

export interface RoomRate
  extends Omit<Reservations.AvailableRateModel, 'dailyRates' | 'amenities'> {
  dailyRates: Array<DailyRate>;
  amenities: Array<AvailableAmenity>;
}

export function mapDailyRate(rate: Reservations.DailyRateModel): DailyRate {
  return {
    ...rate,
    date: parseISO(rate.date),
  };
}

export function mapRoomRate(rate: Reservations.AvailableRateModel): RoomRate {
  return {
    ...rate,
    dailyRates: rate.dailyRates.map(mapDailyRate),
  };
}
