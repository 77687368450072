import { useInfiniteQuery } from 'react-query';
import { delay, getCancelToken, Audit } from '@lib/state';

const logApi = new Audit.LogApi();

interface AuditLogParams {
  scope: string;
  key?: string;
}

export function useAuditLogQuery({ scope, key }: AuditLogParams) {
  const request: Partial<Audit.AuditQueryRequest> = {
    scope,
    key,
  };
  const query = useInfiniteQuery({
    queryKey: [request],
    queryFn: async ({ pageParam, signal }) => {
      await delay(500, signal); // debounce by 500ms

      const { data } = await logApi.logPost(
        { ...request, continuationToken: pageParam } as Audit.AuditQueryRequest,
        getCancelToken(signal)
      );
      return data;
    },
    getNextPageParam: (last: Audit.AuditQueryResponse) => {
      if (last.isDone) return undefined; //a continuation token is returned by the api regardless if there is more data or not

      return last.continuationToken;
    },
    enabled: !!key, //query will not execute until key is set.
    refetchInterval: 15000,
    cacheTime: 0,
    staleTime: 0,
  });

  return query;
}
