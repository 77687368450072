export enum ReservationForms {
  CreateAccount = 'CreateAccount',
  CreateReservation = 'CreateReservation',
  CancelReservation = 'CancelReservation',
  CheckOutReservation = 'CheckOutReservation',
  UpdateReservation = 'UpdateReservation',
  UpdateCostCenterFields = 'UpdateCostCenterFields',
  UpdateSleepSchedule = 'UpdateSleepSchedule',
  LateCheckOut = 'LateCheckOut',
  RefundReservationCharge = 'RefundReservationCharge',
  RefundStayDate = 'RefundStayDate',
  VerifyCode = 'VerifyCode',
  ClaimReservation = 'ClaimReservation',
  ChangeBilling = 'ChangeBilling',
  RebookReservation = 'RebookReservation',
  VerifyGuest = 'VerifyGuest',
  BulkReservationsModify = 'BulkReservationsModify',
  BulkCancel = 'BulkCancel',
  BulkCancelPreview = 'BulkCancelPreview',
}
