import { dispatchForm, Properties, Property } from '@lib/state';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { MaintenanceTicket } from '../maintenance/maintenance-ticket.model';
import { MaintenanceTicketJournalForms } from './maintenance-journal.form';
import maintenanceTicketJournalStore, {
  MaintenanceTicketJournalStore,
} from './maintenance-journal.store';

export class MaintenanceTicketJournalService {
  constructor(
    private readonly store: MaintenanceTicketJournalStore,
    private readonly maintenanceApi: Properties.MaintenanceApi
  ) {}

  getJournalsByTicketId(propertyId: Property['id'], id: MaintenanceTicket['id']) {
    from(this.maintenanceApi.maintenanceIdJournalsGet(id, propertyId))
      .pipe(map(({ data }) => data.data))
      .subscribe(journals => {
        this.store.upsertMany(journals);
      });
  }

  addJournal(description: string, propertyId: Property['id'], ticketId: string) {
    from(
      this.maintenanceApi.maintenanceIdJournalsPost(ticketId, {
        propertyId: propertyId,
        description: description,
      })
    )
      .pipe(
        map(({ data }) => data.data),
        dispatchForm(MaintenanceTicketJournalForms.Create)
      )
      .subscribe(x => this.store.upsertMany([x]));
  }
}

const maintenanceTicketJournalService = new MaintenanceTicketJournalService(
  maintenanceTicketJournalStore,
  new Properties.MaintenanceApi()
);
export default maintenanceTicketJournalService;
