import React from 'react';
import { makeStyles, Typography, TypographyVariant } from '@material-ui/core';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

import { kioskStyles, Link } from '@lib/common';
import { Tile } from './tile';
import { isKiosk } from '../native-interface';

const useStyles = makeStyles(theme => ({
  link: {
    height: '100%',
    padding: '2rem 0',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',

    textAlign: 'center',
  },
  icon: {
    color: theme.palette.secondary.main,
    marginBottom: '0.5rem',
  },
  disabledIcon: {
    color: theme.palette.grey[600],
    marginBottom: '0.5rem',
  },
  kioskOnlyText: {
    fontSize: '1rem',
  },
}));

interface TileLinkProps {
  className?: string;
  to: string;
  variant?: TypographyVariant;
  textColor?:
    | 'inherit'
    | 'initial'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
  useBorder?: boolean;
  icon: IconProp;
  iconProps?: Partial<Omit<FontAwesomeIconProps, 'icon'>>;
  kioskOnly?: boolean;
}

export const TileLink: React.FC<TileLinkProps> = ({
  className,
  icon,
  iconProps,
  children,
  useBorder,
  kioskOnly,
  ...props
}) => {
  const kiosk = isKiosk();
  const { disabledKioskButton } = kioskStyles();
  const styles = useStyles();

  const linkColor = props.textColor ?? 'secondary';

  return (
    <Tile useBorder={useBorder}>
      {!kiosk && kioskOnly ? (
        <Typography className={clsx(styles.link, disabledKioskButton)} {...props} color={linkColor}>
          <span className={styles.disabledIcon}>
            <FontAwesomeIcon icon={icon} size="2x" fixedWidth {...iconProps} />
          </span>
          {children}
          <span className={styles.kioskOnlyText}>(only available on kiosk)</span>
        </Typography>
      ) : (
        <Link className={clsx(styles.link, className)} {...props} color={linkColor}>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={icon} size="2x" fixedWidth {...iconProps} />
          </span>
          {children}
        </Link>
      )}
    </Tile>
  );
};
