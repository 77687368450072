import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { Box, Button } from '@material-ui/core';

import { sessionQuery } from '@lib/state';
import { useObservable, usePageTitle, Section } from '@lib/common';
import { EmployeeList, EmployeeSearchFilter, AddEmployeeModal } from './../components';
import { Tile } from 'app/shared';
import { employeeService, employeeQuery, EmployeeFilters, filterEmployee } from 'app/state';

export const UserManagementPage: React.FC = () => {
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const employees = useObservable(employeeQuery.employees, 'async');
  const employeeSearchFilters = useObservable(employeeQuery.employeeSearchFilters);

  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false);

  const onCloseOpenAddEmployeeModal = useCallback(() => {
    setOpenAddEmployeeModal(false);
  }, []);

  usePageTitle('User Management');

  useEffect(() => {
    if (propertyId) {
      employeeService.getEmployees(propertyId);
    }
  }, [propertyId]);

  const showAddEmployeeModal = () => {
    setOpenAddEmployeeModal(true);
  };

  const onFiltersChange = (filters: EmployeeFilters) => {
    employeeService.updateFilters(filters);
  };

  const filteredEmployees = useMemo(
    () =>
      employees
        ? employees.filter(employee => filterEmployee(employeeSearchFilters, employee))
        : [],
    [employees, employeeSearchFilters]
  );

  return (
    <Section>
      <Tile>
        <Box display="flex" justifyContent="flex-end" flexDirection="row">
          <Button color="secondary" variant="contained" onClick={showAddEmployeeModal}>
            Add Employee
          </Button>
        </Box>
        <EmployeeSearchFilter filters={employeeSearchFilters} onChange={onFiltersChange} />

        {filteredEmployees && filteredEmployees.length > 0 && (
          <Section title="Results" textAlign="left" disableGutters>
            <EmployeeList employees={filteredEmployees} />
          </Section>
        )}

        <AddEmployeeModal open={openAddEmployeeModal} onClose={onCloseOpenAddEmployeeModal} />
      </Tile>
    </Section>
  );
};
