import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
const useStyles = makeStyles(theme =>
  createStyles({
    innerDiv: {
      backgroundColor: theme.palette.primary.main,
      textAlign: 'center',
      position: 'fixed',
      left: '0',
      bottom: '0',
      width: '100%',
      padding: theme.spacing(2),
      zIndex: 999,
    },
  })
);

export const FooterContainer: React.FC = ({ children }) => {
  const styles = useStyles();
  return <div className={styles.innerDiv}>{children}</div>;
};
