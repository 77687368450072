// tslint:disable
/**
 * Point of Sale
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { ProblemDetails } from '../models';
import { ProductTransactionPaginatedListResponse } from '../models';
import { PurchaseType } from '../models';
/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {PurchaseType} [purchaseType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportPropertyIdPurchasesExportGet(
      propertyId: string,
      startDate?: string,
      endDate?: string,
      purchaseType?: PurchaseType,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling reportPropertyIdPurchasesExportGet.'
        );
      }
      const localVarPath = `/report/{propertyId}/purchases/export`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (purchaseType !== undefined) {
        localVarQueryParameter['purchaseType'] = purchaseType;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {PurchaseType} [purchaseType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportPropertyIdPurchasesGet(
      propertyId: string,
      continuationToken?: string,
      limit?: number,
      startDate?: string,
      endDate?: string,
      purchaseType?: PurchaseType,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling reportPropertyIdPurchasesGet.'
        );
      }
      const localVarPath = `/report/{propertyId}/purchases`.replace(
        `{${'propertyId'}}`,
        encodeURIComponent(String(propertyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (purchaseType !== undefined) {
        localVarQueryParameter['purchaseType'] = purchaseType;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {PurchaseType} [purchaseType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportPropertyIdPurchasesExportGet(
      propertyId: string,
      startDate?: string,
      endDate?: string,
      purchaseType?: PurchaseType,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = ReportApiAxiosParamCreator(
        configuration
      ).reportPropertyIdPurchasesExportGet(propertyId, startDate, endDate, purchaseType, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {PurchaseType} [purchaseType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportPropertyIdPurchasesGet(
      propertyId: string,
      continuationToken?: string,
      limit?: number,
      startDate?: string,
      endDate?: string,
      purchaseType?: PurchaseType,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<ProductTransactionPaginatedListResponse> {
      const localVarAxiosArgs = ReportApiAxiosParamCreator(
        configuration
      ).reportPropertyIdPurchasesGet(
        propertyId,
        continuationToken,
        limit,
        startDate,
        endDate,
        purchaseType,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} propertyId
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {PurchaseType} [purchaseType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportPropertyIdPurchasesExportGet(
      propertyId: string,
      startDate?: string,
      endDate?: string,
      purchaseType?: PurchaseType,
      options?: any
    ): AxiosPromise<any> {
      return ReportApiFp(configuration).reportPropertyIdPurchasesExportGet(
        propertyId,
        startDate,
        endDate,
        purchaseType,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {PurchaseType} [purchaseType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportPropertyIdPurchasesGet(
      propertyId: string,
      continuationToken?: string,
      limit?: number,
      startDate?: string,
      endDate?: string,
      purchaseType?: PurchaseType,
      options?: any
    ): AxiosPromise<ProductTransactionPaginatedListResponse> {
      return ReportApiFp(configuration).reportPropertyIdPurchasesGet(
        propertyId,
        continuationToken,
        limit,
        startDate,
        endDate,
        purchaseType,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
  /**
   *
   * @param {string} propertyId
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {PurchaseType} [purchaseType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public reportPropertyIdPurchasesExportGet(
    propertyId: string,
    startDate?: string,
    endDate?: string,
    purchaseType?: PurchaseType,
    options?: any
  ) {
    return ReportApiFp(this.configuration).reportPropertyIdPurchasesExportGet(
      propertyId,
      startDate,
      endDate,
      purchaseType,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {PurchaseType} [purchaseType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public reportPropertyIdPurchasesGet(
    propertyId: string,
    continuationToken?: string,
    limit?: number,
    startDate?: string,
    endDate?: string,
    purchaseType?: PurchaseType,
    options?: any
  ) {
    return ReportApiFp(this.configuration).reportPropertyIdPurchasesGet(
      propertyId,
      continuationToken,
      limit,
      startDate,
      endDate,
      purchaseType,
      options
    )(this.axios, this.basePath);
  }
}
