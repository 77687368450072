import React, { useCallback, useMemo } from 'react';

import { mapAuditLogResponses } from 'app/state';
import { LogTable } from './log-table';
import { useAuditLogQuery } from '../utils';
import { AuditQueryResponse } from '@lib/state/api/generated/audit';

interface Props {
  scope: string;
  id?: string;
}

export const AuditLogs: React.FC<Props> = ({ scope, id }) => {
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = useAuditLogQuery({
    scope,
    key: id,
  });

  const logs = useMemo(() => {
    if (!data) return undefined;

    return mapAuditLogResponses(data.pages as AuditQueryResponse[]);
  }, [data]);

  const loadMore = useCallback(() => {
    if (id && hasNextPage) fetchNextPage();
  }, [id, hasNextPage, fetchNextPage]);
  return (
    <LogTable logs={logs ?? []} isDone={!hasNextPage || isFetchingNextPage} onLoadMore={loadMore} />
  );
};
