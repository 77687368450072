import React from 'react';
import * as H from 'history';
import { FormLoginOptions } from '@lib/common/reservations/claim/form-login-options';

interface KioskFormLoginOptions {
  history?: H.History;
}

export const KioskFormLoginOptions: React.FC<KioskFormLoginOptions> = ({ history }) => {
  const defaultSignInHandler = () => {
    //Push return route
    history?.push(`/kiosk/account?popOnLogin=true`);
  };

  const defaultSignInQRHandler = () => {
    //Push return route
    history?.push(`/kiosk/qr-code?popOnLogin=true`);
  };

  return (
    <FormLoginOptions
      signInClickHandler={defaultSignInHandler}
      signInQRClickHandler={defaultSignInQRHandler}
    />
  );
};
