import { Query } from '@datorama/akita';

import { GuestSurveyState, guestSurveyStore } from './guest-survey.store';

export class GuestSurveyQuery extends Query<GuestSurveyState> {
  guestSurvey = this.select(({ guestSurvey }) => guestSurvey);
  guestSurveyRequestValid = this.select(({ guestSurveyRequestValid }) => guestSurveyRequestValid);
}

export const guestSurveyQuery = new GuestSurveyQuery(guestSurveyStore);
