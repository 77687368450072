// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { GetGuestProfileItemResponse } from '../models';
import { GetPaymentMethodsListResponse } from '../models';
import { GuestSearchPaginatedListResponse } from '../models';
import { ProblemDetails } from '../models';
import { UpdateGuestProfileRequest } from '../models';
import { UpdateGuestProfileResponse } from '../models';
import { VipStatus } from '../models';
/**
 * GuestsApi - axios parameter creator
 * @export
 */
export const GuestsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [email]
     * @param {string} [phone]
     * @param {string} [propertyId]
     * @param {boolean} [showArchived]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsExportGet(
      firstName?: string,
      lastName?: string,
      email?: string,
      phone?: string,
      propertyId?: string,
      showArchived?: boolean,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/guests/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (firstName !== undefined) {
        localVarQueryParameter['firstName'] = firstName;
      }

      if (lastName !== undefined) {
        localVarQueryParameter['lastName'] = lastName;
      }

      if (email !== undefined) {
        localVarQueryParameter['email'] = email;
      }

      if (phone !== undefined) {
        localVarQueryParameter['phone'] = phone;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (showArchived !== undefined) {
        localVarQueryParameter['showArchived'] = showArchived;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (vipStatus !== undefined) {
        localVarQueryParameter['vipStatus'] = vipStatus;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestUserIdGet(userId: string, propertyId?: string, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling guestsGuestUserIdGet.'
        );
      }
      const localVarPath = `/guests/guest/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {string} [propertyId]
     * @param {string} [corporateAccountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestUserIdPaymentmethodsGet(
      userId: string,
      propertyId?: string,
      corporateAccountId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling guestsGuestUserIdPaymentmethodsGet.'
        );
      }
      const localVarPath = `/guests/guest/{userId}/paymentmethods`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (corporateAccountId !== undefined) {
        localVarQueryParameter['corporateAccountId'] = corporateAccountId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestUserIdPictureGet(userId: string, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling guestsGuestUserIdPictureGet.'
        );
      }
      const localVarPath = `/guests/guest/{userId}/picture`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateGuestProfileRequest} [updateGuestProfileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestUserIdPost(
      userId: string,
      updateGuestProfileRequest?: UpdateGuestProfileRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling guestsGuestUserIdPost.'
        );
      }
      const localVarPath = `/guests/guest/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateGuestProfileRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateGuestProfileRequest !== undefined ? updateGuestProfileRequest : {})
        : updateGuestProfileRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [email]
     * @param {string} [phone]
     * @param {string} [propertyId]
     * @param {boolean} [showArchived]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsSearchGet(
      firstName?: string,
      lastName?: string,
      email?: string,
      phone?: string,
      propertyId?: string,
      showArchived?: boolean,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/guests/search`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (firstName !== undefined) {
        localVarQueryParameter['firstName'] = firstName;
      }

      if (lastName !== undefined) {
        localVarQueryParameter['lastName'] = lastName;
      }

      if (email !== undefined) {
        localVarQueryParameter['email'] = email;
      }

      if (phone !== undefined) {
        localVarQueryParameter['phone'] = phone;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (showArchived !== undefined) {
        localVarQueryParameter['showArchived'] = showArchived;
      }

      if (continuationToken !== undefined) {
        localVarQueryParameter['continuationToken'] = continuationToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (vipStatus !== undefined) {
        localVarQueryParameter['vipStatus'] = vipStatus;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GuestsApi - functional programming interface
 * @export
 */
export const GuestsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [email]
     * @param {string} [phone]
     * @param {string} [propertyId]
     * @param {boolean} [showArchived]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsExportGet(
      firstName?: string,
      lastName?: string,
      email?: string,
      phone?: string,
      propertyId?: string,
      showArchived?: boolean,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = GuestsApiAxiosParamCreator(configuration).guestsExportGet(
        firstName,
        lastName,
        email,
        phone,
        propertyId,
        showArchived,
        continuationToken,
        limit,
        vipStatus,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} userId
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestUserIdGet(
      userId: string,
      propertyId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestProfileItemResponse> {
      const localVarAxiosArgs = GuestsApiAxiosParamCreator(configuration).guestsGuestUserIdGet(
        userId,
        propertyId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} userId
     * @param {string} [propertyId]
     * @param {string} [corporateAccountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestUserIdPaymentmethodsGet(
      userId: string,
      propertyId?: string,
      corporateAccountId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaymentMethodsListResponse> {
      const localVarAxiosArgs = GuestsApiAxiosParamCreator(
        configuration
      ).guestsGuestUserIdPaymentmethodsGet(userId, propertyId, corporateAccountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestUserIdPictureGet(
      userId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = GuestsApiAxiosParamCreator(
        configuration
      ).guestsGuestUserIdPictureGet(userId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateGuestProfileRequest} [updateGuestProfileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestUserIdPost(
      userId: string,
      updateGuestProfileRequest?: UpdateGuestProfileRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateGuestProfileResponse> {
      const localVarAxiosArgs = GuestsApiAxiosParamCreator(configuration).guestsGuestUserIdPost(
        userId,
        updateGuestProfileRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [email]
     * @param {string} [phone]
     * @param {string} [propertyId]
     * @param {boolean} [showArchived]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsSearchGet(
      firstName?: string,
      lastName?: string,
      email?: string,
      phone?: string,
      propertyId?: string,
      showArchived?: boolean,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GuestSearchPaginatedListResponse> {
      const localVarAxiosArgs = GuestsApiAxiosParamCreator(configuration).guestsSearchGet(
        firstName,
        lastName,
        email,
        phone,
        propertyId,
        showArchived,
        continuationToken,
        limit,
        vipStatus,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GuestsApi - factory interface
 * @export
 */
export const GuestsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [email]
     * @param {string} [phone]
     * @param {string} [propertyId]
     * @param {boolean} [showArchived]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsExportGet(
      firstName?: string,
      lastName?: string,
      email?: string,
      phone?: string,
      propertyId?: string,
      showArchived?: boolean,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): AxiosPromise<any> {
      return GuestsApiFp(configuration).guestsExportGet(
        firstName,
        lastName,
        email,
        phone,
        propertyId,
        showArchived,
        continuationToken,
        limit,
        vipStatus,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} userId
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestUserIdGet(
      userId: string,
      propertyId?: string,
      options?: any
    ): AxiosPromise<GetGuestProfileItemResponse> {
      return GuestsApiFp(configuration).guestsGuestUserIdGet(
        userId,
        propertyId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} userId
     * @param {string} [propertyId]
     * @param {string} [corporateAccountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestUserIdPaymentmethodsGet(
      userId: string,
      propertyId?: string,
      corporateAccountId?: string,
      options?: any
    ): AxiosPromise<GetPaymentMethodsListResponse> {
      return GuestsApiFp(configuration).guestsGuestUserIdPaymentmethodsGet(
        userId,
        propertyId,
        corporateAccountId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestUserIdPictureGet(userId: string, options?: any): AxiosPromise<void> {
      return GuestsApiFp(configuration).guestsGuestUserIdPictureGet(userId, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateGuestProfileRequest} [updateGuestProfileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsGuestUserIdPost(
      userId: string,
      updateGuestProfileRequest?: UpdateGuestProfileRequest,
      options?: any
    ): AxiosPromise<UpdateGuestProfileResponse> {
      return GuestsApiFp(configuration).guestsGuestUserIdPost(
        userId,
        updateGuestProfileRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [email]
     * @param {string} [phone]
     * @param {string} [propertyId]
     * @param {boolean} [showArchived]
     * @param {string} [continuationToken]
     * @param {number} [limit]
     * @param {VipStatus} [vipStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestsSearchGet(
      firstName?: string,
      lastName?: string,
      email?: string,
      phone?: string,
      propertyId?: string,
      showArchived?: boolean,
      continuationToken?: string,
      limit?: number,
      vipStatus?: VipStatus,
      options?: any
    ): AxiosPromise<GuestSearchPaginatedListResponse> {
      return GuestsApiFp(configuration).guestsSearchGet(
        firstName,
        lastName,
        email,
        phone,
        propertyId,
        showArchived,
        continuationToken,
        limit,
        vipStatus,
        options
      )(axios, basePath);
    },
  };
};

/**
 * GuestsApi - object-oriented interface
 * @export
 * @class GuestsApi
 * @extends {BaseAPI}
 */
export class GuestsApi extends BaseAPI {
  /**
   *
   * @param {string} [firstName]
   * @param {string} [lastName]
   * @param {string} [email]
   * @param {string} [phone]
   * @param {string} [propertyId]
   * @param {boolean} [showArchived]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {VipStatus} [vipStatus]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestsApi
   */
  public guestsExportGet(
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    propertyId?: string,
    showArchived?: boolean,
    continuationToken?: string,
    limit?: number,
    vipStatus?: VipStatus,
    options?: any
  ) {
    return GuestsApiFp(this.configuration).guestsExportGet(
      firstName,
      lastName,
      email,
      phone,
      propertyId,
      showArchived,
      continuationToken,
      limit,
      vipStatus,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} userId
   * @param {string} [propertyId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestsApi
   */
  public guestsGuestUserIdGet(userId: string, propertyId?: string, options?: any) {
    return GuestsApiFp(this.configuration).guestsGuestUserIdGet(
      userId,
      propertyId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} userId
   * @param {string} [propertyId]
   * @param {string} [corporateAccountId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestsApi
   */
  public guestsGuestUserIdPaymentmethodsGet(
    userId: string,
    propertyId?: string,
    corporateAccountId?: string,
    options?: any
  ) {
    return GuestsApiFp(this.configuration).guestsGuestUserIdPaymentmethodsGet(
      userId,
      propertyId,
      corporateAccountId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestsApi
   */
  public guestsGuestUserIdPictureGet(userId: string, options?: any) {
    return GuestsApiFp(this.configuration).guestsGuestUserIdPictureGet(userId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} userId
   * @param {UpdateGuestProfileRequest} [updateGuestProfileRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestsApi
   */
  public guestsGuestUserIdPost(
    userId: string,
    updateGuestProfileRequest?: UpdateGuestProfileRequest,
    options?: any
  ) {
    return GuestsApiFp(this.configuration).guestsGuestUserIdPost(
      userId,
      updateGuestProfileRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [firstName]
   * @param {string} [lastName]
   * @param {string} [email]
   * @param {string} [phone]
   * @param {string} [propertyId]
   * @param {boolean} [showArchived]
   * @param {string} [continuationToken]
   * @param {number} [limit]
   * @param {VipStatus} [vipStatus]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestsApi
   */
  public guestsSearchGet(
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    propertyId?: string,
    showArchived?: boolean,
    continuationToken?: string,
    limit?: number,
    vipStatus?: VipStatus,
    options?: any
  ) {
    return GuestsApiFp(this.configuration).guestsSearchGet(
      firstName,
      lastName,
      email,
      phone,
      propertyId,
      showArchived,
      continuationToken,
      limit,
      vipStatus,
      options
    )(this.axios, this.basePath);
  }
}
