import React, { useState } from 'react';
import { Box, makeStyles, createStyles } from '@material-ui/core';
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';

import { AppColors } from '../theme';

const useStyles = makeStyles(() =>
  createStyles({
    carousel: {
      height: '400px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
    },
    carouselControl: {
      alignSelf: 'center',
      backgroundColor: AppColors.DarkGray,
      padding: '10px',
      height: '44px',
      cursor: 'pointer',
    },
  })
);

export type ImageCarouselProps = {
  images: Array<string>;
};

export const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const styles = useStyles();

  const [index, setIndex] = useState(0);

  const goNext = () => {
    setIndex(index === images.length - 1 ? 0 : index + 1);
  };

  const goPrevious = () => {
    setIndex(index === 0 ? images.length - 1 : index - 1);
  };

  const currentImage = images[index];

  return (
    <Box className={styles.carousel} style={{ backgroundImage: `url(${currentImage})` }}>
      <Box className={styles.carouselControl} onClick={() => goPrevious()}>
        <ArrowBackIos style={{ transform: 'translateX(5px)', fill: 'white' }} />
      </Box>
      <Box className={styles.carouselControl} onClick={() => goNext()}>
        <ArrowForwardIos style={{ fill: 'white' }} />
      </Box>
    </Box>
  );
};
