import React, { useCallback, useMemo } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { useObservable } from '@lib/common';

import { entryCodesService, sessionQuery, ArmKeyGuest, Reservation, getGuests } from '@lib/state';
import { printQRCode } from 'app/shared';
import { formatNameFromObject } from '@lib/state/utils';

interface Props extends Omit<ButtonProps, 'action' | 'onClick'> {
  userId?: string | null;
  reservation?: Reservation;
  printAllGuests?: boolean | null;
}

export const PrintQRCodeButton: React.FC<Props> = ({
  userId,
  reservation,
  printAllGuests,
  children,
  ...props
}) => {
  const propertyId = useObservable(sessionQuery.propertyId);

  const guestsToPrint = useMemo(() => {
    if (reservation) {
      const guests = getGuests(reservation);

      if (printAllGuests) {
        return guests;
      }

      const guest = guests?.find(g => g.userId === userId);

      if (guest) {
        return [guest];
      }
    }
    return [];
  }, [reservation, printAllGuests, userId]);

  const onPrint = useCallback(() => {
    if (propertyId && userId && reservation) {
      const guests: ArmKeyGuest[] =
        guestsToPrint
          ?.filter(guest => !!guest?.userId)
          .map(guest => ({
            userId: guest.userId!,
            guestName: formatNameFromObject(guest.name),
          })) ?? [];

      const company = reservation?.affiliation?.corporateAccountName;

      entryCodesService.getEntryCodesAndPrintQrCode(
        propertyId,
        company,
        reservation.id,
        guests,
        printQRCode
      );
    }
  }, [propertyId, userId, guestsToPrint, reservation]);

  if (!propertyId || !userId) return null;

  return (
    <Button type="button" onClick={onPrint} {...props}>
      {children}
    </Button>
  );
};
