import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import {
  Typography,
  TableBody,
  TableHead,
  TableCell,
  Table,
  TableRow,
  Paper,
  makeStyles,
  createStyles,
  TableContainer,
  Icon,
} from '@material-ui/core';
import { printTheme, SleepScheduleIconDisplay } from '@lib/common';

import { HousekeepingAssignment, Housekeeper, ShowHousekeepingStatus } from '@lib/state';
import { format, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate } from 'app/shared';
import { HousekeepingStatusEnum } from '@lib/state/api/generated/properties';

interface PrintableHousekeepingReportProps {
  rooms: HousekeepingAssignment[];
  housekeepers: Housekeeper[];
  date: Date;
}

const useStyles = makeStyles(theme =>
  createStyles({
    nowrap: {
      whiteSpace: 'nowrap',
    },
    translated: {
      display: 'block',
    },
    page: {
      pageBreakAfter: 'always',
      pageBreakInside: 'avoid',
      marginTop: '2em',
    },
    screen: {
      marginRight: '2em',
      marginLeft: '2em',
    },
    highlighted: {
      backgroundColor: 'lightgray',
      whiteSpace: 'nowrap',
    },
  })
);

export const PrintableHousekeepingReport: React.FC<PrintableHousekeepingReportProps> = ({
  rooms,
  housekeepers,
  date,
}) => {
  const styles = useStyles();

  const housekeeperRows = housekeepers.map(h => {
    return {
      name: getHousekeeperName(h),
      rooms: getAssignmentRows(h, rooms),
    };
  });

  return (
    <div className={styles.screen}>
      <ThemeProvider theme={printTheme}>
        {housekeeperRows
          .filter(f => f.rooms.length > 0)
          .map((h, i) => (
            <TableContainer component={Paper} key={i} className={styles.page}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      <Typography variant="h4">
                        {h.name} Assignments ({formatDate(date)})
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.nowrap}>
                      Room #<small className={styles.translated}>Habitación</small>
                    </TableCell>
                    <TableCell>
                      Time of Assignment
                      <small className={styles.translated}>Tiempo de asignación</small>
                    </TableCell>
                    <TableCell>
                      Type of Clean Requested
                      <small className={styles.translated}>Tipo</small>
                    </TableCell>
                    <TableCell>
                      Corporate Account
                      <small className={styles.translated}>Cuenta corporativa</small>
                    </TableCell>
                    <TableCell>
                      Completed
                      <small className={styles.translated}>Estado</small>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {h.rooms.map((assignment, roomIndex) => (
                    <TableRow key={roomIndex}>
                      <TableCell
                        className={
                          assignment.serviceRequested === HousekeepingStatusEnum.Checkout
                            ? styles.highlighted
                            : styles.nowrap
                        }
                      >
                        {assignment.roomNumber}&nbsp;
                        <SleepScheduleIconDisplay
                          sleepSchedule={assignment.affiliation?.sleepSchedule}
                          height="1.25rem"
                          width="1.25rem"
                        />
                      </TableCell>
                      <TableCell>{getDateString(assignment.assignedTime)}</TableCell>
                      <TableCell
                        className={
                          assignment.serviceRequested === HousekeepingStatusEnum.FullClean
                            ? styles.highlighted
                            : styles.nowrap
                        }
                      >
                        {ShowHousekeepingStatus(
                          assignment.serviceRequested,
                          assignment.isUpcomingCheckOut
                        )}
                      </TableCell>
                      <TableCell className={styles.nowrap}>
                        {assignment.affiliation?.corporateAccountName ?? 'No Company'}
                      </TableCell>
                      <TableCell>
                        <Icon>
                          {!!assignment.endTime && <FontAwesomeIcon icon="check-square" />}
                          {!assignment.endTime && <FontAwesomeIcon icon={['far', 'square']} />}
                        </Icon>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ))}
      </ThemeProvider>
    </div>
  );
};
function getHousekeeperName(housekeeper: Housekeeper): string {
  if (!housekeeper) return '';
  return `${housekeeper.name?.first} ${housekeeper.name?.last}`;
}
function getDateString(date?: string | null) {
  return !!date ? format(parseISO(date), 'MM/dd/yyyy hh:mm a') : '--';
}
function getAssignmentRows(housekeeper: Housekeeper, rooms: HousekeepingAssignment[]) {
  return rooms.filter(f => f.housekeeperUserId === housekeeper.userId);
}
