// tslint:disable
/**
 * Property Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum RoomTypeOption {
  Apartment = 'Apartment',
  Bungalow = 'Bungalow',
  Cabin = 'Cabin',
  Chalet = 'Chalet',
  Condo = 'Condo',
  Cottage = 'Cottage',
  DoubleRoom = 'DoubleRoom',
  Duplex = 'Duplex',
  House = 'House',
  Loft = 'Loft',
  Penthouse = 'Penthouse',
  QuadrupleRoom = 'QuadrupleRoom',
  Room = 'Room',
  SharedDormitory = 'SharedDormitory',
  SingleRoom = 'SingleRoom',
  Studio = 'Studio',
  StudioSuite = 'StudioSuite',
  Suite = 'Suite',
  Tent = 'Tent',
  Townhome = 'Townhome',
  TripleRoom = 'TripleRoom',
  TwinRoom = 'TwinRoom',
  Villa = 'Villa',
}
