import React from 'react';
import { Properties } from '@lib/state';
import { NameDisplay, Link, EnumValueDisplay, DataTable } from '@lib/common';

interface Props {
  employees: Properties.EmployeeModel[];
}

export const EmployeeList: React.FC<Props> = ({ employees }) => {
  return (
    <DataTable
      items={employees}
      getItemKey={employee => employee.userId}
      columns={[
        {
          title: 'Name',
          valueFactory: employee => (
            <Link to={`/user-management/${employee.userId}`}>
              <NameDisplay {...employee.name} directory />
            </Link>
          ),
        },
        {
          title: 'User Role(s)',
          valueFactory: employee => (
            <EnumValueDisplay value={employee.propertyRoles.map(x => x.role)} />
          ),
        },
      ]}
    />
  );
};
