import React, { HTMLAttributes } from 'react';
import { Container, ContainerProps, Typography, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export type SectionVariant = 'default' | 'contained';

export interface SectionProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  maxWidth?: ContainerProps['maxWidth'];
  disableGutters?: ContainerProps['disableGutters'];
  textAlign?: 'center' | 'left';
  variant?: SectionVariant;
  left?: React.ReactNode;
  right?: React.ReactNode;
  banner?: React.ReactNode;
}

interface SectionStyleProps {
  variant?: SectionVariant;
  radius: string;
  textAlign: SectionProps['textAlign'];
}

const useStyles = makeStyles(theme =>
  createStyles({
    header: ({ variant, radius }: SectionStyleProps) => ({
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 2),
      backgroundColor: theme.palette.grey[300],
      borderRadius: isContained(variant) ? `${radius} ${radius} 0 0` : '0',
      color: theme.palette.primary.dark,
      '@media print': {
        color: '#000000',
      },
    }),
    title: {
      flex: 2,
      whiteSpace: 'pre-line',
    },
    leftAside: ({ textAlign }) => ({
      flex: textAlign === 'center' ? 1 : 0,
      display: 'flex',
      justifyContent: 'flex-start',
    }),
    rightAside: ({ textAlign }) => ({
      flex: textAlign === 'center' ? 1 : 0,
      display: 'flex',
      justifyContent: 'flex-end',
    }),
    content: ({ variant }: SectionStyleProps) => ({
      marginTop: isContained(variant) ? theme.spacing(1) : theme.spacing(2),
      padding: isContained(variant) ? '1rem' : '0',
    }),
    container: ({ variant, radius }: SectionStyleProps) => ({
      marginTop: theme.spacing(2),
      border: isContained(variant) ? `1px solid ${theme.palette.grey[300]}` : 'none',
      borderRadius: isContained(variant) ? radius : '0',
    }),
  })
);

function isContained(variant?: SectionVariant): boolean {
  return variant === 'contained';
}

export const Section: React.FC<SectionProps> = ({
  title,
  textAlign = 'center',
  children,
  left,
  right,
  banner,
  maxWidth = 'xl',
  disableGutters,
  variant,
  className,
  ...props
}) => {
  const styles = useStyles({ variant, textAlign, radius: '16px' });
  const hasEitherSide = !!left || !!right;

  return (
    <div {...props} className={clsx(styles.container, className)}>
      {title && (
        <div className={styles.header}>
          {hasEitherSide && <div className={styles.leftAside}>{left}</div>}
          <Typography className={styles.title} variant="h6" align={textAlign}>
            {title}
          </Typography>
          {hasEitherSide && <div className={styles.rightAside}>{right}</div>}
        </div>
      )}
      {banner}
      <Container className={styles.content} maxWidth={maxWidth} disableGutters={disableGutters}>
        {children!}
      </Container>
    </div>
  );
};
