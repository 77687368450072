// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { GetRoomListResponse } from '../models';
import { GetRoomResponse } from '../models';
import { ProblemDetails } from '../models';
import { RoomModel } from '../models';
import { UpdateRoomAvailabilityRequest } from '../models';
/**
 * RoomsApi - axios parameter creator
 * @export
 */
export const RoomsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomsGet(propertyId?: string, options: any = {}): RequestArgs {
      const localVarPath = `/rooms`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateRoomAvailabilityRequest} [updateRoomAvailabilityRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomsIdAvailabilityPost(
      id: string,
      updateRoomAvailabilityRequest?: UpdateRoomAvailabilityRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling roomsIdAvailabilityPost.'
        );
      }
      const localVarPath = `/rooms/{id}/availability`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof updateRoomAvailabilityRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            updateRoomAvailabilityRequest !== undefined ? updateRoomAvailabilityRequest : {}
          )
        : updateRoomAvailabilityRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomId
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomsRoomIdGet(roomId: string, propertyId?: string, options: any = {}): RequestArgs {
      // verify required parameter 'roomId' is not null or undefined
      if (roomId === null || roomId === undefined) {
        throw new RequiredError(
          'roomId',
          'Required parameter roomId was null or undefined when calling roomsRoomIdGet.'
        );
      }
      const localVarPath = `/rooms/{roomId}`.replace(
        `{${'roomId'}}`,
        encodeURIComponent(String(roomId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RoomsApi - functional programming interface
 * @export
 */
export const RoomsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomsGet(
      propertyId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomListResponse> {
      const localVarAxiosArgs = RoomsApiAxiosParamCreator(configuration).roomsGet(
        propertyId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateRoomAvailabilityRequest} [updateRoomAvailabilityRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomsIdAvailabilityPost(
      id: string,
      updateRoomAvailabilityRequest?: UpdateRoomAvailabilityRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomModel> {
      const localVarAxiosArgs = RoomsApiAxiosParamCreator(configuration).roomsIdAvailabilityPost(
        id,
        updateRoomAvailabilityRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} roomId
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomsRoomIdGet(
      roomId: string,
      propertyId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomResponse> {
      const localVarAxiosArgs = RoomsApiAxiosParamCreator(configuration).roomsRoomIdGet(
        roomId,
        propertyId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * RoomsApi - factory interface
 * @export
 */
export const RoomsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomsGet(propertyId?: string, options?: any): AxiosPromise<GetRoomListResponse> {
      return RoomsApiFp(configuration).roomsGet(propertyId, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {UpdateRoomAvailabilityRequest} [updateRoomAvailabilityRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomsIdAvailabilityPost(
      id: string,
      updateRoomAvailabilityRequest?: UpdateRoomAvailabilityRequest,
      options?: any
    ): AxiosPromise<RoomModel> {
      return RoomsApiFp(configuration).roomsIdAvailabilityPost(
        id,
        updateRoomAvailabilityRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} roomId
     * @param {string} [propertyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomsRoomIdGet(
      roomId: string,
      propertyId?: string,
      options?: any
    ): AxiosPromise<GetRoomResponse> {
      return RoomsApiFp(configuration).roomsRoomIdGet(roomId, propertyId, options)(axios, basePath);
    },
  };
};

/**
 * RoomsApi - object-oriented interface
 * @export
 * @class RoomsApi
 * @extends {BaseAPI}
 */
export class RoomsApi extends BaseAPI {
  /**
   *
   * @param {string} [propertyId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomsApi
   */
  public roomsGet(propertyId?: string, options?: any) {
    return RoomsApiFp(this.configuration).roomsGet(propertyId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {UpdateRoomAvailabilityRequest} [updateRoomAvailabilityRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomsApi
   */
  public roomsIdAvailabilityPost(
    id: string,
    updateRoomAvailabilityRequest?: UpdateRoomAvailabilityRequest,
    options?: any
  ) {
    return RoomsApiFp(this.configuration).roomsIdAvailabilityPost(
      id,
      updateRoomAvailabilityRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} roomId
   * @param {string} [propertyId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomsApi
   */
  public roomsRoomIdGet(roomId: string, propertyId?: string, options?: any) {
    return RoomsApiFp(this.configuration).roomsRoomIdGet(
      roomId,
      propertyId,
      options
    )(this.axios, this.basePath);
  }
}
