import React, { useEffect } from 'react';
import { Container, Typography } from '@material-ui/core';
import { useObservable, usePageTitle } from '@lib/common';
import { sessionQuery, sessionService } from '@lib/state';

export const ManagerOverrideCodePage: React.FC = () => {
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const code = useObservable(sessionQuery.employeeCode);

  usePageTitle('Manager Override Code');

  useEffect(() => {
    if (propertyId) sessionService.getEmployeeCode(propertyId);
  }, [propertyId]);

  return (
    <Container maxWidth="md">
      <Typography variant="h2" color="textPrimary" align="center" paragraph>
        Manager Override Code
      </Typography>
      <Typography variant="h4" color="textPrimary" align="center" paragraph>
        {code}
      </Typography>
    </Container>
  );
};
