import { applyTransaction } from '@datorama/akita';
import { Properties, Property } from '@lib/state';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { GuestNoteTypeStore, guestNoteTypeStore } from './guest-note-types.store';

export class GuestNoteTypesService {
  constructor(
    private store: GuestNoteTypeStore,
    private guestNoteTypesApi: Properties.GuestNoteTypesApi
  ) {}

  getGuestNoteTypes(propertyId: Property['id']) {
    from(this.guestNoteTypesApi.propertyIdGuestnotetypesGet(propertyId))
      .pipe(map(({ data }) => data.data))
      .subscribe(guestNoteTypes =>
        applyTransaction(() => {
          this.store.upsertMany(guestNoteTypes);
        })
      );
  }
}

export const guestNoteTypesService = new GuestNoteTypesService(
  guestNoteTypeStore,
  new Properties.GuestNoteTypesApi()
);
