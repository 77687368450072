import React from 'react';
import { Box } from '@material-ui/core';
import { GuestPicture } from '../components';
import { GuestProfile, guestPictureSize } from '@lib/state';
import { useFeatureEnabled, useFormPrefix } from '..';

interface Props {
  prefix?: string;
  guest?: GuestProfile | null;
  defaultToRearCamera?: boolean;
}

export const GuestPictureContainerComponent: React.FC<Props> = ({
  guest,
  prefix,
  defaultToRearCamera = false,
  children,
}) => {
  const fieldName = useFormPrefix(prefix);
  const captureGuestPicture = useFeatureEnabled('GuestPhotos');

  return (
    <Box display="flex" flex={1}>
      {captureGuestPicture && (
        <Box display="flex" flexDirection="column" alignItems="center" mr={1}>
          <GuestPicture
            style={{
              width: `${guestPictureSize + 1}px`,
              height: `${guestPictureSize + 1}px`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid black',
            }}
            guest={guest}
            name={fieldName(`picture`)}
            defaultToRearCamera={defaultToRearCamera}
          />
        </Box>
      )}
      <Box flex={1}>{children}</Box>
    </Box>
  );
};
