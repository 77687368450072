import { Order, QueryEntity } from '@datorama/akita';
import { QRCodeState, QRCodeStore, qrCodeStore } from './qr-code.store';
import { switchMap } from 'rxjs/operators';

export class QRCodeQuery extends QueryEntity<QRCodeState> {
  constructor(protected store: QRCodeStore) {
    super(store);
  }

  activeQRCode = this.selectActive();
  qrCodes = this.selectAll();
  pagination = this.select(({ pagination }) => pagination);
  loading = this.selectLoading();
  maintenanceTicketNumber = this.select(({ maintenanceTicketNumber }) => maintenanceTicketNumber);

  filters = this.select(({ filters }) => filters);

  searchResults = this.filters.pipe(
    switchMap(ui =>
      this.selectAll({
        filterBy: [
          x => x.propertyId === ui.propertyId,
          x =>
            !ui.location ||
            (!!x.location && x.location.toLowerCase().startsWith(ui.location.toLowerCase())),
          x => !ui.name || (!!x.name && x.name.toLowerCase().startsWith(ui.name.toLowerCase())),
          x =>
            !ui.roomNumber ||
            (!!x.roomNumber && x.roomNumber.toLowerCase().startsWith(ui.roomNumber.toLowerCase())),
          x => !ui.status || x.status === ui.status,
          x => !ui.type || x.type === ui.type,
          x =>
            !ui.qrCodeNumber ||
            (!!x.qrCodeNumber &&
              x.qrCodeNumber.toString().toLowerCase().startsWith(ui.qrCodeNumber.toLowerCase())),
        ],
        sortBy: 'name',
        sortByOrder: Order.ASC,
      })
    )
  );
}

export const qrCodeQuery = new QRCodeQuery(qrCodeStore);
