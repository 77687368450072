import React, { useEffect, useState } from 'react';
import { Button, ButtonProps, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { StyledDialog } from './styled-dialog';
import { ErrorDisplay, Feedback } from '../forms';
import { FormStatus } from '@lib/state';

export interface ConfirmedButtonProps extends Omit<ButtonProps, 'action' | 'onClick'> {
  action: string;
  onConfirm: () => void;
  status: FormStatus;
  error?: Error;
  buttonText?: React.ReactNode;
  hidden?: boolean;
  confirmView?: React.ReactChild;
  resultView?: React.ReactChild;
  onOpen?: () => void;
  onSuccess?: () => void;
}

export const ConfirmedButton: React.FC<ConfirmedButtonProps> = ({
  action,
  onConfirm,
  buttonText,
  status,
  error,
  hidden = false,
  children,
  confirmView,
  resultView,
  onOpen,
  onSuccess,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const hasResultView = !!resultView;

  useEffect(() => {
    // if we have a result view to display, then keep the modal open
    if (status === FormStatus.Success) setOpen(open && hasResultView);
  }, [status, hasResultView, open]);

  useEffect(() => {
    // if form status is success, modal is closed, then call onSuccess callback
    if (status === FormStatus.Success && open === false && onSuccess) onSuccess();
  }, [status, open, onSuccess]);

  const confirmDialog = (
    <StyledDialog open={open} title={action} onClose={() => setOpen(false)}>
      <ErrorDisplay error={error} />
      <DialogContent data-testid="confirmActionDisplay">
        {confirmView || <Typography>Are you sure you want to {action}?</Typography>}
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="contained"
          color="default"
          disabled={!!status && status === FormStatus.Pending}
          onClick={() => setOpen(false)}
          data-testid="confirmCancelButton"
        >
          Cancel
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          disabled={!!status && status === FormStatus.Pending}
          onClick={() => {
            onConfirm();
          }}
          data-testid="confirmButton"
        >
          Confirm
        </Button>
      </DialogActions>
    </StyledDialog>
  );

  const resultDialog = (
    <StyledDialog open={open} title={action} onClose={() => setOpen(false)}>
      <Feedback show severity="success">
        <Typography data-testid="confirmActionSuccessDisplay">Success!</Typography>
      </Feedback>
      <DialogContent>{resultView}</DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => setOpen(false)}
          data-testid="confirmCloseSuccessButton"
        >
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
  );

  const dialogState = status === FormStatus.Success ? resultDialog : confirmDialog;

  return (
    <>
      {!hidden && (
        <Button
          type="button"
          onClick={() => {
            setOpen(true);
            if (onOpen) onOpen();
          }}
          {...props}
        >
          {buttonText ?? action}
        </Button>
      )}
      {dialogState}
    </>
  );
};
