import React, { useCallback, useState } from 'react';
import { Room, FormStatus } from '@lib/state';
import { DataTable, RemoveButton, Section, ShowMoreButton, ShowMoreButtonProps } from '@lib/common';
import { Link, Typography } from '@material-ui/core';
import { RoomDetailsModal } from './room-details-modal';

interface Props extends ShowMoreButtonProps {
  rooms: Array<Room>;
  propertyId: string;
  onDeleteRoom: (id: string) => void;
  error: Error | undefined;
  status: FormStatus;
}

export const RoomListResults: React.FC<Props> = ({
  rooms,
  propertyId,
  error,
  status,
  onDeleteRoom,
  isDone,
  loadMore,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<Room>();

  const onDetailsClick = useCallback(
    (room: Room) => {
      setOpen(true);
      setSelectedRoom(room);
    },
    [setOpen, setSelectedRoom]
  );

  const onClose = useCallback(() => {
    setOpen(false);
    setSelectedRoom(undefined);
  }, [setOpen, setSelectedRoom]);

  const renderRoomNumber = useCallback(
    (room: Room) => <Link onClick={() => onDetailsClick(room)}>{room.roomNumber}</Link>,
    [onDetailsClick]
  );

  return (
    <Section title="Results" textAlign="left" disableGutters>
      {selectedRoom && (
        <RoomDetailsModal
          open={open}
          onClose={onClose}
          room={selectedRoom}
          propertyId={propertyId}
        />
      )}
      <DataTable
        items={rooms}
        getItemKey={room => room.id}
        children={<ShowMoreButton isDone={isDone} loadMore={loadMore} />}
        columns={[
          {
            title: 'Room Number',
            valueFactory: renderRoomNumber,
          },
          {
            title: 'Room Type',
            valueFactory: room => room.roomType.name,
          },
          {
            title: 'Room Zone',
            valueFactory: room => room.roomZoneName,
          },
          {
            title: '',
            align: 'right',
            valueFactory: x => (
              <RemoveButton
                onConfirm={() => onDeleteRoom(x.id)}
                status={status}
                error={error}
                confirmView={
                  <Typography>Are you sure you want to remove {x.roomNumber}?</Typography>
                }
              />
            ),
          },
        ]}
      />
    </Section>
  );
};
