import React, { useState, useCallback } from 'react';
import { Box, Button, DialogActions, DialogContent, Grid, MenuItem } from '@material-ui/core';

import { FormStatus, ImportStrategy } from '@lib/state';
import {
  ErrorDisplay,
  Feedback,
  FormFileField,
  FormHub,
  FormSelect,
  StyledDialog,
  SubmitButton,
  useFormEvents,
} from '@lib/common';
import { CorporateAccount, CorporateAccountForms, corporateAccountService } from 'app/state';

interface Props {
  account: CorporateAccount;
}

export const CorporateEmployees: React.FC<Props> = ({ account }) => {
  const [open, setOpen] = useState(false);

  const [{ status, error }, reset] = useFormEvents(CorporateAccountForms.ImportEmployees);

  const closeForm = useCallback(() => {
    reset();
    setOpen(false);
  }, [reset]);

  const onSubmit = useCallback(
    ({ strategy, file }) => corporateAccountService.importEmployees(account, strategy, file[0]),
    // eslint-disable-next-line
    [account.id]
  );

  return (
    <>
      <Box mr={2} clone>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => corporateAccountService.exportEmployees(account)}
        >
          Export Employees
        </Button>
      </Box>
      <Button type="button" variant="contained" color="secondary" onClick={() => setOpen(true)}>
        Import Employees
      </Button>
      <StyledDialog open={open} title="Import Employees" onClose={closeForm}>
        <FormHub onSubmit={onSubmit}>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormSelect
                  name="strategy"
                  label="Import Strategy"
                  defaultValue={ImportStrategy.Merge}
                  fullWidth
                  required
                >
                  <MenuItem value={ImportStrategy.Merge}>Replace</MenuItem>
                  <MenuItem value={ImportStrategy.Upsert}>Append</MenuItem>
                </FormSelect>
              </Grid>
              <Grid item xs={12}>
                <FormFileField name="file" accept=".csv" required />
              </Grid>
              <Grid item xs={12}>
                <Feedback show={status === FormStatus.Success} severity="success" onHide={reset}>
                  Corporate Account {account ? 'saved' : 'created'} successfully!
                </Feedback>
                <ErrorDisplay error={error} />
              </Grid>
            </Grid>
          </DialogContent>
          <Box px="1.5rem !important" clone>
            <DialogActions>
              <Button
                type="button"
                variant="contained"
                color="default"
                disabled={!!status && status === FormStatus.Pending}
                onClick={closeForm}
              >
                Cancel
              </Button>
              <SubmitButton
                variant="contained"
                color="primary"
                disabled={!!status && status === FormStatus.Pending}
                onClick={e => e.stopPropagation()}
              >
                Import
              </SubmitButton>
            </DialogActions>
          </Box>
        </FormHub>
      </StyledDialog>
    </>
  );
};
