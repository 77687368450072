import { of, from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { dispatchForm } from '../../state/forms';
import { AccountForms } from '../../state/account';
import { Auth, Reservations } from '../api';
import { SessionService, sessionService, sessionQuery, SessionQuery } from '../session';
import { getE164FormattedPhoneNumber } from '../utils';

export class AccountService {
  constructor(
    private readonly accountApi: Auth.AccountApi,
    private readonly guestApi: Reservations.GuestApi,
    private readonly sessionService: SessionService
  ) {}

  createAccount(
    email: string,
    phoneNumber: string | undefined | null,
    name: Reservations.Name,
    address: Reservations.Address,
    password: string
  ) {
    const unformattedPhone = getE164FormattedPhoneNumber(phoneNumber);

    from(this.accountApi.accountCreatePost({ email, phoneNumber: unformattedPhone, password }))
      .pipe(
        switchMap(() => this.sessionService.login(email, password)),
        switchMap(() => this.guestApi.guestProfilePost({ name, address })),
        dispatchForm(AccountForms.CreateAccount)
      )
      .subscribe();
  }

  forgotPassword(email: string) {
    from(
      this.accountApi.accountPasswordForgotPost({
        email,
      })
    )
      .pipe(dispatchForm(AccountForms.ForgotPassword))
      .subscribe();
  }

  resetPassword(email: string, token: string, password: string) {
    from(
      this.accountApi.accountPasswordResetPost({
        email,
        token,
        password,
      })
    )
      .pipe(dispatchForm(AccountForms.ResetPassword))
      .subscribe();
  }

  verifyEmail(email: string, token: string) {
    from(
      this.accountApi.accountVerifyEmailConfirmPost({
        email,
        token,
      })
    )
      .pipe(
        dispatchForm(AccountForms.VerifyEmail),
        switchMap(token => this.sessionService.updateUserInfo())
      )
      .subscribe();
  }

  verifyPhoneNumber(phoneNumber: string, code: string, userId?: string) {
    from(
      this.accountApi.accountVerifyPhoneConfirmPost({
        phoneNumber,
        token: code,
        userId,
      })
    )
      .pipe(
        dispatchForm(AccountForms.VerifyPhoneNumber),
        switchMap(token => this.sessionService.updateUserInfo())
      )
      .subscribe();
  }

  sendVerifyPhoneNumberCode(phoneNumber: string, userId?: string) {
    from(
      this.accountApi.accountVerifyPhonePost({
        phoneNumber,
        userId,
      })
    )
      .pipe(dispatchForm(AccountForms.SendPhoneNumberVerificationCode))
      .subscribe();
  }

  sendVerifyEmailToken(email: string) {
    from(
      this.accountApi.accountVerifyEmailPost({
        email,
      })
    )
      .pipe(dispatchForm(AccountForms.SendEmailVerificationToken))
      .subscribe();
  }

  changePassword(currentPassword: string, newPassword: string) {
    from(
      this.accountApi.accountPasswordPost({
        currentPassword: currentPassword,
        newPassword: newPassword,
      })
    )
      .pipe(dispatchForm(AccountForms.ChangePassword))
      .subscribe();
  }

  regenerateKey() {
    from(this.accountApi.accountKeyRegeneratePost())
      .pipe(
        dispatchForm(AccountForms.RegenerateUserKey),
        switchMap(() => this.sessionService.updateUserInfo())
      )
      .subscribe();
  }

  resendKey(reservationUser: string) {
    from(this.accountApi.accountKeyResendPost(reservationUser))
      .pipe(dispatchForm(AccountForms.RegenerateUserKey))
      .subscribe();
  }
}

export const accountService = new AccountService(
  new Auth.AccountApi(),
  new Reservations.GuestApi(),
  sessionService
);
