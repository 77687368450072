import axios from 'axios';

/**
 * Converts an abort signal to a cancel token.
 * @param signal AbortSignal
 * @returns Axios CancelToken
 */
export function getCancelToken(signal?: AbortSignal) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by React Query');
  });

  return { cancelToken: source.token };
}
