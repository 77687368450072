import { Observable } from 'rxjs';
import { filter, startWith, tap } from 'rxjs/operators';

import { getError } from '../api';
import { FormStatus, FormEvent } from './form.models';
import { formService } from './form.service';
import { formQuery } from './form.query';
export function resetFormUI(formId: string) {
  formService.resetForm(formId);
}

export function getFormEvents(formId: string) {
  return formQuery.events.pipe(
    filter(x => x.formId === formId),
    startWith({
      formId,
      status: FormStatus.Initial,
      submitted: false,
    } as FormEvent)
  );
}

export function watchFormEvents(status: FormStatus, ...formIds: string[]) {
  return formQuery.events.pipe(
    filter(({ formId, status: formStatus }) => formStatus === status && formIds.includes(formId))
  );
}

export function dispatchForm<T>(formId: string) {
  return (source: Observable<T>) => {
    formService.setPending(formId);
    return new Observable<T>(observer => {
      return source.subscribe({
        next(value: T) {
          observer.next(value);
        },
        error(err: Error) {
          formService.setError(formId, getError(err));
          observer.error(err);
        },
        complete() {
          formService.setSuccess(formId);
          observer.complete();
        },
      });
    });
  };
}
